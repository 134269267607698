import {
  ErrorTooltip,
  InfoTooltip,
  SuccessTooltip,
} from "@/components/shared/info-tooltip";
import { Button } from "@/components/ui/button";
import { Checkbox } from "@/components/ui/checkbox";
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { Input } from "@/components/ui/input";
import { useToast } from "@/components/ui/use-toast";
import {
  identity,
  registerAsValidator,
  unJailValidator,
  unRegisterAsValidator,
} from "deso-protocol";
import {
  LucideInfo,
  NetworkIcon,
  PlusIcon,
  RocketIcon,
  SendIcon,
  SettingsIcon,
  XIcon,
} from "lucide-react";
import { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { getValidatorByPublicKeyBase58Check } from "../backend/api";
import type { ValidatorEntry } from "../backend/types";
import Spinner from "../components/shared/spinner";
import { ActiveAccountContext } from "../contexts/active-account";
import {
  VALIDATOR_REGISTRATION_ANNOUNCEMENTS_PASSWORD,
  VALIDATOR_REGISTRATION_ANNOUNCEMENTS_PASSWORD_KEY,
  VALIDATOR_REGISTRATION_DISCUSSION_PASSWORD,
  VALIDATOR_REGISTRATION_DISCUSSION_PASSWORD_KEY,
} from "../utils/constants";
import { basisPointsToPercent, percentToBasisPoints } from "../utils/currency";
import {
  CheckNodeStatusResponse,
  checkNodeStatus,
  handleIdentityClosedWithoutInteraction,
  pollForTxnFinalized,
} from "../utils/helpers";

interface ValidatorSettings {
  disableDelegatedStake?: boolean;
  delegatedStakeCommissionPercentage?: string;
  votingPublicKey?: string;
  votingAuthorization?: string;
}

const ValidatorSettingsPage = () => {
  const { account } = useContext(ActiveAccountContext);

  const [isLoading, setIsLoading] = useState(true);
  const [validatorEntry, setValidatorEntry] = useState<ValidatorEntry | null>(
    null,
  );

  const [domains, setDomains] = useState<string[]>([]);
  const [settings, setSettings] = useState<ValidatorSettings>({
    disableDelegatedStake: false,
  });
  const [addDomainError, setAddDomainError] = useState<string | null>(null);
  const [checkNodeStatusState, setCheckNodeStatusState] = useState<
    (CheckNodeStatusResponse | null)[]
  >([]);
  const [hasRegisteredValidator, setHasRegisteredValidator] = useState(false);
  const [isJailed, setIsJailed] = useState(false);
  const [isPendingTransaction, setIsPendingTransaction] = useState(false);
  const [showUnregisterModal, setShowUnregisterModal] = useState(false);
  const [announcementsPassword, setAnnouncementsPassword] = useState(
    localStorage.getItem(VALIDATOR_REGISTRATION_ANNOUNCEMENTS_PASSWORD_KEY) ||
      "",
  );
  const [discussionPassword, setDiscussionPassword] = useState(
    localStorage.getItem(VALIDATOR_REGISTRATION_DISCUSSION_PASSWORD_KEY) || "",
  );
  const [showCommissionIncreaseWarning, setShowCommissionIncreaseWarning] =
    useState(false);
  const { toast } = useToast();

  const fetchValidatorEntry = async (publicKey: string) => {
    setIsLoading(true);

    await getValidatorByPublicKeyBase58Check(publicKey)
      .then((data) => {
        setValidatorEntry(data);
        setIsLoading(false);
        setHasRegisteredValidator(true);
      })
      .catch((e) => {
        setValidatorEntry(null);
        setIsLoading(false);
        setHasRegisteredValidator(false);
      });
  };

  useEffect(() => {
    if (!account?.publicKey) {
      setIsLoading(false);
      return;
    }

    fetchValidatorEntry(account.publicKey);
  }, [account?.publicKey]);

  const resetFormData = () => {
    setDomains([]);
    setCheckNodeStatusState([]);
    setSettings({
      disableDelegatedStake: false,
      votingPublicKey: "",
      votingAuthorization: "",
      delegatedStakeCommissionPercentage: "",
    });
  };

  useEffect(() => {
    if (!validatorEntry) {
      // TODO: We should distinguish between a not found validator and an api error...
      // If no validator entry is found for the current user, we
      // set the state to the default values.
      resetFormData();
      return;
    }

    setIsJailed(validatorEntry.JailedAtEpochNumber !== 0);

    if (validatorEntry.Domains?.length) {
      const domains = validatorEntry.Domains.filter(
        (d): d is string => d !== null,
      );
      setDomains(domains);
      setCheckNodeStatusState(domains.map(() => null));
    }

    setSettings({
      disableDelegatedStake: validatorEntry.DisableDelegatedStake === true,
      delegatedStakeCommissionPercentage: basisPointsToPercent(
        validatorEntry.DelegatedStakeCommissionBasisPoints,
      ).toString(),
      votingPublicKey: validatorEntry.VotingPublicKey ?? "",
      votingAuthorization: validatorEntry.VotingAuthorization ?? "",
    });
  }, [validatorEntry]);

  const addDomain = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const form = e.target as HTMLFormElement;
    const formData = new FormData(form);
    const domain = ((formData.get("addDomain") ?? "") as string).replace(
      /^.*:\/\/?/,
      "",
    );

    // validation:
    // - check if it's a valid url
    // - check if it's a duplicate
    try {
      new URL(domain);
    } catch (e) {
      setAddDomainError(
        "Please enter a valid domain including the port number.",
      );
      return;
    }
    if (domains.includes(domain)) {
      setAddDomainError("Domain already exists.");
      return;
    }

    setDomains((domains) => [...domains, domain]);
    setCheckNodeStatusState((checkNodeStatusState) => [
      ...checkNodeStatusState,
      null,
    ]);
  };

  const getTestStatus = (i: number) => {
    if (i > checkNodeStatusState.length) {
      return <></>;
    }
    const status = checkNodeStatusState[i];
    if (!status) {
      return <></>;
    }
    if (status.pending) {
      return <Spinner size={14} />;
    }
    if (status.success) {
      return <SuccessTooltip text={"Success!"} iconSize={14} />;
    }
    if (status.error) {
      return <ErrorTooltip iconSize={14} text={status.error} />;
    }
    return <></>;
  };

  const executeRegisterAsValidator = async () => {
    if (!account?.publicKey) {
      toast({
        variant: "destructive",
        title: "Error",
        description: `Cannot register as a validator without being logged in.`,
      });
      return;
    }

    if (!domains.length) {
      toast({
        variant: "destructive",
        title: "Error",
        description: `No domains added.`,
      });
      return;
    }

    if (!settings.votingPublicKey) {
      toast({
        variant: "destructive",
        title: "Error",
        description: `A valid voting public key hex is required.`,
      });
      return;
    }

    if (!settings.votingAuthorization) {
      toast({
        variant: "destructive",
        title: "Error",
        description: `A valid voting authorization hex is required.`,
      });
      return;
    }

    if (
      announcementsPassword !== VALIDATOR_REGISTRATION_ANNOUNCEMENTS_PASSWORD
    ) {
      toast({
        variant: "destructive",
        title: "Error",
        description: `Please provide a valid announcements password.`,
      });
      return;
    }

    if (discussionPassword !== VALIDATOR_REGISTRATION_DISCUSSION_PASSWORD) {
      toast({
        variant: "destructive",
        title: "Error",
        description: `Please provide a valid discussion password.`,
      });
      return;
    }

    if (checkNodeStatusState.some((s) => !s?.success)) {
      toast({
        variant: "destructive",
        title: "Error",
        description: `A passing test is required for each domain added. Please click test for each domain and try again.`,
      });
      return;
    }

    setIsPendingTransaction(true);

    try {
      const params = {
        TransactorPublicKeyBase58Check: account.publicKey,
        Domains: domains,
        DisableDelegatedStake: !!settings.disableDelegatedStake,
        DelegatedStakeCommissionBasisPoints: percentToBasisPoints(
          Number(settings.delegatedStakeCommissionPercentage ?? 0),
        ),
        VotingPublicKey: settings.votingPublicKey,
        VotingAuthorization: settings.votingAuthorization,
      };

      // broadcast the tx
      const resp = await registerAsValidator(params, {
        localConstruction: true,
      });

      if (!resp.submittedTransactionResponse?.TxnHashHex) {
        throw new Error(
          "Problem submitting transaction. No transaction hash returned.",
        );
      }

      await pollForTxnFinalized(resp.submittedTransactionResponse?.TxnHashHex);

      await fetchValidatorEntry(account.publicKey);

      toast({
        variant: "default",
        title: "Success",
        duration: 2500,
        description: `The validator has been successfully registered.`,
      });
    } catch (e: any) {
      handleIdentityClosedWithoutInteraction(
        e,
        `There was a problem registering as a validator. ${e.message}`,
      );
    }
    setIsPendingTransaction(false);
  };

  if (isLoading || isPendingTransaction) {
    return (
      <main className="mt-4 container m-auto">
        <div className="w-full justify-between flex items-center">
          <h1 className="text-2xl text-black dark:text-white font-semibold">
            Validator Management
          </h1>
        </div>
        <div className="flex justify-center align-center items-center min-h-[100vh]">
          <Spinner size={64} />
        </div>
      </main>
    );
  }

  // TODO: handle api errors and show appropriate feedback in the UI

  return (
    <main className="mt-4 container m-auto">
      <div className="w-full justify-between flex items-center">
        <h1 className="text-2xl text-black dark:text-white font-semibold">
          Validator Management
        </h1>
      </div>
      <div className="mt-8">
        <div className="flex justify-between items-center">
          <h3 className="mb-4 flex items-center">
            <NetworkIcon className="mr-2" />
            Domain Management
          </h3>
        </div>
        <div className="border border-border rounded-2xl text-sm">
          <form
            onSubmit={addDomain}
            className="p-4 border-b border-border-light last:border-b-0"
          >
            <div className="flex md:items-center flex-col md:flex-row gap-4 md:gap-0">
              <h3 className="text-muted-foreground w-full md:max-w-[200px] flex items-center gap-2">
                Add Domain
                <InfoTooltip text={domainsTooltip} />
              </h3>
              <div className="flex md:items-center gap-4 md:gap-2 flex-col md:flex-row items-start">
                <div>
                  <Input
                    type="text"
                    name="addDomain"
                    placeholder="example.com:17000"
                    className="w-full md:w-[400px] rounded-full text-muted-foreground"
                    onInput={() => setAddDomainError(null)}
                  />
                  {addDomainError && (
                    <p className="text-red-500 pl-1 mt-2">{addDomainError}</p>
                  )}
                </div>
                <Button
                  variant="outline"
                  className="flex items-center gap-2"
                  type="submit"
                >
                  <PlusIcon className="w-4 h-4" />
                  Add Domain
                </Button>
              </div>
            </div>
          </form>
          {domains.map((domain, i) => (
            <div
              className="p-4 border-b border-border-light last:border-b-0"
              key={domain}
            >
              <div className="flex items-start md:items-center flex-col md:flex-row w-full gap-2 md:gap-0">
                <h3 className="text-muted-foreground w-full max-w-[200px]">
                  Domain #{i + 1}
                </h3>
                <div className="flex flex-col md:flex-row gap-4 md:gap-0 items-start md:items-center justify-between w-full">
                  {domain}
                  <div className="flex">
                    <Button
                      size="sm"
                      variant="outline"
                      className="flex items-center gap-2 mr-3"
                      onClick={() => {
                        setCheckNodeStatusState((checkNodeStatusState) => [
                          ...checkNodeStatusState.slice(0, i),
                          { pending: true },
                          ...checkNodeStatusState.slice(i + 1),
                        ]);
                        checkNodeStatus(domain).then((status) => {
                          if (status.error) {
                            toast({
                              variant: "destructive",
                              title: "Error",
                              description: status.error,
                            });
                          }
                          setCheckNodeStatusState((checkNodeStatusState) => [
                            ...checkNodeStatusState.slice(0, i),
                            status,
                            ...checkNodeStatusState.slice(i + 1),
                          ]);
                        });
                      }}
                    >
                      Test
                      {getTestStatus(i)}
                    </Button>
                    <Button
                      size="sm"
                      variant="outline"
                      className="flex items-center gap-2"
                      onClick={() => {
                        setDomains([
                          ...domains.slice(0, i),
                          ...domains.slice(i + 1),
                        ]);
                        setCheckNodeStatusState([
                          ...checkNodeStatusState.slice(0, i),
                          ...checkNodeStatusState.slice(i + 1),
                        ]);
                      }}
                    >
                      <XIcon className="w-4 h-4" />
                      Remove
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="mt-8">
        <div className="flex justify-between items-center">
          <h3 className="mb-4 flex items-center">
            <SettingsIcon className="mr-2" />
            Validator Settings
          </h3>
        </div>
        <div className="border border-border rounded-2xl text-sm">
          <div className="p-4 border-b border-border-light">
            <div className="flex items-start md:items-center flex-col md:flex-row w-full gap-4 md:gap-0">
              <h3 className="text-muted-foreground w-full max-w-[200px] flex items-center gap-2">
                Delegated Stake
                <InfoTooltip text={delegatedStakeTooltip} />
              </h3>
              <div className="flex items-center gap-2">
                <div className="items-top flex space-x-3">
                  <Checkbox
                    id="disable-delegated-stake"
                    checked={!settings.disableDelegatedStake}
                    onClick={() => {
                      setSettings((settings) => ({
                        ...settings,
                        disableDelegatedStake: !settings.disableDelegatedStake,
                      }));
                    }}
                  />
                  <div className="grid gap-1.5 leading-none">
                    <label
                      htmlFor="disable-delegated-stake"
                      className="text-sm font-medium leading-none text-muted-foreground"
                    >
                      Enable Delegated Stake
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="p-4 border-b border-border-light">
            <div className="flex items-start md:items-center flex-col md:flex-row w-full gap-4 md:gap-0">
              <h3
                className={`text-muted${
                  !settings.disableDelegatedStake ? "-foreground" : ""
                } w-full max-w-[200px] flex items-center gap-2`}
              >
                Commission Rate %
                <InfoTooltip text={commissionRateTooltip} />
              </h3>
              <div className="flex items-center justify-between w-full">
                <Input
                  type="number"
                  placeholder="0.00"
                  className="w-[100px] rounded-full text-muted-foreground"
                  disabled={settings.disableDelegatedStake}
                  value={settings.delegatedStakeCommissionPercentage}
                  onInput={(e) => {
                    const input = e.target as HTMLInputElement;
                    setSettings((settings) => ({
                      ...settings,
                      delegatedStakeCommissionPercentage: input.value,
                    }));
                  }}
                />
              </div>
            </div>
            <p className="text-sm text-muted mt-5">
              This only applies for validators with delegated stake.
            </p>
          </div>
          <div className="p-4 border-b border-border-light">
            <div className="flex items-start md:items-center flex-col md:flex-row w-full gap-4 md:gap-0">
              <h3 className="text-muted-foreground w-full max-w-[200px] flex items-center gap-2">
                Voting Public Key
                <InfoTooltip text={votingPublicKeyTooltip} />
              </h3>
              <div className="flex items-center justify-between w-full">
                <Input
                  type="text"
                  placeholder="Input Public Key"
                  className="w-[400px] rounded-full text-muted-foreground"
                  value={settings.votingPublicKey}
                  onInput={(e) => {
                    const input = e.target as HTMLInputElement;
                    setSettings((settings) => ({
                      ...settings,
                      votingPublicKey: input.value,
                    }));
                  }}
                />
              </div>
            </div>
          </div>
          <div className="p-4">
            <div className="flex items-start md:items-center flex-col md:flex-row w-full gap-4 md:gap-0">
              <h3 className="text-muted-foreground w-full max-w-[200px] flex items-center gap-2">
                Voting Authorization
                <InfoTooltip text={votingAuthorizationTooltip} />
              </h3>
              <div className="flex items-center justify-between w-full">
                <Input
                  type="text"
                  placeholder="Input Voting Authorization"
                  className="w-[400px] rounded-full text-muted-foreground"
                  value={settings.votingAuthorization}
                  onInput={(e) => {
                    const input = e.target as HTMLInputElement;
                    setSettings((settings) => ({
                      ...settings,
                      votingAuthorization: input.value,
                    }));
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="mt-8">
        <div className="flex justify-between items-center">
          <h3 className="mb-4 flex items-center">
            <SendIcon className="mr-2" />
            Join Our Telegram Channels
          </h3>
        </div>
        <div className="border border-border rounded-2xl text-sm">
          <div className="border-b border-border-light">
            <div className="flex items-start md:items-center flex-col md:flex-row w-full gap-4 md:gap-0 p-4">
              <h3 className="text-muted-foreground w-full max-w-[200px] flex items-center gap-2">
                Announcements Password
              </h3>
              <div className="flex items-center justify-between w-full">
                <Input
                  type="password"
                  placeholder="•••••"
                  className="w-[400px] rounded-full text-muted-foreground"
                  value={announcementsPassword}
                  onInput={(e) => {
                    const input = e.target as HTMLInputElement;
                    setAnnouncementsPassword(input.value);
                    localStorage.setItem(
                      VALIDATOR_REGISTRATION_ANNOUNCEMENTS_PASSWORD_KEY,
                      input.value,
                    );
                  }}
                />
              </div>
            </div>
            <div className="text-sm text-muted max-w-[80%] leading-6 p-4 pt-0">
              Join the{" "}
              <Link
                to={"https://t.me/deso_pos_announcements"}
                target="_blank"
                className="underline-offset-4 underline"
              >
                DeSo PoS Announcements
              </Link>{" "}
              Telegram Channel and turn on notifications for it. The password
              will be in the <strong>pinned message</strong> at the top of the
              channel. This is an announcements-only channel critical for
              keeping your validator up-to-date.
            </div>
          </div>
          <div className="flex items-start md:items-center flex-col md:flex-row w-full gap-4 md:gap-0 p-4">
            <h3 className="text-muted-foreground w-full max-w-[200px] flex items-center gap-2">
              Discussion Password
            </h3>
            <div className="flex items-center justify-between w-full">
              <Input
                type="password"
                placeholder="•••••"
                className="w-[400px] rounded-full text-muted-foreground"
                value={discussionPassword}
                onInput={(e) => {
                  const input = e.target as HTMLInputElement;
                  setDiscussionPassword(input.value);
                  localStorage.setItem(
                    VALIDATOR_REGISTRATION_DISCUSSION_PASSWORD_KEY,
                    input.value,
                  );
                }}
              />
            </div>
          </div>
          <div className="text-sm text-muted p-4 pt-0 max-w-[80%] leading-6">
            Join the{" "}
            <Link
              to="https://t.me/deso_pos_discussion"
              target="_blank"
              className="underline-offset-4 underline"
            >
              DeSo PoS Discussion
            </Link>{" "}
            Telegram Channel. The password will be in the{" "}
            <strong>pinned message</strong> at the top of the channel. This is a
            two-way channel where you can ask questions and discuss any issues.
          </div>
        </div>
      </div>
      <div className="my-8">
        <div className="flex justify-between items-center">
          <h3 className="mb-4 flex items-center">
            <RocketIcon className="mr-2" />
            Authorize
          </h3>
        </div>
        {(announcementsPassword !==
          VALIDATOR_REGISTRATION_ANNOUNCEMENTS_PASSWORD ||
          discussionPassword !== VALIDATOR_REGISTRATION_DISCUSSION_PASSWORD) &&
        !hasRegisteredValidator ? (
          <div className="mb-5">
            Please make sure to fill in the passwords from the Telegram channels
            to proceed.
          </div>
        ) : (
          <></>
        )}
        {!account && (
          <div className="flex gap-2 mb-4">
            <LucideInfo className=" w-4 h-4" />
            <p className="text-sm">
              You must{" "}
              <Button
                variant="link"
                className="p-0 h-auto"
                onClick={() => {
                  identity
                    .login()
                    .catch((e: any) =>
                      handleIdentityClosedWithoutInteraction(
                        e,
                        `An error occurred while trying to login. Please try again. ${e.message}`,
                      ),
                    );
                }}
              >
                login with a DeSo wallet
              </Button>{" "}
              to construct a validator registration transaction.
            </p>
          </div>
        )}
        <div className="flex flex-col md:flex-row md:items-center gap-4">
          <Button
            disabled={isPendingTransaction || !account || !!addDomainError}
            variant={"default"}
            onClick={async () => {
              const delegatedStakeBasisPoints = percentToBasisPoints(
                Number(settings.delegatedStakeCommissionPercentage ?? 0),
              );

              if (validatorEntry) {
                // if the user is attempting to increase the commission rate, we need to show them a warning.
                if (
                  delegatedStakeBasisPoints >
                  validatorEntry?.DelegatedStakeCommissionBasisPoints
                ) {
                  setShowCommissionIncreaseWarning(true);
                  return;
                }
              }

              executeRegisterAsValidator();
            }}
          >
            {hasRegisteredValidator
              ? "Update Registration"
              : "Register Validator"}
          </Button>
          {hasRegisteredValidator && (
            <Dialog
              open={showUnregisterModal}
              onOpenChange={setShowUnregisterModal}
            >
              <DialogTrigger asChild>
                <Button
                  variant={"outline"}
                  onClick={() => {
                    setShowUnregisterModal(true);
                  }}
                >
                  Unregister Validator
                </Button>
              </DialogTrigger>
              <DialogContent>
                <DialogHeader>
                  <DialogTitle>Confirm</DialogTitle>
                </DialogHeader>
                <div>
                  Unregistering as a validator will immediately unstake all DESO
                  that has been delegated to you. Are you sure you want to
                  unregister?
                </div>
                <Button
                  variant="outline"
                  onClick={() => setShowUnregisterModal(false)}
                >
                  Cancel
                </Button>
                <Button
                  variant={"default"}
                  onClick={async () => {
                    setShowUnregisterModal(false);

                    if (!account?.publicKey) {
                      toast({
                        variant: "destructive",
                        title: "Error",
                        description: `Cannot unregister as a validator without being logged in.`,
                      });
                      return;
                    }
                    if (!validatorEntry) {
                      toast({
                        variant: "destructive",
                        title: "Error",
                        description: `No registered validator. Cannot unregister.`,
                      });
                      return;
                    }
                    setIsPendingTransaction(true);
                    try {
                      const resp = await unRegisterAsValidator(
                        {
                          TransactorPublicKeyBase58Check: account.publicKey,
                        },
                        {
                          localConstruction: true,
                        },
                      );
                      if (!resp.submittedTransactionResponse?.TxnHashHex) {
                        throw new Error(
                          "Problem submitting transaction. No transaction hash returned.",
                        );
                      }
                      await pollForTxnFinalized(
                        resp.submittedTransactionResponse?.TxnHashHex,
                      );
                      await fetchValidatorEntry(account.publicKey);
                      toast({
                        variant: "default",
                        title: "Success",
                        duration: 2500,
                        description: `The validator has been successfully unregistered.`,
                      });
                    } catch (e: any) {
                      handleIdentityClosedWithoutInteraction(
                        e,
                        `There was a problem unregistering as a validator. ${e.message}`,
                      );
                    }
                    setIsPendingTransaction(false);
                  }}
                >
                  Unregister Validator
                </Button>
              </DialogContent>
            </Dialog>
          )}
          {isJailed && (
            <Button
              variant={"outline"}
              onClick={async () => {
                if (!account?.publicKey) {
                  toast({
                    variant: "destructive",
                    title: "Error",
                    description: `Cannot unjail a validator without being logged in.`,
                  });
                  return;
                }
                setIsPendingTransaction(true);
                try {
                  const resp = await unJailValidator(
                    {
                      TransactorPublicKeyBase58Check: account.publicKey,
                    },
                    {
                      localConstruction: true,
                    },
                  );

                  if (!resp.submittedTransactionResponse?.TxnHashHex) {
                    throw new Error(
                      "Problem submitting transaction. No transaction hash returned.",
                    );
                  }

                  await pollForTxnFinalized(
                    resp.submittedTransactionResponse?.TxnHashHex,
                  );

                  await fetchValidatorEntry(account.publicKey);

                  toast({
                    variant: "default",
                    title: "Success",
                    duration: 2500,
                    description: `The validator has been successfully unjailed.`,
                  });
                } catch (e: any) {
                  handleIdentityClosedWithoutInteraction(
                    e,
                    `There was a problem unjailing. ${e.message}`,
                  );
                }
                setIsPendingTransaction(false);
              }}
            >
              Unjail Validator
            </Button>
          )}
        </div>
      </div>
      <Dialog
        open={showCommissionIncreaseWarning}
        onOpenChange={setShowCommissionIncreaseWarning}
      >
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Warning: Fee history will be affected!</DialogTitle>
          </DialogHeader>
          <div>
            <p>
              Stakers will use the highest fee you've set from the last 90 days
              to make allocation decisions. If you increase your fee, it could
              "stick" with your profile for 90 days.{" "}
              <strong>Are you sure?</strong>
            </p>
          </div>
          <DialogFooter>
            <Button
              variant="default"
              onClick={() => {
                setShowCommissionIncreaseWarning(false);
                executeRegisterAsValidator();
              }}
            >
              Confirm
            </Button>
            <Button
              variant="ghost"
              onClick={() => setShowCommissionIncreaseWarning(false)}
            >
              Cancel
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </main>
  );
};

export default ValidatorSettingsPage;

const domainsTooltip = (
  <>
    <p>This is the list of domains that other validators</p>
    <p>in the network will use to connect to your validator.</p>
    <p>It should be a domain with a port and no scheme.</p>
    <p>For example, "example.com:17000".</p>
  </>
);

const delegatedStakeTooltip =
  "Delegated stake allows others to stake DESO with your validator.";

const commissionRateTooltip = (
  <>
    <p>The commission rate is a percentage you will receive</p>
    <p>from staking rewards earned from delegated staked.</p>
    <p>For example, if a user is going to receive 1 DESO</p>
    <p>as a staking reward and you have a commission rate</p>
    <p>of 10%, then the user will receive 0.9 DESO and</p>
    <p>you will receive 0.1 DESO.</p>
  </>
);
const votingPublicKeyTooltip = (
  <>
    <p>Your voting public key is a BLS public key</p>
    <p>derived from your BLS seed phrase.</p>
    <p>Other validators in the network will use your</p>
    <p>voting public key to verify your votes on blocks.</p>
    <p>To generate your voting public key, please</p>
    <p>
      use the{" "}
      <Link
        to="https://github.com/deso-protocol/validator-key-generator"
        target="_blank"
        className="underline-offset-4 underline"
      >
        validator key generator
      </Link>
      .
    </p>
  </>
);

const votingAuthorizationTooltip = (
  <>
    <p>Your voting authorization is a BLS signature</p>
    <p>of the DeSo public key of your validator.</p>
    <p>To generate your voting authorization, please</p>
    <p>
      use the{" "}
      <Link
        to="https://github.com/deso-protocol/validator-key-generator"
        target="_blank"
        className="underline-offset-4 underline"
      >
        validator key generator
      </Link>
      .
    </p>
  </>
);
