import * as React from "react";
import { Button } from "@/components/ui/button";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip";
import { CheckIcon, InfoIcon, XIcon } from "lucide-react";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import { useMobile } from "../../hooks/use-mobile";

interface InfoTooltipProps {
  triggerBtn?: JSX.Element | string;
  text: string | JSX.Element;
  iconSize?: number;
}

export const InfoTooltip = ({
  triggerBtn,
  text,
  iconSize = 20,
}: InfoTooltipProps) => {
  const { isMobile } = useMobile();

  const iconStyle = triggerBtn
    ? {}
    : {
        width: `${iconSize}px`,
        height: `${iconSize}px`,
      };

  const triggerButton = triggerBtn ?? (
    <Button size="tooltip" className="bg-transparent h-auto w-auto">
      <InfoIcon
        className="text-foreground dark:text-secondary hover:text-muted-foreground"
        style={iconStyle}
      />
    </Button>
  );

  return isMobile ? (
    <Popover>
      <PopoverTrigger style={iconStyle}>{triggerButton}</PopoverTrigger>
      <PopoverContent>{text}</PopoverContent>
    </Popover>
  ) : (
    <TooltipProvider>
      <Tooltip delayDuration={50}>
        <TooltipTrigger asChild style={iconStyle}>
          <div>{triggerButton}</div>
        </TooltipTrigger>

        <TooltipContent className="max-w-[400px]">{text}</TooltipContent>
      </Tooltip>
    </TooltipProvider>
  );
};

export const ErrorTooltip = ({
  triggerBtn,
  text,
  iconSize = 20,
}: InfoTooltipProps) => {
  const triggerButton = triggerBtn ?? (
    <Button size="tooltip" className="bg-transparent h-auto w-auto flex">
      <XIcon
        className="text-red-500 dark:text-red-500 hover:text-red-500"
        style={{ width: `${iconSize}px`, height: `${iconSize}px` }}
      />
    </Button>
  );
  return (
    <InfoTooltip text={text} triggerBtn={triggerButton} iconSize={iconSize} />
  );
};

export const SuccessTooltip = ({
  triggerBtn,
  text,
  iconSize = 20,
}: InfoTooltipProps) => {
  const triggerButton = triggerBtn ?? (
    <Button size="tooltip" className="bg-transparent h-auto w-auto flex">
      <CheckIcon
        className="text-green-500 dark:text-green-500 hover:text-green-500"
        style={{ width: `${iconSize}px`, height: `${iconSize}px` }}
      />
    </Button>
  );
  return (
    <InfoTooltip text={text} triggerBtn={triggerButton} iconSize={iconSize} />
  );
};
