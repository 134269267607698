import { DateRange } from "react-day-picker";

export enum TRANSACTION_TYPE {
  TxnTypeUnset = "TxnTypeUnset",
  TxnTypeBlockReward = "TxnTypeBlockReward",
  TxnTypeBasicTransfer = "TxnTypeBasicTransfer",
  TxnTypeBitcoinExchange = "TxnTypeBitcoinExchange",
  TxnTypePrivateMessage = "TxnTypePrivateMessage",
  TxnTypeSubmitPost = "TxnTypeSubmitPost",
  TxnTypeUpdateProfile = "TxnTypeUpdateProfile",
  TxnTypeUpdateBitcoinUSDExchangeRate = "TxnTypeUpdateBitcoinUSDExchangeRate",
  TxnTypeFollow = "TxnTypeFollow",
  TxnTypeLike = "TxnTypeLike",
  TxnTypeCreatorCoin = "TxnTypeCreatorCoin",
  TxnTypeSwapIdentity = "TxnTypeSwapIdentity",
  TxnTypeUpdateGlobalParams = "TxnTypeUpdateGlobalParams",
  TxnTypeCreatorCoinTransfer = "TxnTypeCreatorCoinTransfer",
  TxnTypeCreateNFT = "TxnTypeCreateNFT",
  TxnTypeUpdateNFT = "TxnTypeUpdateNFT",
  TxnTypeAcceptNFTBid = "TxnTypeAcceptNFTBid",
  TxnTypeNFTBid = "TxnTypeNFTBid",
  TxnTypeNFTTransfer = "TxnTypeNFTTransfer",
  TxnTypeAcceptNFTTransfer = "TxnTypeAcceptNFTTransfer",
  TxnTypeBurnNFT = "TxnTypeBurnNFT",
  TxnTypeAuthorizeDerivedKey = "TxnTypeAuthorizeDerivedKey",
  TxnTypeMessagingGroup = "TxnTypeMessagingGroup",
  TxnTypeDAOCoin = "TxnTypeDAOCoin",
  TxnTypeDAOCoinTransfer = "TxnTypeDAOCoinTransfer",
  TxnTypeDAOCoinLimitOrder = "TxnTypeDAOCoinLimitOrder",
  TxnTypeCreateUserAssociation = "TxnTypeCreateUserAssociation",
  TxnTypeDeleteUserAssociation = "TxnTypeDeleteUserAssociation",
  TxnTypeCreatePostAssociation = "TxnTypeCreatePostAssociation",
  TxnTypeDeletePostAssociation = "TxnTypeDeletePostAssociation",
  TxnTypeAccessGroup = "TxnTypeAccessGroup",
  TxnTypeAccessGroupMembers = "TxnTypeAccessGroupMembers",
  TxnTypeNewMessage = "TxnTypeNewMessage",
  TxnTypeRegisterAsValidator = "TxnTypeRegisterAsValidator",
  TxnTypeUnregisterAsValidator = "TxnTypeUnregisterAsValidator",
  TxnTypeStake = "TxnTypeStake",
  TxnTypeUnstake = "TxnTypeUnstake",
  TxnTypeUnlockStake = "TxnTypeUnlockStake",
  TxnTypeUnjailValidator = "TxnTypeUnjailValidator",
  TxnTypeCoinLockup = "TxnTypeCoinLockup",
  TxnTypeUpdateCoinLockupParams = "TxnTypeUpdateCoinLockupParams",
  TxnTypeCoinLockupTransfer = "TxnTypeCoinLockupTransfer",
  TxnTypeCoinUnlock = "TxnTypeCoinUnlock",
  TxnTypeAtomicTxnsWrapper = "TxnTypeAtomicTxnsWrapper",
}

export const TRANSACTION_TYPES_BY_INDEX = [
  TRANSACTION_TYPE.TxnTypeUnset,
  TRANSACTION_TYPE.TxnTypeBlockReward,
  TRANSACTION_TYPE.TxnTypeBasicTransfer,
  TRANSACTION_TYPE.TxnTypeBitcoinExchange,
  TRANSACTION_TYPE.TxnTypePrivateMessage,
  TRANSACTION_TYPE.TxnTypeSubmitPost,
  TRANSACTION_TYPE.TxnTypeUpdateProfile,
  "", // TransactionType = 7 is missing in core
  TRANSACTION_TYPE.TxnTypeUpdateBitcoinUSDExchangeRate,
  TRANSACTION_TYPE.TxnTypeFollow,
  TRANSACTION_TYPE.TxnTypeLike,
  TRANSACTION_TYPE.TxnTypeCreatorCoin,
  TRANSACTION_TYPE.TxnTypeSwapIdentity,
  TRANSACTION_TYPE.TxnTypeUpdateGlobalParams,
  TRANSACTION_TYPE.TxnTypeCreatorCoinTransfer,
  TRANSACTION_TYPE.TxnTypeCreateNFT,
  TRANSACTION_TYPE.TxnTypeUpdateNFT,
  TRANSACTION_TYPE.TxnTypeAcceptNFTBid,
  TRANSACTION_TYPE.TxnTypeNFTBid,
  TRANSACTION_TYPE.TxnTypeNFTTransfer,
  TRANSACTION_TYPE.TxnTypeAcceptNFTTransfer,
  TRANSACTION_TYPE.TxnTypeBurnNFT,
  TRANSACTION_TYPE.TxnTypeAuthorizeDerivedKey,
  TRANSACTION_TYPE.TxnTypeMessagingGroup,
  TRANSACTION_TYPE.TxnTypeDAOCoin,
  TRANSACTION_TYPE.TxnTypeDAOCoinTransfer,
  TRANSACTION_TYPE.TxnTypeDAOCoinLimitOrder,
  TRANSACTION_TYPE.TxnTypeCreateUserAssociation,
  TRANSACTION_TYPE.TxnTypeDeleteUserAssociation,
  TRANSACTION_TYPE.TxnTypeCreatePostAssociation,
  TRANSACTION_TYPE.TxnTypeDeletePostAssociation,
  TRANSACTION_TYPE.TxnTypeAccessGroup,
  TRANSACTION_TYPE.TxnTypeAccessGroupMembers,
  TRANSACTION_TYPE.TxnTypeNewMessage,
  TRANSACTION_TYPE.TxnTypeRegisterAsValidator,
  TRANSACTION_TYPE.TxnTypeUnregisterAsValidator,
  TRANSACTION_TYPE.TxnTypeStake,
  TRANSACTION_TYPE.TxnTypeUnstake,
  TRANSACTION_TYPE.TxnTypeUnlockStake,
  TRANSACTION_TYPE.TxnTypeUnjailValidator,
  TRANSACTION_TYPE.TxnTypeCoinLockup,
  TRANSACTION_TYPE.TxnTypeUpdateCoinLockupParams,
  TRANSACTION_TYPE.TxnTypeCoinLockupTransfer,
  TRANSACTION_TYPE.TxnTypeCoinUnlock,
  TRANSACTION_TYPE.TxnTypeAtomicTxnsWrapper,
];
export const TRANSACTION_TYPES_EXTENDED = [
  {
    type: TRANSACTION_TYPE.TxnTypeBasicTransfer,
    displayName: "BASIC_TRANSFER",
    desc: "User transfers any amount of DESO to another users wallet",
  },
  {
    type: TRANSACTION_TYPE.TxnTypePrivateMessage,
    displayName: "PRIVATE_MESSAGE",
    desc: "[Legacy] User sends an encrypted message to another user",
  },
  {
    type: TRANSACTION_TYPE.TxnTypeSubmitPost,
    displayName: "SUBMIT_POST",
    desc: "User submits a post that gets stored on the DeSo blockchain",
  },
  {
    type: TRANSACTION_TYPE.TxnTypeUpdateProfile,
    displayName: "UPDATE_PROFILE",
    desc: "User updates their profile",
  },
  {
    type: TRANSACTION_TYPE.TxnTypeFollow,
    displayName: "FOLLOW",
    desc: "User follows another user",
  },
  {
    type: TRANSACTION_TYPE.TxnTypeLike,
    displayName: "LIKE",
    desc: "User likes a post",
  },
  {
    type: TRANSACTION_TYPE.TxnTypeCreatorCoin,
    displayName: "CREATOR_COIN",
    desc: "User buys or sells a creator coin",
  },
  // {
  //   type: TRANSACTION_TYPE.TxnTypeSwapIdentity,
  //   displayName: "SWAP_IDENTITY",
  //   desc: "User transfers their username to another public key (wallet)",
  // },
  {
    type: TRANSACTION_TYPE.TxnTypeCreatorCoinTransfer,
    displayName: "CREATOR_COIN_TRANSFER",
    desc: "User transfers a creator coin to another users wallet",
  },
  {
    type: TRANSACTION_TYPE.TxnTypeCreateNFT,
    displayName: "CREATE_NFT",
    desc: "User creates an NFT (non-fungible token) on the DeSo blockchain",
  },
  {
    type: TRANSACTION_TYPE.TxnTypeUpdateNFT,
    displayName: "UPDATE_NFT",
    desc: "User updates an NFT on the DeSo blockchain",
  },
  {
    type: TRANSACTION_TYPE.TxnTypeAcceptNFTBid,
    displayName: "ACCEPT_NFT_BID",
    desc: "User accepts an NFT bid from an auction on the DeSo blockchain",
  },
  {
    type: TRANSACTION_TYPE.TxnTypeNFTBid,
    displayName: "NFT_BID",
    desc: "User places a bid on an NFT on the DeSo blockchain",
  },
  {
    type: TRANSACTION_TYPE.TxnTypeNFTTransfer,
    displayName: "NFT_TRANSFER",
    desc: "User transfers an NFT to another user",
  },
  {
    type: TRANSACTION_TYPE.TxnTypeAcceptNFTTransfer,
    displayName: "ACCEPT_NFT_TRANSFER",
    desc: "User accepts an NFT transfer from another user",
  },
  {
    type: TRANSACTION_TYPE.TxnTypeBurnNFT,
    displayName: "BURN_NFT",
    desc: "User burns their NFT",
  },
  {
    type: TRANSACTION_TYPE.TxnTypeAuthorizeDerivedKey,
    displayName: "AUTHORIZE_DERIVED_KEY",
    desc: "User authorizes a temporary derived key for granting permissions",
  },
  {
    type: TRANSACTION_TYPE.TxnTypeDAOCoin,
    displayName: "DAO_COIN",
    desc: "User mints, burns, disables or updates a DeSo token (DAO Coin) on the DeSo blockchain",
  },
  {
    type: TRANSACTION_TYPE.TxnTypeDAOCoinTransfer,
    displayName: "DAO_COIN_TRANSFER",
    desc: "User transfers a DeSo token (DAO Coin) to another user",
  },
  {
    type: TRANSACTION_TYPE.TxnTypeDAOCoinLimitOrder,
    displayName: "DAO_COIN_LIMIT_ORDER",
    desc: "User places a limit order to buy or sell a DeSo token",
  },
  {
    type: TRANSACTION_TYPE.TxnTypeCreateUserAssociation,
    displayName: "CREATE_USER_ASSOCIATION",
    desc: "User creates a relationship with another user",
  },
  {
    type: TRANSACTION_TYPE.TxnTypeDeleteUserAssociation,
    displayName: "DELETE_USER_ASSOCIATION",
    desc: "User removes their relationship with another user",
  },
  {
    type: TRANSACTION_TYPE.TxnTypeCreatePostAssociation,
    displayName: "CREATE_POST_ASSOCIATION",
    desc: "User creates a relationship with a post on the DeSo blockchain",
  },
  {
    type: TRANSACTION_TYPE.TxnTypeDeletePostAssociation,
    displayName: "DELETE_POST_ASSOCIATION",
    desc: "User removes a relationship with a post on the DeSo blockchain",
  },
  {
    type: TRANSACTION_TYPE.TxnTypeAccessGroup,
    displayName: "ACCESS_GROUP",
    desc: "User enables encrypted content",
  },
  {
    type: TRANSACTION_TYPE.TxnTypeAccessGroupMembers,
    displayName: "ACCESS_GROUP_MEMBERS",
    desc: "User enables permissions for encrypted content",
  },
  {
    type: TRANSACTION_TYPE.TxnTypeNewMessage,
    displayName: "NEW_MESSAGE",
    desc: "[New] User sends an encrypted message to another user",
  },
  {
    type: TRANSACTION_TYPE.TxnTypeRegisterAsValidator,
    displayName: "REGISTER_AS_VALIDATOR",
    desc: "User registers as a validator",
  },
  {
    type: TRANSACTION_TYPE.TxnTypeUnregisterAsValidator,
    displayName: "UNREGISTER_AS_VALIDATOR",
    desc: "User unregisters as a validator",
  },
  {
    type: TRANSACTION_TYPE.TxnTypeStake,
    displayName: "STAKE",
    desc: "User stakes DESO",
  },
  {
    type: TRANSACTION_TYPE.TxnTypeUnstake,
    displayName: "UNSTAKE",
    desc: "User unstakes DESO",
  },
  {
    type: TRANSACTION_TYPE.TxnTypeUnlockStake,
    displayName: "UNLOCK_STAKE",
    desc: "User unlocks DESO after unstaking", // TODO: how to simply convey this one.
  },
  {
    type: TRANSACTION_TYPE.TxnTypeUnjailValidator,
    displayName: "UNJAIL_VALIDATOR",
    desc: "User unjails their validator",
  },
  {
    type: TRANSACTION_TYPE.TxnTypeCoinLockup,
    displayName: "COIN_LOCKUP",
    desc: "User locks up a DeSo Token",
  },
  {
    type: TRANSACTION_TYPE.TxnTypeUpdateCoinLockupParams,
    displayName: "UPDATE_COIN_LOCKUP_PARAMS",
    desc: "User updates the parameters of their DeSo Token lockup",
  },
  {
    type: TRANSACTION_TYPE.TxnTypeCoinLockupTransfer,
    displayName: "COIN_LOCKUP_TRANSFER",
    desc: "User transfers a locked DeSo Token to another user",
  },
  {
    type: TRANSACTION_TYPE.TxnTypeCoinUnlock,
    displayName: "COIN_UNLOCK",
    desc: "User unlocks a locked DeSo Token",
  },
  {
    type: TRANSACTION_TYPE.TxnTypeAtomicTxnsWrapper,
    displayName: "ATOMIC_TXNS_WRAPPER",
    desc: "A series of transactions executed atomically",
  },
];

export interface TransactionFilters {
  date: DateRange;
  txnHash: string;
  blockHeight: string;
  txnTypes: Array<number>;
  transactorPublicKeys: Array<string>;
  affectedUsers: Array<string>;
}

export interface BlockFilters {
  date: DateRange;
  blockHeight: string;
  blockHash: string;
}

export interface LeaderboardUser<T> {
  publicKey: string;
  username: string;
  value: string;
  rawValue: T;
}
