import {
  desoNanosToDeso,
  desoNanosToUSD,
  formatDecimalValue,
  formatUSD,
} from "../../utils/currency";
import { useContext } from "react";
import { MarketDataContext } from "../../contexts/market-data";
import * as React from "react";
import { Skeleton } from "@/components/ui/skeleton";
import { cn } from "@/lib";

interface UserDesoBalanceProps {
  balanceNanos: string;
  loading?: boolean;
  className?: string;
  label?: string;
}

const UserDesoBalance = ({
  balanceNanos,
  loading = false,
  label,
  className = "text-sm",
}: UserDesoBalanceProps) => {
  const { exchangeRate, loading: loadingMarketData } =
    useContext(MarketDataContext);

  return loadingMarketData || !exchangeRate || loading ? (
    <Skeleton className="w-full mb-3 h-5" />
  ) : (
    <div className="flex items-center flex-rowgap-2 ">
      <div
        className={cn(
          `flex items-center mb-0 text-muted border rounded-full px-4 py-1`,
          className,
        )}
      >
        <div>
          {formatDecimalValue(desoNanosToDeso(balanceNanos || 0))}
          <span className="ml-1 text-xs">$DESO</span>

          <span className="mx-1"> ≈ </span>
          <span className="text-muted-foreground">
            {formatUSD(desoNanosToUSD(balanceNanos || 0, exchangeRate))}
            <span className="ml-1 text-xs">USD {label}</span>
          </span>
        </div>
      </div>
    </div>
  );
};

export default UserDesoBalance;
