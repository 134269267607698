import { ReactNode, useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";

const ScrollToTop = ({ children }: { children: ReactNode }) => {
  const location = useLocation();
  const prevPathnameRef = useRef<string | null>(null);

  useEffect(() => {
    if (prevPathnameRef.current !== location.pathname) {
      window.scrollTo(0, 0);
      prevPathnameRef.current = location.pathname;
    }
  }, [location]);

  return <>{children}</>;
};

export default ScrollToTop;
