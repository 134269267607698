import { Link } from "react-router-dom";
import { linkToDisplayApp } from "../../utils/helpers";

export const DiamondPostLink = ({
  postId,
  text = "post",
}: {
  postId: string;
  text?: string;
}) => {
  return (
    <Link
      to={`${linkToDisplayApp()}/posts/${postId}`}
      className="underline"
      target="_blank"
    >
      {text}
    </Link>
  );
};
