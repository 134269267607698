import { ArrowLeftRight, SearchIcon, ToyBrick } from "lucide-react";
import * as React from "react";
import { useCallback, useEffect, useState } from "react";
import { useLazyQuery } from "@apollo/client";
import {
  CoreAccountFieldsFragment,
  AccountsOrderBy,
  BlockSearchResultFragment,
  GlobalSearchDocument,
  TransactionSearchResultFragment,
  AccountByUsernameDocument,
} from "../../graphql/codegen/graphql";
import { client } from "../../graphql/client";
import { Command, CommandGroup, CommandItem, CommandList } from "../ui/command";
import DesoAvatar from "@/components/shared/deso-avatar";
import { Input } from "@/components/ui/input";
import debounce from "lodash/debounce";
import Spinner from "./spinner";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  concatUserResponses,
  formatDisplayName,
  formatTxnType,
  isBlockHash,
  isDesoPublicKey,
  shortenLongWord,
} from "../../utils/helpers";
import UserLink from "@/components/shared/user-link";

interface GlobalSearchProps {
  placeholder?: string;
}

enum SEARCH_ENTITY {
  USERNAME = "Username",
  PUB_KEY = "User Public Key",
  BLOCK_HASH = "Block hash",
  BLOCK_HEIGHT = "Block height",
  TRANSACTION_ID = "Transaction ID",
  TRANSACTION_HASH = "Transaction hash",
}

function validateInput(input: string): string[] {
  const matchedTypes: string[] = [];

  // Username: [a-zA-Z0-9_] from 1 to 26 characters
  if (/^[a-zA-Z0-9_]{1,26}$/.test(input))
    matchedTypes.push(SEARCH_ENTITY.USERNAME);

  // User public key: Starts with 'BC', length 55, base58 characters
  if (isDesoPublicKey(input)) matchedTypes.push(SEARCH_ENTITY.PUB_KEY);

  // Block hash: Hex string of length 64
  if (isBlockHash(input)) matchedTypes.push(SEARCH_ENTITY.BLOCK_HASH);

  // Block height: Positive integer
  if (/^\d+$/.test(input) && parseInt(input) >= 0)
    matchedTypes.push(SEARCH_ENTITY.BLOCK_HEIGHT);

  // Transaction ID: Starts with '3J', then 52 base58 characters
  if (/^3J[1-9A-HJ-NP-Za-km-z]{52}/.test(input))
    matchedTypes.push(SEARCH_ENTITY.TRANSACTION_ID);

  // Transaction hash: Hex string of length 64
  if (/^[0-9A-Fa-f]{64}$/.test(input))
    matchedTypes.push(SEARCH_ENTITY.TRANSACTION_HASH);

  return matchedTypes;
}

interface SearchResults {
  accounts: Array<CoreAccountFieldsFragment>;
  blocks: Array<BlockSearchResultFragment>;
  transactions: Array<TransactionSearchResultFragment>;
}

const getEmptySearchResults = (): SearchResults => {
  return {
    accounts: [],
    blocks: [],
    transactions: [],
  };
};

const GlobalSearch = ({
  placeholder = "Search any username, public key, txn hash...",
}: GlobalSearchProps) => {
  const [aborterRef, setAbortRef] = useState(new AbortController());

  const location = useLocation();
  const navigate = useNavigate();

  const [fetchSearchResultsLazy, { loading: loadingSearchResults }] =
    useLazyQuery(GlobalSearchDocument, {
      client,
      notifyOnNetworkStatusChange: true,
    });
  const [fetchAccountByUsername] = useLazyQuery(AccountByUsernameDocument, {
    client,
  });

  const [searchValue, setSearchValue] = useState<string>("");
  const [searchResults, setSearchResults] = useState<SearchResults>(
    getEmptySearchResults(),
  );

  const search = async (inputValue = "", abortController: AbortController) => {
    const value = inputValue && inputValue.trim();

    setSearchResults(getEmptySearchResults());

    const searchTypes = validateInput(value);

    const includeAccounts =
      searchTypes.includes(SEARCH_ENTITY.USERNAME) ||
      searchTypes.includes(SEARCH_ENTITY.PUB_KEY);
    const includeTransactions =
      searchTypes.includes(SEARCH_ENTITY.TRANSACTION_ID) ||
      searchTypes.includes(SEARCH_ENTITY.TRANSACTION_HASH);
    const includeBlocks =
      searchTypes.includes(SEARCH_ENTITY.BLOCK_HEIGHT) ||
      searchTypes.includes(SEARCH_ENTITY.BLOCK_HASH);

    const fetchSearchResultsRequest = fetchSearchResultsLazy({
      variables: {
        includeAccounts,
        includeTransactions,
        includeBlocks,
        accountsFilter: searchTypes.includes(SEARCH_ENTITY.USERNAME)
          ? {
              username: {
                likeInsensitive: `${value}%`,
              },
            }
          : {
              publicKey: {
                equalTo: value,
              },
            },
        orderBy: AccountsOrderBy.DesoLockedNanosDesc,
        blocksFilter: searchTypes.includes(SEARCH_ENTITY.BLOCK_HEIGHT)
          ? {
              height: {
                in: [value],
              },
            }
          : {
              blockHash: {
                in: [value],
              },
            },
        transactionsFilter: searchTypes.includes(SEARCH_ENTITY.TRANSACTION_HASH)
          ? {
              transactionHash: {
                in: [value],
              },
            }
          : {
              transactionId: {
                in: [value],
              },
            },
        first: 3,
      },
      context: {
        fetchOptions: {
          signal: abortController.signal,
        },
      },
    });

    const [{ data }, exactMatch] = await Promise.all([
      fetchSearchResultsRequest,
      searchTypes.includes(SEARCH_ENTITY.USERNAME)
        ? fetchAccountByUsername({
            variables: { username: value.trim() },
          })
        : Promise.resolve(null),
    ]);

    const allAccounts = concatUserResponses(
      exactMatch?.data?.accountByUsername,
      data?.accounts?.nodes,
    );

    setSearchResults({
      accounts: allAccounts,
      blocks: (data?.blocks?.nodes as Array<BlockSearchResultFragment>) || [],
      transactions:
        (data?.transactions?.nodes as Array<TransactionSearchResultFragment>) ||
        [],
    });
  };

  const searchDebounced = useCallback(
    debounce((v, a) => search(v, a), 400),
    [],
  );

  useEffect(() => {
    aborterRef.abort();

    const newAbortController = new AbortController();
    setAbortRef(newAbortController);

    searchDebounced(searchValue, newAbortController);
  }, [searchValue]);

  useEffect(() => {
    setSearchResults(getEmptySearchResults());
    setSearchValue("");
  }, [location]);

  return (
    <form className="flex relative items-center w-full">
      <Command shouldFilter={false} className="rounded-full text-left">
        <Input
          placeholder={placeholder}
          className="border border-border placeholder:text-muted rounded-full px-4 pr-16"
          value={searchValue}
          onInput={(event) => {
            setSearchValue((event.target as HTMLInputElement).value);
          }}
        />

        <CommandList>
          {loadingSearchResults && (
            <Command className="border rounded-xl">
              <Spinner size={32} className="mx-auto my-2" />
            </Command>
          )}

          {(searchResults.accounts.length > 0 ||
            searchResults.blocks.length > 0 ||
            searchResults.transactions.length > 0) && (
            <div className="border rounded-xl">
              {searchResults.accounts.length > 0 && (
                <CommandGroup heading="Users">
                  {searchResults.accounts.map(
                    (e: CoreAccountFieldsFragment) => {
                      const displayName = formatDisplayName(
                        e.username,
                        e.publicKey,
                        false,
                        false,
                      );

                      return (
                        <CommandItem
                          key={e.publicKey}
                          onSelect={() => {
                            navigate(`/u/${e?.username || e?.publicKey}`);
                          }}
                        >
                          <UserLink
                            username={e?.username || ""}
                            pubKey={e?.publicKey || ""}
                            className="flex items-center cursor-pointer px-2 py-1.5 w-full truncate"
                          >
                            <DesoAvatar
                              size={24}
                              publicKey={e?.publicKey || ""}
                            />

                            <span className="ml-2 w-full truncate">
                              {displayName}
                            </span>
                          </UserLink>
                        </CommandItem>
                      );
                    },
                  )}
                </CommandGroup>
              )}

              {searchResults.blocks.length > 0 && (
                <CommandGroup heading="Blocks">
                  {searchResults.blocks.map((e: BlockSearchResultFragment) => (
                    <CommandItem className="mx-1">
                      <Link
                        to={`/blocks/${e.height}`}
                        className="cursor-pointer px-2 py-1.5 w-full truncate"
                      >
                        <div className="flex items-center">
                          <ToyBrick />

                          <div className="ml-2">
                            <p className="text-xs mb-1">
                              <span className="text-muted">Height:</span>{" "}
                              <b>{e.height}</b>
                            </p>

                            <p className="text-xs">
                              <span className="text-muted">Hash:</span>{" "}
                              <b>{shortenLongWord(e.blockHash, 8, 8)}</b>
                            </p>
                          </div>
                        </div>
                      </Link>
                    </CommandItem>
                  ))}
                </CommandGroup>
              )}

              {searchResults.transactions.length > 0 && (
                <CommandGroup heading="Transactions">
                  {searchResults.transactions.map(
                    (e: TransactionSearchResultFragment) => (
                      <CommandItem className="mx-1">
                        <Link
                          to={`/txn/${e.transactionHash}`}
                          className="flex items-center cursor-pointer px-2 py-1.5 w-full truncate"
                        >
                          <div className="flex items-center">
                            <ArrowLeftRight />

                            <div className="ml-2">
                              <p className="text-xs mb-1">
                                <span className="text-muted">Type:</span>{" "}
                                <b>{formatTxnType(e.txnType)}</b>
                              </p>

                              <p className="text-xs">
                                <span className="text-muted">Hash:</span>{" "}
                                <b>
                                  {shortenLongWord(e.transactionHash, 8, 8)}
                                </b>
                              </p>
                            </div>
                          </div>
                        </Link>
                      </CommandItem>
                    ),
                  )}
                </CommandGroup>
              )}
            </div>
          )}
        </CommandList>
      </Command>

      <SearchIcon
        className="absolute right-4 w-4 h-4 text-muted-foreground cursor-pointer top-[12px]"
        onClick={() => search(searchValue, aborterRef)}
      />
    </form>
  );
};

export default GlobalSearch;
