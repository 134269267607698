import { Link } from "react-router-dom";

interface UserLinkProps {
  username?: string;
  pubKey: string;
  className?: string;
  children: JSX.Element | Array<JSX.Element> | string;
  linkType?: "user" | "validator";
}

const LINK_TYPES = {
  user: "/u/",
  validator: "/validator/",
};

const UserLink = ({
  username,
  pubKey,
  linkType = "user",
  className = "",
  children,
}: UserLinkProps) => {
  return (
    <Link
      to={`${LINK_TYPES[linkType]}${username || pubKey}`}
      className={className}
    >
      {children}
    </Link>
  );
};

export default UserLink;
