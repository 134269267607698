import { EyeIcon, EyeOffIcon } from "lucide-react";
import { Button } from "@/components/ui/button";

interface AdvancedFiltersToggleProps {
  open: boolean;
  setOpen: (state: boolean) => void;
}

const AdvancedFiltersToggle = ({
  open,
  setOpen,
}: AdvancedFiltersToggleProps) => {
  return (
    <Button variant="ghost" size="sm" onClick={() => setOpen(!open)}>
      {open ? (
        <>
          <EyeOffIcon className="mr-2" /> Hide
        </>
      ) : (
        <>
          <EyeIcon className="mr-2" /> Show
        </>
      )}
      <span className="ml-1 hidden md:block">Advanced Filters</span>
    </Button>
  );
};

export default AdvancedFiltersToggle;
