import { Link } from "react-router-dom";
import { MEMPOOL_LABEL } from "../../utils/constants";
import { ReactNode } from "react";

interface BlockLinkProps {
  height?: string;
  className?: string;
  children?: ReactNode;
}

const BlockLink = ({ height, className = "", children }: BlockLinkProps) => {
  return (
    <>
      {height && parseInt(height) >= 0 ? (
        <Link className={className} to={`/blocks/${height}`}>
          {children || height}
        </Link>
      ) : (
        <Link className={className} to={`/mempool`}>
          {children || MEMPOOL_LABEL}
        </Link>
      )}
    </>
  );
};

export default BlockLink;
