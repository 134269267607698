import * as React from "react";
import { SocialCard } from "@/components/shared/social-card";
import { InfoTooltip } from "@/components/shared/info-tooltip";
import { formatDecimalValue } from "../../utils/currency";
import { LeaderboardUser } from "src/types";

interface LeaderboardTableProps<T> {
  title: string;
  username: string;
  metric: string | JSX.Element;
  tooltip: string;
  currency?: string;
  noDataLabel: string;
  topUsers: Array<LeaderboardUser<T>>;
  minDecimal?: number;
  maxDecimal?: number;
  additionalColumns?: Array<{
    title: string;
    formatter: (e: LeaderboardUser<T>) => string | JSX.Element;
  }>;
}

export const LeaderboardTable = <T,>({
  title,
  username,
  metric,
  tooltip,
  noDataLabel,
  topUsers,
  currency,
  minDecimal = 0,
  maxDecimal = 2,
  additionalColumns = [],
}: LeaderboardTableProps<T>) => {
  return (
    <div>
      <div className="border rounded-2xl overflow-hidden">
        <h2 className="p-2 flex items-center justify-center border-b text-sm bg-card rounded-tl-2xl rounded-tr-2xl text-center">
          <span className="inline-block mr-2">{title}</span>
          {tooltip && <InfoTooltip text={tooltip} />}
        </h2>
        <div className="p-2 border-b flex justify-between text-xs">
          <div className="w-1/12 text-xs">#</div>
          <div className="w-7/12">{username}</div>
          <div className="w-4/12 text-right">{metric}</div>
          {additionalColumns?.map((e, i) => (
            <div className="w-4/12 text-right" key={i}>
              {e.title}
            </div>
          ))}
        </div>
        {topUsers.length > 0 ? (
          <div className="h-[330px] last-of-type:border-none">
            {topUsers.map((e, i) => (
              <LeaderboardTableRow
                user={e}
                key={e.publicKey}
                counter={i + 1}
                lastItem={i === topUsers.length - 1}
                minDecimal={minDecimal}
                maxDecimal={maxDecimal}
                additionalColumns={additionalColumns}
                currency={currency}
              />
            ))}
          </div>
        ) : (
          <div className="h-[330px] flex items-center justify-center text-center px-4">
            {noDataLabel}
          </div>
        )}
      </div>
    </div>
  );
};

interface LeaderboardTableRowProps<T> {
  user: LeaderboardUser<T>;
  counter: number;
  minDecimal: number;
  currency?: string;
  maxDecimal: number;
  additionalColumns?: Array<{
    title: string;
    formatter: (e: LeaderboardUser<T>) => string | JSX.Element;
  }>;
  lastItem: boolean;
}

const LeaderboardTableRow = <T,>({
  user,
  counter,
  currency,
  minDecimal,
  maxDecimal,
  additionalColumns = [],
  lastItem = false,
}: LeaderboardTableRowProps<T>) => (
  <div
    className={`min-h-[33px] px-2 border-border-light flex justify-between text-xs items-center ${
      !lastItem ? "border-b" : ""
    }`}
  >
    <div className="w-1/12 text-xs text-muted">{counter}</div>

    <div className="w-7/12 flex items-center">
      <SocialCard publicKey={user.publicKey || ""} showAvatar={true} />
    </div>

    <div className="w-4/12 text-right flex items-center justify-end">
      {formatDecimalValue(user.value, maxDecimal, minDecimal)}
      {currency === "DESO" && (
        <span>
          <img
            src="/assets/img/logo-deso-mark.svg"
            alt="DESO"
            className="h-[14px] ml-2 inline-block"
          />
        </span>
      )}
    </div>

    {additionalColumns?.map((col, i) => (
      <div className="w-4/12 text-right" key={i}>
        {col.formatter(user)}
      </div>
    ))}
  </div>
);
