import DOMPurify from "dompurify";

interface CodeHighlighterProps {
  json: string;
}

export const CodeHighlighter = ({ json }: CodeHighlighterProps) => {
  const jsonOutput = json
    .replace(/&/g, "&amp;")
    .replace(/</g, "&lt;")
    .replace(/>/g, "&gt;");

  const content = jsonOutput.replace(
    /("(\\u[a-zA-Z0-9]{4}|\\[^u]|[^\\"])*"(\s*:)?|\b(true|false|null)\b|-?\d+(?:\.\d*)?(?:[eE][+-]?\d+)?)/g,

    (match: string) => {
      let className = "number";
      if (/^"/.test(match)) {
        if (/:$/.test(match)) {
          className = "key";
        } else {
          className = "string";
        }
      } else if (/true|false/.test(match)) {
        className = "boolean";
      } else if (/null/.test(match)) {
        className = "null";
      }
      return `<span class="${className}">${match}</span>`;
    },
  );

  return (
    <div className="json-viewer relative group">
      <pre
        className="py-4 px-6 bg-gray-200 text-sm text-gray-700 w-full whitespace-pre-wrap break-words"
        dangerouslySetInnerHTML={{
          __html: DOMPurify.sanitize(content),
        }}
      ></pre>
    </div>
  );
};
