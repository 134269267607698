import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { Code2Icon, EyeIcon, PlayCircleIcon, SmileIcon } from "lucide-react";

export function VideoModal() {
  return (
    <Dialog>
      <DialogTrigger asChild>
        <div className="flex items-center text-sm text-center mt-4 justify-center">
          <span className="text-muted">Curious about DeSo?</span>
          <PlayCircleIcon className="ml-2 mr-2" />{" "}
          <Button variant="link" className="text-muted px-0">
            Watch this video
          </Button>
        </div>
      </DialogTrigger>

      <DialogContent className="max-w-[800px]">
        <DialogHeader>
          <DialogTitle>
            Learn about DeSo: The Social Layer of Crypto
          </DialogTitle>
        </DialogHeader>

        <Tabs defaultValue="brand" className="w-full">
          <TabsList>
            <TabsTrigger
              value="brand"
              className="flex flex-row items-center justify-center gap-2"
            >
              <EyeIcon /> For Visionaries
            </TabsTrigger>
            <TabsTrigger
              value="creators"
              className="flex flex-row items-center justify-center gap-2"
            >
              <SmileIcon /> For Creators
            </TabsTrigger>
            <TabsTrigger
              value="builders"
              className="flex flex-row items-center justify-center gap-2"
            >
              <Code2Icon /> For Builders
            </TabsTrigger>
          </TabsList>
          <TabsContent value="brand">
            <div className="grid gap-4 pt-2 pb-0">
              <iframe
                className="w-full h-[460px] border border-border-light rounded-lg border-solid"
                src="https://www.youtube.com/embed/kPPb_S5Ry5E?autoplay=1"
                title="Welcome to DeSo – The Social Layer of Crypto – Decentralized Social Media"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowFullScreen
              />
            </div>
          </TabsContent>
          <TabsContent value="creators">
            <div className="grid gap-4 pt-2 pb-0">
              <iframe
                className="w-full h-[460px] border border-border-light rounded-lg border-solid"
                src="https://www.youtube.com/embed/3XunCyyxt7A?autoplay=1"
                title="Welcome to DeSo – The Social Layer of Crypto – Decentralized Social Media"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowFullScreen
              />
            </div>
          </TabsContent>
          <TabsContent value="builders">
            <div className="grid gap-4 pt-2 pb-0">
              <iframe
                className="w-full h-[460px] border border-border-light rounded-lg border-solid"
                src="https://www.youtube.com/embed/q2FPbh6zBTg?autoplay=1"
                title="Welcome to DeSo – The Social Layer of Crypto – Decentralized Social Media"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowFullScreen
              />
            </div>
          </TabsContent>
        </Tabs>
      </DialogContent>
    </Dialog>
  );
}
