import { Loader2Icon } from "lucide-react";

interface SpinnerProps {
  size?: number;
  centered?: boolean;
  className?: string;
}

const Spinner = ({
  size = 16,
  centered = false,
  className = "",
}: SpinnerProps) => {
  return (
    <div
      className={
        centered ? `flex justify-center ${className}` : `inline ${className}`
      }
    >
      <Loader2Icon
        className="text-blue-200 animate-spin"
        style={{
          width: `${size}px`,
          height: `${size}px`,
        }}
      />
    </div>
  );
};

export default Spinner;
