import { createContext, useEffect, useState, ReactNode } from "react";
import { useLazyQuery } from "@apollo/client";
import {
  CoreAccountFieldsFragment,
  AccountByPublicKeyDocument,
} from "../graphql/codegen/graphql";
import { client } from "../graphql/client";
import { useDesoIdentity } from "../hooks/use-deso-identity";

export interface ActiveAccountContextType {
  account: CoreAccountFieldsFragment | null;
  accountLoading: boolean;
}

export const ActiveAccountContext = createContext<ActiveAccountContextType>({
  account: null,
  accountLoading: false,
});

export const ActiveAccountProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const { currentUser, pendingDerivedKeyAuthorization } = useDesoIdentity();
  const [getAccountByPublicKey, { loading }] = useLazyQuery(
    AccountByPublicKeyDocument,
    {
      client,
    },
  );

  const [activeAccountState, setActiveAccountState] =
    useState<ActiveAccountContextType>({
      account: null,
      accountLoading: false,
    });

  useEffect(() => {
    setActiveAccountState((prev) => ({
      ...prev,
      accountLoading: pendingDerivedKeyAuthorization || loading,
    }));
  }, [pendingDerivedKeyAuthorization, loading]);

  useEffect(() => {
    if (!currentUser?.publicKey) {
      setActiveAccountState((prev) => ({
        ...prev,
        account: null,
      }));
      return;
    }

    getAccountByPublicKey({
      variables: { publicKey: currentUser.publicKey },
    })
      .then((res) => {
        const account = res.data?.accountByPublicKey ?? null;
        setActiveAccountState((prev) => ({
          ...prev,
          account: account ?? {
            id: currentUser.publicKey,
            publicKey: currentUser.publicKey,
          },
        }));
      })
      .catch((e) => {
        setActiveAccountState((prev) => ({
          ...prev,
          account: null,
        }));
      });
  }, [currentUser?.publicKey, getAccountByPublicKey]);

  return (
    <ActiveAccountContext.Provider value={activeAccountState}>
      {children}
    </ActiveAccountContext.Provider>
  );
};
