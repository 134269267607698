import { InfoTooltip } from "@/components/shared/info-tooltip";
import JailedBadge from "@/components/shared/jailed-badge";
import { Progress } from "@/components/ui/progress";
import { GetExchangeRateResponse } from "deso-protocol";
import { LucideDollarSign, LucideInfo, Users2Icon } from "lucide-react";
import { useContext } from "react";
import { Link } from "react-router-dom";
import { ActiveAccountContext } from "../../contexts/active-account";
import {
  StakingOverviewFragment,
  ValidatorsTableRowFragment,
} from "../../graphql/codegen/graphql";
import { useTableScroll } from "../../hooks/use-table-scroll";
import {
  basisPointsToPercent,
  desoNanosToDeso,
  desoNanosToUSD,
  formatDecimalValue,
} from "../../utils/currency";
import { abbreviateNumber, shortenLongWord } from "../../utils/helpers";
import { Button } from "../ui/button";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../ui/table";
import StakingModal from "../validatorDetail/staking-modal";
import DesoAvatar from "./deso-avatar";
import { cn } from "@/lib";
import { EpochEntry } from "../../backend/types";

interface TableValidatorsProps {
  exchangeRates: GetExchangeRateResponse | null;
  validators: ValidatorsTableRowFragment[];
  stakingOverview: StakingOverviewFragment;
  currentEpochEntry: EpochEntry | undefined;
  onTransactionFinalized: () => Promise<any>;
  total: number | null;
  offset: number;
  perPage: number;
  loadingPage: boolean;
  onPrevPage: () => void;
  onNextPage: () => void;
  hasPrevPage?: boolean;
  hasNextPage?: boolean;
}

const TableValidators = ({
  exchangeRates,
  validators,
  currentEpochEntry,
  onTransactionFinalized,
  total,
  offset,
  perPage,
  loadingPage,
  onPrevPage,
  onNextPage,
  hasPrevPage,
  hasNextPage,
  stakingOverview,
}: TableValidatorsProps) => {
  const { wrapperElement, onPageChange } = useTableScroll();
  const { account } = useContext(ActiveAccountContext);
  const headers = [
    "Rank",
    "Validator",
    "Total Staked",
    "Total Stakers",
    "Commission",
    "90d Commission",
    "Last Active Epoch",
    "Percent Stake",
    "Action",
  ];

  return (
    <div className="w-full" ref={wrapperElement}>
      <div className="relative rounded-2xl border overflow-hidden">
        <Table>
          <TableHeader>
            <TableRow>
              {headers.map((name) => (
                <TableHead
                  key={name}
                  className="last:text-right whitespace-nowrap md:whitespace-normal"
                >
                  {name === "90d Commission" ? (
                    <InfoTooltip
                      text="Soon you'll be able to see the maximum commission this validator has charged in the last 90 days. This discourages validators from jacking up commissions in unexpected ways."
                      triggerBtn={
                        <span className="flex items-center gap-1">
                          {name} <LucideInfo className="h-4 w-4" />{" "}
                        </span>
                      }
                    />
                  ) : name === "Last Active Epoch" ? (
                    <InfoTooltip
                      text="Green means they were active in the current epoch, yellow means they have yet to vote in the current epoch, and red means they were jailed."
                      triggerBtn={
                        <span className="flex items-center gap-1">
                          {name} <LucideInfo className="h-4 w-4" />{" "}
                        </span>
                      }
                    />
                  ) : (
                    name
                  )}
                </TableHead>
              ))}
            </TableRow>
          </TableHeader>
          <TableBody>
            {validators.map((validator) => (
              <TableRow
                className="hover:bg-card dark:hover:bg-black/10 border-border-light"
                key={validator?.validatorPkid}
              >
                <TableCell>{validator?.validatorRank}</TableCell>
                <TableCell className="align-middle py-2 px-4 text-xs whitespace-nowrap">
                  <div className="flex items-start gap-3">
                    <DesoAvatar
                      size={40}
                      publicKey={
                        validator?.validatorEntry?.account?.publicKey ?? ""
                      }
                      username={
                        validator?.validatorEntry?.account?.username ?? ""
                      }
                      clickable={true}
                      linkType="validator"
                      className="mb-1 cursor-pointer border w-10 h-10 hover:border-secondary"
                    />
                    <div className="flex flex-col items-start gap-1">
                      <div className=" flex items-center gap-2">
                        <div className="text-sm text-muted-foreground hover:underline underline-offset-4">
                          <Link
                            to={`/validator/${
                              validator?.validatorEntry?.account?.username ??
                              validator?.validatorEntry?.account?.publicKey
                            }`}
                          >
                            {validator?.validatorEntry?.account?.username ?? ""}
                          </Link>
                        </div>
                        {validator?.validatorEntry?.jailedAtEpochNumber !==
                          "0" && (
                          <JailedBadge className="text-[10px] py-0 px-1.5" />
                        )}
                      </div>
                      <div className="text-xs text-muted font-mono">
                        {shortenLongWord(
                          validator?.validatorEntry?.account?.publicKey ?? "",
                          8,
                          8,
                        )}
                      </div>
                    </div>
                  </div>
                </TableCell>
                <TableCell>
                  <div className="flex flex-col items-end gap-1">
                    <div className="text-sm text-muted-foreground flex items-center gap-2">
                      {formatDecimalValue(
                        desoNanosToDeso(
                          validator?.validatorEntry?.totalStakeAmountNanos ?? 0,
                        ),
                      )}{" "}
                      <img
                        src="/assets/img/logo-deso-mark.svg"
                        alt="DESO"
                        className="h-[14px] inline-block"
                      />
                    </div>
                    {exchangeRates && (
                      <div className="text-xs text-muted flex items-center">
                        ~$
                        {abbreviateNumber(
                          desoNanosToUSD(
                            validator?.validatorEntry?.totalStakeAmountNanos ??
                              0,
                            exchangeRates.USDCentsPerDeSoCoinbase,
                          ),
                        )}{" "}
                        {/* NOTE: we just have this here to maintain the same space and keep the numbers aligned */}
                        <span className="inline-block ml-2 w-3 h-4 text-xs">
                          <LucideDollarSign className="h-4 w-4" />
                        </span>
                      </div>
                    )}
                  </div>
                </TableCell>
                <TableCell>
                  <div className="flex flex-col items-center gap-1">
                    <div className="text-sm text-muted-foreground flex items-center gap-2">
                      <Users2Icon className="w-5 h-5 text-muted" />
                      {validator?.validatorEntry?.stakeEntries?.totalCount}
                    </div>
                  </div>
                </TableCell>
                <TableCell>
                  <div className="flex flex-col items-start gap-1">
                    <div className="text-sm text-muted-foreground">
                      {basisPointsToPercent(
                        (validator.validatorEntry
                          ?.delegatedStakeCommissionBasisPoints ?? 0) / 100,
                      ).toLocaleString("en-US", {
                        style: "percent",
                        maximumFractionDigits: 2,
                      })}
                    </div>
                  </div>
                </TableCell>
                <TableCell>
                  <div className="flex flex-col items-start gap-1">
                    <div className="text-sm text-muted">Coming soon!</div>
                  </div>
                </TableCell>
                <TableCell>
                  <div className="flex flex-col items-start gap-1">
                    <div
                      className={cn(
                        "text-sm rounded-full px-2 py-1",
                        validator?.validatorEntry?.lastActiveAtEpochNumber ===
                          (currentEpochEntry?.EpochNumber?.toString() ||
                            stakingOverview?.currentEpochNumber)
                          ? "text-green-500 border border-green-500/40"
                          : validator?.validatorEntry?.jailedAtEpochNumber !==
                            "0"
                          ? "text-red-500 border border-red-500/40"
                          : "text-yellow-500 border border-yellow-500/40",
                      )}
                    >
                      {validator?.validatorEntry?.lastActiveAtEpochNumber}
                    </div>
                  </div>
                </TableCell>
                <TableCell>
                  <div className="flex flex-col gap-2">
                    <div className="text-sm text-muted-foreground w-full">
                      <Progress
                        value={(validator.percentTotalStake ?? 0) * 100}
                      />
                    </div>
                    <div className="text-xs text-muted font-mono self-end">
                      {(validator.percentTotalStake ?? 0)?.toLocaleString(
                        "en-US",
                        { style: "percent", maximumFractionDigits: 2 },
                      )}
                    </div>
                  </div>
                </TableCell>
                <TableCell>
                  <div className="flex justify-end gap-2 flex-col">
                    {validator?.validatorEntry?.account?.publicKey &&
                    account?.publicKey ? (
                      <>
                        <StakingModal
                          validatorPublicKey={
                            validator?.validatorEntry?.account?.publicKey
                          }
                          transactorPublicKey={account?.publicKey}
                          operation="stake"
                          buttonStyle="default"
                          onTransactionFinalized={() =>
                            onTransactionFinalized()
                          }
                        />
                        {Number(
                          validator?.validatorEntry?.viewerStake?.nodes?.[0]
                            ?.stakeAmountNanos ?? 0,
                        ) > 0 && (
                          <StakingModal
                            validatorPublicKey={
                              validator?.validatorEntry?.account?.publicKey
                            }
                            transactorPublicKey={account?.publicKey}
                            operation="unstake"
                            buttonStyle="secondary"
                            onTransactionFinalized={() =>
                              onTransactionFinalized()
                            }
                          />
                        )}
                      </>
                    ) : (
                      <Link
                        to={`/validator/${
                          validator?.validatorEntry?.account?.username ??
                          validator?.validatorEntry?.account?.publicKey
                        }`}
                      >
                        <Button
                          variant="outline"
                          size={"sm"}
                          className="text-sm flex items-center gap-2"
                        >
                          View
                        </Button>
                      </Link>
                    )}
                  </div>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>

      <div className="flex items-center justify-end space-x-2 py-4 flex-col md:flex-row gap-4 md:gap-0">
        <div className="flex-1 text-xs text-muted">
          Showing {offset + 1}-{offset + validators.length}
          {total !== null ? (
            <span> of {total.toLocaleString("en-US")} validators</span>
          ) : (
            " validators"
          )}
        </div>
        <div className="space-x-2">
          <Button
            variant="outline"
            size="sm"
            onClick={() => {
              onPrevPage();
              onPageChange();
            }}
            disabled={hasPrevPage !== undefined ? !hasPrevPage : offset === 0}
            className={loadingPage ? "pointer-events-none cursor-default" : ""}
          >
            Previous
          </Button>

          <Button
            variant="outline"
            size="sm"
            onClick={() => {
              onNextPage();
              onPageChange();
            }}
            disabled={
              hasNextPage !== undefined
                ? !hasNextPage
                : offset + perPage >= (total || 0)
            }
            className={loadingPage ? "pointer-events-none cursor-default" : ""}
          >
            Next
          </Button>
        </div>
      </div>
    </div>
  );
};

export default TableValidators;
