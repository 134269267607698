import Chart from "react-apexcharts";
import { formatDecimalValue } from "../../utils/currency";

interface PlotEarningsProps {
  series: Array<{ name: string; data: Array<number> }>;
  colorFrom: string;
}

const PlotEarnings = ({ series, colorFrom }: PlotEarningsProps) => {
  const dataSample = {
    series,
    options: {
      chart: {
        height: 350,
        toolbar: { show: false },
      },
      theme: {
        monochrome: {
          enabled: true,
          color: colorFrom,
          shadeTo: "light" as any,
          shadeIntensity: 1,
        },
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: "55%",
          endingShape: "rounded",
        },
      },
      dataLabels: {
        enabled: true,
        formatter: function (val: number) {
          return formatDecimalValue(val);
        },
      },
      stroke: {
        show: true,
        width: 2,
        colors: ["transparent"],
      },
      xaxis: {
        categories: ["Last 30 Days"],
      },
      yaxis: {
        title: {
          text: "USD",
        },
      },
      fill: {
        opacity: 1,
      },
      tooltip: {
        theme: "dark",
        y: {
          formatter(val: number) {
            return `$${val}`;
          },
        },
      },
    },
  };

  return (
    <div>
      <Chart
        options={dataSample.options}
        series={dataSample.series}
        type="bar"
        height="300"
      />
    </div>
  );
};

export default PlotEarnings;
