import DesoAvatar from "@/components/shared/deso-avatar";
import { SocialCard } from "@/components/shared/social-card";
import TableLockedStakeEntries, {
  AggregateLockedStakeTableRow,
} from "@/components/shared/table-locked-stake-entries";
import TableStakeEntries from "@/components/shared/table-stake-entries";
import TableValidators from "@/components/shared/table-validators";
import { Badge } from "@/components/ui/badge";
import { Button } from "@/components/ui/button";
import { Metric } from "@/components/ui/metric";
import { useLazyQuery, useQuery } from "@apollo/client";
import { GetExchangeRateResponse, getExchangeRates } from "deso-protocol";
import {
  AtomIcon,
  FlagIcon,
  LockIcon,
  ScrollTextIcon,
  TrophyIcon,
  User2Icon,
} from "lucide-react";
import { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { EpochEntry, UserInfoBasic } from "src/backend/types";
import { getCurrentEpochProgress } from "../backend/api";
import Spinner from "../components/shared/spinner";
import { useToast } from "../components/ui/use-toast";
import { ActiveAccountContext } from "../contexts/active-account";
import { client } from "../graphql/client";
import {
  GetStakingSummariesDocument,
  GetValidatorsDocument,
  GetValidatorsOverviewDocument,
  LockedStakeTableRowFragment,
  StakeTableRowFragment,
  StakingOverviewFragment,
  ValidatorsTableRowFragment,
} from "../graphql/codegen/graphql";
import {
  basisPointsToPercent,
  desoNanosToDeso,
  desoNanosToUSD,
  formatDecimalValue,
  formatUSD,
} from "../utils/currency";
import { getUnlockableEpochBoundary } from "../utils/helpers";
import { VideoTutorialModal } from "@/components/validatorDetail/video-tutorial";

const NUM_LEADERS_TO_DISPLAY = 6;
const PAGINATION_LIMIT = 50;

const ValidatorPage = () => {
  const [currentEpochCompletedPercentage, setCurrentEpochCompletedPercentage] =
    useState(0);
  const [currentEpochEntry, setCurrentEpochEntry] = useState<
    EpochEntry | undefined
  >(undefined);
  const [leadersForDisplay, setLeadersForDisplay] = useState<UserInfoBasic[]>(
    [],
  );
  const [exchangeRates, setExchangeRates] =
    useState<GetExchangeRateResponse | null>(null);

  useEffect(() => {
    getCurrentEpochProgress().then((res) => {
      // Set the current EpochEntry
      setCurrentEpochEntry(res.EpochEntry);

      // Compute the percentage of the current epoch that has been completed
      const epochPercentComplete =
        (res.CurrentTipHeight - res.EpochEntry.InitialBlockHeight) /
        (res.EpochEntry.FinalBlockHeight - res.EpochEntry.InitialBlockHeight);
      setCurrentEpochCompletedPercentage(epochPercentComplete);

      // Compute the number of timeouts during the current epoch
      const viewDiff = res.CurrentView - res.EpochEntry.InitialView;
      const blocHeightDiff =
        res.CurrentTipHeight - res.EpochEntry.InitialBlockHeight;

      const numTimeouts = viewDiff - blocHeightDiff;

      const currentLeaderIndex =
        (numTimeouts + res.EpochEntry.InitialLeaderIndexOffset) %
        res.LeaderSchedule.length;

      // Compute the list of upcoming leaders
      const doubleLengthLeaderSchedule = res.LeaderSchedule.concat(
        res.LeaderSchedule,
      );
      const currentAndUpcomingLeaders = doubleLengthLeaderSchedule.slice(
        currentLeaderIndex,
        currentLeaderIndex + NUM_LEADERS_TO_DISPLAY,
      );

      setLeadersForDisplay(currentAndUpcomingLeaders);

      return res;
    });
  }, []);

  const [validatorsTableOffset, setValidatorsTableOffset] = useState(0);

  const [getValidatorsOverview, { data, loading, refetch: refetchOverview }] =
    useLazyQuery(GetValidatorsOverviewDocument, {
      client,
    });

  const { data: stakingSummariesData, loading: stakingSummariesLoading } =
    useQuery(GetStakingSummariesDocument, {
      client,
      // update the overview/aggregate data every 5 seconds
      pollInterval: 5000,
    });

  const [
    getValidatorsList,
    { data: validatorsListData, loading: validatorsListLoading },
  ] = useLazyQuery(GetValidatorsDocument, { client });

  const { account } = useContext(ActiveAccountContext);
  const { toast } = useToast();

  useEffect(() => {
    getValidatorsOverview({
      variables: {
        viewerPublicKey: account?.publicKey ?? "",
      },
    });

    getValidatorsList({
      variables: {
        viewerPublicKey: account?.publicKey ?? "",
        first: PAGINATION_LIMIT,
        before: null,
        after: null,
        last: null,
      },
    });

    getExchangeRates().then(setExchangeRates);
    setLockedEntriesTableProps({
      currentPage: [],
      fullList: [],
      offset: 0,
    });
    setStakeEntriesTableProps({
      items: [],
      offset: 0,
    });
    setValidatorsTableOffset(0);
  }, [account?.publicKey]);

  const [stakeEntriesTableProps, setStakeEntriesTableProps] = useState<{
    items: StakeTableRowFragment[];
    offset: number;
  }>({
    items: [],
    offset: 0,
  });
  const [lockedEntriesTableProps, setLockedEntriesTableProps] = useState<{
    // locked stake is aggregated by validator. A user can click "View Details"
    // to see the individual locked stakes for a validator.
    currentPage: AggregateLockedStakeTableRow[];
    fullList: AggregateLockedStakeTableRow[];
    offset: number;
  }>({
    currentPage: [],
    fullList: [],
    offset: 0,
  });

  const stakingOverview = stakingSummariesData?.stakingSummaries?.nodes?.[0];
  const viewerTotalStakeDeso = desoNanosToDeso(
    data?.viewerOverview?.myStakeSummary?.totalStake ?? 0,
  );
  const viewerAvailableBalanceDeso = desoNanosToDeso(
    data?.viewerOverview?.desoBalance?.balanceNanos ?? 0,
  );
  const viewerTotalStakeRewardsDeso = desoNanosToDeso(
    data?.viewerOverview?.myStakeSummary?.totalStakeRewards ?? 0,
  );
  const unlockableEpochBoundary =
    (stakingOverview && stakingOverview.currentEpochNumber) ||
    (currentEpochEntry && currentEpochEntry.EpochNumber)
      ? getUnlockableEpochBoundary(
          currentEpochEntry?.EpochNumber || stakingOverview?.currentEpochNumber,
        )
      : null;

  useEffect(() => {
    if (data?.viewerOverview?.stakeEntries?.nodes?.length) {
      setStakeEntriesTableProps((prev) => ({
        items:
          prev.items.length > 0
            ? prev.items
            : (data.viewerOverview?.stakeEntries.nodes
                ?.filter(Boolean)
                .slice(0, PAGINATION_LIMIT) as StakeTableRowFragment[]),
        offset: prev.offset !== 0 ? prev.offset : 0,
      }));
    }
    if (
      data?.viewerOverview?.lockedStakeEntries?.nodes?.length &&
      unlockableEpochBoundary !== null
    ) {
      const groupMapping = new Map<string, AggregateLockedStakeTableRow>();
      data.viewerOverview.lockedStakeEntries.nodes.forEach((entry) => {
        if (!entry) return;

        const key = entry.validatorAccount?.publicKey;

        if (!key) return;

        if (groupMapping.has(key)) {
          const row = groupMapping.get(key);
          if (!row) {
            throw new Error("Row not found in groupMapping.");
          }
          row.lockedAmountNanos += Number(entry.lockedAmountNanos);
          row.unlockableAmountNanos +=
            BigInt(entry.lockedAtEpochNumber) < unlockableEpochBoundary
              ? Number(entry.lockedAmountNanos)
              : 0;
        } else {
          if (!entry.validatorAccount) {
            throw new Error("Validator not found in entry.");
          }
          groupMapping.set(key, {
            validator: entry.validatorAccount,
            jailedAtEpochNumber: entry.validatorEntry?.jailedAtEpochNumber,
            lockedAmountNanos: Number(entry.lockedAmountNanos),
            validatorRegistered: !!entry.validatorEntry,
            unlockableAmountNanos:
              BigInt(entry.lockedAtEpochNumber) < unlockableEpochBoundary
                ? Number(entry.lockedAmountNanos)
                : 0,
          });
        }
      });
      const groupedEntries = Array.from(groupMapping.values());
      // sort by locked nanos desc
      groupedEntries.sort((a, b) => b.lockedAmountNanos - a.lockedAmountNanos);
      setLockedEntriesTableProps((prev) => ({
        currentPage:
          prev.currentPage.length > 0
            ? prev.currentPage
            : groupedEntries.slice(0, PAGINATION_LIMIT),
        fullList: prev.fullList.length > 0 ? prev.fullList : groupedEntries,
        offset: prev.offset !== 0 ? prev.offset : 0,
      }));
    }
    // NOTE: this should also depend on unlockableEpochBoundary, but because it
    // gets updated via polling we don't want to include it here because it will
    // case the tables to re-render every few seconds and if the user is
    // paginating through the table it will cause the table to jump back to the
    // first page. So to keep things simple we just don't include it in the
    // dependencies array which seems like the least worst option.
  }, [data, unlockableEpochBoundary]);

  if (loading) {
    return <Spinner />;
  }

  if (!stakingSummariesLoading && !stakingOverview) {
    // TODO: We need a full page error screen if we don't have
    // any aggregate validator data.
    return <div>Error</div>;
  }

  return (
    <main className="mt-4 container m-auto">
      <div className="w-full justify-between flex items-center">
        <h1 className="text-2xl text-black dark:text-white font-semibold">
          Validators
        </h1>
        <div className="flex items-center gap-0 md:gap-2">
          <Link
            to={"https://docs.deso.org/deso-validators/run-a-validator"}
            target="_blank"
            rel="noreferrer"
          >
            <Button
              variant="link"
              className="text-foreground flex items-center gap-2"
            >
              <ScrollTextIcon className="w-4 h-4" />
              Docs
            </Button>
          </Link>
          <Link to={"/validator-settings"}>
            <Button variant="default" className="min-w-[100px]">
              Run a Validator
            </Button>
          </Link>
        </div>
      </div>
      {stakingOverview && (
        <div className="mt-8">
          <div className="flex justify-between items-center">
            <h3 className="mb-4 flex items-center">
              <AtomIcon className="mr-2" />
              Staking Overview
            </h3>
          </div>
          <div className="grid align-center grid-cols-1 md:grid-cols-2 xl:grid-cols-5 w-full gap-4">
            <div className="flex flex-col gap-2">
              {stakingSummariesLoading || !exchangeRates ? (
                <Spinner />
              ) : (
                <Metric
                  value={formatUSD(
                    desoNanosToUSD(
                      stakingOverview.globalStakeAmountNanos ?? 0,
                      exchangeRates.USDCentsPerDeSoCoinbase,
                    ),
                  )}
                  label="Total USD Staked"
                />
              )}
            </div>
            <div className="flex flex-col gap-2">
              {stakingSummariesLoading ? (
                <Spinner />
              ) : (
                <Metric
                  value={formatDecimalValue(
                    desoNanosToDeso(
                      stakingOverview.globalStakeAmountNanos ?? 0,
                    ),
                  )}
                  label="Total DESO Staked"
                />
              )}
            </div>
            <div className="flex flex-col gap-2">
              {stakingSummariesLoading ? (
                <Spinner />
              ) : (
                <Metric
                  value={BigInt(stakingOverview.numStakers).toLocaleString(
                    "en-US",
                  )}
                  label="Unique Wallets Staking"
                />
              )}
            </div>
            <div className="flex flex-col gap-2">
              <Metric
                value={`${basisPointsToPercent(
                  data?.globalParamsEntries?.nodes?.[0]
                    ?.stakingRewardsApyBasisPoints ?? 0,
                )}%`}
                label="APY%"
              />
            </div>
            <div className="flex flex-col gap-2">
              {stakingSummariesLoading ? (
                <Spinner />
              ) : (
                <Metric
                  value={BigInt(stakingOverview.numValidators).toLocaleString(
                    "en-US",
                  )}
                  label="Total Validators"
                  // TODO: we need to do geolocation for this
                  // caption="34 Countries"
                />
              )}
            </div>
          </div>
        </div>
      )}
      {data &&
        ((data?.viewerOverview?.stakeEntries?.nodes?.length ?? 0) > 0 ||
          (data?.viewerOverview?.lockedStakeEntries?.nodes?.length ?? 0) >
            0) && (
          <div className="mt-8 pb-8 border-b border-border-light">
            <div className="flex justify-between items-center">
              <h3 className="mb-4 flex items-center">
                <User2Icon className="mr-2" />
                My Stake Entries
              </h3>
            </div>
            <div className="grid align-center grid-cols-1 md:grid-cols-3 w-full gap-4">
              <div className="flex flex-col gap-2">
                <Metric
                  value={formatDecimalValue(
                    viewerTotalStakeDeso,
                    viewerTotalStakeDeso > 1 ? 2 : 9,
                  )}
                  caption="DESO"
                  label="My Staked Balance"
                />
              </div>
              <div className="flex flex-col gap-2">
                <Metric
                  value={formatDecimalValue(
                    viewerAvailableBalanceDeso,
                    viewerAvailableBalanceDeso > 1 ? 2 : 9,
                  )}
                  label="My Available Balance"
                  caption="DESO"
                />
              </div>
              <div className="flex flex-col gap-2">
                <Metric
                  value={formatDecimalValue(
                    viewerTotalStakeRewardsDeso,
                    viewerTotalStakeRewardsDeso > 1 ? 2 : 9,
                  )}
                  caption="DESO"
                  label="Lifetime Rewards"
                />
              </div>
            </div>
            {(data?.viewerOverview?.stakeEntries?.nodes?.length ?? 0) > 0 && (
              <div className="mt-6">
                <TableStakeEntries
                  stakeEntries={stakeEntriesTableProps.items}
                  onTransactionFinalized={() => refetchOverview()}
                  total={data?.viewerOverview?.stakeEntries?.totalCount ?? 0}
                  offset={stakeEntriesTableProps.offset}
                  perPage={PAGINATION_LIMIT}
                  loadingPage={false}
                  hasNextPage={
                    (Number(data?.viewerOverview?.stakeEntries?.totalCount) ??
                      0) >
                    stakeEntriesTableProps.offset + PAGINATION_LIMIT
                  }
                  hasPrevPage={stakeEntriesTableProps.offset > 0}
                  onPrevPage={function (): void {
                    const currentOffset = stakeEntriesTableProps.offset;
                    const newOffset = currentOffset - PAGINATION_LIMIT;
                    const newItems = data.viewerOverview?.stakeEntries?.nodes
                      ?.filter(Boolean)
                      ?.slice(
                        newOffset,
                        newOffset + PAGINATION_LIMIT,
                      ) as StakeTableRowFragment[];

                    setStakeEntriesTableProps({
                      items: newItems,
                      offset: newOffset,
                    });
                  }}
                  onNextPage={function (): void {
                    const currentOffset = stakeEntriesTableProps.offset;
                    const newOffset = currentOffset + PAGINATION_LIMIT;
                    const fullList =
                      data.viewerOverview?.stakeEntries?.nodes?.filter(
                        Boolean,
                      ) ?? ([] as StakeTableRowFragment[]);
                    const newItems = fullList.slice(
                      newOffset,
                      newOffset + PAGINATION_LIMIT,
                    ) as StakeTableRowFragment[];

                    setStakeEntriesTableProps((prev) => ({
                      ...prev,
                      items: newItems,
                      offset: newOffset,
                    }));
                  }}
                />
              </div>
            )}
            {(data?.viewerOverview?.lockedStakeEntries?.nodes?.length ?? 0) >
              0 && (
              <div className="mt-6">
                <div className="flex flex-col items-start gap-2 mb-4">
                  <h3 className="flex items-center">
                    <LockIcon className="mr-2" />
                    My Locked Stake Entries
                  </h3>
                  <p className="text-sm text-muted">
                    After unstaking, you'll need to{" "}
                    <strong className="text-muted-foreground">unlock</strong>{" "}
                    your locked stake to withdraw. Unlocking takes 2-3 hours.
                  </p>
                </div>
                <div className="mb-0">
                  {unlockableEpochBoundary !== null && stakingOverview && (
                    <TableLockedStakeEntries
                      currentEpochEntry={currentEpochEntry}
                      stakingOverview={stakingOverview}
                      aggregateRows={lockedEntriesTableProps.currentPage}
                      allLockedStakeEntries={
                        (data.viewerOverview?.lockedStakeEntries.nodes.filter(
                          Boolean,
                        ) as LockedStakeTableRowFragment[]) ?? []
                      }
                      unlockableEpochBoundary={Number(unlockableEpochBoundary)}
                      total={lockedEntriesTableProps.fullList.length}
                      hasNextPage={
                        (Number(lockedEntriesTableProps.fullList.length) ?? 0) >
                        lockedEntriesTableProps.offset + PAGINATION_LIMIT
                      }
                      hasPrevPage={lockedEntriesTableProps.offset > 0}
                      onPrevPage={() => {
                        const currentOffset = lockedEntriesTableProps.offset;
                        const newOffset = currentOffset - PAGINATION_LIMIT;
                        const newItems = lockedEntriesTableProps.fullList.slice(
                          newOffset,
                          newOffset + PAGINATION_LIMIT,
                        ) as AggregateLockedStakeTableRow[];

                        setLockedEntriesTableProps((prev) => ({
                          ...prev,
                          currentPage: newItems,
                          offset: newOffset,
                        }));
                      }}
                      onNextPage={() => {
                        const currentOffset = lockedEntriesTableProps.offset;
                        const newOffset = currentOffset + PAGINATION_LIMIT;
                        const newItems = lockedEntriesTableProps.fullList.slice(
                          newOffset,
                          newOffset + PAGINATION_LIMIT,
                        ) as AggregateLockedStakeTableRow[];

                        setLockedEntriesTableProps((prev) => ({
                          ...prev,
                          currentPage: newItems,
                          offset: newOffset,
                        }));
                      }}
                      refetchStakingOverview={async () => refetchOverview()}
                      onUnlockSuccess={async () => {
                        toast({
                          variant: "default",
                          title: "Success",
                          duration: 2500,
                          description:
                            "Your locked stake has been successfully transferred to your available balance.",
                        });
                      }}
                      offset={lockedEntriesTableProps.offset}
                      perPage={PAGINATION_LIMIT}
                      loadingPage={false}
                    />
                  )}
                </div>
              </div>
            )}
          </div>
        )}
      <div className="flex flex-col md:flex-row justify-between items-center pt-6">
        <h3 className="mb-4 flex items-center">
          <TrophyIcon className="mr-2" />
          Current Leader Schedule
        </h3>
        <div className="">
          <h3 className="mb-4 flex flex-col-reverse md:flex-row items-center gap-2">
            <div className="flex flex-col gap-0 text-right">
              <span className="font-bold text-sm text-muted-foreground">
                Current Epoch
              </span>
              <span className="text-muted text-xs">
                {currentEpochCompletedPercentage.toLocaleString("en-US", {
                  style: "percent",
                  maximumFractionDigits: 2,
                })}{" "}
                Completed
              </span>
            </div>
            <Badge
              variant="outline"
              className="border-green-600 text-green-600 text-md"
            >
              {BigInt(currentEpochEntry?.EpochNumber ?? 0).toLocaleString(
                "en-US",
              )}
            </Badge>
          </h3>
        </div>
      </div>
      <div className="md:border md:p-4 rounded-2xl mb-4 w-full">
        <div className="flex flex-col justify-center">
          <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-6 gap-4">
            {leadersForDisplay.map((leader, leaderIdx) => {
              let cardClassName =
                "flex flex-col items-center border border-border-light p-4 rounded-xl";
              if (leaderIdx === 0) {
                cardClassName += " bg-card";
              }
              return (
                <div
                  className={cardClassName}
                  key={leader.PublicKeyBase58Check}
                >
                  <DesoAvatar
                    size={40}
                    publicKey={leader.PublicKeyBase58Check ?? ""}
                    username={leader.Username ?? ""}
                    clickable={true}
                    linkType="validator"
                    className="mb-1 cursor-pointer border w-10 h-10 hover:border-secondary"
                  />
                  <div className="w-full h-8 flex justify-center items-center">
                    <SocialCard
                      linkType="validator"
                      publicKey={leader.PublicKeyBase58Check ?? ""}
                      size="sm"
                    />
                  </div>
                  {leaderIdx === 0 ? (
                    <p className="text-xs text-center opacity-70">
                      Current Leader
                    </p>
                  ) : (
                    <p className="text-xs text-center opacity-70">Upcoming</p>
                  )}
                  {/* Should be Current Leader > Upcoming > In Line */}
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <div className="text-center w-full mb-6 mt-8 bg-card rounded-2xl py-4 md:p-2">
        <VideoTutorialModal />
      </div>
      <div className="flex justify-between items-center pt-6">
        <h3 className="mb-4 flex items-center">
          <FlagIcon className="mr-2" />
          Validators
        </h3>
      </div>
      <div>
        <TableValidators
          exchangeRates={exchangeRates}
          currentEpochEntry={currentEpochEntry}
          stakingOverview={stakingOverview as StakingOverviewFragment}
          validators={
            (validatorsListData?.validatorStats?.nodes.filter(Boolean) ??
              []) as ValidatorsTableRowFragment[]
          }
          onTransactionFinalized={() => refetchOverview()}
          total={validatorsListData?.validatorStats?.totalCount ?? 0}
          offset={validatorsTableOffset}
          perPage={PAGINATION_LIMIT}
          hasNextPage={validatorsListData?.validatorStats?.pageInfo.hasNextPage}
          hasPrevPage={
            validatorsListData?.validatorStats?.pageInfo.hasPreviousPage
          }
          loadingPage={validatorsListLoading}
          onPrevPage={async () => {
            await getValidatorsList({
              variables: {
                viewerPublicKey: account?.publicKey ?? "",
                after: null,
                first: null,
                last: PAGINATION_LIMIT,
                before:
                  validatorsListData?.validatorStats?.pageInfo.startCursor ??
                  null,
              },
            });
            setValidatorsTableOffset((prev) => prev - PAGINATION_LIMIT);
          }}
          onNextPage={async () => {
            await getValidatorsList({
              variables: {
                viewerPublicKey: account?.publicKey ?? "",
                before: null,
                last: null,
                first: PAGINATION_LIMIT,
                after:
                  validatorsListData?.validatorStats?.pageInfo.endCursor ??
                  null,
              },
            });
            setValidatorsTableOffset((prev) => prev + PAGINATION_LIMIT);
          }}
        />
      </div>
    </main>
  );
};

export default ValidatorPage;
