import { GemIcon } from "lucide-react";
import AccumulatedEarnings from "@/components/userDetail/accumulated-earnings";
import EarningsBreakdown from "@/components/userDetail/earnings-breakdown";

interface UserEarningsProps {
  publicKey: string;
}

const UserEarnings = ({ publicKey }: UserEarningsProps) => {
  return (
    <div>
      <div className="flex justify-between items-center">
        <h3 className="mb-4 flex items-center">
          <GemIcon className="mr-2" />
          Financial Activity
        </h3>
      </div>

      <div className="border border-b border-b-border-light rounded-2xl">
        <AccumulatedEarnings publicKey={publicKey} />

        <EarningsBreakdown publicKey={publicKey} />
      </div>
    </div>
  );
};

export default UserEarnings;
