import { useRef } from "react";

export const useTableScroll = () => {
  const wrapperElement = useRef<HTMLDivElement>(null);

  const onPageChange = () => {
    wrapperElement?.current?.scrollIntoView({ behavior: "smooth" });
  };

  return {
    wrapperElement,
    onPageChange,
  };
};
