// Keeping partial object from CoinGecko for simplicity.
// The real response has a lot more info on it
import axios from "axios";
import { COINGECKO_API } from "./constants";

export interface CoinGeckoMarketData {
  circulating_supply: number;
  current_price: {
    usd: number;
  };
  total_supply: number;
  market_cap: {
    usd: number;
  };
  total_volume: {
    usd: number;
  };
  price_change_percentage_24h: number;
  market_cap_change_percentage_24h: number;
}

export async function getDesoMarketData(): Promise<{
  market_data: CoinGeckoMarketData;
}> {
  const { data } = await axios.get(`${COINGECKO_API}/coins/deso`);
  return data;
}
