import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import { MEMPOOL_AGE } from "src/utils/constants";
import { InfoTooltip } from "@/components/shared/info-tooltip";

dayjs.extend(relativeTime);

interface TableTimestampFormatterProps {
  timestamp: number | null;
}

const shortenTimeUnits = (fromNow: string) => {
  return fromNow
    .replaceAll("second", "sec")
    .replaceAll("minute", "min")
    .replaceAll("hour", "hr")
    .replaceAll("year", "yr");
};

export const TableTimestampFormatter = ({
  timestamp,
}: TableTimestampFormatterProps) => {
  let formattedTime = "";
  let formattedTooltip = "";

  if (timestamp) {
    const date = dayjs(timestamp);

    if (dayjs().unix() - date.unix() <= 60 * 60 * 24) {
      // if less than 24h ago
      formattedTime = shortenTimeUnits(date.fromNow());
      formattedTooltip = date.format("MM-DD-YYYY @ hh:mm:ss A");
    } else {
      formattedTime = date.format("MM-DD-YYYY @ hh:mm:ss A");
    }
  } else {
    formattedTime = MEMPOOL_AGE;
  }

  return (
    <InfoTooltip
      text={formattedTooltip || formattedTime}
      triggerBtn={formattedTime}
    />
  );
};
