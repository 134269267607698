import {
  CreditCard,
  LogOut,
  MessageSquare,
  NetworkIcon,
  User,
  UserPlus,
  ZapIcon,
} from "lucide-react";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuPortal,
  DropdownMenuSeparator,
  DropdownMenuSub,
  DropdownMenuSubContent,
  DropdownMenuSubTrigger,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { identity } from "deso-protocol";
import { useContext, useEffect, useState } from "react";
import { ActiveAccountContext } from "../../contexts/active-account";
import DesoAvatar from "@/components/shared/deso-avatar";
import { Link, useNavigate } from "react-router-dom";
import { useDesoIdentity } from "../../hooks/use-deso-identity";
import { useLazyQuery } from "@apollo/client";
import {
  Account,
  AccountByPublicKeyDocument,
} from "../../graphql/codegen/graphql";
import { client } from "../../graphql/client";
import {
  formatDisplayName,
  handleIdentityClosedWithoutInteraction,
  isTestnet,
} from "../../utils/helpers";
import { DIAMOND_APP_URL } from "../../utils/constants";
import WalletLink from "@/components/shared/wallet-link";

export function UserDropdown() {
  const navigate = useNavigate();

  const [fetchAccountLazy] = useLazyQuery(AccountByPublicKeyDocument, {
    client,
  });

  const { account } = useContext(ActiveAccountContext);
  const { alternateUsers } = useDesoIdentity();

  const [otherAccounts, setOtherAccounts] = useState<Array<Account>>([]);

  const displayName = formatDisplayName(account?.username, account?.publicKey);

  useEffect(() => {
    const alternateUserKeys = Object.keys(alternateUsers || {});

    if (!alternateUserKeys.length) {
      setOtherAccounts([]);
    }

    Promise.all(
      alternateUserKeys.map((e) =>
        fetchAccountLazy({
          variables: {
            publicKey: e,
          },
        }),
      ),
    ).then((res) => {
      const accounts = res
        .map((e) => e.data?.accountByPublicKey)
        .filter((e) => e !== undefined) as Array<Account>;

      setOtherAccounts(accounts);
    });
  }, [alternateUsers]);

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <section>
          <DesoAvatar
            className="cursor-pointer border hover:border-secondary"
            publicKey={account?.publicKey || ""}
            username={displayName}
            size={40}
          />
        </section>
      </DropdownMenuTrigger>

      <DropdownMenuContent className="w-56">
        <DropdownMenuLabel className="text-muted">
          <span className="font-base text-xs">Logged in as:</span>
          <br />
          <span className="text-muted-foreground">
            {account?.username ? `@${account.username}` : displayName}
          </span>
        </DropdownMenuLabel>

        <DropdownMenuSeparator />

        <DropdownMenuGroup>
          {account?.username && (
            <>
              <DropdownMenuItem
                onClick={() => {
                  const path = `${DIAMOND_APP_URL}/u/${account.username}`;
                  window.open(path, "_blank");
                }}
              >
                <User className="mr-2 h-4 w-4" />
                <span>My Profile</span>
              </DropdownMenuItem>
            </>
          )}

          <DropdownMenuItem
            onClick={() => {
              const path = `/u/${account?.username ?? account?.publicKey}`;
              navigate(path);
            }}
          >
            <ZapIcon className="mr-2 h-4 w-4" />
            <span>My Activity</span>
          </DropdownMenuItem>

          <DropdownMenuItem className="p-0">
            <WalletLink
              user={account}
              className="flex items-center px-2 py-1.5 w-full"
            >
              <CreditCard className="mr-2 h-4 w-4" />
              <span>My Wallet</span>
            </WalletLink>
          </DropdownMenuItem>

          <DropdownMenuItem className="p-0">
            <Link
              to="https://chat.deso.com"
              target="_blank"
              className="flex items-center px-2 py-1.5 w-full"
            >
              <MessageSquare className="mr-2 h-4 w-4" />
              <span>My Messages</span>
            </Link>
          </DropdownMenuItem>
        </DropdownMenuGroup>

        <DropdownMenuSeparator />

        <DropdownMenuGroup>
          <DropdownMenuSub>
            <DropdownMenuSubTrigger>
              <UserPlus className="mr-2 h-4 w-4" />
              <span>Switch Wallets</span>
            </DropdownMenuSubTrigger>
            <DropdownMenuPortal>
              <DropdownMenuSubContent>
                <DropdownMenuItem
                  onClick={() =>
                    identity
                      .login()
                      .catch((e: any) =>
                        handleIdentityClosedWithoutInteraction(
                          e,
                          "An error occurred while trying to login. Please try again.",
                        ),
                      )
                  }
                >
                  <User className="mr-2 h-4 w-4" />
                  <span>Add account</span>
                </DropdownMenuItem>

                {otherAccounts.length > 0 && (
                  <>
                    <DropdownMenuSeparator />

                    {otherAccounts.map((e, index) => {
                      const displayName = formatDisplayName(
                        e?.username,
                        e?.publicKey,
                      );

                      return (
                        <DropdownMenuItem
                          key={`user_dropdown_${index}`}
                          onClick={() => identity.setActiveUser(e?.publicKey)}
                        >
                          <DesoAvatar
                            publicKey={e?.publicKey}
                            username={displayName}
                          />

                          <span className="ml-2">{displayName}</span>
                        </DropdownMenuItem>
                      );
                    })}
                  </>
                )}
              </DropdownMenuSubContent>
            </DropdownMenuPortal>
          </DropdownMenuSub>
        </DropdownMenuGroup>

        <DropdownMenuSeparator />

        <DropdownMenuGroup>
          <DropdownMenuSub>
            <DropdownMenuSubTrigger>
              <NetworkIcon className="mr-2 h-4 w-4" />
              <span>Switch Networks</span>
            </DropdownMenuSubTrigger>
            <DropdownMenuPortal>
              <DropdownMenuSubContent>
                <DropdownMenuItem asChild>
                  {!isTestnet() ? (
                    <a
                      href="https://explorer-testnet.deso.com"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <NetworkIcon className="mr-2 h-4 w-4" />
                      <span>DeSo Testnet</span>
                    </a>
                  ) : (
                    <a
                      href="https://explorer.deso.com"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <NetworkIcon className="mr-2 h-4 w-4" />
                      <span>DeSo Mainnet</span>
                    </a>
                  )}
                </DropdownMenuItem>
              </DropdownMenuSubContent>
            </DropdownMenuPortal>
          </DropdownMenuSub>
        </DropdownMenuGroup>

        <DropdownMenuSeparator />

        <DropdownMenuItem
          onClick={() =>
            identity
              .logout()
              .catch((e) =>
                handleIdentityClosedWithoutInteraction(
                  e,
                  `An error occurred while trying to logout. Please try again. ${e.message}`,
                ),
              )
          }
        >
          <LogOut className="mr-2 h-4 w-4" />
          <span>Log out</span>
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  );
}
