import DesoBlockhainMetrics from "@/components/home/deso-blockhain-metrics";
import Leaderboards from "@/components/home/leaderboards";
import NetworkActivity from "@/components/home/network-activity";
import SocialActivityMetrics from "@/components/home/social-activity-metrics";
import AdBannerWhatsOnChain from "@/components/home/ad-banner-whats-on-chain";
import RecentTransactions from "@/components/home/recent-transactions";
import { useQuery } from "@apollo/client";
import { DashboardStatsDocument } from "../graphql/codegen/graphql";
import { client } from "../graphql/client";
import RecentBlocks from "@/components/home/recent-blocks";
import ExploreBlockchain from "@/components/home/explore-blockchain";
import NewAndActiveUsers from "@/components/home/new-and-active-users";
import { useTitle } from "../hooks/use-title";

export const Home = () => {
  useTitle("Dashboard");

  const { loading: loadingStats, data } = useQuery(DashboardStatsDocument, {
    client,
  });

  const dashboardStats = data?.dashboardStats?.nodes[0] || {};

  return (
    <section className="container w-full m-auto">
      <div className="w-full">
        <div className="md:w-[80%] m-auto">
          <ExploreBlockchain />
        </div>
        <NewAndActiveUsers />
        <NetworkActivity />
        <DesoBlockhainMetrics
          stats={dashboardStats}
          loadingStats={loadingStats}
        />
        <SocialActivityMetrics
          stats={dashboardStats}
          loadingStats={loadingStats}
        />
        <AdBannerWhatsOnChain />
        <Leaderboards />
        <RecentTransactions
          totalTxn={
            dashboardStats.txnCountAll
              ? parseInt(dashboardStats.txnCountAll)
              : 0
          }
          showAffected={false}
        />
        <RecentBlocks totalBlocks={dashboardStats.blockHeightCurrent} />
      </div>
    </section>
  );
};
