import { AtSignIcon } from "lucide-react";
import { useQuery } from "@apollo/client";
import {
  ProfileTransactionStatResultFragment,
  ProfileTransactionStatsDocument,
  ProfileTransactionStatsOrderBy,
} from "../../graphql/codegen/graphql";
import { client } from "../../graphql/client";
import DesoAvatar from "@/components/shared/deso-avatar";
import { SocialCard } from "../shared/social-card";
import dayjs from "dayjs";
import { range } from "src/utils/helpers";
import { Skeleton } from "../ui/skeleton";

const ITEMS_VISIBLE = 12;

enum USER_ACTIVITY_TYPE {
  ACTIVE = "Active",
  NEW = "Joined",
}

const areTimestampsWithinOneHour = (
  timestamp1: string,
  timestamp2: string,
): boolean => {
  const parsedTimestamp1 = dayjs(`${timestamp1}Z`);
  const parsedTimestamp2 = dayjs(`${timestamp2}Z`);

  const diffInMinutes = Math.abs(
    parsedTimestamp1.diff(parsedTimestamp2, "minute"),
  );

  return diffInMinutes <= 60;
};

const NewAndActiveUsers = () => {
  const { loading: loadingUsers, data: users } = useQuery(
    ProfileTransactionStatsDocument,
    {
      client,
      variables: {
        first: ITEMS_VISIBLE,
        orderBy: ProfileTransactionStatsOrderBy.LatestTransactionTimestampDesc,
        filter: {
          accountExists: true,
        },
      },
    },
  );

  const parsedUsers = (users?.profileTransactionStats?.nodes || [])
    .filter((e) => e !== null)
    .map((e: ProfileTransactionStatResultFragment | null) => {
      const stat = e as ProfileTransactionStatResultFragment;

      const type = areTimestampsWithinOneHour(
        stat.firstTransactionTimestamp,
        stat.latestTransactionTimestamp,
      )
        ? USER_ACTIVITY_TYPE.NEW
        : USER_ACTIVITY_TYPE.ACTIVE;

      return {
        key: `${stat.publicKey}-${stat.latestTransactionTimestamp}`,
        publicKey: stat.publicKey || "",
        username: stat.account?.username || "",
        type,
      };
    });

  return (
    <div className="mt-12 w-full m-auto">
      <h3 className="mb-4 flex items-center">
        <AtSignIcon className="mr-2" />
        New & Active Users
      </h3>

      <div className="md:border md:p-4 rounded-2xl mb-12">
        <div className="flex flex-col justify-center">
          <div className="grid grid-cols-2 md:grid-cols-4 lg:grid-cols-4 xl:grid-cols-6 gap-4">
            {loadingUsers
              ? range(ITEMS_VISIBLE).map((e, i) => (
                  <Skeleton className="rounded-xl h-[120px]" key={i} />
                ))
              : parsedUsers.map((e) => (
                  <div
                    className="flex flex-col items-center bg-card p-4 rounded-xl"
                    key={e.key}
                  >
                    <DesoAvatar
                      size={40}
                      publicKey={e.publicKey}
                      username={e.username}
                      clickable={true}
                      className="mb-1 cursor-pointer border w-10 h-10 hover:border-secondary"
                    />

                    <div className="w-full h-8 flex items-center">
                      <SocialCard
                        publicKey={e.publicKey}
                        size="sm"
                        truncateName={true}
                      />
                    </div>

                    <p className="text-xs text-center opacity-70">
                      {e.type} just now
                    </p>
                  </div>
                ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewAndActiveUsers;
