import { useEffect, useState } from "react";
import { DateFilters } from "../shared/date-filters";
import { Button } from "../ui/button";
import {
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger,
} from "../ui/collapsible";
import { Input } from "../ui/input";
import TransactionTypeAutocomplete from "@/components/transactions/transaction-type-autocomplete";
import {
  TRANSACTION_TYPE,
  TRANSACTION_TYPES_EXTENDED,
  TRANSACTION_TYPES_BY_INDEX,
  TransactionFilters,
} from "../../types";
import { getTransactionInfo } from "../../utils/helpers";
import TransactionAffectedUsersAutocomplete from "./transaction-affected-users-autocomplete";
import {
  AccountsSearchDocument,
  CoreAccountFieldsFragment,
} from "../../graphql/codegen/graphql";
import { useLazyQuery } from "@apollo/client";
import { client } from "../../graphql/client";
import AdvancedFiltersToggle from "@/components/shared/advanced-filters-toggle";

export interface TxnAutocompleteItem {
  type: TRANSACTION_TYPE;
  desc: string;
  displayName: string;
}

interface TransactionAdvancedFiltersProps {
  initTxnIndexes: Array<number>;
  initTransactorPublicKeys: Array<string>;
  initAffectedPublicKeys: Array<string>;
  filters: TransactionFilters;
  setFilters: (filter?: TransactionFilters) => void;
  onApplyFilters: () => void;
  onClearFilters: () => void;
}

const TransactionAdvancedFilters = ({
  initTxnIndexes,
  initTransactorPublicKeys,
  initAffectedPublicKeys,
  filters,
  setFilters,
  onApplyFilters,
  onClearFilters,
}: TransactionAdvancedFiltersProps) => {
  const [isOpen, setIsOpen] = useState(false);

  const [fetchUsersLazy] = useLazyQuery(AccountsSearchDocument, {
    client,
  });

  const initTransactionTypes = initTxnIndexes
    .map((e) => getTransactionInfo(e))
    .filter((e) => !!e) as Array<TxnAutocompleteItem>;

  const [selectedTxnTypes, setSelectedTxnTypes] =
    useState<Array<TxnAutocompleteItem>>(initTransactionTypes);
  const [selectedTransactors, setSelectedTransactors] = useState<
    Array<CoreAccountFieldsFragment>
  >([]);
  const [selectedAffectedUsers, setSelectedAffectedUsers] = useState<
    Array<CoreAccountFieldsFragment>
  >([]);

  const fetchSelectedUsers = async (pubKeys: Array<string>) => {
    const { data } = await fetchUsersLazy({
      variables: {
        filter: {
          publicKey: {
            in: pubKeys,
          },
        },
      },
    });

    const nonNullUsers = data?.accounts?.nodes.filter(
      (e) => !!e,
    ) as Array<CoreAccountFieldsFragment>;

    return nonNullUsers;
  };

  useEffect(() => {
    if (initTransactorPublicKeys.length) {
      fetchSelectedUsers(initTransactorPublicKeys).then((e) =>
        setSelectedTransactors(e),
      );
    }

    if (initAffectedPublicKeys.length) {
      fetchSelectedUsers(initAffectedPublicKeys).then((e) =>
        setSelectedAffectedUsers(e),
      );
    }
  }, []);

  return (
    <Collapsible open={isOpen} onOpenChange={setIsOpen} className="w-full">
      <div className="flex items-center justify-between">
        Advanced Filters
        <CollapsibleTrigger asChild>
          <AdvancedFiltersToggle open={isOpen} setOpen={setIsOpen} />
        </CollapsibleTrigger>
      </div>

      <CollapsibleContent className="w-full">
        <div className="md:border border-border-light mt-2 mb-2 border-solid rounded-md">
          <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mb-5 md:my-0 md:p-5 md:border-b border-b-border-light">
            <div>
              <label className="mb-2 inline-block text-sm">
                Transaction Hash
              </label>
              <Input
                className="w-full placeholder:text-muted"
                placeholder="Enter Txn Hash"
                value={filters.txnHash}
                onChange={(e) =>
                  setFilters({
                    ...filters,
                    txnHash: e.target.value,
                  })
                }
              />
            </div>

            <div>
              <label className="mb-2 inline-block text-sm">Block Height</label>
              <Input
                className="w-full placeholder:text-muted"
                placeholder="Enter Block Height"
                value={filters.blockHeight}
                onChange={(e) =>
                  setFilters({
                    ...filters,
                    blockHeight: e.target.value,
                  })
                }
              />
            </div>

            <div>
              <label className="mb-2 inline-block text-sm">Dates</label>
              <DateFilters
                dateFilter={filters.date}
                setDateFilter={(e) => {
                  if (!e) {
                    return;
                  }

                  setFilters({
                    ...filters,
                    date: e,
                  });
                }}
              />
            </div>
          </div>

          <div className="mb-5 md:mb-0 md:p-5 md:border-b border-b-border-light">
            <div>
              <label className="mb-2 inline-block text-sm">
                Transaction Type(s)
              </label>
              <TransactionTypeAutocomplete
                selectedItems={selectedTxnTypes}
                setSelectedItems={(e) => {
                  setSelectedTxnTypes(e);

                  setFilters({
                    ...filters,
                    txnTypes: e.map((e) =>
                      TRANSACTION_TYPES_BY_INDEX.indexOf(e.type),
                    ),
                  });
                }}
                availableTypes={TRANSACTION_TYPES_EXTENDED}
              />
            </div>
          </div>

          <div className="mb-5 md:my-0 md:p-5 md:border-b border-b-border-light">
            <div>
              <label className="mb-2 inline-block text-sm">
                Transactor Public Key(s)
              </label>
              <TransactionAffectedUsersAutocomplete
                selectedItems={selectedTransactors}
                setSelectedItems={(e) => {
                  setSelectedTransactors(e);

                  setFilters({
                    ...filters,
                    transactorPublicKeys: e.map((user) => user.publicKey),
                  });
                }}
              />
            </div>
          </div>

          <div className="mb-5 md:mb-0 md:p-5">
            <div>
              <label className="mb-2 inline-block text-sm">
                Affected User(s)
              </label>
              <TransactionAffectedUsersAutocomplete
                selectedItems={selectedAffectedUsers}
                setSelectedItems={(e) => {
                  setSelectedAffectedUsers(e);

                  setFilters({
                    ...filters,
                    affectedUsers: e.map((user) => user.publicKey),
                  });
                }}
              />
            </div>
          </div>
        </div>

        <div className="text-right mt-4 mb-2">
          <Button
            variant="outline"
            className="mr-2"
            onClick={() => {
              setSelectedTxnTypes([]);
              setSelectedTransactors([]);
              setSelectedAffectedUsers([]);
              onClearFilters();
            }}
          >
            Clear all
          </Button>
          <Button onClick={() => onApplyFilters()}>Apply filters</Button>
        </div>
      </CollapsibleContent>
    </Collapsible>
  );
};

export default TransactionAdvancedFilters;
