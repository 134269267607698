import { Badge } from "@/components/ui/badge";
import { cn } from "@/lib";
import { Link } from "react-router-dom";

interface JailedBadgeProps {
  className?: string;
}

const JailedBadge = ({ className }: JailedBadgeProps) => {
  return (
    <Link to={"/validator-settings"}>
      <Badge
        variant="destructive"
        className={cn("bg-red-600 text-white", className)}
      >
        Jailed
      </Badge>
    </Link>
  );
};

export default JailedBadge;
