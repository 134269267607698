import { Transaction } from "../../graphql/codegen/graphql";
import maxBy from "lodash/maxBy";
import range from "lodash/range";
import { cn } from "@/lib";
import { TRANSACTION_TYPE, TRANSACTION_TYPES_BY_INDEX } from "../../types";
import { DiamondPostLink } from "./diamond-post-link";
import { DiamondNFTLink } from "./diamond-nft-link";
import { desoNanosToDeso } from "../../utils/currency";
import { Gem } from "lucide-react";
import {
  formatTxnUsername,
  publicKeyByteArrayToBase58Check,
} from "../../utils/helpers";
import { SocialCard } from "@/components/shared/social-card";
import { isMaybeDeSoPublicKey } from "deso-protocol/src/internal";
import { Link } from "react-router-dom";

export interface AffectedPublicKey {
  publicKey: string;
  __typename: string;
}

export interface BasicTransferOutput {
  amount_nanos: string;
  public_key: string;
}

enum AssociationReactionValue {
  LIKE = "👍",
  DISLIKE = "👎",
  LOVE = "❤️",
  LAUGH = "😂",
  ASTONISHED = "😲",
  SAD = "😥",
  ANGRY = "😠",
}

interface ActivityActionProps {
  item: Transaction;
  wrap?: boolean;
  maxInnerTxnsToDisplay?: number;
  showAllInnerTxns?: boolean;
}

const isZeroPublicKey = (publicKey: Array<number>) => {
  return publicKey.every((e) => e === 0);
};

export const TxnActionItem = ({
  item,
  wrap,
  maxInnerTxnsToDisplay = 5,
  showAllInnerTxns = false,
}: ActivityActionProps) => {
  const userKey = item.publicKey;

  const formatMessage = (
    performerUserKey?: string | null,
    action?: string | JSX.Element,
    receiverUserKey?: string | null,
    subAction?: string | JSX.Element,
  ) => {
    return (
      <div className={cn("flex", wrap && "flex-wrap")}>
        {performerUserKey && (
          <SocialCard showAvatar={true} publicKey={performerUserKey} />
        )}

        {action && (
          <span
            className={`flex items-center whitespace-nowrap leading-8 ${cn(
              !!performerUserKey && "ml-2",
              !!receiverUserKey && "mr-2",
            )}`}
          >
            {action}
          </span>
        )}

        {receiverUserKey && (
          <SocialCard showAvatar={true} publicKey={receiverUserKey} />
        )}

        {subAction && (
          <span className={"whitespace-nowrap leading-8"}>{subAction}</span>
        )}
      </div>
    );
  };

  const parsePostAssociation = (rawTxn: any) => {
    if (
      !rawTxn ||
      !rawTxn.AssociationType ||
      !rawTxn.AssociationValue ||
      !rawTxn.PostHashHex
    ) {
      return {
        type: "",
        value: "a post association",
        postId: "",
      };
    }

    const associationType = rawTxn.AssociationType;
    const associationValue = rawTxn.AssociationValue;
    const postId = rawTxn.PostHashHex;

    const formattedValue =
      associationType === "REACTION" &&
      (AssociationReactionValue as any)[associationValue]
        ? (AssociationReactionValue as any)[associationValue]
        : associationValue;

    return {
      postId,
      type: associationType.replaceAll("_", " ").toLowerCase(),
      value: formattedValue,
    };
  };

  const formatTxn = (txn: any) => {
    if (!txn.txnType) {
      return <>Transaction type is missing</>;
    }

    switch (TRANSACTION_TYPES_BY_INDEX[txn.txnType]) {
      case TRANSACTION_TYPE.TxnTypeCreateUserAssociation: {
        /**** TESTED ****/
        const { TargetUserPublicKeyBase58Check } = txn.txIndexMetadata || {};

        return formatMessage(
          userKey,
          TargetUserPublicKeyBase58Check
            ? "created an association with"
            : "created an association with another user",
          TargetUserPublicKeyBase58Check,
        );
      }
      case TRANSACTION_TYPE.TxnTypeDeleteUserAssociation: {
        /**** TESTED ****/
        const { TargetUserPublicKeyBase58Check } = txn.txIndexMetadata || {};

        return formatMessage(
          userKey,
          TargetUserPublicKeyBase58Check
            ? "deleted an association with"
            : "deleted an association with another user",
          TargetUserPublicKeyBase58Check,
        );
      }
      case TRANSACTION_TYPE.TxnTypeCreatePostAssociation: {
        /**** TESTED ****/
        const { postId, type, value } = parsePostAssociation(
          txn?.txIndexMetadata,
        );

        if (
          txn?.txIndexMetadata?.AssociationType === "REACTION" ||
          txn?.txIndexMetadata?.AssociationType === "POLL_RESPONSE"
        ) {
          return formatMessage(
            userKey,
            <>
              <span className="mx-2">{value}</span>
              {type}
              {postId && (
                <>
                  &nbsp;to a&nbsp;
                  <DiamondPostLink postId={postId} />
                </>
              )}
            </>,
          );
        }

        return formatMessage(
          userKey,
          postId ? (
            <>
              created an association to a&nbsp;
              <DiamondPostLink postId={postId} />
            </>
          ) : (
            "created an association to a post"
          ),
        );
      }
      case TRANSACTION_TYPE.TxnTypeDeletePostAssociation: {
        /**** TESTED ****/
        const { postId, type, value } = parsePostAssociation(
          txn?.txIndexMetadata,
        );

        if (
          txn?.txIndexMetadata?.AssociationType === "REACTION" ||
          txn?.txIndexMetadata?.AssociationType === "POLL_RESPONSE"
        ) {
          return formatMessage(
            userKey,
            <>
              deleted <span className="mx-2">{value}</span>
              {type}
              {postId && (
                <>
                  &nbsp;from a&nbsp;
                  <DiamondPostLink postId={postId} />
                </>
              )}
            </>,
          );
        }

        return formatMessage(
          userKey,
          postId ? (
            <>
              deleted an association from a&nbsp;
              <DiamondPostLink postId={postId} />
            </>
          ) : (
            "deleted an association from a post"
          ),
        );
      }
      case TRANSACTION_TYPE.TxnTypeLike: {
        /**** TESTED ****/
        return formatMessage(
          userKey,
          <>
            liked a&nbsp;
            <DiamondPostLink postId={txn.txIndexMetadata?.PostHashHex || ""} />
          </>,
        );
      }
      case TRANSACTION_TYPE.TxnTypeBasicTransfer: {
        /**** TESTED ****/
        const outputs = (txn.outputs || []) as Array<BasicTransferOutput>;
        const receiver = maxBy(
          outputs.filter((o) => o.public_key !== txn.publicKey),
          "amount_nanos",
        );
        const amountFormatted = desoNanosToDeso(
          receiver?.amount_nanos as string,
        );
        const diamondsSent = txn.txIndexMetadata?.DiamondLevel || 0;

        if (diamondsSent) {
          return formatMessage(
            userKey,
            <>
              sent&nbsp;
              <div className="flex">
                {range(diamondsSent).map((i) => (
                  <Gem size={16} key={i} />
                ))}
              </div>
              &nbsp;to
            </>,
            receiver?.public_key,
            <>
              &nbsp;on a&nbsp;
              <DiamondPostLink
                postId={txn.txIndexMetadata?.PostHashHex || ""}
              />
            </>,
          );
        }

        return formatMessage(
          userKey,
          <>
            sent <div className="text-green-600 mx-1">{amountFormatted}</div>{" "}
            $DESO to{" "}
          </>,
          receiver?.public_key,
        );
      }
      case TRANSACTION_TYPE.TxnTypeBitcoinExchange: {
        return formatMessage(userKey, "burned BTC for DESO");
      }
      case TRANSACTION_TYPE.TxnTypeNewMessage: {
        /**** TESTED ****/
        const isDM = txn.txnMeta.NewMessageType === 0;
        const isCreation = txn.txnMeta.NewMessageOperation === 0;

        const sender = publicKeyByteArrayToBase58Check(
          txn.txnMeta.SenderAccessGroupOwnerPublicKey,
        );
        const receiver = publicKeyByteArrayToBase58Check(
          txn.txnMeta.RecipientAccessGroupOwnerPublicKey,
        );

        const subAction = !isDM ? (
          <>
            's group called{" "}
            <b>
              {String.fromCharCode(
                ...txn.txnMeta.RecipientAccessGroupKeyName.filter(
                  (e: number) => e !== 0, // remove trailing zeros
                ),
              )}
            </b>
          </>
        ) : (
          ""
        );

        return formatMessage(
          sender,
          `${isCreation ? "sent" : "updated"} a message to`,
          receiver,
          subAction,
        );
      }
      case TRANSACTION_TYPE.TxnTypePrivateMessage: {
        /**** TESTED ****/
        const receiver = formatTxnUsername(txn.txnMeta.RecipientPublicKey);
        return formatMessage(userKey, "sent a message to", receiver);
      }
      case TRANSACTION_TYPE.TxnTypeUpdateProfile: {
        /**** TESTED ****/
        return formatMessage(userKey, "updated a profile");
      }
      case TRANSACTION_TYPE.TxnTypeSubmitPost: {
        /**** TESTED ****/
        let action: JSX.Element | string = "";

        const isRepost = !!txn.extraData.RecloutedPostHash;
        const postId = txn.txnMeta?.PostHashToModify
          ? txn.txIndexMetadata?.PostHashBeingModifiedHex
          : txn.transactionHash;

        if (isRepost) {
          action = (
            <>
              reposted a&nbsp;
              <DiamondPostLink postId={postId} />
            </>
          );
        } else if (txn.txIndexMetadata?.ParentPostHashHex) {
          action = (
            <>
              commented on a&nbsp;
              <DiamondPostLink postId={postId} />
            </>
          );
        } else if (txn.txIndexMetadata?.PostHashToModify) {
          action = (
            <>
              edited a&nbsp;
              <DiamondPostLink postId={postId} />
            </>
          );
        } else {
          action = (
            <>
              submitted a&nbsp;
              <DiamondPostLink postId={postId} />
            </>
          );
        }

        return formatMessage(userKey, action);
      }
      case TRANSACTION_TYPE.TxnTypeFollow: {
        /**** TESTED ****/
        return formatMessage(
          userKey,
          txn.txnMeta.IsUnfollow ? "unfollowed" : "followed",
          formatTxnUsername(txn.txnMeta.FollowedPublicKey),
        );
      }
      case TRANSACTION_TYPE.TxnTypeCreatorCoin: {
        /**** TESTED ****/
        const operation =
          txn.txIndexMetadata?.OperationType === "buy" ? "bought" : "sold";
        const creator = formatTxnUsername(txn.txnMeta.ProfilePublicKey);

        return formatMessage(userKey, operation, creator, "'s creator coin");
      }
      case TRANSACTION_TYPE.TxnTypeSwapIdentity: {
        /**** TESTED ****/
        return formatMessage(userKey, "swapped identity");
      }
      case TRANSACTION_TYPE.TxnTypeUpdateGlobalParams: {
        /**** TESTED ****/
        return formatMessage(userKey, "updated global params");
      }
      case TRANSACTION_TYPE.TxnTypeCreateNFT: {
        /**** TESTED ****/
        return formatMessage(
          userKey,
          <>
            created an&nbsp;
            <DiamondNFTLink NFTId={txn.txIndexMetadata?.NFTPostHashHex || ""} />
          </>,
        );
      }
      case TRANSACTION_TYPE.TxnTypeUpdateNFT: {
        /**** TESTED ****/
        let action: JSX.Element;

        if (txn.txnMeta?.IsForSale) {
          action = (
            <>
              put an&nbsp;
              <DiamondNFTLink
                NFTId={txn.txIndexMetadata?.NFTPostHashHex}
              />{" "}
              &nbsp;on sale
            </>
          );
        } else {
          action = (
            <>
              took &nbsp;
              <DiamondNFTLink
                NFTId={txn.txIndexMetadata?.NFTPostHashHex || ""}
              />{" "}
              &nbsp;off market
            </>
          );
        }

        return formatMessage(userKey, action);
      }
      case TRANSACTION_TYPE.TxnTypeAcceptNFTBid: {
        /**** TESTED ****/
        return formatMessage(
          userKey,
          <>
            sold&nbsp;
            <DiamondNFTLink NFTId={txn.txIndexMetadata?.NFTPostHashHex || ""} />
          </>,
        );
      }
      case TRANSACTION_TYPE.TxnTypeNFTBid: {
        /**** TESTED ****/
        return formatMessage(
          userKey,
          <>
            bid on an&nbsp;
            <DiamondNFTLink NFTId={txn.txIndexMetadata?.NFTPostHashHex || ""} />
          </>,
        );
      }
      case TRANSACTION_TYPE.TxnTypeNFTTransfer: {
        /**** TESTED ****/
        return formatMessage(
          userKey,
          <>
            bid on an&nbsp;
            <DiamondNFTLink NFTId={txn.txIndexMetadata?.NFTPostHashHex || ""} />
          </>,
        );
      }
      case TRANSACTION_TYPE.TxnTypeAcceptNFTTransfer:
        /**** TESTED ****/
        return formatMessage(
          userKey,
          <>
            accepted an&nbsp;
            <DiamondNFTLink NFTId={txn.txIndexMetadata?.NFTPostHashHex || ""} />
            &nbsp;transfer
          </>,
        );
      case TRANSACTION_TYPE.TxnTypeBurnNFT:
        /**** TESTED ****/
        return formatMessage(
          userKey,
          <>
            burned an&nbsp;
            <DiamondNFTLink NFTId={txn.txIndexMetadata?.NFTPostHashHex || ""} />
          </>,
        );
      case TRANSACTION_TYPE.TxnTypeAuthorizeDerivedKey: {
        /**** TESTED ****/
        return formatMessage(userKey, "authorized a derived key");
      }
      case TRANSACTION_TYPE.TxnTypeDAOCoinTransfer: {
        /**** TESTED ****/
        const daoReceiver = formatTxnUsername(txn.txnMeta.ReceiverPublicKey);
        return formatMessage(userKey, "transferred token to", daoReceiver);
      }
      case TRANSACTION_TYPE.TxnTypeDAOCoinLimitOrder: {
        /**** TESTED ****/
        const isCancelling = !!txn.txnMeta.CancelOrderID;

        if (isCancelling) {
          return formatMessage(userKey, "cancelled an order");
        }

        const buyingPublicKey = isZeroPublicKey(
          txn.txnMeta.BuyingDAOCoinCreatorPublicKey,
        )
          ? null
          : txn.txIndexMetadata?.BuyingDAOCoinCreatorPublicKey;

        const sellingPublicKey = isZeroPublicKey(
          txn.txnMeta.SellingDAOCoinCreatorPublicKey,
        )
          ? null
          : txn.txIndexMetadata?.SellingDAOCoinCreatorPublicKey;

        return formatMessage(
          userKey,
          <>
            submitted an order to buy{" "}
            {buyingPublicKey ? (
              <div className="mx-2">
                <SocialCard showAvatar={true} publicKey={buyingPublicKey} />
              </div>
            ) : (
              "$DESO"
            )}{" "}
            and sell{" "}
            {sellingPublicKey ? (
              <div className="mx-2">
                <SocialCard showAvatar={true} publicKey={sellingPublicKey} />
              </div>
            ) : (
              "$DESO"
            )}
          </>,
        );
      }
      case TRANSACTION_TYPE.TxnTypeCreatorCoinTransfer: {
        /**** TESTED ****/
        return formatMessage(
          userKey,
          <>
            transferred
            <div className="mx-2">
              <SocialCard
                showAvatar={true}
                publicKey={formatTxnUsername(txn.txnMeta.ProfilePublicKey)}
              />
            </div>
            creator coin to
          </>,
          formatTxnUsername(txn.txnMeta.ReceiverPublicKey),
        );
      }
      case TRANSACTION_TYPE.TxnTypeBlockReward: {
        /**** TESTED ****/
        const affectedPublicKeys = txn.affectedPublicKeys?.nodes?.filter(
          (e: any) => isMaybeDeSoPublicKey(e.publicKey),
        );
        const receiver =
          affectedPublicKeys && affectedPublicKeys.length
            ? affectedPublicKeys[0]?.publicKey
            : item.outputs?.[0]?.public_key || "";
        return formatMessage(receiver, "received a block reward");
      }
      case TRANSACTION_TYPE.TxnTypeMessagingGroup: {
        /**** TESTED ****/
        return formatMessage(userKey, "performed a messaging group operation");
      }
      case TRANSACTION_TYPE.TxnTypeAccessGroup: {
        /**** TESTED ****/
        return formatMessage(userKey, "performed an access group operation");
      }
      case TRANSACTION_TYPE.TxnTypeDAOCoin: {
        /**** TESTED ****/
        return formatMessage(userKey, "performed a DeSo Token operation");
      }
      case TRANSACTION_TYPE.TxnTypeAccessGroupMembers:
        const actionByTypes = {
          "2": "added members to",
          "3": "removed members from",
          "4": "updated members in",
        };

        const action =
          (actionByTypes as any)?.[
            txn.txIndexMetadata.AccessGroupMemberOperationType.toString()
          ] || "performed an action with members in";

        const groupName = atob(txn.txnMeta.AccessGroupKeyName);

        return formatMessage(
          userKey,
          <>
            {action} an access group <b className="ml-1">{groupName}</b>
          </>,
        );
      case TRANSACTION_TYPE.TxnTypeRegisterAsValidator:
        return formatMessage(userKey, "registered as a validator");
      case TRANSACTION_TYPE.TxnTypeUnregisterAsValidator:
        return formatMessage(userKey, "unregistered as a validator");
      case TRANSACTION_TYPE.TxnTypeUnjailValidator:
        return formatMessage(userKey, "unjailed themselves as a validator");
      case TRANSACTION_TYPE.TxnTypeStake:
        const stakeAmountFormatted = desoNanosToDeso(
          parseInt(txn.txIndexMetadata?.StakeAmountNanos as string),
        );
        return formatMessage(userKey, `staked ${stakeAmountFormatted} $DESO`);
      case TRANSACTION_TYPE.TxnTypeUnstake:
        const unstakeAmountFormatted = desoNanosToDeso(
          parseInt(txn.txIndexMetadata?.UnstakeAmountNanos as string),
        );
        return formatMessage(
          userKey,
          `unstaked ${unstakeAmountFormatted} $DESO`,
        );
      case TRANSACTION_TYPE.TxnTypeUnlockStake:
        const unlockStakeAmountFormatted = desoNanosToDeso(
          parseInt(txn.txIndexMetadata?.TotalUnlockedAmountNanos as string),
        );
        return formatMessage(
          userKey,
          `unlocked stake of ${unlockStakeAmountFormatted} $DESO`,
        );
      case TRANSACTION_TYPE.TxnTypeCoinLockup:
        return formatMessage(userKey, "performed a coin lockup");
      case TRANSACTION_TYPE.TxnTypeCoinLockupTransfer:
        return formatMessage(userKey, "performed a coin lockup transfer");
      case TRANSACTION_TYPE.TxnTypeCoinUnlock:
        return formatMessage(userKey, "performed a coin unlock");
      case TRANSACTION_TYPE.TxnTypeUpdateCoinLockupParams:
        return formatMessage(userKey, "updated coin lockup params");
      case TRANSACTION_TYPE.TxnTypeAtomicTxnsWrapper:
        const innerTxns = txn.innerTransactions?.nodes || [];
        const innerTxnsWithTxindexMetadata = innerTxns.filter(
          (innerTxn: any) => innerTxn.txIndexMetadata,
        );
        const innerTxnsToDisplay = showAllInnerTxns
          ? innerTxnsWithTxindexMetadata
          : innerTxnsWithTxindexMetadata.slice(0, maxInnerTxnsToDisplay);
        const innerTxnsFormatted =
          innerTxnsToDisplay.map((innerTxn: any) => formatTxn(innerTxn)) || [];

        return (
          <>
            {!wrap && (
              <div className={cn("flex", wrap && "flex-wrap")}>
                A series of {txn.txnMeta.Txns.length} transactions were executed
                atomically.
              </div>
            )}
            {!!innerTxnsFormatted?.length && (
              <div className={cn("flex-col", wrap && "flex-wrap")}>
                {wrap && (
                  <div className={cn("flex", wrap && "flex-wrap")}>
                    A series of {txn.txnMeta.Txns.length} transactions were
                    executed atomically.
                  </div>
                )}
                {innerTxnsFormatted?.map((txn: any, ii: number) => (
                  <div
                    key={`${txn.transactionHash}-inner-txn-${ii}`}
                    className={cn("flex items-center", wrap && "flex-wrap")}
                  >
                    {txn}
                    <Link
                      to={`/txn/${innerTxnsToDisplay[ii].transactionHash}`}
                      className="ml-2 underline"
                    >
                      View transaction
                    </Link>
                  </div>
                ))}
              </div>
            )}
            {!!innerTxnsFormatted.length &&
              !showAllInnerTxns &&
              innerTxns.length > maxInnerTxnsToDisplay && (
                <div className="mb-1">
                  <span className="text-gray-500">
                    and {innerTxns.length - maxInnerTxnsToDisplay} more...
                  </span>
                </div>
              )}
          </>
        );
      case TRANSACTION_TYPE.TxnTypeUpdateBitcoinUSDExchangeRate:
      default:
        return <span>Unhandled transaction</span>;
    }
  };
  return formatTxn(item);
};
