export function formatDecimalValue(
  value: string | number,
  maxDecimalPlaces = 2,
  minDecimalPlaces = 0,
) {
  const minDisplayableValue =
    maxDecimalPlaces > 0 ? `0.${"0".repeat(maxDecimalPlaces - 1)}1` : "1";

  if (Number(value) > 0 && Number(value) < Number(minDisplayableValue)) {
    return `< ${minDisplayableValue}`;
  }

  return Number(value).toLocaleString("en-US", {
    maximumFractionDigits: maxDecimalPlaces,
    minimumFractionDigits: minDecimalPlaces,
  });
}

export function desoNanosToDeso(nanos: number | string | bigint) {
  return Number(nanos) / 1e9;
}

export function baseUnitsToTokens(baseUnits: string | number | bigint) {
  return Number(baseUnits) / 1e18;
}

export function desoNanosToUSD(nanos: number | string | bigint, xRate: number) {
  return ((Number(nanos) / 1e9) * xRate) / 100;
}

export function desoToUSD(nanos: number | string | bigint, xRate: number) {
  return (Number(nanos) * xRate) / 100;
}

export function formatUSD(
  usdAmount: number | string,
  withDecimal = true,
  numDecimals = 2,
) {
  const number = Number(usdAmount);
  if (number <= 0) {
    return "$0.00";
  }

  if (withDecimal && number < 0.01 && numDecimals <= 2) {
    return "< $0.01";
  }

  if (!withDecimal && number < 1) {
    return `< $1`;
  }

  return `${number.toLocaleString("en-US", {
    style: "currency",
    currency: "USD",
    maximumFractionDigits: withDecimal ? numDecimals : 0,
    minimumFractionDigits: withDecimal ? numDecimals : 0,
  })}`;
}

export function calcPercentage(
  number: number,
  total: number,
  round = true,
): number {
  if (total === 0) {
    return 0;
  }

  const percentage = (number / total) * 100;

  if (round) {
    return Math.round(percentage);
  }

  return percentage;
}

/**
 * This will convert basis points to a non-decimal percent representation.
 * So 100 basis points would be converted to 1.00, and NOT 0.01.
 * @param basisPoints
 * @returns
 */
export function basisPointsToPercent(basisPoints: number | string) {
  return Number(basisPoints) / 100;
}

/**
 * This is expecting the non-decimal percent representation, so 1% would be
 * 1.00, and NOT 0.01.
 * @param percentDecimalValue
 * @returns
 */
export function percentToBasisPoints(percentDecimalValue: number | string) {
  return Number(percentDecimalValue) * 100;
}
