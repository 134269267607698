import { cn } from "@/lib";
import { TrendingDownIcon, TrendingUpIcon } from "lucide-react";
import { formatDecimalValue } from "../../utils/currency";

interface TrendingIconProps {
  percentage: number;
  children?: JSX.Element | string;
}

const TrendingIcon = (props: TrendingIconProps) => {
  const isPositiveTrend = props.percentage >= 0;
  const color = isPositiveTrend ? "text-green-500" : "text-red-500";

  return (
    <span className={cn("ml-2 flex items-center font-semibold text-xs", color)}>
      {isPositiveTrend ? (
        <TrendingUpIcon size={18} />
      ) : (
        <TrendingDownIcon size={18} />
      )}
      &nbsp;{formatDecimalValue(Math.abs(props.percentage))}%&nbsp;
      {props.children}
    </span>
  );
};

export default TrendingIcon;
