import { Link } from "react-router-dom";
import { CoreAccountFieldsFragment } from "../../graphql/codegen/graphql";
import { WALLET_URL } from "../../utils/constants";

interface WalletLinkProps {
  user?: CoreAccountFieldsFragment | null;
  className?: string;
  children: JSX.Element | Array<JSX.Element> | string;
}

const WalletLink = ({ user, className = "", children }: WalletLinkProps) => {
  const url = user
    ? `${WALLET_URL}/?user=${user?.username || user?.publicKey}`
    : WALLET_URL;

  return (
    <Link to={url} target="_blank" className={className}>
      {children}
    </Link>
  );
};

export default WalletLink;
