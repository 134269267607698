import { IdentityState, NOTIFICATION_EVENTS, identity } from "deso-protocol";
import { Identity } from "deso-protocol/src/identity/identity";
import { useEffect, useState } from "react";

const isBrowser = typeof window !== "undefined";

export const useDesoIdentity = () => {
  const snapshot = isBrowser
    ? (identity as Identity<Storage>).snapshot()
    : { currentUser: null, alternateUsers: null };
  const [state, setState] = useState<
    IdentityState & { pendingDerivedKeyAuthorization: boolean }
  >({
    ...snapshot,
    pendingDerivedKeyAuthorization: true,
  });

  useEffect(() => {
    identity.subscribe(({ event, currentUser, alternateUsers }) => {
      if (event === NOTIFICATION_EVENTS.AUTHORIZE_DERIVED_KEY_START) {
        setState((state) => ({
          ...state,
          pendingDerivedKeyAuthorization: true,
        }));
        return;
      }

      if (Object.keys(alternateUsers || {})?.length && !currentUser) {
        const fallbackUser = Object.values(alternateUsers || {})[0];
        identity.setActiveUser(fallbackUser.publicKey);
        return;
      }

      setState((state) => ({
        ...state,
        currentUser,
        alternateUsers,
        pendingDerivedKeyAuthorization: false,
      }));
    });
  }, []);

  return state;
};
