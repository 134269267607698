import Chart from "react-apexcharts";
import { TransactionPlotData } from "@/components/home/network-activity";

interface PlotTransactionsProps {
  tooltipLabel: string;
  plotData: TransactionPlotData;
  formatter?: (e: string) => string;
  tickAmount?: number;
}

const PlotTransactions = ({
  plotData,
  tooltipLabel,
  formatter = (e: string) => {
    const dateObj = new Date(e);
    return dateObj.toLocaleDateString("en-US", {
      month: "short",
      day: "2-digit",
    });
  },
  tickAmount,
}: PlotTransactionsProps) => {
  const dataSample = {
    options: {
      chart: {
        id: "basic-bar",
        toolbar: { show: false },
      },
      colors: ["#ffda59"],
      xaxis: {
        categories: plotData.categories,
        labels: {
          formatter,
          range: 10,
        },
        tickAmount: tickAmount || plotData.categories.length,
      },
      dataLabels: {
        enabled: false,
      },
      datetimeUTC: true,
      fill: {
        type: "gradient",
        gradient: {
          shade: "dark",
          type: "vertical",
          shadeIntensity: 0.2,
          opacityFrom: 0.1,
          opacityTo: 0.6,
          stops: [0, 100],
        },
      },
      tooltip: {
        theme: "dark",
      },
    },
    series: [
      {
        name: tooltipLabel,
        data: plotData.series,
      },
    ],
  };

  return (
    <Chart
      options={dataSample.options}
      series={dataSample.series}
      type="area"
      height="300"
    />
  );
};

export default PlotTransactions;
