import { useEffect, useRef } from "react";

const DEFAULT_TITLE = "DeSo Explorer";

const buildTitle = (prefix: string): string => `${prefix} | DeSo Explorer`;

export const setTitle = (suffix: string, originalTitle?: string) => {
  if (typeof window == undefined) {
    return;
  }

  document.title = buildTitle(suffix) ?? originalTitle ?? DEFAULT_TITLE;

  return () => {
    document.title = buildTitle("DeSo Explorer");
  };
};

export const useTitle = (suffix: string) => {
  const documentDefined = typeof document !== "undefined";
  const originalTitle = useRef(documentDefined ? document.title : "");

  useEffect(() => {
    setTitle(suffix, originalTitle.current);
  });
};
