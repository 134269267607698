import { Link } from "react-router-dom";

const AdBannerWhatsOnChain = () => {
  return (
    <div className="text-center my-12 md:border border-border md:p-6 rounded-2xl">
      <div
        className="h-[200px] bg-cover w-full rounded-2xl bg-center mb-10 shadow-inner"
        style={{ backgroundImage: "url(assets/img/img_deso_1.jpeg)" }}
      ></div>
      <h4 className="mb-7 text-xl text-muted">What&apos;s stored on-chain?</h4>
      <div className="grid grid-cols-1 lg:grid-cols-3 gap-6">
        <div className="text-center text-sm text-muted leading-6">
          <div className="flex flex-col gap-2 items-center justify-center">
            <img
              src="/assets/img/icon-head.svg"
              alt="icon-heart"
              className="h-[80px]"
            />
            <h5 className="text-base mb-2 font-bold text-muted-foreground">
              Content, Identity & Social Activity
            </h5>
          </div>
          All identity, profiles and social graphs are fully on-chain, including
          actions such as posts, messages (E2EE), likes, comments, follows &
          reactions are also on-chain.
        </div>
        <div className="text-center text-sm text-muted leading-6">
          <div className="flex flex-col gap-2 items-center justify-center">
            <img
              src="/assets/img/icon-diamond.svg"
              alt="icon-diamond"
              className="h-[80px]"
            />
            <h5 className="text-base mb-2 font-bold text-muted-foreground">
              Creator Coins, Tips & Social NFTs{" "}
            </h5>
          </div>
          All creator coins, tipping and NFT activity are fully on-chain,
          including auctions, royalties, splits, and exclusive content. Soon,
          media assets will be stored on-chain too.
        </div>
        <div className="text-center text-sm text-muted leading-6">
          <div className="flex flex-col gap-2 items-center justify-center">
            <img
              src="/assets/img/icon-deso.svg"
              alt="icon-deso"
              className="h-[80px]"
            />
            <h5 className="text-base mb-2 font-bold text-muted-foreground">
              All Token & DEX Activity
            </h5>
          </div>
          All order-book functionality, including limit orders, are stored
          on-chain. Projects can fundraise, trade and launch tokens via{" "}
          <Link
            to="https://openfund.com"
            target="_blank"
            className="underline"
            rel="noreferrer"
          >
            openfund.com
          </Link>
          , which is built on DeSo.
        </div>
      </div>
      <p className="mt-8 text-sm opacity-60">
        This is all possible thanks to DeSo&apos;s infinite-state blockchain.{" "}
        <Link
          to="https://docs.deso.org/deso-blockchain/infinite-state"
          target="_blank"
          className="underline"
          rel="noreferrer"
        >
          Learn More
        </Link>
      </p>
    </div>
  );
};

export default AdBannerWhatsOnChain;
