import { useCallback, useEffect, useState } from "react";
import { useCombobox, useMultipleSelection } from "downshift";
import { cn } from "@/lib";
import {
  AccountByUsernameDocument,
  AccountsOrderBy,
  AccountsSearchDocument,
  CoreAccountFieldsFragment,
} from "../../graphql/codegen/graphql";
import DesoAvatar from "@/components/shared/deso-avatar";
import { useLazyQuery } from "@apollo/client";
import { client } from "../../graphql/client";
import debounce from "lodash/debounce";
import {
  concatUserResponses,
  isDesoPublicKey,
  shortenLongWord,
} from "../../utils/helpers";

interface TransactionAffectedUsersAutocompleteProps {
  selectedItems: Array<CoreAccountFieldsFragment>;
  setSelectedItems: (types: Array<CoreAccountFieldsFragment>) => void;
}

const TransactionAffectedUsersAutocomplete = ({
  selectedItems,
  setSelectedItems,
}: TransactionAffectedUsersAutocompleteProps) => {
  const [fetchUsersLazy] = useLazyQuery(AccountsSearchDocument, {
    client,
  });
  const [fetchAccountByUsername] = useLazyQuery(AccountByUsernameDocument, {
    client,
  });

  const [availableUsers, setAvailableUsers] = useState<
    Array<CoreAccountFieldsFragment>
  >([]);
  const [inputValue, setInputValue] = useState<string>("");

  const getFilteredTransactionTypes = async (
    inputString: string,
    selectedItems: Array<CoreAccountFieldsFragment>,
  ) => {
    if (!inputString?.trim()) {
      setAvailableUsers([]);
      return;
    }

    const filter = isDesoPublicKey(inputString)
      ? {
          publicKey: {
            equalTo: inputString,
          },
        }
      : {
          username: {
            likeInsensitive: `${inputString}%`,
          },
        };

    const fetchSearchResultsRequest = fetchUsersLazy({
      variables: {
        filter,
        first: 3,
        orderBy: AccountsOrderBy.DesoLockedNanosDesc,
      },
    });

    const [{ data }, exactMatch] = await Promise.all([
      fetchSearchResultsRequest,
      fetchAccountByUsername({
        variables: { username: inputString.trim() },
      }),
    ]);

    const allAccounts = concatUserResponses(
      exactMatch?.data?.accountByUsername,
      data?.accounts?.nodes,
    );

    const selectedPubKeys = selectedItems.map((e) => e.publicKey);

    setAvailableUsers(
      allAccounts.filter((e) => !selectedPubKeys.includes(e?.publicKey || "")),
    );
  };

  const getFilteredTransactionTypesDebounced = useCallback(
    debounce(
      (input, selectedItems) =>
        getFilteredTransactionTypes(input, selectedItems),
      400,
    ),
    [],
  );

  useEffect(() => {
    getFilteredTransactionTypesDebounced(inputValue, selectedItems);
  }, [inputValue]);

  const { getSelectedItemProps, getDropdownProps, removeSelectedItem } =
    useMultipleSelection({
      selectedItems,
      onStateChange({ selectedItems: newSelectedItems, type }) {
        switch (type) {
          case useMultipleSelection.stateChangeTypes
            .SelectedItemKeyDownBackspace:
          case useMultipleSelection.stateChangeTypes.SelectedItemKeyDownDelete:
          case useMultipleSelection.stateChangeTypes.DropdownKeyDownBackspace:
          case useMultipleSelection.stateChangeTypes.FunctionRemoveSelectedItem:
            setSelectedItems(newSelectedItems || []);
            break;
          default:
            break;
        }
      },
    });

  const {
    isOpen,
    getToggleButtonProps,
    getMenuProps,
    getInputProps,
    highlightedIndex,
    getItemProps,
    selectedItem,
  } = useCombobox({
    items: availableUsers,
    itemToString(item: CoreAccountFieldsFragment | null) {
      return item?.username ?? item?.publicKey ?? "";
    },
    defaultHighlightedIndex: 0, // after selection, highlight the first item.
    selectedItem: null,
    inputValue,
    stateReducer(state, actionAndChanges) {
      const { changes, type } = actionAndChanges;

      switch (type) {
        case useCombobox.stateChangeTypes.InputKeyDownEnter:
        case useCombobox.stateChangeTypes.ItemClick:
          return {
            ...changes,
            isOpen: true, // keep the menu open after selection.
            highlightedIndex: 0, // with the first option highlighted.
          };
        default:
          return changes;
      }
    },
    onStateChange({
      inputValue: newInputValue,
      type,
      selectedItem: newSelectedItem,
    }) {
      switch (type) {
        case useCombobox.stateChangeTypes.InputKeyDownEnter:
        case useCombobox.stateChangeTypes.ItemClick:
        case useCombobox.stateChangeTypes.InputBlur:
          if (newSelectedItem) {
            setSelectedItems([...selectedItems, newSelectedItem]);
            setInputValue("");
            setAvailableUsers([]);
          }
          break;

        case useCombobox.stateChangeTypes.InputChange:
          setInputValue(newInputValue || "");

          break;
        default:
          break;
      }
    },
  });

  return (
    <div className="w-full relative">
      <div className="flex flex-col gap-1">
        <div className="shadow-sm bg-card rounded-2xl inline-flex gap-2 items-center flex-wrap p-2">
          {selectedItems.map(
            (
              selectedItemForRender: CoreAccountFieldsFragment,
              index: number,
            ) => {
              return (
                <span
                  className="bg-primary dark:bg-card-foreground text-xs text-muted-foreground rounded-full px-3 py-2 focus:bg-secondary"
                  key={`selected-item-${index}`}
                  {...getSelectedItemProps({
                    selectedItem: selectedItemForRender,
                    index,
                  })}
                >
                  {selectedItemForRender.username
                    ? `@${selectedItemForRender.username}`
                    : shortenLongWord(selectedItemForRender.publicKey, 6, 6)}
                  <span
                    className="px-1 ml-2 text-xs cursor-pointer"
                    onClick={(e) => {
                      e.stopPropagation();
                      removeSelectedItem(selectedItemForRender);
                    }}
                  >
                    &#10005;
                  </span>
                </span>
              );
            },
          )}

          <div className="flex gap-0.5 grow">
            <input
              placeholder="Enter username or public key..."
              className="w-full bg-transparent placeholder:text-muted text-sm px-2 focus:ring-0 focus:outline-none"
              {...getInputProps(getDropdownProps({ preventKeyAction: isOpen }))}
            />

            <button
              aria-label="toggle menu"
              className="px-2"
              type="button"
              {...getToggleButtonProps()}
            >
              &#8595;
            </button>
          </div>
        </div>
      </div>
      <ul
        className={`absolute w-full border border-border rounded-lg bg-card mt-3 shadow-md max-h-80 overflow-scroll p-0 z-10 ${
          !(isOpen && availableUsers.length) && "hidden"
        }`}
        {...getMenuProps()}
      >
        {isOpen &&
          availableUsers.map(
            (item: CoreAccountFieldsFragment, index: number) => (
              <li
                className={cn(
                  highlightedIndex === index &&
                    "hover:bg-secondary dark:hover:bg-accent",
                  selectedItem === item && "font-bold",
                  "py-2 px-2 flex flex-col lg:flex-row items-start lg:items-center hover:bg-secondary dark:hover:bg-accent justify-between",
                )}
                key={item.publicKey}
                {...getItemProps({ item, index })}
              >
                <div className="flex items-center">
                  <div className="text-sm text-muted-foreground flex items-center">
                    <DesoAvatar publicKey={item.publicKey} />

                    <span className="ml-1">
                      {item.username || shortenLongWord(item.publicKey, 6, 6)}
                    </span>
                  </div>
                </div>

                <div className="text-right">
                  <span className="text-xs text-muted font-mono">
                    {item.publicKey}
                  </span>
                </div>
              </li>
            ),
          )}
      </ul>
    </div>
  );
};

export default TransactionAffectedUsersAutocomplete;
