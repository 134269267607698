import React, { createContext, useEffect, useState } from "react";
import {
  DEFAULT_THEME,
  LOCAL_STORAGE_KEYS,
  THEME_OPTIONS,
} from "../utils/constants";

interface ThemeContextProps {
  theme: THEME_OPTIONS;
  setTheme: React.Dispatch<React.SetStateAction<THEME_OPTIONS>>;
}

export const ThemeContext = createContext<ThemeContextProps>({
  theme: DEFAULT_THEME,
  setTheme: () => {
    return;
  },
});

interface ThemeProviderProps {
  children: React.ReactNode;
}

export function ThemeProvider({ children }: ThemeProviderProps) {
  const [theme, setTheme] = useState<THEME_OPTIONS>(
    (localStorage.getItem(LOCAL_STORAGE_KEYS.theme) as THEME_OPTIONS) ||
      DEFAULT_THEME,
  );

  useEffect(() => {
    const root = window.document.documentElement;
    root.classList.remove(
      theme === THEME_OPTIONS.darkMode
        ? THEME_OPTIONS.lightMode
        : THEME_OPTIONS.darkMode,
    );
    root.classList.add(theme);
    localStorage.setItem(LOCAL_STORAGE_KEYS.theme, theme);
  }, [theme]);

  const contextValue: ThemeContextProps = {
    theme,
    setTheme,
  };

  return (
    <ThemeContext.Provider value={contextValue}>
      {children}
    </ThemeContext.Provider>
  );
}
