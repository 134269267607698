import { useToast } from "@/components/ui/use-toast";
import { useLazyQuery } from "@apollo/client";
import { BoxesIcon } from "lucide-react";
import { useEffect, useState } from "react";
import { client } from "../../graphql/client";
import {
  AffectedPublicKeysDocument,
  AffectedPublicKeysOrderBy,
  Transaction,
  TransactionsDocument,
  TransactionsOrderBy,
  TransactionsQuery,
} from "../../graphql/codegen/graphql";
import TableTransactions from "../shared/table-transactions";
import { Skeleton } from "../ui/skeleton";
import { Link } from "react-router-dom";
import { Label } from "../ui/label";
import { Switch } from "../ui/switch";
import { useMobile } from "../../hooks/use-mobile";

const ITEMS_PER_PAGE = 10;

interface RecentTransactionsProps {
  totalTxn?: number;
  publicKey?: string;
  viewAllLink?: string;
  showAffected?: boolean;
}

const RecentTransactions = ({
  totalTxn,
  publicKey,
  viewAllLink = "/txn",
  showAffected = true,
}: RecentTransactionsProps) => {
  const { toast } = useToast();
  const { isMobile } = useMobile();

  const [initLoading, setInitLoading] = useState<boolean>(true);
  const [offset, setOffset] = useState<number>(0);
  const [transactions, setTransactions] = useState<Array<Transaction>>([]);
  const [hasPrevPage, setHasPrevPage] = useState<boolean>(false);
  const [hasNextPage, setHasNextPage] = useState<boolean>(false);
  const [onlyAffectingUserTransactions, setOnlyAffectingUserTransactions] =
    useState<boolean>(false);

  const [fetchTransactionsLazy, { loading: loadingTransactions }] =
    useLazyQuery(TransactionsDocument, {
      client,
    });
  const [
    fetchAffectedUserTransactionsLazy,
    { loading: loadingAffectedUserTransactions },
  ] = useLazyQuery(AffectedPublicKeysDocument, {
    client,
  });

  const fetchTransactions = async (currentOffset: number) => {
    let data;

    if (onlyAffectingUserTransactions) {
      const requestFilters = {
        publicKey: {
          equalTo: publicKey,
        },
        isDuplicate: {
          equalTo: false,
        },
      };

      data = await fetchAffectedUserTransactionsLazy({
        variables: {
          first: ITEMS_PER_PAGE,
          orderBy: [AffectedPublicKeysOrderBy.TimestampDesc],
          offset: currentOffset,
          filter: requestFilters,
          withTotal: false,
        },
      }).then((res) => {
        return {
          transactions: {
            pageInfo: {
              hasNextPage: res.data?.affectedPublicKeys?.pageInfo.hasNextPage,
              hasPreviousPage:
                res.data?.affectedPublicKeys?.pageInfo.hasPreviousPage,
            },
            nodes: res.data?.affectedPublicKeys?.nodes.map(
              (e) => e?.transaction,
            ),
          },
        } as TransactionsQuery;
      });
    } else {
      const requestFilters = publicKey
        ? {
            blockHeight: {
              isNull: false,
            },
            publicKey: {
              equalTo: publicKey,
            },
          }
        : {
            blockHeight: {
              isNull: false,
            },
          };

      const { data: response } = await fetchTransactionsLazy({
        variables: {
          first: ITEMS_PER_PAGE,
          orderBy: [
            TransactionsOrderBy.TimestampDesc,
            TransactionsOrderBy.IndexInBlockAsc,
          ],
          offset: currentOffset,
          filter: requestFilters,
          withTotal: false,
        },
      });

      data = response;
    }

    setHasPrevPage(data?.transactions?.pageInfo.hasPreviousPage || false);
    setHasNextPage(data?.transactions?.pageInfo.hasNextPage || false);

    return data?.transactions?.nodes.filter(
      (e) => e !== null,
    ) as Array<Transaction>;
  };

  const getTransactions = async (currentOffset: number) => {
    try {
      const transactionsList = await fetchTransactions(currentOffset);
      setTransactions(transactionsList || []);
    } catch (e: any) {
      toast({
        variant: "destructive",
        title: "Error",
        description: `There was a problem getting recent transactions. ${JSON.stringify(
          e,
        )}`,
      });
    } finally {
      setInitLoading(false);
    }
  };

  useEffect(() => {
    setOffset(0);
    getTransactions(0);
  }, [publicKey, onlyAffectingUserTransactions]);

  useEffect(() => {
    getTransactions(offset);
  }, [offset]);

  return (
    <div className="my-12">
      <div className="flex justify-between items-center">
        <h3 className="mb-4 flex items-center" id="recent-transactions">
          <BoxesIcon className="mr-2" />
          Recent Transactions
        </h3>

        <div className="flex flex-col items-end gap-2 mb-2">
          {showAffected && (
            <div className="flex items-center space-x-2">
              <Switch
                id="affectedUserTransactions"
                onCheckedChange={setOnlyAffectingUserTransactions}
                checked={onlyAffectingUserTransactions}
              />

              <Label htmlFor="affectedUserTransactions" className="text-xs">
                {isMobile
                  ? "Affecting User"
                  : "Show Transactions Affecting User"}
              </Label>
            </div>
          )}

          <Link to={viewAllLink} className="text-xs hover:underline">
            View All Transactions
          </Link>
        </div>
      </div>

      {initLoading ? (
        <div className="overflow-hidden rounded-xl">
          <Skeleton className="h-[48px] w-full rounded-none mb-1" />
          <Skeleton className="h-[300px] w-full rounded-none" />
        </div>
      ) : (
        <TableTransactions
          transactions={transactions}
          total={totalTxn || null}
          perPage={ITEMS_PER_PAGE}
          offset={offset}
          onPrevPage={() => {
            setOffset((prev) => prev - ITEMS_PER_PAGE);
          }}
          onNextPage={() => {
            setOffset((prev) => prev + ITEMS_PER_PAGE);
          }}
          loadingPage={loadingTransactions || loadingAffectedUserTransactions}
          hasNextPage={hasNextPage}
          hasPrevPage={hasPrevPage}
        />
      )}
    </div>
  );
};

export default RecentTransactions;
