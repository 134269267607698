import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { useLazyQuery } from "@apollo/client";
import { unlockStake } from "deso-protocol";
import { LucideLock, LucideUnlock } from "lucide-react";
import { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { ActiveAccountContext } from "../../contexts/active-account";
import { client } from "../../graphql/client";
import {
  CoreAccountFieldsFragment,
  GetFinalBlockHeightForEpochDocument,
  LockedStakeTableRowFragment,
  StakingOverviewFragment,
} from "../../graphql/codegen/graphql";
import { useTableScroll } from "../../hooks/use-table-scroll";
import { EPOCHS_UNTIL_UNLOCKABLE } from "../../utils/constants";
import { desoNanosToDeso, formatDecimalValue } from "../../utils/currency";
import {
  formatDisplayName,
  handleIdentityClosedWithoutInteraction,
  pollForTxnFinalized,
  shortenLongWord,
} from "../../utils/helpers";
import { Button } from "../ui/button";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../ui/table";
import { useToast } from "../ui/use-toast";
import DesoAvatar from "./deso-avatar";
import Spinner from "./spinner";
import JailedBadge from "@/components/shared/jailed-badge";
import { Badge } from "@/components/ui/badge";
import { EpochEntry } from "../../backend/types";

export interface AggregateLockedStakeTableRow {
  validator: CoreAccountFieldsFragment;
  validatorRegistered: boolean;
  lockedAmountNanos: number;
  unlockableAmountNanos: number;
  jailedAtEpochNumber: string;
}

function calculateUnlocksInTime(
  currentBlockHeight: number,
  numBlocksPerEpoch: number,
  lockedStakeEntryFinalBlockHeight: number,
) {
  // NOTE: Assumptions made here are that a locked stake entry needs 2 epochs +
  // 1 block to finalize to become unLockable. 1 block is produced every 1 second, so blocks
  // === seconds in this calculation. If we've got a negative value, it means
  // the stake is already unLockable so just return 0.
  const numBlocksUntilUnLockable =
    EPOCHS_UNTIL_UNLOCKABLE * Number(numBlocksPerEpoch) + 1;
  const unlockBlockHeight =
    Number(lockedStakeEntryFinalBlockHeight) + numBlocksUntilUnLockable;
  const numSecondsUntilUnlockable =
    unlockBlockHeight - Number(currentBlockHeight);

  return numSecondsUntilUnlockable > 0 ? numSecondsUntilUnlockable : 0;
}

function formatSeconds(seconds: number): string {
  const days = Math.floor(seconds / (3600 * 24));
  const hours = Math.floor((seconds % (3600 * 24)) / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const sec = seconds % 60;

  // Pad the numbers to two digits
  const daysStr = String(days).padStart(2, "0");
  const hoursStr = String(hours).padStart(2, "0");
  const minutesStr = String(minutes).padStart(2, "0");
  const secondsStr = String(sec).padStart(2, "0");

  return `${daysStr}d : ${hoursStr}h : ${minutesStr}m : ${secondsStr}s`;
}

const UnlockCountdownTimer = ({
  currentEpochNumber,
  lockedAtEpochNumber,
  onUnlockableStatusChange,
}: {
  currentEpochNumber: number;
  lockedAtEpochNumber: number;
  onUnlockableStatusChange: () => void;
}) => {
  const [_, { data, stopPolling, startPolling }] = useLazyQuery(
    GetFinalBlockHeightForEpochDocument,
    {
      client,
      pollInterval: 1000,
      variables: {
        epochNumber: lockedAtEpochNumber,
      },
    },
  );
  const [numSecondsUntilUnlockable, setNumSecondsUntilUnlockable] = useState(0);

  useEffect(() => {
    if (!data?.blocks?.nodes?.[0]) return;
    if (!data.globalParamsEntries?.nodes?.[0]) return;
    if (!data.epochEntry) return;

    const numSecondsUntilUnlockable = calculateUnlocksInTime(
      data.blocks.nodes[0].height,
      data.globalParamsEntries.nodes[0].epochDurationNumBlocks,
      data.epochEntry.finalBlockHeight,
    );

    if (numSecondsUntilUnlockable <= 0) {
      stopPolling();
      onUnlockableStatusChange();
    }

    setNumSecondsUntilUnlockable(numSecondsUntilUnlockable);
  }, [data]);

  useEffect(() => {
    // We use a sensible base case to prevent fetching the final block height
    // query from running when we know the stake is already unLockable.
    if (Number(lockedAtEpochNumber) < currentEpochNumber - 2) {
      setNumSecondsUntilUnlockable(0);
    } else {
      startPolling(1000);
    }
  }, [lockedAtEpochNumber, currentEpochNumber]);

  return <div>{formatSeconds(numSecondsUntilUnlockable)}</div>;
};

interface TableLockedStakeEntriesProps {
  aggregateRows: AggregateLockedStakeTableRow[];
  allLockedStakeEntries: LockedStakeTableRowFragment[];
  currentEpochEntry: EpochEntry | undefined;
  stakingOverview: StakingOverviewFragment;
  unlockableEpochBoundary: number;
  onUnlockSuccess: () => void;
  refetchStakingOverview: () => Promise<any>;
  total: number | null;
  offset: number;
  perPage: number;
  loadingPage: boolean;
  onPrevPage: () => void;
  onNextPage: () => void;
  hasPrevPage?: boolean;
  hasNextPage?: boolean;
}

const TableLockedStakeEntries = ({
  aggregateRows,
  allLockedStakeEntries,
  unlockableEpochBoundary,
  currentEpochEntry,
  stakingOverview,
  refetchStakingOverview,
  onUnlockSuccess,
  total,
  offset,
  perPage,
  loadingPage,
  onPrevPage,
  onNextPage,
  hasPrevPage,
  hasNextPage,
}: TableLockedStakeEntriesProps) => {
  const { wrapperElement, onPageChange } = useTableScroll();
  const { account } = useContext(ActiveAccountContext);
  const [detailsDialogIsOpen, setDetailsDialogIsOpen] = useState(false);
  const [dialogData, setDialogData] = useState<LockedStakeTableRowFragment[]>(
    [],
  );
  const [isUnlockingMap, setIsUnlockingMap] = useState<{
    [k: string]: boolean;
  }>({});
  const { toast } = useToast();

  const headers = [
    "Validator",
    "Total Locked Stake",
    "Available to Unlock",
    "Action",
  ];

  useEffect(() => {
    if (!detailsDialogIsOpen) {
      setDialogData([]);
    }
  }, [detailsDialogIsOpen]);

  return (
    <div className="w-full" ref={wrapperElement}>
      <div className="relative rounded-2xl border overflow-hidden">
        <Table>
          <TableHeader>
            <TableRow>
              {headers.map((e) => (
                <TableHead
                  key={e}
                  className="last:text-right whitespace-nowrap md:whitespace-normal"
                >
                  {e}
                </TableHead>
              ))}
            </TableRow>
          </TableHeader>
          <TableBody>
            {aggregateRows.map((aggregateStake) => (
              <TableRow
                className="hover:bg-card dark:hover:bg-black/10 border-border-light"
                key={aggregateStake.validator.publicKey}
              >
                <TableCell className="align-middle py-2 px-4 text-xs whitespace-nowrap">
                  <div className="flex items-start gap-3">
                    <DesoAvatar
                      size={40}
                      publicKey={aggregateStake.validator.publicKey || ""}
                      username={aggregateStake.validator.username || ""}
                      clickable={true}
                      linkType="validator"
                      className="mb-1 cursor-pointer border w-10 h-10 hover:border-secondary"
                    />
                    <div className="flex flex-col items-start gap-1">
                      <div className=" flex items-center gap-2">
                        <div className="text-sm text-muted-foreground hover:underline underline-offset-4">
                          <Link
                            to={`../${
                              aggregateStake.validatorRegistered
                                ? "validator"
                                : "u"
                            }/${
                              aggregateStake.validator.username ??
                              aggregateStake.validator.publicKey
                            }`}
                          >
                            {formatDisplayName(
                              aggregateStake.validator.username,
                              aggregateStake.validator.publicKey,
                              true,
                            )}
                          </Link>
                        </div>
                        {aggregateStake.validatorRegistered &&
                          aggregateStake.jailedAtEpochNumber !== "0" && (
                            <JailedBadge className="text-[10px] py-0 px-1.5" />
                          )}
                        {!aggregateStake.validatorRegistered && (
                          <Badge
                            variant="destructive"
                            className="bg-red-600 text-white text-[10px] py-0 px-1.5"
                          >
                            Unregistered
                          </Badge>
                        )}
                      </div>
                      <div className="text-xs text-muted font-mono">
                        {shortenLongWord(
                          aggregateStake.validator.publicKey,
                          7,
                          7,
                        )}
                      </div>
                    </div>
                  </div>
                </TableCell>
                <TableCell>
                  <div className="flex flex-col items-start gap-1">
                    <div className="text-sm flex items-center text-muted-foreground">
                      <div className="flex items-center gap-2">
                        <LucideLock className="text-red-500 w-4 h-4" />
                        {formatDecimalValue(
                          desoNanosToDeso(aggregateStake.lockedAmountNanos),
                        )}{" "}
                      </div>{" "}
                      <img
                        src="/assets/img/logo-deso-mark.svg"
                        alt="DESO"
                        className="h-[14px] ml-2 inline-block"
                      />
                    </div>
                  </div>
                </TableCell>
                <TableCell>
                  <div className="flex flex-col items-start gap-1">
                    <div className="text-sm flex items-center text-muted-foreground">
                      <div className="flex items-center gap-2">
                        <LucideUnlock className="text-green-500 w-4 h-4" />
                        {formatDecimalValue(
                          desoNanosToDeso(aggregateStake.unlockableAmountNanos),
                        )}{" "}
                      </div>{" "}
                      <img
                        src="/assets/img/logo-deso-mark.svg"
                        alt="DESO"
                        className="h-[14px] ml-2 inline-block"
                      />
                    </div>
                  </div>
                </TableCell>
                <TableCell>
                  <div className="flex flex-row justify-end gap-2">
                    <Button
                      disabled={
                        isUnlockingMap[aggregateStake.validator.publicKey] ||
                        !aggregateStake.unlockableAmountNanos
                      }
                      onClick={async () => {
                        if (isUnlockingMap[aggregateStake.validator.publicKey])
                          return;

                        if (!account?.publicKey) {
                          toast({
                            variant: "destructive",
                            title: "Error",
                            description: `Cannot unlock stake without a logged in user.`,
                          });
                          return;
                        }

                        try {
                          setIsUnlockingMap({
                            ...isUnlockingMap,
                            [aggregateStake.validator.publicKey]: true,
                          });
                          const resp = await unlockStake(
                            {
                              TransactorPublicKeyBase58Check: account.publicKey,
                              ValidatorPublicKeyBase58Check:
                                aggregateStake.validator.publicKey,
                              StartEpochNumber: 0,
                              EndEpochNumber: Number(unlockableEpochBoundary),
                              MinFeeRateNanosPerKB: 1000, // TODO: deso-js should handle this better.
                            },
                            {
                              localConstruction: true,
                            },
                          );

                          if (!resp.submittedTransactionResponse?.TxnHashHex) {
                            throw new Error(
                              "Transaction submission succeeded, but no txn hash was returned.",
                            );
                          }

                          await pollForTxnFinalized(
                            resp.submittedTransactionResponse?.TxnHashHex,
                          );

                          await refetchStakingOverview();
                          setIsUnlockingMap({
                            ...isUnlockingMap,
                            [aggregateStake.validator.publicKey]: false,
                          });
                          onUnlockSuccess();
                        } catch (error: any) {
                          handleIdentityClosedWithoutInteraction(
                            error,
                            `There was a problem unlocking stake: ${error.message}`,
                          );
                        }
                        setIsUnlockingMap({
                          ...isUnlockingMap,
                          [aggregateStake.validator.publicKey]: false,
                        });
                      }}
                    >
                      {isUnlockingMap[aggregateStake.validator.publicKey] ? (
                        <Spinner />
                      ) : (
                        "Unlock"
                      )}
                    </Button>
                    <Dialog
                      open={detailsDialogIsOpen}
                      onOpenChange={setDetailsDialogIsOpen}
                    >
                      <DialogTrigger>
                        <Button
                          variant="outline"
                          onClick={async () => {
                            const filteredStakeEntries =
                              allLockedStakeEntries.filter(
                                (le) =>
                                  le.validatorAccount?.publicKey ===
                                  aggregateStake.validator.publicKey,
                              );

                            setDialogData(filteredStakeEntries);

                            setDetailsDialogIsOpen(true);
                          }}
                        >
                          View Details
                        </Button>
                      </DialogTrigger>
                      <DialogContent className="max-w-max">
                        <DialogHeader className="mt-0">
                          <DialogTitle>Locked Stake Entries</DialogTitle>
                        </DialogHeader>
                        <div className="flex items-center gap-3">
                          <DesoAvatar
                            size={40}
                            publicKey={
                              dialogData?.[0]?.validatorAccount?.publicKey || ""
                            }
                            username={
                              dialogData?.[0]?.validatorAccount?.username || ""
                            }
                            clickable={true}
                            className="mb-1 cursor-pointer border w-10 h-10 hover:border-secondary"
                          />
                          <div className="flex flex-col items-start gap-1">
                            <div className=" flex items-center gap-2">
                              <div className="text-sm text-muted-foreground hover:underline underline-offset-4">
                                <Link
                                  to={`../${
                                    dialogData?.[0]?.validatorEntry
                                      ? "validator"
                                      : "u"
                                  }/${
                                    dialogData?.[0]?.validatorAccount
                                      ?.username ??
                                    (dialogData?.[0]?.validatorAccount
                                      ?.publicKey ||
                                      "")
                                  }`}
                                >
                                  {formatDisplayName(
                                    dialogData?.[0]?.validatorAccount?.username,
                                    dialogData?.[0]?.validatorAccount
                                      ?.publicKey,
                                    true,
                                  )}
                                </Link>
                              </div>
                              {dialogData?.[0]?.validatorEntry &&
                                dialogData?.[0]?.validatorEntry
                                  ?.jailedAtEpochNumber !== "0" && (
                                  <JailedBadge className="text-[10px] py-0 px-1.5" />
                                )}
                              {!dialogData?.[0]?.validatorEntry && (
                                <Badge
                                  variant="destructive"
                                  className="bg-red-600 text-white text-[10px] py-0 px-1.5"
                                >
                                  Unregistered
                                </Badge>
                              )}
                            </div>
                            <div className="text-xs text-muted font-mono">
                              {shortenLongWord(
                                dialogData?.[0]?.validatorAccount?.publicKey,
                                7,
                                7,
                              )}
                            </div>
                          </div>
                          <div className="text-sm text-muted-foreground ml-auto flex-col flex text-right">
                            Current Epoch:{" "}
                            <span className="text-green-500 border border-green-500 rounded-full px-2 py-1 inline-flex ml-auto mt-1">
                              {BigInt(
                                currentEpochEntry?.EpochNumber ||
                                  stakingOverview?.currentEpochNumber,
                              ).toLocaleString("en-US")}
                            </span>
                          </div>
                        </div>

                        <div className="relative rounded-2xl border overflow-hidden">
                          <Table>
                            <TableHeader>
                              <TableRow>
                                <TableHead className="last:text-right whitespace-nowrap">
                                  Amount
                                </TableHead>
                                <TableHead className="last:text-right whitespace-nowrap">
                                  Unlock Epoch
                                </TableHead>
                                <TableHead className="last:text-right whitespace-nowrap">
                                  Unlocks In
                                </TableHead>
                              </TableRow>
                            </TableHeader>
                            <TableBody>
                              {dialogData?.map((lockedStakeEntry, index) => (
                                <TableRow
                                  className="hover:bg-card dark:hover:bg-black/10 border-border-light"
                                  key={`locked_stake_entry_${index}`}
                                >
                                  <TableCell>
                                    {lockedStakeEntry.lockedAtEpochNumber <
                                    unlockableEpochBoundary ? (
                                      <div className="flex flex-col items-start gap-1">
                                        <div className="text-sm flex items-center text-muted-foreground">
                                          <div className="flex items-center gap-2">
                                            <LucideUnlock className="text-green-500 w-4 h-4" />
                                            {formatDecimalValue(
                                              desoNanosToDeso(
                                                lockedStakeEntry.lockedAmountNanos,
                                              ),
                                            )}{" "}
                                          </div>{" "}
                                          <img
                                            src="/assets/img/logo-deso-mark.svg"
                                            alt="DESO"
                                            className="h-[14px] ml-2 inline-block"
                                          />
                                        </div>
                                      </div>
                                    ) : (
                                      <div className="flex flex-col items-start gap-1">
                                        <div className="text-sm flex items-center text-muted-foreground">
                                          <div className="flex items-center gap-2">
                                            <LucideLock className="text-red-500 w-4 h-4" />
                                            {formatDecimalValue(
                                              desoNanosToDeso(
                                                lockedStakeEntry.lockedAmountNanos,
                                              ),
                                            )}{" "}
                                          </div>{" "}
                                          <img
                                            src="/assets/img/logo-deso-mark.svg"
                                            alt="DESO"
                                            className="h-[14px] ml-2 inline-block"
                                          />
                                        </div>
                                      </div>
                                    )}
                                  </TableCell>
                                  <TableCell>
                                    <div className="flex flex-col items-start gap-1">
                                      <div className="flex items-center gap-3">
                                        <div
                                          className={`text-xl font-semibold font-mono${
                                            lockedStakeEntry.lockedAtEpochNumber <
                                            unlockableEpochBoundary
                                              ? " text-green-600"
                                              : " text-red-500"
                                          }`}
                                        >
                                          {(
                                            BigInt(
                                              lockedStakeEntry.lockedAtEpochNumber,
                                            ) +
                                            BigInt(EPOCHS_UNTIL_UNLOCKABLE + 1)
                                          ).toLocaleString("en-US")}
                                        </div>
                                      </div>
                                    </div>
                                  </TableCell>
                                  <TableCell>
                                    <div className="flex flex-col items-start gap-1">
                                      <div className="text-sm font-mono text-foreground">
                                        <UnlockCountdownTimer
                                          currentEpochNumber={
                                            currentEpochEntry?.EpochNumber ||
                                            stakingOverview?.currentEpochNumber
                                          }
                                          lockedAtEpochNumber={
                                            lockedStakeEntry.lockedAtEpochNumber
                                          }
                                          onUnlockableStatusChange={() => {
                                            refetchStakingOverview();
                                          }}
                                        />
                                      </div>
                                    </div>
                                  </TableCell>
                                </TableRow>
                              ))}
                            </TableBody>
                          </Table>
                        </div>
                      </DialogContent>
                    </Dialog>
                  </div>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>

      <div className="flex items-center justify-end space-x-2 py-4 flex-col md:flex-row gap-4 md:gap-0">
        <div className="flex-1 text-xs text-muted">
          Showing {offset + 1}-{offset + aggregateRows.length}
          {total !== null ? (
            <span> of {formatDecimalValue(total)} locked stake entries</span>
          ) : (
            " transactions"
          )}
        </div>
        <div className="space-x-2">
          <Button
            variant="outline"
            size="sm"
            onClick={() => {
              onPrevPage();
              onPageChange();
            }}
            disabled={hasPrevPage !== undefined ? !hasPrevPage : offset === 0}
            className={loadingPage ? "pointer-events-none cursor-default" : ""}
          >
            Previous
          </Button>

          <Button
            variant="outline"
            size="sm"
            onClick={() => {
              onNextPage();
              onPageChange();
            }}
            disabled={
              hasNextPage !== undefined
                ? !hasNextPage
                : offset + perPage >= (total || 0)
            }
            className={loadingPage ? "pointer-events-none cursor-default" : ""}
          >
            Next
          </Button>
        </div>
      </div>
    </div>
  );
};

export default TableLockedStakeEntries;
