import { NavigationMenuLink } from "@/components/ui/navigation-menu";
import { cn } from "@/lib";
import { ExternalLink } from "lucide-react";
import React, { ReactNode } from "react";
import { Link } from "react-router-dom";

interface ListItemProps {
  className?: string;
  icon?: ReactNode;
  title: string;
  children: ReactNode;
  href?: string;
  target?: string;
  external?: boolean;
}

const ListItem: React.FC<ListItemProps> = ({
  className,
  icon,
  title,
  children,
  external,
  href = "",
  target = "_self",
  ...props
}) => {
  return (
    <li>
      <NavigationMenuLink asChild>
        <Link
          className={cn(
            "block select-none space-y-1 rounded-xl p-3 px-4 leading-none no-underline outline-none transition-colors hover:bg-accent focus:bg-accent focus:text-accent-foreground",
            className,
          )}
          to={href}
          target={target}
          {...props}
        >
          <div>{icon}</div>
          <div className="text-sm font-medium leading-none">
            {title}
            {external && (
              <ExternalLink className="w-[16px] opacity-50 ml-2 relative -top-[2px] inline-block" />
            )}
          </div>
          <p className="line-clamp-2 text-sm leading-snug text-muted">
            {children}
          </p>
        </Link>
      </NavigationMenuLink>
    </li>
  );
};

ListItem.displayName = "ListItem";

export default ListItem;
