import GlobalSearch from "@/components/shared/global-search";
import { VideoModal } from "./video-modal";

const ExploreBlockchain = () => {
  return (
    <div className="text-center w-full md:w-[70%] m-auto my-12">
      <h1 className="text-4xl mb-4 text-black dark:text-white font-semibold">
        Explore the DeSo blockchain
      </h1>

      <p className="mb-6">
        DeSo is the first & only censorship-resistant layer-1 blockchain
        purpose-built to power storage-heavy apps and scale decentralized social
        networks for mass adoption.
      </p>

      <div className="flex relative items-center">
        <GlobalSearch placeholder="Search any username, public key, txn hash, block height or block hash..." />
      </div>

      <VideoModal />
    </div>
  );
};

export default ExploreBlockchain;
