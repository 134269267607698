import { AtomIcon, LineChartIcon, User2Icon } from "lucide-react";
import { Metric } from "../ui/metric";
import PlotTransactions from "@/components/home/plot-transactions";
import { useState } from "react";
import { useQuery } from "@apollo/client";
import {
  DailyActiveWalletCountStat,
  DailyActiveWalletCountStatsDocument,
  DailyActiveWalletCountStatsOrderBy,
  DailyTxnCountStat,
  DailyTxnCountStatsDocument,
  DailyTxnCountStatsOrderBy,
} from "../../graphql/codegen/graphql";
import { client } from "../../graphql/client";
import { Skeleton } from "@/components/ui/skeleton";
import sumBy from "lodash/sumBy";
import { formatDecimalValue } from "../../utils/currency";
import { useMobile } from "../../hooks/use-mobile";

enum TransactionTypes {
  transactionsByDay = "transactionsByDay",
  usersByDay = "usersByDay",
}

export interface TransactionPlotData {
  categories: Array<string>;
  series: Array<number>;
}

const normalizePlotTxnsData = <T extends { day?: string }>(
  series: Array<T | null>,
  field: keyof T,
) => {
  return series.reduce(
    (acc: TransactionPlotData, curr: T | null) => {
      if (!curr || !curr.day) {
        return acc;
      }

      const normalizedDateToISOFormat = `${curr.day}T00:00:00.000Z`;

      return {
        ...acc,
        categories: [...acc.categories, normalizedDateToISOFormat],
        series: [...acc.series, curr[field] as number],
      };
    },
    { categories: [], series: [] } as TransactionPlotData,
  );
};

const NetworkActivity = () => {
  const { isMobile } = useMobile();

  const { data: DailyTxnStats, loading: loadingTxnStats } = useQuery(
    DailyTxnCountStatsDocument,
    {
      client,
      variables: {
        orderBy: DailyTxnCountStatsOrderBy.DayAsc,
      },
    },
  );
  const { data: dailyUserStats, loading: loadingUserStats } = useQuery(
    DailyActiveWalletCountStatsDocument,
    {
      client,
      variables: {
        orderBy: DailyActiveWalletCountStatsOrderBy.DayAsc,
      },
    },
  );

  const [transactionType, setTransactionType] = useState<TransactionTypes>(
    TransactionTypes.transactionsByDay,
  );

  const dailyTxnStatsList = DailyTxnStats?.dailyTxnCountStats?.nodes || [];
  const dailyUserStatsList =
    dailyUserStats?.dailyActiveWalletCountStats?.nodes || [];

  const plotData =
    transactionType === TransactionTypes.transactionsByDay
      ? normalizePlotTxnsData<DailyTxnCountStat>(
          dailyTxnStatsList.slice(0, -1), // remove last day that is today because it's still getting measured
          "transactionCount",
        )
      : normalizePlotTxnsData<DailyActiveWalletCountStat>(
          dailyUserStatsList.slice(0, -1), // remove last day that is today because it's still getting measured
          "count",
        );

  const transactionsTotal30d = sumBy(dailyTxnStatsList, (e) =>
    Number.parseInt(e?.transactionCount || 0),
  );
  const usersTotal30d = sumBy(dailyUserStatsList, (e) =>
    Number.parseInt(e?.count || 0),
  );

  return (
    <>
      <div className="flex justify-between items-center">
        <h3 className="mb-4 flex items-center">
          <AtomIcon className="mr-2" />
          Network Activity
        </h3>
      </div>
      <div className="md:border border-border md:p-4 md:pb-0 rounded-2xl">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          {loadingUserStats || loadingTxnStats ? (
            <>
              <Skeleton className="w-auto h-[80px]" />
              <Skeleton className="w-auto h-[80px]" />
            </>
          ) : (
            <>
              <Metric
                value={formatDecimalValue(transactionsTotal30d)}
                label="Monthly Transactions"
                caption="Last 30d"
                icon={<LineChartIcon />}
                state={
                  transactionType === TransactionTypes.transactionsByDay
                    ? "active"
                    : "inActive"
                }
                onClick={() =>
                  setTransactionType(TransactionTypes.transactionsByDay)
                }
              />
              <Metric
                value={formatDecimalValue(usersTotal30d)}
                label="Monthly Users"
                icon={<User2Icon />}
                caption="Last 30d"
                state={
                  transactionType === TransactionTypes.usersByDay
                    ? "active"
                    : "inActive"
                }
                onClick={() => setTransactionType(TransactionTypes.usersByDay)}
              />
            </>
          )}
        </div>

        {loadingUserStats || loadingTxnStats ? (
          <Skeleton className="min-h-[280px] my-4" />
        ) : (
          <PlotTransactions
            plotData={plotData}
            tooltipLabel={
              transactionType === TransactionTypes.transactionsByDay
                ? "Transactions"
                : "Users"
            }
            tickAmount={isMobile ? 4 : 15}
          />
        )}
      </div>
    </>
  );
};

export default NetworkActivity;
