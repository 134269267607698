import { Link } from "react-router-dom";
import { DIAMOND_APP_URL } from "../../utils/constants";

export const DiamondNFTLink = ({
  NFTId,
  text = "NFT",
}: {
  NFTId: string;
  text?: string;
}) => {
  return (
    <Link
      to={`${DIAMOND_APP_URL}/nft/${NFTId}`}
      className="underline"
      target="_blank"
    >
      {text}
    </Link>
  );
};
