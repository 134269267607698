/* eslint-disable */
import { TypedDocumentNode as DocumentNode } from "@graphql-typed-document-node/core";
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
export type MakeEmpty<
  T extends { [key: string]: unknown },
  K extends keyof T,
> = { [_ in K]?: never };
export type Incremental<T> =
  | T
  | {
      [P in keyof T]?: P extends " $fragmentName" | "__typename" ? T[P] : never;
    };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  /** A floating point number that requires more precision than IEEE 754 binary 64 */
  BigFloat: { input: any; output: any };
  /**
   * A signed eight-byte integer. The upper big integer values are greater than the
   * max value for a JavaScript number. Therefore all big integers will be output as
   * strings and not numbers.
   */
  BigInt: { input: any; output: any };
  /** A location in a connection that can be used for resuming pagination. */
  Cursor: { input: any; output: any };
  /** The day, does not include a time. */
  Date: { input: any; output: any };
  /**
   * A point in time as described by the [ISO
   * 8601](https://en.wikipedia.org/wiki/ISO_8601) standard. May or may not include a timezone.
   */
  Datetime: { input: any; output: any };
  /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSON: { input: any; output: any };
};

export type AccessGroup = Node & {
  __typename?: "AccessGroup";
  accessGroupKeyName?: Maybe<Scalars["String"]["output"]>;
  /** Reads and enables pagination through a set of `AccessGroupMember`. */
  accessGroupMembers: AccessGroupMembersConnection;
  accessGroupOwnerPublicKey?: Maybe<Scalars["String"]["output"]>;
  accessGroupPublicKey?: Maybe<Scalars["String"]["output"]>;
  extraData?: Maybe<Scalars["JSON"]["output"]>;
  /** Reads and enables pagination through a set of `Message`. */
  groupMessagesReceived: MessagesConnection;
  /** Reads and enables pagination through a set of `Message`. */
  groupMessagesSent: MessagesConnection;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  id: Scalars["ID"]["output"];
  /** Reads a single `Account` that is related to this `AccessGroup`. */
  owner?: Maybe<Account>;
};

export type AccessGroupAccessGroupMembersArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<AccessGroupMemberCondition>;
  filter?: InputMaybe<AccessGroupMemberFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<AccessGroupMembersOrderBy>>;
};

export type AccessGroupGroupMessagesReceivedArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<MessageCondition>;
  filter?: InputMaybe<MessageFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<MessagesOrderBy>>;
};

export type AccessGroupGroupMessagesSentArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<MessageCondition>;
  filter?: InputMaybe<MessageFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<MessagesOrderBy>>;
};

/**
 * A condition to be used against `AccessGroup` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type AccessGroupCondition = {
  /** Checks for equality with the object’s `accessGroupKeyName` field. */
  accessGroupKeyName?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `accessGroupOwnerPublicKey` field. */
  accessGroupOwnerPublicKey?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `accessGroupPublicKey` field. */
  accessGroupPublicKey?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `extraData` field. */
  extraData?: InputMaybe<Scalars["JSON"]["input"]>;
};

/** A filter to be used against `AccessGroup` object types. All fields are combined with a logical ‘and.’ */
export type AccessGroupFilter = {
  /** Filter by the object’s `accessGroupKeyName` field. */
  accessGroupKeyName?: InputMaybe<StringFilter>;
  /** Filter by the object’s `accessGroupMembers` relation. */
  accessGroupMembers?: InputMaybe<AccessGroupToManyAccessGroupMemberFilter>;
  /** Some related `accessGroupMembers` exist. */
  accessGroupMembersExist?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Filter by the object’s `accessGroupOwnerPublicKey` field. */
  accessGroupOwnerPublicKey?: InputMaybe<StringFilter>;
  /** Filter by the object’s `accessGroupPublicKey` field. */
  accessGroupPublicKey?: InputMaybe<StringFilter>;
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<AccessGroupFilter>>;
  /** Filter by the object’s `extraData` field. */
  extraData?: InputMaybe<JsonFilter>;
  /** Filter by the object’s `groupMessagesReceived` relation. */
  groupMessagesReceived?: InputMaybe<AccessGroupToManyMessageFilter>;
  /** Some related `groupMessagesReceived` exist. */
  groupMessagesReceivedExist?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Filter by the object’s `groupMessagesSent` relation. */
  groupMessagesSent?: InputMaybe<AccessGroupToManyMessageFilter>;
  /** Some related `groupMessagesSent` exist. */
  groupMessagesSentExist?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Negates the expression. */
  not?: InputMaybe<AccessGroupFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<AccessGroupFilter>>;
  /** Filter by the object’s `owner` relation. */
  owner?: InputMaybe<AccountFilter>;
  /** A related `owner` exists. */
  ownerExists?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type AccessGroupMember = Node & {
  __typename?: "AccessGroupMember";
  /** Reads a single `AccessGroup` that is related to this `AccessGroupMember`. */
  accessGroup?: Maybe<AccessGroup>;
  accessGroupKeyName: Scalars["String"]["output"];
  accessGroupMemberKeyName: Scalars["String"]["output"];
  accessGroupMemberPublicKey: Scalars["String"]["output"];
  accessGroupOwnerPublicKey: Scalars["String"]["output"];
  encryptedKey?: Maybe<Scalars["String"]["output"]>;
  extraData?: Maybe<Scalars["JSON"]["output"]>;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  id: Scalars["ID"]["output"];
  /** Reads a single `Account` that is related to this `AccessGroupMember`. */
  member?: Maybe<Account>;
};

/**
 * A condition to be used against `AccessGroupMember` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type AccessGroupMemberCondition = {
  /** Checks for equality with the object’s `accessGroupKeyName` field. */
  accessGroupKeyName?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `accessGroupMemberKeyName` field. */
  accessGroupMemberKeyName?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `accessGroupMemberPublicKey` field. */
  accessGroupMemberPublicKey?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `accessGroupOwnerPublicKey` field. */
  accessGroupOwnerPublicKey?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `encryptedKey` field. */
  encryptedKey?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `extraData` field. */
  extraData?: InputMaybe<Scalars["JSON"]["input"]>;
};

/** A filter to be used against `AccessGroupMember` object types. All fields are combined with a logical ‘and.’ */
export type AccessGroupMemberFilter = {
  /** Filter by the object’s `accessGroup` relation. */
  accessGroup?: InputMaybe<AccessGroupFilter>;
  /** Filter by the object’s `accessGroupKeyName` field. */
  accessGroupKeyName?: InputMaybe<StringFilter>;
  /** Filter by the object’s `accessGroupMemberKeyName` field. */
  accessGroupMemberKeyName?: InputMaybe<StringFilter>;
  /** Filter by the object’s `accessGroupMemberPublicKey` field. */
  accessGroupMemberPublicKey?: InputMaybe<StringFilter>;
  /** Filter by the object’s `accessGroupOwnerPublicKey` field. */
  accessGroupOwnerPublicKey?: InputMaybe<StringFilter>;
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<AccessGroupMemberFilter>>;
  /** Filter by the object’s `extraData` field. */
  extraData?: InputMaybe<JsonFilter>;
  /** Filter by the object’s `member` relation. */
  member?: InputMaybe<AccountFilter>;
  /** Negates the expression. */
  not?: InputMaybe<AccessGroupMemberFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<AccessGroupMemberFilter>>;
};

/** A connection to a list of `AccessGroupMember` values. */
export type AccessGroupMembersConnection = {
  __typename?: "AccessGroupMembersConnection";
  /** A list of edges which contains the `AccessGroupMember` and cursor to aid in pagination. */
  edges: Array<AccessGroupMembersEdge>;
  /** A list of `AccessGroupMember` objects. */
  nodes: Array<Maybe<AccessGroupMember>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `AccessGroupMember` you could get from the connection. */
  totalCount: Scalars["Int"]["output"];
};

/** A `AccessGroupMember` edge in the connection. */
export type AccessGroupMembersEdge = {
  __typename?: "AccessGroupMembersEdge";
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars["Cursor"]["output"]>;
  /** The `AccessGroupMember` at the end of the edge. */
  node?: Maybe<AccessGroupMember>;
};

/** Methods to use when ordering `AccessGroupMember`. */
export enum AccessGroupMembersOrderBy {
  AccessGroupKeyNameAsc = "ACCESS_GROUP_KEY_NAME_ASC",
  AccessGroupKeyNameDesc = "ACCESS_GROUP_KEY_NAME_DESC",
  AccessGroupMemberKeyNameAsc = "ACCESS_GROUP_MEMBER_KEY_NAME_ASC",
  AccessGroupMemberKeyNameDesc = "ACCESS_GROUP_MEMBER_KEY_NAME_DESC",
  AccessGroupMemberPublicKeyAsc = "ACCESS_GROUP_MEMBER_PUBLIC_KEY_ASC",
  AccessGroupMemberPublicKeyDesc = "ACCESS_GROUP_MEMBER_PUBLIC_KEY_DESC",
  AccessGroupOwnerPublicKeyAsc = "ACCESS_GROUP_OWNER_PUBLIC_KEY_ASC",
  AccessGroupOwnerPublicKeyDesc = "ACCESS_GROUP_OWNER_PUBLIC_KEY_DESC",
  EncryptedKeyAsc = "ENCRYPTED_KEY_ASC",
  EncryptedKeyDesc = "ENCRYPTED_KEY_DESC",
  ExtraDataAsc = "EXTRA_DATA_ASC",
  ExtraDataDesc = "EXTRA_DATA_DESC",
  Natural = "NATURAL",
  PrimaryKeyAsc = "PRIMARY_KEY_ASC",
  PrimaryKeyDesc = "PRIMARY_KEY_DESC",
}

/** A filter to be used against many `AccessGroupMember` object types. All fields are combined with a logical ‘and.’ */
export type AccessGroupToManyAccessGroupMemberFilter = {
  /** Every related `AccessGroupMember` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<AccessGroupMemberFilter>;
  /** No related `AccessGroupMember` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<AccessGroupMemberFilter>;
  /** Some related `AccessGroupMember` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<AccessGroupMemberFilter>;
};

/** A filter to be used against many `Message` object types. All fields are combined with a logical ‘and.’ */
export type AccessGroupToManyMessageFilter = {
  /** Every related `Message` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<MessageFilter>;
  /** No related `Message` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<MessageFilter>;
  /** Some related `Message` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<MessageFilter>;
};

/** A connection to a list of `AccessGroup` values. */
export type AccessGroupsConnection = {
  __typename?: "AccessGroupsConnection";
  /** A list of edges which contains the `AccessGroup` and cursor to aid in pagination. */
  edges: Array<AccessGroupsEdge>;
  /** A list of `AccessGroup` objects. */
  nodes: Array<Maybe<AccessGroup>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `AccessGroup` you could get from the connection. */
  totalCount: Scalars["Int"]["output"];
};

/** A `AccessGroup` edge in the connection. */
export type AccessGroupsEdge = {
  __typename?: "AccessGroupsEdge";
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars["Cursor"]["output"]>;
  /** The `AccessGroup` at the end of the edge. */
  node?: Maybe<AccessGroup>;
};

/** Methods to use when ordering `AccessGroup`. */
export enum AccessGroupsOrderBy {
  AccessGroupKeyNameAsc = "ACCESS_GROUP_KEY_NAME_ASC",
  AccessGroupKeyNameDesc = "ACCESS_GROUP_KEY_NAME_DESC",
  AccessGroupOwnerPublicKeyAsc = "ACCESS_GROUP_OWNER_PUBLIC_KEY_ASC",
  AccessGroupOwnerPublicKeyDesc = "ACCESS_GROUP_OWNER_PUBLIC_KEY_DESC",
  AccessGroupPublicKeyAsc = "ACCESS_GROUP_PUBLIC_KEY_ASC",
  AccessGroupPublicKeyDesc = "ACCESS_GROUP_PUBLIC_KEY_DESC",
  ExtraDataAsc = "EXTRA_DATA_ASC",
  ExtraDataDesc = "EXTRA_DATA_DESC",
  Natural = "NATURAL",
  PrimaryKeyAsc = "PRIMARY_KEY_ASC",
  PrimaryKeyDesc = "PRIMARY_KEY_DESC",
}

export type Account = Node & {
  __typename?: "Account";
  /** Reads and enables pagination through a set of `AccessGroupMember`. */
  accessGroupMemberships: AccessGroupMembersConnection;
  /** Reads and enables pagination through a set of `AccessGroup`. */
  accessGroupsOwned: AccessGroupsConnection;
  /** Reads and enables pagination through a set of `StakeRewardsByValidatorAndStaker`. */
  accountStakeRewardsByStaker: StakeRewardsByValidatorAndStakersConnection;
  /** Reads and enables pagination through a set of `StakeRewardsByValidatorAndStaker`. */
  accountStakeRewardsByValidator: StakeRewardsByValidatorAndStakersConnection;
  /** Reads and enables pagination through a set of `DesoTokenLimitOrderBidAsk`. */
  bidAskAsBuyingToken: DesoTokenLimitOrderBidAsksConnection;
  /** Reads and enables pagination through a set of `DesoTokenLimitOrderBidAsk`. */
  bidAskAsSellingToken: DesoTokenLimitOrderBidAsksConnection;
  /** Reads and enables pagination through a set of `BlsPublicKeyPkidPairSnapshotEntry`. */
  blsPublicKeyPkidPairSnapshotEntries: BlsPublicKeyPkidPairSnapshotEntriesConnection;
  ccCoinsInCirculationNanos?: Maybe<Scalars["BigInt"]["output"]>;
  coinPriceDesoNanos?: Maybe<Scalars["BigFloat"]["output"]>;
  coinWatermarkNanos?: Maybe<Scalars["BigInt"]["output"]>;
  creatorBasisPoints?: Maybe<Scalars["BigInt"]["output"]>;
  /** Reads and enables pagination through a set of `CreatorCoinBalance`. */
  creatorCoinBalances: CreatorCoinBalancesConnection;
  /** Reads and enables pagination through a set of `CreatorCoinBalance`. */
  creatorCoinBalancesAsCreator: CreatorCoinBalancesConnection;
  daoCoinMintingDisabled?: Maybe<Scalars["Boolean"]["output"]>;
  daoCoinTransferRestrictionStatus?: Maybe<Scalars["Int"]["output"]>;
  daoCoinsInCirculationNanosHex?: Maybe<Scalars["String"]["output"]>;
  /** Reads and enables pagination through a set of `DerivedKey`. */
  derivedKeys: DerivedKeysConnection;
  description?: Maybe<Scalars["String"]["output"]>;
  /** Reads a single `DesoBalance` that is related to this `Account`. */
  desoBalance?: Maybe<DesoBalance>;
  desoLockedNanos?: Maybe<Scalars["BigInt"]["output"]>;
  /** Reads and enables pagination through a set of `DesoTokenLimitOrder`. */
  desoTokenLimitOrderByCreatorBought: DesoTokenLimitOrdersConnection;
  /** Reads and enables pagination through a set of `DesoTokenLimitOrder`. */
  desoTokenLimitOrderByCreatorSold: DesoTokenLimitOrdersConnection;
  /** Reads and enables pagination through a set of `DesoTokenLimitOrder`. */
  desoTokenLimitOrderByTransactor: DesoTokenLimitOrdersConnection;
  /** Reads and enables pagination through a set of `Diamond`. */
  diamondsReceived: DiamondsConnection;
  /** Reads and enables pagination through a set of `Diamond`. */
  diamondsSent: DiamondsConnection;
  extraData?: Maybe<Scalars["JSON"]["output"]>;
  /** Reads and enables pagination through a set of `Follow`. */
  followers: FollowsConnection;
  /** Reads and enables pagination through a set of `Follow`. */
  following: FollowsConnection;
  /** Reads and enables pagination through a set of `LockedBalanceEntry`. */
  hodlerLockedBalanceEntries: LockedBalanceEntriesConnection;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  id: Scalars["ID"]["output"];
  /** Reads and enables pagination through a set of `JailedHistoryEvent`. */
  jailedHistoryEvents: JailedHistoryEventsConnection;
  /** Reads and enables pagination through a set of `LeaderScheduleEntry`. */
  leaderScheduleEntries: LeaderScheduleEntriesConnection;
  /** Reads and enables pagination through a set of `LegacyMessage`. */
  legacyMessagesReceived: LegacyMessagesConnection;
  /** Reads and enables pagination through a set of `LegacyMessage`. */
  legacyMessagesSent: LegacyMessagesConnection;
  /** Reads and enables pagination through a set of `Like`. */
  likes: LikesConnection;
  /** Reads and enables pagination through a set of `LockedStakeEntry`. */
  lockedStakeEntries: LockedStakeEntriesConnection;
  /** Reads and enables pagination through a set of `Message`. */
  messagesReceived: MessagesConnection;
  /** Reads and enables pagination through a set of `Message`. */
  messagesSent: MessagesConnection;
  metadata?: Maybe<AccountMetadata>;
  mintingDisabled?: Maybe<Scalars["Boolean"]["output"]>;
  /** Reads a single `MyStakeSummary` that is related to this `Account`. */
  myStakeSummary?: Maybe<MyStakeSummary>;
  /** Reads and enables pagination through a set of `NftBid`. */
  nftBids: NftBidsConnection;
  /** Reads and enables pagination through a set of `Nft`. */
  nftsAsLastOwner: NftsConnection;
  /** Reads and enables pagination through a set of `Nft`. */
  nftsOwned: NftsConnection;
  pkid?: Maybe<Scalars["String"]["output"]>;
  /** Reads and enables pagination through a set of `PostAssociation`. */
  postAssociations: PostAssociationsConnection;
  /** Reads and enables pagination through a set of `PostAssociation`. */
  postAssociationsAsAppOwner: PostAssociationsConnection;
  /** Reads and enables pagination through a set of `Post`. */
  posts: PostsConnection;
  /** Reads a single `Profile` that is related to this `Account`. */
  profile?: Maybe<Profile>;
  /** Reads and enables pagination through a set of `LockedBalanceEntry`. */
  profileLockedBalanceEntries: LockedBalanceEntriesConnection;
  profilePic?: Maybe<Scalars["String"]["output"]>;
  /** Reads and enables pagination through a set of `EpochDetailsForBlock`. */
  proposedBlockHashes: EpochDetailsForBlocksConnection;
  publicKey: Scalars["String"]["output"];
  /** Reads and enables pagination through a set of `StakeEntry`. */
  stakeEntries: StakeEntriesConnection;
  /** Reads and enables pagination through a set of `StakeReward`. */
  stakeRewards: StakeRewardsConnection;
  /** Reads and enables pagination through a set of `TokenBalance`. */
  tokenBalances: TokenBalancesConnection;
  /** Reads and enables pagination through a set of `TokenBalance`. */
  tokenBalancesAsCreator: TokenBalancesConnection;
  /** Reads and enables pagination through a set of `AffectedPublicKey`. */
  transactionHashes: AffectedPublicKeysConnection;
  /** Reads a single `ProfileTransactionStat` that is related to this `Account`. */
  transactionStats?: Maybe<ProfileTransactionStat>;
  /** Reads and enables pagination through a set of `Transaction`. */
  transactions: TransactionsConnection;
  /** Reads and enables pagination through a set of `UserAssociation`. */
  userAssociationsAsAppOwner: UserAssociationsConnection;
  /** Reads and enables pagination through a set of `UserAssociation`. */
  userAssociationsAsTarget: UserAssociationsConnection;
  /** Reads and enables pagination through a set of `UserAssociation`. */
  userAssociationsAsTransactor: UserAssociationsConnection;
  username?: Maybe<Scalars["String"]["output"]>;
  /** Reads a single `ValidatorEntry` that is related to this `Account`. */
  validatorEntry?: Maybe<ValidatorEntry>;
  /** Reads and enables pagination through a set of `LockedStakeEntry`. */
  validatorLockedStakeEntries: LockedStakeEntriesConnection;
  /** Reads and enables pagination through a set of `StakeEntry`. */
  validatorStakeEntries: StakeEntriesConnection;
  /** Reads and enables pagination through a set of `StakeReward`. */
  validatorStakeRewards: StakeRewardsConnection;
  /** Reads and enables pagination through a set of `YieldCurvePoint`. */
  yieldCurvePoints: YieldCurvePointsConnection;
};

export type AccountAccessGroupMembershipsArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<AccessGroupMemberCondition>;
  filter?: InputMaybe<AccessGroupMemberFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<AccessGroupMembersOrderBy>>;
};

export type AccountAccessGroupsOwnedArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<AccessGroupCondition>;
  filter?: InputMaybe<AccessGroupFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<AccessGroupsOrderBy>>;
};

export type AccountAccountStakeRewardsByStakerArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<StakeRewardsByValidatorAndStakerCondition>;
  filter?: InputMaybe<StakeRewardsByValidatorAndStakerFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<StakeRewardsByValidatorAndStakersOrderBy>>;
};

export type AccountAccountStakeRewardsByValidatorArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<StakeRewardsByValidatorAndStakerCondition>;
  filter?: InputMaybe<StakeRewardsByValidatorAndStakerFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<StakeRewardsByValidatorAndStakersOrderBy>>;
};

export type AccountBidAskAsBuyingTokenArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<DesoTokenLimitOrderBidAskCondition>;
  filter?: InputMaybe<DesoTokenLimitOrderBidAskFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<DesoTokenLimitOrderBidAsksOrderBy>>;
};

export type AccountBidAskAsSellingTokenArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<DesoTokenLimitOrderBidAskCondition>;
  filter?: InputMaybe<DesoTokenLimitOrderBidAskFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<DesoTokenLimitOrderBidAsksOrderBy>>;
};

export type AccountBlsPublicKeyPkidPairSnapshotEntriesArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<BlsPublicKeyPkidPairSnapshotEntryCondition>;
  filter?: InputMaybe<BlsPublicKeyPkidPairSnapshotEntryFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<BlsPublicKeyPkidPairSnapshotEntriesOrderBy>>;
};

export type AccountCreatorCoinBalancesArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<CreatorCoinBalanceCondition>;
  filter?: InputMaybe<CreatorCoinBalanceFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<CreatorCoinBalancesOrderBy>>;
};

export type AccountCreatorCoinBalancesAsCreatorArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<CreatorCoinBalanceCondition>;
  filter?: InputMaybe<CreatorCoinBalanceFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<CreatorCoinBalancesOrderBy>>;
};

export type AccountDerivedKeysArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<DerivedKeyCondition>;
  filter?: InputMaybe<DerivedKeyFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<DerivedKeysOrderBy>>;
};

export type AccountDesoTokenLimitOrderByCreatorBoughtArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<DesoTokenLimitOrderCondition>;
  filter?: InputMaybe<DesoTokenLimitOrderFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<DesoTokenLimitOrdersOrderBy>>;
};

export type AccountDesoTokenLimitOrderByCreatorSoldArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<DesoTokenLimitOrderCondition>;
  filter?: InputMaybe<DesoTokenLimitOrderFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<DesoTokenLimitOrdersOrderBy>>;
};

export type AccountDesoTokenLimitOrderByTransactorArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<DesoTokenLimitOrderCondition>;
  filter?: InputMaybe<DesoTokenLimitOrderFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<DesoTokenLimitOrdersOrderBy>>;
};

export type AccountDiamondsReceivedArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<DiamondCondition>;
  filter?: InputMaybe<DiamondFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<DiamondsOrderBy>>;
};

export type AccountDiamondsSentArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<DiamondCondition>;
  filter?: InputMaybe<DiamondFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<DiamondsOrderBy>>;
};

export type AccountFollowersArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<FollowCondition>;
  filter?: InputMaybe<FollowFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<FollowsOrderBy>>;
};

export type AccountFollowingArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<FollowCondition>;
  filter?: InputMaybe<FollowFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<FollowsOrderBy>>;
};

export type AccountHodlerLockedBalanceEntriesArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<LockedBalanceEntryCondition>;
  filter?: InputMaybe<LockedBalanceEntryFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<LockedBalanceEntriesOrderBy>>;
};

export type AccountJailedHistoryEventsArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<JailedHistoryEventCondition>;
  filter?: InputMaybe<JailedHistoryEventFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<JailedHistoryEventsOrderBy>>;
};

export type AccountLeaderScheduleEntriesArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<LeaderScheduleEntryCondition>;
  filter?: InputMaybe<LeaderScheduleEntryFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<LeaderScheduleEntriesOrderBy>>;
};

export type AccountLegacyMessagesReceivedArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<LegacyMessageCondition>;
  filter?: InputMaybe<LegacyMessageFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<LegacyMessagesOrderBy>>;
};

export type AccountLegacyMessagesSentArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<LegacyMessageCondition>;
  filter?: InputMaybe<LegacyMessageFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<LegacyMessagesOrderBy>>;
};

export type AccountLikesArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<LikeCondition>;
  filter?: InputMaybe<LikeFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<LikesOrderBy>>;
};

export type AccountLockedStakeEntriesArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<LockedStakeEntryCondition>;
  filter?: InputMaybe<LockedStakeEntryFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<LockedStakeEntriesOrderBy>>;
};

export type AccountMessagesReceivedArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<MessageCondition>;
  filter?: InputMaybe<MessageFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<MessagesOrderBy>>;
};

export type AccountMessagesSentArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<MessageCondition>;
  filter?: InputMaybe<MessageFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<MessagesOrderBy>>;
};

export type AccountNftBidsArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<NftBidCondition>;
  filter?: InputMaybe<NftBidFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<NftBidsOrderBy>>;
};

export type AccountNftsAsLastOwnerArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<NftCondition>;
  filter?: InputMaybe<NftFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<NftsOrderBy>>;
};

export type AccountNftsOwnedArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<NftCondition>;
  filter?: InputMaybe<NftFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<NftsOrderBy>>;
};

export type AccountPostAssociationsArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<PostAssociationCondition>;
  filter?: InputMaybe<PostAssociationFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<PostAssociationsOrderBy>>;
};

export type AccountPostAssociationsAsAppOwnerArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<PostAssociationCondition>;
  filter?: InputMaybe<PostAssociationFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<PostAssociationsOrderBy>>;
};

export type AccountPostsArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<PostCondition>;
  filter?: InputMaybe<PostFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<PostsOrderBy>>;
};

export type AccountProfileLockedBalanceEntriesArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<LockedBalanceEntryCondition>;
  filter?: InputMaybe<LockedBalanceEntryFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<LockedBalanceEntriesOrderBy>>;
};

export type AccountProposedBlockHashesArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<EpochDetailsForBlockCondition>;
  filter?: InputMaybe<EpochDetailsForBlockFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<EpochDetailsForBlocksOrderBy>>;
};

export type AccountStakeEntriesArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<StakeEntryCondition>;
  filter?: InputMaybe<StakeEntryFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<StakeEntriesOrderBy>>;
};

export type AccountStakeRewardsArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<StakeRewardCondition>;
  filter?: InputMaybe<StakeRewardFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<StakeRewardsOrderBy>>;
};

export type AccountTokenBalancesArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<TokenBalanceCondition>;
  filter?: InputMaybe<TokenBalanceFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<TokenBalancesOrderBy>>;
};

export type AccountTokenBalancesAsCreatorArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<TokenBalanceCondition>;
  filter?: InputMaybe<TokenBalanceFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<TokenBalancesOrderBy>>;
};

export type AccountTransactionHashesArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<AffectedPublicKeyCondition>;
  filter?: InputMaybe<AffectedPublicKeyFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<AffectedPublicKeysOrderBy>>;
};

export type AccountTransactionsArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<TransactionCondition>;
  filter?: InputMaybe<TransactionFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<TransactionsOrderBy>>;
};

export type AccountUserAssociationsAsAppOwnerArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<UserAssociationCondition>;
  filter?: InputMaybe<UserAssociationFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<UserAssociationsOrderBy>>;
};

export type AccountUserAssociationsAsTargetArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<UserAssociationCondition>;
  filter?: InputMaybe<UserAssociationFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<UserAssociationsOrderBy>>;
};

export type AccountUserAssociationsAsTransactorArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<UserAssociationCondition>;
  filter?: InputMaybe<UserAssociationFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<UserAssociationsOrderBy>>;
};

export type AccountValidatorLockedStakeEntriesArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<LockedStakeEntryCondition>;
  filter?: InputMaybe<LockedStakeEntryFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<LockedStakeEntriesOrderBy>>;
};

export type AccountValidatorStakeEntriesArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<StakeEntryCondition>;
  filter?: InputMaybe<StakeEntryFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<StakeEntriesOrderBy>>;
};

export type AccountValidatorStakeRewardsArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<StakeRewardCondition>;
  filter?: InputMaybe<StakeRewardFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<StakeRewardsOrderBy>>;
};

export type AccountYieldCurvePointsArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<YieldCurvePointCondition>;
  filter?: InputMaybe<YieldCurvePointFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<YieldCurvePointsOrderBy>>;
};

/** A condition to be used against `Account` object types. All fields are tested for equality and combined with a logical ‘and.’ */
export type AccountCondition = {
  /** Checks for equality with the object’s `ccCoinsInCirculationNanos` field. */
  ccCoinsInCirculationNanos?: InputMaybe<Scalars["BigInt"]["input"]>;
  /** Checks for equality with the object’s `coinPriceDesoNanos` field. */
  coinPriceDesoNanos?: InputMaybe<Scalars["BigFloat"]["input"]>;
  /** Checks for equality with the object’s `coinWatermarkNanos` field. */
  coinWatermarkNanos?: InputMaybe<Scalars["BigInt"]["input"]>;
  /** Checks for equality with the object’s `creatorBasisPoints` field. */
  creatorBasisPoints?: InputMaybe<Scalars["BigInt"]["input"]>;
  /** Checks for equality with the object’s `daoCoinMintingDisabled` field. */
  daoCoinMintingDisabled?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Checks for equality with the object’s `daoCoinTransferRestrictionStatus` field. */
  daoCoinTransferRestrictionStatus?: InputMaybe<Scalars["Int"]["input"]>;
  /** Checks for equality with the object’s `daoCoinsInCirculationNanosHex` field. */
  daoCoinsInCirculationNanosHex?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `description` field. */
  description?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `desoLockedNanos` field. */
  desoLockedNanos?: InputMaybe<Scalars["BigInt"]["input"]>;
  /** Checks for equality with the object’s `extraData` field. */
  extraData?: InputMaybe<Scalars["JSON"]["input"]>;
  /** Checks for equality with the object’s `mintingDisabled` field. */
  mintingDisabled?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Checks for equality with the object’s `pkid` field. */
  pkid?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `profilePic` field. */
  profilePic?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `publicKey` field. */
  publicKey?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `username` field. */
  username?: InputMaybe<Scalars["String"]["input"]>;
};

/** A filter to be used against `Account` object types. All fields are combined with a logical ‘and.’ */
export type AccountFilter = {
  /** Filter by the object’s `accessGroupMemberships` relation. */
  accessGroupMemberships?: InputMaybe<AccountToManyAccessGroupMemberFilter>;
  /** Some related `accessGroupMemberships` exist. */
  accessGroupMembershipsExist?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Filter by the object’s `accessGroupsOwned` relation. */
  accessGroupsOwned?: InputMaybe<AccountToManyAccessGroupFilter>;
  /** Some related `accessGroupsOwned` exist. */
  accessGroupsOwnedExist?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Filter by the object’s `accountStakeRewardsByStaker` relation. */
  accountStakeRewardsByStaker?: InputMaybe<AccountToManyStakeRewardsByValidatorAndStakerFilter>;
  /** Some related `accountStakeRewardsByStaker` exist. */
  accountStakeRewardsByStakerExist?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Filter by the object’s `accountStakeRewardsByValidator` relation. */
  accountStakeRewardsByValidator?: InputMaybe<AccountToManyStakeRewardsByValidatorAndStakerFilter>;
  /** Some related `accountStakeRewardsByValidator` exist. */
  accountStakeRewardsByValidatorExist?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<AccountFilter>>;
  /** Filter by the object’s `bidAskAsBuyingToken` relation. */
  bidAskAsBuyingToken?: InputMaybe<AccountToManyDesoTokenLimitOrderBidAskFilter>;
  /** Some related `bidAskAsBuyingToken` exist. */
  bidAskAsBuyingTokenExist?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Filter by the object’s `bidAskAsSellingToken` relation. */
  bidAskAsSellingToken?: InputMaybe<AccountToManyDesoTokenLimitOrderBidAskFilter>;
  /** Some related `bidAskAsSellingToken` exist. */
  bidAskAsSellingTokenExist?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Filter by the object’s `blsPublicKeyPkidPairSnapshotEntries` relation. */
  blsPublicKeyPkidPairSnapshotEntries?: InputMaybe<AccountToManyBlsPublicKeyPkidPairSnapshotEntryFilter>;
  /** Some related `blsPublicKeyPkidPairSnapshotEntries` exist. */
  blsPublicKeyPkidPairSnapshotEntriesExist?: InputMaybe<
    Scalars["Boolean"]["input"]
  >;
  /** Filter by the object’s `ccCoinsInCirculationNanos` field. */
  ccCoinsInCirculationNanos?: InputMaybe<BigIntFilter>;
  /** Filter by the object’s `coinPriceDesoNanos` field. */
  coinPriceDesoNanos?: InputMaybe<BigFloatFilter>;
  /** Filter by the object’s `coinWatermarkNanos` field. */
  coinWatermarkNanos?: InputMaybe<BigIntFilter>;
  /** Filter by the object’s `creatorBasisPoints` field. */
  creatorBasisPoints?: InputMaybe<BigIntFilter>;
  /** Filter by the object’s `creatorCoinBalances` relation. */
  creatorCoinBalances?: InputMaybe<AccountToManyCreatorCoinBalanceFilter>;
  /** Filter by the object’s `creatorCoinBalancesAsCreator` relation. */
  creatorCoinBalancesAsCreator?: InputMaybe<AccountToManyCreatorCoinBalanceFilter>;
  /** Some related `creatorCoinBalancesAsCreator` exist. */
  creatorCoinBalancesAsCreatorExist?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Some related `creatorCoinBalances` exist. */
  creatorCoinBalancesExist?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Filter by the object’s `daoCoinMintingDisabled` field. */
  daoCoinMintingDisabled?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `daoCoinTransferRestrictionStatus` field. */
  daoCoinTransferRestrictionStatus?: InputMaybe<IntFilter>;
  /** Filter by the object’s `daoCoinsInCirculationNanosHex` field. */
  daoCoinsInCirculationNanosHex?: InputMaybe<StringFilter>;
  /** Filter by the object’s `derivedKeys` relation. */
  derivedKeys?: InputMaybe<AccountToManyDerivedKeyFilter>;
  /** Some related `derivedKeys` exist. */
  derivedKeysExist?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Filter by the object’s `description` field. */
  description?: InputMaybe<StringFilter>;
  /** Filter by the object’s `desoBalance` relation. */
  desoBalance?: InputMaybe<DesoBalanceFilter>;
  /** A related `desoBalance` exists. */
  desoBalanceExists?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Filter by the object’s `desoLockedNanos` field. */
  desoLockedNanos?: InputMaybe<BigIntFilter>;
  /** Filter by the object’s `desoTokenLimitOrderByCreatorBought` relation. */
  desoTokenLimitOrderByCreatorBought?: InputMaybe<AccountToManyDesoTokenLimitOrderFilter>;
  /** Some related `desoTokenLimitOrderByCreatorBought` exist. */
  desoTokenLimitOrderByCreatorBoughtExist?: InputMaybe<
    Scalars["Boolean"]["input"]
  >;
  /** Filter by the object’s `desoTokenLimitOrderByCreatorSold` relation. */
  desoTokenLimitOrderByCreatorSold?: InputMaybe<AccountToManyDesoTokenLimitOrderFilter>;
  /** Some related `desoTokenLimitOrderByCreatorSold` exist. */
  desoTokenLimitOrderByCreatorSoldExist?: InputMaybe<
    Scalars["Boolean"]["input"]
  >;
  /** Filter by the object’s `desoTokenLimitOrderByTransactor` relation. */
  desoTokenLimitOrderByTransactor?: InputMaybe<AccountToManyDesoTokenLimitOrderFilter>;
  /** Some related `desoTokenLimitOrderByTransactor` exist. */
  desoTokenLimitOrderByTransactorExist?: InputMaybe<
    Scalars["Boolean"]["input"]
  >;
  /** Filter by the object’s `diamondsReceived` relation. */
  diamondsReceived?: InputMaybe<AccountToManyDiamondFilter>;
  /** Some related `diamondsReceived` exist. */
  diamondsReceivedExist?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Filter by the object’s `diamondsSent` relation. */
  diamondsSent?: InputMaybe<AccountToManyDiamondFilter>;
  /** Some related `diamondsSent` exist. */
  diamondsSentExist?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Filter by the object’s `extraData` field. */
  extraData?: InputMaybe<JsonFilter>;
  /** Filter by the object’s `followers` relation. */
  followers?: InputMaybe<AccountToManyFollowFilter>;
  /** Some related `followers` exist. */
  followersExist?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Filter by the object’s `following` relation. */
  following?: InputMaybe<AccountToManyFollowFilter>;
  /** Some related `following` exist. */
  followingExist?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Filter by the object’s `hodlerLockedBalanceEntries` relation. */
  hodlerLockedBalanceEntries?: InputMaybe<AccountToManyLockedBalanceEntryFilter>;
  /** Some related `hodlerLockedBalanceEntries` exist. */
  hodlerLockedBalanceEntriesExist?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Filter by the object’s `jailedHistoryEvents` relation. */
  jailedHistoryEvents?: InputMaybe<AccountToManyJailedHistoryEventFilter>;
  /** Some related `jailedHistoryEvents` exist. */
  jailedHistoryEventsExist?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Filter by the object’s `leaderScheduleEntries` relation. */
  leaderScheduleEntries?: InputMaybe<AccountToManyLeaderScheduleEntryFilter>;
  /** Some related `leaderScheduleEntries` exist. */
  leaderScheduleEntriesExist?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Filter by the object’s `legacyMessagesReceived` relation. */
  legacyMessagesReceived?: InputMaybe<AccountToManyLegacyMessageFilter>;
  /** Some related `legacyMessagesReceived` exist. */
  legacyMessagesReceivedExist?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Filter by the object’s `legacyMessagesSent` relation. */
  legacyMessagesSent?: InputMaybe<AccountToManyLegacyMessageFilter>;
  /** Some related `legacyMessagesSent` exist. */
  legacyMessagesSentExist?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Filter by the object’s `likes` relation. */
  likes?: InputMaybe<AccountToManyLikeFilter>;
  /** Some related `likes` exist. */
  likesExist?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Filter by the object’s `lockedStakeEntries` relation. */
  lockedStakeEntries?: InputMaybe<AccountToManyLockedStakeEntryFilter>;
  /** Some related `lockedStakeEntries` exist. */
  lockedStakeEntriesExist?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Filter by the object’s `messagesReceived` relation. */
  messagesReceived?: InputMaybe<AccountToManyMessageFilter>;
  /** Some related `messagesReceived` exist. */
  messagesReceivedExist?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Filter by the object’s `messagesSent` relation. */
  messagesSent?: InputMaybe<AccountToManyMessageFilter>;
  /** Some related `messagesSent` exist. */
  messagesSentExist?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Filter by the object’s `mintingDisabled` field. */
  mintingDisabled?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `myStakeSummary` relation. */
  myStakeSummary?: InputMaybe<MyStakeSummaryFilter>;
  /** A related `myStakeSummary` exists. */
  myStakeSummaryExists?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Filter by the object’s `nftBids` relation. */
  nftBids?: InputMaybe<AccountToManyNftBidFilter>;
  /** Some related `nftBids` exist. */
  nftBidsExist?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Filter by the object’s `nftsAsLastOwner` relation. */
  nftsAsLastOwner?: InputMaybe<AccountToManyNftFilter>;
  /** Some related `nftsAsLastOwner` exist. */
  nftsAsLastOwnerExist?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Filter by the object’s `nftsOwned` relation. */
  nftsOwned?: InputMaybe<AccountToManyNftFilter>;
  /** Some related `nftsOwned` exist. */
  nftsOwnedExist?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Negates the expression. */
  not?: InputMaybe<AccountFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<AccountFilter>>;
  /** Filter by the object’s `pkid` field. */
  pkid?: InputMaybe<StringFilter>;
  /** Filter by the object’s `postAssociations` relation. */
  postAssociations?: InputMaybe<AccountToManyPostAssociationFilter>;
  /** Filter by the object’s `postAssociationsAsAppOwner` relation. */
  postAssociationsAsAppOwner?: InputMaybe<AccountToManyPostAssociationFilter>;
  /** Some related `postAssociationsAsAppOwner` exist. */
  postAssociationsAsAppOwnerExist?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Some related `postAssociations` exist. */
  postAssociationsExist?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Filter by the object’s `posts` relation. */
  posts?: InputMaybe<AccountToManyPostFilter>;
  /** Some related `posts` exist. */
  postsExist?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Filter by the object’s `profile` relation. */
  profile?: InputMaybe<ProfileFilter>;
  /** A related `profile` exists. */
  profileExists?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Filter by the object’s `profileLockedBalanceEntries` relation. */
  profileLockedBalanceEntries?: InputMaybe<AccountToManyLockedBalanceEntryFilter>;
  /** Some related `profileLockedBalanceEntries` exist. */
  profileLockedBalanceEntriesExist?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Filter by the object’s `proposedBlockHashes` relation. */
  proposedBlockHashes?: InputMaybe<AccountToManyEpochDetailsForBlockFilter>;
  /** Some related `proposedBlockHashes` exist. */
  proposedBlockHashesExist?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Filter by the object’s `publicKey` field. */
  publicKey?: InputMaybe<StringFilter>;
  /** Filter by the object’s `stakeEntries` relation. */
  stakeEntries?: InputMaybe<AccountToManyStakeEntryFilter>;
  /** Some related `stakeEntries` exist. */
  stakeEntriesExist?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Filter by the object’s `stakeRewards` relation. */
  stakeRewards?: InputMaybe<AccountToManyStakeRewardFilter>;
  /** Some related `stakeRewards` exist. */
  stakeRewardsExist?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Filter by the object’s `tokenBalances` relation. */
  tokenBalances?: InputMaybe<AccountToManyTokenBalanceFilter>;
  /** Filter by the object’s `tokenBalancesAsCreator` relation. */
  tokenBalancesAsCreator?: InputMaybe<AccountToManyTokenBalanceFilter>;
  /** Some related `tokenBalancesAsCreator` exist. */
  tokenBalancesAsCreatorExist?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Some related `tokenBalances` exist. */
  tokenBalancesExist?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Filter by the object’s `transactionHashes` relation. */
  transactionHashes?: InputMaybe<AccountToManyAffectedPublicKeyFilter>;
  /** Some related `transactionHashes` exist. */
  transactionHashesExist?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Filter by the object’s `transactionStats` relation. */
  transactionStats?: InputMaybe<ProfileTransactionStatFilter>;
  /** A related `transactionStats` exists. */
  transactionStatsExists?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Filter by the object’s `transactions` relation. */
  transactions?: InputMaybe<AccountToManyTransactionFilter>;
  /** Some related `transactions` exist. */
  transactionsExist?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Filter by the object’s `userAssociationsAsAppOwner` relation. */
  userAssociationsAsAppOwner?: InputMaybe<AccountToManyUserAssociationFilter>;
  /** Some related `userAssociationsAsAppOwner` exist. */
  userAssociationsAsAppOwnerExist?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Filter by the object’s `userAssociationsAsTarget` relation. */
  userAssociationsAsTarget?: InputMaybe<AccountToManyUserAssociationFilter>;
  /** Some related `userAssociationsAsTarget` exist. */
  userAssociationsAsTargetExist?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Filter by the object’s `userAssociationsAsTransactor` relation. */
  userAssociationsAsTransactor?: InputMaybe<AccountToManyUserAssociationFilter>;
  /** Some related `userAssociationsAsTransactor` exist. */
  userAssociationsAsTransactorExist?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Filter by the object’s `username` field. */
  username?: InputMaybe<StringFilter>;
  /** Filter by the object’s `validatorEntry` relation. */
  validatorEntry?: InputMaybe<ValidatorEntryFilter>;
  /** A related `validatorEntry` exists. */
  validatorEntryExists?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Filter by the object’s `validatorLockedStakeEntries` relation. */
  validatorLockedStakeEntries?: InputMaybe<AccountToManyLockedStakeEntryFilter>;
  /** Some related `validatorLockedStakeEntries` exist. */
  validatorLockedStakeEntriesExist?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Filter by the object’s `validatorStakeEntries` relation. */
  validatorStakeEntries?: InputMaybe<AccountToManyStakeEntryFilter>;
  /** Some related `validatorStakeEntries` exist. */
  validatorStakeEntriesExist?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Filter by the object’s `validatorStakeRewards` relation. */
  validatorStakeRewards?: InputMaybe<AccountToManyStakeRewardFilter>;
  /** Some related `validatorStakeRewards` exist. */
  validatorStakeRewardsExist?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Filter by the object’s `yieldCurvePoints` relation. */
  yieldCurvePoints?: InputMaybe<AccountToManyYieldCurvePointFilter>;
  /** Some related `yieldCurvePoints` exist. */
  yieldCurvePointsExist?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type AccountMetadata = {
  __typename?: "AccountMetadata";
  email?: Maybe<Scalars["String"]["output"]>;
  phoneNumber?: Maybe<Scalars["String"]["output"]>;
};

/** A filter to be used against many `AccessGroup` object types. All fields are combined with a logical ‘and.’ */
export type AccountToManyAccessGroupFilter = {
  /** Every related `AccessGroup` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<AccessGroupFilter>;
  /** No related `AccessGroup` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<AccessGroupFilter>;
  /** Some related `AccessGroup` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<AccessGroupFilter>;
};

/** A filter to be used against many `AccessGroupMember` object types. All fields are combined with a logical ‘and.’ */
export type AccountToManyAccessGroupMemberFilter = {
  /** Every related `AccessGroupMember` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<AccessGroupMemberFilter>;
  /** No related `AccessGroupMember` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<AccessGroupMemberFilter>;
  /** Some related `AccessGroupMember` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<AccessGroupMemberFilter>;
};

/** A filter to be used against many `AffectedPublicKey` object types. All fields are combined with a logical ‘and.’ */
export type AccountToManyAffectedPublicKeyFilter = {
  /** Every related `AffectedPublicKey` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<AffectedPublicKeyFilter>;
  /** No related `AffectedPublicKey` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<AffectedPublicKeyFilter>;
  /** Some related `AffectedPublicKey` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<AffectedPublicKeyFilter>;
};

/** A filter to be used against many `BlsPublicKeyPkidPairSnapshotEntry` object types. All fields are combined with a logical ‘and.’ */
export type AccountToManyBlsPublicKeyPkidPairSnapshotEntryFilter = {
  /** Every related `BlsPublicKeyPkidPairSnapshotEntry` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<BlsPublicKeyPkidPairSnapshotEntryFilter>;
  /** No related `BlsPublicKeyPkidPairSnapshotEntry` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<BlsPublicKeyPkidPairSnapshotEntryFilter>;
  /** Some related `BlsPublicKeyPkidPairSnapshotEntry` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<BlsPublicKeyPkidPairSnapshotEntryFilter>;
};

/** A filter to be used against many `CreatorCoinBalance` object types. All fields are combined with a logical ‘and.’ */
export type AccountToManyCreatorCoinBalanceFilter = {
  /** Every related `CreatorCoinBalance` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<CreatorCoinBalanceFilter>;
  /** No related `CreatorCoinBalance` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<CreatorCoinBalanceFilter>;
  /** Some related `CreatorCoinBalance` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<CreatorCoinBalanceFilter>;
};

/** A filter to be used against many `DerivedKey` object types. All fields are combined with a logical ‘and.’ */
export type AccountToManyDerivedKeyFilter = {
  /** Every related `DerivedKey` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<DerivedKeyFilter>;
  /** No related `DerivedKey` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<DerivedKeyFilter>;
  /** Some related `DerivedKey` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<DerivedKeyFilter>;
};

/** A filter to be used against many `DesoTokenLimitOrderBidAsk` object types. All fields are combined with a logical ‘and.’ */
export type AccountToManyDesoTokenLimitOrderBidAskFilter = {
  /** Every related `DesoTokenLimitOrderBidAsk` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<DesoTokenLimitOrderBidAskFilter>;
  /** No related `DesoTokenLimitOrderBidAsk` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<DesoTokenLimitOrderBidAskFilter>;
  /** Some related `DesoTokenLimitOrderBidAsk` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<DesoTokenLimitOrderBidAskFilter>;
};

/** A filter to be used against many `DesoTokenLimitOrder` object types. All fields are combined with a logical ‘and.’ */
export type AccountToManyDesoTokenLimitOrderFilter = {
  /** Every related `DesoTokenLimitOrder` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<DesoTokenLimitOrderFilter>;
  /** No related `DesoTokenLimitOrder` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<DesoTokenLimitOrderFilter>;
  /** Some related `DesoTokenLimitOrder` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<DesoTokenLimitOrderFilter>;
};

/** A filter to be used against many `Diamond` object types. All fields are combined with a logical ‘and.’ */
export type AccountToManyDiamondFilter = {
  /** Every related `Diamond` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<DiamondFilter>;
  /** No related `Diamond` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<DiamondFilter>;
  /** Some related `Diamond` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<DiamondFilter>;
};

/** A filter to be used against many `EpochDetailsForBlock` object types. All fields are combined with a logical ‘and.’ */
export type AccountToManyEpochDetailsForBlockFilter = {
  /** Every related `EpochDetailsForBlock` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<EpochDetailsForBlockFilter>;
  /** No related `EpochDetailsForBlock` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<EpochDetailsForBlockFilter>;
  /** Some related `EpochDetailsForBlock` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<EpochDetailsForBlockFilter>;
};

/** A filter to be used against many `Follow` object types. All fields are combined with a logical ‘and.’ */
export type AccountToManyFollowFilter = {
  /** Every related `Follow` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<FollowFilter>;
  /** No related `Follow` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<FollowFilter>;
  /** Some related `Follow` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<FollowFilter>;
};

/** A filter to be used against many `JailedHistoryEvent` object types. All fields are combined with a logical ‘and.’ */
export type AccountToManyJailedHistoryEventFilter = {
  /** Every related `JailedHistoryEvent` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<JailedHistoryEventFilter>;
  /** No related `JailedHistoryEvent` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<JailedHistoryEventFilter>;
  /** Some related `JailedHistoryEvent` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<JailedHistoryEventFilter>;
};

/** A filter to be used against many `LeaderScheduleEntry` object types. All fields are combined with a logical ‘and.’ */
export type AccountToManyLeaderScheduleEntryFilter = {
  /** Every related `LeaderScheduleEntry` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<LeaderScheduleEntryFilter>;
  /** No related `LeaderScheduleEntry` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<LeaderScheduleEntryFilter>;
  /** Some related `LeaderScheduleEntry` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<LeaderScheduleEntryFilter>;
};

/** A filter to be used against many `LegacyMessage` object types. All fields are combined with a logical ‘and.’ */
export type AccountToManyLegacyMessageFilter = {
  /** Every related `LegacyMessage` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<LegacyMessageFilter>;
  /** No related `LegacyMessage` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<LegacyMessageFilter>;
  /** Some related `LegacyMessage` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<LegacyMessageFilter>;
};

/** A filter to be used against many `Like` object types. All fields are combined with a logical ‘and.’ */
export type AccountToManyLikeFilter = {
  /** Every related `Like` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<LikeFilter>;
  /** No related `Like` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<LikeFilter>;
  /** Some related `Like` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<LikeFilter>;
};

/** A filter to be used against many `LockedBalanceEntry` object types. All fields are combined with a logical ‘and.’ */
export type AccountToManyLockedBalanceEntryFilter = {
  /** Every related `LockedBalanceEntry` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<LockedBalanceEntryFilter>;
  /** No related `LockedBalanceEntry` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<LockedBalanceEntryFilter>;
  /** Some related `LockedBalanceEntry` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<LockedBalanceEntryFilter>;
};

/** A filter to be used against many `LockedStakeEntry` object types. All fields are combined with a logical ‘and.’ */
export type AccountToManyLockedStakeEntryFilter = {
  /** Every related `LockedStakeEntry` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<LockedStakeEntryFilter>;
  /** No related `LockedStakeEntry` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<LockedStakeEntryFilter>;
  /** Some related `LockedStakeEntry` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<LockedStakeEntryFilter>;
};

/** A filter to be used against many `Message` object types. All fields are combined with a logical ‘and.’ */
export type AccountToManyMessageFilter = {
  /** Every related `Message` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<MessageFilter>;
  /** No related `Message` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<MessageFilter>;
  /** Some related `Message` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<MessageFilter>;
};

/** A filter to be used against many `NftBid` object types. All fields are combined with a logical ‘and.’ */
export type AccountToManyNftBidFilter = {
  /** Every related `NftBid` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<NftBidFilter>;
  /** No related `NftBid` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<NftBidFilter>;
  /** Some related `NftBid` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<NftBidFilter>;
};

/** A filter to be used against many `Nft` object types. All fields are combined with a logical ‘and.’ */
export type AccountToManyNftFilter = {
  /** Every related `Nft` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<NftFilter>;
  /** No related `Nft` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<NftFilter>;
  /** Some related `Nft` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<NftFilter>;
};

/** A filter to be used against many `PostAssociation` object types. All fields are combined with a logical ‘and.’ */
export type AccountToManyPostAssociationFilter = {
  /** Every related `PostAssociation` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<PostAssociationFilter>;
  /** No related `PostAssociation` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<PostAssociationFilter>;
  /** Some related `PostAssociation` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<PostAssociationFilter>;
};

/** A filter to be used against many `Post` object types. All fields are combined with a logical ‘and.’ */
export type AccountToManyPostFilter = {
  /** Every related `Post` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<PostFilter>;
  /** No related `Post` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<PostFilter>;
  /** Some related `Post` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<PostFilter>;
};

/** A filter to be used against many `StakeEntry` object types. All fields are combined with a logical ‘and.’ */
export type AccountToManyStakeEntryFilter = {
  /** Every related `StakeEntry` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<StakeEntryFilter>;
  /** No related `StakeEntry` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<StakeEntryFilter>;
  /** Some related `StakeEntry` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<StakeEntryFilter>;
};

/** A filter to be used against many `StakeReward` object types. All fields are combined with a logical ‘and.’ */
export type AccountToManyStakeRewardFilter = {
  /** Every related `StakeReward` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<StakeRewardFilter>;
  /** No related `StakeReward` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<StakeRewardFilter>;
  /** Some related `StakeReward` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<StakeRewardFilter>;
};

/** A filter to be used against many `StakeRewardsByValidatorAndStaker` object types. All fields are combined with a logical ‘and.’ */
export type AccountToManyStakeRewardsByValidatorAndStakerFilter = {
  /** Every related `StakeRewardsByValidatorAndStaker` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<StakeRewardsByValidatorAndStakerFilter>;
  /** No related `StakeRewardsByValidatorAndStaker` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<StakeRewardsByValidatorAndStakerFilter>;
  /** Some related `StakeRewardsByValidatorAndStaker` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<StakeRewardsByValidatorAndStakerFilter>;
};

/** A filter to be used against many `TokenBalance` object types. All fields are combined with a logical ‘and.’ */
export type AccountToManyTokenBalanceFilter = {
  /** Every related `TokenBalance` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<TokenBalanceFilter>;
  /** No related `TokenBalance` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<TokenBalanceFilter>;
  /** Some related `TokenBalance` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<TokenBalanceFilter>;
};

/** A filter to be used against many `Transaction` object types. All fields are combined with a logical ‘and.’ */
export type AccountToManyTransactionFilter = {
  /** Every related `Transaction` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<TransactionFilter>;
  /** No related `Transaction` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<TransactionFilter>;
  /** Some related `Transaction` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<TransactionFilter>;
};

/** A filter to be used against many `UserAssociation` object types. All fields are combined with a logical ‘and.’ */
export type AccountToManyUserAssociationFilter = {
  /** Every related `UserAssociation` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<UserAssociationFilter>;
  /** No related `UserAssociation` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<UserAssociationFilter>;
  /** Some related `UserAssociation` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<UserAssociationFilter>;
};

/** A filter to be used against many `YieldCurvePoint` object types. All fields are combined with a logical ‘and.’ */
export type AccountToManyYieldCurvePointFilter = {
  /** Every related `YieldCurvePoint` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<YieldCurvePointFilter>;
  /** No related `YieldCurvePoint` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<YieldCurvePointFilter>;
  /** Some related `YieldCurvePoint` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<YieldCurvePointFilter>;
};

/** A connection to a list of `Account` values. */
export type AccountsConnection = {
  __typename?: "AccountsConnection";
  /** A list of edges which contains the `Account` and cursor to aid in pagination. */
  edges: Array<AccountsEdge>;
  /** A list of `Account` objects. */
  nodes: Array<Maybe<Account>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Account` you could get from the connection. */
  totalCount: Scalars["Int"]["output"];
};

/** A `Account` edge in the connection. */
export type AccountsEdge = {
  __typename?: "AccountsEdge";
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars["Cursor"]["output"]>;
  /** The `Account` at the end of the edge. */
  node?: Maybe<Account>;
};

/** Methods to use when ordering `Account`. */
export enum AccountsOrderBy {
  CcCoinsInCirculationNanosAsc = "CC_COINS_IN_CIRCULATION_NANOS_ASC",
  CcCoinsInCirculationNanosDesc = "CC_COINS_IN_CIRCULATION_NANOS_DESC",
  CoinPriceDesoNanosAsc = "COIN_PRICE_DESO_NANOS_ASC",
  CoinPriceDesoNanosDesc = "COIN_PRICE_DESO_NANOS_DESC",
  CoinWatermarkNanosAsc = "COIN_WATERMARK_NANOS_ASC",
  CoinWatermarkNanosDesc = "COIN_WATERMARK_NANOS_DESC",
  CreatorBasisPointsAsc = "CREATOR_BASIS_POINTS_ASC",
  CreatorBasisPointsDesc = "CREATOR_BASIS_POINTS_DESC",
  DaoCoinsInCirculationNanosHexAsc = "DAO_COINS_IN_CIRCULATION_NANOS_HEX_ASC",
  DaoCoinsInCirculationNanosHexDesc = "DAO_COINS_IN_CIRCULATION_NANOS_HEX_DESC",
  DaoCoinMintingDisabledAsc = "DAO_COIN_MINTING_DISABLED_ASC",
  DaoCoinMintingDisabledDesc = "DAO_COIN_MINTING_DISABLED_DESC",
  DaoCoinTransferRestrictionStatusAsc = "DAO_COIN_TRANSFER_RESTRICTION_STATUS_ASC",
  DaoCoinTransferRestrictionStatusDesc = "DAO_COIN_TRANSFER_RESTRICTION_STATUS_DESC",
  DescriptionAsc = "DESCRIPTION_ASC",
  DescriptionDesc = "DESCRIPTION_DESC",
  DesoLockedNanosAsc = "DESO_LOCKED_NANOS_ASC",
  DesoLockedNanosDesc = "DESO_LOCKED_NANOS_DESC",
  ExtraDataAsc = "EXTRA_DATA_ASC",
  ExtraDataDesc = "EXTRA_DATA_DESC",
  MintingDisabledAsc = "MINTING_DISABLED_ASC",
  MintingDisabledDesc = "MINTING_DISABLED_DESC",
  Natural = "NATURAL",
  PkidAsc = "PKID_ASC",
  PkidDesc = "PKID_DESC",
  PrimaryKeyAsc = "PRIMARY_KEY_ASC",
  PrimaryKeyDesc = "PRIMARY_KEY_DESC",
  ProfilePicAsc = "PROFILE_PIC_ASC",
  ProfilePicDesc = "PROFILE_PIC_DESC",
  PublicKeyAsc = "PUBLIC_KEY_ASC",
  PublicKeyDesc = "PUBLIC_KEY_DESC",
  UsernameAsc = "USERNAME_ASC",
  UsernameDesc = "USERNAME_DESC",
}

export type AffectedPublicKey = Node & {
  __typename?: "AffectedPublicKey";
  /** Reads a single `Account` that is related to this `AffectedPublicKey`. */
  account?: Maybe<Account>;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  id: Scalars["ID"]["output"];
  isDuplicate: Scalars["Boolean"]["output"];
  metadata: Scalars["String"]["output"];
  publicKey: Scalars["String"]["output"];
  timestamp?: Maybe<Scalars["Datetime"]["output"]>;
  /** Reads a single `Transaction` that is related to this `AffectedPublicKey`. */
  transaction?: Maybe<Transaction>;
  transactionHash: Scalars["String"]["output"];
  txnType: Scalars["Int"]["output"];
};

/**
 * A condition to be used against `AffectedPublicKey` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type AffectedPublicKeyCondition = {
  /** Checks for equality with the object’s `isDuplicate` field. */
  isDuplicate?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Checks for equality with the object’s `metadata` field. */
  metadata?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `publicKey` field. */
  publicKey?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `timestamp` field. */
  timestamp?: InputMaybe<Scalars["Datetime"]["input"]>;
  /** Checks for equality with the object’s `transactionHash` field. */
  transactionHash?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `txnType` field. */
  txnType?: InputMaybe<Scalars["Int"]["input"]>;
};

/** A filter to be used against `AffectedPublicKey` object types. All fields are combined with a logical ‘and.’ */
export type AffectedPublicKeyFilter = {
  /** Filter by the object’s `account` relation. */
  account?: InputMaybe<AccountFilter>;
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<AffectedPublicKeyFilter>>;
  /** Filter by the object’s `isDuplicate` field. */
  isDuplicate?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `metadata` field. */
  metadata?: InputMaybe<StringFilter>;
  /** Negates the expression. */
  not?: InputMaybe<AffectedPublicKeyFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<AffectedPublicKeyFilter>>;
  /** Filter by the object’s `publicKey` field. */
  publicKey?: InputMaybe<StringFilter>;
  /** Filter by the object’s `timestamp` field. */
  timestamp?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `transaction` relation. */
  transaction?: InputMaybe<TransactionFilter>;
  /** Filter by the object’s `transactionHash` field. */
  transactionHash?: InputMaybe<StringFilter>;
  /** Filter by the object’s `txnType` field. */
  txnType?: InputMaybe<IntFilter>;
};

/** A connection to a list of `AffectedPublicKey` values. */
export type AffectedPublicKeysConnection = {
  __typename?: "AffectedPublicKeysConnection";
  /** A list of edges which contains the `AffectedPublicKey` and cursor to aid in pagination. */
  edges: Array<AffectedPublicKeysEdge>;
  /** A list of `AffectedPublicKey` objects. */
  nodes: Array<Maybe<AffectedPublicKey>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `AffectedPublicKey` you could get from the connection. */
  totalCount: Scalars["Int"]["output"];
};

/** A `AffectedPublicKey` edge in the connection. */
export type AffectedPublicKeysEdge = {
  __typename?: "AffectedPublicKeysEdge";
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars["Cursor"]["output"]>;
  /** The `AffectedPublicKey` at the end of the edge. */
  node?: Maybe<AffectedPublicKey>;
};

/** Methods to use when ordering `AffectedPublicKey`. */
export enum AffectedPublicKeysOrderBy {
  IsDuplicateAsc = "IS_DUPLICATE_ASC",
  IsDuplicateDesc = "IS_DUPLICATE_DESC",
  MetadataAsc = "METADATA_ASC",
  MetadataDesc = "METADATA_DESC",
  Natural = "NATURAL",
  PrimaryKeyAsc = "PRIMARY_KEY_ASC",
  PrimaryKeyDesc = "PRIMARY_KEY_DESC",
  PublicKeyAsc = "PUBLIC_KEY_ASC",
  PublicKeyDesc = "PUBLIC_KEY_DESC",
  TimestampAsc = "TIMESTAMP_ASC",
  TimestampDesc = "TIMESTAMP_DESC",
  TransactionHashAsc = "TRANSACTION_HASH_ASC",
  TransactionHashDesc = "TRANSACTION_HASH_DESC",
  TxnTypeAsc = "TXN_TYPE_ASC",
  TxnTypeDesc = "TXN_TYPE_DESC",
}

/** A filter to be used against BigFloat fields. All fields are combined with a logical ‘and.’ */
export type BigFloatFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<Scalars["BigFloat"]["input"]>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<Scalars["BigFloat"]["input"]>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<Scalars["BigFloat"]["input"]>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<Scalars["BigFloat"]["input"]>;
  /** Included in the specified list. */
  in?: InputMaybe<Array<Scalars["BigFloat"]["input"]>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<Scalars["BigFloat"]["input"]>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<Scalars["BigFloat"]["input"]>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<Scalars["BigFloat"]["input"]>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<Scalars["BigFloat"]["input"]>;
  /** Not included in the specified list. */
  notIn?: InputMaybe<Array<Scalars["BigFloat"]["input"]>>;
};

/** A filter to be used against BigInt fields. All fields are combined with a logical ‘and.’ */
export type BigIntFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<Scalars["BigInt"]["input"]>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<Scalars["BigInt"]["input"]>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<Scalars["BigInt"]["input"]>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<Scalars["BigInt"]["input"]>;
  /** Included in the specified list. */
  in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<Scalars["BigInt"]["input"]>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<Scalars["BigInt"]["input"]>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<Scalars["BigInt"]["input"]>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<Scalars["BigInt"]["input"]>;
  /** Not included in the specified list. */
  notIn?: InputMaybe<Array<Scalars["BigInt"]["input"]>>;
};

export type Block = Node & {
  __typename?: "Block";
  blockHash: Scalars["String"]["output"];
  blockVersion?: Maybe<Scalars["BigInt"]["output"]>;
  /** Reads a single `EpochDetailsForBlock` that is related to this `Block`. */
  epochDetailForBlock?: Maybe<EpochDetailsForBlock>;
  extraNonce?: Maybe<Scalars["BigInt"]["output"]>;
  height: Scalars["BigInt"]["output"];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  id: Scalars["ID"]["output"];
  /** Reads and enables pagination through a set of `NftBid`. */
  nftBids: NftBidsConnection;
  nonce?: Maybe<Scalars["BigInt"]["output"]>;
  /** Reads and enables pagination through a set of `PostAssociation`. */
  postAssociations: PostAssociationsConnection;
  prevBlockHash?: Maybe<Scalars["String"]["output"]>;
  proposedInView?: Maybe<Scalars["BigInt"]["output"]>;
  proposerRandomSeedSignature?: Maybe<Scalars["String"]["output"]>;
  proposerVotePartialSignature?: Maybe<Scalars["String"]["output"]>;
  proposerVotingPublicKey?: Maybe<Scalars["String"]["output"]>;
  /** Reads and enables pagination through a set of `StakeReward`. */
  stakeRewardForBlock: StakeRewardsConnection;
  timestamp: Scalars["Datetime"]["output"];
  /** Reads and enables pagination through a set of `Transaction`. */
  transactions: TransactionsConnection;
  txnMerkleRoot: Scalars["String"]["output"];
  /** Reads and enables pagination through a set of `UserAssociation`. */
  userAssociations: UserAssociationsConnection;
};

export type BlockNftBidsArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<NftBidCondition>;
  filter?: InputMaybe<NftBidFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<NftBidsOrderBy>>;
};

export type BlockPostAssociationsArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<PostAssociationCondition>;
  filter?: InputMaybe<PostAssociationFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<PostAssociationsOrderBy>>;
};

export type BlockStakeRewardForBlockArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<StakeRewardCondition>;
  filter?: InputMaybe<StakeRewardFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<StakeRewardsOrderBy>>;
};

export type BlockTransactionsArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<TransactionCondition>;
  filter?: InputMaybe<TransactionFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<TransactionsOrderBy>>;
};

export type BlockUserAssociationsArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<UserAssociationCondition>;
  filter?: InputMaybe<UserAssociationFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<UserAssociationsOrderBy>>;
};

/** A condition to be used against `Block` object types. All fields are tested for equality and combined with a logical ‘and.’ */
export type BlockCondition = {
  /** Checks for equality with the object’s `blockHash` field. */
  blockHash?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `blockVersion` field. */
  blockVersion?: InputMaybe<Scalars["BigInt"]["input"]>;
  /** Checks for equality with the object’s `extraNonce` field. */
  extraNonce?: InputMaybe<Scalars["BigInt"]["input"]>;
  /** Checks for equality with the object’s `height` field. */
  height?: InputMaybe<Scalars["BigInt"]["input"]>;
  /** Checks for equality with the object’s `nonce` field. */
  nonce?: InputMaybe<Scalars["BigInt"]["input"]>;
  /** Checks for equality with the object’s `prevBlockHash` field. */
  prevBlockHash?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `proposedInView` field. */
  proposedInView?: InputMaybe<Scalars["BigInt"]["input"]>;
  /** Checks for equality with the object’s `proposerRandomSeedSignature` field. */
  proposerRandomSeedSignature?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `proposerVotePartialSignature` field. */
  proposerVotePartialSignature?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `proposerVotingPublicKey` field. */
  proposerVotingPublicKey?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `timestamp` field. */
  timestamp?: InputMaybe<Scalars["Datetime"]["input"]>;
  /** Checks for equality with the object’s `txnMerkleRoot` field. */
  txnMerkleRoot?: InputMaybe<Scalars["String"]["input"]>;
};

/** A filter to be used against `Block` object types. All fields are combined with a logical ‘and.’ */
export type BlockFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<BlockFilter>>;
  /** Filter by the object’s `blockHash` field. */
  blockHash?: InputMaybe<StringFilter>;
  /** Filter by the object’s `blockVersion` field. */
  blockVersion?: InputMaybe<BigIntFilter>;
  /** Filter by the object’s `epochDetailForBlock` relation. */
  epochDetailForBlock?: InputMaybe<EpochDetailsForBlockFilter>;
  /** A related `epochDetailForBlock` exists. */
  epochDetailForBlockExists?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Filter by the object’s `extraNonce` field. */
  extraNonce?: InputMaybe<BigIntFilter>;
  /** Filter by the object’s `height` field. */
  height?: InputMaybe<BigIntFilter>;
  /** Filter by the object’s `nftBids` relation. */
  nftBids?: InputMaybe<BlockToManyNftBidFilter>;
  /** Some related `nftBids` exist. */
  nftBidsExist?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Filter by the object’s `nonce` field. */
  nonce?: InputMaybe<BigIntFilter>;
  /** Negates the expression. */
  not?: InputMaybe<BlockFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<BlockFilter>>;
  /** Filter by the object’s `postAssociations` relation. */
  postAssociations?: InputMaybe<BlockToManyPostAssociationFilter>;
  /** Some related `postAssociations` exist. */
  postAssociationsExist?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Filter by the object’s `prevBlockHash` field. */
  prevBlockHash?: InputMaybe<StringFilter>;
  /** Filter by the object’s `proposedInView` field. */
  proposedInView?: InputMaybe<BigIntFilter>;
  /** Filter by the object’s `proposerRandomSeedSignature` field. */
  proposerRandomSeedSignature?: InputMaybe<StringFilter>;
  /** Filter by the object’s `proposerVotePartialSignature` field. */
  proposerVotePartialSignature?: InputMaybe<StringFilter>;
  /** Filter by the object’s `proposerVotingPublicKey` field. */
  proposerVotingPublicKey?: InputMaybe<StringFilter>;
  /** Filter by the object’s `stakeRewardForBlock` relation. */
  stakeRewardForBlock?: InputMaybe<BlockToManyStakeRewardFilter>;
  /** Some related `stakeRewardForBlock` exist. */
  stakeRewardForBlockExist?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Filter by the object’s `timestamp` field. */
  timestamp?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `transactions` relation. */
  transactions?: InputMaybe<BlockToManyTransactionFilter>;
  /** Some related `transactions` exist. */
  transactionsExist?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Filter by the object’s `txnMerkleRoot` field. */
  txnMerkleRoot?: InputMaybe<StringFilter>;
  /** Filter by the object’s `userAssociations` relation. */
  userAssociations?: InputMaybe<BlockToManyUserAssociationFilter>;
  /** Some related `userAssociations` exist. */
  userAssociationsExist?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type BlockSigner = Node & {
  __typename?: "BlockSigner";
  blockHash: Scalars["String"]["output"];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  id: Scalars["ID"]["output"];
  signerIndex: Scalars["BigInt"]["output"];
};

/**
 * A condition to be used against `BlockSigner` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type BlockSignerCondition = {
  /** Checks for equality with the object’s `blockHash` field. */
  blockHash?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `signerIndex` field. */
  signerIndex?: InputMaybe<Scalars["BigInt"]["input"]>;
};

/** A filter to be used against `BlockSigner` object types. All fields are combined with a logical ‘and.’ */
export type BlockSignerFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<BlockSignerFilter>>;
  /** Filter by the object’s `blockHash` field. */
  blockHash?: InputMaybe<StringFilter>;
  /** Negates the expression. */
  not?: InputMaybe<BlockSignerFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<BlockSignerFilter>>;
  /** Filter by the object’s `signerIndex` field. */
  signerIndex?: InputMaybe<BigIntFilter>;
};

/** A connection to a list of `BlockSigner` values. */
export type BlockSignersConnection = {
  __typename?: "BlockSignersConnection";
  /** A list of edges which contains the `BlockSigner` and cursor to aid in pagination. */
  edges: Array<BlockSignersEdge>;
  /** A list of `BlockSigner` objects. */
  nodes: Array<Maybe<BlockSigner>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `BlockSigner` you could get from the connection. */
  totalCount: Scalars["Int"]["output"];
};

/** A `BlockSigner` edge in the connection. */
export type BlockSignersEdge = {
  __typename?: "BlockSignersEdge";
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars["Cursor"]["output"]>;
  /** The `BlockSigner` at the end of the edge. */
  node?: Maybe<BlockSigner>;
};

/** Methods to use when ordering `BlockSigner`. */
export enum BlockSignersOrderBy {
  BlockHashAsc = "BLOCK_HASH_ASC",
  BlockHashDesc = "BLOCK_HASH_DESC",
  Natural = "NATURAL",
  PrimaryKeyAsc = "PRIMARY_KEY_ASC",
  PrimaryKeyDesc = "PRIMARY_KEY_DESC",
  SignerIndexAsc = "SIGNER_INDEX_ASC",
  SignerIndexDesc = "SIGNER_INDEX_DESC",
}

/** A filter to be used against many `NftBid` object types. All fields are combined with a logical ‘and.’ */
export type BlockToManyNftBidFilter = {
  /** Every related `NftBid` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<NftBidFilter>;
  /** No related `NftBid` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<NftBidFilter>;
  /** Some related `NftBid` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<NftBidFilter>;
};

/** A filter to be used against many `PostAssociation` object types. All fields are combined with a logical ‘and.’ */
export type BlockToManyPostAssociationFilter = {
  /** Every related `PostAssociation` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<PostAssociationFilter>;
  /** No related `PostAssociation` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<PostAssociationFilter>;
  /** Some related `PostAssociation` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<PostAssociationFilter>;
};

/** A filter to be used against many `StakeReward` object types. All fields are combined with a logical ‘and.’ */
export type BlockToManyStakeRewardFilter = {
  /** Every related `StakeReward` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<StakeRewardFilter>;
  /** No related `StakeReward` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<StakeRewardFilter>;
  /** Some related `StakeReward` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<StakeRewardFilter>;
};

/** A filter to be used against many `Transaction` object types. All fields are combined with a logical ‘and.’ */
export type BlockToManyTransactionFilter = {
  /** Every related `Transaction` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<TransactionFilter>;
  /** No related `Transaction` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<TransactionFilter>;
  /** Some related `Transaction` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<TransactionFilter>;
};

/** A filter to be used against many `UserAssociation` object types. All fields are combined with a logical ‘and.’ */
export type BlockToManyUserAssociationFilter = {
  /** Every related `UserAssociation` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<UserAssociationFilter>;
  /** No related `UserAssociation` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<UserAssociationFilter>;
  /** Some related `UserAssociation` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<UserAssociationFilter>;
};

/** A connection to a list of `Block` values. */
export type BlocksConnection = {
  __typename?: "BlocksConnection";
  /** A list of edges which contains the `Block` and cursor to aid in pagination. */
  edges: Array<BlocksEdge>;
  /** A list of `Block` objects. */
  nodes: Array<Maybe<Block>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Block` you could get from the connection. */
  totalCount: Scalars["Int"]["output"];
};

/** A `Block` edge in the connection. */
export type BlocksEdge = {
  __typename?: "BlocksEdge";
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars["Cursor"]["output"]>;
  /** The `Block` at the end of the edge. */
  node?: Maybe<Block>;
};

/** Methods to use when ordering `Block`. */
export enum BlocksOrderBy {
  BlockHashAsc = "BLOCK_HASH_ASC",
  BlockHashDesc = "BLOCK_HASH_DESC",
  BlockVersionAsc = "BLOCK_VERSION_ASC",
  BlockVersionDesc = "BLOCK_VERSION_DESC",
  ExtraNonceAsc = "EXTRA_NONCE_ASC",
  ExtraNonceDesc = "EXTRA_NONCE_DESC",
  HeightAsc = "HEIGHT_ASC",
  HeightDesc = "HEIGHT_DESC",
  Natural = "NATURAL",
  NonceAsc = "NONCE_ASC",
  NonceDesc = "NONCE_DESC",
  PrevBlockHashAsc = "PREV_BLOCK_HASH_ASC",
  PrevBlockHashDesc = "PREV_BLOCK_HASH_DESC",
  PrimaryKeyAsc = "PRIMARY_KEY_ASC",
  PrimaryKeyDesc = "PRIMARY_KEY_DESC",
  ProposedInViewAsc = "PROPOSED_IN_VIEW_ASC",
  ProposedInViewDesc = "PROPOSED_IN_VIEW_DESC",
  ProposerRandomSeedSignatureAsc = "PROPOSER_RANDOM_SEED_SIGNATURE_ASC",
  ProposerRandomSeedSignatureDesc = "PROPOSER_RANDOM_SEED_SIGNATURE_DESC",
  ProposerVotePartialSignatureAsc = "PROPOSER_VOTE_PARTIAL_SIGNATURE_ASC",
  ProposerVotePartialSignatureDesc = "PROPOSER_VOTE_PARTIAL_SIGNATURE_DESC",
  ProposerVotingPublicKeyAsc = "PROPOSER_VOTING_PUBLIC_KEY_ASC",
  ProposerVotingPublicKeyDesc = "PROPOSER_VOTING_PUBLIC_KEY_DESC",
  TimestampAsc = "TIMESTAMP_ASC",
  TimestampDesc = "TIMESTAMP_DESC",
  TxnMerkleRootAsc = "TXN_MERKLE_ROOT_ASC",
  TxnMerkleRootDesc = "TXN_MERKLE_ROOT_DESC",
}

/** A connection to a list of `BlsPublicKeyPkidPairEntry` values. */
export type BlsPublicKeyPkidPairEntriesConnection = {
  __typename?: "BlsPublicKeyPkidPairEntriesConnection";
  /** A list of edges which contains the `BlsPublicKeyPkidPairEntry` and cursor to aid in pagination. */
  edges: Array<BlsPublicKeyPkidPairEntriesEdge>;
  /** A list of `BlsPublicKeyPkidPairEntry` objects. */
  nodes: Array<Maybe<BlsPublicKeyPkidPairEntry>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `BlsPublicKeyPkidPairEntry` you could get from the connection. */
  totalCount: Scalars["Int"]["output"];
};

/** A `BlsPublicKeyPkidPairEntry` edge in the connection. */
export type BlsPublicKeyPkidPairEntriesEdge = {
  __typename?: "BlsPublicKeyPkidPairEntriesEdge";
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars["Cursor"]["output"]>;
  /** The `BlsPublicKeyPkidPairEntry` at the end of the edge. */
  node?: Maybe<BlsPublicKeyPkidPairEntry>;
};

/** Methods to use when ordering `BlsPublicKeyPkidPairEntry`. */
export enum BlsPublicKeyPkidPairEntriesOrderBy {
  BadgerKeyAsc = "BADGER_KEY_ASC",
  BadgerKeyDesc = "BADGER_KEY_DESC",
  BlsPublicKeyAsc = "BLS_PUBLIC_KEY_ASC",
  BlsPublicKeyDesc = "BLS_PUBLIC_KEY_DESC",
  Natural = "NATURAL",
  PkidAsc = "PKID_ASC",
  PkidDesc = "PKID_DESC",
  PrimaryKeyAsc = "PRIMARY_KEY_ASC",
  PrimaryKeyDesc = "PRIMARY_KEY_DESC",
}

export type BlsPublicKeyPkidPairEntry = Node & {
  __typename?: "BlsPublicKeyPkidPairEntry";
  badgerKey: Scalars["String"]["output"];
  blsPublicKey: Scalars["String"]["output"];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  id: Scalars["ID"]["output"];
  pkid: Scalars["String"]["output"];
};

/**
 * A condition to be used against `BlsPublicKeyPkidPairEntry` object types. All
 * fields are tested for equality and combined with a logical ‘and.’
 */
export type BlsPublicKeyPkidPairEntryCondition = {
  /** Checks for equality with the object’s `badgerKey` field. */
  badgerKey?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `blsPublicKey` field. */
  blsPublicKey?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `pkid` field. */
  pkid?: InputMaybe<Scalars["String"]["input"]>;
};

/** A filter to be used against `BlsPublicKeyPkidPairEntry` object types. All fields are combined with a logical ‘and.’ */
export type BlsPublicKeyPkidPairEntryFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<BlsPublicKeyPkidPairEntryFilter>>;
  /** Filter by the object’s `blsPublicKey` field. */
  blsPublicKey?: InputMaybe<StringFilter>;
  /** Negates the expression. */
  not?: InputMaybe<BlsPublicKeyPkidPairEntryFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<BlsPublicKeyPkidPairEntryFilter>>;
  /** Filter by the object’s `pkid` field. */
  pkid?: InputMaybe<StringFilter>;
};

/** A connection to a list of `BlsPublicKeyPkidPairSnapshotEntry` values. */
export type BlsPublicKeyPkidPairSnapshotEntriesConnection = {
  __typename?: "BlsPublicKeyPkidPairSnapshotEntriesConnection";
  /** A list of edges which contains the `BlsPublicKeyPkidPairSnapshotEntry` and cursor to aid in pagination. */
  edges: Array<BlsPublicKeyPkidPairSnapshotEntriesEdge>;
  /** A list of `BlsPublicKeyPkidPairSnapshotEntry` objects. */
  nodes: Array<Maybe<BlsPublicKeyPkidPairSnapshotEntry>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `BlsPublicKeyPkidPairSnapshotEntry` you could get from the connection. */
  totalCount: Scalars["Int"]["output"];
};

/** A `BlsPublicKeyPkidPairSnapshotEntry` edge in the connection. */
export type BlsPublicKeyPkidPairSnapshotEntriesEdge = {
  __typename?: "BlsPublicKeyPkidPairSnapshotEntriesEdge";
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars["Cursor"]["output"]>;
  /** The `BlsPublicKeyPkidPairSnapshotEntry` at the end of the edge. */
  node?: Maybe<BlsPublicKeyPkidPairSnapshotEntry>;
};

/** Methods to use when ordering `BlsPublicKeyPkidPairSnapshotEntry`. */
export enum BlsPublicKeyPkidPairSnapshotEntriesOrderBy {
  BlsPublicKeyAsc = "BLS_PUBLIC_KEY_ASC",
  BlsPublicKeyDesc = "BLS_PUBLIC_KEY_DESC",
  Natural = "NATURAL",
  PkidAsc = "PKID_ASC",
  PkidDesc = "PKID_DESC",
  PrimaryKeyAsc = "PRIMARY_KEY_ASC",
  PrimaryKeyDesc = "PRIMARY_KEY_DESC",
  SnapshotAtEpochNumberAsc = "SNAPSHOT_AT_EPOCH_NUMBER_ASC",
  SnapshotAtEpochNumberDesc = "SNAPSHOT_AT_EPOCH_NUMBER_DESC",
}

export type BlsPublicKeyPkidPairSnapshotEntry = Node & {
  __typename?: "BlsPublicKeyPkidPairSnapshotEntry";
  /** Reads a single `Account` that is related to this `BlsPublicKeyPkidPairSnapshotEntry`. */
  account?: Maybe<Account>;
  blsPublicKey: Scalars["String"]["output"];
  /** Reads a single `EpochEntry` that is related to this `BlsPublicKeyPkidPairSnapshotEntry`. */
  epochEntry?: Maybe<EpochEntry>;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  id: Scalars["ID"]["output"];
  pkid: Scalars["String"]["output"];
  snapshotAtEpochNumber: Scalars["BigInt"]["output"];
};

/**
 * A condition to be used against `BlsPublicKeyPkidPairSnapshotEntry` object types.
 * All fields are tested for equality and combined with a logical ‘and.’
 */
export type BlsPublicKeyPkidPairSnapshotEntryCondition = {
  /** Checks for equality with the object’s `blsPublicKey` field. */
  blsPublicKey?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `pkid` field. */
  pkid?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `snapshotAtEpochNumber` field. */
  snapshotAtEpochNumber?: InputMaybe<Scalars["BigInt"]["input"]>;
};

/** A filter to be used against `BlsPublicKeyPkidPairSnapshotEntry` object types. All fields are combined with a logical ‘and.’ */
export type BlsPublicKeyPkidPairSnapshotEntryFilter = {
  /** Filter by the object’s `account` relation. */
  account?: InputMaybe<AccountFilter>;
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<BlsPublicKeyPkidPairSnapshotEntryFilter>>;
  /** Filter by the object’s `blsPublicKey` field. */
  blsPublicKey?: InputMaybe<StringFilter>;
  /** Filter by the object’s `epochEntry` relation. */
  epochEntry?: InputMaybe<EpochEntryFilter>;
  /** Negates the expression. */
  not?: InputMaybe<BlsPublicKeyPkidPairSnapshotEntryFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<BlsPublicKeyPkidPairSnapshotEntryFilter>>;
  /** Filter by the object’s `pkid` field. */
  pkid?: InputMaybe<StringFilter>;
  /** Filter by the object’s `snapshotAtEpochNumber` field. */
  snapshotAtEpochNumber?: InputMaybe<BigIntFilter>;
};

/** A filter to be used against Boolean fields. All fields are combined with a logical ‘and.’ */
export type BooleanFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Included in the specified list. */
  in?: InputMaybe<Array<Scalars["Boolean"]["input"]>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Not included in the specified list. */
  notIn?: InputMaybe<Array<Scalars["Boolean"]["input"]>>;
};

export type CreatorCoinBalance = Node & {
  __typename?: "CreatorCoinBalance";
  balanceNanos?: Maybe<Scalars["BigFloat"]["output"]>;
  coinPriceDesoNanos?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Reads a single `Account` that is related to this `CreatorCoinBalance`. */
  creator?: Maybe<Account>;
  creatorPkid: Scalars["String"]["output"];
  hasPurchased?: Maybe<Scalars["Boolean"]["output"]>;
  hodlerPkid: Scalars["String"]["output"];
  /** Reads a single `Account` that is related to this `CreatorCoinBalance`. */
  holder?: Maybe<Account>;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  id: Scalars["ID"]["output"];
  totalValueNanos?: Maybe<Scalars["BigFloat"]["output"]>;
};

/**
 * A condition to be used against `CreatorCoinBalance` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type CreatorCoinBalanceCondition = {
  /** Checks for equality with the object’s `balanceNanos` field. */
  balanceNanos?: InputMaybe<Scalars["BigFloat"]["input"]>;
  /** Checks for equality with the object’s `coinPriceDesoNanos` field. */
  coinPriceDesoNanos?: InputMaybe<Scalars["BigFloat"]["input"]>;
  /** Checks for equality with the object’s `creatorPkid` field. */
  creatorPkid?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `hasPurchased` field. */
  hasPurchased?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Checks for equality with the object’s `hodlerPkid` field. */
  hodlerPkid?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `totalValueNanos` field. */
  totalValueNanos?: InputMaybe<Scalars["BigFloat"]["input"]>;
};

/** A filter to be used against `CreatorCoinBalance` object types. All fields are combined with a logical ‘and.’ */
export type CreatorCoinBalanceFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<CreatorCoinBalanceFilter>>;
  /** Filter by the object’s `balanceNanos` field. */
  balanceNanos?: InputMaybe<BigFloatFilter>;
  /** Filter by the object’s `coinPriceDesoNanos` field. */
  coinPriceDesoNanos?: InputMaybe<BigFloatFilter>;
  /** Filter by the object’s `creator` relation. */
  creator?: InputMaybe<AccountFilter>;
  /** A related `creator` exists. */
  creatorExists?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Filter by the object’s `creatorPkid` field. */
  creatorPkid?: InputMaybe<StringFilter>;
  /** Filter by the object’s `hasPurchased` field. */
  hasPurchased?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `hodlerPkid` field. */
  hodlerPkid?: InputMaybe<StringFilter>;
  /** Filter by the object’s `holder` relation. */
  holder?: InputMaybe<AccountFilter>;
  /** A related `holder` exists. */
  holderExists?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Negates the expression. */
  not?: InputMaybe<CreatorCoinBalanceFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<CreatorCoinBalanceFilter>>;
  /** Filter by the object’s `totalValueNanos` field. */
  totalValueNanos?: InputMaybe<BigFloatFilter>;
};

/** A connection to a list of `CreatorCoinBalance` values. */
export type CreatorCoinBalancesConnection = {
  __typename?: "CreatorCoinBalancesConnection";
  /** A list of edges which contains the `CreatorCoinBalance` and cursor to aid in pagination. */
  edges: Array<CreatorCoinBalancesEdge>;
  /** A list of `CreatorCoinBalance` objects. */
  nodes: Array<Maybe<CreatorCoinBalance>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `CreatorCoinBalance` you could get from the connection. */
  totalCount: Scalars["Int"]["output"];
};

/** A `CreatorCoinBalance` edge in the connection. */
export type CreatorCoinBalancesEdge = {
  __typename?: "CreatorCoinBalancesEdge";
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars["Cursor"]["output"]>;
  /** The `CreatorCoinBalance` at the end of the edge. */
  node?: Maybe<CreatorCoinBalance>;
};

/** Methods to use when ordering `CreatorCoinBalance`. */
export enum CreatorCoinBalancesOrderBy {
  BalanceNanosAsc = "BALANCE_NANOS_ASC",
  BalanceNanosDesc = "BALANCE_NANOS_DESC",
  CoinPriceDesoNanosAsc = "COIN_PRICE_DESO_NANOS_ASC",
  CoinPriceDesoNanosDesc = "COIN_PRICE_DESO_NANOS_DESC",
  CreatorPkidAsc = "CREATOR_PKID_ASC",
  CreatorPkidDesc = "CREATOR_PKID_DESC",
  HasPurchasedAsc = "HAS_PURCHASED_ASC",
  HasPurchasedDesc = "HAS_PURCHASED_DESC",
  HodlerPkidAsc = "HODLER_PKID_ASC",
  HodlerPkidDesc = "HODLER_PKID_DESC",
  Natural = "NATURAL",
  PrimaryKeyAsc = "PRIMARY_KEY_ASC",
  PrimaryKeyDesc = "PRIMARY_KEY_DESC",
  TotalValueNanosAsc = "TOTAL_VALUE_NANOS_ASC",
  TotalValueNanosDesc = "TOTAL_VALUE_NANOS_DESC",
}

export type DailyActiveWalletCountStat = {
  __typename?: "DailyActiveWalletCountStat";
  count?: Maybe<Scalars["BigInt"]["output"]>;
  day?: Maybe<Scalars["Date"]["output"]>;
  rowId?: Maybe<Scalars["BigInt"]["output"]>;
};

/**
 * A condition to be used against `DailyActiveWalletCountStat` object types. All
 * fields are tested for equality and combined with a logical ‘and.’
 */
export type DailyActiveWalletCountStatCondition = {
  /** Checks for equality with the object’s `count` field. */
  count?: InputMaybe<Scalars["BigInt"]["input"]>;
  /** Checks for equality with the object’s `day` field. */
  day?: InputMaybe<Scalars["Date"]["input"]>;
  /** Checks for equality with the object’s `rowId` field. */
  rowId?: InputMaybe<Scalars["BigInt"]["input"]>;
};

/** A filter to be used against `DailyActiveWalletCountStat` object types. All fields are combined with a logical ‘and.’ */
export type DailyActiveWalletCountStatFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<DailyActiveWalletCountStatFilter>>;
  /** Filter by the object’s `count` field. */
  count?: InputMaybe<BigIntFilter>;
  /** Filter by the object’s `day` field. */
  day?: InputMaybe<DateFilter>;
  /** Negates the expression. */
  not?: InputMaybe<DailyActiveWalletCountStatFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<DailyActiveWalletCountStatFilter>>;
  /** Filter by the object’s `rowId` field. */
  rowId?: InputMaybe<BigIntFilter>;
};

/** A connection to a list of `DailyActiveWalletCountStat` values. */
export type DailyActiveWalletCountStatsConnection = {
  __typename?: "DailyActiveWalletCountStatsConnection";
  /** A list of edges which contains the `DailyActiveWalletCountStat` and cursor to aid in pagination. */
  edges: Array<DailyActiveWalletCountStatsEdge>;
  /** A list of `DailyActiveWalletCountStat` objects. */
  nodes: Array<Maybe<DailyActiveWalletCountStat>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `DailyActiveWalletCountStat` you could get from the connection. */
  totalCount: Scalars["Int"]["output"];
};

/** A `DailyActiveWalletCountStat` edge in the connection. */
export type DailyActiveWalletCountStatsEdge = {
  __typename?: "DailyActiveWalletCountStatsEdge";
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars["Cursor"]["output"]>;
  /** The `DailyActiveWalletCountStat` at the end of the edge. */
  node?: Maybe<DailyActiveWalletCountStat>;
};

/** Methods to use when ordering `DailyActiveWalletCountStat`. */
export enum DailyActiveWalletCountStatsOrderBy {
  CountAsc = "COUNT_ASC",
  CountDesc = "COUNT_DESC",
  DayAsc = "DAY_ASC",
  DayDesc = "DAY_DESC",
  IdAsc = "ID_ASC",
  IdDesc = "ID_DESC",
  Natural = "NATURAL",
}

export type DailyNewWalletCountStat = {
  __typename?: "DailyNewWalletCountStat";
  day?: Maybe<Scalars["Date"]["output"]>;
  rowId?: Maybe<Scalars["BigInt"]["output"]>;
  walletCount?: Maybe<Scalars["BigInt"]["output"]>;
};

/**
 * A condition to be used against `DailyNewWalletCountStat` object types. All
 * fields are tested for equality and combined with a logical ‘and.’
 */
export type DailyNewWalletCountStatCondition = {
  /** Checks for equality with the object’s `day` field. */
  day?: InputMaybe<Scalars["Date"]["input"]>;
  /** Checks for equality with the object’s `rowId` field. */
  rowId?: InputMaybe<Scalars["BigInt"]["input"]>;
  /** Checks for equality with the object’s `walletCount` field. */
  walletCount?: InputMaybe<Scalars["BigInt"]["input"]>;
};

/** A filter to be used against `DailyNewWalletCountStat` object types. All fields are combined with a logical ‘and.’ */
export type DailyNewWalletCountStatFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<DailyNewWalletCountStatFilter>>;
  /** Filter by the object’s `day` field. */
  day?: InputMaybe<DateFilter>;
  /** Negates the expression. */
  not?: InputMaybe<DailyNewWalletCountStatFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<DailyNewWalletCountStatFilter>>;
  /** Filter by the object’s `rowId` field. */
  rowId?: InputMaybe<BigIntFilter>;
  /** Filter by the object’s `walletCount` field. */
  walletCount?: InputMaybe<BigIntFilter>;
};

/** A connection to a list of `DailyNewWalletCountStat` values. */
export type DailyNewWalletCountStatsConnection = {
  __typename?: "DailyNewWalletCountStatsConnection";
  /** A list of edges which contains the `DailyNewWalletCountStat` and cursor to aid in pagination. */
  edges: Array<DailyNewWalletCountStatsEdge>;
  /** A list of `DailyNewWalletCountStat` objects. */
  nodes: Array<Maybe<DailyNewWalletCountStat>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `DailyNewWalletCountStat` you could get from the connection. */
  totalCount: Scalars["Int"]["output"];
};

/** A `DailyNewWalletCountStat` edge in the connection. */
export type DailyNewWalletCountStatsEdge = {
  __typename?: "DailyNewWalletCountStatsEdge";
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars["Cursor"]["output"]>;
  /** The `DailyNewWalletCountStat` at the end of the edge. */
  node?: Maybe<DailyNewWalletCountStat>;
};

/** Methods to use when ordering `DailyNewWalletCountStat`. */
export enum DailyNewWalletCountStatsOrderBy {
  DayAsc = "DAY_ASC",
  DayDesc = "DAY_DESC",
  IdAsc = "ID_ASC",
  IdDesc = "ID_DESC",
  Natural = "NATURAL",
  WalletCountAsc = "WALLET_COUNT_ASC",
  WalletCountDesc = "WALLET_COUNT_DESC",
}

export type DailyTxnCountStat = {
  __typename?: "DailyTxnCountStat";
  day?: Maybe<Scalars["Date"]["output"]>;
  rowId?: Maybe<Scalars["BigInt"]["output"]>;
  transactionCount?: Maybe<Scalars["BigInt"]["output"]>;
};

/**
 * A condition to be used against `DailyTxnCountStat` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type DailyTxnCountStatCondition = {
  /** Checks for equality with the object’s `day` field. */
  day?: InputMaybe<Scalars["Date"]["input"]>;
  /** Checks for equality with the object’s `rowId` field. */
  rowId?: InputMaybe<Scalars["BigInt"]["input"]>;
  /** Checks for equality with the object’s `transactionCount` field. */
  transactionCount?: InputMaybe<Scalars["BigInt"]["input"]>;
};

/** A filter to be used against `DailyTxnCountStat` object types. All fields are combined with a logical ‘and.’ */
export type DailyTxnCountStatFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<DailyTxnCountStatFilter>>;
  /** Filter by the object’s `day` field. */
  day?: InputMaybe<DateFilter>;
  /** Negates the expression. */
  not?: InputMaybe<DailyTxnCountStatFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<DailyTxnCountStatFilter>>;
  /** Filter by the object’s `rowId` field. */
  rowId?: InputMaybe<BigIntFilter>;
  /** Filter by the object’s `transactionCount` field. */
  transactionCount?: InputMaybe<BigIntFilter>;
};

/** A connection to a list of `DailyTxnCountStat` values. */
export type DailyTxnCountStatsConnection = {
  __typename?: "DailyTxnCountStatsConnection";
  /** A list of edges which contains the `DailyTxnCountStat` and cursor to aid in pagination. */
  edges: Array<DailyTxnCountStatsEdge>;
  /** A list of `DailyTxnCountStat` objects. */
  nodes: Array<Maybe<DailyTxnCountStat>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `DailyTxnCountStat` you could get from the connection. */
  totalCount: Scalars["Int"]["output"];
};

/** A `DailyTxnCountStat` edge in the connection. */
export type DailyTxnCountStatsEdge = {
  __typename?: "DailyTxnCountStatsEdge";
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars["Cursor"]["output"]>;
  /** The `DailyTxnCountStat` at the end of the edge. */
  node?: Maybe<DailyTxnCountStat>;
};

/** Methods to use when ordering `DailyTxnCountStat`. */
export enum DailyTxnCountStatsOrderBy {
  DayAsc = "DAY_ASC",
  DayDesc = "DAY_DESC",
  IdAsc = "ID_ASC",
  IdDesc = "ID_DESC",
  Natural = "NATURAL",
  TransactionCountAsc = "TRANSACTION_COUNT_ASC",
  TransactionCountDesc = "TRANSACTION_COUNT_DESC",
}

export type DashboardStat = {
  __typename?: "DashboardStat";
  activeWalletCount30D?: Maybe<Scalars["BigInt"]["output"]>;
  blockHeightCurrent?: Maybe<Scalars["BigInt"]["output"]>;
  commentCount?: Maybe<Scalars["BigInt"]["output"]>;
  followCount?: Maybe<Scalars["BigInt"]["output"]>;
  messageCount?: Maybe<Scalars["BigFloat"]["output"]>;
  newWalletCount30D?: Maybe<Scalars["BigInt"]["output"]>;
  postCount?: Maybe<Scalars["BigInt"]["output"]>;
  postLongformCount?: Maybe<Scalars["BigInt"]["output"]>;
  repostCount?: Maybe<Scalars["BigInt"]["output"]>;
  totalSupply?: Maybe<Scalars["BigFloat"]["output"]>;
  txnCount30D?: Maybe<Scalars["BigInt"]["output"]>;
  txnCountAll?: Maybe<Scalars["BigFloat"]["output"]>;
  txnCountCreatorCoin?: Maybe<Scalars["BigInt"]["output"]>;
  txnCountDex?: Maybe<Scalars["BigInt"]["output"]>;
  txnCountNft?: Maybe<Scalars["BigInt"]["output"]>;
  txnCountPending?: Maybe<Scalars["BigInt"]["output"]>;
  txnCountSocial?: Maybe<Scalars["BigInt"]["output"]>;
  txnFee1D?: Maybe<Scalars["BigFloat"]["output"]>;
  walletCountAll?: Maybe<Scalars["BigInt"]["output"]>;
};

/**
 * A condition to be used against `DashboardStat` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type DashboardStatCondition = {
  /** Checks for equality with the object’s `activeWalletCount30D` field. */
  activeWalletCount30D?: InputMaybe<Scalars["BigInt"]["input"]>;
  /** Checks for equality with the object’s `blockHeightCurrent` field. */
  blockHeightCurrent?: InputMaybe<Scalars["BigInt"]["input"]>;
  /** Checks for equality with the object’s `commentCount` field. */
  commentCount?: InputMaybe<Scalars["BigInt"]["input"]>;
  /** Checks for equality with the object’s `followCount` field. */
  followCount?: InputMaybe<Scalars["BigInt"]["input"]>;
  /** Checks for equality with the object’s `messageCount` field. */
  messageCount?: InputMaybe<Scalars["BigFloat"]["input"]>;
  /** Checks for equality with the object’s `newWalletCount30D` field. */
  newWalletCount30D?: InputMaybe<Scalars["BigInt"]["input"]>;
  /** Checks for equality with the object’s `postCount` field. */
  postCount?: InputMaybe<Scalars["BigInt"]["input"]>;
  /** Checks for equality with the object’s `postLongformCount` field. */
  postLongformCount?: InputMaybe<Scalars["BigInt"]["input"]>;
  /** Checks for equality with the object’s `repostCount` field. */
  repostCount?: InputMaybe<Scalars["BigInt"]["input"]>;
  /** Checks for equality with the object’s `totalSupply` field. */
  totalSupply?: InputMaybe<Scalars["BigFloat"]["input"]>;
  /** Checks for equality with the object’s `txnCount30D` field. */
  txnCount30D?: InputMaybe<Scalars["BigInt"]["input"]>;
  /** Checks for equality with the object’s `txnCountAll` field. */
  txnCountAll?: InputMaybe<Scalars["BigFloat"]["input"]>;
  /** Checks for equality with the object’s `txnCountCreatorCoin` field. */
  txnCountCreatorCoin?: InputMaybe<Scalars["BigInt"]["input"]>;
  /** Checks for equality with the object’s `txnCountDex` field. */
  txnCountDex?: InputMaybe<Scalars["BigInt"]["input"]>;
  /** Checks for equality with the object’s `txnCountNft` field. */
  txnCountNft?: InputMaybe<Scalars["BigInt"]["input"]>;
  /** Checks for equality with the object’s `txnCountPending` field. */
  txnCountPending?: InputMaybe<Scalars["BigInt"]["input"]>;
  /** Checks for equality with the object’s `txnCountSocial` field. */
  txnCountSocial?: InputMaybe<Scalars["BigInt"]["input"]>;
  /** Checks for equality with the object’s `txnFee1D` field. */
  txnFee1D?: InputMaybe<Scalars["BigFloat"]["input"]>;
  /** Checks for equality with the object’s `walletCountAll` field. */
  walletCountAll?: InputMaybe<Scalars["BigInt"]["input"]>;
};

/** A filter to be used against `DashboardStat` object types. All fields are combined with a logical ‘and.’ */
export type DashboardStatFilter = {
  /** Filter by the object’s `activeWalletCount30D` field. */
  activeWalletCount30D?: InputMaybe<BigIntFilter>;
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<DashboardStatFilter>>;
  /** Filter by the object’s `blockHeightCurrent` field. */
  blockHeightCurrent?: InputMaybe<BigIntFilter>;
  /** Filter by the object’s `commentCount` field. */
  commentCount?: InputMaybe<BigIntFilter>;
  /** Filter by the object’s `followCount` field. */
  followCount?: InputMaybe<BigIntFilter>;
  /** Filter by the object’s `messageCount` field. */
  messageCount?: InputMaybe<BigFloatFilter>;
  /** Filter by the object’s `newWalletCount30D` field. */
  newWalletCount30D?: InputMaybe<BigIntFilter>;
  /** Negates the expression. */
  not?: InputMaybe<DashboardStatFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<DashboardStatFilter>>;
  /** Filter by the object’s `postCount` field. */
  postCount?: InputMaybe<BigIntFilter>;
  /** Filter by the object’s `postLongformCount` field. */
  postLongformCount?: InputMaybe<BigIntFilter>;
  /** Filter by the object’s `repostCount` field. */
  repostCount?: InputMaybe<BigIntFilter>;
  /** Filter by the object’s `totalSupply` field. */
  totalSupply?: InputMaybe<BigFloatFilter>;
  /** Filter by the object’s `txnCount30D` field. */
  txnCount30D?: InputMaybe<BigIntFilter>;
  /** Filter by the object’s `txnCountAll` field. */
  txnCountAll?: InputMaybe<BigFloatFilter>;
  /** Filter by the object’s `txnCountCreatorCoin` field. */
  txnCountCreatorCoin?: InputMaybe<BigIntFilter>;
  /** Filter by the object’s `txnCountDex` field. */
  txnCountDex?: InputMaybe<BigIntFilter>;
  /** Filter by the object’s `txnCountNft` field. */
  txnCountNft?: InputMaybe<BigIntFilter>;
  /** Filter by the object’s `txnCountPending` field. */
  txnCountPending?: InputMaybe<BigIntFilter>;
  /** Filter by the object’s `txnCountSocial` field. */
  txnCountSocial?: InputMaybe<BigIntFilter>;
  /** Filter by the object’s `txnFee1D` field. */
  txnFee1D?: InputMaybe<BigFloatFilter>;
  /** Filter by the object’s `walletCountAll` field. */
  walletCountAll?: InputMaybe<BigIntFilter>;
};

/** A connection to a list of `DashboardStat` values. */
export type DashboardStatsConnection = {
  __typename?: "DashboardStatsConnection";
  /** A list of edges which contains the `DashboardStat` and cursor to aid in pagination. */
  edges: Array<DashboardStatsEdge>;
  /** A list of `DashboardStat` objects. */
  nodes: Array<Maybe<DashboardStat>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `DashboardStat` you could get from the connection. */
  totalCount: Scalars["Int"]["output"];
};

/** A `DashboardStat` edge in the connection. */
export type DashboardStatsEdge = {
  __typename?: "DashboardStatsEdge";
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars["Cursor"]["output"]>;
  /** The `DashboardStat` at the end of the edge. */
  node?: Maybe<DashboardStat>;
};

/** Methods to use when ordering `DashboardStat`. */
export enum DashboardStatsOrderBy {
  ActiveWalletCount_30DAsc = "ACTIVE_WALLET_COUNT_30_D_ASC",
  ActiveWalletCount_30DDesc = "ACTIVE_WALLET_COUNT_30_D_DESC",
  BlockHeightCurrentAsc = "BLOCK_HEIGHT_CURRENT_ASC",
  BlockHeightCurrentDesc = "BLOCK_HEIGHT_CURRENT_DESC",
  CommentCountAsc = "COMMENT_COUNT_ASC",
  CommentCountDesc = "COMMENT_COUNT_DESC",
  FollowCountAsc = "FOLLOW_COUNT_ASC",
  FollowCountDesc = "FOLLOW_COUNT_DESC",
  MessageCountAsc = "MESSAGE_COUNT_ASC",
  MessageCountDesc = "MESSAGE_COUNT_DESC",
  Natural = "NATURAL",
  NewWalletCount_30DAsc = "NEW_WALLET_COUNT_30_D_ASC",
  NewWalletCount_30DDesc = "NEW_WALLET_COUNT_30_D_DESC",
  PostCountAsc = "POST_COUNT_ASC",
  PostCountDesc = "POST_COUNT_DESC",
  PostLongformCountAsc = "POST_LONGFORM_COUNT_ASC",
  PostLongformCountDesc = "POST_LONGFORM_COUNT_DESC",
  RepostCountAsc = "REPOST_COUNT_ASC",
  RepostCountDesc = "REPOST_COUNT_DESC",
  TotalSupplyAsc = "TOTAL_SUPPLY_ASC",
  TotalSupplyDesc = "TOTAL_SUPPLY_DESC",
  TxnCount_30DAsc = "TXN_COUNT_30_D_ASC",
  TxnCount_30DDesc = "TXN_COUNT_30_D_DESC",
  TxnCountAllAsc = "TXN_COUNT_ALL_ASC",
  TxnCountAllDesc = "TXN_COUNT_ALL_DESC",
  TxnCountCreatorCoinAsc = "TXN_COUNT_CREATOR_COIN_ASC",
  TxnCountCreatorCoinDesc = "TXN_COUNT_CREATOR_COIN_DESC",
  TxnCountDexAsc = "TXN_COUNT_DEX_ASC",
  TxnCountDexDesc = "TXN_COUNT_DEX_DESC",
  TxnCountNftAsc = "TXN_COUNT_NFT_ASC",
  TxnCountNftDesc = "TXN_COUNT_NFT_DESC",
  TxnCountPendingAsc = "TXN_COUNT_PENDING_ASC",
  TxnCountPendingDesc = "TXN_COUNT_PENDING_DESC",
  TxnCountSocialAsc = "TXN_COUNT_SOCIAL_ASC",
  TxnCountSocialDesc = "TXN_COUNT_SOCIAL_DESC",
  TxnFee_1DAsc = "TXN_FEE_1_D_ASC",
  TxnFee_1DDesc = "TXN_FEE_1_D_DESC",
  WalletCountAllAsc = "WALLET_COUNT_ALL_ASC",
  WalletCountAllDesc = "WALLET_COUNT_ALL_DESC",
}

/** A filter to be used against Date fields. All fields are combined with a logical ‘and.’ */
export type DateFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<Scalars["Date"]["input"]>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<Scalars["Date"]["input"]>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<Scalars["Date"]["input"]>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<Scalars["Date"]["input"]>;
  /** Included in the specified list. */
  in?: InputMaybe<Array<Scalars["Date"]["input"]>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<Scalars["Date"]["input"]>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<Scalars["Date"]["input"]>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<Scalars["Date"]["input"]>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<Scalars["Date"]["input"]>;
  /** Not included in the specified list. */
  notIn?: InputMaybe<Array<Scalars["Date"]["input"]>>;
};

/** A filter to be used against Datetime fields. All fields are combined with a logical ‘and.’ */
export type DatetimeFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<Scalars["Datetime"]["input"]>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<Scalars["Datetime"]["input"]>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<Scalars["Datetime"]["input"]>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<Scalars["Datetime"]["input"]>;
  /** Included in the specified list. */
  in?: InputMaybe<Array<Scalars["Datetime"]["input"]>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<Scalars["Datetime"]["input"]>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<Scalars["Datetime"]["input"]>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<Scalars["Datetime"]["input"]>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<Scalars["Datetime"]["input"]>;
  /** Not included in the specified list. */
  notIn?: InputMaybe<Array<Scalars["Datetime"]["input"]>>;
};

export type DefiLeaderboardStat = {
  __typename?: "DefiLeaderboardStat";
  buyingPublicKey?: Maybe<Scalars["String"]["output"]>;
  netQuantity?: Maybe<Scalars["BigFloat"]["output"]>;
  pkid?: Maybe<Scalars["String"]["output"]>;
  publicKey?: Maybe<Scalars["String"]["output"]>;
  username?: Maybe<Scalars["String"]["output"]>;
};

/**
 * A condition to be used against `DefiLeaderboardStat` object types. All fields
 * are tested for equality and combined with a logical ‘and.’
 */
export type DefiLeaderboardStatCondition = {
  /** Checks for equality with the object’s `buyingPublicKey` field. */
  buyingPublicKey?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `netQuantity` field. */
  netQuantity?: InputMaybe<Scalars["BigFloat"]["input"]>;
  /** Checks for equality with the object’s `pkid` field. */
  pkid?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `publicKey` field. */
  publicKey?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `username` field. */
  username?: InputMaybe<Scalars["String"]["input"]>;
};

/** A filter to be used against `DefiLeaderboardStat` object types. All fields are combined with a logical ‘and.’ */
export type DefiLeaderboardStatFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<DefiLeaderboardStatFilter>>;
  /** Filter by the object’s `buyingPublicKey` field. */
  buyingPublicKey?: InputMaybe<StringFilter>;
  /** Filter by the object’s `netQuantity` field. */
  netQuantity?: InputMaybe<BigFloatFilter>;
  /** Negates the expression. */
  not?: InputMaybe<DefiLeaderboardStatFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<DefiLeaderboardStatFilter>>;
  /** Filter by the object’s `pkid` field. */
  pkid?: InputMaybe<StringFilter>;
  /** Filter by the object’s `publicKey` field. */
  publicKey?: InputMaybe<StringFilter>;
  /** Filter by the object’s `username` field. */
  username?: InputMaybe<StringFilter>;
};

/** A connection to a list of `DefiLeaderboardStat` values. */
export type DefiLeaderboardStatsConnection = {
  __typename?: "DefiLeaderboardStatsConnection";
  /** A list of edges which contains the `DefiLeaderboardStat` and cursor to aid in pagination. */
  edges: Array<DefiLeaderboardStatsEdge>;
  /** A list of `DefiLeaderboardStat` objects. */
  nodes: Array<Maybe<DefiLeaderboardStat>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `DefiLeaderboardStat` you could get from the connection. */
  totalCount: Scalars["Int"]["output"];
};

/** A `DefiLeaderboardStat` edge in the connection. */
export type DefiLeaderboardStatsEdge = {
  __typename?: "DefiLeaderboardStatsEdge";
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars["Cursor"]["output"]>;
  /** The `DefiLeaderboardStat` at the end of the edge. */
  node?: Maybe<DefiLeaderboardStat>;
};

/** Methods to use when ordering `DefiLeaderboardStat`. */
export enum DefiLeaderboardStatsOrderBy {
  BuyingPublicKeyAsc = "BUYING_PUBLIC_KEY_ASC",
  BuyingPublicKeyDesc = "BUYING_PUBLIC_KEY_DESC",
  Natural = "NATURAL",
  NetQuantityAsc = "NET_QUANTITY_ASC",
  NetQuantityDesc = "NET_QUANTITY_DESC",
  PkidAsc = "PKID_ASC",
  PkidDesc = "PKID_DESC",
  PublicKeyAsc = "PUBLIC_KEY_ASC",
  PublicKeyDesc = "PUBLIC_KEY_DESC",
  UsernameAsc = "USERNAME_ASC",
  UsernameDesc = "USERNAME_DESC",
}

export type DerivedKey = Node & {
  __typename?: "DerivedKey";
  derivedPublicKey: Scalars["String"]["output"];
  expirationBlock: Scalars["BigInt"]["output"];
  extraData?: Maybe<Scalars["JSON"]["output"]>;
  globalDesoLimit?: Maybe<Scalars["BigInt"]["output"]>;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  id: Scalars["ID"]["output"];
  isUnlimited?: Maybe<Scalars["Boolean"]["output"]>;
  isValid?: Maybe<Scalars["Boolean"]["output"]>;
  operationType: Scalars["Int"]["output"];
  /** Reads a single `Account` that is related to this `DerivedKey`. */
  owner?: Maybe<Account>;
  ownerPublicKey: Scalars["String"]["output"];
  transactionSpendingLimits?: Maybe<Scalars["JSON"]["output"]>;
};

/**
 * A condition to be used against `DerivedKey` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type DerivedKeyCondition = {
  /** Checks for equality with the object’s `derivedPublicKey` field. */
  derivedPublicKey?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `expirationBlock` field. */
  expirationBlock?: InputMaybe<Scalars["BigInt"]["input"]>;
  /** Checks for equality with the object’s `extraData` field. */
  extraData?: InputMaybe<Scalars["JSON"]["input"]>;
  /** Checks for equality with the object’s `globalDesoLimit` field. */
  globalDesoLimit?: InputMaybe<Scalars["BigInt"]["input"]>;
  /** Checks for equality with the object’s `isUnlimited` field. */
  isUnlimited?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Checks for equality with the object’s `operationType` field. */
  operationType?: InputMaybe<Scalars["Int"]["input"]>;
  /** Checks for equality with the object’s `ownerPublicKey` field. */
  ownerPublicKey?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `transactionSpendingLimits` field. */
  transactionSpendingLimits?: InputMaybe<Scalars["JSON"]["input"]>;
};

/** A filter to be used against `DerivedKey` object types. All fields are combined with a logical ‘and.’ */
export type DerivedKeyFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<DerivedKeyFilter>>;
  /** Filter by the object’s `derivedPublicKey` field. */
  derivedPublicKey?: InputMaybe<StringFilter>;
  /** Filter by the object’s `expirationBlock` field. */
  expirationBlock?: InputMaybe<BigIntFilter>;
  /** Filter by the object’s `extraData` field. */
  extraData?: InputMaybe<JsonFilter>;
  /** Filter by the object’s `globalDesoLimit` field. */
  globalDesoLimit?: InputMaybe<BigIntFilter>;
  /** Filter by the object’s `isUnlimited` field. */
  isUnlimited?: InputMaybe<BooleanFilter>;
  /** Negates the expression. */
  not?: InputMaybe<DerivedKeyFilter>;
  /** Filter by the object’s `operationType` field. */
  operationType?: InputMaybe<IntFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<DerivedKeyFilter>>;
  /** Filter by the object’s `owner` relation. */
  owner?: InputMaybe<AccountFilter>;
  /** Filter by the object’s `ownerPublicKey` field. */
  ownerPublicKey?: InputMaybe<StringFilter>;
  /** Filter by the object’s `transactionSpendingLimits` field. */
  transactionSpendingLimits?: InputMaybe<JsonFilter>;
};

/** A connection to a list of `DerivedKey` values. */
export type DerivedKeysConnection = {
  __typename?: "DerivedKeysConnection";
  /** A list of edges which contains the `DerivedKey` and cursor to aid in pagination. */
  edges: Array<DerivedKeysEdge>;
  /** A list of `DerivedKey` objects. */
  nodes: Array<Maybe<DerivedKey>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `DerivedKey` you could get from the connection. */
  totalCount: Scalars["Int"]["output"];
};

/** A `DerivedKey` edge in the connection. */
export type DerivedKeysEdge = {
  __typename?: "DerivedKeysEdge";
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars["Cursor"]["output"]>;
  /** The `DerivedKey` at the end of the edge. */
  node?: Maybe<DerivedKey>;
};

/** Methods to use when ordering `DerivedKey`. */
export enum DerivedKeysOrderBy {
  DerivedPublicKeyAsc = "DERIVED_PUBLIC_KEY_ASC",
  DerivedPublicKeyDesc = "DERIVED_PUBLIC_KEY_DESC",
  ExpirationBlockAsc = "EXPIRATION_BLOCK_ASC",
  ExpirationBlockDesc = "EXPIRATION_BLOCK_DESC",
  ExtraDataAsc = "EXTRA_DATA_ASC",
  ExtraDataDesc = "EXTRA_DATA_DESC",
  GlobalDesoLimitAsc = "GLOBAL_DESO_LIMIT_ASC",
  GlobalDesoLimitDesc = "GLOBAL_DESO_LIMIT_DESC",
  IsUnlimitedAsc = "IS_UNLIMITED_ASC",
  IsUnlimitedDesc = "IS_UNLIMITED_DESC",
  Natural = "NATURAL",
  OperationTypeAsc = "OPERATION_TYPE_ASC",
  OperationTypeDesc = "OPERATION_TYPE_DESC",
  OwnerPublicKeyAsc = "OWNER_PUBLIC_KEY_ASC",
  OwnerPublicKeyDesc = "OWNER_PUBLIC_KEY_DESC",
  PrimaryKeyAsc = "PRIMARY_KEY_ASC",
  PrimaryKeyDesc = "PRIMARY_KEY_DESC",
  TransactionSpendingLimitsAsc = "TRANSACTION_SPENDING_LIMITS_ASC",
  TransactionSpendingLimitsDesc = "TRANSACTION_SPENDING_LIMITS_DESC",
}

export type DesoBalance = Node & {
  __typename?: "DesoBalance";
  /** Reads a single `Account` that is related to this `DesoBalance`. */
  account?: Maybe<Account>;
  balanceNanos: Scalars["BigInt"]["output"];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  id: Scalars["ID"]["output"];
  publicKey: Scalars["String"]["output"];
};

/**
 * A condition to be used against `DesoBalance` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type DesoBalanceCondition = {
  /** Checks for equality with the object’s `balanceNanos` field. */
  balanceNanos?: InputMaybe<Scalars["BigInt"]["input"]>;
  /** Checks for equality with the object’s `publicKey` field. */
  publicKey?: InputMaybe<Scalars["String"]["input"]>;
};

/** A filter to be used against `DesoBalance` object types. All fields are combined with a logical ‘and.’ */
export type DesoBalanceFilter = {
  /** Filter by the object’s `account` relation. */
  account?: InputMaybe<AccountFilter>;
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<DesoBalanceFilter>>;
  /** Filter by the object’s `balanceNanos` field. */
  balanceNanos?: InputMaybe<BigIntFilter>;
  /** Negates the expression. */
  not?: InputMaybe<DesoBalanceFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<DesoBalanceFilter>>;
  /** Filter by the object’s `publicKey` field. */
  publicKey?: InputMaybe<StringFilter>;
};

/** A connection to a list of `DesoBalance` values. */
export type DesoBalancesConnection = {
  __typename?: "DesoBalancesConnection";
  /** A list of edges which contains the `DesoBalance` and cursor to aid in pagination. */
  edges: Array<DesoBalancesEdge>;
  /** A list of `DesoBalance` objects. */
  nodes: Array<Maybe<DesoBalance>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `DesoBalance` you could get from the connection. */
  totalCount: Scalars["Int"]["output"];
};

/** A `DesoBalance` edge in the connection. */
export type DesoBalancesEdge = {
  __typename?: "DesoBalancesEdge";
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars["Cursor"]["output"]>;
  /** The `DesoBalance` at the end of the edge. */
  node?: Maybe<DesoBalance>;
};

/** Methods to use when ordering `DesoBalance`. */
export enum DesoBalancesOrderBy {
  BalanceNanosAsc = "BALANCE_NANOS_ASC",
  BalanceNanosDesc = "BALANCE_NANOS_DESC",
  Natural = "NATURAL",
  PrimaryKeyAsc = "PRIMARY_KEY_ASC",
  PrimaryKeyDesc = "PRIMARY_KEY_DESC",
  PublicKeyAsc = "PUBLIC_KEY_ASC",
  PublicKeyDesc = "PUBLIC_KEY_DESC",
}

export type DesoTokenLimitOrder = Node & {
  __typename?: "DesoTokenLimitOrder";
  badgerKey: Scalars["String"]["output"];
  blockHeight?: Maybe<Scalars["BigInt"]["output"]>;
  buyingDaoCoinCreatorPkid?: Maybe<Scalars["String"]["output"]>;
  /** Reads a single `Account` that is related to this `DesoTokenLimitOrder`. */
  creatorBoughtAccount?: Maybe<Account>;
  /** Reads a single `Account` that is related to this `DesoTokenLimitOrder`. */
  creatorSoldAccount?: Maybe<Account>;
  fillType?: Maybe<Scalars["Int"]["output"]>;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  id: Scalars["ID"]["output"];
  isDaoCoinConst: Scalars["Boolean"]["output"];
  operationType?: Maybe<Scalars["Int"]["output"]>;
  orderId?: Maybe<Scalars["String"]["output"]>;
  quantityToFillInBaseUnitsHex?: Maybe<Scalars["String"]["output"]>;
  quantityToFillInBaseUnitsNumeric?: Maybe<Scalars["BigFloat"]["output"]>;
  scaledExchangeRateCoinsToSellPerCoinToBuyHex?: Maybe<
    Scalars["String"]["output"]
  >;
  scaledExchangeRateCoinsToSellPerCoinToBuyNumeric?: Maybe<
    Scalars["BigFloat"]["output"]
  >;
  sellingDaoCoinCreatorPkid?: Maybe<Scalars["String"]["output"]>;
  /** Reads a single `Account` that is related to this `DesoTokenLimitOrder`. */
  transactorAccount?: Maybe<Account>;
  /** Reads a single `TokenBalance` that is related to this `DesoTokenLimitOrder`. */
  transactorBuyingTokenBalance?: Maybe<TokenBalance>;
  transactorPkid?: Maybe<Scalars["String"]["output"]>;
  /** Reads a single `TokenBalance` that is related to this `DesoTokenLimitOrder`. */
  transactorSellingTokenBalance?: Maybe<TokenBalance>;
};

export type DesoTokenLimitOrderBidAsk = {
  __typename?: "DesoTokenLimitOrderBidAsk";
  ask?: Maybe<Scalars["BigFloat"]["output"]>;
  askOrderCount?: Maybe<Scalars["BigInt"]["output"]>;
  askSumQuantityToFillInBaseUnits?: Maybe<Scalars["BigFloat"]["output"]>;
  askSumScaledExchangeRateCoinsToSellPerCoinToBuy?: Maybe<
    Scalars["BigFloat"]["output"]
  >;
  bid?: Maybe<Scalars["BigFloat"]["output"]>;
  bidOrderCount?: Maybe<Scalars["BigInt"]["output"]>;
  bidSumQuantityToFillInBaseUnits?: Maybe<Scalars["BigFloat"]["output"]>;
  bidSumScaledExchangeRateCoinsToSellPerCoinToBuy?: Maybe<
    Scalars["BigFloat"]["output"]
  >;
  buyingCreatorPkid?: Maybe<Scalars["String"]["output"]>;
  /** Reads a single `Account` that is related to this `DesoTokenLimitOrderBidAsk`. */
  buyingTokenAccount?: Maybe<Account>;
  marketPrice?: Maybe<Scalars["BigFloat"]["output"]>;
  sellingCreatorPkid?: Maybe<Scalars["String"]["output"]>;
  /** Reads a single `Account` that is related to this `DesoTokenLimitOrderBidAsk`. */
  sellingTokenAccount?: Maybe<Account>;
};

/**
 * A condition to be used against `DesoTokenLimitOrderBidAsk` object types. All
 * fields are tested for equality and combined with a logical ‘and.’
 */
export type DesoTokenLimitOrderBidAskCondition = {
  /** Checks for equality with the object’s `ask` field. */
  ask?: InputMaybe<Scalars["BigFloat"]["input"]>;
  /** Checks for equality with the object’s `askOrderCount` field. */
  askOrderCount?: InputMaybe<Scalars["BigInt"]["input"]>;
  /** Checks for equality with the object’s `askSumQuantityToFillInBaseUnits` field. */
  askSumQuantityToFillInBaseUnits?: InputMaybe<Scalars["BigFloat"]["input"]>;
  /** Checks for equality with the object’s `askSumScaledExchangeRateCoinsToSellPerCoinToBuy` field. */
  askSumScaledExchangeRateCoinsToSellPerCoinToBuy?: InputMaybe<
    Scalars["BigFloat"]["input"]
  >;
  /** Checks for equality with the object’s `bid` field. */
  bid?: InputMaybe<Scalars["BigFloat"]["input"]>;
  /** Checks for equality with the object’s `bidOrderCount` field. */
  bidOrderCount?: InputMaybe<Scalars["BigInt"]["input"]>;
  /** Checks for equality with the object’s `bidSumQuantityToFillInBaseUnits` field. */
  bidSumQuantityToFillInBaseUnits?: InputMaybe<Scalars["BigFloat"]["input"]>;
  /** Checks for equality with the object’s `bidSumScaledExchangeRateCoinsToSellPerCoinToBuy` field. */
  bidSumScaledExchangeRateCoinsToSellPerCoinToBuy?: InputMaybe<
    Scalars["BigFloat"]["input"]
  >;
  /** Checks for equality with the object’s `buyingCreatorPkid` field. */
  buyingCreatorPkid?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `marketPrice` field. */
  marketPrice?: InputMaybe<Scalars["BigFloat"]["input"]>;
  /** Checks for equality with the object’s `sellingCreatorPkid` field. */
  sellingCreatorPkid?: InputMaybe<Scalars["String"]["input"]>;
};

/** A filter to be used against `DesoTokenLimitOrderBidAsk` object types. All fields are combined with a logical ‘and.’ */
export type DesoTokenLimitOrderBidAskFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<DesoTokenLimitOrderBidAskFilter>>;
  /** Filter by the object’s `ask` field. */
  ask?: InputMaybe<BigFloatFilter>;
  /** Filter by the object’s `askOrderCount` field. */
  askOrderCount?: InputMaybe<BigIntFilter>;
  /** Filter by the object’s `askSumQuantityToFillInBaseUnits` field. */
  askSumQuantityToFillInBaseUnits?: InputMaybe<BigFloatFilter>;
  /** Filter by the object’s `askSumScaledExchangeRateCoinsToSellPerCoinToBuy` field. */
  askSumScaledExchangeRateCoinsToSellPerCoinToBuy?: InputMaybe<BigFloatFilter>;
  /** Filter by the object’s `bid` field. */
  bid?: InputMaybe<BigFloatFilter>;
  /** Filter by the object’s `bidOrderCount` field. */
  bidOrderCount?: InputMaybe<BigIntFilter>;
  /** Filter by the object’s `bidSumQuantityToFillInBaseUnits` field. */
  bidSumQuantityToFillInBaseUnits?: InputMaybe<BigFloatFilter>;
  /** Filter by the object’s `bidSumScaledExchangeRateCoinsToSellPerCoinToBuy` field. */
  bidSumScaledExchangeRateCoinsToSellPerCoinToBuy?: InputMaybe<BigFloatFilter>;
  /** Filter by the object’s `buyingCreatorPkid` field. */
  buyingCreatorPkid?: InputMaybe<StringFilter>;
  /** Filter by the object’s `buyingTokenAccount` relation. */
  buyingTokenAccount?: InputMaybe<AccountFilter>;
  /** A related `buyingTokenAccount` exists. */
  buyingTokenAccountExists?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Filter by the object’s `marketPrice` field. */
  marketPrice?: InputMaybe<BigFloatFilter>;
  /** Negates the expression. */
  not?: InputMaybe<DesoTokenLimitOrderBidAskFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<DesoTokenLimitOrderBidAskFilter>>;
  /** Filter by the object’s `sellingCreatorPkid` field. */
  sellingCreatorPkid?: InputMaybe<StringFilter>;
  /** Filter by the object’s `sellingTokenAccount` relation. */
  sellingTokenAccount?: InputMaybe<AccountFilter>;
  /** A related `sellingTokenAccount` exists. */
  sellingTokenAccountExists?: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** A connection to a list of `DesoTokenLimitOrderBidAsk` values. */
export type DesoTokenLimitOrderBidAsksConnection = {
  __typename?: "DesoTokenLimitOrderBidAsksConnection";
  /** A list of edges which contains the `DesoTokenLimitOrderBidAsk` and cursor to aid in pagination. */
  edges: Array<DesoTokenLimitOrderBidAsksEdge>;
  /** A list of `DesoTokenLimitOrderBidAsk` objects. */
  nodes: Array<Maybe<DesoTokenLimitOrderBidAsk>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `DesoTokenLimitOrderBidAsk` you could get from the connection. */
  totalCount: Scalars["Int"]["output"];
};

/** A `DesoTokenLimitOrderBidAsk` edge in the connection. */
export type DesoTokenLimitOrderBidAsksEdge = {
  __typename?: "DesoTokenLimitOrderBidAsksEdge";
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars["Cursor"]["output"]>;
  /** The `DesoTokenLimitOrderBidAsk` at the end of the edge. */
  node?: Maybe<DesoTokenLimitOrderBidAsk>;
};

/** Methods to use when ordering `DesoTokenLimitOrderBidAsk`. */
export enum DesoTokenLimitOrderBidAsksOrderBy {
  AskAsc = "ASK_ASC",
  AskDesc = "ASK_DESC",
  AskOrderCountAsc = "ASK_ORDER_COUNT_ASC",
  AskOrderCountDesc = "ASK_ORDER_COUNT_DESC",
  AskSumQuantityToFillInBaseUnitsAsc = "ASK_SUM_QUANTITY_TO_FILL_IN_BASE_UNITS_ASC",
  AskSumQuantityToFillInBaseUnitsDesc = "ASK_SUM_QUANTITY_TO_FILL_IN_BASE_UNITS_DESC",
  AskSumScaledExchangeRateCoinsToSellPerCoinToBuyAsc = "ASK_SUM_SCALED_EXCHANGE_RATE_COINS_TO_SELL_PER_COIN_TO_BUY_ASC",
  AskSumScaledExchangeRateCoinsToSellPerCoinToBuyDesc = "ASK_SUM_SCALED_EXCHANGE_RATE_COINS_TO_SELL_PER_COIN_TO_BUY_DESC",
  BidAsc = "BID_ASC",
  BidDesc = "BID_DESC",
  BidOrderCountAsc = "BID_ORDER_COUNT_ASC",
  BidOrderCountDesc = "BID_ORDER_COUNT_DESC",
  BidSumQuantityToFillInBaseUnitsAsc = "BID_SUM_QUANTITY_TO_FILL_IN_BASE_UNITS_ASC",
  BidSumQuantityToFillInBaseUnitsDesc = "BID_SUM_QUANTITY_TO_FILL_IN_BASE_UNITS_DESC",
  BidSumScaledExchangeRateCoinsToSellPerCoinToBuyAsc = "BID_SUM_SCALED_EXCHANGE_RATE_COINS_TO_SELL_PER_COIN_TO_BUY_ASC",
  BidSumScaledExchangeRateCoinsToSellPerCoinToBuyDesc = "BID_SUM_SCALED_EXCHANGE_RATE_COINS_TO_SELL_PER_COIN_TO_BUY_DESC",
  BuyingCreatorPkidAsc = "BUYING_CREATOR_PKID_ASC",
  BuyingCreatorPkidDesc = "BUYING_CREATOR_PKID_DESC",
  MarketPriceAsc = "MARKET_PRICE_ASC",
  MarketPriceDesc = "MARKET_PRICE_DESC",
  Natural = "NATURAL",
  SellingCreatorPkidAsc = "SELLING_CREATOR_PKID_ASC",
  SellingCreatorPkidDesc = "SELLING_CREATOR_PKID_DESC",
}

/**
 * A condition to be used against `DesoTokenLimitOrder` object types. All fields
 * are tested for equality and combined with a logical ‘and.’
 */
export type DesoTokenLimitOrderCondition = {
  /** Checks for equality with the object’s `badgerKey` field. */
  badgerKey?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `blockHeight` field. */
  blockHeight?: InputMaybe<Scalars["BigInt"]["input"]>;
  /** Checks for equality with the object’s `buyingDaoCoinCreatorPkid` field. */
  buyingDaoCoinCreatorPkid?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `fillType` field. */
  fillType?: InputMaybe<Scalars["Int"]["input"]>;
  /** Checks for equality with the object’s `isDaoCoinConst` field. */
  isDaoCoinConst?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Checks for equality with the object’s `operationType` field. */
  operationType?: InputMaybe<Scalars["Int"]["input"]>;
  /** Checks for equality with the object’s `orderId` field. */
  orderId?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `quantityToFillInBaseUnitsHex` field. */
  quantityToFillInBaseUnitsHex?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `quantityToFillInBaseUnitsNumeric` field. */
  quantityToFillInBaseUnitsNumeric?: InputMaybe<Scalars["BigFloat"]["input"]>;
  /** Checks for equality with the object’s `scaledExchangeRateCoinsToSellPerCoinToBuyHex` field. */
  scaledExchangeRateCoinsToSellPerCoinToBuyHex?: InputMaybe<
    Scalars["String"]["input"]
  >;
  /** Checks for equality with the object’s `scaledExchangeRateCoinsToSellPerCoinToBuyNumeric` field. */
  scaledExchangeRateCoinsToSellPerCoinToBuyNumeric?: InputMaybe<
    Scalars["BigFloat"]["input"]
  >;
  /** Checks for equality with the object’s `sellingDaoCoinCreatorPkid` field. */
  sellingDaoCoinCreatorPkid?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `transactorPkid` field. */
  transactorPkid?: InputMaybe<Scalars["String"]["input"]>;
};

/** A filter to be used against `DesoTokenLimitOrder` object types. All fields are combined with a logical ‘and.’ */
export type DesoTokenLimitOrderFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<DesoTokenLimitOrderFilter>>;
  /** Filter by the object’s `blockHeight` field. */
  blockHeight?: InputMaybe<BigIntFilter>;
  /** Filter by the object’s `buyingDaoCoinCreatorPkid` field. */
  buyingDaoCoinCreatorPkid?: InputMaybe<StringFilter>;
  /** Filter by the object’s `creatorBoughtAccount` relation. */
  creatorBoughtAccount?: InputMaybe<AccountFilter>;
  /** A related `creatorBoughtAccount` exists. */
  creatorBoughtAccountExists?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Filter by the object’s `creatorSoldAccount` relation. */
  creatorSoldAccount?: InputMaybe<AccountFilter>;
  /** A related `creatorSoldAccount` exists. */
  creatorSoldAccountExists?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Filter by the object’s `fillType` field. */
  fillType?: InputMaybe<IntFilter>;
  /** Filter by the object’s `isDaoCoinConst` field. */
  isDaoCoinConst?: InputMaybe<BooleanFilter>;
  /** Negates the expression. */
  not?: InputMaybe<DesoTokenLimitOrderFilter>;
  /** Filter by the object’s `operationType` field. */
  operationType?: InputMaybe<IntFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<DesoTokenLimitOrderFilter>>;
  /** Filter by the object’s `orderId` field. */
  orderId?: InputMaybe<StringFilter>;
  /** Filter by the object’s `quantityToFillInBaseUnitsHex` field. */
  quantityToFillInBaseUnitsHex?: InputMaybe<StringFilter>;
  /** Filter by the object’s `quantityToFillInBaseUnitsNumeric` field. */
  quantityToFillInBaseUnitsNumeric?: InputMaybe<BigFloatFilter>;
  /** Filter by the object’s `scaledExchangeRateCoinsToSellPerCoinToBuyHex` field. */
  scaledExchangeRateCoinsToSellPerCoinToBuyHex?: InputMaybe<StringFilter>;
  /** Filter by the object’s `scaledExchangeRateCoinsToSellPerCoinToBuyNumeric` field. */
  scaledExchangeRateCoinsToSellPerCoinToBuyNumeric?: InputMaybe<BigFloatFilter>;
  /** Filter by the object’s `sellingDaoCoinCreatorPkid` field. */
  sellingDaoCoinCreatorPkid?: InputMaybe<StringFilter>;
  /** Filter by the object’s `transactorAccount` relation. */
  transactorAccount?: InputMaybe<AccountFilter>;
  /** A related `transactorAccount` exists. */
  transactorAccountExists?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Filter by the object’s `transactorBuyingTokenBalance` relation. */
  transactorBuyingTokenBalance?: InputMaybe<TokenBalanceFilter>;
  /** A related `transactorBuyingTokenBalance` exists. */
  transactorBuyingTokenBalanceExists?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Filter by the object’s `transactorPkid` field. */
  transactorPkid?: InputMaybe<StringFilter>;
  /** Filter by the object’s `transactorSellingTokenBalance` relation. */
  transactorSellingTokenBalance?: InputMaybe<TokenBalanceFilter>;
  /** A related `transactorSellingTokenBalance` exists. */
  transactorSellingTokenBalanceExists?: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** A connection to a list of `DesoTokenLimitOrder` values. */
export type DesoTokenLimitOrdersConnection = {
  __typename?: "DesoTokenLimitOrdersConnection";
  /** A list of edges which contains the `DesoTokenLimitOrder` and cursor to aid in pagination. */
  edges: Array<DesoTokenLimitOrdersEdge>;
  /** A list of `DesoTokenLimitOrder` objects. */
  nodes: Array<Maybe<DesoTokenLimitOrder>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `DesoTokenLimitOrder` you could get from the connection. */
  totalCount: Scalars["Int"]["output"];
};

/** A `DesoTokenLimitOrder` edge in the connection. */
export type DesoTokenLimitOrdersEdge = {
  __typename?: "DesoTokenLimitOrdersEdge";
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars["Cursor"]["output"]>;
  /** The `DesoTokenLimitOrder` at the end of the edge. */
  node?: Maybe<DesoTokenLimitOrder>;
};

/** Methods to use when ordering `DesoTokenLimitOrder`. */
export enum DesoTokenLimitOrdersOrderBy {
  BadgerKeyAsc = "BADGER_KEY_ASC",
  BadgerKeyDesc = "BADGER_KEY_DESC",
  BlockHeightAsc = "BLOCK_HEIGHT_ASC",
  BlockHeightDesc = "BLOCK_HEIGHT_DESC",
  BuyingDaoCoinCreatorPkidAsc = "BUYING_DAO_COIN_CREATOR_PKID_ASC",
  BuyingDaoCoinCreatorPkidDesc = "BUYING_DAO_COIN_CREATOR_PKID_DESC",
  FillTypeAsc = "FILL_TYPE_ASC",
  FillTypeDesc = "FILL_TYPE_DESC",
  IsDaoCoinConstAsc = "IS_DAO_COIN_CONST_ASC",
  IsDaoCoinConstDesc = "IS_DAO_COIN_CONST_DESC",
  Natural = "NATURAL",
  OperationTypeAsc = "OPERATION_TYPE_ASC",
  OperationTypeDesc = "OPERATION_TYPE_DESC",
  OrderIdAsc = "ORDER_ID_ASC",
  OrderIdDesc = "ORDER_ID_DESC",
  PrimaryKeyAsc = "PRIMARY_KEY_ASC",
  PrimaryKeyDesc = "PRIMARY_KEY_DESC",
  QuantityToFillInBaseUnitsHexAsc = "QUANTITY_TO_FILL_IN_BASE_UNITS_HEX_ASC",
  QuantityToFillInBaseUnitsHexDesc = "QUANTITY_TO_FILL_IN_BASE_UNITS_HEX_DESC",
  QuantityToFillInBaseUnitsNumericAsc = "QUANTITY_TO_FILL_IN_BASE_UNITS_NUMERIC_ASC",
  QuantityToFillInBaseUnitsNumericDesc = "QUANTITY_TO_FILL_IN_BASE_UNITS_NUMERIC_DESC",
  ScaledExchangeRateCoinsToSellPerCoinToBuyHexAsc = "SCALED_EXCHANGE_RATE_COINS_TO_SELL_PER_COIN_TO_BUY_HEX_ASC",
  ScaledExchangeRateCoinsToSellPerCoinToBuyHexDesc = "SCALED_EXCHANGE_RATE_COINS_TO_SELL_PER_COIN_TO_BUY_HEX_DESC",
  ScaledExchangeRateCoinsToSellPerCoinToBuyNumericAsc = "SCALED_EXCHANGE_RATE_COINS_TO_SELL_PER_COIN_TO_BUY_NUMERIC_ASC",
  ScaledExchangeRateCoinsToSellPerCoinToBuyNumericDesc = "SCALED_EXCHANGE_RATE_COINS_TO_SELL_PER_COIN_TO_BUY_NUMERIC_DESC",
  SellingDaoCoinCreatorPkidAsc = "SELLING_DAO_COIN_CREATOR_PKID_ASC",
  SellingDaoCoinCreatorPkidDesc = "SELLING_DAO_COIN_CREATOR_PKID_DESC",
  TransactorPkidAsc = "TRANSACTOR_PKID_ASC",
  TransactorPkidDesc = "TRANSACTOR_PKID_DESC",
}

export type Diamond = Node & {
  __typename?: "Diamond";
  diamondLevel: Scalars["Int"]["output"];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  id: Scalars["ID"]["output"];
  /** Reads a single `Post` that is related to this `Diamond`. */
  post?: Maybe<Post>;
  postHash: Scalars["String"]["output"];
  receiverPkid: Scalars["String"]["output"];
  /** Reads a single `Account` that is related to this `Diamond`. */
  reciever?: Maybe<Account>;
  /** Reads a single `Account` that is related to this `Diamond`. */
  sender?: Maybe<Account>;
  senderPkid: Scalars["String"]["output"];
};

/** A condition to be used against `Diamond` object types. All fields are tested for equality and combined with a logical ‘and.’ */
export type DiamondCondition = {
  /** Checks for equality with the object’s `diamondLevel` field. */
  diamondLevel?: InputMaybe<Scalars["Int"]["input"]>;
  /** Checks for equality with the object’s `postHash` field. */
  postHash?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `receiverPkid` field. */
  receiverPkid?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `senderPkid` field. */
  senderPkid?: InputMaybe<Scalars["String"]["input"]>;
};

/** A filter to be used against `Diamond` object types. All fields are combined with a logical ‘and.’ */
export type DiamondFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<DiamondFilter>>;
  /** Filter by the object’s `diamondLevel` field. */
  diamondLevel?: InputMaybe<IntFilter>;
  /** Negates the expression. */
  not?: InputMaybe<DiamondFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<DiamondFilter>>;
  /** Filter by the object’s `post` relation. */
  post?: InputMaybe<PostFilter>;
  /** Filter by the object’s `postHash` field. */
  postHash?: InputMaybe<StringFilter>;
  /** Filter by the object’s `receiverPkid` field. */
  receiverPkid?: InputMaybe<StringFilter>;
  /** Filter by the object’s `reciever` relation. */
  reciever?: InputMaybe<AccountFilter>;
  /** Filter by the object’s `sender` relation. */
  sender?: InputMaybe<AccountFilter>;
  /** Filter by the object’s `senderPkid` field. */
  senderPkid?: InputMaybe<StringFilter>;
};

/** A connection to a list of `Diamond` values. */
export type DiamondsConnection = {
  __typename?: "DiamondsConnection";
  /** A list of edges which contains the `Diamond` and cursor to aid in pagination. */
  edges: Array<DiamondsEdge>;
  /** A list of `Diamond` objects. */
  nodes: Array<Maybe<Diamond>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Diamond` you could get from the connection. */
  totalCount: Scalars["Int"]["output"];
};

/** A `Diamond` edge in the connection. */
export type DiamondsEdge = {
  __typename?: "DiamondsEdge";
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars["Cursor"]["output"]>;
  /** The `Diamond` at the end of the edge. */
  node?: Maybe<Diamond>;
};

/** Methods to use when ordering `Diamond`. */
export enum DiamondsOrderBy {
  DiamondLevelAsc = "DIAMOND_LEVEL_ASC",
  DiamondLevelDesc = "DIAMOND_LEVEL_DESC",
  Natural = "NATURAL",
  PostHashAsc = "POST_HASH_ASC",
  PostHashDesc = "POST_HASH_DESC",
  PrimaryKeyAsc = "PRIMARY_KEY_ASC",
  PrimaryKeyDesc = "PRIMARY_KEY_DESC",
  ReceiverPkidAsc = "RECEIVER_PKID_ASC",
  ReceiverPkidDesc = "RECEIVER_PKID_DESC",
  SenderPkidAsc = "SENDER_PKID_ASC",
  SenderPkidDesc = "SENDER_PKID_DESC",
}

export type EpochDetailsForBlock = {
  __typename?: "EpochDetailsForBlock";
  /** Reads a single `Block` that is related to this `EpochDetailsForBlock`. */
  block?: Maybe<Block>;
  blockHash?: Maybe<Scalars["String"]["output"]>;
  /** Reads a single `EpochEntry` that is related to this `EpochDetailsForBlock`. */
  epochEntry?: Maybe<EpochEntry>;
  epochNumber?: Maybe<Scalars["BigInt"]["output"]>;
  /** Reads a single `Account` that is related to this `EpochDetailsForBlock`. */
  proposer?: Maybe<Account>;
  proposerPkid?: Maybe<Scalars["String"]["output"]>;
};

/**
 * A condition to be used against `EpochDetailsForBlock` object types. All fields
 * are tested for equality and combined with a logical ‘and.’
 */
export type EpochDetailsForBlockCondition = {
  /** Checks for equality with the object’s `blockHash` field. */
  blockHash?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `epochNumber` field. */
  epochNumber?: InputMaybe<Scalars["BigInt"]["input"]>;
  /** Checks for equality with the object’s `proposerPkid` field. */
  proposerPkid?: InputMaybe<Scalars["String"]["input"]>;
};

/** A filter to be used against `EpochDetailsForBlock` object types. All fields are combined with a logical ‘and.’ */
export type EpochDetailsForBlockFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<EpochDetailsForBlockFilter>>;
  /** Filter by the object’s `block` relation. */
  block?: InputMaybe<BlockFilter>;
  /** A related `block` exists. */
  blockExists?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Filter by the object’s `blockHash` field. */
  blockHash?: InputMaybe<StringFilter>;
  /** Filter by the object’s `epochEntry` relation. */
  epochEntry?: InputMaybe<EpochEntryFilter>;
  /** A related `epochEntry` exists. */
  epochEntryExists?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Filter by the object’s `epochNumber` field. */
  epochNumber?: InputMaybe<BigIntFilter>;
  /** Negates the expression. */
  not?: InputMaybe<EpochDetailsForBlockFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<EpochDetailsForBlockFilter>>;
  /** Filter by the object’s `proposer` relation. */
  proposer?: InputMaybe<AccountFilter>;
  /** A related `proposer` exists. */
  proposerExists?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Filter by the object’s `proposerPkid` field. */
  proposerPkid?: InputMaybe<StringFilter>;
};

/** A connection to a list of `EpochDetailsForBlock` values. */
export type EpochDetailsForBlocksConnection = {
  __typename?: "EpochDetailsForBlocksConnection";
  /** A list of edges which contains the `EpochDetailsForBlock` and cursor to aid in pagination. */
  edges: Array<EpochDetailsForBlocksEdge>;
  /** A list of `EpochDetailsForBlock` objects. */
  nodes: Array<Maybe<EpochDetailsForBlock>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `EpochDetailsForBlock` you could get from the connection. */
  totalCount: Scalars["Int"]["output"];
};

/** A `EpochDetailsForBlock` edge in the connection. */
export type EpochDetailsForBlocksEdge = {
  __typename?: "EpochDetailsForBlocksEdge";
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars["Cursor"]["output"]>;
  /** The `EpochDetailsForBlock` at the end of the edge. */
  node?: Maybe<EpochDetailsForBlock>;
};

/** Methods to use when ordering `EpochDetailsForBlock`. */
export enum EpochDetailsForBlocksOrderBy {
  BlockHashAsc = "BLOCK_HASH_ASC",
  BlockHashDesc = "BLOCK_HASH_DESC",
  EpochNumberAsc = "EPOCH_NUMBER_ASC",
  EpochNumberDesc = "EPOCH_NUMBER_DESC",
  Natural = "NATURAL",
  ProposerPkidAsc = "PROPOSER_PKID_ASC",
  ProposerPkidDesc = "PROPOSER_PKID_DESC",
}

/** A connection to a list of `EpochEntry` values. */
export type EpochEntriesConnection = {
  __typename?: "EpochEntriesConnection";
  /** A list of edges which contains the `EpochEntry` and cursor to aid in pagination. */
  edges: Array<EpochEntriesEdge>;
  /** A list of `EpochEntry` objects. */
  nodes: Array<Maybe<EpochEntry>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `EpochEntry` you could get from the connection. */
  totalCount: Scalars["Int"]["output"];
};

/** A `EpochEntry` edge in the connection. */
export type EpochEntriesEdge = {
  __typename?: "EpochEntriesEdge";
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars["Cursor"]["output"]>;
  /** The `EpochEntry` at the end of the edge. */
  node?: Maybe<EpochEntry>;
};

/** Methods to use when ordering `EpochEntry`. */
export enum EpochEntriesOrderBy {
  CreatedAtBlockTimestampNanoSecsAsc = "CREATED_AT_BLOCK_TIMESTAMP_NANO_SECS_ASC",
  CreatedAtBlockTimestampNanoSecsDesc = "CREATED_AT_BLOCK_TIMESTAMP_NANO_SECS_DESC",
  EpochNumberAsc = "EPOCH_NUMBER_ASC",
  EpochNumberDesc = "EPOCH_NUMBER_DESC",
  FinalBlockHeightAsc = "FINAL_BLOCK_HEIGHT_ASC",
  FinalBlockHeightDesc = "FINAL_BLOCK_HEIGHT_DESC",
  InitialBlockHeightAsc = "INITIAL_BLOCK_HEIGHT_ASC",
  InitialBlockHeightDesc = "INITIAL_BLOCK_HEIGHT_DESC",
  InitialLeaderIndexOffsetAsc = "INITIAL_LEADER_INDEX_OFFSET_ASC",
  InitialLeaderIndexOffsetDesc = "INITIAL_LEADER_INDEX_OFFSET_DESC",
  InitialViewAsc = "INITIAL_VIEW_ASC",
  InitialViewDesc = "INITIAL_VIEW_DESC",
  Natural = "NATURAL",
  PrimaryKeyAsc = "PRIMARY_KEY_ASC",
  PrimaryKeyDesc = "PRIMARY_KEY_DESC",
  SnapshotAtEpochNumberAsc = "SNAPSHOT_AT_EPOCH_NUMBER_ASC",
  SnapshotAtEpochNumberDesc = "SNAPSHOT_AT_EPOCH_NUMBER_DESC",
}

export type EpochEntry = Node & {
  __typename?: "EpochEntry";
  /** Reads a single `EpochDetailsForBlock` that is related to this `EpochEntry`. */
  blockHashesInEpoch?: Maybe<EpochDetailsForBlock>;
  /** Reads and enables pagination through a set of `BlsPublicKeyPkidPairSnapshotEntry`. */
  blsPublicKeyPkidPairSnapshotEntries: BlsPublicKeyPkidPairSnapshotEntriesConnection;
  createdAtBlockTimestampNanoSecs: Scalars["BigInt"]["output"];
  epochNumber: Scalars["BigInt"]["output"];
  finalBlockHeight: Scalars["BigInt"]["output"];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  id: Scalars["ID"]["output"];
  initialBlockHeight: Scalars["BigInt"]["output"];
  initialLeaderIndexOffset: Scalars["BigInt"]["output"];
  initialView: Scalars["BigInt"]["output"];
  /** Reads and enables pagination through a set of `LeaderScheduleEntry`. */
  leaderScheduleEntries: LeaderScheduleEntriesConnection;
  snapshotAtEpochNumber: Scalars["BigInt"]["output"];
};

export type EpochEntryBlsPublicKeyPkidPairSnapshotEntriesArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<BlsPublicKeyPkidPairSnapshotEntryCondition>;
  filter?: InputMaybe<BlsPublicKeyPkidPairSnapshotEntryFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<BlsPublicKeyPkidPairSnapshotEntriesOrderBy>>;
};

export type EpochEntryLeaderScheduleEntriesArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<LeaderScheduleEntryCondition>;
  filter?: InputMaybe<LeaderScheduleEntryFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<LeaderScheduleEntriesOrderBy>>;
};

/**
 * A condition to be used against `EpochEntry` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type EpochEntryCondition = {
  /** Checks for equality with the object’s `createdAtBlockTimestampNanoSecs` field. */
  createdAtBlockTimestampNanoSecs?: InputMaybe<Scalars["BigInt"]["input"]>;
  /** Checks for equality with the object’s `epochNumber` field. */
  epochNumber?: InputMaybe<Scalars["BigInt"]["input"]>;
  /** Checks for equality with the object’s `finalBlockHeight` field. */
  finalBlockHeight?: InputMaybe<Scalars["BigInt"]["input"]>;
  /** Checks for equality with the object’s `initialBlockHeight` field. */
  initialBlockHeight?: InputMaybe<Scalars["BigInt"]["input"]>;
  /** Checks for equality with the object’s `initialLeaderIndexOffset` field. */
  initialLeaderIndexOffset?: InputMaybe<Scalars["BigInt"]["input"]>;
  /** Checks for equality with the object’s `initialView` field. */
  initialView?: InputMaybe<Scalars["BigInt"]["input"]>;
  /** Checks for equality with the object’s `snapshotAtEpochNumber` field. */
  snapshotAtEpochNumber?: InputMaybe<Scalars["BigInt"]["input"]>;
};

/** A filter to be used against `EpochEntry` object types. All fields are combined with a logical ‘and.’ */
export type EpochEntryFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<EpochEntryFilter>>;
  /** Filter by the object’s `blockHashesInEpoch` relation. */
  blockHashesInEpoch?: InputMaybe<EpochDetailsForBlockFilter>;
  /** A related `blockHashesInEpoch` exists. */
  blockHashesInEpochExists?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Filter by the object’s `blsPublicKeyPkidPairSnapshotEntries` relation. */
  blsPublicKeyPkidPairSnapshotEntries?: InputMaybe<EpochEntryToManyBlsPublicKeyPkidPairSnapshotEntryFilter>;
  /** Some related `blsPublicKeyPkidPairSnapshotEntries` exist. */
  blsPublicKeyPkidPairSnapshotEntriesExist?: InputMaybe<
    Scalars["Boolean"]["input"]
  >;
  /** Filter by the object’s `createdAtBlockTimestampNanoSecs` field. */
  createdAtBlockTimestampNanoSecs?: InputMaybe<BigIntFilter>;
  /** Filter by the object’s `epochNumber` field. */
  epochNumber?: InputMaybe<BigIntFilter>;
  /** Filter by the object’s `finalBlockHeight` field. */
  finalBlockHeight?: InputMaybe<BigIntFilter>;
  /** Filter by the object’s `initialBlockHeight` field. */
  initialBlockHeight?: InputMaybe<BigIntFilter>;
  /** Filter by the object’s `initialLeaderIndexOffset` field. */
  initialLeaderIndexOffset?: InputMaybe<BigIntFilter>;
  /** Filter by the object’s `initialView` field. */
  initialView?: InputMaybe<BigIntFilter>;
  /** Filter by the object’s `leaderScheduleEntries` relation. */
  leaderScheduleEntries?: InputMaybe<EpochEntryToManyLeaderScheduleEntryFilter>;
  /** Some related `leaderScheduleEntries` exist. */
  leaderScheduleEntriesExist?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Negates the expression. */
  not?: InputMaybe<EpochEntryFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<EpochEntryFilter>>;
  /** Filter by the object’s `snapshotAtEpochNumber` field. */
  snapshotAtEpochNumber?: InputMaybe<BigIntFilter>;
};

/** A filter to be used against many `BlsPublicKeyPkidPairSnapshotEntry` object types. All fields are combined with a logical ‘and.’ */
export type EpochEntryToManyBlsPublicKeyPkidPairSnapshotEntryFilter = {
  /** Every related `BlsPublicKeyPkidPairSnapshotEntry` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<BlsPublicKeyPkidPairSnapshotEntryFilter>;
  /** No related `BlsPublicKeyPkidPairSnapshotEntry` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<BlsPublicKeyPkidPairSnapshotEntryFilter>;
  /** Some related `BlsPublicKeyPkidPairSnapshotEntry` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<BlsPublicKeyPkidPairSnapshotEntryFilter>;
};

/** A filter to be used against many `LeaderScheduleEntry` object types. All fields are combined with a logical ‘and.’ */
export type EpochEntryToManyLeaderScheduleEntryFilter = {
  /** Every related `LeaderScheduleEntry` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<LeaderScheduleEntryFilter>;
  /** No related `LeaderScheduleEntry` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<LeaderScheduleEntryFilter>;
  /** Some related `LeaderScheduleEntry` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<LeaderScheduleEntryFilter>;
};

/** A filter to be used against Float fields. All fields are combined with a logical ‘and.’ */
export type FloatFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<Scalars["Float"]["input"]>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<Scalars["Float"]["input"]>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<Scalars["Float"]["input"]>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<Scalars["Float"]["input"]>;
  /** Included in the specified list. */
  in?: InputMaybe<Array<Scalars["Float"]["input"]>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<Scalars["Float"]["input"]>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<Scalars["Float"]["input"]>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<Scalars["Float"]["input"]>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<Scalars["Float"]["input"]>;
  /** Not included in the specified list. */
  notIn?: InputMaybe<Array<Scalars["Float"]["input"]>>;
};

export type Follow = Node & {
  __typename?: "Follow";
  followedPkid: Scalars["String"]["output"];
  /** Reads a single `Account` that is related to this `Follow`. */
  followee?: Maybe<Account>;
  /** Reads a single `Account` that is related to this `Follow`. */
  follower?: Maybe<Account>;
  followerPkid: Scalars["String"]["output"];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  id: Scalars["ID"]["output"];
};

/** A condition to be used against `Follow` object types. All fields are tested for equality and combined with a logical ‘and.’ */
export type FollowCondition = {
  /** Checks for equality with the object’s `followedPkid` field. */
  followedPkid?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `followerPkid` field. */
  followerPkid?: InputMaybe<Scalars["String"]["input"]>;
};

/** A filter to be used against `Follow` object types. All fields are combined with a logical ‘and.’ */
export type FollowFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<FollowFilter>>;
  /** Filter by the object’s `followedPkid` field. */
  followedPkid?: InputMaybe<StringFilter>;
  /** Filter by the object’s `followee` relation. */
  followee?: InputMaybe<AccountFilter>;
  /** Filter by the object’s `follower` relation. */
  follower?: InputMaybe<AccountFilter>;
  /** Filter by the object’s `followerPkid` field. */
  followerPkid?: InputMaybe<StringFilter>;
  /** Negates the expression. */
  not?: InputMaybe<FollowFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<FollowFilter>>;
};

/** A connection to a list of `Follow` values. */
export type FollowsConnection = {
  __typename?: "FollowsConnection";
  /** A list of edges which contains the `Follow` and cursor to aid in pagination. */
  edges: Array<FollowsEdge>;
  /** A list of `Follow` objects. */
  nodes: Array<Maybe<Follow>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Follow` you could get from the connection. */
  totalCount: Scalars["Int"]["output"];
};

/** A `Follow` edge in the connection. */
export type FollowsEdge = {
  __typename?: "FollowsEdge";
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars["Cursor"]["output"]>;
  /** The `Follow` at the end of the edge. */
  node?: Maybe<Follow>;
};

/** Methods to use when ordering `Follow`. */
export enum FollowsOrderBy {
  FollowedPkidAsc = "FOLLOWED_PKID_ASC",
  FollowedPkidDesc = "FOLLOWED_PKID_DESC",
  FollowerPkidAsc = "FOLLOWER_PKID_ASC",
  FollowerPkidDesc = "FOLLOWER_PKID_DESC",
  Natural = "NATURAL",
  PrimaryKeyAsc = "PRIMARY_KEY_ASC",
  PrimaryKeyDesc = "PRIMARY_KEY_DESC",
}

/** A connection to a list of `GlobalParamsEntry` values. */
export type GlobalParamsEntriesConnection = {
  __typename?: "GlobalParamsEntriesConnection";
  /** A list of edges which contains the `GlobalParamsEntry` and cursor to aid in pagination. */
  edges: Array<GlobalParamsEntriesEdge>;
  /** A list of `GlobalParamsEntry` objects. */
  nodes: Array<Maybe<GlobalParamsEntry>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `GlobalParamsEntry` you could get from the connection. */
  totalCount: Scalars["Int"]["output"];
};

/** A `GlobalParamsEntry` edge in the connection. */
export type GlobalParamsEntriesEdge = {
  __typename?: "GlobalParamsEntriesEdge";
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars["Cursor"]["output"]>;
  /** The `GlobalParamsEntry` at the end of the edge. */
  node?: Maybe<GlobalParamsEntry>;
};

/** Methods to use when ordering `GlobalParamsEntry`. */
export enum GlobalParamsEntriesOrderBy {
  BlockProductionIntervalMillisecondsPosAsc = "BLOCK_PRODUCTION_INTERVAL_MILLISECONDS_POS_ASC",
  BlockProductionIntervalMillisecondsPosDesc = "BLOCK_PRODUCTION_INTERVAL_MILLISECONDS_POS_DESC",
  BlockTimestampDriftNanoSecsAsc = "BLOCK_TIMESTAMP_DRIFT_NANO_SECS_ASC",
  BlockTimestampDriftNanoSecsDesc = "BLOCK_TIMESTAMP_DRIFT_NANO_SECS_DESC",
  CreateNftFeeNanosAsc = "CREATE_NFT_FEE_NANOS_ASC",
  CreateNftFeeNanosDesc = "CREATE_NFT_FEE_NANOS_DESC",
  CreateProfileFeeNanosAsc = "CREATE_PROFILE_FEE_NANOS_ASC",
  CreateProfileFeeNanosDesc = "CREATE_PROFILE_FEE_NANOS_DESC",
  EpochDurationNumBlocksAsc = "EPOCH_DURATION_NUM_BLOCKS_ASC",
  EpochDurationNumBlocksDesc = "EPOCH_DURATION_NUM_BLOCKS_DESC",
  FeeBucketGrowthRateBasisPointsAsc = "FEE_BUCKET_GROWTH_RATE_BASIS_POINTS_ASC",
  FeeBucketGrowthRateBasisPointsDesc = "FEE_BUCKET_GROWTH_RATE_BASIS_POINTS_DESC",
  JailInactiveValidatorGracePeriodEpochsAsc = "JAIL_INACTIVE_VALIDATOR_GRACE_PERIOD_EPOCHS_ASC",
  JailInactiveValidatorGracePeriodEpochsDesc = "JAIL_INACTIVE_VALIDATOR_GRACE_PERIOD_EPOCHS_DESC",
  LeaderScheduleMaxNumValidatorsAsc = "LEADER_SCHEDULE_MAX_NUM_VALIDATORS_ASC",
  LeaderScheduleMaxNumValidatorsDesc = "LEADER_SCHEDULE_MAX_NUM_VALIDATORS_DESC",
  MaximumVestedIntersectionsPerLockupTransactionAsc = "MAXIMUM_VESTED_INTERSECTIONS_PER_LOCKUP_TRANSACTION_ASC",
  MaximumVestedIntersectionsPerLockupTransactionDesc = "MAXIMUM_VESTED_INTERSECTIONS_PER_LOCKUP_TRANSACTION_DESC",
  MaxBlockSizeBytesPosAsc = "MAX_BLOCK_SIZE_BYTES_POS_ASC",
  MaxBlockSizeBytesPosDesc = "MAX_BLOCK_SIZE_BYTES_POS_DESC",
  MaxCopiesPerNftAsc = "MAX_COPIES_PER_NFT_ASC",
  MaxCopiesPerNftDesc = "MAX_COPIES_PER_NFT_DESC",
  MaxNonceExpirationBlockHeightOffsetAsc = "MAX_NONCE_EXPIRATION_BLOCK_HEIGHT_OFFSET_ASC",
  MaxNonceExpirationBlockHeightOffsetDesc = "MAX_NONCE_EXPIRATION_BLOCK_HEIGHT_OFFSET_DESC",
  MaxTxnSizeBytesPosAsc = "MAX_TXN_SIZE_BYTES_POS_ASC",
  MaxTxnSizeBytesPosDesc = "MAX_TXN_SIZE_BYTES_POS_DESC",
  MempoolFeeEstimatorNumMempoolBlocksAsc = "MEMPOOL_FEE_ESTIMATOR_NUM_MEMPOOL_BLOCKS_ASC",
  MempoolFeeEstimatorNumMempoolBlocksDesc = "MEMPOOL_FEE_ESTIMATOR_NUM_MEMPOOL_BLOCKS_DESC",
  MempoolFeeEstimatorNumPastBlocksAsc = "MEMPOOL_FEE_ESTIMATOR_NUM_PAST_BLOCKS_ASC",
  MempoolFeeEstimatorNumPastBlocksDesc = "MEMPOOL_FEE_ESTIMATOR_NUM_PAST_BLOCKS_DESC",
  MempoolMaxSizeBytesAsc = "MEMPOOL_MAX_SIZE_BYTES_ASC",
  MempoolMaxSizeBytesDesc = "MEMPOOL_MAX_SIZE_BYTES_DESC",
  MinimumNetworkFeeNanosPerKbAsc = "MINIMUM_NETWORK_FEE_NANOS_PER_KB_ASC",
  MinimumNetworkFeeNanosPerKbDesc = "MINIMUM_NETWORK_FEE_NANOS_PER_KB_DESC",
  Natural = "NATURAL",
  PrimaryKeyAsc = "PRIMARY_KEY_ASC",
  PrimaryKeyDesc = "PRIMARY_KEY_DESC",
  SoftMaxBlockSizeBytesPosAsc = "SOFT_MAX_BLOCK_SIZE_BYTES_POS_ASC",
  SoftMaxBlockSizeBytesPosDesc = "SOFT_MAX_BLOCK_SIZE_BYTES_POS_DESC",
  StakeLockupEpochDurationAsc = "STAKE_LOCKUP_EPOCH_DURATION_ASC",
  StakeLockupEpochDurationDesc = "STAKE_LOCKUP_EPOCH_DURATION_DESC",
  StakingRewardsApyBasisPointsAsc = "STAKING_REWARDS_APY_BASIS_POINTS_ASC",
  StakingRewardsApyBasisPointsDesc = "STAKING_REWARDS_APY_BASIS_POINTS_DESC",
  StakingRewardsMaxNumStakesAsc = "STAKING_REWARDS_MAX_NUM_STAKES_ASC",
  StakingRewardsMaxNumStakesDesc = "STAKING_REWARDS_MAX_NUM_STAKES_DESC",
  TimeoutIntervalMillisecondsPosAsc = "TIMEOUT_INTERVAL_MILLISECONDS_POS_ASC",
  TimeoutIntervalMillisecondsPosDesc = "TIMEOUT_INTERVAL_MILLISECONDS_POS_DESC",
  UsdCentsPerBitcoinAsc = "USD_CENTS_PER_BITCOIN_ASC",
  UsdCentsPerBitcoinDesc = "USD_CENTS_PER_BITCOIN_DESC",
  ValidatorJailEpochDurationAsc = "VALIDATOR_JAIL_EPOCH_DURATION_ASC",
  ValidatorJailEpochDurationDesc = "VALIDATOR_JAIL_EPOCH_DURATION_DESC",
  ValidatorSetMaxNumValidatorsAsc = "VALIDATOR_SET_MAX_NUM_VALIDATORS_ASC",
  ValidatorSetMaxNumValidatorsDesc = "VALIDATOR_SET_MAX_NUM_VALIDATORS_DESC",
}

export type GlobalParamsEntry = Node & {
  __typename?: "GlobalParamsEntry";
  blockProductionIntervalMillisecondsPos: Scalars["BigInt"]["output"];
  blockTimestampDriftNanoSecs: Scalars["BigInt"]["output"];
  createNftFeeNanos: Scalars["BigInt"]["output"];
  createProfileFeeNanos: Scalars["BigInt"]["output"];
  epochDurationNumBlocks: Scalars["BigInt"]["output"];
  feeBucketGrowthRateBasisPoints: Scalars["BigInt"]["output"];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  id: Scalars["ID"]["output"];
  jailInactiveValidatorGracePeriodEpochs: Scalars["BigInt"]["output"];
  leaderScheduleMaxNumValidators: Scalars["BigInt"]["output"];
  maxBlockSizeBytesPos: Scalars["BigInt"]["output"];
  maxCopiesPerNft: Scalars["BigInt"]["output"];
  maxNonceExpirationBlockHeightOffset: Scalars["BigInt"]["output"];
  maxTxnSizeBytesPos: Scalars["BigInt"]["output"];
  maximumVestedIntersectionsPerLockupTransaction: Scalars["Int"]["output"];
  mempoolFeeEstimatorNumMempoolBlocks: Scalars["BigInt"]["output"];
  mempoolFeeEstimatorNumPastBlocks: Scalars["BigInt"]["output"];
  mempoolMaxSizeBytes: Scalars["BigInt"]["output"];
  minimumNetworkFeeNanosPerKb: Scalars["BigInt"]["output"];
  softMaxBlockSizeBytesPos: Scalars["BigInt"]["output"];
  stakeLockupEpochDuration: Scalars["BigInt"]["output"];
  stakingRewardsApyBasisPoints: Scalars["BigInt"]["output"];
  stakingRewardsMaxNumStakes: Scalars["BigInt"]["output"];
  timeoutIntervalMillisecondsPos: Scalars["BigInt"]["output"];
  usdCentsPerBitcoin: Scalars["BigInt"]["output"];
  validatorJailEpochDuration: Scalars["BigInt"]["output"];
  validatorSetMaxNumValidators: Scalars["BigInt"]["output"];
};

/**
 * A condition to be used against `GlobalParamsEntry` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type GlobalParamsEntryCondition = {
  /** Checks for equality with the object’s `blockProductionIntervalMillisecondsPos` field. */
  blockProductionIntervalMillisecondsPos?: InputMaybe<
    Scalars["BigInt"]["input"]
  >;
  /** Checks for equality with the object’s `blockTimestampDriftNanoSecs` field. */
  blockTimestampDriftNanoSecs?: InputMaybe<Scalars["BigInt"]["input"]>;
  /** Checks for equality with the object’s `createNftFeeNanos` field. */
  createNftFeeNanos?: InputMaybe<Scalars["BigInt"]["input"]>;
  /** Checks for equality with the object’s `createProfileFeeNanos` field. */
  createProfileFeeNanos?: InputMaybe<Scalars["BigInt"]["input"]>;
  /** Checks for equality with the object’s `epochDurationNumBlocks` field. */
  epochDurationNumBlocks?: InputMaybe<Scalars["BigInt"]["input"]>;
  /** Checks for equality with the object’s `feeBucketGrowthRateBasisPoints` field. */
  feeBucketGrowthRateBasisPoints?: InputMaybe<Scalars["BigInt"]["input"]>;
  /** Checks for equality with the object’s `jailInactiveValidatorGracePeriodEpochs` field. */
  jailInactiveValidatorGracePeriodEpochs?: InputMaybe<
    Scalars["BigInt"]["input"]
  >;
  /** Checks for equality with the object’s `leaderScheduleMaxNumValidators` field. */
  leaderScheduleMaxNumValidators?: InputMaybe<Scalars["BigInt"]["input"]>;
  /** Checks for equality with the object’s `maxBlockSizeBytesPos` field. */
  maxBlockSizeBytesPos?: InputMaybe<Scalars["BigInt"]["input"]>;
  /** Checks for equality with the object’s `maxCopiesPerNft` field. */
  maxCopiesPerNft?: InputMaybe<Scalars["BigInt"]["input"]>;
  /** Checks for equality with the object’s `maxNonceExpirationBlockHeightOffset` field. */
  maxNonceExpirationBlockHeightOffset?: InputMaybe<Scalars["BigInt"]["input"]>;
  /** Checks for equality with the object’s `maxTxnSizeBytesPos` field. */
  maxTxnSizeBytesPos?: InputMaybe<Scalars["BigInt"]["input"]>;
  /** Checks for equality with the object’s `maximumVestedIntersectionsPerLockupTransaction` field. */
  maximumVestedIntersectionsPerLockupTransaction?: InputMaybe<
    Scalars["Int"]["input"]
  >;
  /** Checks for equality with the object’s `mempoolFeeEstimatorNumMempoolBlocks` field. */
  mempoolFeeEstimatorNumMempoolBlocks?: InputMaybe<Scalars["BigInt"]["input"]>;
  /** Checks for equality with the object’s `mempoolFeeEstimatorNumPastBlocks` field. */
  mempoolFeeEstimatorNumPastBlocks?: InputMaybe<Scalars["BigInt"]["input"]>;
  /** Checks for equality with the object’s `mempoolMaxSizeBytes` field. */
  mempoolMaxSizeBytes?: InputMaybe<Scalars["BigInt"]["input"]>;
  /** Checks for equality with the object’s `minimumNetworkFeeNanosPerKb` field. */
  minimumNetworkFeeNanosPerKb?: InputMaybe<Scalars["BigInt"]["input"]>;
  /** Checks for equality with the object’s `softMaxBlockSizeBytesPos` field. */
  softMaxBlockSizeBytesPos?: InputMaybe<Scalars["BigInt"]["input"]>;
  /** Checks for equality with the object’s `stakeLockupEpochDuration` field. */
  stakeLockupEpochDuration?: InputMaybe<Scalars["BigInt"]["input"]>;
  /** Checks for equality with the object’s `stakingRewardsApyBasisPoints` field. */
  stakingRewardsApyBasisPoints?: InputMaybe<Scalars["BigInt"]["input"]>;
  /** Checks for equality with the object’s `stakingRewardsMaxNumStakes` field. */
  stakingRewardsMaxNumStakes?: InputMaybe<Scalars["BigInt"]["input"]>;
  /** Checks for equality with the object’s `timeoutIntervalMillisecondsPos` field. */
  timeoutIntervalMillisecondsPos?: InputMaybe<Scalars["BigInt"]["input"]>;
  /** Checks for equality with the object’s `usdCentsPerBitcoin` field. */
  usdCentsPerBitcoin?: InputMaybe<Scalars["BigInt"]["input"]>;
  /** Checks for equality with the object’s `validatorJailEpochDuration` field. */
  validatorJailEpochDuration?: InputMaybe<Scalars["BigInt"]["input"]>;
  /** Checks for equality with the object’s `validatorSetMaxNumValidators` field. */
  validatorSetMaxNumValidators?: InputMaybe<Scalars["BigInt"]["input"]>;
};

/** A filter to be used against `GlobalParamsEntry` object types. All fields are combined with a logical ‘and.’ */
export type GlobalParamsEntryFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<GlobalParamsEntryFilter>>;
  /** Filter by the object’s `blockProductionIntervalMillisecondsPos` field. */
  blockProductionIntervalMillisecondsPos?: InputMaybe<BigIntFilter>;
  /** Filter by the object’s `blockTimestampDriftNanoSecs` field. */
  blockTimestampDriftNanoSecs?: InputMaybe<BigIntFilter>;
  /** Filter by the object’s `createNftFeeNanos` field. */
  createNftFeeNanos?: InputMaybe<BigIntFilter>;
  /** Filter by the object’s `createProfileFeeNanos` field. */
  createProfileFeeNanos?: InputMaybe<BigIntFilter>;
  /** Filter by the object’s `epochDurationNumBlocks` field. */
  epochDurationNumBlocks?: InputMaybe<BigIntFilter>;
  /** Filter by the object’s `feeBucketGrowthRateBasisPoints` field. */
  feeBucketGrowthRateBasisPoints?: InputMaybe<BigIntFilter>;
  /** Filter by the object’s `jailInactiveValidatorGracePeriodEpochs` field. */
  jailInactiveValidatorGracePeriodEpochs?: InputMaybe<BigIntFilter>;
  /** Filter by the object’s `leaderScheduleMaxNumValidators` field. */
  leaderScheduleMaxNumValidators?: InputMaybe<BigIntFilter>;
  /** Filter by the object’s `maxBlockSizeBytesPos` field. */
  maxBlockSizeBytesPos?: InputMaybe<BigIntFilter>;
  /** Filter by the object’s `maxCopiesPerNft` field. */
  maxCopiesPerNft?: InputMaybe<BigIntFilter>;
  /** Filter by the object’s `maxNonceExpirationBlockHeightOffset` field. */
  maxNonceExpirationBlockHeightOffset?: InputMaybe<BigIntFilter>;
  /** Filter by the object’s `maxTxnSizeBytesPos` field. */
  maxTxnSizeBytesPos?: InputMaybe<BigIntFilter>;
  /** Filter by the object’s `maximumVestedIntersectionsPerLockupTransaction` field. */
  maximumVestedIntersectionsPerLockupTransaction?: InputMaybe<IntFilter>;
  /** Filter by the object’s `mempoolFeeEstimatorNumMempoolBlocks` field. */
  mempoolFeeEstimatorNumMempoolBlocks?: InputMaybe<BigIntFilter>;
  /** Filter by the object’s `mempoolFeeEstimatorNumPastBlocks` field. */
  mempoolFeeEstimatorNumPastBlocks?: InputMaybe<BigIntFilter>;
  /** Filter by the object’s `mempoolMaxSizeBytes` field. */
  mempoolMaxSizeBytes?: InputMaybe<BigIntFilter>;
  /** Filter by the object’s `minimumNetworkFeeNanosPerKb` field. */
  minimumNetworkFeeNanosPerKb?: InputMaybe<BigIntFilter>;
  /** Negates the expression. */
  not?: InputMaybe<GlobalParamsEntryFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<GlobalParamsEntryFilter>>;
  /** Filter by the object’s `softMaxBlockSizeBytesPos` field. */
  softMaxBlockSizeBytesPos?: InputMaybe<BigIntFilter>;
  /** Filter by the object’s `stakeLockupEpochDuration` field. */
  stakeLockupEpochDuration?: InputMaybe<BigIntFilter>;
  /** Filter by the object’s `stakingRewardsApyBasisPoints` field. */
  stakingRewardsApyBasisPoints?: InputMaybe<BigIntFilter>;
  /** Filter by the object’s `stakingRewardsMaxNumStakes` field. */
  stakingRewardsMaxNumStakes?: InputMaybe<BigIntFilter>;
  /** Filter by the object’s `timeoutIntervalMillisecondsPos` field. */
  timeoutIntervalMillisecondsPos?: InputMaybe<BigIntFilter>;
  /** Filter by the object’s `usdCentsPerBitcoin` field. */
  usdCentsPerBitcoin?: InputMaybe<BigIntFilter>;
  /** Filter by the object’s `validatorJailEpochDuration` field. */
  validatorJailEpochDuration?: InputMaybe<BigIntFilter>;
  /** Filter by the object’s `validatorSetMaxNumValidators` field. */
  validatorSetMaxNumValidators?: InputMaybe<BigIntFilter>;
};

/** A filter to be used against Int fields. All fields are combined with a logical ‘and.’ */
export type IntFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<Scalars["Int"]["input"]>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<Scalars["Int"]["input"]>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<Scalars["Int"]["input"]>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<Scalars["Int"]["input"]>;
  /** Included in the specified list. */
  in?: InputMaybe<Array<Scalars["Int"]["input"]>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<Scalars["Int"]["input"]>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<Scalars["Int"]["input"]>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<Scalars["Int"]["input"]>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<Scalars["Int"]["input"]>;
  /** Not included in the specified list. */
  notIn?: InputMaybe<Array<Scalars["Int"]["input"]>>;
};

/** A filter to be used against JSON fields. All fields are combined with a logical ‘and.’ */
export type JsonFilter = {
  /** Contained by the specified JSON. */
  containedBy?: InputMaybe<Scalars["JSON"]["input"]>;
  /** Contains the specified JSON. */
  contains?: InputMaybe<Scalars["JSON"]["input"]>;
  /** Contains all of the specified keys. */
  containsAllKeys?: InputMaybe<Array<Scalars["String"]["input"]>>;
  /** Contains any of the specified keys. */
  containsAnyKeys?: InputMaybe<Array<Scalars["String"]["input"]>>;
  /** Contains the specified key. */
  containsKey?: InputMaybe<Scalars["String"]["input"]>;
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<Scalars["JSON"]["input"]>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<Scalars["JSON"]["input"]>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<Scalars["JSON"]["input"]>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<Scalars["JSON"]["input"]>;
  /** Included in the specified list. */
  in?: InputMaybe<Array<Scalars["JSON"]["input"]>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<Scalars["JSON"]["input"]>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<Scalars["JSON"]["input"]>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<Scalars["JSON"]["input"]>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<Scalars["JSON"]["input"]>;
  /** Not included in the specified list. */
  notIn?: InputMaybe<Array<Scalars["JSON"]["input"]>>;
};

export type JailedHistoryEvent = Node & {
  __typename?: "JailedHistoryEvent";
  /** Reads a single `Account` that is related to this `JailedHistoryEvent`. */
  account?: Maybe<Account>;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  id: Scalars["ID"]["output"];
  jailedAtEpochNumber: Scalars["BigInt"]["output"];
  unjailedAtEpochNumber: Scalars["BigInt"]["output"];
  /** Reads a single `ValidatorEntry` that is related to this `JailedHistoryEvent`. */
  validatorEntry?: Maybe<ValidatorEntry>;
  validatorPkid: Scalars["String"]["output"];
};

/**
 * A condition to be used against `JailedHistoryEvent` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type JailedHistoryEventCondition = {
  /** Checks for equality with the object’s `jailedAtEpochNumber` field. */
  jailedAtEpochNumber?: InputMaybe<Scalars["BigInt"]["input"]>;
  /** Checks for equality with the object’s `unjailedAtEpochNumber` field. */
  unjailedAtEpochNumber?: InputMaybe<Scalars["BigInt"]["input"]>;
  /** Checks for equality with the object’s `validatorPkid` field. */
  validatorPkid?: InputMaybe<Scalars["String"]["input"]>;
};

/** A filter to be used against `JailedHistoryEvent` object types. All fields are combined with a logical ‘and.’ */
export type JailedHistoryEventFilter = {
  /** Filter by the object’s `account` relation. */
  account?: InputMaybe<AccountFilter>;
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<JailedHistoryEventFilter>>;
  /** Filter by the object’s `jailedAtEpochNumber` field. */
  jailedAtEpochNumber?: InputMaybe<BigIntFilter>;
  /** Negates the expression. */
  not?: InputMaybe<JailedHistoryEventFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<JailedHistoryEventFilter>>;
  /** Filter by the object’s `unjailedAtEpochNumber` field. */
  unjailedAtEpochNumber?: InputMaybe<BigIntFilter>;
  /** Filter by the object’s `validatorEntry` relation. */
  validatorEntry?: InputMaybe<ValidatorEntryFilter>;
  /** Filter by the object’s `validatorPkid` field. */
  validatorPkid?: InputMaybe<StringFilter>;
};

/** A connection to a list of `JailedHistoryEvent` values. */
export type JailedHistoryEventsConnection = {
  __typename?: "JailedHistoryEventsConnection";
  /** A list of edges which contains the `JailedHistoryEvent` and cursor to aid in pagination. */
  edges: Array<JailedHistoryEventsEdge>;
  /** A list of `JailedHistoryEvent` objects. */
  nodes: Array<Maybe<JailedHistoryEvent>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `JailedHistoryEvent` you could get from the connection. */
  totalCount: Scalars["Int"]["output"];
};

/** A `JailedHistoryEvent` edge in the connection. */
export type JailedHistoryEventsEdge = {
  __typename?: "JailedHistoryEventsEdge";
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars["Cursor"]["output"]>;
  /** The `JailedHistoryEvent` at the end of the edge. */
  node?: Maybe<JailedHistoryEvent>;
};

/** Methods to use when ordering `JailedHistoryEvent`. */
export enum JailedHistoryEventsOrderBy {
  JailedAtEpochNumberAsc = "JAILED_AT_EPOCH_NUMBER_ASC",
  JailedAtEpochNumberDesc = "JAILED_AT_EPOCH_NUMBER_DESC",
  Natural = "NATURAL",
  PrimaryKeyAsc = "PRIMARY_KEY_ASC",
  PrimaryKeyDesc = "PRIMARY_KEY_DESC",
  UnjailedAtEpochNumberAsc = "UNJAILED_AT_EPOCH_NUMBER_ASC",
  UnjailedAtEpochNumberDesc = "UNJAILED_AT_EPOCH_NUMBER_DESC",
  ValidatorPkidAsc = "VALIDATOR_PKID_ASC",
  ValidatorPkidDesc = "VALIDATOR_PKID_DESC",
}

/** A connection to a list of `LeaderScheduleEntry` values. */
export type LeaderScheduleEntriesConnection = {
  __typename?: "LeaderScheduleEntriesConnection";
  /** A list of edges which contains the `LeaderScheduleEntry` and cursor to aid in pagination. */
  edges: Array<LeaderScheduleEntriesEdge>;
  /** A list of `LeaderScheduleEntry` objects. */
  nodes: Array<Maybe<LeaderScheduleEntry>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `LeaderScheduleEntry` you could get from the connection. */
  totalCount: Scalars["Int"]["output"];
};

/** A `LeaderScheduleEntry` edge in the connection. */
export type LeaderScheduleEntriesEdge = {
  __typename?: "LeaderScheduleEntriesEdge";
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars["Cursor"]["output"]>;
  /** The `LeaderScheduleEntry` at the end of the edge. */
  node?: Maybe<LeaderScheduleEntry>;
};

/** Methods to use when ordering `LeaderScheduleEntry`. */
export enum LeaderScheduleEntriesOrderBy {
  LeaderIndexAsc = "LEADER_INDEX_ASC",
  LeaderIndexDesc = "LEADER_INDEX_DESC",
  Natural = "NATURAL",
  PrimaryKeyAsc = "PRIMARY_KEY_ASC",
  PrimaryKeyDesc = "PRIMARY_KEY_DESC",
  SnapshotAtEpochNumberAsc = "SNAPSHOT_AT_EPOCH_NUMBER_ASC",
  SnapshotAtEpochNumberDesc = "SNAPSHOT_AT_EPOCH_NUMBER_DESC",
  ValidatorPkidAsc = "VALIDATOR_PKID_ASC",
  ValidatorPkidDesc = "VALIDATOR_PKID_DESC",
}

export type LeaderScheduleEntry = Node & {
  __typename?: "LeaderScheduleEntry";
  /** Reads a single `EpochEntry` that is related to this `LeaderScheduleEntry`. */
  epochEntryBySnapshot?: Maybe<EpochEntry>;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  id: Scalars["ID"]["output"];
  /** Reads a single `Account` that is related to this `LeaderScheduleEntry`. */
  leaderAccount?: Maybe<Account>;
  leaderIndex: Scalars["Int"]["output"];
  snapshotAtEpochNumber: Scalars["BigInt"]["output"];
  /** Reads a single `ValidatorEntry` that is related to this `LeaderScheduleEntry`. */
  validatorEntry?: Maybe<ValidatorEntry>;
  validatorPkid: Scalars["String"]["output"];
};

/**
 * A condition to be used against `LeaderScheduleEntry` object types. All fields
 * are tested for equality and combined with a logical ‘and.’
 */
export type LeaderScheduleEntryCondition = {
  /** Checks for equality with the object’s `leaderIndex` field. */
  leaderIndex?: InputMaybe<Scalars["Int"]["input"]>;
  /** Checks for equality with the object’s `snapshotAtEpochNumber` field. */
  snapshotAtEpochNumber?: InputMaybe<Scalars["BigInt"]["input"]>;
  /** Checks for equality with the object’s `validatorPkid` field. */
  validatorPkid?: InputMaybe<Scalars["String"]["input"]>;
};

/** A filter to be used against `LeaderScheduleEntry` object types. All fields are combined with a logical ‘and.’ */
export type LeaderScheduleEntryFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<LeaderScheduleEntryFilter>>;
  /** Filter by the object’s `epochEntryBySnapshot` relation. */
  epochEntryBySnapshot?: InputMaybe<EpochEntryFilter>;
  /** Filter by the object’s `leaderAccount` relation. */
  leaderAccount?: InputMaybe<AccountFilter>;
  /** Filter by the object’s `leaderIndex` field. */
  leaderIndex?: InputMaybe<IntFilter>;
  /** Negates the expression. */
  not?: InputMaybe<LeaderScheduleEntryFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<LeaderScheduleEntryFilter>>;
  /** Filter by the object’s `snapshotAtEpochNumber` field. */
  snapshotAtEpochNumber?: InputMaybe<BigIntFilter>;
  /** Filter by the object’s `validatorEntry` relation. */
  validatorEntry?: InputMaybe<ValidatorEntryFilter>;
  /** Filter by the object’s `validatorPkid` field. */
  validatorPkid?: InputMaybe<StringFilter>;
};

export type LegacyMessage = Node & {
  __typename?: "LegacyMessage";
  encryptedText: Scalars["String"]["output"];
  extraData?: Maybe<Scalars["JSON"]["output"]>;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  id: Scalars["ID"]["output"];
  /** Reads a single `Account` that is related to this `LegacyMessage`. */
  receiver?: Maybe<Account>;
  recipientMessagingGroupKeyName?: Maybe<Scalars["String"]["output"]>;
  recipientMessagingPublicKey?: Maybe<Scalars["String"]["output"]>;
  recipientPublicKey: Scalars["String"]["output"];
  /** Reads a single `Account` that is related to this `LegacyMessage`. */
  sender?: Maybe<Account>;
  senderMessagingGroupKeyName?: Maybe<Scalars["String"]["output"]>;
  senderMessagingPublicKey?: Maybe<Scalars["String"]["output"]>;
  senderPublicKey: Scalars["String"]["output"];
  timestamp?: Maybe<Scalars["Datetime"]["output"]>;
  version: Scalars["Int"]["output"];
};

/**
 * A condition to be used against `LegacyMessage` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type LegacyMessageCondition = {
  /** Checks for equality with the object’s `encryptedText` field. */
  encryptedText?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `extraData` field. */
  extraData?: InputMaybe<Scalars["JSON"]["input"]>;
  /** Checks for equality with the object’s `recipientMessagingGroupKeyName` field. */
  recipientMessagingGroupKeyName?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `recipientMessagingPublicKey` field. */
  recipientMessagingPublicKey?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `recipientPublicKey` field. */
  recipientPublicKey?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `senderMessagingGroupKeyName` field. */
  senderMessagingGroupKeyName?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `senderMessagingPublicKey` field. */
  senderMessagingPublicKey?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `senderPublicKey` field. */
  senderPublicKey?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `timestamp` field. */
  timestamp?: InputMaybe<Scalars["Datetime"]["input"]>;
  /** Checks for equality with the object’s `version` field. */
  version?: InputMaybe<Scalars["Int"]["input"]>;
};

/** A filter to be used against `LegacyMessage` object types. All fields are combined with a logical ‘and.’ */
export type LegacyMessageFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<LegacyMessageFilter>>;
  /** Filter by the object’s `encryptedText` field. */
  encryptedText?: InputMaybe<StringFilter>;
  /** Filter by the object’s `extraData` field. */
  extraData?: InputMaybe<JsonFilter>;
  /** Negates the expression. */
  not?: InputMaybe<LegacyMessageFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<LegacyMessageFilter>>;
  /** Filter by the object’s `receiver` relation. */
  receiver?: InputMaybe<AccountFilter>;
  /** Filter by the object’s `recipientMessagingGroupKeyName` field. */
  recipientMessagingGroupKeyName?: InputMaybe<StringFilter>;
  /** Filter by the object’s `recipientMessagingPublicKey` field. */
  recipientMessagingPublicKey?: InputMaybe<StringFilter>;
  /** Filter by the object’s `recipientPublicKey` field. */
  recipientPublicKey?: InputMaybe<StringFilter>;
  /** Filter by the object’s `sender` relation. */
  sender?: InputMaybe<AccountFilter>;
  /** Filter by the object’s `senderMessagingGroupKeyName` field. */
  senderMessagingGroupKeyName?: InputMaybe<StringFilter>;
  /** Filter by the object’s `senderMessagingPublicKey` field. */
  senderMessagingPublicKey?: InputMaybe<StringFilter>;
  /** Filter by the object’s `senderPublicKey` field. */
  senderPublicKey?: InputMaybe<StringFilter>;
  /** Filter by the object’s `timestamp` field. */
  timestamp?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `version` field. */
  version?: InputMaybe<IntFilter>;
};

/** A connection to a list of `LegacyMessage` values. */
export type LegacyMessagesConnection = {
  __typename?: "LegacyMessagesConnection";
  /** A list of edges which contains the `LegacyMessage` and cursor to aid in pagination. */
  edges: Array<LegacyMessagesEdge>;
  /** A list of `LegacyMessage` objects. */
  nodes: Array<Maybe<LegacyMessage>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `LegacyMessage` you could get from the connection. */
  totalCount: Scalars["Int"]["output"];
};

/** A `LegacyMessage` edge in the connection. */
export type LegacyMessagesEdge = {
  __typename?: "LegacyMessagesEdge";
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars["Cursor"]["output"]>;
  /** The `LegacyMessage` at the end of the edge. */
  node?: Maybe<LegacyMessage>;
};

/** Methods to use when ordering `LegacyMessage`. */
export enum LegacyMessagesOrderBy {
  EncryptedTextAsc = "ENCRYPTED_TEXT_ASC",
  EncryptedTextDesc = "ENCRYPTED_TEXT_DESC",
  ExtraDataAsc = "EXTRA_DATA_ASC",
  ExtraDataDesc = "EXTRA_DATA_DESC",
  Natural = "NATURAL",
  PrimaryKeyAsc = "PRIMARY_KEY_ASC",
  PrimaryKeyDesc = "PRIMARY_KEY_DESC",
  RecipientMessagingGroupKeyNameAsc = "RECIPIENT_MESSAGING_GROUP_KEY_NAME_ASC",
  RecipientMessagingGroupKeyNameDesc = "RECIPIENT_MESSAGING_GROUP_KEY_NAME_DESC",
  RecipientMessagingPublicKeyAsc = "RECIPIENT_MESSAGING_PUBLIC_KEY_ASC",
  RecipientMessagingPublicKeyDesc = "RECIPIENT_MESSAGING_PUBLIC_KEY_DESC",
  RecipientPublicKeyAsc = "RECIPIENT_PUBLIC_KEY_ASC",
  RecipientPublicKeyDesc = "RECIPIENT_PUBLIC_KEY_DESC",
  SenderMessagingGroupKeyNameAsc = "SENDER_MESSAGING_GROUP_KEY_NAME_ASC",
  SenderMessagingGroupKeyNameDesc = "SENDER_MESSAGING_GROUP_KEY_NAME_DESC",
  SenderMessagingPublicKeyAsc = "SENDER_MESSAGING_PUBLIC_KEY_ASC",
  SenderMessagingPublicKeyDesc = "SENDER_MESSAGING_PUBLIC_KEY_DESC",
  SenderPublicKeyAsc = "SENDER_PUBLIC_KEY_ASC",
  SenderPublicKeyDesc = "SENDER_PUBLIC_KEY_DESC",
  TimestampAsc = "TIMESTAMP_ASC",
  TimestampDesc = "TIMESTAMP_DESC",
  VersionAsc = "VERSION_ASC",
  VersionDesc = "VERSION_DESC",
}

export type Like = Node & {
  __typename?: "Like";
  /** Reads a single `Account` that is related to this `Like`. */
  account?: Maybe<Account>;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  id: Scalars["ID"]["output"];
  /** Reads a single `Post` that is related to this `Like`. */
  post?: Maybe<Post>;
  postHash: Scalars["String"]["output"];
  publicKey: Scalars["String"]["output"];
};

/** A condition to be used against `Like` object types. All fields are tested for equality and combined with a logical ‘and.’ */
export type LikeCondition = {
  /** Checks for equality with the object’s `postHash` field. */
  postHash?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `publicKey` field. */
  publicKey?: InputMaybe<Scalars["String"]["input"]>;
};

/** A filter to be used against `Like` object types. All fields are combined with a logical ‘and.’ */
export type LikeFilter = {
  /** Filter by the object’s `account` relation. */
  account?: InputMaybe<AccountFilter>;
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<LikeFilter>>;
  /** Negates the expression. */
  not?: InputMaybe<LikeFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<LikeFilter>>;
  /** Filter by the object’s `post` relation. */
  post?: InputMaybe<PostFilter>;
  /** Filter by the object’s `postHash` field. */
  postHash?: InputMaybe<StringFilter>;
  /** Filter by the object’s `publicKey` field. */
  publicKey?: InputMaybe<StringFilter>;
};

/** A connection to a list of `Like` values. */
export type LikesConnection = {
  __typename?: "LikesConnection";
  /** A list of edges which contains the `Like` and cursor to aid in pagination. */
  edges: Array<LikesEdge>;
  /** A list of `Like` objects. */
  nodes: Array<Maybe<Like>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Like` you could get from the connection. */
  totalCount: Scalars["Int"]["output"];
};

/** A `Like` edge in the connection. */
export type LikesEdge = {
  __typename?: "LikesEdge";
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars["Cursor"]["output"]>;
  /** The `Like` at the end of the edge. */
  node?: Maybe<Like>;
};

/** Methods to use when ordering `Like`. */
export enum LikesOrderBy {
  Natural = "NATURAL",
  PostHashAsc = "POST_HASH_ASC",
  PostHashDesc = "POST_HASH_DESC",
  PrimaryKeyAsc = "PRIMARY_KEY_ASC",
  PrimaryKeyDesc = "PRIMARY_KEY_DESC",
  PublicKeyAsc = "PUBLIC_KEY_ASC",
  PublicKeyDesc = "PUBLIC_KEY_DESC",
}

/** A connection to a list of `LockedBalanceEntry` values. */
export type LockedBalanceEntriesConnection = {
  __typename?: "LockedBalanceEntriesConnection";
  /** A list of edges which contains the `LockedBalanceEntry` and cursor to aid in pagination. */
  edges: Array<LockedBalanceEntriesEdge>;
  /** A list of `LockedBalanceEntry` objects. */
  nodes: Array<Maybe<LockedBalanceEntry>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `LockedBalanceEntry` you could get from the connection. */
  totalCount: Scalars["Int"]["output"];
};

/** A `LockedBalanceEntry` edge in the connection. */
export type LockedBalanceEntriesEdge = {
  __typename?: "LockedBalanceEntriesEdge";
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars["Cursor"]["output"]>;
  /** The `LockedBalanceEntry` at the end of the edge. */
  node?: Maybe<LockedBalanceEntry>;
};

/** Methods to use when ordering `LockedBalanceEntry`. */
export enum LockedBalanceEntriesOrderBy {
  BalanceBaseUnitsAsc = "BALANCE_BASE_UNITS_ASC",
  BalanceBaseUnitsDesc = "BALANCE_BASE_UNITS_DESC",
  HodlerPkidAsc = "HODLER_PKID_ASC",
  HodlerPkidDesc = "HODLER_PKID_DESC",
  Natural = "NATURAL",
  PrimaryKeyAsc = "PRIMARY_KEY_ASC",
  PrimaryKeyDesc = "PRIMARY_KEY_DESC",
  ProfilePkidAsc = "PROFILE_PKID_ASC",
  ProfilePkidDesc = "PROFILE_PKID_DESC",
  UnlockTimestampNanoSecsAsc = "UNLOCK_TIMESTAMP_NANO_SECS_ASC",
  UnlockTimestampNanoSecsDesc = "UNLOCK_TIMESTAMP_NANO_SECS_DESC",
  VestingEndTimestampNanoSecsAsc = "VESTING_END_TIMESTAMP_NANO_SECS_ASC",
  VestingEndTimestampNanoSecsDesc = "VESTING_END_TIMESTAMP_NANO_SECS_DESC",
}

export type LockedBalanceEntry = Node & {
  __typename?: "LockedBalanceEntry";
  balanceBaseUnits: Scalars["BigFloat"]["output"];
  /** Reads a single `Account` that is related to this `LockedBalanceEntry`. */
  hodlerAccount?: Maybe<Account>;
  hodlerPkid: Scalars["String"]["output"];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  id: Scalars["ID"]["output"];
  /** Reads a single `Account` that is related to this `LockedBalanceEntry`. */
  profileAccount?: Maybe<Account>;
  profilePkid: Scalars["String"]["output"];
  unlockTimestampNanoSecs: Scalars["BigInt"]["output"];
  vestingEndTimestampNanoSecs: Scalars["BigInt"]["output"];
};

/**
 * A condition to be used against `LockedBalanceEntry` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type LockedBalanceEntryCondition = {
  /** Checks for equality with the object’s `balanceBaseUnits` field. */
  balanceBaseUnits?: InputMaybe<Scalars["BigFloat"]["input"]>;
  /** Checks for equality with the object’s `hodlerPkid` field. */
  hodlerPkid?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `profilePkid` field. */
  profilePkid?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `unlockTimestampNanoSecs` field. */
  unlockTimestampNanoSecs?: InputMaybe<Scalars["BigInt"]["input"]>;
  /** Checks for equality with the object’s `vestingEndTimestampNanoSecs` field. */
  vestingEndTimestampNanoSecs?: InputMaybe<Scalars["BigInt"]["input"]>;
};

/** A filter to be used against `LockedBalanceEntry` object types. All fields are combined with a logical ‘and.’ */
export type LockedBalanceEntryFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<LockedBalanceEntryFilter>>;
  /** Filter by the object’s `balanceBaseUnits` field. */
  balanceBaseUnits?: InputMaybe<BigFloatFilter>;
  /** Filter by the object’s `hodlerAccount` relation. */
  hodlerAccount?: InputMaybe<AccountFilter>;
  /** Filter by the object’s `hodlerPkid` field. */
  hodlerPkid?: InputMaybe<StringFilter>;
  /** Negates the expression. */
  not?: InputMaybe<LockedBalanceEntryFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<LockedBalanceEntryFilter>>;
  /** Filter by the object’s `profileAccount` relation. */
  profileAccount?: InputMaybe<AccountFilter>;
  /** Filter by the object’s `profilePkid` field. */
  profilePkid?: InputMaybe<StringFilter>;
  /** Filter by the object’s `unlockTimestampNanoSecs` field. */
  unlockTimestampNanoSecs?: InputMaybe<BigIntFilter>;
  /** Filter by the object’s `vestingEndTimestampNanoSecs` field. */
  vestingEndTimestampNanoSecs?: InputMaybe<BigIntFilter>;
};

/** A connection to a list of `LockedStakeEntry` values. */
export type LockedStakeEntriesConnection = {
  __typename?: "LockedStakeEntriesConnection";
  /** A list of edges which contains the `LockedStakeEntry` and cursor to aid in pagination. */
  edges: Array<LockedStakeEntriesEdge>;
  /** A list of `LockedStakeEntry` objects. */
  nodes: Array<Maybe<LockedStakeEntry>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `LockedStakeEntry` you could get from the connection. */
  totalCount: Scalars["Int"]["output"];
};

/** A `LockedStakeEntry` edge in the connection. */
export type LockedStakeEntriesEdge = {
  __typename?: "LockedStakeEntriesEdge";
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars["Cursor"]["output"]>;
  /** The `LockedStakeEntry` at the end of the edge. */
  node?: Maybe<LockedStakeEntry>;
};

/** Methods to use when ordering `LockedStakeEntry`. */
export enum LockedStakeEntriesOrderBy {
  ExtraDataAsc = "EXTRA_DATA_ASC",
  ExtraDataDesc = "EXTRA_DATA_DESC",
  LockedAmountNanosAsc = "LOCKED_AMOUNT_NANOS_ASC",
  LockedAmountNanosDesc = "LOCKED_AMOUNT_NANOS_DESC",
  LockedAtEpochNumberAsc = "LOCKED_AT_EPOCH_NUMBER_ASC",
  LockedAtEpochNumberDesc = "LOCKED_AT_EPOCH_NUMBER_DESC",
  Natural = "NATURAL",
  PrimaryKeyAsc = "PRIMARY_KEY_ASC",
  PrimaryKeyDesc = "PRIMARY_KEY_DESC",
  StakerPkidAsc = "STAKER_PKID_ASC",
  StakerPkidDesc = "STAKER_PKID_DESC",
  ValidatorPkidAsc = "VALIDATOR_PKID_ASC",
  ValidatorPkidDesc = "VALIDATOR_PKID_DESC",
}

export type LockedStakeEntry = Node & {
  __typename?: "LockedStakeEntry";
  extraData?: Maybe<Scalars["JSON"]["output"]>;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  id: Scalars["ID"]["output"];
  lockedAmountNanos: Scalars["BigFloat"]["output"];
  lockedAtEpochNumber: Scalars["BigInt"]["output"];
  /** Reads a single `Account` that is related to this `LockedStakeEntry`. */
  staker?: Maybe<Account>;
  stakerPkid: Scalars["String"]["output"];
  /** Reads a single `Account` that is related to this `LockedStakeEntry`. */
  validatorAccount?: Maybe<Account>;
  /** Reads a single `ValidatorEntry` that is related to this `LockedStakeEntry`. */
  validatorEntry?: Maybe<ValidatorEntry>;
  validatorPkid: Scalars["String"]["output"];
};

/**
 * A condition to be used against `LockedStakeEntry` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type LockedStakeEntryCondition = {
  /** Checks for equality with the object’s `extraData` field. */
  extraData?: InputMaybe<Scalars["JSON"]["input"]>;
  /** Checks for equality with the object’s `lockedAmountNanos` field. */
  lockedAmountNanos?: InputMaybe<Scalars["BigFloat"]["input"]>;
  /** Checks for equality with the object’s `lockedAtEpochNumber` field. */
  lockedAtEpochNumber?: InputMaybe<Scalars["BigInt"]["input"]>;
  /** Checks for equality with the object’s `stakerPkid` field. */
  stakerPkid?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `validatorPkid` field. */
  validatorPkid?: InputMaybe<Scalars["String"]["input"]>;
};

/** A filter to be used against `LockedStakeEntry` object types. All fields are combined with a logical ‘and.’ */
export type LockedStakeEntryFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<LockedStakeEntryFilter>>;
  /** Filter by the object’s `extraData` field. */
  extraData?: InputMaybe<JsonFilter>;
  /** Filter by the object’s `lockedAmountNanos` field. */
  lockedAmountNanos?: InputMaybe<BigFloatFilter>;
  /** Filter by the object’s `lockedAtEpochNumber` field. */
  lockedAtEpochNumber?: InputMaybe<BigIntFilter>;
  /** Negates the expression. */
  not?: InputMaybe<LockedStakeEntryFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<LockedStakeEntryFilter>>;
  /** Filter by the object’s `staker` relation. */
  staker?: InputMaybe<AccountFilter>;
  /** Filter by the object’s `stakerPkid` field. */
  stakerPkid?: InputMaybe<StringFilter>;
  /** Filter by the object’s `validatorAccount` relation. */
  validatorAccount?: InputMaybe<AccountFilter>;
  /** Filter by the object’s `validatorEntry` relation. */
  validatorEntry?: InputMaybe<ValidatorEntryFilter>;
  /** Filter by the object’s `validatorPkid` field. */
  validatorPkid?: InputMaybe<StringFilter>;
};

export type Message = Node & {
  __typename?: "Message";
  encryptedText: Scalars["String"]["output"];
  extraData?: Maybe<Scalars["JSON"]["output"]>;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  id: Scalars["ID"]["output"];
  isGroupChatMessage: Scalars["Boolean"]["output"];
  /** Reads a single `Account` that is related to this `Message`. */
  receiver?: Maybe<Account>;
  /** Reads a single `AccessGroup` that is related to this `Message`. */
  receiverAccessGroup?: Maybe<AccessGroup>;
  recipientAccessGroupKeyName?: Maybe<Scalars["String"]["output"]>;
  recipientAccessGroupOwnerPublicKey?: Maybe<Scalars["String"]["output"]>;
  recipientAccessGroupPublicKey?: Maybe<Scalars["String"]["output"]>;
  /** Reads a single `Account` that is related to this `Message`. */
  sender?: Maybe<Account>;
  /** Reads a single `AccessGroup` that is related to this `Message`. */
  senderAccessGroup?: Maybe<AccessGroup>;
  senderAccessGroupKeyName?: Maybe<Scalars["String"]["output"]>;
  senderAccessGroupOwnerPublicKey?: Maybe<Scalars["String"]["output"]>;
  senderAccessGroupPublicKey?: Maybe<Scalars["String"]["output"]>;
  timestamp: Scalars["Datetime"]["output"];
};

/** A condition to be used against `Message` object types. All fields are tested for equality and combined with a logical ‘and.’ */
export type MessageCondition = {
  /** Checks for equality with the object’s `encryptedText` field. */
  encryptedText?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `extraData` field. */
  extraData?: InputMaybe<Scalars["JSON"]["input"]>;
  /** Checks for equality with the object’s `isGroupChatMessage` field. */
  isGroupChatMessage?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Checks for equality with the object’s `recipientAccessGroupKeyName` field. */
  recipientAccessGroupKeyName?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `recipientAccessGroupOwnerPublicKey` field. */
  recipientAccessGroupOwnerPublicKey?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `recipientAccessGroupPublicKey` field. */
  recipientAccessGroupPublicKey?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `senderAccessGroupKeyName` field. */
  senderAccessGroupKeyName?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `senderAccessGroupOwnerPublicKey` field. */
  senderAccessGroupOwnerPublicKey?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `senderAccessGroupPublicKey` field. */
  senderAccessGroupPublicKey?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `timestamp` field. */
  timestamp?: InputMaybe<Scalars["Datetime"]["input"]>;
};

/** A filter to be used against `Message` object types. All fields are combined with a logical ‘and.’ */
export type MessageFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<MessageFilter>>;
  /** Filter by the object’s `encryptedText` field. */
  encryptedText?: InputMaybe<StringFilter>;
  /** Filter by the object’s `extraData` field. */
  extraData?: InputMaybe<JsonFilter>;
  /** Filter by the object’s `isGroupChatMessage` field. */
  isGroupChatMessage?: InputMaybe<BooleanFilter>;
  /** Negates the expression. */
  not?: InputMaybe<MessageFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<MessageFilter>>;
  /** Filter by the object’s `receiver` relation. */
  receiver?: InputMaybe<AccountFilter>;
  /** Filter by the object’s `receiverAccessGroup` relation. */
  receiverAccessGroup?: InputMaybe<AccessGroupFilter>;
  /** A related `receiverAccessGroup` exists. */
  receiverAccessGroupExists?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** A related `receiver` exists. */
  receiverExists?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Filter by the object’s `recipientAccessGroupKeyName` field. */
  recipientAccessGroupKeyName?: InputMaybe<StringFilter>;
  /** Filter by the object’s `recipientAccessGroupOwnerPublicKey` field. */
  recipientAccessGroupOwnerPublicKey?: InputMaybe<StringFilter>;
  /** Filter by the object’s `recipientAccessGroupPublicKey` field. */
  recipientAccessGroupPublicKey?: InputMaybe<StringFilter>;
  /** Filter by the object’s `sender` relation. */
  sender?: InputMaybe<AccountFilter>;
  /** Filter by the object’s `senderAccessGroup` relation. */
  senderAccessGroup?: InputMaybe<AccessGroupFilter>;
  /** A related `senderAccessGroup` exists. */
  senderAccessGroupExists?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Filter by the object’s `senderAccessGroupKeyName` field. */
  senderAccessGroupKeyName?: InputMaybe<StringFilter>;
  /** Filter by the object’s `senderAccessGroupOwnerPublicKey` field. */
  senderAccessGroupOwnerPublicKey?: InputMaybe<StringFilter>;
  /** Filter by the object’s `senderAccessGroupPublicKey` field. */
  senderAccessGroupPublicKey?: InputMaybe<StringFilter>;
  /** A related `sender` exists. */
  senderExists?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Filter by the object’s `timestamp` field. */
  timestamp?: InputMaybe<DatetimeFilter>;
};

/** A connection to a list of `Message` values. */
export type MessagesConnection = {
  __typename?: "MessagesConnection";
  /** A list of edges which contains the `Message` and cursor to aid in pagination. */
  edges: Array<MessagesEdge>;
  /** A list of `Message` objects. */
  nodes: Array<Maybe<Message>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Message` you could get from the connection. */
  totalCount: Scalars["Int"]["output"];
};

/** A `Message` edge in the connection. */
export type MessagesEdge = {
  __typename?: "MessagesEdge";
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars["Cursor"]["output"]>;
  /** The `Message` at the end of the edge. */
  node?: Maybe<Message>;
};

/** Methods to use when ordering `Message`. */
export enum MessagesOrderBy {
  EncryptedTextAsc = "ENCRYPTED_TEXT_ASC",
  EncryptedTextDesc = "ENCRYPTED_TEXT_DESC",
  ExtraDataAsc = "EXTRA_DATA_ASC",
  ExtraDataDesc = "EXTRA_DATA_DESC",
  IsGroupChatMessageAsc = "IS_GROUP_CHAT_MESSAGE_ASC",
  IsGroupChatMessageDesc = "IS_GROUP_CHAT_MESSAGE_DESC",
  Natural = "NATURAL",
  PrimaryKeyAsc = "PRIMARY_KEY_ASC",
  PrimaryKeyDesc = "PRIMARY_KEY_DESC",
  RecipientAccessGroupKeyNameAsc = "RECIPIENT_ACCESS_GROUP_KEY_NAME_ASC",
  RecipientAccessGroupKeyNameDesc = "RECIPIENT_ACCESS_GROUP_KEY_NAME_DESC",
  RecipientAccessGroupOwnerPublicKeyAsc = "RECIPIENT_ACCESS_GROUP_OWNER_PUBLIC_KEY_ASC",
  RecipientAccessGroupOwnerPublicKeyDesc = "RECIPIENT_ACCESS_GROUP_OWNER_PUBLIC_KEY_DESC",
  RecipientAccessGroupPublicKeyAsc = "RECIPIENT_ACCESS_GROUP_PUBLIC_KEY_ASC",
  RecipientAccessGroupPublicKeyDesc = "RECIPIENT_ACCESS_GROUP_PUBLIC_KEY_DESC",
  SenderAccessGroupKeyNameAsc = "SENDER_ACCESS_GROUP_KEY_NAME_ASC",
  SenderAccessGroupKeyNameDesc = "SENDER_ACCESS_GROUP_KEY_NAME_DESC",
  SenderAccessGroupOwnerPublicKeyAsc = "SENDER_ACCESS_GROUP_OWNER_PUBLIC_KEY_ASC",
  SenderAccessGroupOwnerPublicKeyDesc = "SENDER_ACCESS_GROUP_OWNER_PUBLIC_KEY_DESC",
  SenderAccessGroupPublicKeyAsc = "SENDER_ACCESS_GROUP_PUBLIC_KEY_ASC",
  SenderAccessGroupPublicKeyDesc = "SENDER_ACCESS_GROUP_PUBLIC_KEY_DESC",
  TimestampAsc = "TIMESTAMP_ASC",
  TimestampDesc = "TIMESTAMP_DESC",
}

export type MonthlyNewWalletCountStat = {
  __typename?: "MonthlyNewWalletCountStat";
  month?: Maybe<Scalars["Datetime"]["output"]>;
  rowId?: Maybe<Scalars["BigInt"]["output"]>;
  walletCount?: Maybe<Scalars["BigInt"]["output"]>;
};

/**
 * A condition to be used against `MonthlyNewWalletCountStat` object types. All
 * fields are tested for equality and combined with a logical ‘and.’
 */
export type MonthlyNewWalletCountStatCondition = {
  /** Checks for equality with the object’s `month` field. */
  month?: InputMaybe<Scalars["Datetime"]["input"]>;
  /** Checks for equality with the object’s `rowId` field. */
  rowId?: InputMaybe<Scalars["BigInt"]["input"]>;
  /** Checks for equality with the object’s `walletCount` field. */
  walletCount?: InputMaybe<Scalars["BigInt"]["input"]>;
};

/** A filter to be used against `MonthlyNewWalletCountStat` object types. All fields are combined with a logical ‘and.’ */
export type MonthlyNewWalletCountStatFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<MonthlyNewWalletCountStatFilter>>;
  /** Filter by the object’s `month` field. */
  month?: InputMaybe<DatetimeFilter>;
  /** Negates the expression. */
  not?: InputMaybe<MonthlyNewWalletCountStatFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<MonthlyNewWalletCountStatFilter>>;
  /** Filter by the object’s `rowId` field. */
  rowId?: InputMaybe<BigIntFilter>;
  /** Filter by the object’s `walletCount` field. */
  walletCount?: InputMaybe<BigIntFilter>;
};

/** A connection to a list of `MonthlyNewWalletCountStat` values. */
export type MonthlyNewWalletCountStatsConnection = {
  __typename?: "MonthlyNewWalletCountStatsConnection";
  /** A list of edges which contains the `MonthlyNewWalletCountStat` and cursor to aid in pagination. */
  edges: Array<MonthlyNewWalletCountStatsEdge>;
  /** A list of `MonthlyNewWalletCountStat` objects. */
  nodes: Array<Maybe<MonthlyNewWalletCountStat>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `MonthlyNewWalletCountStat` you could get from the connection. */
  totalCount: Scalars["Int"]["output"];
};

/** A `MonthlyNewWalletCountStat` edge in the connection. */
export type MonthlyNewWalletCountStatsEdge = {
  __typename?: "MonthlyNewWalletCountStatsEdge";
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars["Cursor"]["output"]>;
  /** The `MonthlyNewWalletCountStat` at the end of the edge. */
  node?: Maybe<MonthlyNewWalletCountStat>;
};

/** Methods to use when ordering `MonthlyNewWalletCountStat`. */
export enum MonthlyNewWalletCountStatsOrderBy {
  IdAsc = "ID_ASC",
  IdDesc = "ID_DESC",
  MonthAsc = "MONTH_ASC",
  MonthDesc = "MONTH_DESC",
  Natural = "NATURAL",
  WalletCountAsc = "WALLET_COUNT_ASC",
  WalletCountDesc = "WALLET_COUNT_DESC",
}

export type MonthlyTxnCountStat = {
  __typename?: "MonthlyTxnCountStat";
  month?: Maybe<Scalars["Datetime"]["output"]>;
  rowId?: Maybe<Scalars["BigInt"]["output"]>;
  transactionCount?: Maybe<Scalars["BigInt"]["output"]>;
};

/**
 * A condition to be used against `MonthlyTxnCountStat` object types. All fields
 * are tested for equality and combined with a logical ‘and.’
 */
export type MonthlyTxnCountStatCondition = {
  /** Checks for equality with the object’s `month` field. */
  month?: InputMaybe<Scalars["Datetime"]["input"]>;
  /** Checks for equality with the object’s `rowId` field. */
  rowId?: InputMaybe<Scalars["BigInt"]["input"]>;
  /** Checks for equality with the object’s `transactionCount` field. */
  transactionCount?: InputMaybe<Scalars["BigInt"]["input"]>;
};

/** A filter to be used against `MonthlyTxnCountStat` object types. All fields are combined with a logical ‘and.’ */
export type MonthlyTxnCountStatFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<MonthlyTxnCountStatFilter>>;
  /** Filter by the object’s `month` field. */
  month?: InputMaybe<DatetimeFilter>;
  /** Negates the expression. */
  not?: InputMaybe<MonthlyTxnCountStatFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<MonthlyTxnCountStatFilter>>;
  /** Filter by the object’s `rowId` field. */
  rowId?: InputMaybe<BigIntFilter>;
  /** Filter by the object’s `transactionCount` field. */
  transactionCount?: InputMaybe<BigIntFilter>;
};

/** A connection to a list of `MonthlyTxnCountStat` values. */
export type MonthlyTxnCountStatsConnection = {
  __typename?: "MonthlyTxnCountStatsConnection";
  /** A list of edges which contains the `MonthlyTxnCountStat` and cursor to aid in pagination. */
  edges: Array<MonthlyTxnCountStatsEdge>;
  /** A list of `MonthlyTxnCountStat` objects. */
  nodes: Array<Maybe<MonthlyTxnCountStat>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `MonthlyTxnCountStat` you could get from the connection. */
  totalCount: Scalars["Int"]["output"];
};

/** A `MonthlyTxnCountStat` edge in the connection. */
export type MonthlyTxnCountStatsEdge = {
  __typename?: "MonthlyTxnCountStatsEdge";
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars["Cursor"]["output"]>;
  /** The `MonthlyTxnCountStat` at the end of the edge. */
  node?: Maybe<MonthlyTxnCountStat>;
};

/** Methods to use when ordering `MonthlyTxnCountStat`. */
export enum MonthlyTxnCountStatsOrderBy {
  IdAsc = "ID_ASC",
  IdDesc = "ID_DESC",
  MonthAsc = "MONTH_ASC",
  MonthDesc = "MONTH_DESC",
  Natural = "NATURAL",
  TransactionCountAsc = "TRANSACTION_COUNT_ASC",
  TransactionCountDesc = "TRANSACTION_COUNT_DESC",
}

/** A connection to a list of `MyStakeSummary` values. */
export type MyStakeSummariesConnection = {
  __typename?: "MyStakeSummariesConnection";
  /** A list of edges which contains the `MyStakeSummary` and cursor to aid in pagination. */
  edges: Array<MyStakeSummariesEdge>;
  /** A list of `MyStakeSummary` objects. */
  nodes: Array<Maybe<MyStakeSummary>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `MyStakeSummary` you could get from the connection. */
  totalCount: Scalars["Int"]["output"];
};

/** A `MyStakeSummary` edge in the connection. */
export type MyStakeSummariesEdge = {
  __typename?: "MyStakeSummariesEdge";
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars["Cursor"]["output"]>;
  /** The `MyStakeSummary` at the end of the edge. */
  node?: Maybe<MyStakeSummary>;
};

/** Methods to use when ordering `MyStakeSummary`. */
export enum MyStakeSummariesOrderBy {
  Natural = "NATURAL",
  StakerPkidAsc = "STAKER_PKID_ASC",
  StakerPkidDesc = "STAKER_PKID_DESC",
  TotalStakeAsc = "TOTAL_STAKE_ASC",
  TotalStakeDesc = "TOTAL_STAKE_DESC",
  TotalStakeRewardsAsc = "TOTAL_STAKE_REWARDS_ASC",
  TotalStakeRewardsDesc = "TOTAL_STAKE_REWARDS_DESC",
}

export type MyStakeSummary = {
  __typename?: "MyStakeSummary";
  /** Reads a single `Account` that is related to this `MyStakeSummary`. */
  staker?: Maybe<Account>;
  stakerPkid?: Maybe<Scalars["String"]["output"]>;
  totalStake?: Maybe<Scalars["BigFloat"]["output"]>;
  totalStakeRewards?: Maybe<Scalars["BigFloat"]["output"]>;
};

/**
 * A condition to be used against `MyStakeSummary` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type MyStakeSummaryCondition = {
  /** Checks for equality with the object’s `stakerPkid` field. */
  stakerPkid?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `totalStake` field. */
  totalStake?: InputMaybe<Scalars["BigFloat"]["input"]>;
  /** Checks for equality with the object’s `totalStakeRewards` field. */
  totalStakeRewards?: InputMaybe<Scalars["BigFloat"]["input"]>;
};

/** A filter to be used against `MyStakeSummary` object types. All fields are combined with a logical ‘and.’ */
export type MyStakeSummaryFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<MyStakeSummaryFilter>>;
  /** Negates the expression. */
  not?: InputMaybe<MyStakeSummaryFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<MyStakeSummaryFilter>>;
  /** Filter by the object’s `staker` relation. */
  staker?: InputMaybe<AccountFilter>;
  /** A related `staker` exists. */
  stakerExists?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Filter by the object’s `stakerPkid` field. */
  stakerPkid?: InputMaybe<StringFilter>;
  /** Filter by the object’s `totalStake` field. */
  totalStake?: InputMaybe<BigFloatFilter>;
  /** Filter by the object’s `totalStakeRewards` field. */
  totalStakeRewards?: InputMaybe<BigFloatFilter>;
};

export type Nft = Node & {
  __typename?: "Nft";
  buyNowPriceNanos?: Maybe<Scalars["BigInt"]["output"]>;
  extraData?: Maybe<Scalars["JSON"]["output"]>;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  id: Scalars["ID"]["output"];
  isBuyNow: Scalars["Boolean"]["output"];
  isForSale: Scalars["Boolean"]["output"];
  isPending: Scalars["Boolean"]["output"];
  lastAcceptedBidAmountNanos?: Maybe<Scalars["BigInt"]["output"]>;
  /** Reads a single `Account` that is related to this `Nft`. */
  lastOwner?: Maybe<Account>;
  lastOwnerPkid?: Maybe<Scalars["String"]["output"]>;
  minBidAmountNanos?: Maybe<Scalars["BigInt"]["output"]>;
  nftPostHash: Scalars["String"]["output"];
  /** Reads a single `Account` that is related to this `Nft`. */
  owner?: Maybe<Account>;
  ownerPkid: Scalars["String"]["output"];
  /** Reads a single `Post` that is related to this `Nft`. */
  post?: Maybe<Post>;
  serialNumber: Scalars["BigInt"]["output"];
  unlockableText?: Maybe<Scalars["String"]["output"]>;
};

export type NftBid = Node & {
  __typename?: "NftBid";
  acceptedBlockHeight?: Maybe<Scalars["BigInt"]["output"]>;
  bidAmountNanos: Scalars["BigInt"]["output"];
  /** Reads a single `Account` that is related to this `NftBid`. */
  bidder?: Maybe<Account>;
  bidderPkid: Scalars["String"]["output"];
  /** Reads a single `Block` that is related to this `NftBid`. */
  block?: Maybe<Block>;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  id: Scalars["ID"]["output"];
  nftPostHash: Scalars["String"]["output"];
  /** Reads a single `Post` that is related to this `NftBid`. */
  post?: Maybe<Post>;
  serialNumber: Scalars["BigInt"]["output"];
};

/** A condition to be used against `NftBid` object types. All fields are tested for equality and combined with a logical ‘and.’ */
export type NftBidCondition = {
  /** Checks for equality with the object’s `acceptedBlockHeight` field. */
  acceptedBlockHeight?: InputMaybe<Scalars["BigInt"]["input"]>;
  /** Checks for equality with the object’s `bidAmountNanos` field. */
  bidAmountNanos?: InputMaybe<Scalars["BigInt"]["input"]>;
  /** Checks for equality with the object’s `bidderPkid` field. */
  bidderPkid?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `nftPostHash` field. */
  nftPostHash?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `serialNumber` field. */
  serialNumber?: InputMaybe<Scalars["BigInt"]["input"]>;
};

/** A filter to be used against `NftBid` object types. All fields are combined with a logical ‘and.’ */
export type NftBidFilter = {
  /** Filter by the object’s `acceptedBlockHeight` field. */
  acceptedBlockHeight?: InputMaybe<BigIntFilter>;
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<NftBidFilter>>;
  /** Filter by the object’s `bidAmountNanos` field. */
  bidAmountNanos?: InputMaybe<BigIntFilter>;
  /** Filter by the object’s `bidder` relation. */
  bidder?: InputMaybe<AccountFilter>;
  /** Filter by the object’s `bidderPkid` field. */
  bidderPkid?: InputMaybe<StringFilter>;
  /** Filter by the object’s `block` relation. */
  block?: InputMaybe<BlockFilter>;
  /** A related `block` exists. */
  blockExists?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Filter by the object’s `nftPostHash` field. */
  nftPostHash?: InputMaybe<StringFilter>;
  /** Negates the expression. */
  not?: InputMaybe<NftBidFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<NftBidFilter>>;
  /** Filter by the object’s `post` relation. */
  post?: InputMaybe<PostFilter>;
  /** Filter by the object’s `serialNumber` field. */
  serialNumber?: InputMaybe<BigIntFilter>;
};

/** A connection to a list of `NftBid` values. */
export type NftBidsConnection = {
  __typename?: "NftBidsConnection";
  /** A list of edges which contains the `NftBid` and cursor to aid in pagination. */
  edges: Array<NftBidsEdge>;
  /** A list of `NftBid` objects. */
  nodes: Array<Maybe<NftBid>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `NftBid` you could get from the connection. */
  totalCount: Scalars["Int"]["output"];
};

/** A `NftBid` edge in the connection. */
export type NftBidsEdge = {
  __typename?: "NftBidsEdge";
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars["Cursor"]["output"]>;
  /** The `NftBid` at the end of the edge. */
  node?: Maybe<NftBid>;
};

/** Methods to use when ordering `NftBid`. */
export enum NftBidsOrderBy {
  AcceptedBlockHeightAsc = "ACCEPTED_BLOCK_HEIGHT_ASC",
  AcceptedBlockHeightDesc = "ACCEPTED_BLOCK_HEIGHT_DESC",
  BidderPkidAsc = "BIDDER_PKID_ASC",
  BidderPkidDesc = "BIDDER_PKID_DESC",
  BidAmountNanosAsc = "BID_AMOUNT_NANOS_ASC",
  BidAmountNanosDesc = "BID_AMOUNT_NANOS_DESC",
  Natural = "NATURAL",
  NftPostHashAsc = "NFT_POST_HASH_ASC",
  NftPostHashDesc = "NFT_POST_HASH_DESC",
  PrimaryKeyAsc = "PRIMARY_KEY_ASC",
  PrimaryKeyDesc = "PRIMARY_KEY_DESC",
  SerialNumberAsc = "SERIAL_NUMBER_ASC",
  SerialNumberDesc = "SERIAL_NUMBER_DESC",
}

/** A condition to be used against `Nft` object types. All fields are tested for equality and combined with a logical ‘and.’ */
export type NftCondition = {
  /** Checks for equality with the object’s `buyNowPriceNanos` field. */
  buyNowPriceNanos?: InputMaybe<Scalars["BigInt"]["input"]>;
  /** Checks for equality with the object’s `extraData` field. */
  extraData?: InputMaybe<Scalars["JSON"]["input"]>;
  /** Checks for equality with the object’s `isBuyNow` field. */
  isBuyNow?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Checks for equality with the object’s `isForSale` field. */
  isForSale?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Checks for equality with the object’s `isPending` field. */
  isPending?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Checks for equality with the object’s `lastAcceptedBidAmountNanos` field. */
  lastAcceptedBidAmountNanos?: InputMaybe<Scalars["BigInt"]["input"]>;
  /** Checks for equality with the object’s `lastOwnerPkid` field. */
  lastOwnerPkid?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `minBidAmountNanos` field. */
  minBidAmountNanos?: InputMaybe<Scalars["BigInt"]["input"]>;
  /** Checks for equality with the object’s `nftPostHash` field. */
  nftPostHash?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `ownerPkid` field. */
  ownerPkid?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `serialNumber` field. */
  serialNumber?: InputMaybe<Scalars["BigInt"]["input"]>;
  /** Checks for equality with the object’s `unlockableText` field. */
  unlockableText?: InputMaybe<Scalars["String"]["input"]>;
};

/** A filter to be used against `Nft` object types. All fields are combined with a logical ‘and.’ */
export type NftFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<NftFilter>>;
  /** Filter by the object’s `buyNowPriceNanos` field. */
  buyNowPriceNanos?: InputMaybe<BigIntFilter>;
  /** Filter by the object’s `extraData` field. */
  extraData?: InputMaybe<JsonFilter>;
  /** Filter by the object’s `isBuyNow` field. */
  isBuyNow?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `isForSale` field. */
  isForSale?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `isPending` field. */
  isPending?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `lastAcceptedBidAmountNanos` field. */
  lastAcceptedBidAmountNanos?: InputMaybe<BigIntFilter>;
  /** Filter by the object’s `lastOwner` relation. */
  lastOwner?: InputMaybe<AccountFilter>;
  /** A related `lastOwner` exists. */
  lastOwnerExists?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Filter by the object’s `lastOwnerPkid` field. */
  lastOwnerPkid?: InputMaybe<StringFilter>;
  /** Filter by the object’s `minBidAmountNanos` field. */
  minBidAmountNanos?: InputMaybe<BigIntFilter>;
  /** Filter by the object’s `nftPostHash` field. */
  nftPostHash?: InputMaybe<StringFilter>;
  /** Negates the expression. */
  not?: InputMaybe<NftFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<NftFilter>>;
  /** Filter by the object’s `owner` relation. */
  owner?: InputMaybe<AccountFilter>;
  /** Filter by the object’s `ownerPkid` field. */
  ownerPkid?: InputMaybe<StringFilter>;
  /** Filter by the object’s `post` relation. */
  post?: InputMaybe<PostFilter>;
  /** Filter by the object’s `serialNumber` field. */
  serialNumber?: InputMaybe<BigIntFilter>;
  /** Filter by the object’s `unlockableText` field. */
  unlockableText?: InputMaybe<StringFilter>;
};

export type NftLeaderboardStat = {
  __typename?: "NftLeaderboardStat";
  publicKey?: Maybe<Scalars["String"]["output"]>;
  rowId?: Maybe<Scalars["BigInt"]["output"]>;
  sum?: Maybe<Scalars["BigFloat"]["output"]>;
  username?: Maybe<Scalars["String"]["output"]>;
};

/**
 * A condition to be used against `NftLeaderboardStat` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type NftLeaderboardStatCondition = {
  /** Checks for equality with the object’s `publicKey` field. */
  publicKey?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `rowId` field. */
  rowId?: InputMaybe<Scalars["BigInt"]["input"]>;
  /** Checks for equality with the object’s `sum` field. */
  sum?: InputMaybe<Scalars["BigFloat"]["input"]>;
  /** Checks for equality with the object’s `username` field. */
  username?: InputMaybe<Scalars["String"]["input"]>;
};

/** A filter to be used against `NftLeaderboardStat` object types. All fields are combined with a logical ‘and.’ */
export type NftLeaderboardStatFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<NftLeaderboardStatFilter>>;
  /** Negates the expression. */
  not?: InputMaybe<NftLeaderboardStatFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<NftLeaderboardStatFilter>>;
  /** Filter by the object’s `publicKey` field. */
  publicKey?: InputMaybe<StringFilter>;
  /** Filter by the object’s `rowId` field. */
  rowId?: InputMaybe<BigIntFilter>;
  /** Filter by the object’s `sum` field. */
  sum?: InputMaybe<BigFloatFilter>;
  /** Filter by the object’s `username` field. */
  username?: InputMaybe<StringFilter>;
};

/** A connection to a list of `NftLeaderboardStat` values. */
export type NftLeaderboardStatsConnection = {
  __typename?: "NftLeaderboardStatsConnection";
  /** A list of edges which contains the `NftLeaderboardStat` and cursor to aid in pagination. */
  edges: Array<NftLeaderboardStatsEdge>;
  /** A list of `NftLeaderboardStat` objects. */
  nodes: Array<Maybe<NftLeaderboardStat>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `NftLeaderboardStat` you could get from the connection. */
  totalCount: Scalars["Int"]["output"];
};

/** A `NftLeaderboardStat` edge in the connection. */
export type NftLeaderboardStatsEdge = {
  __typename?: "NftLeaderboardStatsEdge";
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars["Cursor"]["output"]>;
  /** The `NftLeaderboardStat` at the end of the edge. */
  node?: Maybe<NftLeaderboardStat>;
};

/** Methods to use when ordering `NftLeaderboardStat`. */
export enum NftLeaderboardStatsOrderBy {
  IdAsc = "ID_ASC",
  IdDesc = "ID_DESC",
  Natural = "NATURAL",
  PublicKeyAsc = "PUBLIC_KEY_ASC",
  PublicKeyDesc = "PUBLIC_KEY_DESC",
  SumAsc = "SUM_ASC",
  SumDesc = "SUM_DESC",
  UsernameAsc = "USERNAME_ASC",
  UsernameDesc = "USERNAME_DESC",
}

/** A connection to a list of `Nft` values. */
export type NftsConnection = {
  __typename?: "NftsConnection";
  /** A list of edges which contains the `Nft` and cursor to aid in pagination. */
  edges: Array<NftsEdge>;
  /** A list of `Nft` objects. */
  nodes: Array<Maybe<Nft>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Nft` you could get from the connection. */
  totalCount: Scalars["Int"]["output"];
};

/** A `Nft` edge in the connection. */
export type NftsEdge = {
  __typename?: "NftsEdge";
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars["Cursor"]["output"]>;
  /** The `Nft` at the end of the edge. */
  node?: Maybe<Nft>;
};

/** Methods to use when ordering `Nft`. */
export enum NftsOrderBy {
  BuyNowPriceNanosAsc = "BUY_NOW_PRICE_NANOS_ASC",
  BuyNowPriceNanosDesc = "BUY_NOW_PRICE_NANOS_DESC",
  ExtraDataAsc = "EXTRA_DATA_ASC",
  ExtraDataDesc = "EXTRA_DATA_DESC",
  IsBuyNowAsc = "IS_BUY_NOW_ASC",
  IsBuyNowDesc = "IS_BUY_NOW_DESC",
  IsForSaleAsc = "IS_FOR_SALE_ASC",
  IsForSaleDesc = "IS_FOR_SALE_DESC",
  IsPendingAsc = "IS_PENDING_ASC",
  IsPendingDesc = "IS_PENDING_DESC",
  LastAcceptedBidAmountNanosAsc = "LAST_ACCEPTED_BID_AMOUNT_NANOS_ASC",
  LastAcceptedBidAmountNanosDesc = "LAST_ACCEPTED_BID_AMOUNT_NANOS_DESC",
  LastOwnerPkidAsc = "LAST_OWNER_PKID_ASC",
  LastOwnerPkidDesc = "LAST_OWNER_PKID_DESC",
  MinBidAmountNanosAsc = "MIN_BID_AMOUNT_NANOS_ASC",
  MinBidAmountNanosDesc = "MIN_BID_AMOUNT_NANOS_DESC",
  Natural = "NATURAL",
  NftPostHashAsc = "NFT_POST_HASH_ASC",
  NftPostHashDesc = "NFT_POST_HASH_DESC",
  OwnerPkidAsc = "OWNER_PKID_ASC",
  OwnerPkidDesc = "OWNER_PKID_DESC",
  PrimaryKeyAsc = "PRIMARY_KEY_ASC",
  PrimaryKeyDesc = "PRIMARY_KEY_DESC",
  SerialNumberAsc = "SERIAL_NUMBER_ASC",
  SerialNumberDesc = "SERIAL_NUMBER_DESC",
  UnlockableTextAsc = "UNLOCKABLE_TEXT_ASC",
  UnlockableTextDesc = "UNLOCKABLE_TEXT_DESC",
}

/** An object with a globally unique `ID`. */
export type Node = {
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  id: Scalars["ID"]["output"];
};

/** Information about pagination in a connection. */
export type PageInfo = {
  __typename?: "PageInfo";
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars["Cursor"]["output"]>;
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars["Boolean"]["output"];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars["Boolean"]["output"];
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars["Cursor"]["output"]>;
};

export type Post = Node & {
  __typename?: "Post";
  additionalNftRoyaltiesToCoinsBasisPoints?: Maybe<Scalars["JSON"]["output"]>;
  additionalNftRoyaltiesToCreatorsBasisPoints?: Maybe<
    Scalars["JSON"]["output"]
  >;
  body?: Maybe<Scalars["String"]["output"]>;
  /** Reads and enables pagination through a set of `Diamond`. */
  diamonds: DiamondsConnection;
  extraData?: Maybe<Scalars["JSON"]["output"]>;
  hasUnlockable?: Maybe<Scalars["Boolean"]["output"]>;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  id: Scalars["ID"]["output"];
  imageUrls?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  isFrozen?: Maybe<Scalars["Boolean"]["output"]>;
  isHidden?: Maybe<Scalars["Boolean"]["output"]>;
  isNft?: Maybe<Scalars["Boolean"]["output"]>;
  isPinned?: Maybe<Scalars["Boolean"]["output"]>;
  isQuotedRepost?: Maybe<Scalars["Boolean"]["output"]>;
  /** Reads and enables pagination through a set of `Like`. */
  likes: LikesConnection;
  /** Reads and enables pagination through a set of `NftBid`. */
  nftBids: NftBidsConnection;
  nftRoyaltyToCoinBasisPoints?: Maybe<Scalars["BigInt"]["output"]>;
  nftRoyaltyToCreatorBasisPoints?: Maybe<Scalars["BigInt"]["output"]>;
  /** Reads and enables pagination through a set of `Nft`. */
  nfts: NftsConnection;
  numNftCopies?: Maybe<Scalars["BigInt"]["output"]>;
  numNftCopiesBurned?: Maybe<Scalars["BigInt"]["output"]>;
  numNftCopiesForSale?: Maybe<Scalars["BigInt"]["output"]>;
  /** Reads a single `Post` that is related to this `Post`. */
  parentPost?: Maybe<Post>;
  parentPostHash?: Maybe<Scalars["String"]["output"]>;
  /** Reads and enables pagination through a set of `PostAssociation`. */
  postAssociationsByPostHash: PostAssociationsConnection;
  postHash: Scalars["String"]["output"];
  /** Reads a single `Account` that is related to this `Post`. */
  poster?: Maybe<Account>;
  posterPublicKey?: Maybe<Scalars["String"]["output"]>;
  /** Reads and enables pagination through a set of `Post`. */
  replies: PostsConnection;
  /** Reads a single `Post` that is related to this `Post`. */
  repostedPost?: Maybe<Post>;
  repostedPostHash?: Maybe<Scalars["String"]["output"]>;
  /** Reads and enables pagination through a set of `Post`. */
  reposts: PostsConnection;
  timestamp?: Maybe<Scalars["Datetime"]["output"]>;
  videoUrls?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
};

export type PostDiamondsArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<DiamondCondition>;
  filter?: InputMaybe<DiamondFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<DiamondsOrderBy>>;
};

export type PostLikesArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<LikeCondition>;
  filter?: InputMaybe<LikeFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<LikesOrderBy>>;
};

export type PostNftBidsArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<NftBidCondition>;
  filter?: InputMaybe<NftBidFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<NftBidsOrderBy>>;
};

export type PostNftsArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<NftCondition>;
  filter?: InputMaybe<NftFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<NftsOrderBy>>;
};

export type PostPostAssociationsByPostHashArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<PostAssociationCondition>;
  filter?: InputMaybe<PostAssociationFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<PostAssociationsOrderBy>>;
};

export type PostRepliesArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<PostCondition>;
  filter?: InputMaybe<PostFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<PostsOrderBy>>;
};

export type PostRepostsArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<PostCondition>;
  filter?: InputMaybe<PostFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<PostsOrderBy>>;
};

export type PostAssociation = Node & {
  __typename?: "PostAssociation";
  /** Reads a single `Account` that is related to this `PostAssociation`. */
  app?: Maybe<Account>;
  appPkid?: Maybe<Scalars["String"]["output"]>;
  associationId?: Maybe<Scalars["String"]["output"]>;
  associationType: Scalars["String"]["output"];
  associationValue: Scalars["String"]["output"];
  /** Reads a single `Block` that is related to this `PostAssociation`. */
  block?: Maybe<Block>;
  blockHeight?: Maybe<Scalars["Int"]["output"]>;
  extraData?: Maybe<Scalars["JSON"]["output"]>;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  id: Scalars["ID"]["output"];
  /** Reads a single `Post` that is related to this `PostAssociation`. */
  post?: Maybe<Post>;
  postHash?: Maybe<Scalars["String"]["output"]>;
  /** Reads a single `Account` that is related to this `PostAssociation`. */
  transactor?: Maybe<Account>;
  transactorPkid?: Maybe<Scalars["String"]["output"]>;
};

/**
 * A condition to be used against `PostAssociation` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type PostAssociationCondition = {
  /** Checks for equality with the object’s `appPkid` field. */
  appPkid?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `associationId` field. */
  associationId?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `associationType` field. */
  associationType?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `associationValue` field. */
  associationValue?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `blockHeight` field. */
  blockHeight?: InputMaybe<Scalars["Int"]["input"]>;
  /** Checks for equality with the object’s `extraData` field. */
  extraData?: InputMaybe<Scalars["JSON"]["input"]>;
  /** Checks for equality with the object’s `postHash` field. */
  postHash?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `transactorPkid` field. */
  transactorPkid?: InputMaybe<Scalars["String"]["input"]>;
};

/** A filter to be used against `PostAssociation` object types. All fields are combined with a logical ‘and.’ */
export type PostAssociationFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<PostAssociationFilter>>;
  /** Filter by the object’s `app` relation. */
  app?: InputMaybe<AccountFilter>;
  /** A related `app` exists. */
  appExists?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Filter by the object’s `appPkid` field. */
  appPkid?: InputMaybe<StringFilter>;
  /** Filter by the object’s `associationId` field. */
  associationId?: InputMaybe<StringFilter>;
  /** Filter by the object’s `associationType` field. */
  associationType?: InputMaybe<StringFilter>;
  /** Filter by the object’s `associationValue` field. */
  associationValue?: InputMaybe<StringFilter>;
  /** Filter by the object’s `block` relation. */
  block?: InputMaybe<BlockFilter>;
  /** A related `block` exists. */
  blockExists?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Filter by the object’s `blockHeight` field. */
  blockHeight?: InputMaybe<IntFilter>;
  /** Filter by the object’s `extraData` field. */
  extraData?: InputMaybe<JsonFilter>;
  /** Negates the expression. */
  not?: InputMaybe<PostAssociationFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<PostAssociationFilter>>;
  /** Filter by the object’s `post` relation. */
  post?: InputMaybe<PostFilter>;
  /** A related `post` exists. */
  postExists?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Filter by the object’s `postHash` field. */
  postHash?: InputMaybe<StringFilter>;
  /** Filter by the object’s `transactor` relation. */
  transactor?: InputMaybe<AccountFilter>;
  /** A related `transactor` exists. */
  transactorExists?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Filter by the object’s `transactorPkid` field. */
  transactorPkid?: InputMaybe<StringFilter>;
};

/** A connection to a list of `PostAssociation` values. */
export type PostAssociationsConnection = {
  __typename?: "PostAssociationsConnection";
  /** A list of edges which contains the `PostAssociation` and cursor to aid in pagination. */
  edges: Array<PostAssociationsEdge>;
  /** A list of `PostAssociation` objects. */
  nodes: Array<Maybe<PostAssociation>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `PostAssociation` you could get from the connection. */
  totalCount: Scalars["Int"]["output"];
};

/** A `PostAssociation` edge in the connection. */
export type PostAssociationsEdge = {
  __typename?: "PostAssociationsEdge";
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars["Cursor"]["output"]>;
  /** The `PostAssociation` at the end of the edge. */
  node?: Maybe<PostAssociation>;
};

/** Methods to use when ordering `PostAssociation`. */
export enum PostAssociationsOrderBy {
  AppPkidAsc = "APP_PKID_ASC",
  AppPkidDesc = "APP_PKID_DESC",
  AssociationIdAsc = "ASSOCIATION_ID_ASC",
  AssociationIdDesc = "ASSOCIATION_ID_DESC",
  AssociationTypeAsc = "ASSOCIATION_TYPE_ASC",
  AssociationTypeDesc = "ASSOCIATION_TYPE_DESC",
  AssociationValueAsc = "ASSOCIATION_VALUE_ASC",
  AssociationValueDesc = "ASSOCIATION_VALUE_DESC",
  BlockHeightAsc = "BLOCK_HEIGHT_ASC",
  BlockHeightDesc = "BLOCK_HEIGHT_DESC",
  ExtraDataAsc = "EXTRA_DATA_ASC",
  ExtraDataDesc = "EXTRA_DATA_DESC",
  Natural = "NATURAL",
  PostHashAsc = "POST_HASH_ASC",
  PostHashDesc = "POST_HASH_DESC",
  PrimaryKeyAsc = "PRIMARY_KEY_ASC",
  PrimaryKeyDesc = "PRIMARY_KEY_DESC",
  TransactorPkidAsc = "TRANSACTOR_PKID_ASC",
  TransactorPkidDesc = "TRANSACTOR_PKID_DESC",
}

/** A condition to be used against `Post` object types. All fields are tested for equality and combined with a logical ‘and.’ */
export type PostCondition = {
  /** Checks for equality with the object’s `additionalNftRoyaltiesToCoinsBasisPoints` field. */
  additionalNftRoyaltiesToCoinsBasisPoints?: InputMaybe<
    Scalars["JSON"]["input"]
  >;
  /** Checks for equality with the object’s `additionalNftRoyaltiesToCreatorsBasisPoints` field. */
  additionalNftRoyaltiesToCreatorsBasisPoints?: InputMaybe<
    Scalars["JSON"]["input"]
  >;
  /** Checks for equality with the object’s `body` field. */
  body?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `extraData` field. */
  extraData?: InputMaybe<Scalars["JSON"]["input"]>;
  /** Checks for equality with the object’s `hasUnlockable` field. */
  hasUnlockable?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Checks for equality with the object’s `imageUrls` field. */
  imageUrls?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** Checks for equality with the object’s `isFrozen` field. */
  isFrozen?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Checks for equality with the object’s `isHidden` field. */
  isHidden?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Checks for equality with the object’s `isNft` field. */
  isNft?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Checks for equality with the object’s `isPinned` field. */
  isPinned?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Checks for equality with the object’s `isQuotedRepost` field. */
  isQuotedRepost?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Checks for equality with the object’s `nftRoyaltyToCoinBasisPoints` field. */
  nftRoyaltyToCoinBasisPoints?: InputMaybe<Scalars["BigInt"]["input"]>;
  /** Checks for equality with the object’s `nftRoyaltyToCreatorBasisPoints` field. */
  nftRoyaltyToCreatorBasisPoints?: InputMaybe<Scalars["BigInt"]["input"]>;
  /** Checks for equality with the object’s `numNftCopies` field. */
  numNftCopies?: InputMaybe<Scalars["BigInt"]["input"]>;
  /** Checks for equality with the object’s `numNftCopiesBurned` field. */
  numNftCopiesBurned?: InputMaybe<Scalars["BigInt"]["input"]>;
  /** Checks for equality with the object’s `numNftCopiesForSale` field. */
  numNftCopiesForSale?: InputMaybe<Scalars["BigInt"]["input"]>;
  /** Checks for equality with the object’s `parentPostHash` field. */
  parentPostHash?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `postHash` field. */
  postHash?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `posterPublicKey` field. */
  posterPublicKey?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `repostedPostHash` field. */
  repostedPostHash?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `timestamp` field. */
  timestamp?: InputMaybe<Scalars["Datetime"]["input"]>;
  /** Checks for equality with the object’s `videoUrls` field. */
  videoUrls?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

/** A filter to be used against `Post` object types. All fields are combined with a logical ‘and.’ */
export type PostFilter = {
  /** Filter by the object’s `additionalNftRoyaltiesToCoinsBasisPoints` field. */
  additionalNftRoyaltiesToCoinsBasisPoints?: InputMaybe<JsonFilter>;
  /** Filter by the object’s `additionalNftRoyaltiesToCreatorsBasisPoints` field. */
  additionalNftRoyaltiesToCreatorsBasisPoints?: InputMaybe<JsonFilter>;
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<PostFilter>>;
  /** Filter by the object’s `body` field. */
  body?: InputMaybe<StringFilter>;
  /** Filter by the object’s `diamonds` relation. */
  diamonds?: InputMaybe<PostToManyDiamondFilter>;
  /** Some related `diamonds` exist. */
  diamondsExist?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Filter by the object’s `extraData` field. */
  extraData?: InputMaybe<JsonFilter>;
  /** Filter by the object’s `hasUnlockable` field. */
  hasUnlockable?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `imageUrls` field. */
  imageUrls?: InputMaybe<StringListFilter>;
  /** Filter by the object’s `isFrozen` field. */
  isFrozen?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `isHidden` field. */
  isHidden?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `isNft` field. */
  isNft?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `isPinned` field. */
  isPinned?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `isQuotedRepost` field. */
  isQuotedRepost?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `likes` relation. */
  likes?: InputMaybe<PostToManyLikeFilter>;
  /** Some related `likes` exist. */
  likesExist?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Filter by the object’s `nftBids` relation. */
  nftBids?: InputMaybe<PostToManyNftBidFilter>;
  /** Some related `nftBids` exist. */
  nftBidsExist?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Filter by the object’s `nftRoyaltyToCoinBasisPoints` field. */
  nftRoyaltyToCoinBasisPoints?: InputMaybe<BigIntFilter>;
  /** Filter by the object’s `nftRoyaltyToCreatorBasisPoints` field. */
  nftRoyaltyToCreatorBasisPoints?: InputMaybe<BigIntFilter>;
  /** Filter by the object’s `nfts` relation. */
  nfts?: InputMaybe<PostToManyNftFilter>;
  /** Some related `nfts` exist. */
  nftsExist?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Negates the expression. */
  not?: InputMaybe<PostFilter>;
  /** Filter by the object’s `numNftCopies` field. */
  numNftCopies?: InputMaybe<BigIntFilter>;
  /** Filter by the object’s `numNftCopiesBurned` field. */
  numNftCopiesBurned?: InputMaybe<BigIntFilter>;
  /** Filter by the object’s `numNftCopiesForSale` field. */
  numNftCopiesForSale?: InputMaybe<BigIntFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<PostFilter>>;
  /** Filter by the object’s `parentPost` relation. */
  parentPost?: InputMaybe<PostFilter>;
  /** A related `parentPost` exists. */
  parentPostExists?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Filter by the object’s `parentPostHash` field. */
  parentPostHash?: InputMaybe<StringFilter>;
  /** Filter by the object’s `postAssociationsByPostHash` relation. */
  postAssociationsByPostHash?: InputMaybe<PostToManyPostAssociationFilter>;
  /** Some related `postAssociationsByPostHash` exist. */
  postAssociationsByPostHashExist?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Filter by the object’s `postHash` field. */
  postHash?: InputMaybe<StringFilter>;
  /** Filter by the object’s `poster` relation. */
  poster?: InputMaybe<AccountFilter>;
  /** A related `poster` exists. */
  posterExists?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Filter by the object’s `posterPublicKey` field. */
  posterPublicKey?: InputMaybe<StringFilter>;
  /** Filter by the object’s `replies` relation. */
  replies?: InputMaybe<PostToManyPostFilter>;
  /** Some related `replies` exist. */
  repliesExist?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Filter by the object’s `repostedPost` relation. */
  repostedPost?: InputMaybe<PostFilter>;
  /** A related `repostedPost` exists. */
  repostedPostExists?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Filter by the object’s `repostedPostHash` field. */
  repostedPostHash?: InputMaybe<StringFilter>;
  /** Filter by the object’s `reposts` relation. */
  reposts?: InputMaybe<PostToManyPostFilter>;
  /** Some related `reposts` exist. */
  repostsExist?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Filter by the object’s `timestamp` field. */
  timestamp?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `videoUrls` field. */
  videoUrls?: InputMaybe<StringListFilter>;
};

/** A filter to be used against many `Diamond` object types. All fields are combined with a logical ‘and.’ */
export type PostToManyDiamondFilter = {
  /** Every related `Diamond` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<DiamondFilter>;
  /** No related `Diamond` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<DiamondFilter>;
  /** Some related `Diamond` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<DiamondFilter>;
};

/** A filter to be used against many `Like` object types. All fields are combined with a logical ‘and.’ */
export type PostToManyLikeFilter = {
  /** Every related `Like` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<LikeFilter>;
  /** No related `Like` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<LikeFilter>;
  /** Some related `Like` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<LikeFilter>;
};

/** A filter to be used against many `NftBid` object types. All fields are combined with a logical ‘and.’ */
export type PostToManyNftBidFilter = {
  /** Every related `NftBid` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<NftBidFilter>;
  /** No related `NftBid` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<NftBidFilter>;
  /** Some related `NftBid` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<NftBidFilter>;
};

/** A filter to be used against many `Nft` object types. All fields are combined with a logical ‘and.’ */
export type PostToManyNftFilter = {
  /** Every related `Nft` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<NftFilter>;
  /** No related `Nft` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<NftFilter>;
  /** Some related `Nft` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<NftFilter>;
};

/** A filter to be used against many `PostAssociation` object types. All fields are combined with a logical ‘and.’ */
export type PostToManyPostAssociationFilter = {
  /** Every related `PostAssociation` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<PostAssociationFilter>;
  /** No related `PostAssociation` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<PostAssociationFilter>;
  /** Some related `PostAssociation` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<PostAssociationFilter>;
};

/** A filter to be used against many `Post` object types. All fields are combined with a logical ‘and.’ */
export type PostToManyPostFilter = {
  /** Every related `Post` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<PostFilter>;
  /** No related `Post` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<PostFilter>;
  /** Some related `Post` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<PostFilter>;
};

/** A connection to a list of `Post` values. */
export type PostsConnection = {
  __typename?: "PostsConnection";
  /** A list of edges which contains the `Post` and cursor to aid in pagination. */
  edges: Array<PostsEdge>;
  /** A list of `Post` objects. */
  nodes: Array<Maybe<Post>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Post` you could get from the connection. */
  totalCount: Scalars["Int"]["output"];
};

/** A `Post` edge in the connection. */
export type PostsEdge = {
  __typename?: "PostsEdge";
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars["Cursor"]["output"]>;
  /** The `Post` at the end of the edge. */
  node?: Maybe<Post>;
};

/** Methods to use when ordering `Post`. */
export enum PostsOrderBy {
  AdditionalNftRoyaltiesToCoinsBasisPointsAsc = "ADDITIONAL_NFT_ROYALTIES_TO_COINS_BASIS_POINTS_ASC",
  AdditionalNftRoyaltiesToCoinsBasisPointsDesc = "ADDITIONAL_NFT_ROYALTIES_TO_COINS_BASIS_POINTS_DESC",
  AdditionalNftRoyaltiesToCreatorsBasisPointsAsc = "ADDITIONAL_NFT_ROYALTIES_TO_CREATORS_BASIS_POINTS_ASC",
  AdditionalNftRoyaltiesToCreatorsBasisPointsDesc = "ADDITIONAL_NFT_ROYALTIES_TO_CREATORS_BASIS_POINTS_DESC",
  BodyAsc = "BODY_ASC",
  BodyDesc = "BODY_DESC",
  ExtraDataAsc = "EXTRA_DATA_ASC",
  ExtraDataDesc = "EXTRA_DATA_DESC",
  HasUnlockableAsc = "HAS_UNLOCKABLE_ASC",
  HasUnlockableDesc = "HAS_UNLOCKABLE_DESC",
  ImageUrlsAsc = "IMAGE_URLS_ASC",
  ImageUrlsDesc = "IMAGE_URLS_DESC",
  IsFrozenAsc = "IS_FROZEN_ASC",
  IsFrozenDesc = "IS_FROZEN_DESC",
  IsHiddenAsc = "IS_HIDDEN_ASC",
  IsHiddenDesc = "IS_HIDDEN_DESC",
  IsNftAsc = "IS_NFT_ASC",
  IsNftDesc = "IS_NFT_DESC",
  IsPinnedAsc = "IS_PINNED_ASC",
  IsPinnedDesc = "IS_PINNED_DESC",
  IsQuotedRepostAsc = "IS_QUOTED_REPOST_ASC",
  IsQuotedRepostDesc = "IS_QUOTED_REPOST_DESC",
  Natural = "NATURAL",
  NftRoyaltyToCoinBasisPointsAsc = "NFT_ROYALTY_TO_COIN_BASIS_POINTS_ASC",
  NftRoyaltyToCoinBasisPointsDesc = "NFT_ROYALTY_TO_COIN_BASIS_POINTS_DESC",
  NftRoyaltyToCreatorBasisPointsAsc = "NFT_ROYALTY_TO_CREATOR_BASIS_POINTS_ASC",
  NftRoyaltyToCreatorBasisPointsDesc = "NFT_ROYALTY_TO_CREATOR_BASIS_POINTS_DESC",
  NumNftCopiesAsc = "NUM_NFT_COPIES_ASC",
  NumNftCopiesBurnedAsc = "NUM_NFT_COPIES_BURNED_ASC",
  NumNftCopiesBurnedDesc = "NUM_NFT_COPIES_BURNED_DESC",
  NumNftCopiesDesc = "NUM_NFT_COPIES_DESC",
  NumNftCopiesForSaleAsc = "NUM_NFT_COPIES_FOR_SALE_ASC",
  NumNftCopiesForSaleDesc = "NUM_NFT_COPIES_FOR_SALE_DESC",
  ParentPostHashAsc = "PARENT_POST_HASH_ASC",
  ParentPostHashDesc = "PARENT_POST_HASH_DESC",
  PosterPublicKeyAsc = "POSTER_PUBLIC_KEY_ASC",
  PosterPublicKeyDesc = "POSTER_PUBLIC_KEY_DESC",
  PostHashAsc = "POST_HASH_ASC",
  PostHashDesc = "POST_HASH_DESC",
  PrimaryKeyAsc = "PRIMARY_KEY_ASC",
  PrimaryKeyDesc = "PRIMARY_KEY_DESC",
  RepostedPostHashAsc = "REPOSTED_POST_HASH_ASC",
  RepostedPostHashDesc = "REPOSTED_POST_HASH_DESC",
  TimestampAsc = "TIMESTAMP_ASC",
  TimestampDesc = "TIMESTAMP_DESC",
  VideoUrlsAsc = "VIDEO_URLS_ASC",
  VideoUrlsDesc = "VIDEO_URLS_DESC",
}

export type Profile = Node & {
  __typename?: "Profile";
  /** Reads a single `Account` that is related to this `Profile`. */
  account?: Maybe<Account>;
  ccCoinsInCirculationNanos: Scalars["BigInt"]["output"];
  coinPriceDesoNanos?: Maybe<Scalars["BigFloat"]["output"]>;
  coinWatermarkNanos: Scalars["BigInt"]["output"];
  creatorBasisPoints: Scalars["BigInt"]["output"];
  daoCoinMintingDisabled: Scalars["Boolean"]["output"];
  daoCoinTransferRestrictionStatus: Scalars["Int"]["output"];
  daoCoinsInCirculationNanosHex: Scalars["String"]["output"];
  description?: Maybe<Scalars["String"]["output"]>;
  desoLockedNanos: Scalars["BigInt"]["output"];
  extraData?: Maybe<Scalars["JSON"]["output"]>;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  id: Scalars["ID"]["output"];
  mintingDisabled: Scalars["Boolean"]["output"];
  pkid: Scalars["String"]["output"];
  profilePic?: Maybe<Scalars["String"]["output"]>;
  publicKey: Scalars["String"]["output"];
  username?: Maybe<Scalars["String"]["output"]>;
};

/** A condition to be used against `Profile` object types. All fields are tested for equality and combined with a logical ‘and.’ */
export type ProfileCondition = {
  /** Checks for equality with the object’s `ccCoinsInCirculationNanos` field. */
  ccCoinsInCirculationNanos?: InputMaybe<Scalars["BigInt"]["input"]>;
  /** Checks for equality with the object’s `coinPriceDesoNanos` field. */
  coinPriceDesoNanos?: InputMaybe<Scalars["BigFloat"]["input"]>;
  /** Checks for equality with the object’s `coinWatermarkNanos` field. */
  coinWatermarkNanos?: InputMaybe<Scalars["BigInt"]["input"]>;
  /** Checks for equality with the object’s `creatorBasisPoints` field. */
  creatorBasisPoints?: InputMaybe<Scalars["BigInt"]["input"]>;
  /** Checks for equality with the object’s `daoCoinMintingDisabled` field. */
  daoCoinMintingDisabled?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Checks for equality with the object’s `daoCoinTransferRestrictionStatus` field. */
  daoCoinTransferRestrictionStatus?: InputMaybe<Scalars["Int"]["input"]>;
  /** Checks for equality with the object’s `daoCoinsInCirculationNanosHex` field. */
  daoCoinsInCirculationNanosHex?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `description` field. */
  description?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `desoLockedNanos` field. */
  desoLockedNanos?: InputMaybe<Scalars["BigInt"]["input"]>;
  /** Checks for equality with the object’s `extraData` field. */
  extraData?: InputMaybe<Scalars["JSON"]["input"]>;
  /** Checks for equality with the object’s `mintingDisabled` field. */
  mintingDisabled?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Checks for equality with the object’s `pkid` field. */
  pkid?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `profilePic` field. */
  profilePic?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `publicKey` field. */
  publicKey?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `username` field. */
  username?: InputMaybe<Scalars["String"]["input"]>;
};

export type ProfileEarningsBreakdownStat = {
  __typename?: "ProfileEarningsBreakdownStat";
  ccBuyAmountNanos?: Maybe<Scalars["BigFloat"]["output"]>;
  ccBuyCount?: Maybe<Scalars["BigInt"]["output"]>;
  ccSellAmountNanos?: Maybe<Scalars["BigFloat"]["output"]>;
  ccSellCount?: Maybe<Scalars["BigInt"]["output"]>;
  diamondsGivenCount?: Maybe<Scalars["BigInt"]["output"]>;
  diamondsReceivedCount?: Maybe<Scalars["BigInt"]["output"]>;
  nftBuyAmountNanos?: Maybe<Scalars["BigFloat"]["output"]>;
  nftBuyCount?: Maybe<Scalars["BigInt"]["output"]>;
  nftSellAmountNanos?: Maybe<Scalars["BigFloat"]["output"]>;
  nftSellCount?: Maybe<Scalars["BigInt"]["output"]>;
  publicKey?: Maybe<Scalars["String"]["output"]>;
  tokenBuyOrderNanosFilled?: Maybe<Scalars["BigFloat"]["output"]>;
  tokenBuyTradeCount?: Maybe<Scalars["BigInt"]["output"]>;
  tokenSellOrderNanosFillede?: Maybe<Scalars["BigFloat"]["output"]>;
  tokenSellTradeCount?: Maybe<Scalars["BigInt"]["output"]>;
  username?: Maybe<Scalars["String"]["output"]>;
};

export type ProfileEarningsStat = {
  __typename?: "ProfileEarningsStat";
  publicKey?: Maybe<Scalars["String"]["output"]>;
  totalCcRoyaltyNanos?: Maybe<Scalars["BigFloat"]["output"]>;
  totalDiamondNanos?: Maybe<Scalars["BigFloat"]["output"]>;
  totalNftAdditionalRoyaltyNanos?: Maybe<Scalars["BigFloat"]["output"]>;
  totalNftCreatorRoyaltyNanos?: Maybe<Scalars["BigFloat"]["output"]>;
  totalNftRoyaltyNanos?: Maybe<Scalars["BigFloat"]["output"]>;
  username?: Maybe<Scalars["String"]["output"]>;
};

/** A filter to be used against `Profile` object types. All fields are combined with a logical ‘and.’ */
export type ProfileFilter = {
  /** Filter by the object’s `account` relation. */
  account?: InputMaybe<AccountFilter>;
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<ProfileFilter>>;
  /** Filter by the object’s `ccCoinsInCirculationNanos` field. */
  ccCoinsInCirculationNanos?: InputMaybe<BigIntFilter>;
  /** Filter by the object’s `coinPriceDesoNanos` field. */
  coinPriceDesoNanos?: InputMaybe<BigFloatFilter>;
  /** Filter by the object’s `coinWatermarkNanos` field. */
  coinWatermarkNanos?: InputMaybe<BigIntFilter>;
  /** Filter by the object’s `creatorBasisPoints` field. */
  creatorBasisPoints?: InputMaybe<BigIntFilter>;
  /** Filter by the object’s `daoCoinMintingDisabled` field. */
  daoCoinMintingDisabled?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `daoCoinTransferRestrictionStatus` field. */
  daoCoinTransferRestrictionStatus?: InputMaybe<IntFilter>;
  /** Filter by the object’s `daoCoinsInCirculationNanosHex` field. */
  daoCoinsInCirculationNanosHex?: InputMaybe<StringFilter>;
  /** Filter by the object’s `description` field. */
  description?: InputMaybe<StringFilter>;
  /** Filter by the object’s `desoLockedNanos` field. */
  desoLockedNanos?: InputMaybe<BigIntFilter>;
  /** Filter by the object’s `extraData` field. */
  extraData?: InputMaybe<JsonFilter>;
  /** Filter by the object’s `mintingDisabled` field. */
  mintingDisabled?: InputMaybe<BooleanFilter>;
  /** Negates the expression. */
  not?: InputMaybe<ProfileFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<ProfileFilter>>;
  /** Filter by the object’s `pkid` field. */
  pkid?: InputMaybe<StringFilter>;
  /** Filter by the object’s `publicKey` field. */
  publicKey?: InputMaybe<StringFilter>;
  /** Filter by the object’s `username` field. */
  username?: InputMaybe<StringFilter>;
};

export type ProfileNftTopOwner = {
  __typename?: "ProfileNftTopOwner";
  count?: Maybe<Scalars["BigInt"]["output"]>;
  creatorPublicKey?: Maybe<Scalars["String"]["output"]>;
  publicKey?: Maybe<Scalars["String"]["output"]>;
  username?: Maybe<Scalars["String"]["output"]>;
};

/**
 * A condition to be used against `ProfileNftTopOwner` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type ProfileNftTopOwnerCondition = {
  /** Checks for equality with the object’s `count` field. */
  count?: InputMaybe<Scalars["BigInt"]["input"]>;
  /** Checks for equality with the object’s `creatorPublicKey` field. */
  creatorPublicKey?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `publicKey` field. */
  publicKey?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `username` field. */
  username?: InputMaybe<Scalars["String"]["input"]>;
};

/** A filter to be used against `ProfileNftTopOwner` object types. All fields are combined with a logical ‘and.’ */
export type ProfileNftTopOwnerFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<ProfileNftTopOwnerFilter>>;
  /** Filter by the object’s `count` field. */
  count?: InputMaybe<BigIntFilter>;
  /** Filter by the object’s `creatorPublicKey` field. */
  creatorPublicKey?: InputMaybe<StringFilter>;
  /** Negates the expression. */
  not?: InputMaybe<ProfileNftTopOwnerFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<ProfileNftTopOwnerFilter>>;
  /** Filter by the object’s `publicKey` field. */
  publicKey?: InputMaybe<StringFilter>;
  /** Filter by the object’s `username` field. */
  username?: InputMaybe<StringFilter>;
};

/** A connection to a list of `ProfileNftTopOwner` values. */
export type ProfileNftTopOwnersConnection = {
  __typename?: "ProfileNftTopOwnersConnection";
  /** A list of edges which contains the `ProfileNftTopOwner` and cursor to aid in pagination. */
  edges: Array<ProfileNftTopOwnersEdge>;
  /** A list of `ProfileNftTopOwner` objects. */
  nodes: Array<Maybe<ProfileNftTopOwner>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `ProfileNftTopOwner` you could get from the connection. */
  totalCount: Scalars["Int"]["output"];
};

/** A `ProfileNftTopOwner` edge in the connection. */
export type ProfileNftTopOwnersEdge = {
  __typename?: "ProfileNftTopOwnersEdge";
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars["Cursor"]["output"]>;
  /** The `ProfileNftTopOwner` at the end of the edge. */
  node?: Maybe<ProfileNftTopOwner>;
};

/** Methods to use when ordering `ProfileNftTopOwner`. */
export enum ProfileNftTopOwnersOrderBy {
  CountAsc = "COUNT_ASC",
  CountDesc = "COUNT_DESC",
  CreatorPublicKeyAsc = "CREATOR_PUBLIC_KEY_ASC",
  CreatorPublicKeyDesc = "CREATOR_PUBLIC_KEY_DESC",
  Natural = "NATURAL",
  PublicKeyAsc = "PUBLIC_KEY_ASC",
  PublicKeyDesc = "PUBLIC_KEY_DESC",
  UsernameAsc = "USERNAME_ASC",
  UsernameDesc = "USERNAME_DESC",
}

export type ProfilePortfolioValueStat = {
  __typename?: "ProfilePortfolioValueStat";
  ccValueNanos?: Maybe<Scalars["BigFloat"]["output"]>;
  desoBalanceValueNanos?: Maybe<Scalars["BigInt"]["output"]>;
  nftValueNanos?: Maybe<Scalars["BigFloat"]["output"]>;
  publicKey?: Maybe<Scalars["String"]["output"]>;
  tokenValueNanos?: Maybe<Scalars["BigFloat"]["output"]>;
};

export type ProfileTransactionStat = {
  __typename?: "ProfileTransactionStat";
  /** Reads a single `Account` that is related to this `ProfileTransactionStat`. */
  account?: Maybe<Account>;
  count?: Maybe<Scalars["BigInt"]["output"]>;
  firstTransactionTimestamp?: Maybe<Scalars["Datetime"]["output"]>;
  latestTransactionTimestamp?: Maybe<Scalars["Datetime"]["output"]>;
  publicKey?: Maybe<Scalars["String"]["output"]>;
  totalFees?: Maybe<Scalars["BigFloat"]["output"]>;
};

/**
 * A condition to be used against `ProfileTransactionStat` object types. All fields
 * are tested for equality and combined with a logical ‘and.’
 */
export type ProfileTransactionStatCondition = {
  /** Checks for equality with the object’s `count` field. */
  count?: InputMaybe<Scalars["BigInt"]["input"]>;
  /** Checks for equality with the object’s `firstTransactionTimestamp` field. */
  firstTransactionTimestamp?: InputMaybe<Scalars["Datetime"]["input"]>;
  /** Checks for equality with the object’s `latestTransactionTimestamp` field. */
  latestTransactionTimestamp?: InputMaybe<Scalars["Datetime"]["input"]>;
  /** Checks for equality with the object’s `publicKey` field. */
  publicKey?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `totalFees` field. */
  totalFees?: InputMaybe<Scalars["BigFloat"]["input"]>;
};

/** A filter to be used against `ProfileTransactionStat` object types. All fields are combined with a logical ‘and.’ */
export type ProfileTransactionStatFilter = {
  /** Filter by the object’s `account` relation. */
  account?: InputMaybe<AccountFilter>;
  /** A related `account` exists. */
  accountExists?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<ProfileTransactionStatFilter>>;
  /** Filter by the object’s `count` field. */
  count?: InputMaybe<BigIntFilter>;
  /** Filter by the object’s `firstTransactionTimestamp` field. */
  firstTransactionTimestamp?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `latestTransactionTimestamp` field. */
  latestTransactionTimestamp?: InputMaybe<DatetimeFilter>;
  /** Negates the expression. */
  not?: InputMaybe<ProfileTransactionStatFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<ProfileTransactionStatFilter>>;
  /** Filter by the object’s `publicKey` field. */
  publicKey?: InputMaybe<StringFilter>;
  /** Filter by the object’s `totalFees` field. */
  totalFees?: InputMaybe<BigFloatFilter>;
};

/** A connection to a list of `ProfileTransactionStat` values. */
export type ProfileTransactionStatsConnection = {
  __typename?: "ProfileTransactionStatsConnection";
  /** A list of edges which contains the `ProfileTransactionStat` and cursor to aid in pagination. */
  edges: Array<ProfileTransactionStatsEdge>;
  /** A list of `ProfileTransactionStat` objects. */
  nodes: Array<Maybe<ProfileTransactionStat>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `ProfileTransactionStat` you could get from the connection. */
  totalCount: Scalars["Int"]["output"];
};

/** A `ProfileTransactionStat` edge in the connection. */
export type ProfileTransactionStatsEdge = {
  __typename?: "ProfileTransactionStatsEdge";
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars["Cursor"]["output"]>;
  /** The `ProfileTransactionStat` at the end of the edge. */
  node?: Maybe<ProfileTransactionStat>;
};

/** Methods to use when ordering `ProfileTransactionStat`. */
export enum ProfileTransactionStatsOrderBy {
  CountAsc = "COUNT_ASC",
  CountDesc = "COUNT_DESC",
  FirstTransactionTimestampAsc = "FIRST_TRANSACTION_TIMESTAMP_ASC",
  FirstTransactionTimestampDesc = "FIRST_TRANSACTION_TIMESTAMP_DESC",
  LatestTransactionTimestampAsc = "LATEST_TRANSACTION_TIMESTAMP_ASC",
  LatestTransactionTimestampDesc = "LATEST_TRANSACTION_TIMESTAMP_DESC",
  Natural = "NATURAL",
  PublicKeyAsc = "PUBLIC_KEY_ASC",
  PublicKeyDesc = "PUBLIC_KEY_DESC",
  TotalFeesAsc = "TOTAL_FEES_ASC",
  TotalFeesDesc = "TOTAL_FEES_DESC",
}

/** A connection to a list of `Profile` values. */
export type ProfilesConnection = {
  __typename?: "ProfilesConnection";
  /** A list of edges which contains the `Profile` and cursor to aid in pagination. */
  edges: Array<ProfilesEdge>;
  /** A list of `Profile` objects. */
  nodes: Array<Maybe<Profile>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Profile` you could get from the connection. */
  totalCount: Scalars["Int"]["output"];
};

/** A `Profile` edge in the connection. */
export type ProfilesEdge = {
  __typename?: "ProfilesEdge";
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars["Cursor"]["output"]>;
  /** The `Profile` at the end of the edge. */
  node?: Maybe<Profile>;
};

/** Methods to use when ordering `Profile`. */
export enum ProfilesOrderBy {
  CcCoinsInCirculationNanosAsc = "CC_COINS_IN_CIRCULATION_NANOS_ASC",
  CcCoinsInCirculationNanosDesc = "CC_COINS_IN_CIRCULATION_NANOS_DESC",
  CoinPriceDesoNanosAsc = "COIN_PRICE_DESO_NANOS_ASC",
  CoinPriceDesoNanosDesc = "COIN_PRICE_DESO_NANOS_DESC",
  CoinWatermarkNanosAsc = "COIN_WATERMARK_NANOS_ASC",
  CoinWatermarkNanosDesc = "COIN_WATERMARK_NANOS_DESC",
  CreatorBasisPointsAsc = "CREATOR_BASIS_POINTS_ASC",
  CreatorBasisPointsDesc = "CREATOR_BASIS_POINTS_DESC",
  DaoCoinsInCirculationNanosHexAsc = "DAO_COINS_IN_CIRCULATION_NANOS_HEX_ASC",
  DaoCoinsInCirculationNanosHexDesc = "DAO_COINS_IN_CIRCULATION_NANOS_HEX_DESC",
  DaoCoinMintingDisabledAsc = "DAO_COIN_MINTING_DISABLED_ASC",
  DaoCoinMintingDisabledDesc = "DAO_COIN_MINTING_DISABLED_DESC",
  DaoCoinTransferRestrictionStatusAsc = "DAO_COIN_TRANSFER_RESTRICTION_STATUS_ASC",
  DaoCoinTransferRestrictionStatusDesc = "DAO_COIN_TRANSFER_RESTRICTION_STATUS_DESC",
  DescriptionAsc = "DESCRIPTION_ASC",
  DescriptionDesc = "DESCRIPTION_DESC",
  DesoLockedNanosAsc = "DESO_LOCKED_NANOS_ASC",
  DesoLockedNanosDesc = "DESO_LOCKED_NANOS_DESC",
  ExtraDataAsc = "EXTRA_DATA_ASC",
  ExtraDataDesc = "EXTRA_DATA_DESC",
  MintingDisabledAsc = "MINTING_DISABLED_ASC",
  MintingDisabledDesc = "MINTING_DISABLED_DESC",
  Natural = "NATURAL",
  PkidAsc = "PKID_ASC",
  PkidDesc = "PKID_DESC",
  PrimaryKeyAsc = "PRIMARY_KEY_ASC",
  PrimaryKeyDesc = "PRIMARY_KEY_DESC",
  ProfilePicAsc = "PROFILE_PIC_ASC",
  ProfilePicDesc = "PROFILE_PIC_DESC",
  PublicKeyAsc = "PUBLIC_KEY_ASC",
  PublicKeyDesc = "PUBLIC_KEY_DESC",
  UsernameAsc = "USERNAME_ASC",
  UsernameDesc = "USERNAME_DESC",
}

export type PublicKey = Node & {
  __typename?: "PublicKey";
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  id: Scalars["ID"]["output"];
  publicKey: Scalars["String"]["output"];
};

/**
 * A condition to be used against `PublicKey` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type PublicKeyCondition = {
  /** Checks for equality with the object’s `publicKey` field. */
  publicKey?: InputMaybe<Scalars["String"]["input"]>;
};

/** A filter to be used against `PublicKey` object types. All fields are combined with a logical ‘and.’ */
export type PublicKeyFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<PublicKeyFilter>>;
  /** Negates the expression. */
  not?: InputMaybe<PublicKeyFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<PublicKeyFilter>>;
  /** Filter by the object’s `publicKey` field. */
  publicKey?: InputMaybe<StringFilter>;
};

/** A connection to a list of `PublicKey` values. */
export type PublicKeysConnection = {
  __typename?: "PublicKeysConnection";
  /** A list of edges which contains the `PublicKey` and cursor to aid in pagination. */
  edges: Array<PublicKeysEdge>;
  /** A list of `PublicKey` objects. */
  nodes: Array<Maybe<PublicKey>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `PublicKey` you could get from the connection. */
  totalCount: Scalars["Int"]["output"];
};

/** A `PublicKey` edge in the connection. */
export type PublicKeysEdge = {
  __typename?: "PublicKeysEdge";
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars["Cursor"]["output"]>;
  /** The `PublicKey` at the end of the edge. */
  node?: Maybe<PublicKey>;
};

/** Methods to use when ordering `PublicKey`. */
export enum PublicKeysOrderBy {
  Natural = "NATURAL",
  PrimaryKeyAsc = "PRIMARY_KEY_ASC",
  PrimaryKeyDesc = "PRIMARY_KEY_DESC",
  PublicKeyAsc = "PUBLIC_KEY_ASC",
  PublicKeyDesc = "PUBLIC_KEY_DESC",
}

/** The root query type which gives access points into the data universe. */
export type Query = Node & {
  __typename?: "Query";
  accessGroupByAccessGroupOwnerPublicKeyAndAccessGroupKeyName?: Maybe<AccessGroup>;
  accessGroupByAccessGroupPublicKey?: Maybe<AccessGroup>;
  /** Reads a single `AccessGroup` using its globally unique `ID`. */
  accessGroupById?: Maybe<AccessGroup>;
  accessGroupMemberByAccessGroupOwnerPublicKeyAndAccessGroupMemberPublicKeyAndAccessGroupKeyNameAndAccessGroupMemberKeyName?: Maybe<AccessGroupMember>;
  /** Reads a single `AccessGroupMember` using its globally unique `ID`. */
  accessGroupMemberById?: Maybe<AccessGroupMember>;
  /** Reads and enables pagination through a set of `AccessGroupMember`. */
  accessGroupMembers?: Maybe<AccessGroupMembersConnection>;
  /** Reads and enables pagination through a set of `AccessGroup`. */
  accessGroups?: Maybe<AccessGroupsConnection>;
  account?: Maybe<Account>;
  /** Reads a single `Account` using its globally unique `ID`. */
  accountById?: Maybe<Account>;
  accountByPkid?: Maybe<Account>;
  accountByPublicKey?: Maybe<Account>;
  accountByUsername?: Maybe<Account>;
  /** Reads and enables pagination through a set of `Account`. */
  accounts?: Maybe<AccountsConnection>;
  affectedPublicKey?: Maybe<AffectedPublicKey>;
  /** Reads a single `AffectedPublicKey` using its globally unique `ID`. */
  affectedPublicKeyById?: Maybe<AffectedPublicKey>;
  /** Reads and enables pagination through a set of `AffectedPublicKey`. */
  affectedPublicKeys?: Maybe<AffectedPublicKeysConnection>;
  block?: Maybe<Block>;
  blockByBlockHash?: Maybe<Block>;
  blockByHeight?: Maybe<Block>;
  /** Reads a single `Block` using its globally unique `ID`. */
  blockById?: Maybe<Block>;
  blockSigner?: Maybe<BlockSigner>;
  /** Reads a single `BlockSigner` using its globally unique `ID`. */
  blockSignerById?: Maybe<BlockSigner>;
  /** Reads and enables pagination through a set of `BlockSigner`. */
  blockSigners?: Maybe<BlockSignersConnection>;
  /** Reads and enables pagination through a set of `Block`. */
  blocks?: Maybe<BlocksConnection>;
  /** Reads and enables pagination through a set of `BlsPublicKeyPkidPairEntry`. */
  blsPublicKeyPkidPairEntries?: Maybe<BlsPublicKeyPkidPairEntriesConnection>;
  blsPublicKeyPkidPairEntry?: Maybe<BlsPublicKeyPkidPairEntry>;
  /** Reads a single `BlsPublicKeyPkidPairEntry` using its globally unique `ID`. */
  blsPublicKeyPkidPairEntryById?: Maybe<BlsPublicKeyPkidPairEntry>;
  /** Reads and enables pagination through a set of `BlsPublicKeyPkidPairSnapshotEntry`. */
  blsPublicKeyPkidPairSnapshotEntries?: Maybe<BlsPublicKeyPkidPairSnapshotEntriesConnection>;
  /** Reads a single `BlsPublicKeyPkidPairSnapshotEntry` using its globally unique `ID`. */
  blsPublicKeyPkidPairSnapshotEntryById?: Maybe<BlsPublicKeyPkidPairSnapshotEntry>;
  creatorCoinBalance?: Maybe<CreatorCoinBalance>;
  /** Reads a single `CreatorCoinBalance` using its globally unique `ID`. */
  creatorCoinBalanceById?: Maybe<CreatorCoinBalance>;
  /** Reads and enables pagination through a set of `CreatorCoinBalance`. */
  creatorCoinBalances?: Maybe<CreatorCoinBalancesConnection>;
  /** Reads and enables pagination through a set of `DailyActiveWalletCountStat`. */
  dailyActiveWalletCountStats?: Maybe<DailyActiveWalletCountStatsConnection>;
  /** Reads and enables pagination through a set of `DailyNewWalletCountStat`. */
  dailyNewWalletCountStats?: Maybe<DailyNewWalletCountStatsConnection>;
  /** Reads and enables pagination through a set of `DailyTxnCountStat`. */
  dailyTxnCountStats?: Maybe<DailyTxnCountStatsConnection>;
  /** Reads and enables pagination through a set of `DashboardStat`. */
  dashboardStats?: Maybe<DashboardStatsConnection>;
  /** Reads and enables pagination through a set of `DefiLeaderboardStat`. */
  defiLeaderboardStats?: Maybe<DefiLeaderboardStatsConnection>;
  /** Reads a single `DerivedKey` using its globally unique `ID`. */
  derivedKeyById?: Maybe<DerivedKey>;
  /** Reads and enables pagination through a set of `DerivedKey`. */
  derivedKeys?: Maybe<DerivedKeysConnection>;
  desoBalance?: Maybe<DesoBalance>;
  /** Reads a single `DesoBalance` using its globally unique `ID`. */
  desoBalanceById?: Maybe<DesoBalance>;
  desoBalanceByPublicKey?: Maybe<DesoBalance>;
  /** Reads and enables pagination through a set of `DesoBalance`. */
  desoBalances?: Maybe<DesoBalancesConnection>;
  desoTokenLimitOrder?: Maybe<DesoTokenLimitOrder>;
  desoTokenLimitOrderBidAskBySellingCreatorPkidAndBuyingCreatorPkid?: Maybe<DesoTokenLimitOrderBidAsk>;
  /** Reads and enables pagination through a set of `DesoTokenLimitOrderBidAsk`. */
  desoTokenLimitOrderBidAsks?: Maybe<DesoTokenLimitOrderBidAsksConnection>;
  /** Reads a single `DesoTokenLimitOrder` using its globally unique `ID`. */
  desoTokenLimitOrderById?: Maybe<DesoTokenLimitOrder>;
  desoTokenLimitOrderByOrderId?: Maybe<DesoTokenLimitOrder>;
  /** Reads and enables pagination through a set of `DesoTokenLimitOrder`. */
  desoTokenLimitOrders?: Maybe<DesoTokenLimitOrdersConnection>;
  /** Reads a single `Diamond` using its globally unique `ID`. */
  diamondById?: Maybe<Diamond>;
  /** Reads and enables pagination through a set of `Diamond`. */
  diamonds?: Maybe<DiamondsConnection>;
  epochDetailsForBlockByBlockHash?: Maybe<EpochDetailsForBlock>;
  epochDetailsForBlockByEpochNumber?: Maybe<EpochDetailsForBlock>;
  /** Reads and enables pagination through a set of `EpochDetailsForBlock`. */
  epochDetailsForBlocks?: Maybe<EpochDetailsForBlocksConnection>;
  /** Reads and enables pagination through a set of `EpochEntry`. */
  epochEntries?: Maybe<EpochEntriesConnection>;
  epochEntry?: Maybe<EpochEntry>;
  /** Reads a single `EpochEntry` using its globally unique `ID`. */
  epochEntryById?: Maybe<EpochEntry>;
  /** Reads a single `Follow` using its globally unique `ID`. */
  followById?: Maybe<Follow>;
  /** Reads and enables pagination through a set of `Follow`. */
  follows?: Maybe<FollowsConnection>;
  /** Reads and enables pagination through a set of `GlobalParamsEntry`. */
  globalParamsEntries?: Maybe<GlobalParamsEntriesConnection>;
  /** Reads a single `GlobalParamsEntry` using its globally unique `ID`. */
  globalParamsEntryById?: Maybe<GlobalParamsEntry>;
  /** The root query type must be a `Node` to work well with Relay 1 mutations. This just resolves to `query`. */
  id: Scalars["ID"]["output"];
  jailedHistoryEvent?: Maybe<JailedHistoryEvent>;
  /** Reads a single `JailedHistoryEvent` using its globally unique `ID`. */
  jailedHistoryEventById?: Maybe<JailedHistoryEvent>;
  /** Reads and enables pagination through a set of `JailedHistoryEvent`. */
  jailedHistoryEvents?: Maybe<JailedHistoryEventsConnection>;
  /** Reads and enables pagination through a set of `LeaderScheduleEntry`. */
  leaderScheduleEntries?: Maybe<LeaderScheduleEntriesConnection>;
  /** Reads a single `LeaderScheduleEntry` using its globally unique `ID`. */
  leaderScheduleEntryById?: Maybe<LeaderScheduleEntry>;
  /** Reads a single `LegacyMessage` using its globally unique `ID`. */
  legacyMessageById?: Maybe<LegacyMessage>;
  /** Reads and enables pagination through a set of `LegacyMessage`. */
  legacyMessages?: Maybe<LegacyMessagesConnection>;
  /** Reads a single `Like` using its globally unique `ID`. */
  likeById?: Maybe<Like>;
  /** Reads and enables pagination through a set of `Like`. */
  likes?: Maybe<LikesConnection>;
  /** Reads and enables pagination through a set of `LockedBalanceEntry`. */
  lockedBalanceEntries?: Maybe<LockedBalanceEntriesConnection>;
  /** Reads a single `LockedBalanceEntry` using its globally unique `ID`. */
  lockedBalanceEntryById?: Maybe<LockedBalanceEntry>;
  /** Reads and enables pagination through a set of `LockedStakeEntry`. */
  lockedStakeEntries?: Maybe<LockedStakeEntriesConnection>;
  /** Reads a single `LockedStakeEntry` using its globally unique `ID`. */
  lockedStakeEntryById?: Maybe<LockedStakeEntry>;
  /** Reads a single `Message` using its globally unique `ID`. */
  messageById?: Maybe<Message>;
  /** Reads and enables pagination through a set of `Message`. */
  messages?: Maybe<MessagesConnection>;
  /** Reads and enables pagination through a set of `MonthlyNewWalletCountStat`. */
  monthlyNewWalletCountStats?: Maybe<MonthlyNewWalletCountStatsConnection>;
  /** Reads and enables pagination through a set of `MonthlyTxnCountStat`. */
  monthlyTxnCountStats?: Maybe<MonthlyTxnCountStatsConnection>;
  /** Reads and enables pagination through a set of `MyStakeSummary`. */
  myStakeSummaries?: Maybe<MyStakeSummariesConnection>;
  myStakeSummaryByStakerPkid?: Maybe<MyStakeSummary>;
  /** Reads a single `NftBid` using its globally unique `ID`. */
  nftBidById?: Maybe<NftBid>;
  /** Reads and enables pagination through a set of `NftBid`. */
  nftBids?: Maybe<NftBidsConnection>;
  /** Reads a single `Nft` using its globally unique `ID`. */
  nftById?: Maybe<Nft>;
  /** Reads and enables pagination through a set of `NftLeaderboardStat`. */
  nftLeaderboardStats?: Maybe<NftLeaderboardStatsConnection>;
  /** Reads and enables pagination through a set of `Nft`. */
  nfts?: Maybe<NftsConnection>;
  /** Fetches an object given its globally unique `ID`. */
  node?: Maybe<Node>;
  post?: Maybe<Post>;
  postAssociationByAssociationId?: Maybe<PostAssociation>;
  /** Reads a single `PostAssociation` using its globally unique `ID`. */
  postAssociationById?: Maybe<PostAssociation>;
  postAssociationByTransactorPkidAndPostHashAndAppPkidAndAssociationTypeAndAssociationValue?: Maybe<PostAssociation>;
  /** Reads and enables pagination through a set of `PostAssociation`. */
  postAssociations?: Maybe<PostAssociationsConnection>;
  /** Reads a single `Post` using its globally unique `ID`. */
  postById?: Maybe<Post>;
  postByPostHash?: Maybe<Post>;
  /** Reads and enables pagination through a set of `Post`. */
  posts?: Maybe<PostsConnection>;
  profile?: Maybe<Profile>;
  /** Reads a single `Profile` using its globally unique `ID`. */
  profileById?: Maybe<Profile>;
  profileByUsername?: Maybe<Profile>;
  profileEarningsBreakdownStatByPublicKey?: Maybe<ProfileEarningsBreakdownStat>;
  profileEarningsStatByPublicKey?: Maybe<ProfileEarningsStat>;
  /** Reads and enables pagination through a set of `ProfileNftTopOwner`. */
  profileNftTopOwners?: Maybe<ProfileNftTopOwnersConnection>;
  profilePortfolioValueStatByPublicKey?: Maybe<ProfilePortfolioValueStat>;
  profileTransactionStatByPublicKey?: Maybe<ProfileTransactionStat>;
  /** Reads and enables pagination through a set of `ProfileTransactionStat`. */
  profileTransactionStats?: Maybe<ProfileTransactionStatsConnection>;
  /** Reads and enables pagination through a set of `Profile`. */
  profiles?: Maybe<ProfilesConnection>;
  publicKey?: Maybe<PublicKey>;
  /** Reads a single `PublicKey` using its globally unique `ID`. */
  publicKeyById?: Maybe<PublicKey>;
  /** Reads and enables pagination through a set of `PublicKey`. */
  publicKeys?: Maybe<PublicKeysConnection>;
  /**
   * Exposes the root query type nested one level down. This is helpful for Relay 1
   * which can only query top level fields if they are in a particular form.
   */
  query: Query;
  /** Reads and enables pagination through a set of `SnapshotValidatorEntry`. */
  snapshotValidatorEntries?: Maybe<SnapshotValidatorEntriesConnection>;
  snapshotValidatorEntry?: Maybe<SnapshotValidatorEntry>;
  /** Reads a single `SnapshotValidatorEntry` using its globally unique `ID`. */
  snapshotValidatorEntryById?: Maybe<SnapshotValidatorEntry>;
  /** Reads and enables pagination through a set of `SocialLeaderboardStat`. */
  socialLeaderboardStats?: Maybe<SocialLeaderboardStatsConnection>;
  /** Reads and enables pagination through a set of `StakeEntry`. */
  stakeEntries?: Maybe<StakeEntriesConnection>;
  /** Reads a single `StakeEntry` using its globally unique `ID`. */
  stakeEntryById?: Maybe<StakeEntry>;
  stakeReward?: Maybe<StakeReward>;
  /** Reads a single `StakeReward` using its globally unique `ID`. */
  stakeRewardById?: Maybe<StakeReward>;
  /** Reads and enables pagination through a set of `StakeReward`. */
  stakeRewards?: Maybe<StakeRewardsConnection>;
  stakeRewardsByValidatorAndStaker?: Maybe<StakeRewardsByValidatorAndStaker>;
  /** Reads a single `StakeRewardsByValidatorAndStaker` using its globally unique `ID`. */
  stakeRewardsByValidatorAndStakerById?: Maybe<StakeRewardsByValidatorAndStaker>;
  /** Reads and enables pagination through a set of `StakeRewardsByValidatorAndStaker`. */
  stakeRewardsByValidatorAndStakers?: Maybe<StakeRewardsByValidatorAndStakersConnection>;
  /** Reads and enables pagination through a set of `StakingSummary`. */
  stakingSummaries?: Maybe<StakingSummariesConnection>;
  /** Reads a single `TokenBalance` using its globally unique `ID`. */
  tokenBalanceById?: Maybe<TokenBalance>;
  /** Reads and enables pagination through a set of `TokenBalance`. */
  tokenBalances?: Maybe<TokenBalancesConnection>;
  transactionByTransactionHash?: Maybe<Transaction>;
  transactionType?: Maybe<TransactionType>;
  /** Reads a single `TransactionType` using its globally unique `ID`. */
  transactionTypeById?: Maybe<TransactionType>;
  /** Reads and enables pagination through a set of `TransactionType`. */
  transactionTypes?: Maybe<TransactionTypesConnection>;
  /** Reads and enables pagination through a set of `Transaction`. */
  transactions?: Maybe<TransactionsConnection>;
  userAssociationByAssociationId?: Maybe<UserAssociation>;
  /** Reads a single `UserAssociation` using its globally unique `ID`. */
  userAssociationById?: Maybe<UserAssociation>;
  userAssociationByTransactorPkidAndTargetUserPkidAndAppPkidAndAssociationTypeAndAssociationValue?: Maybe<UserAssociation>;
  /** Reads and enables pagination through a set of `UserAssociation`. */
  userAssociations?: Maybe<UserAssociationsConnection>;
  /** Reads and enables pagination through a set of `UtxoOperation`. */
  utxoOperations?: Maybe<UtxoOperationsConnection>;
  /** Reads and enables pagination through a set of `ValidatorEntry`. */
  validatorEntries?: Maybe<ValidatorEntriesConnection>;
  /** Reads a single `ValidatorEntry` using its globally unique `ID`. */
  validatorEntryById?: Maybe<ValidatorEntry>;
  validatorEntryByValidatorPkid?: Maybe<ValidatorEntry>;
  validatorStat?: Maybe<ValidatorStat>;
  /** Reads a single `ValidatorStat` using its globally unique `ID`. */
  validatorStatById?: Maybe<ValidatorStat>;
  validatorStatByValidatorRank?: Maybe<ValidatorStat>;
  /** Reads and enables pagination through a set of `ValidatorStat`. */
  validatorStats?: Maybe<ValidatorStatsConnection>;
  /** Reads a single `YieldCurvePoint` using its globally unique `ID`. */
  yieldCurvePointById?: Maybe<YieldCurvePoint>;
  /** Reads and enables pagination through a set of `YieldCurvePoint`. */
  yieldCurvePoints?: Maybe<YieldCurvePointsConnection>;
};

/** The root query type which gives access points into the data universe. */
export type QueryAccessGroupByAccessGroupOwnerPublicKeyAndAccessGroupKeyNameArgs =
  {
    accessGroupKeyName: Scalars["String"]["input"];
    accessGroupOwnerPublicKey: Scalars["String"]["input"];
  };

/** The root query type which gives access points into the data universe. */
export type QueryAccessGroupByAccessGroupPublicKeyArgs = {
  accessGroupPublicKey: Scalars["String"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QueryAccessGroupByIdArgs = {
  id: Scalars["ID"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QueryAccessGroupMemberByAccessGroupOwnerPublicKeyAndAccessGroupMemberPublicKeyAndAccessGroupKeyNameAndAccessGroupMemberKeyNameArgs =
  {
    accessGroupKeyName: Scalars["String"]["input"];
    accessGroupMemberKeyName: Scalars["String"]["input"];
    accessGroupMemberPublicKey: Scalars["String"]["input"];
    accessGroupOwnerPublicKey: Scalars["String"]["input"];
  };

/** The root query type which gives access points into the data universe. */
export type QueryAccessGroupMemberByIdArgs = {
  id: Scalars["ID"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QueryAccessGroupMembersArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<AccessGroupMemberCondition>;
  filter?: InputMaybe<AccessGroupMemberFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<AccessGroupMembersOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryAccessGroupsArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<AccessGroupCondition>;
  filter?: InputMaybe<AccessGroupFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<AccessGroupsOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryAccountArgs = {
  publicKey: Scalars["String"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QueryAccountByIdArgs = {
  id: Scalars["ID"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QueryAccountByPkidArgs = {
  pkid: Scalars["String"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QueryAccountByPublicKeyArgs = {
  publicKey: Scalars["String"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QueryAccountByUsernameArgs = {
  username: Scalars["String"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QueryAccountsArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<AccountCondition>;
  filter?: InputMaybe<AccountFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<AccountsOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryAffectedPublicKeyArgs = {
  metadata: Scalars["String"]["input"];
  publicKey: Scalars["String"]["input"];
  transactionHash: Scalars["String"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QueryAffectedPublicKeyByIdArgs = {
  id: Scalars["ID"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QueryAffectedPublicKeysArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<AffectedPublicKeyCondition>;
  filter?: InputMaybe<AffectedPublicKeyFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<AffectedPublicKeysOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryBlockArgs = {
  blockHash: Scalars["String"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QueryBlockByBlockHashArgs = {
  blockHash: Scalars["String"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QueryBlockByHeightArgs = {
  height: Scalars["BigInt"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QueryBlockByIdArgs = {
  id: Scalars["ID"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QueryBlockSignerArgs = {
  blockHash: Scalars["String"]["input"];
  signerIndex: Scalars["BigInt"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QueryBlockSignerByIdArgs = {
  id: Scalars["ID"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QueryBlockSignersArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<BlockSignerCondition>;
  filter?: InputMaybe<BlockSignerFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<BlockSignersOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryBlocksArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<BlockCondition>;
  filter?: InputMaybe<BlockFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<BlocksOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryBlsPublicKeyPkidPairEntriesArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<BlsPublicKeyPkidPairEntryCondition>;
  filter?: InputMaybe<BlsPublicKeyPkidPairEntryFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<BlsPublicKeyPkidPairEntriesOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryBlsPublicKeyPkidPairEntryArgs = {
  badgerKey: Scalars["String"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QueryBlsPublicKeyPkidPairEntryByIdArgs = {
  id: Scalars["ID"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QueryBlsPublicKeyPkidPairSnapshotEntriesArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<BlsPublicKeyPkidPairSnapshotEntryCondition>;
  filter?: InputMaybe<BlsPublicKeyPkidPairSnapshotEntryFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<BlsPublicKeyPkidPairSnapshotEntriesOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryBlsPublicKeyPkidPairSnapshotEntryByIdArgs = {
  id: Scalars["ID"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QueryCreatorCoinBalanceArgs = {
  creatorPkid: Scalars["String"]["input"];
  hodlerPkid: Scalars["String"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QueryCreatorCoinBalanceByIdArgs = {
  id: Scalars["ID"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QueryCreatorCoinBalancesArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<CreatorCoinBalanceCondition>;
  filter?: InputMaybe<CreatorCoinBalanceFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<CreatorCoinBalancesOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryDailyActiveWalletCountStatsArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<DailyActiveWalletCountStatCondition>;
  filter?: InputMaybe<DailyActiveWalletCountStatFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<DailyActiveWalletCountStatsOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryDailyNewWalletCountStatsArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<DailyNewWalletCountStatCondition>;
  filter?: InputMaybe<DailyNewWalletCountStatFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<DailyNewWalletCountStatsOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryDailyTxnCountStatsArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<DailyTxnCountStatCondition>;
  filter?: InputMaybe<DailyTxnCountStatFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<DailyTxnCountStatsOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryDashboardStatsArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<DashboardStatCondition>;
  filter?: InputMaybe<DashboardStatFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<DashboardStatsOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryDefiLeaderboardStatsArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<DefiLeaderboardStatCondition>;
  filter?: InputMaybe<DefiLeaderboardStatFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<DefiLeaderboardStatsOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryDerivedKeyByIdArgs = {
  id: Scalars["ID"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QueryDerivedKeysArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<DerivedKeyCondition>;
  filter?: InputMaybe<DerivedKeyFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<DerivedKeysOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryDesoBalanceArgs = {
  publicKey: Scalars["String"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QueryDesoBalanceByIdArgs = {
  id: Scalars["ID"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QueryDesoBalanceByPublicKeyArgs = {
  publicKey: Scalars["String"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QueryDesoBalancesArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<DesoBalanceCondition>;
  filter?: InputMaybe<DesoBalanceFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<DesoBalancesOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryDesoTokenLimitOrderArgs = {
  badgerKey: Scalars["String"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QueryDesoTokenLimitOrderBidAskBySellingCreatorPkidAndBuyingCreatorPkidArgs =
  {
    buyingCreatorPkid: Scalars["String"]["input"];
    sellingCreatorPkid: Scalars["String"]["input"];
  };

/** The root query type which gives access points into the data universe. */
export type QueryDesoTokenLimitOrderBidAsksArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<DesoTokenLimitOrderBidAskCondition>;
  filter?: InputMaybe<DesoTokenLimitOrderBidAskFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<DesoTokenLimitOrderBidAsksOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryDesoTokenLimitOrderByIdArgs = {
  id: Scalars["ID"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QueryDesoTokenLimitOrderByOrderIdArgs = {
  orderId: Scalars["String"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QueryDesoTokenLimitOrdersArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<DesoTokenLimitOrderCondition>;
  filter?: InputMaybe<DesoTokenLimitOrderFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<DesoTokenLimitOrdersOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryDiamondByIdArgs = {
  id: Scalars["ID"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QueryDiamondsArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<DiamondCondition>;
  filter?: InputMaybe<DiamondFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<DiamondsOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryEpochDetailsForBlockByBlockHashArgs = {
  blockHash: Scalars["String"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QueryEpochDetailsForBlockByEpochNumberArgs = {
  epochNumber: Scalars["BigInt"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QueryEpochDetailsForBlocksArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<EpochDetailsForBlockCondition>;
  filter?: InputMaybe<EpochDetailsForBlockFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<EpochDetailsForBlocksOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryEpochEntriesArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<EpochEntryCondition>;
  filter?: InputMaybe<EpochEntryFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<EpochEntriesOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryEpochEntryArgs = {
  epochNumber: Scalars["BigInt"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QueryEpochEntryByIdArgs = {
  id: Scalars["ID"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QueryFollowByIdArgs = {
  id: Scalars["ID"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QueryFollowsArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<FollowCondition>;
  filter?: InputMaybe<FollowFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<FollowsOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryGlobalParamsEntriesArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<GlobalParamsEntryCondition>;
  filter?: InputMaybe<GlobalParamsEntryFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<GlobalParamsEntriesOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryGlobalParamsEntryByIdArgs = {
  id: Scalars["ID"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QueryJailedHistoryEventArgs = {
  jailedAtEpochNumber: Scalars["BigInt"]["input"];
  unjailedAtEpochNumber: Scalars["BigInt"]["input"];
  validatorPkid: Scalars["String"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QueryJailedHistoryEventByIdArgs = {
  id: Scalars["ID"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QueryJailedHistoryEventsArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<JailedHistoryEventCondition>;
  filter?: InputMaybe<JailedHistoryEventFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<JailedHistoryEventsOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryLeaderScheduleEntriesArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<LeaderScheduleEntryCondition>;
  filter?: InputMaybe<LeaderScheduleEntryFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<LeaderScheduleEntriesOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryLeaderScheduleEntryByIdArgs = {
  id: Scalars["ID"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QueryLegacyMessageByIdArgs = {
  id: Scalars["ID"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QueryLegacyMessagesArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<LegacyMessageCondition>;
  filter?: InputMaybe<LegacyMessageFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<LegacyMessagesOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryLikeByIdArgs = {
  id: Scalars["ID"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QueryLikesArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<LikeCondition>;
  filter?: InputMaybe<LikeFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<LikesOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryLockedBalanceEntriesArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<LockedBalanceEntryCondition>;
  filter?: InputMaybe<LockedBalanceEntryFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<LockedBalanceEntriesOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryLockedBalanceEntryByIdArgs = {
  id: Scalars["ID"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QueryLockedStakeEntriesArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<LockedStakeEntryCondition>;
  filter?: InputMaybe<LockedStakeEntryFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<LockedStakeEntriesOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryLockedStakeEntryByIdArgs = {
  id: Scalars["ID"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QueryMessageByIdArgs = {
  id: Scalars["ID"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QueryMessagesArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<MessageCondition>;
  filter?: InputMaybe<MessageFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<MessagesOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryMonthlyNewWalletCountStatsArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<MonthlyNewWalletCountStatCondition>;
  filter?: InputMaybe<MonthlyNewWalletCountStatFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<MonthlyNewWalletCountStatsOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryMonthlyTxnCountStatsArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<MonthlyTxnCountStatCondition>;
  filter?: InputMaybe<MonthlyTxnCountStatFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<MonthlyTxnCountStatsOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryMyStakeSummariesArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<MyStakeSummaryCondition>;
  filter?: InputMaybe<MyStakeSummaryFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<MyStakeSummariesOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryMyStakeSummaryByStakerPkidArgs = {
  stakerPkid: Scalars["String"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QueryNftBidByIdArgs = {
  id: Scalars["ID"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QueryNftBidsArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<NftBidCondition>;
  filter?: InputMaybe<NftBidFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<NftBidsOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryNftByIdArgs = {
  id: Scalars["ID"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QueryNftLeaderboardStatsArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<NftLeaderboardStatCondition>;
  filter?: InputMaybe<NftLeaderboardStatFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<NftLeaderboardStatsOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryNftsArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<NftCondition>;
  filter?: InputMaybe<NftFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<NftsOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryNodeArgs = {
  id: Scalars["ID"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QueryPostArgs = {
  postHash: Scalars["String"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QueryPostAssociationByAssociationIdArgs = {
  associationId: Scalars["String"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QueryPostAssociationByIdArgs = {
  id: Scalars["ID"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QueryPostAssociationByTransactorPkidAndPostHashAndAppPkidAndAssociationTypeAndAssociationValueArgs =
  {
    appPkid: Scalars["String"]["input"];
    associationType: Scalars["String"]["input"];
    associationValue: Scalars["String"]["input"];
    postHash: Scalars["String"]["input"];
    transactorPkid: Scalars["String"]["input"];
  };

/** The root query type which gives access points into the data universe. */
export type QueryPostAssociationsArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<PostAssociationCondition>;
  filter?: InputMaybe<PostAssociationFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<PostAssociationsOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryPostByIdArgs = {
  id: Scalars["ID"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QueryPostByPostHashArgs = {
  postHash: Scalars["String"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QueryPostsArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<PostCondition>;
  filter?: InputMaybe<PostFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<PostsOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryProfileArgs = {
  publicKey: Scalars["String"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QueryProfileByIdArgs = {
  id: Scalars["ID"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QueryProfileByUsernameArgs = {
  username: Scalars["String"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QueryProfileEarningsBreakdownStatByPublicKeyArgs = {
  publicKey: Scalars["String"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QueryProfileEarningsStatByPublicKeyArgs = {
  publicKey: Scalars["String"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QueryProfileNftTopOwnersArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<ProfileNftTopOwnerCondition>;
  filter?: InputMaybe<ProfileNftTopOwnerFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<ProfileNftTopOwnersOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryProfilePortfolioValueStatByPublicKeyArgs = {
  publicKey: Scalars["String"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QueryProfileTransactionStatByPublicKeyArgs = {
  publicKey: Scalars["String"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QueryProfileTransactionStatsArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<ProfileTransactionStatCondition>;
  filter?: InputMaybe<ProfileTransactionStatFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<ProfileTransactionStatsOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryProfilesArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<ProfileCondition>;
  filter?: InputMaybe<ProfileFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<ProfilesOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryPublicKeyArgs = {
  publicKey: Scalars["String"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QueryPublicKeyByIdArgs = {
  id: Scalars["ID"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QueryPublicKeysArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<PublicKeyCondition>;
  filter?: InputMaybe<PublicKeyFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<PublicKeysOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QuerySnapshotValidatorEntriesArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<SnapshotValidatorEntryCondition>;
  filter?: InputMaybe<SnapshotValidatorEntryFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<SnapshotValidatorEntriesOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QuerySnapshotValidatorEntryArgs = {
  badgerKey: Scalars["String"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QuerySnapshotValidatorEntryByIdArgs = {
  id: Scalars["ID"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QuerySocialLeaderboardStatsArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<SocialLeaderboardStatCondition>;
  filter?: InputMaybe<SocialLeaderboardStatFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<SocialLeaderboardStatsOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryStakeEntriesArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<StakeEntryCondition>;
  filter?: InputMaybe<StakeEntryFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<StakeEntriesOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryStakeEntryByIdArgs = {
  id: Scalars["ID"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QueryStakeRewardArgs = {
  blockHash: Scalars["String"]["input"];
  utxoOpIndex: Scalars["BigInt"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QueryStakeRewardByIdArgs = {
  id: Scalars["ID"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QueryStakeRewardsArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<StakeRewardCondition>;
  filter?: InputMaybe<StakeRewardFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<StakeRewardsOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryStakeRewardsByValidatorAndStakerArgs = {
  stakerPkid: Scalars["String"]["input"];
  validatorPkid: Scalars["String"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QueryStakeRewardsByValidatorAndStakerByIdArgs = {
  id: Scalars["ID"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QueryStakeRewardsByValidatorAndStakersArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<StakeRewardsByValidatorAndStakerCondition>;
  filter?: InputMaybe<StakeRewardsByValidatorAndStakerFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<StakeRewardsByValidatorAndStakersOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryStakingSummariesArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<StakingSummaryCondition>;
  filter?: InputMaybe<StakingSummaryFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<StakingSummariesOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryTokenBalanceByIdArgs = {
  id: Scalars["ID"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QueryTokenBalancesArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<TokenBalanceCondition>;
  filter?: InputMaybe<TokenBalanceFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<TokenBalancesOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryTransactionByTransactionHashArgs = {
  transactionHash: Scalars["String"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QueryTransactionTypeArgs = {
  type: Scalars["Int"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QueryTransactionTypeByIdArgs = {
  id: Scalars["ID"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QueryTransactionTypesArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<TransactionTypeCondition>;
  filter?: InputMaybe<TransactionTypeFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<TransactionTypesOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryTransactionsArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<TransactionCondition>;
  filter?: InputMaybe<TransactionFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<TransactionsOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryUserAssociationByAssociationIdArgs = {
  associationId: Scalars["String"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QueryUserAssociationByIdArgs = {
  id: Scalars["ID"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QueryUserAssociationByTransactorPkidAndTargetUserPkidAndAppPkidAndAssociationTypeAndAssociationValueArgs =
  {
    appPkid: Scalars["String"]["input"];
    associationType: Scalars["String"]["input"];
    associationValue: Scalars["String"]["input"];
    targetUserPkid: Scalars["String"]["input"];
    transactorPkid: Scalars["String"]["input"];
  };

/** The root query type which gives access points into the data universe. */
export type QueryUserAssociationsArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<UserAssociationCondition>;
  filter?: InputMaybe<UserAssociationFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<UserAssociationsOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryUtxoOperationsArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<UtxoOperationCondition>;
  filter?: InputMaybe<UtxoOperationFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<UtxoOperationsOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryValidatorEntriesArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<ValidatorEntryCondition>;
  filter?: InputMaybe<ValidatorEntryFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<ValidatorEntriesOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryValidatorEntryByIdArgs = {
  id: Scalars["ID"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QueryValidatorEntryByValidatorPkidArgs = {
  validatorPkid: Scalars["String"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QueryValidatorStatArgs = {
  validatorPkid: Scalars["String"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QueryValidatorStatByIdArgs = {
  id: Scalars["ID"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QueryValidatorStatByValidatorRankArgs = {
  validatorRank: Scalars["BigInt"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QueryValidatorStatsArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<ValidatorStatCondition>;
  filter?: InputMaybe<ValidatorStatFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<ValidatorStatsOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryYieldCurvePointByIdArgs = {
  id: Scalars["ID"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QueryYieldCurvePointsArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<YieldCurvePointCondition>;
  filter?: InputMaybe<YieldCurvePointFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<YieldCurvePointsOrderBy>>;
};

/** A connection to a list of `SnapshotValidatorEntry` values. */
export type SnapshotValidatorEntriesConnection = {
  __typename?: "SnapshotValidatorEntriesConnection";
  /** A list of edges which contains the `SnapshotValidatorEntry` and cursor to aid in pagination. */
  edges: Array<SnapshotValidatorEntriesEdge>;
  /** A list of `SnapshotValidatorEntry` objects. */
  nodes: Array<Maybe<SnapshotValidatorEntry>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `SnapshotValidatorEntry` you could get from the connection. */
  totalCount: Scalars["Int"]["output"];
};

/** A `SnapshotValidatorEntry` edge in the connection. */
export type SnapshotValidatorEntriesEdge = {
  __typename?: "SnapshotValidatorEntriesEdge";
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars["Cursor"]["output"]>;
  /** The `SnapshotValidatorEntry` at the end of the edge. */
  node?: Maybe<SnapshotValidatorEntry>;
};

/** Methods to use when ordering `SnapshotValidatorEntry`. */
export enum SnapshotValidatorEntriesOrderBy {
  BadgerKeyAsc = "BADGER_KEY_ASC",
  BadgerKeyDesc = "BADGER_KEY_DESC",
  DelegatedStakeCommissionBasisPointsAsc = "DELEGATED_STAKE_COMMISSION_BASIS_POINTS_ASC",
  DelegatedStakeCommissionBasisPointsDesc = "DELEGATED_STAKE_COMMISSION_BASIS_POINTS_DESC",
  DisableDelegatedStakeAsc = "DISABLE_DELEGATED_STAKE_ASC",
  DisableDelegatedStakeDesc = "DISABLE_DELEGATED_STAKE_DESC",
  DomainsAsc = "DOMAINS_ASC",
  DomainsDesc = "DOMAINS_DESC",
  ExtraDataAsc = "EXTRA_DATA_ASC",
  ExtraDataDesc = "EXTRA_DATA_DESC",
  JailedAtEpochNumberAsc = "JAILED_AT_EPOCH_NUMBER_ASC",
  JailedAtEpochNumberDesc = "JAILED_AT_EPOCH_NUMBER_DESC",
  LastActiveAtEpochNumberAsc = "LAST_ACTIVE_AT_EPOCH_NUMBER_ASC",
  LastActiveAtEpochNumberDesc = "LAST_ACTIVE_AT_EPOCH_NUMBER_DESC",
  Natural = "NATURAL",
  PrimaryKeyAsc = "PRIMARY_KEY_ASC",
  PrimaryKeyDesc = "PRIMARY_KEY_DESC",
  SnapshotAtEpochNumberAsc = "SNAPSHOT_AT_EPOCH_NUMBER_ASC",
  SnapshotAtEpochNumberDesc = "SNAPSHOT_AT_EPOCH_NUMBER_DESC",
  TotalStakeAmountNanosAsc = "TOTAL_STAKE_AMOUNT_NANOS_ASC",
  TotalStakeAmountNanosDesc = "TOTAL_STAKE_AMOUNT_NANOS_DESC",
  ValidatorPkidAsc = "VALIDATOR_PKID_ASC",
  ValidatorPkidDesc = "VALIDATOR_PKID_DESC",
  VotingAuthorizationAsc = "VOTING_AUTHORIZATION_ASC",
  VotingAuthorizationDesc = "VOTING_AUTHORIZATION_DESC",
  VotingPublicKeyAsc = "VOTING_PUBLIC_KEY_ASC",
  VotingPublicKeyDesc = "VOTING_PUBLIC_KEY_DESC",
}

export type SnapshotValidatorEntry = Node & {
  __typename?: "SnapshotValidatorEntry";
  badgerKey: Scalars["String"]["output"];
  delegatedStakeCommissionBasisPoints?: Maybe<Scalars["BigInt"]["output"]>;
  disableDelegatedStake?: Maybe<Scalars["Boolean"]["output"]>;
  domains?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  extraData?: Maybe<Scalars["JSON"]["output"]>;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  id: Scalars["ID"]["output"];
  jailedAtEpochNumber?: Maybe<Scalars["BigInt"]["output"]>;
  lastActiveAtEpochNumber?: Maybe<Scalars["BigInt"]["output"]>;
  snapshotAtEpochNumber: Scalars["BigInt"]["output"];
  totalStakeAmountNanos: Scalars["BigFloat"]["output"];
  validatorPkid: Scalars["String"]["output"];
  votingAuthorization?: Maybe<Scalars["String"]["output"]>;
  votingPublicKey?: Maybe<Scalars["String"]["output"]>;
};

/**
 * A condition to be used against `SnapshotValidatorEntry` object types. All fields
 * are tested for equality and combined with a logical ‘and.’
 */
export type SnapshotValidatorEntryCondition = {
  /** Checks for equality with the object’s `badgerKey` field. */
  badgerKey?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `delegatedStakeCommissionBasisPoints` field. */
  delegatedStakeCommissionBasisPoints?: InputMaybe<Scalars["BigInt"]["input"]>;
  /** Checks for equality with the object’s `disableDelegatedStake` field. */
  disableDelegatedStake?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Checks for equality with the object’s `domains` field. */
  domains?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** Checks for equality with the object’s `extraData` field. */
  extraData?: InputMaybe<Scalars["JSON"]["input"]>;
  /** Checks for equality with the object’s `jailedAtEpochNumber` field. */
  jailedAtEpochNumber?: InputMaybe<Scalars["BigInt"]["input"]>;
  /** Checks for equality with the object’s `lastActiveAtEpochNumber` field. */
  lastActiveAtEpochNumber?: InputMaybe<Scalars["BigInt"]["input"]>;
  /** Checks for equality with the object’s `snapshotAtEpochNumber` field. */
  snapshotAtEpochNumber?: InputMaybe<Scalars["BigInt"]["input"]>;
  /** Checks for equality with the object’s `totalStakeAmountNanos` field. */
  totalStakeAmountNanos?: InputMaybe<Scalars["BigFloat"]["input"]>;
  /** Checks for equality with the object’s `validatorPkid` field. */
  validatorPkid?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `votingAuthorization` field. */
  votingAuthorization?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `votingPublicKey` field. */
  votingPublicKey?: InputMaybe<Scalars["String"]["input"]>;
};

/** A filter to be used against `SnapshotValidatorEntry` object types. All fields are combined with a logical ‘and.’ */
export type SnapshotValidatorEntryFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<SnapshotValidatorEntryFilter>>;
  /** Filter by the object’s `delegatedStakeCommissionBasisPoints` field. */
  delegatedStakeCommissionBasisPoints?: InputMaybe<BigIntFilter>;
  /** Filter by the object’s `disableDelegatedStake` field. */
  disableDelegatedStake?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `domains` field. */
  domains?: InputMaybe<StringListFilter>;
  /** Filter by the object’s `extraData` field. */
  extraData?: InputMaybe<JsonFilter>;
  /** Filter by the object’s `jailedAtEpochNumber` field. */
  jailedAtEpochNumber?: InputMaybe<BigIntFilter>;
  /** Filter by the object’s `lastActiveAtEpochNumber` field. */
  lastActiveAtEpochNumber?: InputMaybe<BigIntFilter>;
  /** Negates the expression. */
  not?: InputMaybe<SnapshotValidatorEntryFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<SnapshotValidatorEntryFilter>>;
  /** Filter by the object’s `snapshotAtEpochNumber` field. */
  snapshotAtEpochNumber?: InputMaybe<BigIntFilter>;
  /** Filter by the object’s `totalStakeAmountNanos` field. */
  totalStakeAmountNanos?: InputMaybe<BigFloatFilter>;
  /** Filter by the object’s `validatorPkid` field. */
  validatorPkid?: InputMaybe<StringFilter>;
  /** Filter by the object’s `votingAuthorization` field. */
  votingAuthorization?: InputMaybe<StringFilter>;
  /** Filter by the object’s `votingPublicKey` field. */
  votingPublicKey?: InputMaybe<StringFilter>;
};

export type SocialLeaderboardStat = {
  __typename?: "SocialLeaderboardStat";
  badgerKey?: Maybe<Scalars["String"]["output"]>;
  ccCoinsInCirculationNanos?: Maybe<Scalars["BigInt"]["output"]>;
  coinPriceDesoNanos?: Maybe<Scalars["BigFloat"]["output"]>;
  coinWatermarkNanos?: Maybe<Scalars["BigInt"]["output"]>;
  count?: Maybe<Scalars["BigFloat"]["output"]>;
  creatorBasisPoints?: Maybe<Scalars["BigInt"]["output"]>;
  daoCoinMintingDisabled?: Maybe<Scalars["Boolean"]["output"]>;
  daoCoinTransferRestrictionStatus?: Maybe<Scalars["Int"]["output"]>;
  daoCoinsInCirculationNanosHex?: Maybe<Scalars["String"]["output"]>;
  description?: Maybe<Scalars["String"]["output"]>;
  desoLockedNanos?: Maybe<Scalars["BigInt"]["output"]>;
  extraData?: Maybe<Scalars["JSON"]["output"]>;
  mintingDisabled?: Maybe<Scalars["Boolean"]["output"]>;
  pkid?: Maybe<Scalars["String"]["output"]>;
  profilePic?: Maybe<Scalars["String"]["output"]>;
  publicKey?: Maybe<Scalars["String"]["output"]>;
  rowId?: Maybe<Scalars["BigInt"]["output"]>;
  username?: Maybe<Scalars["String"]["output"]>;
};

/**
 * A condition to be used against `SocialLeaderboardStat` object types. All fields
 * are tested for equality and combined with a logical ‘and.’
 */
export type SocialLeaderboardStatCondition = {
  /** Checks for equality with the object’s `badgerKey` field. */
  badgerKey?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `ccCoinsInCirculationNanos` field. */
  ccCoinsInCirculationNanos?: InputMaybe<Scalars["BigInt"]["input"]>;
  /** Checks for equality with the object’s `coinPriceDesoNanos` field. */
  coinPriceDesoNanos?: InputMaybe<Scalars["BigFloat"]["input"]>;
  /** Checks for equality with the object’s `coinWatermarkNanos` field. */
  coinWatermarkNanos?: InputMaybe<Scalars["BigInt"]["input"]>;
  /** Checks for equality with the object’s `count` field. */
  count?: InputMaybe<Scalars["BigFloat"]["input"]>;
  /** Checks for equality with the object’s `creatorBasisPoints` field. */
  creatorBasisPoints?: InputMaybe<Scalars["BigInt"]["input"]>;
  /** Checks for equality with the object’s `daoCoinMintingDisabled` field. */
  daoCoinMintingDisabled?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Checks for equality with the object’s `daoCoinTransferRestrictionStatus` field. */
  daoCoinTransferRestrictionStatus?: InputMaybe<Scalars["Int"]["input"]>;
  /** Checks for equality with the object’s `daoCoinsInCirculationNanosHex` field. */
  daoCoinsInCirculationNanosHex?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `description` field. */
  description?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `desoLockedNanos` field. */
  desoLockedNanos?: InputMaybe<Scalars["BigInt"]["input"]>;
  /** Checks for equality with the object’s `extraData` field. */
  extraData?: InputMaybe<Scalars["JSON"]["input"]>;
  /** Checks for equality with the object’s `mintingDisabled` field. */
  mintingDisabled?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Checks for equality with the object’s `pkid` field. */
  pkid?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `profilePic` field. */
  profilePic?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `publicKey` field. */
  publicKey?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `rowId` field. */
  rowId?: InputMaybe<Scalars["BigInt"]["input"]>;
  /** Checks for equality with the object’s `username` field. */
  username?: InputMaybe<Scalars["String"]["input"]>;
};

/** A filter to be used against `SocialLeaderboardStat` object types. All fields are combined with a logical ‘and.’ */
export type SocialLeaderboardStatFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<SocialLeaderboardStatFilter>>;
  /** Filter by the object’s `ccCoinsInCirculationNanos` field. */
  ccCoinsInCirculationNanos?: InputMaybe<BigIntFilter>;
  /** Filter by the object’s `coinPriceDesoNanos` field. */
  coinPriceDesoNanos?: InputMaybe<BigFloatFilter>;
  /** Filter by the object’s `coinWatermarkNanos` field. */
  coinWatermarkNanos?: InputMaybe<BigIntFilter>;
  /** Filter by the object’s `count` field. */
  count?: InputMaybe<BigFloatFilter>;
  /** Filter by the object’s `creatorBasisPoints` field. */
  creatorBasisPoints?: InputMaybe<BigIntFilter>;
  /** Filter by the object’s `daoCoinMintingDisabled` field. */
  daoCoinMintingDisabled?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `daoCoinTransferRestrictionStatus` field. */
  daoCoinTransferRestrictionStatus?: InputMaybe<IntFilter>;
  /** Filter by the object’s `daoCoinsInCirculationNanosHex` field. */
  daoCoinsInCirculationNanosHex?: InputMaybe<StringFilter>;
  /** Filter by the object’s `description` field. */
  description?: InputMaybe<StringFilter>;
  /** Filter by the object’s `desoLockedNanos` field. */
  desoLockedNanos?: InputMaybe<BigIntFilter>;
  /** Filter by the object’s `extraData` field. */
  extraData?: InputMaybe<JsonFilter>;
  /** Filter by the object’s `mintingDisabled` field. */
  mintingDisabled?: InputMaybe<BooleanFilter>;
  /** Negates the expression. */
  not?: InputMaybe<SocialLeaderboardStatFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<SocialLeaderboardStatFilter>>;
  /** Filter by the object’s `pkid` field. */
  pkid?: InputMaybe<StringFilter>;
  /** Filter by the object’s `publicKey` field. */
  publicKey?: InputMaybe<StringFilter>;
  /** Filter by the object’s `rowId` field. */
  rowId?: InputMaybe<BigIntFilter>;
  /** Filter by the object’s `username` field. */
  username?: InputMaybe<StringFilter>;
};

/** A connection to a list of `SocialLeaderboardStat` values. */
export type SocialLeaderboardStatsConnection = {
  __typename?: "SocialLeaderboardStatsConnection";
  /** A list of edges which contains the `SocialLeaderboardStat` and cursor to aid in pagination. */
  edges: Array<SocialLeaderboardStatsEdge>;
  /** A list of `SocialLeaderboardStat` objects. */
  nodes: Array<Maybe<SocialLeaderboardStat>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `SocialLeaderboardStat` you could get from the connection. */
  totalCount: Scalars["Int"]["output"];
};

/** A `SocialLeaderboardStat` edge in the connection. */
export type SocialLeaderboardStatsEdge = {
  __typename?: "SocialLeaderboardStatsEdge";
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars["Cursor"]["output"]>;
  /** The `SocialLeaderboardStat` at the end of the edge. */
  node?: Maybe<SocialLeaderboardStat>;
};

/** Methods to use when ordering `SocialLeaderboardStat`. */
export enum SocialLeaderboardStatsOrderBy {
  BadgerKeyAsc = "BADGER_KEY_ASC",
  BadgerKeyDesc = "BADGER_KEY_DESC",
  CcCoinsInCirculationNanosAsc = "CC_COINS_IN_CIRCULATION_NANOS_ASC",
  CcCoinsInCirculationNanosDesc = "CC_COINS_IN_CIRCULATION_NANOS_DESC",
  CoinPriceDesoNanosAsc = "COIN_PRICE_DESO_NANOS_ASC",
  CoinPriceDesoNanosDesc = "COIN_PRICE_DESO_NANOS_DESC",
  CoinWatermarkNanosAsc = "COIN_WATERMARK_NANOS_ASC",
  CoinWatermarkNanosDesc = "COIN_WATERMARK_NANOS_DESC",
  CountAsc = "COUNT_ASC",
  CountDesc = "COUNT_DESC",
  CreatorBasisPointsAsc = "CREATOR_BASIS_POINTS_ASC",
  CreatorBasisPointsDesc = "CREATOR_BASIS_POINTS_DESC",
  DaoCoinsInCirculationNanosHexAsc = "DAO_COINS_IN_CIRCULATION_NANOS_HEX_ASC",
  DaoCoinsInCirculationNanosHexDesc = "DAO_COINS_IN_CIRCULATION_NANOS_HEX_DESC",
  DaoCoinMintingDisabledAsc = "DAO_COIN_MINTING_DISABLED_ASC",
  DaoCoinMintingDisabledDesc = "DAO_COIN_MINTING_DISABLED_DESC",
  DaoCoinTransferRestrictionStatusAsc = "DAO_COIN_TRANSFER_RESTRICTION_STATUS_ASC",
  DaoCoinTransferRestrictionStatusDesc = "DAO_COIN_TRANSFER_RESTRICTION_STATUS_DESC",
  DescriptionAsc = "DESCRIPTION_ASC",
  DescriptionDesc = "DESCRIPTION_DESC",
  DesoLockedNanosAsc = "DESO_LOCKED_NANOS_ASC",
  DesoLockedNanosDesc = "DESO_LOCKED_NANOS_DESC",
  ExtraDataAsc = "EXTRA_DATA_ASC",
  ExtraDataDesc = "EXTRA_DATA_DESC",
  IdAsc = "ID_ASC",
  IdDesc = "ID_DESC",
  MintingDisabledAsc = "MINTING_DISABLED_ASC",
  MintingDisabledDesc = "MINTING_DISABLED_DESC",
  Natural = "NATURAL",
  PkidAsc = "PKID_ASC",
  PkidDesc = "PKID_DESC",
  ProfilePicAsc = "PROFILE_PIC_ASC",
  ProfilePicDesc = "PROFILE_PIC_DESC",
  PublicKeyAsc = "PUBLIC_KEY_ASC",
  PublicKeyDesc = "PUBLIC_KEY_DESC",
  UsernameAsc = "USERNAME_ASC",
  UsernameDesc = "USERNAME_DESC",
}

/** A connection to a list of `StakeEntry` values. */
export type StakeEntriesConnection = {
  __typename?: "StakeEntriesConnection";
  /** A list of edges which contains the `StakeEntry` and cursor to aid in pagination. */
  edges: Array<StakeEntriesEdge>;
  /** A list of `StakeEntry` objects. */
  nodes: Array<Maybe<StakeEntry>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `StakeEntry` you could get from the connection. */
  totalCount: Scalars["Int"]["output"];
};

/** A `StakeEntry` edge in the connection. */
export type StakeEntriesEdge = {
  __typename?: "StakeEntriesEdge";
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars["Cursor"]["output"]>;
  /** The `StakeEntry` at the end of the edge. */
  node?: Maybe<StakeEntry>;
};

/** Methods to use when ordering `StakeEntry`. */
export enum StakeEntriesOrderBy {
  ExtraDataAsc = "EXTRA_DATA_ASC",
  ExtraDataDesc = "EXTRA_DATA_DESC",
  Natural = "NATURAL",
  PrimaryKeyAsc = "PRIMARY_KEY_ASC",
  PrimaryKeyDesc = "PRIMARY_KEY_DESC",
  RewardMethodAsc = "REWARD_METHOD_ASC",
  RewardMethodDesc = "REWARD_METHOD_DESC",
  StakerPkidAsc = "STAKER_PKID_ASC",
  StakerPkidDesc = "STAKER_PKID_DESC",
  StakeAmountNanosAsc = "STAKE_AMOUNT_NANOS_ASC",
  StakeAmountNanosDesc = "STAKE_AMOUNT_NANOS_DESC",
  ValidatorPkidAsc = "VALIDATOR_PKID_ASC",
  ValidatorPkidDesc = "VALIDATOR_PKID_DESC",
}

export type StakeEntry = Node & {
  __typename?: "StakeEntry";
  extraData?: Maybe<Scalars["JSON"]["output"]>;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  id: Scalars["ID"]["output"];
  rewardMethod: Scalars["String"]["output"];
  stakeAmountNanos: Scalars["BigFloat"]["output"];
  /** Reads a single `Account` that is related to this `StakeEntry`. */
  staker?: Maybe<Account>;
  stakerPkid: Scalars["String"]["output"];
  /** Reads a single `Account` that is related to this `StakeEntry`. */
  validatorAccount?: Maybe<Account>;
  /** Reads a single `ValidatorEntry` that is related to this `StakeEntry`. */
  validatorEntry?: Maybe<ValidatorEntry>;
  validatorPkid: Scalars["String"]["output"];
};

/**
 * A condition to be used against `StakeEntry` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type StakeEntryCondition = {
  /** Checks for equality with the object’s `extraData` field. */
  extraData?: InputMaybe<Scalars["JSON"]["input"]>;
  /** Checks for equality with the object’s `rewardMethod` field. */
  rewardMethod?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `stakeAmountNanos` field. */
  stakeAmountNanos?: InputMaybe<Scalars["BigFloat"]["input"]>;
  /** Checks for equality with the object’s `stakerPkid` field. */
  stakerPkid?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `validatorPkid` field. */
  validatorPkid?: InputMaybe<Scalars["String"]["input"]>;
};

/** A filter to be used against `StakeEntry` object types. All fields are combined with a logical ‘and.’ */
export type StakeEntryFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<StakeEntryFilter>>;
  /** Filter by the object’s `extraData` field. */
  extraData?: InputMaybe<JsonFilter>;
  /** Negates the expression. */
  not?: InputMaybe<StakeEntryFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<StakeEntryFilter>>;
  /** Filter by the object’s `rewardMethod` field. */
  rewardMethod?: InputMaybe<StringFilter>;
  /** Filter by the object’s `stakeAmountNanos` field. */
  stakeAmountNanos?: InputMaybe<BigFloatFilter>;
  /** Filter by the object’s `staker` relation. */
  staker?: InputMaybe<AccountFilter>;
  /** Filter by the object’s `stakerPkid` field. */
  stakerPkid?: InputMaybe<StringFilter>;
  /** Filter by the object’s `validatorAccount` relation. */
  validatorAccount?: InputMaybe<AccountFilter>;
  /** Filter by the object’s `validatorEntry` relation. */
  validatorEntry?: InputMaybe<ValidatorEntryFilter>;
  /** Filter by the object’s `validatorPkid` field. */
  validatorPkid?: InputMaybe<StringFilter>;
};

export type StakeReward = Node & {
  __typename?: "StakeReward";
  /** Reads a single `Block` that is related to this `StakeReward`. */
  block?: Maybe<Block>;
  blockHash: Scalars["String"]["output"];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  id: Scalars["ID"]["output"];
  isValidatorCommission: Scalars["Boolean"]["output"];
  rewardMethod: Scalars["Int"]["output"];
  rewardNanos: Scalars["BigInt"]["output"];
  /** Reads a single `Account` that is related to this `StakeReward`. */
  staker?: Maybe<Account>;
  stakerPkid: Scalars["String"]["output"];
  utxoOpIndex: Scalars["BigInt"]["output"];
  /** Reads a single `Account` that is related to this `StakeReward`. */
  validator?: Maybe<Account>;
  validatorPkid: Scalars["String"]["output"];
};

/**
 * A condition to be used against `StakeReward` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type StakeRewardCondition = {
  /** Checks for equality with the object’s `blockHash` field. */
  blockHash?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `isValidatorCommission` field. */
  isValidatorCommission?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Checks for equality with the object’s `rewardMethod` field. */
  rewardMethod?: InputMaybe<Scalars["Int"]["input"]>;
  /** Checks for equality with the object’s `rewardNanos` field. */
  rewardNanos?: InputMaybe<Scalars["BigInt"]["input"]>;
  /** Checks for equality with the object’s `stakerPkid` field. */
  stakerPkid?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `utxoOpIndex` field. */
  utxoOpIndex?: InputMaybe<Scalars["BigInt"]["input"]>;
  /** Checks for equality with the object’s `validatorPkid` field. */
  validatorPkid?: InputMaybe<Scalars["String"]["input"]>;
};

/** A filter to be used against `StakeReward` object types. All fields are combined with a logical ‘and.’ */
export type StakeRewardFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<StakeRewardFilter>>;
  /** Filter by the object’s `block` relation. */
  block?: InputMaybe<BlockFilter>;
  /** Filter by the object’s `blockHash` field. */
  blockHash?: InputMaybe<StringFilter>;
  /** Filter by the object’s `isValidatorCommission` field. */
  isValidatorCommission?: InputMaybe<BooleanFilter>;
  /** Negates the expression. */
  not?: InputMaybe<StakeRewardFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<StakeRewardFilter>>;
  /** Filter by the object’s `rewardMethod` field. */
  rewardMethod?: InputMaybe<IntFilter>;
  /** Filter by the object’s `rewardNanos` field. */
  rewardNanos?: InputMaybe<BigIntFilter>;
  /** Filter by the object’s `staker` relation. */
  staker?: InputMaybe<AccountFilter>;
  /** Filter by the object’s `stakerPkid` field. */
  stakerPkid?: InputMaybe<StringFilter>;
  /** Filter by the object’s `utxoOpIndex` field. */
  utxoOpIndex?: InputMaybe<BigIntFilter>;
  /** Filter by the object’s `validator` relation. */
  validator?: InputMaybe<AccountFilter>;
  /** Filter by the object’s `validatorPkid` field. */
  validatorPkid?: InputMaybe<StringFilter>;
};

export type StakeRewardsByValidatorAndStaker = Node & {
  __typename?: "StakeRewardsByValidatorAndStaker";
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  id: Scalars["ID"]["output"];
  rewardNanos?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Reads a single `Account` that is related to this `StakeRewardsByValidatorAndStaker`. */
  stakerAccount?: Maybe<Account>;
  stakerPkid: Scalars["String"]["output"];
  /** Reads a single `Account` that is related to this `StakeRewardsByValidatorAndStaker`. */
  validatorAccount?: Maybe<Account>;
  /** Reads a single `ValidatorEntry` that is related to this `StakeRewardsByValidatorAndStaker`. */
  validatorEntry?: Maybe<ValidatorEntry>;
  validatorPkid: Scalars["String"]["output"];
};

/**
 * A condition to be used against `StakeRewardsByValidatorAndStaker` object types.
 * All fields are tested for equality and combined with a logical ‘and.’
 */
export type StakeRewardsByValidatorAndStakerCondition = {
  /** Checks for equality with the object’s `rewardNanos` field. */
  rewardNanos?: InputMaybe<Scalars["BigFloat"]["input"]>;
  /** Checks for equality with the object’s `stakerPkid` field. */
  stakerPkid?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `validatorPkid` field. */
  validatorPkid?: InputMaybe<Scalars["String"]["input"]>;
};

/** A filter to be used against `StakeRewardsByValidatorAndStaker` object types. All fields are combined with a logical ‘and.’ */
export type StakeRewardsByValidatorAndStakerFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<StakeRewardsByValidatorAndStakerFilter>>;
  /** Negates the expression. */
  not?: InputMaybe<StakeRewardsByValidatorAndStakerFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<StakeRewardsByValidatorAndStakerFilter>>;
  /** Filter by the object’s `rewardNanos` field. */
  rewardNanos?: InputMaybe<BigFloatFilter>;
  /** Filter by the object’s `stakerAccount` relation. */
  stakerAccount?: InputMaybe<AccountFilter>;
  /** A related `stakerAccount` exists. */
  stakerAccountExists?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Filter by the object’s `stakerPkid` field. */
  stakerPkid?: InputMaybe<StringFilter>;
  /** Filter by the object’s `validatorAccount` relation. */
  validatorAccount?: InputMaybe<AccountFilter>;
  /** A related `validatorAccount` exists. */
  validatorAccountExists?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Filter by the object’s `validatorEntry` relation. */
  validatorEntry?: InputMaybe<ValidatorEntryFilter>;
  /** A related `validatorEntry` exists. */
  validatorEntryExists?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Filter by the object’s `validatorPkid` field. */
  validatorPkid?: InputMaybe<StringFilter>;
};

/** A connection to a list of `StakeRewardsByValidatorAndStaker` values. */
export type StakeRewardsByValidatorAndStakersConnection = {
  __typename?: "StakeRewardsByValidatorAndStakersConnection";
  /** A list of edges which contains the `StakeRewardsByValidatorAndStaker` and cursor to aid in pagination. */
  edges: Array<StakeRewardsByValidatorAndStakersEdge>;
  /** A list of `StakeRewardsByValidatorAndStaker` objects. */
  nodes: Array<Maybe<StakeRewardsByValidatorAndStaker>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `StakeRewardsByValidatorAndStaker` you could get from the connection. */
  totalCount: Scalars["Int"]["output"];
};

/** A `StakeRewardsByValidatorAndStaker` edge in the connection. */
export type StakeRewardsByValidatorAndStakersEdge = {
  __typename?: "StakeRewardsByValidatorAndStakersEdge";
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars["Cursor"]["output"]>;
  /** The `StakeRewardsByValidatorAndStaker` at the end of the edge. */
  node?: Maybe<StakeRewardsByValidatorAndStaker>;
};

/** Methods to use when ordering `StakeRewardsByValidatorAndStaker`. */
export enum StakeRewardsByValidatorAndStakersOrderBy {
  Natural = "NATURAL",
  PrimaryKeyAsc = "PRIMARY_KEY_ASC",
  PrimaryKeyDesc = "PRIMARY_KEY_DESC",
  RewardNanosAsc = "REWARD_NANOS_ASC",
  RewardNanosDesc = "REWARD_NANOS_DESC",
  StakerPkidAsc = "STAKER_PKID_ASC",
  StakerPkidDesc = "STAKER_PKID_DESC",
  ValidatorPkidAsc = "VALIDATOR_PKID_ASC",
  ValidatorPkidDesc = "VALIDATOR_PKID_DESC",
}

/** A connection to a list of `StakeReward` values. */
export type StakeRewardsConnection = {
  __typename?: "StakeRewardsConnection";
  /** A list of edges which contains the `StakeReward` and cursor to aid in pagination. */
  edges: Array<StakeRewardsEdge>;
  /** A list of `StakeReward` objects. */
  nodes: Array<Maybe<StakeReward>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `StakeReward` you could get from the connection. */
  totalCount: Scalars["Int"]["output"];
};

/** A `StakeReward` edge in the connection. */
export type StakeRewardsEdge = {
  __typename?: "StakeRewardsEdge";
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars["Cursor"]["output"]>;
  /** The `StakeReward` at the end of the edge. */
  node?: Maybe<StakeReward>;
};

/** Methods to use when ordering `StakeReward`. */
export enum StakeRewardsOrderBy {
  BlockHashAsc = "BLOCK_HASH_ASC",
  BlockHashDesc = "BLOCK_HASH_DESC",
  IsValidatorCommissionAsc = "IS_VALIDATOR_COMMISSION_ASC",
  IsValidatorCommissionDesc = "IS_VALIDATOR_COMMISSION_DESC",
  Natural = "NATURAL",
  PrimaryKeyAsc = "PRIMARY_KEY_ASC",
  PrimaryKeyDesc = "PRIMARY_KEY_DESC",
  RewardMethodAsc = "REWARD_METHOD_ASC",
  RewardMethodDesc = "REWARD_METHOD_DESC",
  RewardNanosAsc = "REWARD_NANOS_ASC",
  RewardNanosDesc = "REWARD_NANOS_DESC",
  StakerPkidAsc = "STAKER_PKID_ASC",
  StakerPkidDesc = "STAKER_PKID_DESC",
  UtxoOpIndexAsc = "UTXO_OP_INDEX_ASC",
  UtxoOpIndexDesc = "UTXO_OP_INDEX_DESC",
  ValidatorPkidAsc = "VALIDATOR_PKID_ASC",
  ValidatorPkidDesc = "VALIDATOR_PKID_DESC",
}

/** A connection to a list of `StakingSummary` values. */
export type StakingSummariesConnection = {
  __typename?: "StakingSummariesConnection";
  /** A list of edges which contains the `StakingSummary` and cursor to aid in pagination. */
  edges: Array<StakingSummariesEdge>;
  /** A list of `StakingSummary` objects. */
  nodes: Array<Maybe<StakingSummary>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `StakingSummary` you could get from the connection. */
  totalCount: Scalars["Int"]["output"];
};

/** A `StakingSummary` edge in the connection. */
export type StakingSummariesEdge = {
  __typename?: "StakingSummariesEdge";
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars["Cursor"]["output"]>;
  /** The `StakingSummary` at the end of the edge. */
  node?: Maybe<StakingSummary>;
};

/** Methods to use when ordering `StakingSummary`. */
export enum StakingSummariesOrderBy {
  CurrentEpochNumberAsc = "CURRENT_EPOCH_NUMBER_ASC",
  CurrentEpochNumberDesc = "CURRENT_EPOCH_NUMBER_DESC",
  GlobalStakeAmountNanosAsc = "GLOBAL_STAKE_AMOUNT_NANOS_ASC",
  GlobalStakeAmountNanosDesc = "GLOBAL_STAKE_AMOUNT_NANOS_DESC",
  IdAsc = "ID_ASC",
  IdDesc = "ID_DESC",
  Natural = "NATURAL",
  NumEpochsInLeaderScheduleAsc = "NUM_EPOCHS_IN_LEADER_SCHEDULE_ASC",
  NumEpochsInLeaderScheduleDesc = "NUM_EPOCHS_IN_LEADER_SCHEDULE_DESC",
  NumStakersAsc = "NUM_STAKERS_ASC",
  NumStakersDesc = "NUM_STAKERS_DESC",
  NumValidatorsAsc = "NUM_VALIDATORS_ASC",
  NumValidatorsDesc = "NUM_VALIDATORS_DESC",
}

export type StakingSummary = {
  __typename?: "StakingSummary";
  currentEpochNumber?: Maybe<Scalars["BigInt"]["output"]>;
  globalStakeAmountNanos?: Maybe<Scalars["BigFloat"]["output"]>;
  numEpochsInLeaderSchedule?: Maybe<Scalars["BigInt"]["output"]>;
  numStakers?: Maybe<Scalars["BigInt"]["output"]>;
  numValidators?: Maybe<Scalars["BigInt"]["output"]>;
  rowId?: Maybe<Scalars["Int"]["output"]>;
};

/**
 * A condition to be used against `StakingSummary` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type StakingSummaryCondition = {
  /** Checks for equality with the object’s `currentEpochNumber` field. */
  currentEpochNumber?: InputMaybe<Scalars["BigInt"]["input"]>;
  /** Checks for equality with the object’s `globalStakeAmountNanos` field. */
  globalStakeAmountNanos?: InputMaybe<Scalars["BigFloat"]["input"]>;
  /** Checks for equality with the object’s `numEpochsInLeaderSchedule` field. */
  numEpochsInLeaderSchedule?: InputMaybe<Scalars["BigInt"]["input"]>;
  /** Checks for equality with the object’s `numStakers` field. */
  numStakers?: InputMaybe<Scalars["BigInt"]["input"]>;
  /** Checks for equality with the object’s `numValidators` field. */
  numValidators?: InputMaybe<Scalars["BigInt"]["input"]>;
  /** Checks for equality with the object’s `rowId` field. */
  rowId?: InputMaybe<Scalars["Int"]["input"]>;
};

/** A filter to be used against `StakingSummary` object types. All fields are combined with a logical ‘and.’ */
export type StakingSummaryFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<StakingSummaryFilter>>;
  /** Filter by the object’s `currentEpochNumber` field. */
  currentEpochNumber?: InputMaybe<BigIntFilter>;
  /** Filter by the object’s `globalStakeAmountNanos` field. */
  globalStakeAmountNanos?: InputMaybe<BigFloatFilter>;
  /** Negates the expression. */
  not?: InputMaybe<StakingSummaryFilter>;
  /** Filter by the object’s `numEpochsInLeaderSchedule` field. */
  numEpochsInLeaderSchedule?: InputMaybe<BigIntFilter>;
  /** Filter by the object’s `numStakers` field. */
  numStakers?: InputMaybe<BigIntFilter>;
  /** Filter by the object’s `numValidators` field. */
  numValidators?: InputMaybe<BigIntFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<StakingSummaryFilter>>;
  /** Filter by the object’s `rowId` field. */
  rowId?: InputMaybe<IntFilter>;
};

/** A filter to be used against String fields. All fields are combined with a logical ‘and.’ */
export type StringFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<Scalars["String"]["input"]>;
  /** Not equal to the specified value, treating null like an ordinary value (case-insensitive). */
  distinctFromInsensitive?: InputMaybe<Scalars["String"]["input"]>;
  /** Ends with the specified string (case-sensitive). */
  endsWith?: InputMaybe<Scalars["String"]["input"]>;
  /** Ends with the specified string (case-insensitive). */
  endsWithInsensitive?: InputMaybe<Scalars["String"]["input"]>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<Scalars["String"]["input"]>;
  /** Equal to the specified value (case-insensitive). */
  equalToInsensitive?: InputMaybe<Scalars["String"]["input"]>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<Scalars["String"]["input"]>;
  /** Greater than the specified value (case-insensitive). */
  greaterThanInsensitive?: InputMaybe<Scalars["String"]["input"]>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<Scalars["String"]["input"]>;
  /** Greater than or equal to the specified value (case-insensitive). */
  greaterThanOrEqualToInsensitive?: InputMaybe<Scalars["String"]["input"]>;
  /** Included in the specified list. */
  in?: InputMaybe<Array<Scalars["String"]["input"]>>;
  /** Included in the specified list (case-insensitive). */
  inInsensitive?: InputMaybe<Array<Scalars["String"]["input"]>>;
  /** Contains the specified string (case-sensitive). */
  includes?: InputMaybe<Scalars["String"]["input"]>;
  /** Contains the specified string (case-insensitive). */
  includesInsensitive?: InputMaybe<Scalars["String"]["input"]>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<Scalars["String"]["input"]>;
  /** Less than the specified value (case-insensitive). */
  lessThanInsensitive?: InputMaybe<Scalars["String"]["input"]>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<Scalars["String"]["input"]>;
  /** Less than or equal to the specified value (case-insensitive). */
  lessThanOrEqualToInsensitive?: InputMaybe<Scalars["String"]["input"]>;
  /** Matches the specified pattern (case-sensitive). An underscore (_) matches any single character; a percent sign (%) matches any sequence of zero or more characters. */
  like?: InputMaybe<Scalars["String"]["input"]>;
  /** Matches the specified pattern (case-insensitive). An underscore (_) matches any single character; a percent sign (%) matches any sequence of zero or more characters. */
  likeInsensitive?: InputMaybe<Scalars["String"]["input"]>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<Scalars["String"]["input"]>;
  /** Equal to the specified value, treating null like an ordinary value (case-insensitive). */
  notDistinctFromInsensitive?: InputMaybe<Scalars["String"]["input"]>;
  /** Does not end with the specified string (case-sensitive). */
  notEndsWith?: InputMaybe<Scalars["String"]["input"]>;
  /** Does not end with the specified string (case-insensitive). */
  notEndsWithInsensitive?: InputMaybe<Scalars["String"]["input"]>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<Scalars["String"]["input"]>;
  /** Not equal to the specified value (case-insensitive). */
  notEqualToInsensitive?: InputMaybe<Scalars["String"]["input"]>;
  /** Not included in the specified list. */
  notIn?: InputMaybe<Array<Scalars["String"]["input"]>>;
  /** Not included in the specified list (case-insensitive). */
  notInInsensitive?: InputMaybe<Array<Scalars["String"]["input"]>>;
  /** Does not contain the specified string (case-sensitive). */
  notIncludes?: InputMaybe<Scalars["String"]["input"]>;
  /** Does not contain the specified string (case-insensitive). */
  notIncludesInsensitive?: InputMaybe<Scalars["String"]["input"]>;
  /** Does not match the specified pattern (case-sensitive). An underscore (_) matches any single character; a percent sign (%) matches any sequence of zero or more characters. */
  notLike?: InputMaybe<Scalars["String"]["input"]>;
  /** Does not match the specified pattern (case-insensitive). An underscore (_) matches any single character; a percent sign (%) matches any sequence of zero or more characters. */
  notLikeInsensitive?: InputMaybe<Scalars["String"]["input"]>;
  /** Does not start with the specified string (case-sensitive). */
  notStartsWith?: InputMaybe<Scalars["String"]["input"]>;
  /** Does not start with the specified string (case-insensitive). */
  notStartsWithInsensitive?: InputMaybe<Scalars["String"]["input"]>;
  /** Starts with the specified string (case-sensitive). */
  startsWith?: InputMaybe<Scalars["String"]["input"]>;
  /** Starts with the specified string (case-insensitive). */
  startsWithInsensitive?: InputMaybe<Scalars["String"]["input"]>;
};

/** A filter to be used against String List fields. All fields are combined with a logical ‘and.’ */
export type StringListFilter = {
  /** Any array item is equal to the specified value. */
  anyEqualTo?: InputMaybe<Scalars["String"]["input"]>;
  /** Any array item is greater than the specified value. */
  anyGreaterThan?: InputMaybe<Scalars["String"]["input"]>;
  /** Any array item is greater than or equal to the specified value. */
  anyGreaterThanOrEqualTo?: InputMaybe<Scalars["String"]["input"]>;
  /** Any array item is less than the specified value. */
  anyLessThan?: InputMaybe<Scalars["String"]["input"]>;
  /** Any array item is less than or equal to the specified value. */
  anyLessThanOrEqualTo?: InputMaybe<Scalars["String"]["input"]>;
  /** Any array item is not equal to the specified value. */
  anyNotEqualTo?: InputMaybe<Scalars["String"]["input"]>;
  /** Contained by the specified list of values. */
  containedBy?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** Contains the specified list of values. */
  contains?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** Overlaps the specified list of values. */
  overlaps?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type TokenBalance = Node & {
  __typename?: "TokenBalance";
  balanceNanos: Scalars["BigFloat"]["output"];
  /** Reads a single `Account` that is related to this `TokenBalance`. */
  creator?: Maybe<Account>;
  creatorPkid: Scalars["String"]["output"];
  /** Reads and enables pagination through a set of `DesoTokenLimitOrder`. */
  desoTokenBuyingLimitOrders: DesoTokenLimitOrdersConnection;
  /** Reads and enables pagination through a set of `DesoTokenLimitOrder`. */
  desoTokenSellingLimitOrders: DesoTokenLimitOrdersConnection;
  hasPurchased: Scalars["Boolean"]["output"];
  hodlerPkid: Scalars["String"]["output"];
  /** Reads a single `Account` that is related to this `TokenBalance`. */
  holder?: Maybe<Account>;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  id: Scalars["ID"]["output"];
  isDaoCoin: Scalars["Boolean"]["output"];
};

export type TokenBalanceDesoTokenBuyingLimitOrdersArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<DesoTokenLimitOrderCondition>;
  filter?: InputMaybe<DesoTokenLimitOrderFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<DesoTokenLimitOrdersOrderBy>>;
};

export type TokenBalanceDesoTokenSellingLimitOrdersArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<DesoTokenLimitOrderCondition>;
  filter?: InputMaybe<DesoTokenLimitOrderFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<DesoTokenLimitOrdersOrderBy>>;
};

/**
 * A condition to be used against `TokenBalance` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type TokenBalanceCondition = {
  /** Checks for equality with the object’s `balanceNanos` field. */
  balanceNanos?: InputMaybe<Scalars["BigFloat"]["input"]>;
  /** Checks for equality with the object’s `creatorPkid` field. */
  creatorPkid?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `hasPurchased` field. */
  hasPurchased?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Checks for equality with the object’s `hodlerPkid` field. */
  hodlerPkid?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `isDaoCoin` field. */
  isDaoCoin?: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** A filter to be used against `TokenBalance` object types. All fields are combined with a logical ‘and.’ */
export type TokenBalanceFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<TokenBalanceFilter>>;
  /** Filter by the object’s `balanceNanos` field. */
  balanceNanos?: InputMaybe<BigFloatFilter>;
  /** Filter by the object’s `creator` relation. */
  creator?: InputMaybe<AccountFilter>;
  /** Filter by the object’s `creatorPkid` field. */
  creatorPkid?: InputMaybe<StringFilter>;
  /** Filter by the object’s `desoTokenBuyingLimitOrders` relation. */
  desoTokenBuyingLimitOrders?: InputMaybe<TokenBalanceToManyDesoTokenLimitOrderFilter>;
  /** Some related `desoTokenBuyingLimitOrders` exist. */
  desoTokenBuyingLimitOrdersExist?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Filter by the object’s `desoTokenSellingLimitOrders` relation. */
  desoTokenSellingLimitOrders?: InputMaybe<TokenBalanceToManyDesoTokenLimitOrderFilter>;
  /** Some related `desoTokenSellingLimitOrders` exist. */
  desoTokenSellingLimitOrdersExist?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Filter by the object’s `hasPurchased` field. */
  hasPurchased?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `hodlerPkid` field. */
  hodlerPkid?: InputMaybe<StringFilter>;
  /** Filter by the object’s `holder` relation. */
  holder?: InputMaybe<AccountFilter>;
  /** Filter by the object’s `isDaoCoin` field. */
  isDaoCoin?: InputMaybe<BooleanFilter>;
  /** Negates the expression. */
  not?: InputMaybe<TokenBalanceFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<TokenBalanceFilter>>;
};

/** A filter to be used against many `DesoTokenLimitOrder` object types. All fields are combined with a logical ‘and.’ */
export type TokenBalanceToManyDesoTokenLimitOrderFilter = {
  /** Every related `DesoTokenLimitOrder` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<DesoTokenLimitOrderFilter>;
  /** No related `DesoTokenLimitOrder` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<DesoTokenLimitOrderFilter>;
  /** Some related `DesoTokenLimitOrder` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<DesoTokenLimitOrderFilter>;
};

/** A connection to a list of `TokenBalance` values. */
export type TokenBalancesConnection = {
  __typename?: "TokenBalancesConnection";
  /** A list of edges which contains the `TokenBalance` and cursor to aid in pagination. */
  edges: Array<TokenBalancesEdge>;
  /** A list of `TokenBalance` objects. */
  nodes: Array<Maybe<TokenBalance>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `TokenBalance` you could get from the connection. */
  totalCount: Scalars["Int"]["output"];
};

/** A `TokenBalance` edge in the connection. */
export type TokenBalancesEdge = {
  __typename?: "TokenBalancesEdge";
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars["Cursor"]["output"]>;
  /** The `TokenBalance` at the end of the edge. */
  node?: Maybe<TokenBalance>;
};

/** Methods to use when ordering `TokenBalance`. */
export enum TokenBalancesOrderBy {
  BalanceNanosAsc = "BALANCE_NANOS_ASC",
  BalanceNanosDesc = "BALANCE_NANOS_DESC",
  CreatorPkidAsc = "CREATOR_PKID_ASC",
  CreatorPkidDesc = "CREATOR_PKID_DESC",
  HasPurchasedAsc = "HAS_PURCHASED_ASC",
  HasPurchasedDesc = "HAS_PURCHASED_DESC",
  HodlerPkidAsc = "HODLER_PKID_ASC",
  HodlerPkidDesc = "HODLER_PKID_DESC",
  IsDaoCoinAsc = "IS_DAO_COIN_ASC",
  IsDaoCoinDesc = "IS_DAO_COIN_DESC",
  Natural = "NATURAL",
  PrimaryKeyAsc = "PRIMARY_KEY_ASC",
  PrimaryKeyDesc = "PRIMARY_KEY_DESC",
}

export type Transaction = {
  __typename?: "Transaction";
  /** Reads a single `Account` that is related to this `Transaction`. */
  account?: Maybe<Account>;
  /** Reads and enables pagination through a set of `AffectedPublicKey`. */
  affectedPublicKeys: AffectedPublicKeysConnection;
  /** Reads a single `Block` that is related to this `Transaction`. */
  block?: Maybe<Block>;
  blockHash?: Maybe<Scalars["String"]["output"]>;
  blockHeight?: Maybe<Scalars["BigInt"]["output"]>;
  extraData?: Maybe<Scalars["JSON"]["output"]>;
  feeNanos?: Maybe<Scalars["BigInt"]["output"]>;
  indexInBlock?: Maybe<Scalars["Int"]["output"]>;
  indexInWrapperTransaction?: Maybe<Scalars["BigInt"]["output"]>;
  /** Reads and enables pagination through a set of `Transaction`. */
  innerTransactions: TransactionsConnection;
  inputs?: Maybe<Scalars["JSON"]["output"]>;
  nonceExpirationBlockHeight?: Maybe<Scalars["BigInt"]["output"]>;
  noncePartialId?: Maybe<Scalars["BigInt"]["output"]>;
  outputs?: Maybe<Scalars["JSON"]["output"]>;
  publicKey?: Maybe<Scalars["String"]["output"]>;
  signature?: Maybe<Scalars["String"]["output"]>;
  timestamp?: Maybe<Scalars["Datetime"]["output"]>;
  transactionHash?: Maybe<Scalars["String"]["output"]>;
  transactionId?: Maybe<Scalars["String"]["output"]>;
  /** Reads a single `TransactionType` that is related to this `Transaction`. */
  transactionType?: Maybe<TransactionType>;
  txIndexBasicTransferMetadata?: Maybe<Scalars["JSON"]["output"]>;
  txIndexMetadata?: Maybe<Scalars["JSON"]["output"]>;
  txnBytes?: Maybe<Scalars["String"]["output"]>;
  txnMeta?: Maybe<Scalars["JSON"]["output"]>;
  txnMetaBytes?: Maybe<Scalars["String"]["output"]>;
  txnType?: Maybe<Scalars["Int"]["output"]>;
  /** Reads and enables pagination through a set of `UtxoOperation`. */
  utxoOperationsByBlockHashAndTransactionIndex: UtxoOperationsConnection;
  version?: Maybe<Scalars["Int"]["output"]>;
  /** Reads a single `Transaction` that is related to this `Transaction`. */
  wrapperTransaction?: Maybe<Transaction>;
  wrapperTransactionHash?: Maybe<Scalars["String"]["output"]>;
};

export type TransactionAffectedPublicKeysArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<AffectedPublicKeyCondition>;
  filter?: InputMaybe<AffectedPublicKeyFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<AffectedPublicKeysOrderBy>>;
};

export type TransactionInnerTransactionsArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<TransactionCondition>;
  filter?: InputMaybe<TransactionFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<TransactionsOrderBy>>;
};

export type TransactionUtxoOperationsByBlockHashAndTransactionIndexArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<UtxoOperationCondition>;
  filter?: InputMaybe<UtxoOperationFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<UtxoOperationsOrderBy>>;
};

/**
 * A condition to be used against `Transaction` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type TransactionCondition = {
  /** Checks for equality with the object’s `blockHash` field. */
  blockHash?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `blockHeight` field. */
  blockHeight?: InputMaybe<Scalars["BigInt"]["input"]>;
  /** Checks for equality with the object’s `extraData` field. */
  extraData?: InputMaybe<Scalars["JSON"]["input"]>;
  /** Checks for equality with the object’s `feeNanos` field. */
  feeNanos?: InputMaybe<Scalars["BigInt"]["input"]>;
  /** Checks for equality with the object’s `indexInBlock` field. */
  indexInBlock?: InputMaybe<Scalars["Int"]["input"]>;
  /** Checks for equality with the object’s `indexInWrapperTransaction` field. */
  indexInWrapperTransaction?: InputMaybe<Scalars["BigInt"]["input"]>;
  /** Checks for equality with the object’s `inputs` field. */
  inputs?: InputMaybe<Scalars["JSON"]["input"]>;
  /** Checks for equality with the object’s `nonceExpirationBlockHeight` field. */
  nonceExpirationBlockHeight?: InputMaybe<Scalars["BigInt"]["input"]>;
  /** Checks for equality with the object’s `noncePartialId` field. */
  noncePartialId?: InputMaybe<Scalars["BigInt"]["input"]>;
  /** Checks for equality with the object’s `outputs` field. */
  outputs?: InputMaybe<Scalars["JSON"]["input"]>;
  /** Checks for equality with the object’s `publicKey` field. */
  publicKey?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `signature` field. */
  signature?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `timestamp` field. */
  timestamp?: InputMaybe<Scalars["Datetime"]["input"]>;
  /** Checks for equality with the object’s `transactionHash` field. */
  transactionHash?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `transactionId` field. */
  transactionId?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `txIndexBasicTransferMetadata` field. */
  txIndexBasicTransferMetadata?: InputMaybe<Scalars["JSON"]["input"]>;
  /** Checks for equality with the object’s `txIndexMetadata` field. */
  txIndexMetadata?: InputMaybe<Scalars["JSON"]["input"]>;
  /** Checks for equality with the object’s `txnBytes` field. */
  txnBytes?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `txnMeta` field. */
  txnMeta?: InputMaybe<Scalars["JSON"]["input"]>;
  /** Checks for equality with the object’s `txnMetaBytes` field. */
  txnMetaBytes?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `txnType` field. */
  txnType?: InputMaybe<Scalars["Int"]["input"]>;
  /** Checks for equality with the object’s `version` field. */
  version?: InputMaybe<Scalars["Int"]["input"]>;
  /** Checks for equality with the object’s `wrapperTransactionHash` field. */
  wrapperTransactionHash?: InputMaybe<Scalars["String"]["input"]>;
};

/** A filter to be used against `Transaction` object types. All fields are combined with a logical ‘and.’ */
export type TransactionFilter = {
  /** Filter by the object’s `account` relation. */
  account?: InputMaybe<AccountFilter>;
  /** A related `account` exists. */
  accountExists?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Filter by the object’s `affectedPublicKeys` relation. */
  affectedPublicKeys?: InputMaybe<TransactionToManyAffectedPublicKeyFilter>;
  /** Some related `affectedPublicKeys` exist. */
  affectedPublicKeysExist?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<TransactionFilter>>;
  /** Filter by the object’s `block` relation. */
  block?: InputMaybe<BlockFilter>;
  /** A related `block` exists. */
  blockExists?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Filter by the object’s `blockHash` field. */
  blockHash?: InputMaybe<StringFilter>;
  /** Filter by the object’s `blockHeight` field. */
  blockHeight?: InputMaybe<BigIntFilter>;
  /** Filter by the object’s `extraData` field. */
  extraData?: InputMaybe<JsonFilter>;
  /** Filter by the object’s `feeNanos` field. */
  feeNanos?: InputMaybe<BigIntFilter>;
  /** Filter by the object’s `indexInBlock` field. */
  indexInBlock?: InputMaybe<IntFilter>;
  /** Filter by the object’s `indexInWrapperTransaction` field. */
  indexInWrapperTransaction?: InputMaybe<BigIntFilter>;
  /** Filter by the object’s `innerTransactions` relation. */
  innerTransactions?: InputMaybe<TransactionToManyTransactionFilter>;
  /** Some related `innerTransactions` exist. */
  innerTransactionsExist?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Filter by the object’s `inputs` field. */
  inputs?: InputMaybe<JsonFilter>;
  /** Filter by the object’s `nonceExpirationBlockHeight` field. */
  nonceExpirationBlockHeight?: InputMaybe<BigIntFilter>;
  /** Filter by the object’s `noncePartialId` field. */
  noncePartialId?: InputMaybe<BigIntFilter>;
  /** Negates the expression. */
  not?: InputMaybe<TransactionFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<TransactionFilter>>;
  /** Filter by the object’s `outputs` field. */
  outputs?: InputMaybe<JsonFilter>;
  /** Filter by the object’s `publicKey` field. */
  publicKey?: InputMaybe<StringFilter>;
  /** Filter by the object’s `timestamp` field. */
  timestamp?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `transactionHash` field. */
  transactionHash?: InputMaybe<StringFilter>;
  /** Filter by the object’s `transactionId` field. */
  transactionId?: InputMaybe<StringFilter>;
  /** Filter by the object’s `transactionType` relation. */
  transactionType?: InputMaybe<TransactionTypeFilter>;
  /** A related `transactionType` exists. */
  transactionTypeExists?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Filter by the object’s `txIndexBasicTransferMetadata` field. */
  txIndexBasicTransferMetadata?: InputMaybe<JsonFilter>;
  /** Filter by the object’s `txIndexMetadata` field. */
  txIndexMetadata?: InputMaybe<JsonFilter>;
  /** Filter by the object’s `txnMeta` field. */
  txnMeta?: InputMaybe<JsonFilter>;
  /** Filter by the object’s `txnType` field. */
  txnType?: InputMaybe<IntFilter>;
  /** Filter by the object’s `utxoOperationsByBlockHashAndTransactionIndex` relation. */
  utxoOperationsByBlockHashAndTransactionIndex?: InputMaybe<TransactionToManyUtxoOperationFilter>;
  /** Some related `utxoOperationsByBlockHashAndTransactionIndex` exist. */
  utxoOperationsByBlockHashAndTransactionIndexExist?: InputMaybe<
    Scalars["Boolean"]["input"]
  >;
  /** Filter by the object’s `version` field. */
  version?: InputMaybe<IntFilter>;
  /** Filter by the object’s `wrapperTransaction` relation. */
  wrapperTransaction?: InputMaybe<TransactionFilter>;
  /** A related `wrapperTransaction` exists. */
  wrapperTransactionExists?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Filter by the object’s `wrapperTransactionHash` field. */
  wrapperTransactionHash?: InputMaybe<StringFilter>;
};

/** A filter to be used against many `AffectedPublicKey` object types. All fields are combined with a logical ‘and.’ */
export type TransactionToManyAffectedPublicKeyFilter = {
  /** Every related `AffectedPublicKey` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<AffectedPublicKeyFilter>;
  /** No related `AffectedPublicKey` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<AffectedPublicKeyFilter>;
  /** Some related `AffectedPublicKey` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<AffectedPublicKeyFilter>;
};

/** A filter to be used against many `Transaction` object types. All fields are combined with a logical ‘and.’ */
export type TransactionToManyTransactionFilter = {
  /** Every related `Transaction` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<TransactionFilter>;
  /** No related `Transaction` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<TransactionFilter>;
  /** Some related `Transaction` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<TransactionFilter>;
};

/** A filter to be used against many `UtxoOperation` object types. All fields are combined with a logical ‘and.’ */
export type TransactionToManyUtxoOperationFilter = {
  /** Every related `UtxoOperation` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<UtxoOperationFilter>;
  /** No related `UtxoOperation` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<UtxoOperationFilter>;
  /** Some related `UtxoOperation` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<UtxoOperationFilter>;
};

export type TransactionType = Node & {
  __typename?: "TransactionType";
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  id: Scalars["ID"]["output"];
  name: Scalars["String"]["output"];
  /** Reads a single `Transaction` that is related to this `TransactionType`. */
  transaction?: Maybe<Transaction>;
  type: Scalars["Int"]["output"];
};

/**
 * A condition to be used against `TransactionType` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type TransactionTypeCondition = {
  /** Checks for equality with the object’s `name` field. */
  name?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `type` field. */
  type?: InputMaybe<Scalars["Int"]["input"]>;
};

/** A filter to be used against `TransactionType` object types. All fields are combined with a logical ‘and.’ */
export type TransactionTypeFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<TransactionTypeFilter>>;
  /** Filter by the object’s `name` field. */
  name?: InputMaybe<StringFilter>;
  /** Negates the expression. */
  not?: InputMaybe<TransactionTypeFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<TransactionTypeFilter>>;
  /** Filter by the object’s `transaction` relation. */
  transaction?: InputMaybe<TransactionFilter>;
  /** Filter by the object’s `type` field. */
  type?: InputMaybe<IntFilter>;
};

/** A connection to a list of `TransactionType` values. */
export type TransactionTypesConnection = {
  __typename?: "TransactionTypesConnection";
  /** A list of edges which contains the `TransactionType` and cursor to aid in pagination. */
  edges: Array<TransactionTypesEdge>;
  /** A list of `TransactionType` objects. */
  nodes: Array<Maybe<TransactionType>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `TransactionType` you could get from the connection. */
  totalCount: Scalars["Int"]["output"];
};

/** A `TransactionType` edge in the connection. */
export type TransactionTypesEdge = {
  __typename?: "TransactionTypesEdge";
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars["Cursor"]["output"]>;
  /** The `TransactionType` at the end of the edge. */
  node?: Maybe<TransactionType>;
};

/** Methods to use when ordering `TransactionType`. */
export enum TransactionTypesOrderBy {
  NameAsc = "NAME_ASC",
  NameDesc = "NAME_DESC",
  Natural = "NATURAL",
  PrimaryKeyAsc = "PRIMARY_KEY_ASC",
  PrimaryKeyDesc = "PRIMARY_KEY_DESC",
  TypeAsc = "TYPE_ASC",
  TypeDesc = "TYPE_DESC",
}

/** A connection to a list of `Transaction` values. */
export type TransactionsConnection = {
  __typename?: "TransactionsConnection";
  /** A list of edges which contains the `Transaction` and cursor to aid in pagination. */
  edges: Array<TransactionsEdge>;
  /** A list of `Transaction` objects. */
  nodes: Array<Maybe<Transaction>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Transaction` you could get from the connection. */
  totalCount: Scalars["Int"]["output"];
};

/** A `Transaction` edge in the connection. */
export type TransactionsEdge = {
  __typename?: "TransactionsEdge";
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars["Cursor"]["output"]>;
  /** The `Transaction` at the end of the edge. */
  node?: Maybe<Transaction>;
};

/** Methods to use when ordering `Transaction`. */
export enum TransactionsOrderBy {
  BlockHashAsc = "BLOCK_HASH_ASC",
  BlockHashDesc = "BLOCK_HASH_DESC",
  BlockHeightAsc = "BLOCK_HEIGHT_ASC",
  BlockHeightDesc = "BLOCK_HEIGHT_DESC",
  ExtraDataAsc = "EXTRA_DATA_ASC",
  ExtraDataDesc = "EXTRA_DATA_DESC",
  FeeNanosAsc = "FEE_NANOS_ASC",
  FeeNanosDesc = "FEE_NANOS_DESC",
  IndexInBlockAsc = "INDEX_IN_BLOCK_ASC",
  IndexInBlockDesc = "INDEX_IN_BLOCK_DESC",
  IndexInWrapperTransactionAsc = "INDEX_IN_WRAPPER_TRANSACTION_ASC",
  IndexInWrapperTransactionDesc = "INDEX_IN_WRAPPER_TRANSACTION_DESC",
  InputsAsc = "INPUTS_ASC",
  InputsDesc = "INPUTS_DESC",
  Natural = "NATURAL",
  NonceExpirationBlockHeightAsc = "NONCE_EXPIRATION_BLOCK_HEIGHT_ASC",
  NonceExpirationBlockHeightDesc = "NONCE_EXPIRATION_BLOCK_HEIGHT_DESC",
  NoncePartialIdAsc = "NONCE_PARTIAL_ID_ASC",
  NoncePartialIdDesc = "NONCE_PARTIAL_ID_DESC",
  OutputsAsc = "OUTPUTS_ASC",
  OutputsDesc = "OUTPUTS_DESC",
  PublicKeyAsc = "PUBLIC_KEY_ASC",
  PublicKeyDesc = "PUBLIC_KEY_DESC",
  SignatureAsc = "SIGNATURE_ASC",
  SignatureDesc = "SIGNATURE_DESC",
  TimestampAsc = "TIMESTAMP_ASC",
  TimestampDesc = "TIMESTAMP_DESC",
  TransactionHashAsc = "TRANSACTION_HASH_ASC",
  TransactionHashDesc = "TRANSACTION_HASH_DESC",
  TransactionIdAsc = "TRANSACTION_ID_ASC",
  TransactionIdDesc = "TRANSACTION_ID_DESC",
  TxnBytesAsc = "TXN_BYTES_ASC",
  TxnBytesDesc = "TXN_BYTES_DESC",
  TxnMetaAsc = "TXN_META_ASC",
  TxnMetaBytesAsc = "TXN_META_BYTES_ASC",
  TxnMetaBytesDesc = "TXN_META_BYTES_DESC",
  TxnMetaDesc = "TXN_META_DESC",
  TxnTypeAsc = "TXN_TYPE_ASC",
  TxnTypeDesc = "TXN_TYPE_DESC",
  TxIndexBasicTransferMetadataAsc = "TX_INDEX_BASIC_TRANSFER_METADATA_ASC",
  TxIndexBasicTransferMetadataDesc = "TX_INDEX_BASIC_TRANSFER_METADATA_DESC",
  TxIndexMetadataAsc = "TX_INDEX_METADATA_ASC",
  TxIndexMetadataDesc = "TX_INDEX_METADATA_DESC",
  VersionAsc = "VERSION_ASC",
  VersionDesc = "VERSION_DESC",
  WrapperTransactionHashAsc = "WRAPPER_TRANSACTION_HASH_ASC",
  WrapperTransactionHashDesc = "WRAPPER_TRANSACTION_HASH_DESC",
}

export type UserAssociation = Node & {
  __typename?: "UserAssociation";
  /** Reads a single `Account` that is related to this `UserAssociation`. */
  app?: Maybe<Account>;
  appPkid?: Maybe<Scalars["String"]["output"]>;
  associationId?: Maybe<Scalars["String"]["output"]>;
  associationType: Scalars["String"]["output"];
  associationValue: Scalars["String"]["output"];
  /** Reads a single `Block` that is related to this `UserAssociation`. */
  block?: Maybe<Block>;
  blockHeight?: Maybe<Scalars["Int"]["output"]>;
  extraData?: Maybe<Scalars["JSON"]["output"]>;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  id: Scalars["ID"]["output"];
  /** Reads a single `Account` that is related to this `UserAssociation`. */
  target?: Maybe<Account>;
  targetUserPkid?: Maybe<Scalars["String"]["output"]>;
  /** Reads a single `Account` that is related to this `UserAssociation`. */
  transactor?: Maybe<Account>;
  transactorPkid?: Maybe<Scalars["String"]["output"]>;
};

/**
 * A condition to be used against `UserAssociation` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type UserAssociationCondition = {
  /** Checks for equality with the object’s `appPkid` field. */
  appPkid?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `associationId` field. */
  associationId?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `associationType` field. */
  associationType?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `associationValue` field. */
  associationValue?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `blockHeight` field. */
  blockHeight?: InputMaybe<Scalars["Int"]["input"]>;
  /** Checks for equality with the object’s `extraData` field. */
  extraData?: InputMaybe<Scalars["JSON"]["input"]>;
  /** Checks for equality with the object’s `targetUserPkid` field. */
  targetUserPkid?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `transactorPkid` field. */
  transactorPkid?: InputMaybe<Scalars["String"]["input"]>;
};

/** A filter to be used against `UserAssociation` object types. All fields are combined with a logical ‘and.’ */
export type UserAssociationFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<UserAssociationFilter>>;
  /** Filter by the object’s `app` relation. */
  app?: InputMaybe<AccountFilter>;
  /** A related `app` exists. */
  appExists?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Filter by the object’s `appPkid` field. */
  appPkid?: InputMaybe<StringFilter>;
  /** Filter by the object’s `associationId` field. */
  associationId?: InputMaybe<StringFilter>;
  /** Filter by the object’s `associationType` field. */
  associationType?: InputMaybe<StringFilter>;
  /** Filter by the object’s `associationValue` field. */
  associationValue?: InputMaybe<StringFilter>;
  /** Filter by the object’s `block` relation. */
  block?: InputMaybe<BlockFilter>;
  /** A related `block` exists. */
  blockExists?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Filter by the object’s `blockHeight` field. */
  blockHeight?: InputMaybe<IntFilter>;
  /** Filter by the object’s `extraData` field. */
  extraData?: InputMaybe<JsonFilter>;
  /** Negates the expression. */
  not?: InputMaybe<UserAssociationFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<UserAssociationFilter>>;
  /** Filter by the object’s `target` relation. */
  target?: InputMaybe<AccountFilter>;
  /** A related `target` exists. */
  targetExists?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Filter by the object’s `targetUserPkid` field. */
  targetUserPkid?: InputMaybe<StringFilter>;
  /** Filter by the object’s `transactor` relation. */
  transactor?: InputMaybe<AccountFilter>;
  /** A related `transactor` exists. */
  transactorExists?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Filter by the object’s `transactorPkid` field. */
  transactorPkid?: InputMaybe<StringFilter>;
};

/** A connection to a list of `UserAssociation` values. */
export type UserAssociationsConnection = {
  __typename?: "UserAssociationsConnection";
  /** A list of edges which contains the `UserAssociation` and cursor to aid in pagination. */
  edges: Array<UserAssociationsEdge>;
  /** A list of `UserAssociation` objects. */
  nodes: Array<Maybe<UserAssociation>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `UserAssociation` you could get from the connection. */
  totalCount: Scalars["Int"]["output"];
};

/** A `UserAssociation` edge in the connection. */
export type UserAssociationsEdge = {
  __typename?: "UserAssociationsEdge";
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars["Cursor"]["output"]>;
  /** The `UserAssociation` at the end of the edge. */
  node?: Maybe<UserAssociation>;
};

/** Methods to use when ordering `UserAssociation`. */
export enum UserAssociationsOrderBy {
  AppPkidAsc = "APP_PKID_ASC",
  AppPkidDesc = "APP_PKID_DESC",
  AssociationIdAsc = "ASSOCIATION_ID_ASC",
  AssociationIdDesc = "ASSOCIATION_ID_DESC",
  AssociationTypeAsc = "ASSOCIATION_TYPE_ASC",
  AssociationTypeDesc = "ASSOCIATION_TYPE_DESC",
  AssociationValueAsc = "ASSOCIATION_VALUE_ASC",
  AssociationValueDesc = "ASSOCIATION_VALUE_DESC",
  BlockHeightAsc = "BLOCK_HEIGHT_ASC",
  BlockHeightDesc = "BLOCK_HEIGHT_DESC",
  ExtraDataAsc = "EXTRA_DATA_ASC",
  ExtraDataDesc = "EXTRA_DATA_DESC",
  Natural = "NATURAL",
  PrimaryKeyAsc = "PRIMARY_KEY_ASC",
  PrimaryKeyDesc = "PRIMARY_KEY_DESC",
  TargetUserPkidAsc = "TARGET_USER_PKID_ASC",
  TargetUserPkidDesc = "TARGET_USER_PKID_DESC",
  TransactorPkidAsc = "TRANSACTOR_PKID_ASC",
  TransactorPkidDesc = "TRANSACTOR_PKID_DESC",
}

export type UtxoOperation = {
  __typename?: "UtxoOperation";
  badgerKey: Scalars["String"]["output"];
  blockHash: Scalars["String"]["output"];
  operationType: Scalars["Int"]["output"];
  /** Reads a single `Transaction` that is related to this `UtxoOperation`. */
  transaction?: Maybe<Transaction>;
  transactionIndex: Scalars["Int"]["output"];
  utxoOpBytes: Scalars["String"]["output"];
  utxoOpIndex: Scalars["Int"]["output"];
};

/**
 * A condition to be used against `UtxoOperation` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type UtxoOperationCondition = {
  /** Checks for equality with the object’s `badgerKey` field. */
  badgerKey?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `blockHash` field. */
  blockHash?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `operationType` field. */
  operationType?: InputMaybe<Scalars["Int"]["input"]>;
  /** Checks for equality with the object’s `transactionIndex` field. */
  transactionIndex?: InputMaybe<Scalars["Int"]["input"]>;
  /** Checks for equality with the object’s `utxoOpBytes` field. */
  utxoOpBytes?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `utxoOpIndex` field. */
  utxoOpIndex?: InputMaybe<Scalars["Int"]["input"]>;
};

/** A filter to be used against `UtxoOperation` object types. All fields are combined with a logical ‘and.’ */
export type UtxoOperationFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<UtxoOperationFilter>>;
  /** Filter by the object’s `blockHash` field. */
  blockHash?: InputMaybe<StringFilter>;
  /** Negates the expression. */
  not?: InputMaybe<UtxoOperationFilter>;
  /** Filter by the object’s `operationType` field. */
  operationType?: InputMaybe<IntFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<UtxoOperationFilter>>;
  /** Filter by the object’s `transaction` relation. */
  transaction?: InputMaybe<TransactionFilter>;
  /** Filter by the object’s `transactionIndex` field. */
  transactionIndex?: InputMaybe<IntFilter>;
  /** Filter by the object’s `utxoOpIndex` field. */
  utxoOpIndex?: InputMaybe<IntFilter>;
};

/** A connection to a list of `UtxoOperation` values. */
export type UtxoOperationsConnection = {
  __typename?: "UtxoOperationsConnection";
  /** A list of edges which contains the `UtxoOperation` and cursor to aid in pagination. */
  edges: Array<UtxoOperationsEdge>;
  /** A list of `UtxoOperation` objects. */
  nodes: Array<Maybe<UtxoOperation>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `UtxoOperation` you could get from the connection. */
  totalCount: Scalars["Int"]["output"];
};

/** A `UtxoOperation` edge in the connection. */
export type UtxoOperationsEdge = {
  __typename?: "UtxoOperationsEdge";
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars["Cursor"]["output"]>;
  /** The `UtxoOperation` at the end of the edge. */
  node?: Maybe<UtxoOperation>;
};

/** Methods to use when ordering `UtxoOperation`. */
export enum UtxoOperationsOrderBy {
  BadgerKeyAsc = "BADGER_KEY_ASC",
  BadgerKeyDesc = "BADGER_KEY_DESC",
  BlockHashAsc = "BLOCK_HASH_ASC",
  BlockHashDesc = "BLOCK_HASH_DESC",
  Natural = "NATURAL",
  OperationTypeAsc = "OPERATION_TYPE_ASC",
  OperationTypeDesc = "OPERATION_TYPE_DESC",
  TransactionIndexAsc = "TRANSACTION_INDEX_ASC",
  TransactionIndexDesc = "TRANSACTION_INDEX_DESC",
  UtxoOpBytesAsc = "UTXO_OP_BYTES_ASC",
  UtxoOpBytesDesc = "UTXO_OP_BYTES_DESC",
  UtxoOpIndexAsc = "UTXO_OP_INDEX_ASC",
  UtxoOpIndexDesc = "UTXO_OP_INDEX_DESC",
}

/** A connection to a list of `ValidatorEntry` values. */
export type ValidatorEntriesConnection = {
  __typename?: "ValidatorEntriesConnection";
  /** A list of edges which contains the `ValidatorEntry` and cursor to aid in pagination. */
  edges: Array<ValidatorEntriesEdge>;
  /** A list of `ValidatorEntry` objects. */
  nodes: Array<Maybe<ValidatorEntry>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `ValidatorEntry` you could get from the connection. */
  totalCount: Scalars["Int"]["output"];
};

/** A `ValidatorEntry` edge in the connection. */
export type ValidatorEntriesEdge = {
  __typename?: "ValidatorEntriesEdge";
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars["Cursor"]["output"]>;
  /** The `ValidatorEntry` at the end of the edge. */
  node?: Maybe<ValidatorEntry>;
};

/** Methods to use when ordering `ValidatorEntry`. */
export enum ValidatorEntriesOrderBy {
  DelegatedStakeCommissionBasisPointsAsc = "DELEGATED_STAKE_COMMISSION_BASIS_POINTS_ASC",
  DelegatedStakeCommissionBasisPointsDesc = "DELEGATED_STAKE_COMMISSION_BASIS_POINTS_DESC",
  DisableDelegatedStakeAsc = "DISABLE_DELEGATED_STAKE_ASC",
  DisableDelegatedStakeDesc = "DISABLE_DELEGATED_STAKE_DESC",
  DomainsAsc = "DOMAINS_ASC",
  DomainsDesc = "DOMAINS_DESC",
  ExtraDataAsc = "EXTRA_DATA_ASC",
  ExtraDataDesc = "EXTRA_DATA_DESC",
  JailedAtEpochNumberAsc = "JAILED_AT_EPOCH_NUMBER_ASC",
  JailedAtEpochNumberDesc = "JAILED_AT_EPOCH_NUMBER_DESC",
  LastActiveAtEpochNumberAsc = "LAST_ACTIVE_AT_EPOCH_NUMBER_ASC",
  LastActiveAtEpochNumberDesc = "LAST_ACTIVE_AT_EPOCH_NUMBER_DESC",
  Natural = "NATURAL",
  PrimaryKeyAsc = "PRIMARY_KEY_ASC",
  PrimaryKeyDesc = "PRIMARY_KEY_DESC",
  TotalStakeAmountNanosAsc = "TOTAL_STAKE_AMOUNT_NANOS_ASC",
  TotalStakeAmountNanosDesc = "TOTAL_STAKE_AMOUNT_NANOS_DESC",
  ValidatorPkidAsc = "VALIDATOR_PKID_ASC",
  ValidatorPkidDesc = "VALIDATOR_PKID_DESC",
  VotingAuthorizationAsc = "VOTING_AUTHORIZATION_ASC",
  VotingAuthorizationDesc = "VOTING_AUTHORIZATION_DESC",
  VotingPublicKeyAsc = "VOTING_PUBLIC_KEY_ASC",
  VotingPublicKeyDesc = "VOTING_PUBLIC_KEY_DESC",
}

export type ValidatorEntry = Node & {
  __typename?: "ValidatorEntry";
  /** Reads a single `Account` that is related to this `ValidatorEntry`. */
  account?: Maybe<Account>;
  delegatedStakeCommissionBasisPoints?: Maybe<Scalars["BigInt"]["output"]>;
  disableDelegatedStake?: Maybe<Scalars["Boolean"]["output"]>;
  domains?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  extraData?: Maybe<Scalars["JSON"]["output"]>;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  id: Scalars["ID"]["output"];
  jailedAtEpochNumber?: Maybe<Scalars["BigInt"]["output"]>;
  /** Reads and enables pagination through a set of `JailedHistoryEvent`. */
  jailedHistoryEvents: JailedHistoryEventsConnection;
  lastActiveAtEpochNumber?: Maybe<Scalars["BigInt"]["output"]>;
  /** Reads and enables pagination through a set of `LeaderScheduleEntry`. */
  leaderScheduleEntries: LeaderScheduleEntriesConnection;
  /** Reads and enables pagination through a set of `StakeEntry`. */
  stakeEntries: StakeEntriesConnection;
  totalStakeAmountNanos: Scalars["BigFloat"]["output"];
  /** Reads and enables pagination through a set of `LockedStakeEntry`. */
  validatorLockedStakeEntries: LockedStakeEntriesConnection;
  validatorPkid: Scalars["String"]["output"];
  /** Reads and enables pagination through a set of `StakeRewardsByValidatorAndStaker`. */
  validatorStakeRewardsByStaker: StakeRewardsByValidatorAndStakersConnection;
  /** Reads a single `ValidatorStat` that is related to this `ValidatorEntry`. */
  validatorStats?: Maybe<ValidatorStat>;
  votingAuthorization?: Maybe<Scalars["String"]["output"]>;
  votingPublicKey?: Maybe<Scalars["String"]["output"]>;
};

export type ValidatorEntryJailedHistoryEventsArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<JailedHistoryEventCondition>;
  filter?: InputMaybe<JailedHistoryEventFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<JailedHistoryEventsOrderBy>>;
};

export type ValidatorEntryLeaderScheduleEntriesArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<LeaderScheduleEntryCondition>;
  filter?: InputMaybe<LeaderScheduleEntryFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<LeaderScheduleEntriesOrderBy>>;
};

export type ValidatorEntryStakeEntriesArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<StakeEntryCondition>;
  filter?: InputMaybe<StakeEntryFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<StakeEntriesOrderBy>>;
};

export type ValidatorEntryValidatorLockedStakeEntriesArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<LockedStakeEntryCondition>;
  filter?: InputMaybe<LockedStakeEntryFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<LockedStakeEntriesOrderBy>>;
};

export type ValidatorEntryValidatorStakeRewardsByStakerArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<StakeRewardsByValidatorAndStakerCondition>;
  filter?: InputMaybe<StakeRewardsByValidatorAndStakerFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<StakeRewardsByValidatorAndStakersOrderBy>>;
};

/**
 * A condition to be used against `ValidatorEntry` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type ValidatorEntryCondition = {
  /** Checks for equality with the object’s `delegatedStakeCommissionBasisPoints` field. */
  delegatedStakeCommissionBasisPoints?: InputMaybe<Scalars["BigInt"]["input"]>;
  /** Checks for equality with the object’s `disableDelegatedStake` field. */
  disableDelegatedStake?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Checks for equality with the object’s `domains` field. */
  domains?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** Checks for equality with the object’s `extraData` field. */
  extraData?: InputMaybe<Scalars["JSON"]["input"]>;
  /** Checks for equality with the object’s `jailedAtEpochNumber` field. */
  jailedAtEpochNumber?: InputMaybe<Scalars["BigInt"]["input"]>;
  /** Checks for equality with the object’s `lastActiveAtEpochNumber` field. */
  lastActiveAtEpochNumber?: InputMaybe<Scalars["BigInt"]["input"]>;
  /** Checks for equality with the object’s `totalStakeAmountNanos` field. */
  totalStakeAmountNanos?: InputMaybe<Scalars["BigFloat"]["input"]>;
  /** Checks for equality with the object’s `validatorPkid` field. */
  validatorPkid?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `votingAuthorization` field. */
  votingAuthorization?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `votingPublicKey` field. */
  votingPublicKey?: InputMaybe<Scalars["String"]["input"]>;
};

/** A filter to be used against `ValidatorEntry` object types. All fields are combined with a logical ‘and.’ */
export type ValidatorEntryFilter = {
  /** Filter by the object’s `account` relation. */
  account?: InputMaybe<AccountFilter>;
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<ValidatorEntryFilter>>;
  /** Filter by the object’s `delegatedStakeCommissionBasisPoints` field. */
  delegatedStakeCommissionBasisPoints?: InputMaybe<BigIntFilter>;
  /** Filter by the object’s `disableDelegatedStake` field. */
  disableDelegatedStake?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `domains` field. */
  domains?: InputMaybe<StringListFilter>;
  /** Filter by the object’s `extraData` field. */
  extraData?: InputMaybe<JsonFilter>;
  /** Filter by the object’s `jailedAtEpochNumber` field. */
  jailedAtEpochNumber?: InputMaybe<BigIntFilter>;
  /** Filter by the object’s `jailedHistoryEvents` relation. */
  jailedHistoryEvents?: InputMaybe<ValidatorEntryToManyJailedHistoryEventFilter>;
  /** Some related `jailedHistoryEvents` exist. */
  jailedHistoryEventsExist?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Filter by the object’s `lastActiveAtEpochNumber` field. */
  lastActiveAtEpochNumber?: InputMaybe<BigIntFilter>;
  /** Filter by the object’s `leaderScheduleEntries` relation. */
  leaderScheduleEntries?: InputMaybe<ValidatorEntryToManyLeaderScheduleEntryFilter>;
  /** Some related `leaderScheduleEntries` exist. */
  leaderScheduleEntriesExist?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Negates the expression. */
  not?: InputMaybe<ValidatorEntryFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<ValidatorEntryFilter>>;
  /** Filter by the object’s `stakeEntries` relation. */
  stakeEntries?: InputMaybe<ValidatorEntryToManyStakeEntryFilter>;
  /** Some related `stakeEntries` exist. */
  stakeEntriesExist?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Filter by the object’s `totalStakeAmountNanos` field. */
  totalStakeAmountNanos?: InputMaybe<BigFloatFilter>;
  /** Filter by the object’s `validatorLockedStakeEntries` relation. */
  validatorLockedStakeEntries?: InputMaybe<ValidatorEntryToManyLockedStakeEntryFilter>;
  /** Some related `validatorLockedStakeEntries` exist. */
  validatorLockedStakeEntriesExist?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Filter by the object’s `validatorPkid` field. */
  validatorPkid?: InputMaybe<StringFilter>;
  /** Filter by the object’s `validatorStakeRewardsByStaker` relation. */
  validatorStakeRewardsByStaker?: InputMaybe<ValidatorEntryToManyStakeRewardsByValidatorAndStakerFilter>;
  /** Some related `validatorStakeRewardsByStaker` exist. */
  validatorStakeRewardsByStakerExist?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Filter by the object’s `validatorStats` relation. */
  validatorStats?: InputMaybe<ValidatorStatFilter>;
  /** A related `validatorStats` exists. */
  validatorStatsExists?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Filter by the object’s `votingAuthorization` field. */
  votingAuthorization?: InputMaybe<StringFilter>;
  /** Filter by the object’s `votingPublicKey` field. */
  votingPublicKey?: InputMaybe<StringFilter>;
};

/** A filter to be used against many `JailedHistoryEvent` object types. All fields are combined with a logical ‘and.’ */
export type ValidatorEntryToManyJailedHistoryEventFilter = {
  /** Every related `JailedHistoryEvent` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<JailedHistoryEventFilter>;
  /** No related `JailedHistoryEvent` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<JailedHistoryEventFilter>;
  /** Some related `JailedHistoryEvent` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<JailedHistoryEventFilter>;
};

/** A filter to be used against many `LeaderScheduleEntry` object types. All fields are combined with a logical ‘and.’ */
export type ValidatorEntryToManyLeaderScheduleEntryFilter = {
  /** Every related `LeaderScheduleEntry` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<LeaderScheduleEntryFilter>;
  /** No related `LeaderScheduleEntry` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<LeaderScheduleEntryFilter>;
  /** Some related `LeaderScheduleEntry` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<LeaderScheduleEntryFilter>;
};

/** A filter to be used against many `LockedStakeEntry` object types. All fields are combined with a logical ‘and.’ */
export type ValidatorEntryToManyLockedStakeEntryFilter = {
  /** Every related `LockedStakeEntry` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<LockedStakeEntryFilter>;
  /** No related `LockedStakeEntry` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<LockedStakeEntryFilter>;
  /** Some related `LockedStakeEntry` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<LockedStakeEntryFilter>;
};

/** A filter to be used against many `StakeEntry` object types. All fields are combined with a logical ‘and.’ */
export type ValidatorEntryToManyStakeEntryFilter = {
  /** Every related `StakeEntry` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<StakeEntryFilter>;
  /** No related `StakeEntry` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<StakeEntryFilter>;
  /** Some related `StakeEntry` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<StakeEntryFilter>;
};

/** A filter to be used against many `StakeRewardsByValidatorAndStaker` object types. All fields are combined with a logical ‘and.’ */
export type ValidatorEntryToManyStakeRewardsByValidatorAndStakerFilter = {
  /** Every related `StakeRewardsByValidatorAndStaker` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<StakeRewardsByValidatorAndStakerFilter>;
  /** No related `StakeRewardsByValidatorAndStaker` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<StakeRewardsByValidatorAndStakerFilter>;
  /** Some related `StakeRewardsByValidatorAndStaker` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<StakeRewardsByValidatorAndStakerFilter>;
};

export type ValidatorStat = Node & {
  __typename?: "ValidatorStat";
  epochsInJail?: Maybe<Scalars["BigFloat"]["output"]>;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  id: Scalars["ID"]["output"];
  numEpochsInLeaderSchedule?: Maybe<Scalars["BigInt"]["output"]>;
  percentEpochsInLeaderSchedule?: Maybe<Scalars["Float"]["output"]>;
  percentTotalStake?: Maybe<Scalars["Float"]["output"]>;
  totalStakeRewardNanos?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Reads a single `ValidatorEntry` that is related to this `ValidatorStat`. */
  validatorEntry?: Maybe<ValidatorEntry>;
  validatorPkid: Scalars["String"]["output"];
  validatorRank?: Maybe<Scalars["BigInt"]["output"]>;
};

/**
 * A condition to be used against `ValidatorStat` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type ValidatorStatCondition = {
  /** Checks for equality with the object’s `epochsInJail` field. */
  epochsInJail?: InputMaybe<Scalars["BigFloat"]["input"]>;
  /** Checks for equality with the object’s `numEpochsInLeaderSchedule` field. */
  numEpochsInLeaderSchedule?: InputMaybe<Scalars["BigInt"]["input"]>;
  /** Checks for equality with the object’s `percentEpochsInLeaderSchedule` field. */
  percentEpochsInLeaderSchedule?: InputMaybe<Scalars["Float"]["input"]>;
  /** Checks for equality with the object’s `percentTotalStake` field. */
  percentTotalStake?: InputMaybe<Scalars["Float"]["input"]>;
  /** Checks for equality with the object’s `totalStakeRewardNanos` field. */
  totalStakeRewardNanos?: InputMaybe<Scalars["BigFloat"]["input"]>;
  /** Checks for equality with the object’s `validatorPkid` field. */
  validatorPkid?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `validatorRank` field. */
  validatorRank?: InputMaybe<Scalars["BigInt"]["input"]>;
};

/** A filter to be used against `ValidatorStat` object types. All fields are combined with a logical ‘and.’ */
export type ValidatorStatFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<ValidatorStatFilter>>;
  /** Filter by the object’s `epochsInJail` field. */
  epochsInJail?: InputMaybe<BigFloatFilter>;
  /** Negates the expression. */
  not?: InputMaybe<ValidatorStatFilter>;
  /** Filter by the object’s `numEpochsInLeaderSchedule` field. */
  numEpochsInLeaderSchedule?: InputMaybe<BigIntFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<ValidatorStatFilter>>;
  /** Filter by the object’s `percentEpochsInLeaderSchedule` field. */
  percentEpochsInLeaderSchedule?: InputMaybe<FloatFilter>;
  /** Filter by the object’s `percentTotalStake` field. */
  percentTotalStake?: InputMaybe<FloatFilter>;
  /** Filter by the object’s `totalStakeRewardNanos` field. */
  totalStakeRewardNanos?: InputMaybe<BigFloatFilter>;
  /** Filter by the object’s `validatorEntry` relation. */
  validatorEntry?: InputMaybe<ValidatorEntryFilter>;
  /** A related `validatorEntry` exists. */
  validatorEntryExists?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Filter by the object’s `validatorPkid` field. */
  validatorPkid?: InputMaybe<StringFilter>;
  /** Filter by the object’s `validatorRank` field. */
  validatorRank?: InputMaybe<BigIntFilter>;
};

/** A connection to a list of `ValidatorStat` values. */
export type ValidatorStatsConnection = {
  __typename?: "ValidatorStatsConnection";
  /** A list of edges which contains the `ValidatorStat` and cursor to aid in pagination. */
  edges: Array<ValidatorStatsEdge>;
  /** A list of `ValidatorStat` objects. */
  nodes: Array<Maybe<ValidatorStat>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `ValidatorStat` you could get from the connection. */
  totalCount: Scalars["Int"]["output"];
};

/** A `ValidatorStat` edge in the connection. */
export type ValidatorStatsEdge = {
  __typename?: "ValidatorStatsEdge";
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars["Cursor"]["output"]>;
  /** The `ValidatorStat` at the end of the edge. */
  node?: Maybe<ValidatorStat>;
};

/** Methods to use when ordering `ValidatorStat`. */
export enum ValidatorStatsOrderBy {
  EpochsInJailAsc = "EPOCHS_IN_JAIL_ASC",
  EpochsInJailDesc = "EPOCHS_IN_JAIL_DESC",
  Natural = "NATURAL",
  NumEpochsInLeaderScheduleAsc = "NUM_EPOCHS_IN_LEADER_SCHEDULE_ASC",
  NumEpochsInLeaderScheduleDesc = "NUM_EPOCHS_IN_LEADER_SCHEDULE_DESC",
  PercentEpochsInLeaderScheduleAsc = "PERCENT_EPOCHS_IN_LEADER_SCHEDULE_ASC",
  PercentEpochsInLeaderScheduleDesc = "PERCENT_EPOCHS_IN_LEADER_SCHEDULE_DESC",
  PercentTotalStakeAsc = "PERCENT_TOTAL_STAKE_ASC",
  PercentTotalStakeDesc = "PERCENT_TOTAL_STAKE_DESC",
  PrimaryKeyAsc = "PRIMARY_KEY_ASC",
  PrimaryKeyDesc = "PRIMARY_KEY_DESC",
  TotalStakeRewardNanosAsc = "TOTAL_STAKE_REWARD_NANOS_ASC",
  TotalStakeRewardNanosDesc = "TOTAL_STAKE_REWARD_NANOS_DESC",
  ValidatorPkidAsc = "VALIDATOR_PKID_ASC",
  ValidatorPkidDesc = "VALIDATOR_PKID_DESC",
  ValidatorRankAsc = "VALIDATOR_RANK_ASC",
  ValidatorRankDesc = "VALIDATOR_RANK_DESC",
}

export type YieldCurvePoint = Node & {
  __typename?: "YieldCurvePoint";
  /** Reads a single `Account` that is related to this `YieldCurvePoint`. */
  account?: Maybe<Account>;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  id: Scalars["ID"]["output"];
  lockupDurationNanoSecs: Scalars["BigInt"]["output"];
  lockupYieldApyBasisPoints: Scalars["BigInt"]["output"];
  profilePkid: Scalars["String"]["output"];
};

/**
 * A condition to be used against `YieldCurvePoint` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type YieldCurvePointCondition = {
  /** Checks for equality with the object’s `lockupDurationNanoSecs` field. */
  lockupDurationNanoSecs?: InputMaybe<Scalars["BigInt"]["input"]>;
  /** Checks for equality with the object’s `lockupYieldApyBasisPoints` field. */
  lockupYieldApyBasisPoints?: InputMaybe<Scalars["BigInt"]["input"]>;
  /** Checks for equality with the object’s `profilePkid` field. */
  profilePkid?: InputMaybe<Scalars["String"]["input"]>;
};

/** A filter to be used against `YieldCurvePoint` object types. All fields are combined with a logical ‘and.’ */
export type YieldCurvePointFilter = {
  /** Filter by the object’s `account` relation. */
  account?: InputMaybe<AccountFilter>;
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<YieldCurvePointFilter>>;
  /** Filter by the object’s `lockupDurationNanoSecs` field. */
  lockupDurationNanoSecs?: InputMaybe<BigIntFilter>;
  /** Filter by the object’s `lockupYieldApyBasisPoints` field. */
  lockupYieldApyBasisPoints?: InputMaybe<BigIntFilter>;
  /** Negates the expression. */
  not?: InputMaybe<YieldCurvePointFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<YieldCurvePointFilter>>;
  /** Filter by the object’s `profilePkid` field. */
  profilePkid?: InputMaybe<StringFilter>;
};

/** A connection to a list of `YieldCurvePoint` values. */
export type YieldCurvePointsConnection = {
  __typename?: "YieldCurvePointsConnection";
  /** A list of edges which contains the `YieldCurvePoint` and cursor to aid in pagination. */
  edges: Array<YieldCurvePointsEdge>;
  /** A list of `YieldCurvePoint` objects. */
  nodes: Array<Maybe<YieldCurvePoint>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `YieldCurvePoint` you could get from the connection. */
  totalCount: Scalars["Int"]["output"];
};

/** A `YieldCurvePoint` edge in the connection. */
export type YieldCurvePointsEdge = {
  __typename?: "YieldCurvePointsEdge";
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars["Cursor"]["output"]>;
  /** The `YieldCurvePoint` at the end of the edge. */
  node?: Maybe<YieldCurvePoint>;
};

/** Methods to use when ordering `YieldCurvePoint`. */
export enum YieldCurvePointsOrderBy {
  LockupDurationNanoSecsAsc = "LOCKUP_DURATION_NANO_SECS_ASC",
  LockupDurationNanoSecsDesc = "LOCKUP_DURATION_NANO_SECS_DESC",
  LockupYieldApyBasisPointsAsc = "LOCKUP_YIELD_APY_BASIS_POINTS_ASC",
  LockupYieldApyBasisPointsDesc = "LOCKUP_YIELD_APY_BASIS_POINTS_DESC",
  Natural = "NATURAL",
  PrimaryKeyAsc = "PRIMARY_KEY_ASC",
  PrimaryKeyDesc = "PRIMARY_KEY_DESC",
  ProfilePkidAsc = "PROFILE_PKID_ASC",
  ProfilePkidDesc = "PROFILE_PKID_DESC",
}

export type CoreAccountFieldsFragment = {
  __typename?: "Account";
  publicKey: string;
  pkid?: string | null;
  username?: string | null;
};

export type AccountBalanceFragment = {
  __typename?: "Account";
  publicKey: string;
  desoBalance?: {
    __typename?: "DesoBalance";
    publicKey: string;
    balanceNanos: any;
  } | null;
};

export type GetDesoBalanceByPublicKeyQueryVariables = Exact<{
  publicKey: Scalars["String"]["input"];
}>;

export type GetDesoBalanceByPublicKeyQuery = {
  __typename?: "Query";
  accountByPublicKey?: {
    __typename?: "Account";
    publicKey: string;
    desoBalance?: {
      __typename?: "DesoBalance";
      publicKey: string;
      balanceNanos: any;
    } | null;
  } | null;
};

export type AccountByPublicKeyQueryVariables = Exact<{
  publicKey: Scalars["String"]["input"];
}>;

export type AccountByPublicKeyQuery = {
  __typename?: "Query";
  accountByPublicKey?: {
    __typename?: "Account";
    publicKey: string;
    pkid?: string | null;
    username?: string | null;
  } | null;
};

export type AccountExtendedByPublicKeyQueryVariables = Exact<{
  publicKey: Scalars["String"]["input"];
}>;

export type AccountExtendedByPublicKeyQuery = {
  __typename?: "Query";
  accountByPublicKey?: {
    __typename?: "Account";
    publicKey: string;
    pkid?: string | null;
    username?: string | null;
    profile?: {
      __typename?: "Profile";
      coinPriceDesoNanos?: any | null;
    } | null;
    desoBalance?: {
      __typename?: "DesoBalance";
      publicKey: string;
      balanceNanos: any;
    } | null;
  } | null;
};

export type AccountByUsernameQueryVariables = Exact<{
  username: Scalars["String"]["input"];
}>;

export type AccountByUsernameQuery = {
  __typename?: "Query";
  accountByUsername?: {
    __typename?: "Account";
    publicKey: string;
    pkid?: string | null;
    username?: string | null;
  } | null;
};

export type AccountDetailsFragment = {
  __typename?: "Account";
  extraData?: any | null;
  publicKey: string;
  pkid?: string | null;
  username?: string | null;
  desoBalance?: {
    __typename?: "DesoBalance";
    publicKey: string;
    balanceNanos: any;
  } | null;
};

export type AccountsFilteredQueryVariables = Exact<{
  filter?: InputMaybe<AccountFilter>;
}>;

export type AccountsFilteredQuery = {
  __typename?: "Query";
  accounts?: {
    __typename?: "AccountsConnection";
    nodes: Array<{
      __typename?: "Account";
      extraData?: any | null;
      publicKey: string;
      pkid?: string | null;
      username?: string | null;
      desoBalance?: {
        __typename?: "DesoBalance";
        publicKey: string;
        balanceNanos: any;
      } | null;
    } | null>;
  } | null;
};

export type AccountsSearchQueryVariables = Exact<{
  filter?: InputMaybe<AccountFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<AccountsOrderBy> | AccountsOrderBy>;
}>;

export type AccountsSearchQuery = {
  __typename?: "Query";
  accounts?: {
    __typename?: "AccountsConnection";
    nodes: Array<{
      __typename?: "Account";
      publicKey: string;
      pkid?: string | null;
      username?: string | null;
    } | null>;
  } | null;
};

export type AccountFollowersFragment = {
  __typename?: "Account";
  publicKey: string;
  followers: { __typename?: "FollowsConnection"; totalCount: number };
  following: { __typename?: "FollowsConnection"; totalCount: number };
};

export type AccountFollowersByPublicKeyQueryVariables = Exact<{
  publicKey: Scalars["String"]["input"];
}>;

export type AccountFollowersByPublicKeyQuery = {
  __typename?: "Query";
  accountByPublicKey?: {
    __typename?: "Account";
    publicKey: string;
    followers: { __typename?: "FollowsConnection"; totalCount: number };
    following: { __typename?: "FollowsConnection"; totalCount: number };
  } | null;
};

export type DashboardStatsQueryVariables = Exact<{ [key: string]: never }>;

export type DashboardStatsQuery = {
  __typename?: "Query";
  dashboardStats?: {
    __typename?: "DashboardStatsConnection";
    nodes: Array<{
      __typename?: "DashboardStat";
      txnCountAll?: any | null;
      txnCount30D?: any | null;
      walletCountAll?: any | null;
      activeWalletCount30D?: any | null;
      blockHeightCurrent?: any | null;
      txnCountPending?: any | null;
      txnFee1D?: any | null;
      totalSupply?: any | null;
      postCount?: any | null;
      commentCount?: any | null;
      repostCount?: any | null;
      txnCountCreatorCoin?: any | null;
      txnCountNft?: any | null;
      txnCountDex?: any | null;
      txnCountSocial?: any | null;
      followCount?: any | null;
      messageCount?: any | null;
      postLongformCount?: any | null;
    } | null>;
  } | null;
};

export type SocialLeaderboardStatsQueryVariables = Exact<{
  orderBy?: InputMaybe<
    Array<SocialLeaderboardStatsOrderBy> | SocialLeaderboardStatsOrderBy
  >;
}>;

export type SocialLeaderboardStatsQuery = {
  __typename?: "Query";
  socialLeaderboardStats?: {
    __typename?: "SocialLeaderboardStatsConnection";
    nodes: Array<{
      __typename?: "SocialLeaderboardStat";
      publicKey?: string | null;
      username?: string | null;
      count?: any | null;
    } | null>;
  } | null;
};

export type NftLeaderboardStatsQueryVariables = Exact<{
  orderBy?: InputMaybe<
    Array<NftLeaderboardStatsOrderBy> | NftLeaderboardStatsOrderBy
  >;
}>;

export type NftLeaderboardStatsQuery = {
  __typename?: "Query";
  nftLeaderboardStats?: {
    __typename?: "NftLeaderboardStatsConnection";
    nodes: Array<{
      __typename?: "NftLeaderboardStat";
      sum?: any | null;
      publicKey?: string | null;
      username?: string | null;
    } | null>;
  } | null;
};

export type StatisticDefiLeaderboardsQueryVariables = Exact<{
  orderBy?: InputMaybe<
    Array<DefiLeaderboardStatsOrderBy> | DefiLeaderboardStatsOrderBy
  >;
}>;

export type StatisticDefiLeaderboardsQuery = {
  __typename?: "Query";
  defiLeaderboardStats?: {
    __typename?: "DefiLeaderboardStatsConnection";
    nodes: Array<{
      __typename?: "DefiLeaderboardStat";
      buyingPublicKey?: string | null;
      username?: string | null;
      publicKey?: string | null;
      pkid?: string | null;
      netQuantity?: any | null;
    } | null>;
  } | null;
};

export type DailyTxnCountStatsQueryVariables = Exact<{
  orderBy?: InputMaybe<
    Array<DailyTxnCountStatsOrderBy> | DailyTxnCountStatsOrderBy
  >;
}>;

export type DailyTxnCountStatsQuery = {
  __typename?: "Query";
  dailyTxnCountStats?: {
    __typename?: "DailyTxnCountStatsConnection";
    nodes: Array<{
      __typename?: "DailyTxnCountStat";
      day?: any | null;
      transactionCount?: any | null;
    } | null>;
  } | null;
};

export type DailyActiveWalletCountStatsQueryVariables = Exact<{
  orderBy?: InputMaybe<
    | Array<DailyActiveWalletCountStatsOrderBy>
    | DailyActiveWalletCountStatsOrderBy
  >;
}>;

export type DailyActiveWalletCountStatsQuery = {
  __typename?: "Query";
  dailyActiveWalletCountStats?: {
    __typename?: "DailyActiveWalletCountStatsConnection";
    nodes: Array<{
      __typename?: "DailyActiveWalletCountStat";
      day?: any | null;
      count?: any | null;
    } | null>;
  } | null;
};

export type CoreBlockFieldsFragment = {
  __typename?: "Block";
  blockHash: string;
  height: any;
  timestamp: any;
};

export type PartialTransactionFieldsFragment = {
  __typename?: "Transaction";
  transactionHash?: string | null;
  blockHash?: string | null;
  outputs?: any | null;
  txnMeta?: any | null;
  txIndexMetadata?: any | null;
  txIndexBasicTransferMetadata?: any | null;
  transactionId?: string | null;
  txnType?: number | null;
  publicKey?: string | null;
  extraData?: any | null;
  indexInBlock?: number | null;
  wrapperTransactionHash?: string | null;
  indexInWrapperTransaction?: any | null;
};

export type CoreTransactionFieldsFragment = {
  __typename?: "Transaction";
  transactionHash?: string | null;
  blockHash?: string | null;
  version?: number | null;
  inputs?: any | null;
  outputs?: any | null;
  feeNanos?: any | null;
  nonceExpirationBlockHeight?: any | null;
  noncePartialId?: any | null;
  txnMeta?: any | null;
  txnMetaBytes?: string | null;
  txIndexMetadata?: any | null;
  txIndexBasicTransferMetadata?: any | null;
  transactionId?: string | null;
  txnType?: number | null;
  publicKey?: string | null;
  extraData?: any | null;
  signature?: string | null;
  txnBytes?: string | null;
  indexInBlock?: number | null;
  wrapperTransactionHash?: string | null;
  indexInWrapperTransaction?: any | null;
  account?: {
    __typename?: "Account";
    publicKey: string;
    pkid?: string | null;
    username?: string | null;
  } | null;
  affectedPublicKeys: {
    __typename?: "AffectedPublicKeysConnection";
    nodes: Array<{
      __typename?: "AffectedPublicKey";
      publicKey: string;
    } | null>;
  };
  wrapperTransaction?: {
    __typename?: "Transaction";
    transactionHash?: string | null;
    blockHash?: string | null;
    version?: number | null;
    inputs?: any | null;
    outputs?: any | null;
    feeNanos?: any | null;
    nonceExpirationBlockHeight?: any | null;
    noncePartialId?: any | null;
    txnMeta?: any | null;
    txnMetaBytes?: string | null;
    txIndexMetadata?: any | null;
    txIndexBasicTransferMetadata?: any | null;
    transactionId?: string | null;
    txnType?: number | null;
    publicKey?: string | null;
    extraData?: any | null;
    signature?: string | null;
    txnBytes?: string | null;
    indexInBlock?: number | null;
    wrapperTransactionHash?: string | null;
    indexInWrapperTransaction?: any | null;
  } | null;
  innerTransactions: {
    __typename?: "TransactionsConnection";
    nodes: Array<{
      __typename?: "Transaction";
      transactionHash?: string | null;
      blockHash?: string | null;
      version?: number | null;
      inputs?: any | null;
      outputs?: any | null;
      feeNanos?: any | null;
      nonceExpirationBlockHeight?: any | null;
      noncePartialId?: any | null;
      txnMeta?: any | null;
      txnMetaBytes?: string | null;
      txIndexMetadata?: any | null;
      txIndexBasicTransferMetadata?: any | null;
      transactionId?: string | null;
      txnType?: number | null;
      publicKey?: string | null;
      extraData?: any | null;
      signature?: string | null;
      txnBytes?: string | null;
      indexInBlock?: number | null;
      indexInWrapperTransaction?: any | null;
    } | null>;
  };
};

export type TransactionsQueryVariables = Exact<{
  first?: InputMaybe<Scalars["Int"]["input"]>;
  condition?: InputMaybe<TransactionCondition>;
  orderBy?: InputMaybe<Array<TransactionsOrderBy> | TransactionsOrderBy>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  filter?: InputMaybe<TransactionFilter>;
  withTotal: Scalars["Boolean"]["input"];
}>;

export type TransactionsQuery = {
  __typename?: "Query";
  transactions?: {
    __typename?: "TransactionsConnection";
    totalCount?: number;
    nodes: Array<{
      __typename?: "Transaction";
      transactionHash?: string | null;
      blockHash?: string | null;
      version?: number | null;
      inputs?: any | null;
      outputs?: any | null;
      feeNanos?: any | null;
      nonceExpirationBlockHeight?: any | null;
      noncePartialId?: any | null;
      txnMeta?: any | null;
      txnMetaBytes?: string | null;
      txIndexMetadata?: any | null;
      txIndexBasicTransferMetadata?: any | null;
      transactionId?: string | null;
      txnType?: number | null;
      publicKey?: string | null;
      extraData?: any | null;
      signature?: string | null;
      txnBytes?: string | null;
      indexInBlock?: number | null;
      wrapperTransactionHash?: string | null;
      indexInWrapperTransaction?: any | null;
      block?: {
        __typename?: "Block";
        blockHash: string;
        height: any;
        timestamp: any;
      } | null;
      account?: {
        __typename?: "Account";
        publicKey: string;
        pkid?: string | null;
        username?: string | null;
      } | null;
      affectedPublicKeys: {
        __typename?: "AffectedPublicKeysConnection";
        nodes: Array<{
          __typename?: "AffectedPublicKey";
          publicKey: string;
        } | null>;
      };
      wrapperTransaction?: {
        __typename?: "Transaction";
        transactionHash?: string | null;
        blockHash?: string | null;
        version?: number | null;
        inputs?: any | null;
        outputs?: any | null;
        feeNanos?: any | null;
        nonceExpirationBlockHeight?: any | null;
        noncePartialId?: any | null;
        txnMeta?: any | null;
        txnMetaBytes?: string | null;
        txIndexMetadata?: any | null;
        txIndexBasicTransferMetadata?: any | null;
        transactionId?: string | null;
        txnType?: number | null;
        publicKey?: string | null;
        extraData?: any | null;
        signature?: string | null;
        txnBytes?: string | null;
        indexInBlock?: number | null;
        wrapperTransactionHash?: string | null;
        indexInWrapperTransaction?: any | null;
      } | null;
      innerTransactions: {
        __typename?: "TransactionsConnection";
        nodes: Array<{
          __typename?: "Transaction";
          transactionHash?: string | null;
          blockHash?: string | null;
          version?: number | null;
          inputs?: any | null;
          outputs?: any | null;
          feeNanos?: any | null;
          nonceExpirationBlockHeight?: any | null;
          noncePartialId?: any | null;
          txnMeta?: any | null;
          txnMetaBytes?: string | null;
          txIndexMetadata?: any | null;
          txIndexBasicTransferMetadata?: any | null;
          transactionId?: string | null;
          txnType?: number | null;
          publicKey?: string | null;
          extraData?: any | null;
          signature?: string | null;
          txnBytes?: string | null;
          indexInBlock?: number | null;
          indexInWrapperTransaction?: any | null;
        } | null>;
      };
    } | null>;
    pageInfo: {
      __typename?: "PageInfo";
      hasPreviousPage: boolean;
      hasNextPage: boolean;
    };
  } | null;
};

export type AffectedPublicKeysQueryVariables = Exact<{
  filter?: InputMaybe<AffectedPublicKeyFilter>;
  orderBy?: InputMaybe<
    Array<AffectedPublicKeysOrderBy> | AffectedPublicKeysOrderBy
  >;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  withTotal: Scalars["Boolean"]["input"];
}>;

export type AffectedPublicKeysQuery = {
  __typename?: "Query";
  affectedPublicKeys?: {
    __typename?: "AffectedPublicKeysConnection";
    totalCount?: number;
    nodes: Array<{
      __typename?: "AffectedPublicKey";
      metadata: string;
      transaction?: {
        __typename?: "Transaction";
        transactionHash?: string | null;
        blockHash?: string | null;
        version?: number | null;
        inputs?: any | null;
        outputs?: any | null;
        feeNanos?: any | null;
        nonceExpirationBlockHeight?: any | null;
        noncePartialId?: any | null;
        txnMeta?: any | null;
        txnMetaBytes?: string | null;
        txIndexMetadata?: any | null;
        txIndexBasicTransferMetadata?: any | null;
        transactionId?: string | null;
        txnType?: number | null;
        publicKey?: string | null;
        extraData?: any | null;
        signature?: string | null;
        txnBytes?: string | null;
        indexInBlock?: number | null;
        wrapperTransactionHash?: string | null;
        indexInWrapperTransaction?: any | null;
        block?: {
          __typename?: "Block";
          blockHash: string;
          height: any;
          timestamp: any;
        } | null;
        account?: {
          __typename?: "Account";
          publicKey: string;
          pkid?: string | null;
          username?: string | null;
        } | null;
        affectedPublicKeys: {
          __typename?: "AffectedPublicKeysConnection";
          nodes: Array<{
            __typename?: "AffectedPublicKey";
            publicKey: string;
          } | null>;
        };
        wrapperTransaction?: {
          __typename?: "Transaction";
          transactionHash?: string | null;
          blockHash?: string | null;
          version?: number | null;
          inputs?: any | null;
          outputs?: any | null;
          feeNanos?: any | null;
          nonceExpirationBlockHeight?: any | null;
          noncePartialId?: any | null;
          txnMeta?: any | null;
          txnMetaBytes?: string | null;
          txIndexMetadata?: any | null;
          txIndexBasicTransferMetadata?: any | null;
          transactionId?: string | null;
          txnType?: number | null;
          publicKey?: string | null;
          extraData?: any | null;
          signature?: string | null;
          txnBytes?: string | null;
          indexInBlock?: number | null;
          wrapperTransactionHash?: string | null;
          indexInWrapperTransaction?: any | null;
        } | null;
        innerTransactions: {
          __typename?: "TransactionsConnection";
          nodes: Array<{
            __typename?: "Transaction";
            transactionHash?: string | null;
            blockHash?: string | null;
            version?: number | null;
            inputs?: any | null;
            outputs?: any | null;
            feeNanos?: any | null;
            nonceExpirationBlockHeight?: any | null;
            noncePartialId?: any | null;
            txnMeta?: any | null;
            txnMetaBytes?: string | null;
            txIndexMetadata?: any | null;
            txIndexBasicTransferMetadata?: any | null;
            transactionId?: string | null;
            txnType?: number | null;
            publicKey?: string | null;
            extraData?: any | null;
            signature?: string | null;
            txnBytes?: string | null;
            indexInBlock?: number | null;
            indexInWrapperTransaction?: any | null;
          } | null>;
        };
      } | null;
    } | null>;
    pageInfo: {
      __typename?: "PageInfo";
      hasPreviousPage: boolean;
      hasNextPage: boolean;
    };
  } | null;
};

export type BlockQueryVariables = Exact<{
  filter?: InputMaybe<BlockFilter>;
  orderBy?: InputMaybe<Array<TransactionsOrderBy> | TransactionsOrderBy>;
  transactionsFirst?: InputMaybe<Scalars["Int"]["input"]>;
  blocksFirst?: InputMaybe<Scalars["Int"]["input"]>;
  blocksOrderBy?: InputMaybe<Array<BlocksOrderBy> | BlocksOrderBy>;
  transactionsOffset?: InputMaybe<Scalars["Int"]["input"]>;
  blocksOffset?: InputMaybe<Scalars["Int"]["input"]>;
}>;

export type BlockQuery = {
  __typename?: "Query";
  blocks?: {
    __typename?: "BlocksConnection";
    totalCount: number;
    nodes: Array<{
      __typename?: "Block";
      blockHash: string;
      height: any;
      timestamp: any;
      transactions: {
        __typename?: "TransactionsConnection";
        totalCount: number;
        nodes: Array<{
          __typename?: "Transaction";
          transactionHash?: string | null;
          blockHash?: string | null;
          outputs?: any | null;
          txnMeta?: any | null;
          txIndexMetadata?: any | null;
          txIndexBasicTransferMetadata?: any | null;
          transactionId?: string | null;
          txnType?: number | null;
          publicKey?: string | null;
          extraData?: any | null;
          indexInBlock?: number | null;
          wrapperTransactionHash?: string | null;
          indexInWrapperTransaction?: any | null;
        } | null>;
      };
    } | null>;
  } | null;
};

export type BlockByHeightQueryVariables = Exact<{
  height: Scalars["BigInt"]["input"];
}>;

export type BlockByHeightQuery = {
  __typename?: "Query";
  blockByHeight?: {
    __typename?: "Block";
    blockHash: string;
    height: any;
    timestamp: any;
  } | null;
};

export type BlockByBlockHashQueryVariables = Exact<{
  blockHash: Scalars["String"]["input"];
}>;

export type BlockByBlockHashQuery = {
  __typename?: "Query";
  blockByBlockHash?: {
    __typename?: "Block";
    blockHash: string;
    height: any;
    timestamp: any;
  } | null;
};

export type TokenBalanceFieldsFragment = {
  __typename?: "TokenBalance";
  id: string;
  balanceNanos: any;
  hasPurchased: boolean;
  holder?: {
    __typename?: "Account";
    username?: string | null;
    publicKey: string;
  } | null;
};

export type ProfileNftTopOwnersQueryVariables = Exact<{
  orderBy?: InputMaybe<
    Array<ProfileNftTopOwnersOrderBy> | ProfileNftTopOwnersOrderBy
  >;
  filter?: InputMaybe<ProfileNftTopOwnerFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  publicKey: Scalars["String"]["input"];
  postsFilter2?: InputMaybe<PostFilter>;
}>;

export type ProfileNftTopOwnersQuery = {
  __typename?: "Query";
  profileNftTopOwners?: {
    __typename?: "ProfileNftTopOwnersConnection";
    nodes: Array<{
      __typename?: "ProfileNftTopOwner";
      creatorPublicKey?: string | null;
      publicKey?: string | null;
      username?: string | null;
      count?: any | null;
    } | null>;
  } | null;
  accountByPublicKey?: {
    __typename?: "Account";
    publicKey: string;
    posts: { __typename?: "PostsConnection"; totalCount: number };
  } | null;
};

export type ProfileTopDaoHoldersQueryVariables = Exact<{
  username: Scalars["String"]["input"];
  filter?: InputMaybe<TokenBalanceFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<TokenBalancesOrderBy> | TokenBalancesOrderBy>;
}>;

export type ProfileTopDaoHoldersQuery = {
  __typename?: "Query";
  accountByUsername?: {
    __typename?: "Account";
    publicKey: string;
    profile?: {
      __typename?: "Profile";
      daoCoinsInCirculationNanosHex: string;
    } | null;
    tokenBalancesAsCreator: {
      __typename?: "TokenBalancesConnection";
      nodes: Array<{
        __typename?: "TokenBalance";
        id: string;
        balanceNanos: any;
        hasPurchased: boolean;
        holder?: {
          __typename?: "Account";
          username?: string | null;
          publicKey: string;
        } | null;
      } | null>;
    };
  } | null;
};

export type TotalPostsQueryVariables = Exact<{
  username: Scalars["String"]["input"];
  filter?: InputMaybe<PostFilter>;
}>;

export type TotalPostsQuery = {
  __typename?: "Query";
  accountByUsername?: {
    __typename?: "Account";
    publicKey: string;
    posts: { __typename?: "PostsConnection"; totalCount: number };
  } | null;
};

export type TotalPostsMonthlyQueryVariables = Exact<{
  username: Scalars["String"]["input"];
  filter1?: InputMaybe<PostFilter>;
  filter2?: InputMaybe<PostFilter>;
  filter3?: InputMaybe<PostFilter>;
  filter4?: InputMaybe<PostFilter>;
  filter5?: InputMaybe<PostFilter>;
  filter6?: InputMaybe<PostFilter>;
  filter7?: InputMaybe<PostFilter>;
  filter8?: InputMaybe<PostFilter>;
  filter9?: InputMaybe<PostFilter>;
  filter10?: InputMaybe<PostFilter>;
  filter11?: InputMaybe<PostFilter>;
  filter12?: InputMaybe<PostFilter>;
}>;

export type TotalPostsMonthlyQuery = {
  __typename?: "Query";
  accountByUsername?: {
    __typename?: "Account";
    publicKey: string;
    m1: { __typename?: "PostsConnection"; totalCount: number };
    m2: { __typename?: "PostsConnection"; totalCount: number };
    m3: { __typename?: "PostsConnection"; totalCount: number };
    m4: { __typename?: "PostsConnection"; totalCount: number };
    m5: { __typename?: "PostsConnection"; totalCount: number };
    m6: { __typename?: "PostsConnection"; totalCount: number };
    m7: { __typename?: "PostsConnection"; totalCount: number };
    m8: { __typename?: "PostsConnection"; totalCount: number };
    m9: { __typename?: "PostsConnection"; totalCount: number };
    m10: { __typename?: "PostsConnection"; totalCount: number };
    m11: { __typename?: "PostsConnection"; totalCount: number };
    m12: { __typename?: "PostsConnection"; totalCount: number };
  } | null;
};

export type TotalMessagesQueryVariables = Exact<{
  username: Scalars["String"]["input"];
}>;

export type TotalMessagesQuery = {
  __typename?: "Query";
  accountByUsername?: {
    __typename?: "Account";
    publicKey: string;
    messagesSent: { __typename?: "MessagesConnection"; totalCount: number };
    legacyMessagesSent: {
      __typename?: "LegacyMessagesConnection";
      totalCount: number;
    };
  } | null;
};

export type TotalMessagesMonthlyQueryVariables = Exact<{
  username: Scalars["String"]["input"];
  filter1?: InputMaybe<MessageFilter>;
  filter2?: InputMaybe<MessageFilter>;
  filter3?: InputMaybe<MessageFilter>;
  filter4?: InputMaybe<MessageFilter>;
  filter5?: InputMaybe<MessageFilter>;
  filter6?: InputMaybe<MessageFilter>;
  filter7?: InputMaybe<MessageFilter>;
  filter8?: InputMaybe<MessageFilter>;
  filter9?: InputMaybe<MessageFilter>;
  filter10?: InputMaybe<MessageFilter>;
  filter11?: InputMaybe<MessageFilter>;
  filter12?: InputMaybe<MessageFilter>;
}>;

export type TotalMessagesMonthlyQuery = {
  __typename?: "Query";
  accountByUsername?: {
    __typename?: "Account";
    publicKey: string;
    m1: { __typename?: "MessagesConnection"; totalCount: number };
    m2: { __typename?: "MessagesConnection"; totalCount: number };
    m3: { __typename?: "MessagesConnection"; totalCount: number };
    m4: { __typename?: "MessagesConnection"; totalCount: number };
    m5: { __typename?: "MessagesConnection"; totalCount: number };
    m6: { __typename?: "MessagesConnection"; totalCount: number };
    m7: { __typename?: "MessagesConnection"; totalCount: number };
    m8: { __typename?: "MessagesConnection"; totalCount: number };
    m9: { __typename?: "MessagesConnection"; totalCount: number };
    m10: { __typename?: "MessagesConnection"; totalCount: number };
    m11: { __typename?: "MessagesConnection"; totalCount: number };
    m12: { __typename?: "MessagesConnection"; totalCount: number };
  } | null;
};

export type ProfileTopCcHoldersQueryVariables = Exact<{
  username: Scalars["String"]["input"];
  filter?: InputMaybe<TokenBalanceFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<TokenBalancesOrderBy> | TokenBalancesOrderBy>;
}>;

export type ProfileTopCcHoldersQuery = {
  __typename?: "Query";
  accountByUsername?: {
    __typename?: "Account";
    publicKey: string;
    coinPriceDesoNanos?: any | null;
    profile?: {
      __typename?: "Profile";
      ccCoinsInCirculationNanos: any;
      coinPriceDesoNanos?: any | null;
    } | null;
    tokenBalancesAsCreator: {
      __typename?: "TokenBalancesConnection";
      nodes: Array<{
        __typename?: "TokenBalance";
        id: string;
        balanceNanos: any;
        hasPurchased: boolean;
        holder?: {
          __typename?: "Account";
          username?: string | null;
          publicKey: string;
        } | null;
      } | null>;
    };
  } | null;
};

export type CoreProfileTransactionStatFieldsFragment = {
  __typename?: "ProfileTransactionStat";
  publicKey?: string | null;
  firstTransactionTimestamp?: any | null;
  latestTransactionTimestamp?: any | null;
};

export type ProfileTransactionStatByPublicKeyQueryVariables = Exact<{
  publicKey: Scalars["String"]["input"];
}>;

export type ProfileTransactionStatByPublicKeyQuery = {
  __typename?: "Query";
  profileTransactionStatByPublicKey?: {
    __typename?: "ProfileTransactionStat";
    count?: any | null;
    totalFees?: any | null;
    publicKey?: string | null;
    firstTransactionTimestamp?: any | null;
    latestTransactionTimestamp?: any | null;
  } | null;
};

export type ProfileTransactionStatResultFragment = {
  __typename?: "ProfileTransactionStat";
  publicKey?: string | null;
  firstTransactionTimestamp?: any | null;
  latestTransactionTimestamp?: any | null;
  account?: {
    __typename?: "Account";
    publicKey: string;
    pkid?: string | null;
    username?: string | null;
  } | null;
};

export type ProfileTransactionStatsQueryVariables = Exact<{
  first?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<
    Array<ProfileTransactionStatsOrderBy> | ProfileTransactionStatsOrderBy
  >;
  filter?: InputMaybe<ProfileTransactionStatFilter>;
}>;

export type ProfileTransactionStatsQuery = {
  __typename?: "Query";
  profileTransactionStats?: {
    __typename?: "ProfileTransactionStatsConnection";
    nodes: Array<{
      __typename?: "ProfileTransactionStat";
      publicKey?: string | null;
      firstTransactionTimestamp?: any | null;
      latestTransactionTimestamp?: any | null;
      account?: {
        __typename?: "Account";
        publicKey: string;
        pkid?: string | null;
        username?: string | null;
      } | null;
    } | null>;
  } | null;
};

export type BlockSearchResultFragment = {
  __typename?: "Block";
  height: any;
  blockHash: string;
};

export type TransactionSearchResultFragment = {
  __typename?: "Transaction";
  transactionHash?: string | null;
  txnType?: number | null;
};

export type GlobalSearchQueryVariables = Exact<{
  includeAccounts: Scalars["Boolean"]["input"];
  includeBlocks: Scalars["Boolean"]["input"];
  includeTransactions: Scalars["Boolean"]["input"];
  accountsFilter?: InputMaybe<AccountFilter>;
  blocksFilter?: InputMaybe<BlockFilter>;
  transactionsFilter?: InputMaybe<TransactionFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<AccountsOrderBy> | AccountsOrderBy>;
}>;

export type GlobalSearchQuery = {
  __typename?: "Query";
  accounts?: {
    __typename?: "AccountsConnection";
    nodes: Array<{
      __typename?: "Account";
      publicKey: string;
      pkid?: string | null;
      username?: string | null;
    } | null>;
  } | null;
  blocks?: {
    __typename?: "BlocksConnection";
    nodes: Array<{
      __typename?: "Block";
      height: any;
      blockHash: string;
    } | null>;
  } | null;
  transactions?: {
    __typename?: "TransactionsConnection";
    nodes: Array<{
      __typename?: "Transaction";
      transactionHash?: string | null;
      txnType?: number | null;
    } | null>;
  } | null;
};

export type TopHoldersFragment = {
  __typename?: "DesoBalance";
  publicKey: string;
  balanceNanos: any;
  account?: {
    __typename?: "Account";
    publicKey: string;
    pkid?: string | null;
    username?: string | null;
  } | null;
};

export type DesoBalancesQueryVariables = Exact<{
  first?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<DesoBalancesOrderBy> | DesoBalancesOrderBy>;
}>;

export type DesoBalancesQuery = {
  __typename?: "Query";
  desoBalances?: {
    __typename?: "DesoBalancesConnection";
    totalCount: number;
    nodes: Array<{
      __typename?: "DesoBalance";
      publicKey: string;
      balanceNanos: any;
      account?: {
        __typename?: "Account";
        publicKey: string;
        pkid?: string | null;
        username?: string | null;
      } | null;
    } | null>;
  } | null;
};

export type CoinHoldersQueryVariables = Exact<{ [key: string]: never }>;

export type CoinHoldersQuery = {
  __typename?: "Query";
  dashboardStats?: {
    __typename?: "DashboardStatsConnection";
    nodes: Array<{
      __typename?: "DashboardStat";
      walletCountAll?: any | null;
    } | null>;
  } | null;
};

export type ProfilePortfolioValueStatByPublicKeyQueryVariables = Exact<{
  publicKey: Scalars["String"]["input"];
}>;

export type ProfilePortfolioValueStatByPublicKeyQuery = {
  __typename?: "Query";
  profileEarningsBreakdownStatByPublicKey?: {
    __typename?: "ProfileEarningsBreakdownStat";
    publicKey?: string | null;
    username?: string | null;
    diamondsGivenCount?: any | null;
    diamondsReceivedCount?: any | null;
    ccBuyCount?: any | null;
    ccBuyAmountNanos?: any | null;
    ccSellCount?: any | null;
    ccSellAmountNanos?: any | null;
    nftBuyCount?: any | null;
    nftBuyAmountNanos?: any | null;
    nftSellCount?: any | null;
    nftSellAmountNanos?: any | null;
    tokenBuyTradeCount?: any | null;
    tokenBuyOrderNanosFilled?: any | null;
    tokenSellTradeCount?: any | null;
    tokenSellOrderNanosFillede?: any | null;
  } | null;
};

export type ProfileEarningsByPublicKeyQueryVariables = Exact<{
  publicKey: Scalars["String"]["input"];
}>;

export type ProfileEarningsByPublicKeyQuery = {
  __typename?: "Query";
  profileEarningsStatByPublicKey?: {
    __typename?: "ProfileEarningsStat";
    publicKey?: string | null;
    username?: string | null;
    totalCcRoyaltyNanos?: any | null;
    totalDiamondNanos?: any | null;
    totalNftRoyaltyNanos?: any | null;
    totalNftAdditionalRoyaltyNanos?: any | null;
    totalNftCreatorRoyaltyNanos?: any | null;
  } | null;
};

export type ProfilePortfolioByPublicKeyQueryVariables = Exact<{
  publicKey: Scalars["String"]["input"];
}>;

export type ProfilePortfolioByPublicKeyQuery = {
  __typename?: "Query";
  profilePortfolioValueStatByPublicKey?: {
    __typename?: "ProfilePortfolioValueStat";
    desoBalanceValueNanos?: any | null;
    ccValueNanos?: any | null;
    nftValueNanos?: any | null;
    tokenValueNanos?: any | null;
    publicKey?: string | null;
  } | null;
};

export type ProfileCcStatsQueryVariables = Exact<{
  username: Scalars["String"]["input"];
}>;

export type ProfileCcStatsQuery = {
  __typename?: "Query";
  accountByUsername?: {
    __typename?: "Account";
    publicKey: string;
    profile?: {
      __typename?: "Profile";
      ccCoinsInCirculationNanos: any;
      coinPriceDesoNanos?: any | null;
    } | null;
    creatorCoinBalancesAsCreator: {
      __typename?: "CreatorCoinBalancesConnection";
      totalCount: number;
    };
  } | null;
};

export type PartialValidatorFragment = {
  __typename?: "ValidatorEntry";
  validatorPkid: string;
  jailedAtEpochNumber?: any | null;
  lastActiveAtEpochNumber?: any | null;
  totalStakeAmountNanos: any;
  delegatedStakeCommissionBasisPoints?: any | null;
  disableDelegatedStake?: boolean | null;
  validatorStats?: {
    __typename?: "ValidatorStat";
    percentTotalStake?: number | null;
    validatorRank?: any | null;
  } | null;
  account?: {
    __typename?: "Account";
    username?: string | null;
    publicKey: string;
  } | null;
  stakeEntries: { __typename?: "StakeEntriesConnection"; totalCount: number };
  viewerStake: {
    __typename?: "StakeEntriesConnection";
    nodes: Array<{
      __typename?: "StakeEntry";
      stakeAmountNanos: any;
      rewardMethod: string;
      staker?: {
        __typename?: "Account";
        publicKey: string;
        desoBalance?: {
          __typename?: "DesoBalance";
          publicKey: string;
          balanceNanos: any;
        } | null;
      } | null;
    } | null>;
  };
};

export type ValidatorFragment = {
  __typename?: "ValidatorEntry";
  validatorPkid: string;
  domains?: Array<string | null> | null;
  jailedAtEpochNumber?: any | null;
  lastActiveAtEpochNumber?: any | null;
  totalStakeAmountNanos: any;
  delegatedStakeCommissionBasisPoints?: any | null;
  disableDelegatedStake?: boolean | null;
  votingPublicKey?: string | null;
  votingAuthorization?: string | null;
  validatorStats?: {
    __typename?: "ValidatorStat";
    percentTotalStake?: number | null;
    validatorRank?: any | null;
  } | null;
  viewerStakeRewardsSum: {
    __typename?: "StakeRewardsByValidatorAndStakersConnection";
    nodes: Array<{
      __typename?: "StakeRewardsByValidatorAndStaker";
      rewardNanos?: any | null;
    } | null>;
  };
  account?: {
    __typename?: "Account";
    username?: string | null;
    publicKey: string;
    description?: string | null;
    extraData?: any | null;
  } | null;
  stakeEntries: { __typename?: "StakeEntriesConnection"; totalCount: number };
  viewerStake: {
    __typename?: "StakeEntriesConnection";
    nodes: Array<{
      __typename?: "StakeEntry";
      stakeAmountNanos: any;
      rewardMethod: string;
      staker?: {
        __typename?: "Account";
        publicKey: string;
        desoBalance?: {
          __typename?: "DesoBalance";
          publicKey: string;
          balanceNanos: any;
        } | null;
      } | null;
    } | null>;
  };
};

export type StakeTableRowFragment = {
  __typename?: "StakeEntry";
  stakeAmountNanos: any;
  rewardMethod: string;
  validatorAccount?: {
    __typename?: "Account";
    username?: string | null;
    publicKey: string;
  } | null;
  validatorEntry?: {
    __typename?: "ValidatorEntry";
    validatorPkid: string;
    totalStakeAmountNanos: any;
    jailedAtEpochNumber?: any | null;
    account?: {
      __typename?: "Account";
      username?: string | null;
      publicKey: string;
    } | null;
    viewerStakeRewardsSum: {
      __typename?: "StakeRewardsByValidatorAndStakersConnection";
      nodes: Array<{
        __typename?: "StakeRewardsByValidatorAndStaker";
        rewardNanos?: any | null;
      } | null>;
    };
  } | null;
};

export type LockedStakeTableRowFragment = {
  __typename?: "LockedStakeEntry";
  lockedAmountNanos: any;
  lockedAtEpochNumber: any;
  validatorAccount?: {
    __typename?: "Account";
    username?: string | null;
    publicKey: string;
  } | null;
  validatorEntry?: {
    __typename?: "ValidatorEntry";
    validatorPkid: string;
    jailedAtEpochNumber?: any | null;
    account?: {
      __typename?: "Account";
      username?: string | null;
      publicKey: string;
    } | null;
  } | null;
};

export type StakingOverviewFragment = {
  __typename?: "StakingSummary";
  globalStakeAmountNanos?: any | null;
  numValidators?: any | null;
  currentEpochNumber?: any | null;
  numEpochsInLeaderSchedule?: any | null;
  numStakers?: any | null;
};

export type LeaderFragment = {
  __typename?: "LeaderScheduleEntry";
  id: string;
  leaderIndex: number;
  leaderAccount?: {
    __typename?: "Account";
    username?: string | null;
    publicKey: string;
  } | null;
};

export type GetStakingSummariesQueryVariables = Exact<{ [key: string]: never }>;

export type GetStakingSummariesQuery = {
  __typename?: "Query";
  stakingSummaries?: {
    __typename?: "StakingSummariesConnection";
    nodes: Array<{
      __typename?: "StakingSummary";
      globalStakeAmountNanos?: any | null;
      numValidators?: any | null;
      currentEpochNumber?: any | null;
      numEpochsInLeaderSchedule?: any | null;
      numStakers?: any | null;
    } | null>;
  } | null;
  currentEpoch?: {
    __typename?: "EpochEntriesConnection";
    nodes: Array<{
      __typename?: "EpochEntry";
      epochNumber: any;
      initialView: any;
      initialBlockHeight: any;
      initialLeaderIndexOffset: any;
      leaderScheduleEntries: {
        __typename?: "LeaderScheduleEntriesConnection";
        nodes: Array<{
          __typename?: "LeaderScheduleEntry";
          id: string;
          leaderIndex: number;
          leaderAccount?: {
            __typename?: "Account";
            username?: string | null;
            publicKey: string;
          } | null;
        } | null>;
      };
    } | null>;
  } | null;
  currentBlock?: {
    __typename?: "BlocksConnection";
    nodes: Array<{
      __typename?: "Block";
      blockHash: string;
      height: any;
      proposedInView?: any | null;
      epochDetailForBlock?: {
        __typename?: "EpochDetailsForBlock";
        proposer?: {
          __typename?: "Account";
          publicKey: string;
          pkid?: string | null;
          username?: string | null;
        } | null;
      } | null;
    } | null>;
  } | null;
  globalParamsEntries?: {
    __typename?: "GlobalParamsEntriesConnection";
    nodes: Array<{
      __typename?: "GlobalParamsEntry";
      epochDurationNumBlocks: any;
    } | null>;
  } | null;
};

export type GetGlobalStakeAmountQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetGlobalStakeAmountQuery = {
  __typename?: "Query";
  stakingSummaries?: {
    __typename?: "StakingSummariesConnection";
    nodes: Array<{
      __typename?: "StakingSummary";
      globalStakeAmountNanos?: any | null;
    } | null>;
  } | null;
};

export type GetValidatorsOverviewQueryVariables = Exact<{
  viewerPublicKey: Scalars["String"]["input"];
}>;

export type GetValidatorsOverviewQuery = {
  __typename?: "Query";
  globalParamsEntries?: {
    __typename?: "GlobalParamsEntriesConnection";
    nodes: Array<{
      __typename?: "GlobalParamsEntry";
      stakingRewardsApyBasisPoints: any;
      epochDurationNumBlocks: any;
    } | null>;
  } | null;
  viewerOverview?: {
    __typename?: "Account";
    publicKey: string;
    myStakeSummary?: {
      __typename?: "MyStakeSummary";
      totalStakeRewards?: any | null;
      totalStake?: any | null;
    } | null;
    stakeEntries: {
      __typename?: "StakeEntriesConnection";
      totalCount: number;
      nodes: Array<{
        __typename?: "StakeEntry";
        stakeAmountNanos: any;
        rewardMethod: string;
        validatorAccount?: {
          __typename?: "Account";
          username?: string | null;
          publicKey: string;
        } | null;
        validatorEntry?: {
          __typename?: "ValidatorEntry";
          validatorPkid: string;
          totalStakeAmountNanos: any;
          jailedAtEpochNumber?: any | null;
          account?: {
            __typename?: "Account";
            username?: string | null;
            publicKey: string;
          } | null;
          viewerStakeRewardsSum: {
            __typename?: "StakeRewardsByValidatorAndStakersConnection";
            nodes: Array<{
              __typename?: "StakeRewardsByValidatorAndStaker";
              rewardNanos?: any | null;
            } | null>;
          };
        } | null;
      } | null>;
    };
    lockedStakeEntries: {
      __typename?: "LockedStakeEntriesConnection";
      totalCount: number;
      nodes: Array<{
        __typename?: "LockedStakeEntry";
        lockedAmountNanos: any;
        lockedAtEpochNumber: any;
        validatorAccount?: {
          __typename?: "Account";
          username?: string | null;
          publicKey: string;
        } | null;
        validatorEntry?: {
          __typename?: "ValidatorEntry";
          validatorPkid: string;
          jailedAtEpochNumber?: any | null;
          account?: {
            __typename?: "Account";
            username?: string | null;
            publicKey: string;
          } | null;
        } | null;
      } | null>;
    };
    desoBalance?: {
      __typename?: "DesoBalance";
      publicKey: string;
      balanceNanos: any;
    } | null;
  } | null;
};

export type ValidatorsTableRowFragment = {
  __typename?: "ValidatorStat";
  validatorPkid: string;
  validatorRank?: any | null;
  percentTotalStake?: number | null;
  validatorEntry?: {
    __typename?: "ValidatorEntry";
    validatorPkid: string;
    jailedAtEpochNumber?: any | null;
    lastActiveAtEpochNumber?: any | null;
    totalStakeAmountNanos: any;
    delegatedStakeCommissionBasisPoints?: any | null;
    disableDelegatedStake?: boolean | null;
    validatorStats?: {
      __typename?: "ValidatorStat";
      percentTotalStake?: number | null;
      validatorRank?: any | null;
    } | null;
    account?: {
      __typename?: "Account";
      username?: string | null;
      publicKey: string;
    } | null;
    stakeEntries: { __typename?: "StakeEntriesConnection"; totalCount: number };
    viewerStake: {
      __typename?: "StakeEntriesConnection";
      nodes: Array<{
        __typename?: "StakeEntry";
        stakeAmountNanos: any;
        rewardMethod: string;
        staker?: {
          __typename?: "Account";
          publicKey: string;
          desoBalance?: {
            __typename?: "DesoBalance";
            publicKey: string;
            balanceNanos: any;
          } | null;
        } | null;
      } | null>;
    };
  } | null;
};

export type GetValidatorsQueryVariables = Exact<{
  viewerPublicKey: Scalars["String"]["input"];
  first?: InputMaybe<Scalars["Int"]["input"]>;
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
}>;

export type GetValidatorsQuery = {
  __typename?: "Query";
  validatorStats?: {
    __typename?: "ValidatorStatsConnection";
    totalCount: number;
    pageInfo: {
      __typename?: "PageInfo";
      hasNextPage: boolean;
      hasPreviousPage: boolean;
      endCursor?: any | null;
      startCursor?: any | null;
    };
    nodes: Array<{
      __typename?: "ValidatorStat";
      validatorPkid: string;
      validatorRank?: any | null;
      percentTotalStake?: number | null;
      validatorEntry?: {
        __typename?: "ValidatorEntry";
        validatorPkid: string;
        jailedAtEpochNumber?: any | null;
        lastActiveAtEpochNumber?: any | null;
        totalStakeAmountNanos: any;
        delegatedStakeCommissionBasisPoints?: any | null;
        disableDelegatedStake?: boolean | null;
        validatorStats?: {
          __typename?: "ValidatorStat";
          percentTotalStake?: number | null;
          validatorRank?: any | null;
        } | null;
        account?: {
          __typename?: "Account";
          username?: string | null;
          publicKey: string;
        } | null;
        stakeEntries: {
          __typename?: "StakeEntriesConnection";
          totalCount: number;
        };
        viewerStake: {
          __typename?: "StakeEntriesConnection";
          nodes: Array<{
            __typename?: "StakeEntry";
            stakeAmountNanos: any;
            rewardMethod: string;
            staker?: {
              __typename?: "Account";
              publicKey: string;
              desoBalance?: {
                __typename?: "DesoBalance";
                publicKey: string;
                balanceNanos: any;
              } | null;
            } | null;
          } | null>;
        };
      } | null;
    } | null>;
  } | null;
};

export type GetValidatorByUsernameQueryVariables = Exact<{
  username: Scalars["String"]["input"];
  viewerPublicKey?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type GetValidatorByUsernameQuery = {
  __typename?: "Query";
  accountByUsername?: {
    __typename?: "Account";
    publicKey: string;
    pkid?: string | null;
    username?: string | null;
    validatorEntry?: {
      __typename?: "ValidatorEntry";
      validatorPkid: string;
      domains?: Array<string | null> | null;
      jailedAtEpochNumber?: any | null;
      lastActiveAtEpochNumber?: any | null;
      totalStakeAmountNanos: any;
      delegatedStakeCommissionBasisPoints?: any | null;
      disableDelegatedStake?: boolean | null;
      votingPublicKey?: string | null;
      votingAuthorization?: string | null;
      validatorStats?: {
        __typename?: "ValidatorStat";
        percentTotalStake?: number | null;
        validatorRank?: any | null;
      } | null;
      viewerStakeRewardsSum: {
        __typename?: "StakeRewardsByValidatorAndStakersConnection";
        nodes: Array<{
          __typename?: "StakeRewardsByValidatorAndStaker";
          rewardNanos?: any | null;
        } | null>;
      };
      account?: {
        __typename?: "Account";
        username?: string | null;
        publicKey: string;
        description?: string | null;
        extraData?: any | null;
      } | null;
      stakeEntries: {
        __typename?: "StakeEntriesConnection";
        totalCount: number;
      };
      viewerStake: {
        __typename?: "StakeEntriesConnection";
        nodes: Array<{
          __typename?: "StakeEntry";
          stakeAmountNanos: any;
          rewardMethod: string;
          staker?: {
            __typename?: "Account";
            publicKey: string;
            desoBalance?: {
              __typename?: "DesoBalance";
              publicKey: string;
              balanceNanos: any;
            } | null;
          } | null;
        } | null>;
      };
    } | null;
  } | null;
};

export type GetValidatorByPublicKeyQueryVariables = Exact<{
  publicKey: Scalars["String"]["input"];
  viewerPublicKey?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type GetValidatorByPublicKeyQuery = {
  __typename?: "Query";
  accountByPublicKey?: {
    __typename?: "Account";
    publicKey: string;
    pkid?: string | null;
    username?: string | null;
    validatorEntry?: {
      __typename?: "ValidatorEntry";
      validatorPkid: string;
      domains?: Array<string | null> | null;
      jailedAtEpochNumber?: any | null;
      lastActiveAtEpochNumber?: any | null;
      totalStakeAmountNanos: any;
      delegatedStakeCommissionBasisPoints?: any | null;
      disableDelegatedStake?: boolean | null;
      votingPublicKey?: string | null;
      votingAuthorization?: string | null;
      validatorStats?: {
        __typename?: "ValidatorStat";
        percentTotalStake?: number | null;
        validatorRank?: any | null;
      } | null;
      viewerStakeRewardsSum: {
        __typename?: "StakeRewardsByValidatorAndStakersConnection";
        nodes: Array<{
          __typename?: "StakeRewardsByValidatorAndStaker";
          rewardNanos?: any | null;
        } | null>;
      };
      account?: {
        __typename?: "Account";
        username?: string | null;
        publicKey: string;
        description?: string | null;
        extraData?: any | null;
      } | null;
      stakeEntries: {
        __typename?: "StakeEntriesConnection";
        totalCount: number;
      };
      viewerStake: {
        __typename?: "StakeEntriesConnection";
        nodes: Array<{
          __typename?: "StakeEntry";
          stakeAmountNanos: any;
          rewardMethod: string;
          staker?: {
            __typename?: "Account";
            publicKey: string;
            desoBalance?: {
              __typename?: "DesoBalance";
              publicKey: string;
              balanceNanos: any;
            } | null;
          } | null;
        } | null>;
      };
    } | null;
  } | null;
};

export type StakeFragment = {
  __typename?: "StakeEntry";
  stakeAmountNanos: any;
  rewardMethod: string;
  staker?: {
    __typename?: "Account";
    publicKey: string;
    desoBalance?: {
      __typename?: "DesoBalance";
      publicKey: string;
      balanceNanos: any;
    } | null;
  } | null;
};

export type GetStakeByStakerPublicKeyAndValidatorPublicKeyQueryVariables =
  Exact<{
    stakerPublicKey: Scalars["String"]["input"];
    validatorPublicKey: Scalars["String"]["input"];
  }>;

export type GetStakeByStakerPublicKeyAndValidatorPublicKeyQuery = {
  __typename?: "Query";
  stakeEntries?: {
    __typename?: "StakeEntriesConnection";
    nodes: Array<{
      __typename?: "StakeEntry";
      stakeAmountNanos: any;
      rewardMethod: string;
      staker?: {
        __typename?: "Account";
        publicKey: string;
        desoBalance?: {
          __typename?: "DesoBalance";
          publicKey: string;
          balanceNanos: any;
        } | null;
      } | null;
    } | null>;
  } | null;
};

export type GetTransactionByHashQueryVariables = Exact<{
  transactionHash: Scalars["String"]["input"];
}>;

export type GetTransactionByHashQuery = {
  __typename?: "Query";
  transactionByTransactionHash?: {
    __typename?: "Transaction";
    transactionId?: string | null;
  } | null;
};

export type StakerFragment = {
  __typename?: "StakeEntry";
  stakeAmountNanos: any;
  account?: {
    __typename?: "Account";
    publicKey: string;
    username?: string | null;
  } | null;
};

export type StakeEntriesByValidatorPkidQueryVariables = Exact<{
  validatorPkid: Scalars["String"]["input"];
  first?: InputMaybe<Scalars["Int"]["input"]>;
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
}>;

export type StakeEntriesByValidatorPkidQuery = {
  __typename?: "Query";
  stakeEntries?: {
    __typename?: "StakeEntriesConnection";
    totalCount: number;
    pageInfo: {
      __typename?: "PageInfo";
      hasNextPage: boolean;
      hasPreviousPage: boolean;
      startCursor?: any | null;
      endCursor?: any | null;
    };
    nodes: Array<{
      __typename?: "StakeEntry";
      stakeAmountNanos: any;
      account?: {
        __typename?: "Account";
        publicKey: string;
        username?: string | null;
      } | null;
    } | null>;
  } | null;
};

export type GetFinalBlockHeightForEpochQueryVariables = Exact<{
  epochNumber: Scalars["BigInt"]["input"];
}>;

export type GetFinalBlockHeightForEpochQuery = {
  __typename?: "Query";
  epochEntry?: { __typename?: "EpochEntry"; finalBlockHeight: any } | null;
  blocks?: {
    __typename?: "BlocksConnection";
    nodes: Array<{
      __typename?: "Block";
      blockHash: string;
      height: any;
    } | null>;
  } | null;
  globalParamsEntries?: {
    __typename?: "GlobalParamsEntriesConnection";
    nodes: Array<{
      __typename?: "GlobalParamsEntry";
      epochDurationNumBlocks: any;
    } | null>;
  } | null;
};

export type MyStakeSummariesQueryVariables = Exact<{
  filter?: InputMaybe<MyStakeSummaryFilter>;
}>;

export type MyStakeSummariesQuery = {
  __typename?: "Query";
  myStakeSummaries?: {
    __typename?: "MyStakeSummariesConnection";
    nodes: Array<{
      __typename?: "MyStakeSummary";
      totalStake?: any | null;
    } | null>;
  } | null;
};

export const CoreAccountFieldsFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "CoreAccountFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Account" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "publicKey" } },
          { kind: "Field", name: { kind: "Name", value: "pkid" } },
          { kind: "Field", name: { kind: "Name", value: "username" } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CoreAccountFieldsFragment, unknown>;
export const AccountBalanceFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "AccountBalance" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Account" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "publicKey" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "desoBalance" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "publicKey" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "balanceNanos" },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AccountBalanceFragment, unknown>;
export const AccountDetailsFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "AccountDetails" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Account" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "CoreAccountFields" },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "AccountBalance" },
          },
          { kind: "Field", name: { kind: "Name", value: "extraData" } },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "CoreAccountFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Account" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "publicKey" } },
          { kind: "Field", name: { kind: "Name", value: "pkid" } },
          { kind: "Field", name: { kind: "Name", value: "username" } },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "AccountBalance" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Account" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "publicKey" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "desoBalance" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "publicKey" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "balanceNanos" },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AccountDetailsFragment, unknown>;
export const AccountFollowersFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "AccountFollowers" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Account" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "publicKey" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "followers" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "totalCount" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "following" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "totalCount" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AccountFollowersFragment, unknown>;
export const CoreBlockFieldsFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "CoreBlockFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Block" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "blockHash" } },
          { kind: "Field", name: { kind: "Name", value: "height" } },
          { kind: "Field", name: { kind: "Name", value: "timestamp" } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CoreBlockFieldsFragment, unknown>;
export const PartialTransactionFieldsFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "PartialTransactionFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Transaction" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "transactionHash" } },
          { kind: "Field", name: { kind: "Name", value: "blockHash" } },
          { kind: "Field", name: { kind: "Name", value: "outputs" } },
          { kind: "Field", name: { kind: "Name", value: "txnMeta" } },
          { kind: "Field", name: { kind: "Name", value: "txIndexMetadata" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "txIndexBasicTransferMetadata" },
          },
          { kind: "Field", name: { kind: "Name", value: "transactionId" } },
          { kind: "Field", name: { kind: "Name", value: "txnType" } },
          { kind: "Field", name: { kind: "Name", value: "publicKey" } },
          { kind: "Field", name: { kind: "Name", value: "extraData" } },
          { kind: "Field", name: { kind: "Name", value: "indexInBlock" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "wrapperTransactionHash" },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "indexInWrapperTransaction" },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<PartialTransactionFieldsFragment, unknown>;
export const CoreTransactionFieldsFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "CoreTransactionFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Transaction" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "transactionHash" } },
          { kind: "Field", name: { kind: "Name", value: "blockHash" } },
          { kind: "Field", name: { kind: "Name", value: "version" } },
          { kind: "Field", name: { kind: "Name", value: "inputs" } },
          { kind: "Field", name: { kind: "Name", value: "outputs" } },
          { kind: "Field", name: { kind: "Name", value: "feeNanos" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "nonceExpirationBlockHeight" },
          },
          { kind: "Field", name: { kind: "Name", value: "noncePartialId" } },
          { kind: "Field", name: { kind: "Name", value: "txnMeta" } },
          { kind: "Field", name: { kind: "Name", value: "txnMetaBytes" } },
          { kind: "Field", name: { kind: "Name", value: "txIndexMetadata" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "txIndexBasicTransferMetadata" },
          },
          { kind: "Field", name: { kind: "Name", value: "transactionId" } },
          { kind: "Field", name: { kind: "Name", value: "txnType" } },
          { kind: "Field", name: { kind: "Name", value: "publicKey" } },
          { kind: "Field", name: { kind: "Name", value: "extraData" } },
          { kind: "Field", name: { kind: "Name", value: "signature" } },
          { kind: "Field", name: { kind: "Name", value: "txnBytes" } },
          { kind: "Field", name: { kind: "Name", value: "indexInBlock" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "wrapperTransactionHash" },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "indexInWrapperTransaction" },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "account" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "CoreAccountFields" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "affectedPublicKeys" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "nodes" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "publicKey" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "wrapperTransaction" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "transactionHash" },
                },
                { kind: "Field", name: { kind: "Name", value: "blockHash" } },
                { kind: "Field", name: { kind: "Name", value: "version" } },
                { kind: "Field", name: { kind: "Name", value: "inputs" } },
                { kind: "Field", name: { kind: "Name", value: "outputs" } },
                { kind: "Field", name: { kind: "Name", value: "feeNanos" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "nonceExpirationBlockHeight" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "noncePartialId" },
                },
                { kind: "Field", name: { kind: "Name", value: "txnMeta" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "txnMetaBytes" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "txIndexMetadata" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "txIndexBasicTransferMetadata" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "transactionId" },
                },
                { kind: "Field", name: { kind: "Name", value: "txnType" } },
                { kind: "Field", name: { kind: "Name", value: "publicKey" } },
                { kind: "Field", name: { kind: "Name", value: "extraData" } },
                { kind: "Field", name: { kind: "Name", value: "signature" } },
                { kind: "Field", name: { kind: "Name", value: "txnBytes" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "indexInBlock" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "wrapperTransactionHash" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "indexInWrapperTransaction" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "innerTransactions" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "nodes" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "transactionHash" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "blockHash" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "version" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "inputs" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "outputs" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "feeNanos" },
                      },
                      {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "nonceExpirationBlockHeight",
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "noncePartialId" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "txnMeta" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "txnMetaBytes" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "txIndexMetadata" },
                      },
                      {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "txIndexBasicTransferMetadata",
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "transactionId" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "txnType" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "publicKey" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "extraData" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "signature" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "txnBytes" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "indexInBlock" },
                      },
                      {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "indexInWrapperTransaction",
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "CoreAccountFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Account" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "publicKey" } },
          { kind: "Field", name: { kind: "Name", value: "pkid" } },
          { kind: "Field", name: { kind: "Name", value: "username" } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CoreTransactionFieldsFragment, unknown>;
export const TokenBalanceFieldsFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "TokenBalanceFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "TokenBalance" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "balanceNanos" } },
          { kind: "Field", name: { kind: "Name", value: "hasPurchased" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "holder" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "username" } },
                { kind: "Field", name: { kind: "Name", value: "publicKey" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<TokenBalanceFieldsFragment, unknown>;
export const CoreProfileTransactionStatFieldsFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "CoreProfileTransactionStatFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "ProfileTransactionStat" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "publicKey" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "firstTransactionTimestamp" },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "latestTransactionTimestamp" },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CoreProfileTransactionStatFieldsFragment, unknown>;
export const ProfileTransactionStatResultFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ProfileTransactionStatResult" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "ProfileTransactionStat" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "CoreProfileTransactionStatFields" },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "account" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "CoreAccountFields" },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "CoreProfileTransactionStatFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "ProfileTransactionStat" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "publicKey" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "firstTransactionTimestamp" },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "latestTransactionTimestamp" },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "CoreAccountFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Account" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "publicKey" } },
          { kind: "Field", name: { kind: "Name", value: "pkid" } },
          { kind: "Field", name: { kind: "Name", value: "username" } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ProfileTransactionStatResultFragment, unknown>;
export const BlockSearchResultFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "BlockSearchResult" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Block" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "height" } },
          { kind: "Field", name: { kind: "Name", value: "blockHash" } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<BlockSearchResultFragment, unknown>;
export const TransactionSearchResultFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "TransactionSearchResult" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Transaction" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "transactionHash" } },
          { kind: "Field", name: { kind: "Name", value: "txnType" } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<TransactionSearchResultFragment, unknown>;
export const TopHoldersFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "TopHolders" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "DesoBalance" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "publicKey" } },
          { kind: "Field", name: { kind: "Name", value: "balanceNanos" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "account" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "CoreAccountFields" },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "CoreAccountFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Account" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "publicKey" } },
          { kind: "Field", name: { kind: "Name", value: "pkid" } },
          { kind: "Field", name: { kind: "Name", value: "username" } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<TopHoldersFragment, unknown>;
export const ValidatorFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "Validator" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "ValidatorEntry" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "validatorPkid" } },
          { kind: "Field", name: { kind: "Name", value: "domains" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "jailedAtEpochNumber" },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "lastActiveAtEpochNumber" },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "totalStakeAmountNanos" },
          },
          {
            kind: "Field",
            name: {
              kind: "Name",
              value: "delegatedStakeCommissionBasisPoints",
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "disableDelegatedStake" },
          },
          { kind: "Field", name: { kind: "Name", value: "votingPublicKey" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "votingAuthorization" },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "validatorStats" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "percentTotalStake" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "validatorRank" },
                },
              ],
            },
          },
          {
            kind: "Field",
            alias: { kind: "Name", value: "viewerStakeRewardsSum" },
            name: { kind: "Name", value: "validatorStakeRewardsByStaker" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "filter" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "stakerAccount" },
                      value: {
                        kind: "ObjectValue",
                        fields: [
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "publicKey" },
                            value: {
                              kind: "ObjectValue",
                              fields: [
                                {
                                  kind: "ObjectField",
                                  name: { kind: "Name", value: "equalTo" },
                                  value: {
                                    kind: "Variable",
                                    name: {
                                      kind: "Name",
                                      value: "viewerPublicKey",
                                    },
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "nodes" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "rewardNanos" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "account" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "username" } },
                { kind: "Field", name: { kind: "Name", value: "publicKey" } },
                { kind: "Field", name: { kind: "Name", value: "description" } },
                { kind: "Field", name: { kind: "Name", value: "extraData" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "stakeEntries" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "totalCount" } },
              ],
            },
          },
          {
            kind: "Field",
            alias: { kind: "Name", value: "viewerStake" },
            name: { kind: "Name", value: "stakeEntries" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "filter" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "staker" },
                      value: {
                        kind: "ObjectValue",
                        fields: [
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "publicKey" },
                            value: {
                              kind: "ObjectValue",
                              fields: [
                                {
                                  kind: "ObjectField",
                                  name: { kind: "Name", value: "equalTo" },
                                  value: {
                                    kind: "Variable",
                                    name: {
                                      kind: "Name",
                                      value: "viewerPublicKey",
                                    },
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "nodes" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "stakeAmountNanos" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "rewardMethod" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "staker" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "publicKey" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "desoBalance" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "publicKey" },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "balanceNanos",
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ValidatorFragment, unknown>;
export const StakeTableRowFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "StakeTableRow" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "StakeEntry" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "stakeAmountNanos" } },
          { kind: "Field", name: { kind: "Name", value: "rewardMethod" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "validatorAccount" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "username" } },
                { kind: "Field", name: { kind: "Name", value: "publicKey" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "validatorEntry" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "validatorPkid" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "totalStakeAmountNanos" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "jailedAtEpochNumber" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "account" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "username" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "publicKey" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  alias: { kind: "Name", value: "viewerStakeRewardsSum" },
                  name: {
                    kind: "Name",
                    value: "validatorStakeRewardsByStaker",
                  },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "filter" },
                      value: {
                        kind: "ObjectValue",
                        fields: [
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "stakerAccount" },
                            value: {
                              kind: "ObjectValue",
                              fields: [
                                {
                                  kind: "ObjectField",
                                  name: { kind: "Name", value: "publicKey" },
                                  value: {
                                    kind: "ObjectValue",
                                    fields: [
                                      {
                                        kind: "ObjectField",
                                        name: {
                                          kind: "Name",
                                          value: "equalTo",
                                        },
                                        value: {
                                          kind: "Variable",
                                          name: {
                                            kind: "Name",
                                            value: "viewerPublicKey",
                                          },
                                        },
                                      },
                                    ],
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "nodes" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "rewardNanos" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<StakeTableRowFragment, unknown>;
export const LockedStakeTableRowFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "LockedStakeTableRow" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "LockedStakeEntry" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "lockedAmountNanos" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "lockedAtEpochNumber" },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "validatorAccount" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "username" } },
                { kind: "Field", name: { kind: "Name", value: "publicKey" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "validatorEntry" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "validatorPkid" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "jailedAtEpochNumber" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "account" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "username" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "publicKey" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<LockedStakeTableRowFragment, unknown>;
export const StakingOverviewFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "StakingOverview" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "StakingSummary" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "globalStakeAmountNanos" },
          },
          { kind: "Field", name: { kind: "Name", value: "numValidators" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "currentEpochNumber" },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "numEpochsInLeaderSchedule" },
          },
          { kind: "Field", name: { kind: "Name", value: "numStakers" } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<StakingOverviewFragment, unknown>;
export const LeaderFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "Leader" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "LeaderScheduleEntry" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "leaderAccount" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "username" } },
                { kind: "Field", name: { kind: "Name", value: "publicKey" } },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "leaderIndex" } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<LeaderFragment, unknown>;
export const PartialValidatorFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "PartialValidator" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "ValidatorEntry" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "validatorPkid" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "jailedAtEpochNumber" },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "lastActiveAtEpochNumber" },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "totalStakeAmountNanos" },
          },
          {
            kind: "Field",
            name: {
              kind: "Name",
              value: "delegatedStakeCommissionBasisPoints",
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "disableDelegatedStake" },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "validatorStats" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "percentTotalStake" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "validatorRank" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "account" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "username" } },
                { kind: "Field", name: { kind: "Name", value: "publicKey" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "stakeEntries" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "totalCount" } },
              ],
            },
          },
          {
            kind: "Field",
            alias: { kind: "Name", value: "viewerStake" },
            name: { kind: "Name", value: "stakeEntries" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "filter" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "staker" },
                      value: {
                        kind: "ObjectValue",
                        fields: [
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "publicKey" },
                            value: {
                              kind: "ObjectValue",
                              fields: [
                                {
                                  kind: "ObjectField",
                                  name: { kind: "Name", value: "equalTo" },
                                  value: {
                                    kind: "Variable",
                                    name: {
                                      kind: "Name",
                                      value: "viewerPublicKey",
                                    },
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "nodes" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "stakeAmountNanos" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "rewardMethod" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "staker" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "publicKey" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "desoBalance" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "publicKey" },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "balanceNanos",
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<PartialValidatorFragment, unknown>;
export const ValidatorsTableRowFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ValidatorsTableRow" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "ValidatorStat" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "validatorPkid" } },
          { kind: "Field", name: { kind: "Name", value: "validatorRank" } },
          { kind: "Field", name: { kind: "Name", value: "percentTotalStake" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "validatorEntry" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "PartialValidator" },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "PartialValidator" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "ValidatorEntry" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "validatorPkid" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "jailedAtEpochNumber" },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "lastActiveAtEpochNumber" },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "totalStakeAmountNanos" },
          },
          {
            kind: "Field",
            name: {
              kind: "Name",
              value: "delegatedStakeCommissionBasisPoints",
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "disableDelegatedStake" },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "validatorStats" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "percentTotalStake" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "validatorRank" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "account" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "username" } },
                { kind: "Field", name: { kind: "Name", value: "publicKey" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "stakeEntries" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "totalCount" } },
              ],
            },
          },
          {
            kind: "Field",
            alias: { kind: "Name", value: "viewerStake" },
            name: { kind: "Name", value: "stakeEntries" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "filter" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "staker" },
                      value: {
                        kind: "ObjectValue",
                        fields: [
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "publicKey" },
                            value: {
                              kind: "ObjectValue",
                              fields: [
                                {
                                  kind: "ObjectField",
                                  name: { kind: "Name", value: "equalTo" },
                                  value: {
                                    kind: "Variable",
                                    name: {
                                      kind: "Name",
                                      value: "viewerPublicKey",
                                    },
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "nodes" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "stakeAmountNanos" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "rewardMethod" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "staker" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "publicKey" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "desoBalance" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "publicKey" },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "balanceNanos",
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ValidatorsTableRowFragment, unknown>;
export const StakeFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "Stake" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "StakeEntry" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "stakeAmountNanos" } },
          { kind: "Field", name: { kind: "Name", value: "rewardMethod" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "staker" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "publicKey" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "desoBalance" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "publicKey" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "balanceNanos" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<StakeFragment, unknown>;
export const StakerFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "Staker" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "StakeEntry" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            alias: { kind: "Name", value: "account" },
            name: { kind: "Name", value: "staker" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "publicKey" } },
                { kind: "Field", name: { kind: "Name", value: "username" } },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "stakeAmountNanos" } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<StakerFragment, unknown>;
export const GetDesoBalanceByPublicKeyDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "GetDesoBalanceByPublicKey" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "publicKey" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "accountByPublicKey" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "publicKey" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "publicKey" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "AccountBalance" },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "AccountBalance" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Account" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "publicKey" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "desoBalance" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "publicKey" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "balanceNanos" },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetDesoBalanceByPublicKeyQuery,
  GetDesoBalanceByPublicKeyQueryVariables
>;
export const AccountByPublicKeyDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "AccountByPublicKey" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "publicKey" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "accountByPublicKey" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "publicKey" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "publicKey" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "CoreAccountFields" },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "CoreAccountFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Account" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "publicKey" } },
          { kind: "Field", name: { kind: "Name", value: "pkid" } },
          { kind: "Field", name: { kind: "Name", value: "username" } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  AccountByPublicKeyQuery,
  AccountByPublicKeyQueryVariables
>;
export const AccountExtendedByPublicKeyDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "AccountExtendedByPublicKey" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "publicKey" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "accountByPublicKey" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "publicKey" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "publicKey" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "CoreAccountFields" },
                },
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "AccountBalance" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "profile" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "coinPriceDesoNanos" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "CoreAccountFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Account" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "publicKey" } },
          { kind: "Field", name: { kind: "Name", value: "pkid" } },
          { kind: "Field", name: { kind: "Name", value: "username" } },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "AccountBalance" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Account" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "publicKey" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "desoBalance" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "publicKey" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "balanceNanos" },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  AccountExtendedByPublicKeyQuery,
  AccountExtendedByPublicKeyQueryVariables
>;
export const AccountByUsernameDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "AccountByUsername" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "username" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "accountByUsername" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "username" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "username" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "CoreAccountFields" },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "CoreAccountFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Account" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "publicKey" } },
          { kind: "Field", name: { kind: "Name", value: "pkid" } },
          { kind: "Field", name: { kind: "Name", value: "username" } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  AccountByUsernameQuery,
  AccountByUsernameQueryVariables
>;
export const AccountsFilteredDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "AccountsFiltered" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "filter" },
          },
          type: {
            kind: "NamedType",
            name: { kind: "Name", value: "AccountFilter" },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "accounts" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "filter" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "filter" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "nodes" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "FragmentSpread",
                        name: { kind: "Name", value: "AccountDetails" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "CoreAccountFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Account" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "publicKey" } },
          { kind: "Field", name: { kind: "Name", value: "pkid" } },
          { kind: "Field", name: { kind: "Name", value: "username" } },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "AccountBalance" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Account" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "publicKey" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "desoBalance" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "publicKey" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "balanceNanos" },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "AccountDetails" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Account" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "CoreAccountFields" },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "AccountBalance" },
          },
          { kind: "Field", name: { kind: "Name", value: "extraData" } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  AccountsFilteredQuery,
  AccountsFilteredQueryVariables
>;
export const AccountsSearchDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "AccountsSearch" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "filter" },
          },
          type: {
            kind: "NamedType",
            name: { kind: "Name", value: "AccountFilter" },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "first" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "orderBy" },
          },
          type: {
            kind: "ListType",
            type: {
              kind: "NonNullType",
              type: {
                kind: "NamedType",
                name: { kind: "Name", value: "AccountsOrderBy" },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "accounts" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "filter" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "filter" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "first" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "first" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "orderBy" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "orderBy" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "nodes" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "FragmentSpread",
                        name: { kind: "Name", value: "CoreAccountFields" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "CoreAccountFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Account" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "publicKey" } },
          { kind: "Field", name: { kind: "Name", value: "pkid" } },
          { kind: "Field", name: { kind: "Name", value: "username" } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AccountsSearchQuery, AccountsSearchQueryVariables>;
export const AccountFollowersByPublicKeyDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "AccountFollowersByPublicKey" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "publicKey" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "accountByPublicKey" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "publicKey" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "publicKey" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "AccountFollowers" },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "AccountFollowers" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Account" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "publicKey" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "followers" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "totalCount" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "following" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "totalCount" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  AccountFollowersByPublicKeyQuery,
  AccountFollowersByPublicKeyQueryVariables
>;
export const DashboardStatsDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "DashboardStats" },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "dashboardStats" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "nodes" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "txnCountAll" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "txnCount30D" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "walletCountAll" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "activeWalletCount30D" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "blockHeightCurrent" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "txnCountPending" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "txnFee1D" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "totalSupply" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "postCount" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "commentCount" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "repostCount" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "txnCountCreatorCoin" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "txnCountNft" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "txnCountDex" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "txnCountSocial" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "followCount" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "messageCount" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "postLongformCount" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DashboardStatsQuery, DashboardStatsQueryVariables>;
export const SocialLeaderboardStatsDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "SocialLeaderboardStats" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "orderBy" },
          },
          type: {
            kind: "ListType",
            type: {
              kind: "NonNullType",
              type: {
                kind: "NamedType",
                name: { kind: "Name", value: "SocialLeaderboardStatsOrderBy" },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "socialLeaderboardStats" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "orderBy" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "orderBy" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "nodes" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "publicKey" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "username" },
                      },
                      { kind: "Field", name: { kind: "Name", value: "count" } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  SocialLeaderboardStatsQuery,
  SocialLeaderboardStatsQueryVariables
>;
export const NftLeaderboardStatsDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "NftLeaderboardStats" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "orderBy" },
          },
          type: {
            kind: "ListType",
            type: {
              kind: "NonNullType",
              type: {
                kind: "NamedType",
                name: { kind: "Name", value: "NftLeaderboardStatsOrderBy" },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "nftLeaderboardStats" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "orderBy" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "orderBy" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "nodes" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "sum" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "publicKey" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "username" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  NftLeaderboardStatsQuery,
  NftLeaderboardStatsQueryVariables
>;
export const StatisticDefiLeaderboardsDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "StatisticDefiLeaderboards" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "orderBy" },
          },
          type: {
            kind: "ListType",
            type: {
              kind: "NonNullType",
              type: {
                kind: "NamedType",
                name: { kind: "Name", value: "DefiLeaderboardStatsOrderBy" },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "defiLeaderboardStats" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "orderBy" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "orderBy" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "nodes" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "buyingPublicKey" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "username" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "publicKey" },
                      },
                      { kind: "Field", name: { kind: "Name", value: "pkid" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "netQuantity" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  StatisticDefiLeaderboardsQuery,
  StatisticDefiLeaderboardsQueryVariables
>;
export const DailyTxnCountStatsDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "DailyTxnCountStats" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "orderBy" },
          },
          type: {
            kind: "ListType",
            type: {
              kind: "NonNullType",
              type: {
                kind: "NamedType",
                name: { kind: "Name", value: "DailyTxnCountStatsOrderBy" },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "dailyTxnCountStats" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "orderBy" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "orderBy" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "nodes" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "day" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "transactionCount" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DailyTxnCountStatsQuery,
  DailyTxnCountStatsQueryVariables
>;
export const DailyActiveWalletCountStatsDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "DailyActiveWalletCountStats" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "orderBy" },
          },
          type: {
            kind: "ListType",
            type: {
              kind: "NonNullType",
              type: {
                kind: "NamedType",
                name: {
                  kind: "Name",
                  value: "DailyActiveWalletCountStatsOrderBy",
                },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "dailyActiveWalletCountStats" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "orderBy" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "orderBy" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "nodes" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "day" } },
                      { kind: "Field", name: { kind: "Name", value: "count" } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DailyActiveWalletCountStatsQuery,
  DailyActiveWalletCountStatsQueryVariables
>;
export const TransactionsDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "Transactions" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "first" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "condition" },
          },
          type: {
            kind: "NamedType",
            name: { kind: "Name", value: "TransactionCondition" },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "orderBy" },
          },
          type: {
            kind: "ListType",
            type: {
              kind: "NonNullType",
              type: {
                kind: "NamedType",
                name: { kind: "Name", value: "TransactionsOrderBy" },
              },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "offset" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "filter" },
          },
          type: {
            kind: "NamedType",
            name: { kind: "Name", value: "TransactionFilter" },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "withTotal" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "Boolean" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "transactions" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "first" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "first" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "condition" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "condition" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "orderBy" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "orderBy" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "offset" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "offset" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "filter" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "filter" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "nodes" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "FragmentSpread",
                        name: { kind: "Name", value: "CoreTransactionFields" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "block" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "FragmentSpread",
                              name: { kind: "Name", value: "CoreBlockFields" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "pageInfo" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "hasPreviousPage" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "hasNextPage" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "totalCount" },
                  directives: [
                    {
                      kind: "Directive",
                      name: { kind: "Name", value: "include" },
                      arguments: [
                        {
                          kind: "Argument",
                          name: { kind: "Name", value: "if" },
                          value: {
                            kind: "Variable",
                            name: { kind: "Name", value: "withTotal" },
                          },
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "CoreAccountFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Account" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "publicKey" } },
          { kind: "Field", name: { kind: "Name", value: "pkid" } },
          { kind: "Field", name: { kind: "Name", value: "username" } },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "CoreTransactionFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Transaction" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "transactionHash" } },
          { kind: "Field", name: { kind: "Name", value: "blockHash" } },
          { kind: "Field", name: { kind: "Name", value: "version" } },
          { kind: "Field", name: { kind: "Name", value: "inputs" } },
          { kind: "Field", name: { kind: "Name", value: "outputs" } },
          { kind: "Field", name: { kind: "Name", value: "feeNanos" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "nonceExpirationBlockHeight" },
          },
          { kind: "Field", name: { kind: "Name", value: "noncePartialId" } },
          { kind: "Field", name: { kind: "Name", value: "txnMeta" } },
          { kind: "Field", name: { kind: "Name", value: "txnMetaBytes" } },
          { kind: "Field", name: { kind: "Name", value: "txIndexMetadata" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "txIndexBasicTransferMetadata" },
          },
          { kind: "Field", name: { kind: "Name", value: "transactionId" } },
          { kind: "Field", name: { kind: "Name", value: "txnType" } },
          { kind: "Field", name: { kind: "Name", value: "publicKey" } },
          { kind: "Field", name: { kind: "Name", value: "extraData" } },
          { kind: "Field", name: { kind: "Name", value: "signature" } },
          { kind: "Field", name: { kind: "Name", value: "txnBytes" } },
          { kind: "Field", name: { kind: "Name", value: "indexInBlock" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "wrapperTransactionHash" },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "indexInWrapperTransaction" },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "account" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "CoreAccountFields" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "affectedPublicKeys" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "nodes" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "publicKey" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "wrapperTransaction" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "transactionHash" },
                },
                { kind: "Field", name: { kind: "Name", value: "blockHash" } },
                { kind: "Field", name: { kind: "Name", value: "version" } },
                { kind: "Field", name: { kind: "Name", value: "inputs" } },
                { kind: "Field", name: { kind: "Name", value: "outputs" } },
                { kind: "Field", name: { kind: "Name", value: "feeNanos" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "nonceExpirationBlockHeight" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "noncePartialId" },
                },
                { kind: "Field", name: { kind: "Name", value: "txnMeta" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "txnMetaBytes" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "txIndexMetadata" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "txIndexBasicTransferMetadata" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "transactionId" },
                },
                { kind: "Field", name: { kind: "Name", value: "txnType" } },
                { kind: "Field", name: { kind: "Name", value: "publicKey" } },
                { kind: "Field", name: { kind: "Name", value: "extraData" } },
                { kind: "Field", name: { kind: "Name", value: "signature" } },
                { kind: "Field", name: { kind: "Name", value: "txnBytes" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "indexInBlock" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "wrapperTransactionHash" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "indexInWrapperTransaction" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "innerTransactions" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "nodes" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "transactionHash" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "blockHash" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "version" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "inputs" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "outputs" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "feeNanos" },
                      },
                      {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "nonceExpirationBlockHeight",
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "noncePartialId" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "txnMeta" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "txnMetaBytes" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "txIndexMetadata" },
                      },
                      {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "txIndexBasicTransferMetadata",
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "transactionId" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "txnType" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "publicKey" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "extraData" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "signature" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "txnBytes" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "indexInBlock" },
                      },
                      {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "indexInWrapperTransaction",
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "CoreBlockFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Block" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "blockHash" } },
          { kind: "Field", name: { kind: "Name", value: "height" } },
          { kind: "Field", name: { kind: "Name", value: "timestamp" } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<TransactionsQuery, TransactionsQueryVariables>;
export const AffectedPublicKeysDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "AffectedPublicKeys" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "filter" },
          },
          type: {
            kind: "NamedType",
            name: { kind: "Name", value: "AffectedPublicKeyFilter" },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "orderBy" },
          },
          type: {
            kind: "ListType",
            type: {
              kind: "NonNullType",
              type: {
                kind: "NamedType",
                name: { kind: "Name", value: "AffectedPublicKeysOrderBy" },
              },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "first" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "offset" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "withTotal" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "Boolean" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "affectedPublicKeys" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "filter" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "filter" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "orderBy" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "orderBy" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "first" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "first" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "offset" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "offset" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "nodes" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "metadata" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "transaction" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "FragmentSpread",
                              name: {
                                kind: "Name",
                                value: "CoreTransactionFields",
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "block" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "FragmentSpread",
                                    name: {
                                      kind: "Name",
                                      value: "CoreBlockFields",
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "pageInfo" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "hasPreviousPage" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "hasNextPage" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "totalCount" },
                  directives: [
                    {
                      kind: "Directive",
                      name: { kind: "Name", value: "include" },
                      arguments: [
                        {
                          kind: "Argument",
                          name: { kind: "Name", value: "if" },
                          value: {
                            kind: "Variable",
                            name: { kind: "Name", value: "withTotal" },
                          },
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "CoreAccountFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Account" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "publicKey" } },
          { kind: "Field", name: { kind: "Name", value: "pkid" } },
          { kind: "Field", name: { kind: "Name", value: "username" } },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "CoreTransactionFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Transaction" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "transactionHash" } },
          { kind: "Field", name: { kind: "Name", value: "blockHash" } },
          { kind: "Field", name: { kind: "Name", value: "version" } },
          { kind: "Field", name: { kind: "Name", value: "inputs" } },
          { kind: "Field", name: { kind: "Name", value: "outputs" } },
          { kind: "Field", name: { kind: "Name", value: "feeNanos" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "nonceExpirationBlockHeight" },
          },
          { kind: "Field", name: { kind: "Name", value: "noncePartialId" } },
          { kind: "Field", name: { kind: "Name", value: "txnMeta" } },
          { kind: "Field", name: { kind: "Name", value: "txnMetaBytes" } },
          { kind: "Field", name: { kind: "Name", value: "txIndexMetadata" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "txIndexBasicTransferMetadata" },
          },
          { kind: "Field", name: { kind: "Name", value: "transactionId" } },
          { kind: "Field", name: { kind: "Name", value: "txnType" } },
          { kind: "Field", name: { kind: "Name", value: "publicKey" } },
          { kind: "Field", name: { kind: "Name", value: "extraData" } },
          { kind: "Field", name: { kind: "Name", value: "signature" } },
          { kind: "Field", name: { kind: "Name", value: "txnBytes" } },
          { kind: "Field", name: { kind: "Name", value: "indexInBlock" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "wrapperTransactionHash" },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "indexInWrapperTransaction" },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "account" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "CoreAccountFields" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "affectedPublicKeys" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "nodes" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "publicKey" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "wrapperTransaction" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "transactionHash" },
                },
                { kind: "Field", name: { kind: "Name", value: "blockHash" } },
                { kind: "Field", name: { kind: "Name", value: "version" } },
                { kind: "Field", name: { kind: "Name", value: "inputs" } },
                { kind: "Field", name: { kind: "Name", value: "outputs" } },
                { kind: "Field", name: { kind: "Name", value: "feeNanos" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "nonceExpirationBlockHeight" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "noncePartialId" },
                },
                { kind: "Field", name: { kind: "Name", value: "txnMeta" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "txnMetaBytes" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "txIndexMetadata" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "txIndexBasicTransferMetadata" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "transactionId" },
                },
                { kind: "Field", name: { kind: "Name", value: "txnType" } },
                { kind: "Field", name: { kind: "Name", value: "publicKey" } },
                { kind: "Field", name: { kind: "Name", value: "extraData" } },
                { kind: "Field", name: { kind: "Name", value: "signature" } },
                { kind: "Field", name: { kind: "Name", value: "txnBytes" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "indexInBlock" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "wrapperTransactionHash" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "indexInWrapperTransaction" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "innerTransactions" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "nodes" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "transactionHash" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "blockHash" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "version" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "inputs" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "outputs" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "feeNanos" },
                      },
                      {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "nonceExpirationBlockHeight",
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "noncePartialId" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "txnMeta" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "txnMetaBytes" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "txIndexMetadata" },
                      },
                      {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "txIndexBasicTransferMetadata",
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "transactionId" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "txnType" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "publicKey" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "extraData" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "signature" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "txnBytes" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "indexInBlock" },
                      },
                      {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "indexInWrapperTransaction",
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "CoreBlockFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Block" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "blockHash" } },
          { kind: "Field", name: { kind: "Name", value: "height" } },
          { kind: "Field", name: { kind: "Name", value: "timestamp" } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  AffectedPublicKeysQuery,
  AffectedPublicKeysQueryVariables
>;
export const BlockDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "Block" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "filter" },
          },
          type: {
            kind: "NamedType",
            name: { kind: "Name", value: "BlockFilter" },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "orderBy" },
          },
          type: {
            kind: "ListType",
            type: {
              kind: "NonNullType",
              type: {
                kind: "NamedType",
                name: { kind: "Name", value: "TransactionsOrderBy" },
              },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "transactionsFirst" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "blocksFirst" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "blocksOrderBy" },
          },
          type: {
            kind: "ListType",
            type: {
              kind: "NonNullType",
              type: {
                kind: "NamedType",
                name: { kind: "Name", value: "BlocksOrderBy" },
              },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "transactionsOffset" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "blocksOffset" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "blocks" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "filter" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "filter" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "first" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "blocksFirst" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "orderBy" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "blocksOrderBy" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "offset" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "blocksOffset" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "totalCount" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "nodes" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "transactions" },
                        arguments: [
                          {
                            kind: "Argument",
                            name: { kind: "Name", value: "orderBy" },
                            value: {
                              kind: "Variable",
                              name: { kind: "Name", value: "orderBy" },
                            },
                          },
                          {
                            kind: "Argument",
                            name: { kind: "Name", value: "first" },
                            value: {
                              kind: "Variable",
                              name: {
                                kind: "Name",
                                value: "transactionsFirst",
                              },
                            },
                          },
                          {
                            kind: "Argument",
                            name: { kind: "Name", value: "offset" },
                            value: {
                              kind: "Variable",
                              name: {
                                kind: "Name",
                                value: "transactionsOffset",
                              },
                            },
                          },
                        ],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "nodes" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "FragmentSpread",
                                    name: {
                                      kind: "Name",
                                      value: "PartialTransactionFields",
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "totalCount" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "FragmentSpread",
                        name: { kind: "Name", value: "CoreBlockFields" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "PartialTransactionFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Transaction" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "transactionHash" } },
          { kind: "Field", name: { kind: "Name", value: "blockHash" } },
          { kind: "Field", name: { kind: "Name", value: "outputs" } },
          { kind: "Field", name: { kind: "Name", value: "txnMeta" } },
          { kind: "Field", name: { kind: "Name", value: "txIndexMetadata" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "txIndexBasicTransferMetadata" },
          },
          { kind: "Field", name: { kind: "Name", value: "transactionId" } },
          { kind: "Field", name: { kind: "Name", value: "txnType" } },
          { kind: "Field", name: { kind: "Name", value: "publicKey" } },
          { kind: "Field", name: { kind: "Name", value: "extraData" } },
          { kind: "Field", name: { kind: "Name", value: "indexInBlock" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "wrapperTransactionHash" },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "indexInWrapperTransaction" },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "CoreBlockFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Block" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "blockHash" } },
          { kind: "Field", name: { kind: "Name", value: "height" } },
          { kind: "Field", name: { kind: "Name", value: "timestamp" } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<BlockQuery, BlockQueryVariables>;
export const BlockByHeightDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "BlockByHeight" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "height" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "BigInt" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "blockByHeight" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "height" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "height" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "CoreBlockFields" },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "CoreBlockFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Block" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "blockHash" } },
          { kind: "Field", name: { kind: "Name", value: "height" } },
          { kind: "Field", name: { kind: "Name", value: "timestamp" } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<BlockByHeightQuery, BlockByHeightQueryVariables>;
export const BlockByBlockHashDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "BlockByBlockHash" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "blockHash" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "blockByBlockHash" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "blockHash" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "blockHash" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "CoreBlockFields" },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "CoreBlockFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Block" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "blockHash" } },
          { kind: "Field", name: { kind: "Name", value: "height" } },
          { kind: "Field", name: { kind: "Name", value: "timestamp" } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  BlockByBlockHashQuery,
  BlockByBlockHashQueryVariables
>;
export const ProfileNftTopOwnersDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "ProfileNftTopOwners" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "orderBy" },
          },
          type: {
            kind: "ListType",
            type: {
              kind: "NonNullType",
              type: {
                kind: "NamedType",
                name: { kind: "Name", value: "ProfileNftTopOwnersOrderBy" },
              },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "filter" },
          },
          type: {
            kind: "NamedType",
            name: { kind: "Name", value: "ProfileNftTopOwnerFilter" },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "first" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "publicKey" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "postsFilter2" },
          },
          type: {
            kind: "NamedType",
            name: { kind: "Name", value: "PostFilter" },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "profileNftTopOwners" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "orderBy" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "orderBy" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "filter" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "filter" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "first" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "first" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "nodes" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "creatorPublicKey" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "publicKey" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "username" },
                      },
                      { kind: "Field", name: { kind: "Name", value: "count" } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "accountByPublicKey" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "publicKey" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "publicKey" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "publicKey" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "posts" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "filter" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "postsFilter2" },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "totalCount" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ProfileNftTopOwnersQuery,
  ProfileNftTopOwnersQueryVariables
>;
export const ProfileTopDaoHoldersDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "ProfileTopDAOHolders" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "username" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "filter" },
          },
          type: {
            kind: "NamedType",
            name: { kind: "Name", value: "TokenBalanceFilter" },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "first" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "orderBy" },
          },
          type: {
            kind: "ListType",
            type: {
              kind: "NonNullType",
              type: {
                kind: "NamedType",
                name: { kind: "Name", value: "TokenBalancesOrderBy" },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "accountByUsername" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "username" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "username" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "publicKey" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "profile" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "daoCoinsInCirculationNanosHex",
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "tokenBalancesAsCreator" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "filter" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "filter" },
                      },
                    },
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "first" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "first" },
                      },
                    },
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "orderBy" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "orderBy" },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "nodes" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "FragmentSpread",
                              name: {
                                kind: "Name",
                                value: "TokenBalanceFields",
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "TokenBalanceFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "TokenBalance" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "balanceNanos" } },
          { kind: "Field", name: { kind: "Name", value: "hasPurchased" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "holder" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "username" } },
                { kind: "Field", name: { kind: "Name", value: "publicKey" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ProfileTopDaoHoldersQuery,
  ProfileTopDaoHoldersQueryVariables
>;
export const TotalPostsDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "TotalPosts" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "username" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "filter" },
          },
          type: {
            kind: "NamedType",
            name: { kind: "Name", value: "PostFilter" },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "accountByUsername" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "username" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "username" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "publicKey" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "posts" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "filter" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "filter" },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "totalCount" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<TotalPostsQuery, TotalPostsQueryVariables>;
export const TotalPostsMonthlyDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "TotalPostsMonthly" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "username" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "filter1" },
          },
          type: {
            kind: "NamedType",
            name: { kind: "Name", value: "PostFilter" },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "filter2" },
          },
          type: {
            kind: "NamedType",
            name: { kind: "Name", value: "PostFilter" },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "filter3" },
          },
          type: {
            kind: "NamedType",
            name: { kind: "Name", value: "PostFilter" },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "filter4" },
          },
          type: {
            kind: "NamedType",
            name: { kind: "Name", value: "PostFilter" },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "filter5" },
          },
          type: {
            kind: "NamedType",
            name: { kind: "Name", value: "PostFilter" },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "filter6" },
          },
          type: {
            kind: "NamedType",
            name: { kind: "Name", value: "PostFilter" },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "filter7" },
          },
          type: {
            kind: "NamedType",
            name: { kind: "Name", value: "PostFilter" },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "filter8" },
          },
          type: {
            kind: "NamedType",
            name: { kind: "Name", value: "PostFilter" },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "filter9" },
          },
          type: {
            kind: "NamedType",
            name: { kind: "Name", value: "PostFilter" },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "filter10" },
          },
          type: {
            kind: "NamedType",
            name: { kind: "Name", value: "PostFilter" },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "filter11" },
          },
          type: {
            kind: "NamedType",
            name: { kind: "Name", value: "PostFilter" },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "filter12" },
          },
          type: {
            kind: "NamedType",
            name: { kind: "Name", value: "PostFilter" },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "accountByUsername" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "username" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "username" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "publicKey" } },
                {
                  kind: "Field",
                  alias: { kind: "Name", value: "m1" },
                  name: { kind: "Name", value: "posts" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "filter" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "filter1" },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "totalCount" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  alias: { kind: "Name", value: "m2" },
                  name: { kind: "Name", value: "posts" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "filter" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "filter2" },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "totalCount" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  alias: { kind: "Name", value: "m3" },
                  name: { kind: "Name", value: "posts" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "filter" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "filter3" },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "totalCount" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  alias: { kind: "Name", value: "m4" },
                  name: { kind: "Name", value: "posts" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "filter" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "filter4" },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "totalCount" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  alias: { kind: "Name", value: "m5" },
                  name: { kind: "Name", value: "posts" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "filter" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "filter5" },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "totalCount" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  alias: { kind: "Name", value: "m6" },
                  name: { kind: "Name", value: "posts" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "filter" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "filter6" },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "totalCount" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  alias: { kind: "Name", value: "m7" },
                  name: { kind: "Name", value: "posts" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "filter" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "filter7" },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "totalCount" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  alias: { kind: "Name", value: "m8" },
                  name: { kind: "Name", value: "posts" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "filter" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "filter8" },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "totalCount" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  alias: { kind: "Name", value: "m9" },
                  name: { kind: "Name", value: "posts" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "filter" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "filter9" },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "totalCount" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  alias: { kind: "Name", value: "m10" },
                  name: { kind: "Name", value: "posts" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "filter" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "filter10" },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "totalCount" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  alias: { kind: "Name", value: "m11" },
                  name: { kind: "Name", value: "posts" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "filter" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "filter11" },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "totalCount" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  alias: { kind: "Name", value: "m12" },
                  name: { kind: "Name", value: "posts" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "filter" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "filter12" },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "totalCount" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  TotalPostsMonthlyQuery,
  TotalPostsMonthlyQueryVariables
>;
export const TotalMessagesDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "TotalMessages" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "username" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "accountByUsername" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "username" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "username" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "publicKey" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "messagesSent" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "totalCount" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "legacyMessagesSent" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "totalCount" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<TotalMessagesQuery, TotalMessagesQueryVariables>;
export const TotalMessagesMonthlyDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "TotalMessagesMonthly" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "username" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "filter1" },
          },
          type: {
            kind: "NamedType",
            name: { kind: "Name", value: "MessageFilter" },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "filter2" },
          },
          type: {
            kind: "NamedType",
            name: { kind: "Name", value: "MessageFilter" },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "filter3" },
          },
          type: {
            kind: "NamedType",
            name: { kind: "Name", value: "MessageFilter" },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "filter4" },
          },
          type: {
            kind: "NamedType",
            name: { kind: "Name", value: "MessageFilter" },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "filter5" },
          },
          type: {
            kind: "NamedType",
            name: { kind: "Name", value: "MessageFilter" },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "filter6" },
          },
          type: {
            kind: "NamedType",
            name: { kind: "Name", value: "MessageFilter" },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "filter7" },
          },
          type: {
            kind: "NamedType",
            name: { kind: "Name", value: "MessageFilter" },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "filter8" },
          },
          type: {
            kind: "NamedType",
            name: { kind: "Name", value: "MessageFilter" },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "filter9" },
          },
          type: {
            kind: "NamedType",
            name: { kind: "Name", value: "MessageFilter" },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "filter10" },
          },
          type: {
            kind: "NamedType",
            name: { kind: "Name", value: "MessageFilter" },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "filter11" },
          },
          type: {
            kind: "NamedType",
            name: { kind: "Name", value: "MessageFilter" },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "filter12" },
          },
          type: {
            kind: "NamedType",
            name: { kind: "Name", value: "MessageFilter" },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "accountByUsername" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "username" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "username" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "publicKey" } },
                {
                  kind: "Field",
                  alias: { kind: "Name", value: "m1" },
                  name: { kind: "Name", value: "messagesSent" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "filter" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "filter1" },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "totalCount" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  alias: { kind: "Name", value: "m2" },
                  name: { kind: "Name", value: "messagesSent" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "filter" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "filter2" },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "totalCount" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  alias: { kind: "Name", value: "m3" },
                  name: { kind: "Name", value: "messagesSent" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "filter" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "filter3" },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "totalCount" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  alias: { kind: "Name", value: "m4" },
                  name: { kind: "Name", value: "messagesSent" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "filter" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "filter4" },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "totalCount" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  alias: { kind: "Name", value: "m5" },
                  name: { kind: "Name", value: "messagesSent" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "filter" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "filter5" },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "totalCount" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  alias: { kind: "Name", value: "m6" },
                  name: { kind: "Name", value: "messagesSent" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "filter" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "filter6" },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "totalCount" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  alias: { kind: "Name", value: "m7" },
                  name: { kind: "Name", value: "messagesSent" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "filter" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "filter7" },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "totalCount" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  alias: { kind: "Name", value: "m8" },
                  name: { kind: "Name", value: "messagesSent" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "filter" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "filter8" },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "totalCount" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  alias: { kind: "Name", value: "m9" },
                  name: { kind: "Name", value: "messagesSent" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "filter" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "filter9" },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "totalCount" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  alias: { kind: "Name", value: "m10" },
                  name: { kind: "Name", value: "messagesSent" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "filter" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "filter10" },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "totalCount" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  alias: { kind: "Name", value: "m11" },
                  name: { kind: "Name", value: "messagesSent" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "filter" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "filter11" },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "totalCount" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  alias: { kind: "Name", value: "m12" },
                  name: { kind: "Name", value: "messagesSent" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "filter" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "filter12" },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "totalCount" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  TotalMessagesMonthlyQuery,
  TotalMessagesMonthlyQueryVariables
>;
export const ProfileTopCcHoldersDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "ProfileTopCCHolders" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "username" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "filter" },
          },
          type: {
            kind: "NamedType",
            name: { kind: "Name", value: "TokenBalanceFilter" },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "first" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "orderBy" },
          },
          type: {
            kind: "ListType",
            type: {
              kind: "NonNullType",
              type: {
                kind: "NamedType",
                name: { kind: "Name", value: "TokenBalancesOrderBy" },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "accountByUsername" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "username" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "username" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "publicKey" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "coinPriceDesoNanos" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "profile" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "ccCoinsInCirculationNanos",
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "coinPriceDesoNanos" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "tokenBalancesAsCreator" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "filter" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "filter" },
                      },
                    },
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "first" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "first" },
                      },
                    },
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "orderBy" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "orderBy" },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "nodes" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "FragmentSpread",
                              name: {
                                kind: "Name",
                                value: "TokenBalanceFields",
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "TokenBalanceFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "TokenBalance" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "balanceNanos" } },
          { kind: "Field", name: { kind: "Name", value: "hasPurchased" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "holder" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "username" } },
                { kind: "Field", name: { kind: "Name", value: "publicKey" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ProfileTopCcHoldersQuery,
  ProfileTopCcHoldersQueryVariables
>;
export const ProfileTransactionStatByPublicKeyDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "ProfileTransactionStatByPublicKey" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "publicKey" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "profileTransactionStatByPublicKey" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "publicKey" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "publicKey" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: {
                    kind: "Name",
                    value: "CoreProfileTransactionStatFields",
                  },
                },
                { kind: "Field", name: { kind: "Name", value: "count" } },
                { kind: "Field", name: { kind: "Name", value: "totalFees" } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "CoreProfileTransactionStatFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "ProfileTransactionStat" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "publicKey" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "firstTransactionTimestamp" },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "latestTransactionTimestamp" },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ProfileTransactionStatByPublicKeyQuery,
  ProfileTransactionStatByPublicKeyQueryVariables
>;
export const ProfileTransactionStatsDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "ProfileTransactionStats" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "first" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "orderBy" },
          },
          type: {
            kind: "ListType",
            type: {
              kind: "NonNullType",
              type: {
                kind: "NamedType",
                name: { kind: "Name", value: "ProfileTransactionStatsOrderBy" },
              },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "filter" },
          },
          type: {
            kind: "NamedType",
            name: { kind: "Name", value: "ProfileTransactionStatFilter" },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "profileTransactionStats" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "first" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "first" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "orderBy" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "orderBy" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "filter" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "filter" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "nodes" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "FragmentSpread",
                        name: {
                          kind: "Name",
                          value: "ProfileTransactionStatResult",
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "CoreProfileTransactionStatFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "ProfileTransactionStat" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "publicKey" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "firstTransactionTimestamp" },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "latestTransactionTimestamp" },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "CoreAccountFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Account" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "publicKey" } },
          { kind: "Field", name: { kind: "Name", value: "pkid" } },
          { kind: "Field", name: { kind: "Name", value: "username" } },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ProfileTransactionStatResult" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "ProfileTransactionStat" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "CoreProfileTransactionStatFields" },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "account" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "CoreAccountFields" },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ProfileTransactionStatsQuery,
  ProfileTransactionStatsQueryVariables
>;
export const GlobalSearchDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "GlobalSearch" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "includeAccounts" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "Boolean" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "includeBlocks" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "Boolean" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "includeTransactions" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "Boolean" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "accountsFilter" },
          },
          type: {
            kind: "NamedType",
            name: { kind: "Name", value: "AccountFilter" },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "blocksFilter" },
          },
          type: {
            kind: "NamedType",
            name: { kind: "Name", value: "BlockFilter" },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "transactionsFilter" },
          },
          type: {
            kind: "NamedType",
            name: { kind: "Name", value: "TransactionFilter" },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "first" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "orderBy" },
          },
          type: {
            kind: "ListType",
            type: {
              kind: "NonNullType",
              type: {
                kind: "NamedType",
                name: { kind: "Name", value: "AccountsOrderBy" },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "accounts" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "filter" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "accountsFilter" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "first" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "first" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "orderBy" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "orderBy" },
                },
              },
            ],
            directives: [
              {
                kind: "Directive",
                name: { kind: "Name", value: "include" },
                arguments: [
                  {
                    kind: "Argument",
                    name: { kind: "Name", value: "if" },
                    value: {
                      kind: "Variable",
                      name: { kind: "Name", value: "includeAccounts" },
                    },
                  },
                ],
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "nodes" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "FragmentSpread",
                        name: { kind: "Name", value: "CoreAccountFields" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "blocks" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "filter" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "blocksFilter" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "first" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "first" },
                },
              },
            ],
            directives: [
              {
                kind: "Directive",
                name: { kind: "Name", value: "include" },
                arguments: [
                  {
                    kind: "Argument",
                    name: { kind: "Name", value: "if" },
                    value: {
                      kind: "Variable",
                      name: { kind: "Name", value: "includeBlocks" },
                    },
                  },
                ],
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "nodes" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "FragmentSpread",
                        name: { kind: "Name", value: "BlockSearchResult" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "transactions" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "filter" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "transactionsFilter" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "first" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "first" },
                },
              },
            ],
            directives: [
              {
                kind: "Directive",
                name: { kind: "Name", value: "include" },
                arguments: [
                  {
                    kind: "Argument",
                    name: { kind: "Name", value: "if" },
                    value: {
                      kind: "Variable",
                      name: { kind: "Name", value: "includeTransactions" },
                    },
                  },
                ],
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "nodes" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "FragmentSpread",
                        name: {
                          kind: "Name",
                          value: "TransactionSearchResult",
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "CoreAccountFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Account" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "publicKey" } },
          { kind: "Field", name: { kind: "Name", value: "pkid" } },
          { kind: "Field", name: { kind: "Name", value: "username" } },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "BlockSearchResult" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Block" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "height" } },
          { kind: "Field", name: { kind: "Name", value: "blockHash" } },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "TransactionSearchResult" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Transaction" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "transactionHash" } },
          { kind: "Field", name: { kind: "Name", value: "txnType" } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GlobalSearchQuery, GlobalSearchQueryVariables>;
export const DesoBalancesDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "DesoBalances" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "first" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "offset" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "orderBy" },
          },
          type: {
            kind: "ListType",
            type: {
              kind: "NonNullType",
              type: {
                kind: "NamedType",
                name: { kind: "Name", value: "DesoBalancesOrderBy" },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "desoBalances" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "first" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "first" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "offset" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "offset" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "orderBy" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "orderBy" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "nodes" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "FragmentSpread",
                        name: { kind: "Name", value: "TopHolders" },
                      },
                    ],
                  },
                },
                { kind: "Field", name: { kind: "Name", value: "totalCount" } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "CoreAccountFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Account" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "publicKey" } },
          { kind: "Field", name: { kind: "Name", value: "pkid" } },
          { kind: "Field", name: { kind: "Name", value: "username" } },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "TopHolders" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "DesoBalance" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "publicKey" } },
          { kind: "Field", name: { kind: "Name", value: "balanceNanos" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "account" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "CoreAccountFields" },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DesoBalancesQuery, DesoBalancesQueryVariables>;
export const CoinHoldersDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "CoinHolders" },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "dashboardStats" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "nodes" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "walletCountAll" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CoinHoldersQuery, CoinHoldersQueryVariables>;
export const ProfilePortfolioValueStatByPublicKeyDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "ProfilePortfolioValueStatByPublicKey" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "publicKey" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: {
              kind: "Name",
              value: "profileEarningsBreakdownStatByPublicKey",
            },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "publicKey" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "publicKey" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "publicKey" } },
                { kind: "Field", name: { kind: "Name", value: "username" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "diamondsGivenCount" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "diamondsReceivedCount" },
                },
                { kind: "Field", name: { kind: "Name", value: "ccBuyCount" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "ccBuyAmountNanos" },
                },
                { kind: "Field", name: { kind: "Name", value: "ccSellCount" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "ccSellAmountNanos" },
                },
                { kind: "Field", name: { kind: "Name", value: "nftBuyCount" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "nftBuyAmountNanos" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "nftSellCount" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "nftSellAmountNanos" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "tokenBuyTradeCount" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "tokenBuyOrderNanosFilled" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "tokenSellTradeCount" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "tokenSellOrderNanosFillede" },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ProfilePortfolioValueStatByPublicKeyQuery,
  ProfilePortfolioValueStatByPublicKeyQueryVariables
>;
export const ProfileEarningsByPublicKeyDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "ProfileEarningsByPublicKey" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "publicKey" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "profileEarningsStatByPublicKey" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "publicKey" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "publicKey" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "publicKey" } },
                { kind: "Field", name: { kind: "Name", value: "username" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "totalCcRoyaltyNanos" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "totalDiamondNanos" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "totalNftRoyaltyNanos" },
                },
                {
                  kind: "Field",
                  name: {
                    kind: "Name",
                    value: "totalNftAdditionalRoyaltyNanos",
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "totalNftCreatorRoyaltyNanos" },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ProfileEarningsByPublicKeyQuery,
  ProfileEarningsByPublicKeyQueryVariables
>;
export const ProfilePortfolioByPublicKeyDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "ProfilePortfolioByPublicKey" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "publicKey" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: {
              kind: "Name",
              value: "profilePortfolioValueStatByPublicKey",
            },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "publicKey" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "publicKey" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "desoBalanceValueNanos" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "ccValueNanos" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "nftValueNanos" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "tokenValueNanos" },
                },
                { kind: "Field", name: { kind: "Name", value: "publicKey" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ProfilePortfolioByPublicKeyQuery,
  ProfilePortfolioByPublicKeyQueryVariables
>;
export const ProfileCcStatsDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "ProfileCCStats" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "username" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "accountByUsername" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "username" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "username" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "publicKey" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "profile" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "ccCoinsInCirculationNanos",
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "coinPriceDesoNanos" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "creatorCoinBalancesAsCreator" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "totalCount" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ProfileCcStatsQuery, ProfileCcStatsQueryVariables>;
export const GetStakingSummariesDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "GetStakingSummaries" },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "stakingSummaries" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "nodes" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "FragmentSpread",
                        name: { kind: "Name", value: "StakingOverview" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            alias: { kind: "Name", value: "currentEpoch" },
            name: { kind: "Name", value: "epochEntries" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "first" },
                value: { kind: "IntValue", value: "1" },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "orderBy" },
                value: { kind: "EnumValue", value: "EPOCH_NUMBER_DESC" },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "nodes" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "epochNumber" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "initialView" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "initialBlockHeight" },
                      },
                      {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "initialLeaderIndexOffset",
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "leaderScheduleEntries" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "nodes" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "FragmentSpread",
                                    name: { kind: "Name", value: "Leader" },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            alias: { kind: "Name", value: "currentBlock" },
            name: { kind: "Name", value: "blocks" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "first" },
                value: { kind: "IntValue", value: "1" },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "orderBy" },
                value: { kind: "EnumValue", value: "HEIGHT_DESC" },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "nodes" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "blockHash" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "height" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "proposedInView" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "epochDetailForBlock" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "proposer" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "FragmentSpread",
                                    name: {
                                      kind: "Name",
                                      value: "CoreAccountFields",
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "globalParamsEntries" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "nodes" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "epochDurationNumBlocks" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "StakingOverview" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "StakingSummary" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "globalStakeAmountNanos" },
          },
          { kind: "Field", name: { kind: "Name", value: "numValidators" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "currentEpochNumber" },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "numEpochsInLeaderSchedule" },
          },
          { kind: "Field", name: { kind: "Name", value: "numStakers" } },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "Leader" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "LeaderScheduleEntry" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "leaderAccount" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "username" } },
                { kind: "Field", name: { kind: "Name", value: "publicKey" } },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "leaderIndex" } },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "CoreAccountFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Account" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "publicKey" } },
          { kind: "Field", name: { kind: "Name", value: "pkid" } },
          { kind: "Field", name: { kind: "Name", value: "username" } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetStakingSummariesQuery,
  GetStakingSummariesQueryVariables
>;
export const GetGlobalStakeAmountDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "GetGlobalStakeAmount" },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "stakingSummaries" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "nodes" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "globalStakeAmountNanos" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetGlobalStakeAmountQuery,
  GetGlobalStakeAmountQueryVariables
>;
export const GetValidatorsOverviewDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "GetValidatorsOverview" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "viewerPublicKey" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "globalParamsEntries" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "nodes" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "stakingRewardsApyBasisPoints",
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "epochDurationNumBlocks" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            alias: { kind: "Name", value: "viewerOverview" },
            name: { kind: "Name", value: "accountByPublicKey" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "publicKey" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "viewerPublicKey" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "publicKey" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "myStakeSummary" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "totalStakeRewards" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "totalStake" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "stakeEntries" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "totalCount" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "nodes" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "FragmentSpread",
                              name: { kind: "Name", value: "StakeTableRow" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "lockedStakeEntries" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "totalCount" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "nodes" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "FragmentSpread",
                              name: {
                                kind: "Name",
                                value: "LockedStakeTableRow",
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "desoBalance" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "publicKey" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "balanceNanos" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "StakeTableRow" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "StakeEntry" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "stakeAmountNanos" } },
          { kind: "Field", name: { kind: "Name", value: "rewardMethod" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "validatorAccount" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "username" } },
                { kind: "Field", name: { kind: "Name", value: "publicKey" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "validatorEntry" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "validatorPkid" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "totalStakeAmountNanos" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "jailedAtEpochNumber" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "account" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "username" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "publicKey" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  alias: { kind: "Name", value: "viewerStakeRewardsSum" },
                  name: {
                    kind: "Name",
                    value: "validatorStakeRewardsByStaker",
                  },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "filter" },
                      value: {
                        kind: "ObjectValue",
                        fields: [
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "stakerAccount" },
                            value: {
                              kind: "ObjectValue",
                              fields: [
                                {
                                  kind: "ObjectField",
                                  name: { kind: "Name", value: "publicKey" },
                                  value: {
                                    kind: "ObjectValue",
                                    fields: [
                                      {
                                        kind: "ObjectField",
                                        name: {
                                          kind: "Name",
                                          value: "equalTo",
                                        },
                                        value: {
                                          kind: "Variable",
                                          name: {
                                            kind: "Name",
                                            value: "viewerPublicKey",
                                          },
                                        },
                                      },
                                    ],
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "nodes" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "rewardNanos" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "LockedStakeTableRow" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "LockedStakeEntry" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "lockedAmountNanos" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "lockedAtEpochNumber" },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "validatorAccount" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "username" } },
                { kind: "Field", name: { kind: "Name", value: "publicKey" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "validatorEntry" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "validatorPkid" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "jailedAtEpochNumber" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "account" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "username" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "publicKey" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetValidatorsOverviewQuery,
  GetValidatorsOverviewQueryVariables
>;
export const GetValidatorsDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "GetValidators" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "viewerPublicKey" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "first" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "after" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "Cursor" } },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "last" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "before" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "Cursor" } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "validatorStats" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "first" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "first" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "after" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "after" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "before" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "before" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "last" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "last" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "orderBy" },
                value: { kind: "EnumValue", value: "VALIDATOR_RANK_ASC" },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "totalCount" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "pageInfo" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "hasNextPage" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "hasPreviousPage" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "endCursor" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "startCursor" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "nodes" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "FragmentSpread",
                        name: { kind: "Name", value: "ValidatorsTableRow" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "PartialValidator" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "ValidatorEntry" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "validatorPkid" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "jailedAtEpochNumber" },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "lastActiveAtEpochNumber" },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "totalStakeAmountNanos" },
          },
          {
            kind: "Field",
            name: {
              kind: "Name",
              value: "delegatedStakeCommissionBasisPoints",
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "disableDelegatedStake" },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "validatorStats" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "percentTotalStake" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "validatorRank" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "account" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "username" } },
                { kind: "Field", name: { kind: "Name", value: "publicKey" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "stakeEntries" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "totalCount" } },
              ],
            },
          },
          {
            kind: "Field",
            alias: { kind: "Name", value: "viewerStake" },
            name: { kind: "Name", value: "stakeEntries" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "filter" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "staker" },
                      value: {
                        kind: "ObjectValue",
                        fields: [
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "publicKey" },
                            value: {
                              kind: "ObjectValue",
                              fields: [
                                {
                                  kind: "ObjectField",
                                  name: { kind: "Name", value: "equalTo" },
                                  value: {
                                    kind: "Variable",
                                    name: {
                                      kind: "Name",
                                      value: "viewerPublicKey",
                                    },
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "nodes" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "stakeAmountNanos" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "rewardMethod" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "staker" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "publicKey" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "desoBalance" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "publicKey" },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "balanceNanos",
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ValidatorsTableRow" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "ValidatorStat" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "validatorPkid" } },
          { kind: "Field", name: { kind: "Name", value: "validatorRank" } },
          { kind: "Field", name: { kind: "Name", value: "percentTotalStake" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "validatorEntry" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "PartialValidator" },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetValidatorsQuery, GetValidatorsQueryVariables>;
export const GetValidatorByUsernameDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "GetValidatorByUsername" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "username" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "viewerPublicKey" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "accountByUsername" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "username" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "username" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "CoreAccountFields" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "validatorEntry" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "FragmentSpread",
                        name: { kind: "Name", value: "Validator" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "CoreAccountFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Account" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "publicKey" } },
          { kind: "Field", name: { kind: "Name", value: "pkid" } },
          { kind: "Field", name: { kind: "Name", value: "username" } },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "Validator" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "ValidatorEntry" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "validatorPkid" } },
          { kind: "Field", name: { kind: "Name", value: "domains" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "jailedAtEpochNumber" },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "lastActiveAtEpochNumber" },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "totalStakeAmountNanos" },
          },
          {
            kind: "Field",
            name: {
              kind: "Name",
              value: "delegatedStakeCommissionBasisPoints",
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "disableDelegatedStake" },
          },
          { kind: "Field", name: { kind: "Name", value: "votingPublicKey" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "votingAuthorization" },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "validatorStats" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "percentTotalStake" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "validatorRank" },
                },
              ],
            },
          },
          {
            kind: "Field",
            alias: { kind: "Name", value: "viewerStakeRewardsSum" },
            name: { kind: "Name", value: "validatorStakeRewardsByStaker" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "filter" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "stakerAccount" },
                      value: {
                        kind: "ObjectValue",
                        fields: [
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "publicKey" },
                            value: {
                              kind: "ObjectValue",
                              fields: [
                                {
                                  kind: "ObjectField",
                                  name: { kind: "Name", value: "equalTo" },
                                  value: {
                                    kind: "Variable",
                                    name: {
                                      kind: "Name",
                                      value: "viewerPublicKey",
                                    },
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "nodes" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "rewardNanos" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "account" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "username" } },
                { kind: "Field", name: { kind: "Name", value: "publicKey" } },
                { kind: "Field", name: { kind: "Name", value: "description" } },
                { kind: "Field", name: { kind: "Name", value: "extraData" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "stakeEntries" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "totalCount" } },
              ],
            },
          },
          {
            kind: "Field",
            alias: { kind: "Name", value: "viewerStake" },
            name: { kind: "Name", value: "stakeEntries" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "filter" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "staker" },
                      value: {
                        kind: "ObjectValue",
                        fields: [
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "publicKey" },
                            value: {
                              kind: "ObjectValue",
                              fields: [
                                {
                                  kind: "ObjectField",
                                  name: { kind: "Name", value: "equalTo" },
                                  value: {
                                    kind: "Variable",
                                    name: {
                                      kind: "Name",
                                      value: "viewerPublicKey",
                                    },
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "nodes" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "stakeAmountNanos" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "rewardMethod" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "staker" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "publicKey" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "desoBalance" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "publicKey" },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "balanceNanos",
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetValidatorByUsernameQuery,
  GetValidatorByUsernameQueryVariables
>;
export const GetValidatorByPublicKeyDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "GetValidatorByPublicKey" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "publicKey" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "viewerPublicKey" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "accountByPublicKey" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "publicKey" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "publicKey" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "CoreAccountFields" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "validatorEntry" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "FragmentSpread",
                        name: { kind: "Name", value: "Validator" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "CoreAccountFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Account" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "publicKey" } },
          { kind: "Field", name: { kind: "Name", value: "pkid" } },
          { kind: "Field", name: { kind: "Name", value: "username" } },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "Validator" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "ValidatorEntry" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "validatorPkid" } },
          { kind: "Field", name: { kind: "Name", value: "domains" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "jailedAtEpochNumber" },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "lastActiveAtEpochNumber" },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "totalStakeAmountNanos" },
          },
          {
            kind: "Field",
            name: {
              kind: "Name",
              value: "delegatedStakeCommissionBasisPoints",
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "disableDelegatedStake" },
          },
          { kind: "Field", name: { kind: "Name", value: "votingPublicKey" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "votingAuthorization" },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "validatorStats" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "percentTotalStake" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "validatorRank" },
                },
              ],
            },
          },
          {
            kind: "Field",
            alias: { kind: "Name", value: "viewerStakeRewardsSum" },
            name: { kind: "Name", value: "validatorStakeRewardsByStaker" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "filter" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "stakerAccount" },
                      value: {
                        kind: "ObjectValue",
                        fields: [
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "publicKey" },
                            value: {
                              kind: "ObjectValue",
                              fields: [
                                {
                                  kind: "ObjectField",
                                  name: { kind: "Name", value: "equalTo" },
                                  value: {
                                    kind: "Variable",
                                    name: {
                                      kind: "Name",
                                      value: "viewerPublicKey",
                                    },
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "nodes" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "rewardNanos" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "account" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "username" } },
                { kind: "Field", name: { kind: "Name", value: "publicKey" } },
                { kind: "Field", name: { kind: "Name", value: "description" } },
                { kind: "Field", name: { kind: "Name", value: "extraData" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "stakeEntries" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "totalCount" } },
              ],
            },
          },
          {
            kind: "Field",
            alias: { kind: "Name", value: "viewerStake" },
            name: { kind: "Name", value: "stakeEntries" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "filter" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "staker" },
                      value: {
                        kind: "ObjectValue",
                        fields: [
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "publicKey" },
                            value: {
                              kind: "ObjectValue",
                              fields: [
                                {
                                  kind: "ObjectField",
                                  name: { kind: "Name", value: "equalTo" },
                                  value: {
                                    kind: "Variable",
                                    name: {
                                      kind: "Name",
                                      value: "viewerPublicKey",
                                    },
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "nodes" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "stakeAmountNanos" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "rewardMethod" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "staker" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "publicKey" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "desoBalance" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "publicKey" },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "balanceNanos",
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetValidatorByPublicKeyQuery,
  GetValidatorByPublicKeyQueryVariables
>;
export const GetStakeByStakerPublicKeyAndValidatorPublicKeyDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: {
        kind: "Name",
        value: "GetStakeByStakerPublicKeyAndValidatorPublicKey",
      },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "stakerPublicKey" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "validatorPublicKey" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "stakeEntries" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "filter" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "staker" },
                      value: {
                        kind: "ObjectValue",
                        fields: [
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "publicKey" },
                            value: {
                              kind: "ObjectValue",
                              fields: [
                                {
                                  kind: "ObjectField",
                                  name: { kind: "Name", value: "equalTo" },
                                  value: {
                                    kind: "Variable",
                                    name: {
                                      kind: "Name",
                                      value: "stakerPublicKey",
                                    },
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "validatorAccount" },
                      value: {
                        kind: "ObjectValue",
                        fields: [
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "publicKey" },
                            value: {
                              kind: "ObjectValue",
                              fields: [
                                {
                                  kind: "ObjectField",
                                  name: { kind: "Name", value: "equalTo" },
                                  value: {
                                    kind: "Variable",
                                    name: {
                                      kind: "Name",
                                      value: "validatorPublicKey",
                                    },
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "nodes" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "FragmentSpread",
                        name: { kind: "Name", value: "Stake" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "Stake" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "StakeEntry" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "stakeAmountNanos" } },
          { kind: "Field", name: { kind: "Name", value: "rewardMethod" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "staker" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "publicKey" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "desoBalance" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "publicKey" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "balanceNanos" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetStakeByStakerPublicKeyAndValidatorPublicKeyQuery,
  GetStakeByStakerPublicKeyAndValidatorPublicKeyQueryVariables
>;
export const GetTransactionByHashDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "GetTransactionByHash" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "transactionHash" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "transactionByTransactionHash" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "transactionHash" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "transactionHash" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "transactionId" },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetTransactionByHashQuery,
  GetTransactionByHashQueryVariables
>;
export const StakeEntriesByValidatorPkidDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "StakeEntriesByValidatorPkid" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "validatorPkid" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "first" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "after" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "Cursor" } },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "last" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "before" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "Cursor" } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "stakeEntries" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "condition" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "validatorPkid" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "validatorPkid" },
                      },
                    },
                  ],
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "first" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "first" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "after" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "after" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "before" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "before" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "last" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "last" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "orderBy" },
                value: { kind: "EnumValue", value: "STAKE_AMOUNT_NANOS_DESC" },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "totalCount" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "pageInfo" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "hasNextPage" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "hasPreviousPage" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "startCursor" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "endCursor" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "nodes" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "FragmentSpread",
                        name: { kind: "Name", value: "Staker" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "Staker" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "StakeEntry" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            alias: { kind: "Name", value: "account" },
            name: { kind: "Name", value: "staker" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "publicKey" } },
                { kind: "Field", name: { kind: "Name", value: "username" } },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "stakeAmountNanos" } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  StakeEntriesByValidatorPkidQuery,
  StakeEntriesByValidatorPkidQueryVariables
>;
export const GetFinalBlockHeightForEpochDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "GetFinalBlockHeightForEpoch" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "epochNumber" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "BigInt" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "epochEntry" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "epochNumber" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "epochNumber" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "finalBlockHeight" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "blocks" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "first" },
                value: { kind: "IntValue", value: "1" },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "orderBy" },
                value: { kind: "EnumValue", value: "HEIGHT_DESC" },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "nodes" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "blockHash" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "height" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "globalParamsEntries" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "nodes" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "epochDurationNumBlocks" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetFinalBlockHeightForEpochQuery,
  GetFinalBlockHeightForEpochQueryVariables
>;
export const MyStakeSummariesDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "MyStakeSummaries" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "filter" },
          },
          type: {
            kind: "NamedType",
            name: { kind: "Name", value: "MyStakeSummaryFilter" },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "myStakeSummaries" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "filter" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "filter" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "nodes" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "totalStake" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  MyStakeSummariesQuery,
  MyStakeSummariesQueryVariables
>;
