import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar";
import { cn } from "@/lib";
import { Link } from "react-router-dom";
import { NODE_URI } from "../../utils/constants";

interface DesoAvatarProps {
  publicKey: string;
  username?: string;
  className?: string;
  size?: number;
  clickable?: boolean;
  linkType?: "user" | "validator";
}

const LINK_TYPE_TO_PATH = {
  user: "/u",
  validator: "/validator",
};

function getInitials(name: string): string {
  const words = name.trim().split(/\s+/);

  if (words.length === 1) {
    return words[0][0].toUpperCase();
  } else if (words.length >= 2) {
    const firstInitial = words[0][0].toUpperCase();
    const lastInitial = words[words.length - 1][0].toUpperCase();
    return firstInitial + lastInitial;
  }

  return "";
}

function getProfilePicUrl(publicKey: string): string {
  return `${NODE_URI}/api/v0/get-single-profile-picture/${publicKey}?fallback=${window.location.origin}/assets/img/app-default-profile.png`;
}

const DesoAvatar = ({
  publicKey,
  username,
  className,
  size = 18,
  clickable = false,
  linkType = "user",
}: DesoAvatarProps) => {
  const avatar = (
    <Avatar
      className={cn(
        "cursor-pointer border w-5 h-5 hover:border-secondary",
        className,
      )}
      style={{
        width: size + "px",
        height: size + "px",
      }}
    >
      <AvatarImage src={getProfilePicUrl(publicKey)} />
      {username && <AvatarFallback>{getInitials(username)}</AvatarFallback>}
    </Avatar>
  );

  if (!clickable) {
    return avatar;
  }

  return (
    <Link to={`${LINK_TYPE_TO_PATH[linkType]}/${username || publicKey}`}>
      {avatar}
    </Link>
  );
};

export default DesoAvatar;
