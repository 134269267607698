import {
  EditIcon,
  HeartIcon,
  MessageSquareIcon,
  MessagesSquareIcon,
  PaintBucketIcon,
  RepeatIcon,
} from "lucide-react";
import {
  AccountDetailsFragment,
  PostFilter,
  TotalMessagesDocument,
  TotalMessagesMonthlyDocument,
  TotalPostsDocument,
  TotalPostsMonthlyDocument,
} from "../../graphql/codegen/graphql";
import { Metric } from "../ui/metric";
import { useLazyQuery } from "@apollo/client";
import { client } from "../../graphql/client";
import { useEffect, useState } from "react";
import { formatDecimalValue } from "../../utils/currency";
import { range } from "../../utils/helpers";
import { Skeleton } from "@/components/ui/skeleton";
import PlotTransactions from "@/components/home/plot-transactions";
import dayjs from "dayjs";
import dayjsPluginUTC from "dayjs/plugin/utc";
import { TransactionPlotData } from "@/components/home/network-activity";
import { useToast } from "@/components/ui/use-toast";
import { useMobile } from "../../hooks/use-mobile";

dayjs.extend(dayjsPluginUTC);

interface UserSocialAcitivityProps {
  user: AccountDetailsFragment;
}

enum PostTypes {
  Posts = "Posts",
  Reposts = "Reposts",
  Comments = "Comments",
  Messages = "Messages",
  NFTs = "NFTs",
}

const DETAILS_BY_POST_TYPE = {
  [PostTypes.Posts]: {
    filter: {
      parentPostHash: {
        isNull: true,
      },
      repostedPostHash: {
        isNull: true,
      },
    },
  },
  [PostTypes.Reposts]: {
    filter: {
      parentPostHash: {
        isNull: true,
      },
      repostedPostHash: {
        isNull: false,
      },
    },
  },
  [PostTypes.Messages]: {
    filter: {},
  },
  [PostTypes.Comments]: {
    filter: {
      parentPostHash: {
        isNull: false,
      },
      repostedPostHash: {
        isNull: true,
      },
    },
  },
  [PostTypes.NFTs]: {
    filter: {
      isNft: {
        equalTo: true,
      },
    },
  },
};

const SKELETON_NUM_OF_CARDS = 5;

const UserSocialAcitivity = ({ user }: UserSocialAcitivityProps) => {
  const { isMobile } = useMobile();
  const { toast } = useToast();

  const [fetchTotalPostsLazy] = useLazyQuery(TotalPostsDocument, {
    client,
  });
  const [fetchTotalPostsMonthlyLazy, { loading: loadingMonthlyTotalPosts }] =
    useLazyQuery(TotalPostsMonthlyDocument, {
      client,
    });
  const [fetchTotalMessagesLazy] = useLazyQuery(TotalMessagesDocument, {
    client,
  });
  const [
    fetchTotalMessagesMonthlyLazy,
    { loading: loadingMonthlyTotalMessages },
  ] = useLazyQuery(TotalMessagesMonthlyDocument, {
    client,
  });

  const [totals, setTotals] = useState({
    posts: 0,
    reposts: 0,
    comments: 0,
    messages: 0,
    nfts: 0,
  });
  const [transactionType, setTransactionType] = useState<PostTypes>(
    PostTypes.Posts,
  );
  const [monthlyStats, setMonthlyStats] = useState<TransactionPlotData>({
    series: [],
    categories: [],
  });
  const [loadingTotals, setLoadingTotals] = useState<boolean>(false);

  const fetchTotalPosts = async (filter: PostFilter) => {
    const payload = {
      username: user.username || "",
      filter,
    };
    const { data } = await fetchTotalPostsLazy({
      variables: payload,
    });

    return data?.accountByUsername?.posts.totalCount || 0;
  };

  const fetchTotalMessages = async () => {
    const { data } = await fetchTotalMessagesLazy({
      variables: {
        username: user.username || "",
      },
    });

    return (
      (data?.accountByUsername?.messagesSent.totalCount || 0) +
      (data?.accountByUsername?.legacyMessagesSent.totalCount || 0)
    );
  };

  useEffect(() => {
    const getTotalPosts = async () => {
      setLoadingTotals(true);

      try {
        const [
          totalPosts,
          totalReposts,
          totalComments,
          totalMessages,
          totalNFTs,
        ] = await Promise.all([
          fetchTotalPosts(DETAILS_BY_POST_TYPE[PostTypes.Posts].filter),
          fetchTotalPosts(DETAILS_BY_POST_TYPE[PostTypes.Reposts].filter),
          fetchTotalPosts(DETAILS_BY_POST_TYPE[PostTypes.Comments].filter),
          fetchTotalMessages(),
          fetchTotalPosts(DETAILS_BY_POST_TYPE[PostTypes.NFTs].filter),
        ]);

        setTotals({
          posts: totalPosts,
          reposts: totalReposts,
          comments: totalComments,
          messages: totalMessages,
          nfts: totalNFTs,
        });
      } catch (e: any) {
        toast({
          variant: "destructive",
          title: "Error",
          description: `There was a problem getting total stats. ${JSON.stringify(
            e,
          )}`,
        });
      } finally {
        setLoadingTotals(false);
      }
    };

    getTotalPosts();
  }, [user]);

  useEffect(() => {
    const currentDate = dayjs.utc();

    const filters = range(12).reduce(
      (acc, curr, i) => {
        return {
          ...acc,
          [`filter${i + 1}`]: {
            ...DETAILS_BY_POST_TYPE[transactionType].filter,
            timestamp: {
              greaterThanOrEqualTo: currentDate
                .subtract(i, "month")
                .startOf("month")
                .toISOString(),
              lessThan: currentDate
                .subtract(i, "month")
                .endOf("month")
                .toISOString(),
            },
          },
        };
      },
      { username: user.username || "" },
    );

    const getMonthlyStats = async () => {
      const { data } =
        transactionType === PostTypes.Messages
          ? await fetchTotalMessagesMonthlyLazy({
              variables: filters,
            })
          : await fetchTotalPostsMonthlyLazy({
              variables: filters,
            });

      const res = range(12).reduce(
        (acc, curr, i) => {
          const metricKey = `m${i + 1}`;
          const currentMetric = (data?.accountByUsername as any)?.[
            metricKey
          ] || {
            totalCount: 0,
          };

          return {
            ...acc,
            categories: [
              ...acc.categories,
              currentDate.subtract(i, "month").toISOString(),
            ],
            series: [...acc.series, currentMetric.totalCount],
          };
        },
        {
          categories: [],
          series: [],
        } as TransactionPlotData,
      );

      setMonthlyStats({
        categories: res.categories.reverse(),
        series: res.series.reverse(),
      });
    };

    getMonthlyStats();
  }, [transactionType]);

  return (
    <div className="mb-12">
      <div className="flex justify-between items-center">
        <h3 className="mb-4 flex items-center">
          <HeartIcon className="mr-2" />
          Social Activity
        </h3>
      </div>

      <div className="p-4 pb-0 border border-border rounded-2xl">
        <div className="grid grid-cols-1 lg:grid-cols-5 gap-4">
          {loadingTotals ? (
            range(SKELETON_NUM_OF_CARDS).map((_, i) => (
              <Skeleton className="h-[116px]" key={i} />
            ))
          ) : (
            <>
              <Metric
                value={formatDecimalValue(totals.posts)}
                label="Total Posts"
                icon={<EditIcon />}
                state={
                  transactionType === PostTypes.Posts ? "active" : "inActive"
                }
                onClick={() => setTransactionType(PostTypes.Posts)}
              />

              <Metric
                value={formatDecimalValue(totals.reposts)}
                label="Total Reposts"
                state={
                  transactionType === PostTypes.Reposts ? "active" : "inActive"
                }
                onClick={() => setTransactionType(PostTypes.Reposts)}
                tooltip="This includes reposts & quote-posts."
                icon={<RepeatIcon />}
              />

              <Metric
                value={formatDecimalValue(totals.comments)}
                label="Total Comments"
                state={
                  transactionType === PostTypes.Comments ? "active" : "inActive"
                }
                onClick={() => setTransactionType(PostTypes.Comments)}
                icon={<MessageSquareIcon />}
              />

              <Metric
                value={formatDecimalValue(totals.messages)}
                label="Total Messages"
                tooltip="The graph below only shows v3 messages."
                state={
                  transactionType === PostTypes.Messages ? "active" : "inActive"
                }
                onClick={() => setTransactionType(PostTypes.Messages)}
                icon={<MessagesSquareIcon />}
              />

              <Metric
                value={formatDecimalValue(totals.nfts)}
                state={
                  transactionType === PostTypes.NFTs ? "active" : "inActive"
                }
                onClick={() => setTransactionType(PostTypes.NFTs)}
                label="Total NFTs Minted"
                icon={<PaintBucketIcon />}
              />
            </>
          )}
        </div>

        {loadingMonthlyTotalPosts || loadingMonthlyTotalMessages ? (
          <Skeleton className="min-h-[280px] my-4" />
        ) : (
          <>
            <div className="mt-4 pl-4 text-xs">Previous 12 Months:</div>
            <PlotTransactions
              plotData={monthlyStats}
              tooltipLabel={PostTypes[transactionType]}
              formatter={(e: string) => {
                const date = dayjs(e);
                return date.format("MMM, YYYY");
              }}
              tickAmount={isMobile ? 6 : undefined}
            />
          </>
        )}
      </div>
    </div>
  );
};

export default UserSocialAcitivity;
