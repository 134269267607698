import { cn } from "@/lib";
import dayjs from "dayjs";
import { CalendarIcon, X } from "lucide-react";
import { Button } from "../ui/button";
import { Popover, PopoverContent, PopoverTrigger } from "../ui/popover";
import { Calendar } from "@/components/ui/calendar";
import { DateRange } from "react-day-picker";

interface DateFiltersProps {
  className?: string;
  dateFilter: DateRange;
  setDateFilter: (dateFilter?: DateRange) => void;
}

export function DateFilters({
  className,
  dateFilter,
  setDateFilter,
}: DateFiltersProps) {
  return (
    <div className={cn("grid gap-2", className)}>
      <Popover>
        <PopoverTrigger asChild>
          <div className="relative">
            <Button
              id="date"
              variant={"outline"}
              className={cn(
                "w-full bg-input rounded-full hover:bg-input border-0 justify-start text-left font-normal",
                !dateFilter && "text-muted-foreground",
              )}
            >
              <CalendarIcon className="mr-2 h-4 w-4" />
              {dateFilter?.from ? (
                dateFilter.to ? (
                  <>
                    {dayjs(dateFilter.from).format("MMM DD, YYYY")} -{" "}
                    {dayjs(dateFilter.to).format("MMM DD, YYYY")}
                  </>
                ) : (
                  dayjs(dateFilter.from).format("MMM DD, YYYY")
                )
              ) : (
                <span>Pick a date</span>
              )}
            </Button>

            {(dateFilter.to || dateFilter.from) && (
              <X
                className="text-destructive absolute right-2 top-0 w-5 cursor-pointer z-50 p-2 box-content"
                onClick={(e) => {
                  setDateFilter({
                    to: undefined,
                    from: undefined,
                  });
                  e.stopPropagation();
                }}
              />
            )}
          </div>
        </PopoverTrigger>

        <PopoverContent className="w-auto p-0" align="start">
          <Calendar
            initialFocus
            mode="range"
            defaultMonth={dateFilter?.from}
            selected={dateFilter}
            onSelect={setDateFilter}
            numberOfMonths={2}
            disabled={(date) => date > new Date()}
          />
        </PopoverContent>
      </Popover>
    </div>
  );
}
