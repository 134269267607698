import { Button } from "../ui/button";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../ui/table";
import { Block, Transaction } from "../../graphql/codegen/graphql";
import { TableTimestampFormatter } from "@/components/shared/table-timestamp-formatter";
import { TxnActionItem } from "@/components/shared/txn-action-item";
import dayjs from "dayjs";
import { formatDecimalValue } from "../../utils/currency";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "../ui/dropdown-menu";
import { MoreHorizontal } from "lucide-react";
import CopyToClipboard from "@/components/shared/copy-to-clipboard";
import BlockLink from "@/components/blocks/block-link";
import { useTableScroll } from "../../hooks/use-table-scroll";
import { parseTimestamp } from "../../utils/helpers";

interface TableBlocksProps {
  blocks: Array<Block>;
  total: number;
  offset: number;
  perPage: number;
  loadingPage: boolean;
  onPrevPage?: () => void;
  onNextPage?: () => void;
  hideFooter?: boolean;
  hideDropdown?: boolean;
}

const TableBlocks = ({
  blocks,
  total,
  offset,
  perPage,
  loadingPage,
  onPrevPage,
  onNextPage,
  hideFooter,
  hideDropdown,
}: TableBlocksProps) => {
  const { wrapperElement, onPageChange } = useTableScroll();

  const headers = ["Block", "Age", "Txns", "Actions"];

  if (!hideDropdown) {
    headers.push("");
  }

  return (
    <div className="w-full" ref={wrapperElement}>
      <div className="relative rounded-2xl border overflow-hidden">
        {loadingPage && (
          <div className="loader-line h-[4px] md:h-[2px] before:h-[4px] md:before:h-[2px]"></div>
        )}
        <Table>
          <TableHeader>
            <TableRow>
              {headers.map((e) => (
                <TableHead key={e}>{e}</TableHead>
              ))}
            </TableRow>
          </TableHeader>

          <TableBody>
            {blocks.length === 0 ? (
              <TableRow>
                <TableCell colSpan={headers.length} className="text-center">
                  No blocks found.
                </TableCell>
              </TableRow>
            ) : (
              blocks.map((e) => {
                const timestamp = parseTimestamp(e?.timestamp);

                const transactions = e.transactions?.nodes || [];
                const slicedTransactions = transactions.length <= 1 ? 0 : 1;
                const transactionsList = transactions
                  .slice(slicedTransactions) // We use .slice(1) to remove "Received block reward" transaction that is usually the first one
                  .filter((e) => e !== null) as Array<Transaction>;

                const hiddenTransactions =
                  e.transactions.totalCount - transactionsList.length;

                return (
                  <TableRow
                    key={e.height}
                    className="hover:bg-card dark:hover:bg-black/10 border-border-light"
                  >
                    <TableCell className="align-top pt-2 pb-1 px-4 text-xs">
                      <BlockLink
                        height={e.height}
                        className="hover:underline"
                      />
                    </TableCell>
                    <TableCell className="align-top pt-2 pb-1 px-4 text-xs">
                      <TableTimestampFormatter timestamp={timestamp} />
                    </TableCell>
                    <TableCell className="align-top pt-2 pb-1 px-4 text-xs">
                      {e.transactions.totalCount}
                    </TableCell>
                    <TableCell className="align-top p-1 px-4 text-xs">
                      {transactionsList.map((transaction) => (
                        <TxnActionItem
                          key={transaction.transactionHash}
                          item={transaction}
                          maxInnerTxnsToDisplay={2}
                        />
                      ))}
                    </TableCell>
                    {!hideDropdown && (
                      <TableCell className="align-top p-1 px-4 text-xs">
                        <div className="text-right flex flex-col items-end justify-between relative h-[90px]">
                          <DropdownMenu>
                            <DropdownMenuTrigger asChild>
                              <Button
                                variant="ghost"
                                className="h-8 w-8 p-0 rounded-full"
                              >
                                <span className="sr-only">Open menu</span>
                                <MoreHorizontal className="h-4 w-4" />
                              </Button>
                            </DropdownMenuTrigger>

                            <DropdownMenuContent align="end">
                              <DropdownMenuItem className="p-0">
                                <BlockLink
                                  height={e.height}
                                  className="px-2 py-1.5 w-full"
                                >
                                  View Details
                                </BlockLink>
                              </DropdownMenuItem>

                              <DropdownMenuItem className="p-0">
                                <CopyToClipboard
                                  text={e.blockHash}
                                  showToast={true}
                                  showIcon={false}
                                  className="px-2 py-1.5 w-full"
                                  toastText={
                                    "The block hash has been successfully copied to your clipboard"
                                  }
                                >
                                  Copy Block Hash
                                </CopyToClipboard>
                              </DropdownMenuItem>

                              <DropdownMenuItem className="p-0">
                                <CopyToClipboard
                                  text={e.height}
                                  showToast={true}
                                  showIcon={false}
                                  className="px-2 py-1.5 w-full"
                                  toastText={
                                    "The block height has been successfully copied to your clipboard"
                                  }
                                >
                                  Copy Block Height
                                </CopyToClipboard>
                              </DropdownMenuItem>
                            </DropdownMenuContent>

                            <BlockLink
                              height={e.height}
                              className="pb-1 text-xs hover:underline"
                            >
                              View{" "}
                              {hiddenTransactions > 0
                                ? `+${hiddenTransactions}`
                                : "all"}{" "}
                              transactions
                            </BlockLink>
                          </DropdownMenu>
                        </div>
                      </TableCell>
                    )}
                  </TableRow>
                );
              })
            )}
          </TableBody>
        </Table>
      </div>

      {!hideFooter && (
        <div className="flex items-center justify-end space-x-2 py-4">
          <div className="flex-1 text-xs text-muted">
            Showing {offset + 1}-{offset + blocks.length} of{" "}
            {formatDecimalValue(total)} blocks
          </div>

          <div className="space-x-2">
            <Button
              variant="outline"
              size="sm"
              onClick={() => {
                if (onPrevPage) {
                  onPrevPage();
                  onPageChange();
                }
              }}
              disabled={offset === 0}
              className={
                loadingPage ? "pointer-events-none cursor-default" : ""
              }
            >
              Previous
            </Button>

            <Button
              variant="outline"
              size="sm"
              onClick={() => {
                if (onNextPage) {
                  onNextPage();
                  onPageChange();
                }
              }}
              disabled={offset + perPage >= total}
              className={
                loadingPage ? "pointer-events-none cursor-default" : ""
              }
            >
              Next
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

export default TableBlocks;
