import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { PlayCircleIcon } from "lucide-react";

export function VideoTutorialModal() {
  return (
    <Dialog>
      <DialogTrigger asChild>
        <div className="flex items-center flex-col md:flex-row md:gap-2 text-sm text-center justify-center">
          <h2 className="text-muted-foreground font-semibold">
            Learn How to Stake $DESO
          </h2>
          <div className="flex items-center gap-2">
            <PlayCircleIcon />{" "}
            <Button
              variant="link"
              className="text-muted underline hover:text-muted-foreground px-0"
            >
              Watch Staking Tutorial
            </Button>
          </div>
        </div>
      </DialogTrigger>

      <DialogContent className="max-w-[800px]">
        <DialogHeader>
          <DialogTitle>Learn How to Stake $DESO</DialogTitle>
        </DialogHeader>
        <div className="grid gap-4 pb-0">
          <iframe
            className="w-full h-[460px] border border-border-light rounded-lg border-solid"
            src="https://www.youtube.com/embed/CqSndsA8WP4?autoplay=1"
            title="Revolution PoS FULL Tutorial | Earn 20% APY on DESO"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowFullScreen
          />
        </div>
      </DialogContent>
    </Dialog>
  );
}
