import { BoxIcon } from "lucide-react";
import { useEffect, useState } from "react";
import { useLazyQuery } from "@apollo/client";
import {
  Block,
  BlockDocument,
  BlocksOrderBy,
  TransactionsOrderBy,
} from "../../graphql/codegen/graphql";
import { client } from "../../graphql/client";
import { useToast } from "@/components/ui/use-toast";
import { Skeleton } from "../ui/skeleton";
import TableBlocks from "@/components/shared/table-blocks";
import { Link } from "react-router-dom";

const ITEMS_PER_PAGE = 10;
const TRANSACTIONS_IN_BLOCK = 3 + 1; // we want to show 3 last transactions, but hide the "Received block reward" one that is usually the last one

interface RecentBlocksProps {
  totalBlocks: number;
}

const RecentBlocks = ({ totalBlocks }: RecentBlocksProps) => {
  const { toast } = useToast();

  const [initLoading, setInitLoading] = useState<boolean>(true);
  const [offset, setOffset] = useState<number>(0);
  const [blocks, setBlocks] = useState<Array<Block>>([]);

  const [fetchBlocksLazy, { loading }] = useLazyQuery(BlockDocument, {
    client,
  });

  const fetchBlocks = async () => {
    const { data } = await fetchBlocksLazy({
      variables: {
        orderBy: TransactionsOrderBy.IndexInBlockAsc,
        transactionsFirst: TRANSACTIONS_IN_BLOCK,
        blocksFirst: ITEMS_PER_PAGE,
        blocksOrderBy: BlocksOrderBy.TimestampDesc,
        transactionsOffset: 0,
        blocksOffset: offset,
      },
    });
    return data?.blocks?.nodes.filter((e) => e !== null) as Array<Block>;
  };

  useEffect(() => {
    const getBlocks = async () => {
      try {
        const blocksList = await fetchBlocks();
        setBlocks(blocksList || []);
      } catch (e: any) {
        toast({
          variant: "destructive",
          title: "Error",
          description: `There was a problem getting recent blocks. ${JSON.stringify(
            e,
          )}`,
        });
      } finally {
        setInitLoading(false);
      }
    };

    getBlocks();
  }, [offset]);

  return (
    <div className="my-12">
      <div className="flex justify-between items-center">
        <h3 className="mb-4 flex items-center">
          <BoxIcon className="mr-2" />
          Recent Blocks
        </h3>
        <Link to="/blocks" className="text-xs hover:underline">
          View All Blocks
        </Link>
      </div>
      {initLoading ? (
        <div className="overflow-hidden rounded-xl">
          <Skeleton className="h-[48px] w-full rounded-none mb-1" />
          <Skeleton className="h-[300px] w-full rounded-none" />
        </div>
      ) : (
        <TableBlocks
          blocks={blocks}
          total={totalBlocks}
          offset={offset}
          perPage={ITEMS_PER_PAGE}
          onPrevPage={() => {
            setOffset((prev) => prev - ITEMS_PER_PAGE);
          }}
          onNextPage={() => {
            setOffset((prev) => prev + ITEMS_PER_PAGE);
          }}
          loadingPage={loading}
        />
      )}
    </div>
  );
};

export default RecentBlocks;
