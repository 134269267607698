import { useParams } from "react-router";
import { useLazyQuery } from "@apollo/client";
import {
  BlockByBlockHashDocument,
  BlockByHeightDocument,
  CoreBlockFieldsFragment,
  Transaction,
  TransactionsDocument,
  TransactionsOrderBy,
} from "../graphql/codegen/graphql";
import { client } from "../graphql/client";
import { useNavigate } from "react-router-dom";
import { Button } from "@/components/ui/button";
import { ArrowLeftCircleIcon, BellIcon, BoxIcon } from "lucide-react";
import { useEffect, useState } from "react";
import CopyToClipboard from "@/components/shared/copy-to-clipboard";
import { Skeleton } from "@/components/ui/skeleton";
import range from "lodash/range";
import { Metric } from "@/components/ui/metric";
import dayjs from "dayjs";
import { isBlockHash, shortenLongWord } from "../utils/helpers";
import TableTransactions from "@/components/shared/table-transactions";
import { useToast } from "@/components/ui/use-toast";
import { useMobile } from "../hooks/use-mobile";
import { formatDecimalValue } from "../utils/currency";
import { MEMPOOL_AGE } from "../utils/constants";
import { useTitle } from "../hooks/use-title";

const SKELETON_NUM_OF_CARDS = 3;
const TRANSACTIONS_PER_PAGE = 50;

const MEMPOOL_BLOCK_ID = "Mempool";

interface BlockDetailProps {
  mempool?: boolean;
}

const BlockDetail = ({ mempool }: BlockDetailProps) => {
  useTitle(mempool ? "Mempool" : "Block Details");

  const { isMobile } = useMobile();
  const { toast } = useToast();
  const navigate = useNavigate();

  const { blockId } = useParams();
  const [initLoading, setInitLoading] = useState<boolean>(true);
  const [offset, setOffset] = useState<number>(0);
  const [transactions, setTransactions] = useState<Array<Transaction>>([]);
  const [totalTransactions, setTotalTransactions] = useState<number>(0);
  const [blockDetails, setBlockDetails] =
    useState<CoreBlockFieldsFragment | null>(null);

  const [loadBlockByHeightLazy, { loading: loadingBlockByHeight }] =
    useLazyQuery(BlockByHeightDocument, {
      client,
    });
  const [loadBlockByHashLazy, { loading: loadingBlockByHash }] = useLazyQuery(
    BlockByBlockHashDocument,
    {
      client,
    },
  );

  const [fetchTransactionsLazy, { loading: loadingTransactions }] =
    useLazyQuery(TransactionsDocument, {
      client,
    });

  const fetchTransactions = async (height: number) => {
    const { data } = await fetchTransactionsLazy({
      variables: {
        first: TRANSACTIONS_PER_PAGE,
        orderBy: [TransactionsOrderBy.IndexInBlockAsc],
        offset,
        filter: {
          blockHeight: {
            equalTo: height,
          },
          indexInBlock: {
            isNull: false,
          },
        },
        withTotal: true,
      },
    });

    setTotalTransactions(data?.transactions?.totalCount || 0);

    return data?.transactions?.nodes.filter(
      (e) => e !== null,
    ) as Array<Transaction>;
  };

  const onError = (e: any) => {
    toast({
      variant: "destructive",
      title: "Error",
      description: `There was a problem getting block and its transactions. ${JSON.stringify(
        e,
      )}`,
    });
  };

  useEffect(() => {
    const getBlock = async () => {
      try {
        if (mempool) {
          setBlockDetails({
            height: MEMPOOL_BLOCK_ID,
            blockHash: MEMPOOL_BLOCK_ID,
            timestamp: MEMPOOL_AGE,
          } as CoreBlockFieldsFragment);

          const response = await fetchTransactions(0);
          setTransactions(response);

          return;
        } else if (!blockId) {
          onError("Please make sure that the URL is correct");
          return;
        }

        const block = isBlockHash(blockId)
          ? await loadBlockByHashLazy({
              variables: { blockHash: blockId },
            }).then((e) => e.data?.blockByBlockHash)
          : await loadBlockByHeightLazy({
              variables: { height: blockId },
            }).then((e) => e.data?.blockByHeight);

        if (!block) {
          onError(
            "Seems like requested block does not exist and cannot be retrieved from the API",
          );
          return;
        }

        setBlockDetails(block);

        const response = await fetchTransactions(block.height);
        setTransactions(response);
      } catch (e: any) {
        onError(e);
      } finally {
        setInitLoading(false);
      }
    };

    getBlock();
  }, [offset]);

  const blockDate = mempool ? null : dayjs(`${blockDetails?.timestamp}Z`);

  return (
    <main className="container-wide">
      {!isMobile && (
        <Button
          variant="outline"
          size="sm"
          className="mb-8 rounded-full"
          onClick={() => navigate(-1)}
        >
          <ArrowLeftCircleIcon className="mr-2 w-5 h-5" />
          Go Back
        </Button>
      )}

      <div className="text-left m-auto mb-6">
        <h1 className="text-2xl text-black dark:text-white font-semibold">
          {loadingBlockByHash || loadingBlockByHeight ? (
            <Skeleton className="h-[32px] w-[150px]" />
          ) : mempool ? (
            "Mempool"
          ) : (
            `Block #${blockDetails?.height}`
          )}
        </h1>
      </div>

      <section className="mb-14">
        <div className="mb-6">
          <div className="flex items-start flex-col lg:flex-row lg:items-center lg:justify-between mb-4">
            <h3 className="flex gap-2 items-center">
              <BellIcon />
              Summary
            </h3>

            <div className="font-mono text-xs mt-6 lg:mt-0">
              {loadingBlockByHash || loadingBlockByHeight || !blockDetails ? (
                <Skeleton className="h-[16px] w-[300px]" />
              ) : (
                <CopyToClipboard text={blockDetails.blockHash || ""}>
                  <span className="text-muted-foreground mr-1">
                    Block Hash:
                  </span>
                  {isMobile
                    ? shortenLongWord(blockDetails.blockHash, 8, 8)
                    : blockDetails.blockHash}
                </CopyToClipboard>
              )}
            </div>
          </div>

          <div className="grid grid-cols-1 lg:grid-cols-3 gap-4">
            {loadingBlockByHash || loadingBlockByHeight || !blockDetails ? (
              range(SKELETON_NUM_OF_CARDS).map((e, i) => (
                <Skeleton className="h-[80px]" key={i} />
              ))
            ) : (
              <>
                <Metric
                  value={
                    mempool
                      ? blockDetails.height
                      : formatDecimalValue(blockDetails.height)
                  }
                  label="Block Height"
                  tooltip="The sequential number assigned to a block in a blockchain"
                />
                <Metric
                  value={shortenLongWord(blockDetails.blockHash, 6, 6)}
                  label="Block Hash"
                  tooltip="A unique cryptographic block identifier"
                />
                <Metric
                  value={
                    blockDate
                      ? blockDate.format("MMM DD, YYYY")
                      : blockDetails.timestamp
                  }
                  caption={
                    blockDate ? `@ ${blockDate.format("hh:mm:ssA")}` : undefined
                  }
                  label="Timestamp"
                  tooltip="Based on your local timezone preferences"
                />
              </>
            )}
          </div>
        </div>
      </section>

      <div className="mb-4">
        <h3 className="flex gap-2 items-center">
          <BoxIcon />
          Block transactions
        </h3>
      </div>

      <div className="mb-12">
        {initLoading ? (
          <div className="overflow-hidden rounded-xl">
            <Skeleton className="h-[48px] w-full rounded-none mb-1" />
            <Skeleton className="h-[300px] w-full rounded-none" />
          </div>
        ) : (
          <TableTransactions
            hideHeightColumn={true}
            transactions={transactions}
            total={totalTransactions}
            loadingPage={loadingTransactions}
            offset={offset}
            perPage={TRANSACTIONS_PER_PAGE}
            onPrevPage={() => {
              setOffset((prev) => prev - TRANSACTIONS_PER_PAGE);
            }}
            onNextPage={() => {
              setOffset((prev) => prev + TRANSACTIONS_PER_PAGE);
            }}
          />
        )}
      </div>
    </main>
  );
};

export default BlockDetail;
