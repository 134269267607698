import { LeaderboardTable } from "@/components/home/leaderboard-table";
import { CandlestickChartIcon, PaintBucketIcon, SmileIcon } from "lucide-react";
import { useQuery } from "@apollo/client";
import {
  DefiLeaderboardStatsOrderBy,
  NftLeaderboardStatsDocument,
  NftLeaderboardStatsOrderBy,
  SocialLeaderboardStatsDocument,
  SocialLeaderboardStatsOrderBy,
  StatisticDefiLeaderboardsDocument,
} from "../../graphql/codegen/graphql";
import { client } from "../../graphql/client";
import { Skeleton } from "@/components/ui/skeleton";
import * as React from "react";
import { desoNanosToDeso } from "../../utils/currency";
import { InfoTooltip } from "@/components/shared/info-tooltip";
import { parseLeaderboardUser } from "../../utils/helpers";

const Leaderboards = () => {
  // TODO: handle error
  const { loading: loadingSocialLeaderboard, data: socialStats } = useQuery(
    SocialLeaderboardStatsDocument,
    {
      client,
      variables: {
        orderBy: SocialLeaderboardStatsOrderBy.CountDesc,
      },
    },
  );
  const topCreatorsSocial = (
    socialStats?.socialLeaderboardStats?.nodes || []
  ).map((e) => parseLeaderboardUser(e, e?.publicKey, e?.username, e?.count));

  // TODO: handle error
  const { loading: loadingNftLeaderboard, data: nftStats } = useQuery(
    NftLeaderboardStatsDocument,
    {
      client,
      variables: {
        orderBy: NftLeaderboardStatsOrderBy.SumDesc,
      },
    },
  );
  const topCreatorsNFT = (nftStats?.nftLeaderboardStats?.nodes || []).map((e) =>
    parseLeaderboardUser(
      e,
      e?.publicKey,
      e?.username,
      desoNanosToDeso(e?.sum).toString(),
    ),
  );

  // TODO: handle error
  const { loading: loadingDeFiLeaderboard, data: deFiStats } = useQuery(
    StatisticDefiLeaderboardsDocument,
    {
      client,
      variables: {
        orderBy: DefiLeaderboardStatsOrderBy.NetQuantityDesc,
      },
    },
  );

  const topCreatorsDeFi = (deFiStats?.defiLeaderboardStats?.nodes || []).map(
    (e) => {
      return parseLeaderboardUser(
        e,
        e?.publicKey,
        e?.username,
        Math.round(
          desoNanosToDeso(Math.abs(Math.round(e?.netQuantity))),
        ).toString(),
      );
    },
  );

  return (
    <div className="my-12 m-auto">
      <div className="grid grid-cols-1 lg:grid-cols-3 gap-4">
        <div className="flex flex-col w-full">
          <SmileIcon className="m-auto mb-2" />
          <div className="text-center mb-3 text-lg text-muted-foreground font-medium">
            Social Leaderboard
          </div>

          {loadingSocialLeaderboard ? (
            <Skeleton className="w-full h-[400px] rounded-2xl" />
          ) : (
            <LeaderboardTable
              title="Top Creators (Last 30d)"
              username="Username"
              metric="Engagements"
              tooltip="Measured by on-chain social activity: Posts × Likes × Comments × Diamonds"
              noDataLabel="No creators found."
              topUsers={topCreatorsSocial}
            />
          )}
        </div>
        <div className="flex flex-col w-full">
          <PaintBucketIcon className="m-auto mb-2" />
          <div className="text-center mb-3 text-lg text-muted-foreground font-medium">
            NFT Leaderboard
          </div>

          {loadingNftLeaderboard ? (
            <Skeleton className="w-full h-[400px] rounded-2xl" />
          ) : (
            <>
              <LeaderboardTable
                title="Top NFTs (All Time)"
                username="Creator"
                metric={
                  <div className="flex items-center justify-end h-4">
                    <span className="mr-1">NFT Volume</span>
                    <InfoTooltip text={"Measured in $DESO"} iconSize={16} />
                  </div>
                }
                tooltip="Measured by sales from on-chain NFT auctions"
                noDataLabel="No creators found."
                topUsers={topCreatorsNFT}
                minDecimal={2}
                maxDecimal={2}
                currency="DESO"
              />
            </>
          )}
        </div>
        <div className="flex flex-col w-full">
          <CandlestickChartIcon className="m-auto mb-2" />
          <div className="text-center mb-3 text-lg text-muted-foreground font-medium">
            DeFi Leaderboard
          </div>

          {loadingDeFiLeaderboard ? (
            <Skeleton className="w-full h-[400px] rounded-2xl" />
          ) : (
            <>
              <LeaderboardTable
                title="Top Tokens (All Time)"
                username="Token"
                metric={
                  <div className="flex items-center justify-end h-4">
                    <span className="mr-1">Order Volume</span>
                    <InfoTooltip text={"Measured in $DESO"} iconSize={16} />
                  </div>
                }
                tooltip="Measured by on-chain market & limit order volume"
                noDataLabel="No creators found."
                topUsers={topCreatorsDeFi}
                minDecimal={2}
                maxDecimal={2}
                currency="DESO"
              />
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default Leaderboards;
