import { ApolloClient, ApolloLink, InMemoryCache } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { createHttpLink } from "@apollo/client/link/http";
import { identity } from "deso-protocol";

import { GRAPHQL_URI } from "../utils/constants";

const authLink = setContext(async (_, ctx) => {
  try {
    const { currentUser } = await identity.snapshot();
    if (!currentUser) return ctx;

    const token = await identity.jwt();

    return {
      ...ctx,
      headers: {
        ...ctx.headers,
        Authorization: `Bearer ${token}`,
      },
    };
  } catch (e) {
    return ctx;
  }
});

const httpLink = createHttpLink({
  uri: GRAPHQL_URI,
});

export const client = new ApolloClient({
  link: ApolloLink.from([authLink, httpLink]),
  cache: new InMemoryCache({
    typePolicies: {
      Account: {
        keyFields: ["publicKey"],
      },
      Block: {
        keyFields: ["blockHash"],
      },
      Transaction: {
        keyFields: ["transactionHash"],
      },
      ProfileTransactionStat: {
        keyFields: ["publicKey"],
      },
      DesoBalance: {
        keyFields: ["publicKey"],
      },
      ProfileEarningsBreakdownStat: {
        keyFields: ["publicKey"],
      },
      ProfileEarningsStat: {
        keyFields: ["publicKey"],
      },
      ProfilePortfolioValueStat: {
        keyFields: ["publicKey"],
      },
      ValidatorEntry: {
        keyFields: ["validatorPkid"],
      },
    },
  }),
});
