import axios from "axios";
import { NODE_URI } from "../utils/constants";

import type { EpochProgressResponse, ValidatorEntry } from "./types";

const baseURL = `${NODE_URI}/api/v0`;

const instance = axios.create({
  baseURL,
  headers: {
    "Content-Type": "application/json",
  },
});

export const getTotalSupply = (): Promise<number> => {
  return instance.get("total-supply").then((res) => res.data as number);
};

export const getCurrentEpochProgress = (): Promise<EpochProgressResponse> => {
  return instance
    .get("current-epoch-progress")
    .then((res) => res.data as EpochProgressResponse);
};

export const getValidatorByPublicKeyBase58Check = (
  publicKeyBase58Check: string,
): Promise<ValidatorEntry> => {
  return instance
    .get(`validators/${publicKeyBase58Check}`)
    .then((res) => res.data as ValidatorEntry);
};
