import { Button, buttonVariants } from "@/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { useLazyQuery, useQuery } from "@apollo/client";
import { StakeRewardMethod, stake, unstake } from "deso-protocol";
import { useEffect, useState } from "react";
import { client } from "../../graphql/client";
import {
  GetDesoBalanceByPublicKeyDocument,
  GetStakeByStakerPublicKeyAndValidatorPublicKeyDocument,
  GetValidatorByPublicKeyDocument,
  StakeFragment,
} from "../../graphql/codegen/graphql";
import { desoNanosToDeso, formatDecimalValue } from "../../utils/currency";
import {
  formatDisplayName,
  handleIdentityClosedWithoutInteraction,
  pollForTxnFinalized,
} from "../../utils/helpers";
import Spinner from "../shared/spinner";
import { Input } from "../ui/input";
import { Label } from "../ui/label";
import { Switch } from "../ui/switch";
import { useToast } from "../ui/use-toast";
import DesoAvatar from "../shared/deso-avatar";

interface StakingModalProps {
  validatorPublicKey: string;
  operation: "stake" | "unstake";
  transactorPublicKey?: string;
  onTransactionFinalized?: () => Promise<any>;
  buttonStyle?: string;
}

const StakingModal: React.FC<StakingModalProps> = ({
  validatorPublicKey,
  transactorPublicKey,
  operation,
  onTransactionFinalized,
  buttonStyle = "default",
}) => {
  const { data: validator, loading: loadingValidator } = useQuery(
    GetValidatorByPublicKeyDocument,
    {
      client,
      variables: {
        publicKey: validatorPublicKey,
        viewerPublicKey: transactorPublicKey ?? "",
      },
    },
  );
  const [
    fetchExistingStake,
    {
      data: viewerStakeData,
      loading: loadingViewerStake,
      refetch: refetchViewerStake,
    },
  ] = useLazyQuery(GetStakeByStakerPublicKeyAndValidatorPublicKeyDocument, {
    client,
  });

  const [desoAmount, setDesoAmount] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [isPendingTransaction, setIsPendingTransaction] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [stakeRewardMethod, setStakeRewardMethod] = useState<StakeRewardMethod>(
    StakeRewardMethod.Restake,
  );
  const [amountInputError, setAmountInputError] = useState<string | null>(null);
  const [viewerStake, setViewerStake] = useState<StakeFragment | null>(null);
  const [
    getDesoBalance,
    {
      data: availableBalance,
      loading: loadingAvailableBalance,
      refetch: refetchAvailableBalance,
    },
  ] = useLazyQuery(GetDesoBalanceByPublicKeyDocument, {
    client,
  });
  const { toast } = useToast();

  useEffect(() => {
    if (!transactorPublicKey) return;

    fetchExistingStake({
      variables: {
        stakerPublicKey: transactorPublicKey,
        validatorPublicKey,
      },
    });
  }, [transactorPublicKey, validatorPublicKey, fetchExistingStake]);

  useEffect(() => {
    setViewerStake(viewerStakeData?.stakeEntries?.nodes?.[0] ?? null);
  }, [viewerStakeData]);

  useEffect(() => {
    if (!transactorPublicKey) return;
    getDesoBalance({
      variables: {
        publicKey: transactorPublicKey,
      },
    });
  }, [transactorPublicKey]);

  useEffect(() => {
    if (viewerStake?.rewardMethod === StakeRewardMethod.PayToBalance) {
      setStakeRewardMethod(StakeRewardMethod.PayToBalance);
      return;
    }

    // If the viewer has no stake, or the reward method is not set, default to restake.
    if (
      !viewerStake?.rewardMethod ||
      viewerStake?.rewardMethod === StakeRewardMethod.Restake
    ) {
      setStakeRewardMethod(StakeRewardMethod.Restake);
      return;
    }
  }, [viewerStake?.rewardMethod]);

  useEffect(() => {
    // if the modal is closed, reset the form
    if (!isOpen) {
      setDesoAmount("");
      setAmountInputError(null);
    }
  }, [isOpen]);

  if (loadingValidator || loadingViewerStake) {
    return <Spinner />;
  }

  if (!validator?.accountByPublicKey?.publicKey) {
    return (
      <div className="text-red-500">
        Error: cannot stake or unstake without a validator account public key.
      </div>
    );
  }

  return (
    <Dialog
      open={isOpen}
      onOpenChange={(open) => {
        if (isPendingTransaction) return;
        setIsOpen(open);
      }}
    >
      <DialogTrigger asChild>
        <Button
          disabled={!transactorPublicKey}
          className={buttonVariants({
            variant: buttonStyle as
              | "default"
              | "link"
              | "destructive"
              | "outline"
              | "secondary"
              | "ghost",
          })}
          onClick={() => setIsOpen(true)}
        >
          {operation === "stake" ? "Stake" : "Unstake"}
        </Button>
      </DialogTrigger>

      <DialogContent className="max-w-[600px] p-0">
        <DialogHeader className="border-b md:p-4 md:pb-2 pb-2">
          <DialogTitle className="flex flex-col md:flex-row items-center gap-2">
            {operation === "stake" ? "Stake" : "Unstake"} $DESO with{" "}
            <div className="flex items-center gap-2">
              <DesoAvatar
                publicKey={validator?.accountByPublicKey?.publicKey}
                size={32}
              />
              {formatDisplayName(
                validator?.accountByPublicKey?.username,
                validator?.accountByPublicKey?.publicKey,
              )}
            </div>
          </DialogTitle>
        </DialogHeader>

        <div className="flex flex-col-reverse md:flex-row items-center justify-between gap-2 p-4 border-b border-border-light pt-0">
          <div className="flex flex-col items-center pt-4 md:pt-0 justify-center md:items-start gap-0">
            <div className="flex justify-center items-center w-full flex-col md:flex-row gap-4">
              <Label htmlFor="stakingAmount" className="leading-5">
                Your Current Stake ($DESO)
              </Label>
            </div>
            <p className="text-green-500 font-semibold text-2xl">
              {formatDecimalValue(
                desoNanosToDeso(viewerStake?.stakeAmountNanos ?? 0),
                9,
              )}
            </p>
          </div>
          <p className="text-xs p-2 rounded-xl text-center md:text-right bg-card px-4 w-full md:w-auto">
            Your Available Balance: <br />
            <div className="text-muted-foreground font-semibold text-base">
              {loadingAvailableBalance ? (
                <Spinner />
              ) : (
                formatDecimalValue(
                  desoNanosToDeso(
                    availableBalance?.accountByPublicKey?.desoBalance
                      ?.balanceNanos ?? 0,
                  ),
                ) ?? 0
              )}{" "}
              $DESO
            </div>
          </p>
        </div>

        <form
          onSubmit={async (e) => {
            e.preventDefault();
            if (isPendingTransaction) {
              return;
            }

            if (!validator?.accountByPublicKey?.publicKey) {
              toast({
                variant: "destructive",
                title: "Error",
                description:
                  "Cannot stake without a validator account public key.",
              });
              console.error(
                "Cannot stake without a validator account public key.",
              );
              return;
            }

            if (!transactorPublicKey) {
              toast({
                variant: "destructive",
                title: "Error",
                description: "Cannot stake without a logged-in account.",
              });
              console.error("Cannot stake without a logged-in public key.");
              return;
            }

            let response;

            try {
              switch (operation) {
                case "stake":
                  const existingStakeAmount =
                    viewerStake?.stakeAmountNanos ?? 0;
                  if (Number(desoAmount) <= 0 && existingStakeAmount <= 0) {
                    throw new Error("Stake amount must be greater than zero.");
                  }
                  response = await stake(
                    {
                      TransactorPublicKeyBase58Check: transactorPublicKey,
                      ValidatorPublicKeyBase58Check:
                        validator.accountByPublicKey?.publicKey,
                      RewardMethod: stakeRewardMethod,
                      StakeAmountNanos: `0x${(
                        Number(desoAmount) * 1e9
                      ).toString(16)}`,
                      MinFeeRateNanosPerKB: 1000, // TODO: deso-js should handle this better.
                    },
                    {
                      // TODO: Use local construction when it works properly with a zero stake amount passed in.
                      localConstruction: false,
                    },
                  );
                  break;
                case "unstake":
                  if (Number(desoAmount) <= 0) {
                    throw new Error(
                      "Unstake amount must be greater than zero.",
                    );
                  }

                  response = await unstake(
                    {
                      TransactorPublicKeyBase58Check: transactorPublicKey,
                      ValidatorPublicKeyBase58Check:
                        validator?.accountByPublicKey?.publicKey,
                      UnstakeAmountNanos: `0x${(
                        Number(desoAmount) * 1e9
                      ).toString(16)}`,
                      MinFeeRateNanosPerKB: 1000, // TODO: deso-js should handle this better.
                    },
                    {
                      localConstruction: true,
                    },
                  );
                  break;
              }

              setIsPendingTransaction(true);

              if (!response?.submittedTransactionResponse?.TxnHashHex) {
                throw new Error("Transaction hash not found.");
              }

              await pollForTxnFinalized(
                response.submittedTransactionResponse.TxnHashHex,
              );

              if (typeof onTransactionFinalized === "function") {
                await onTransactionFinalized();
              }

              // clear the form
              setDesoAmount("");
              setShowSuccess(true);

              await Promise.all([
                refetchViewerStake(),
                refetchAvailableBalance(),
              ]);

              setTimeout(() => {
                setShowSuccess(false);
              }, 3000);
            } catch (e: any) {
              handleIdentityClosedWithoutInteraction(
                e,
                `There was a problem ${
                  operation === "stake" ? "staking" : "unstaking"
                }: ${e.message}`,
              );
            }

            setIsPendingTransaction(false);
          }}
        >
          <div className="flex flex-col items-start gap-2">
            <div className="border-b border-border-light w-full flex items-center justify-between gap-4 p-4 pt-0">
              <div className="flex flex-col gap-1">
                <span className="text-muted-foreground">
                  {operation === "stake" ? "Add Stake" : "Remove Stake"}
                </span>
                {amountInputError && (
                  <p className="text-sm text-red-500">{amountInputError}</p>
                )}
                {!amountInputError &&
                  Number(desoAmount) > 0 &&
                  desoNanosToDeso(viewerStake?.stakeAmountNanos ?? 0) +
                    Number(desoAmount) >
                    0 && (
                    <p className="text-xs text-muted">
                      This will update your stake to{" "}
                      <span className="font-semibold text-muted-foreground">
                        {formatDecimalValue(
                          desoNanosToDeso(viewerStake?.stakeAmountNanos ?? 0) +
                            Number(desoAmount) *
                              (operation === "stake" ? 1 : -1),
                        )}
                      </span>
                    </p>
                  )}
              </div>
              <div className="flex items-center gap-4">
                <Input
                  type="number"
                  min="0"
                  step="any"
                  placeholder="0.00"
                  className="w-[120px]"
                  value={desoAmount}
                  autoFocus={true}
                  onInput={(e) => {
                    const input = e.target as HTMLInputElement;
                    setDesoAmount(input.value);
                    if (
                      operation === "stake" &&
                      Number(input.value) * 1e9 >
                        (availableBalance?.accountByPublicKey?.desoBalance
                          ?.balanceNanos ?? 0)
                    ) {
                      setAmountInputError("Insufficient balance");
                    } else if (
                      operation === "unstake" &&
                      Number(input.value) * 1e9 >
                        (viewerStake?.stakeAmountNanos ?? 0)
                    ) {
                      setAmountInputError("Exceeds your staked amount.");
                    } else {
                      setAmountInputError(null);
                    }
                  }}
                />
                <img
                  src="/assets/img/logo-deso-mark.svg"
                  alt="DESO"
                  className="h-[22px] inline-block"
                />
                <Button
                  variant="secondary"
                  onClick={() => {
                    if (operation === "stake") {
                      if (
                        availableBalance?.accountByPublicKey?.desoBalance
                          ?.balanceNanos
                      ) {
                        const nanosBalance =
                          availableBalance?.accountByPublicKey?.desoBalance
                            ?.balanceNanos ?? 0;
                        const nanosBuffer = 1e5; // We leave enough to cover fees for unstaking as well.
                        setDesoAmount(
                          desoNanosToDeso(
                            nanosBalance > nanosBuffer
                              ? nanosBalance - nanosBuffer
                              : 0,
                          ).toString(),
                        );
                      }
                    }
                    if (operation === "unstake") {
                      setDesoAmount(
                        desoNanosToDeso(
                          viewerStake?.stakeAmountNanos ?? 0,
                        ).toString(),
                      );
                    }

                    setAmountInputError(null);
                  }}
                >
                  Max
                </Button>
              </div>
            </div>
          </div>
          {operation === "stake" && (
            <div className="w-full flex items-start justify-between gap-4 p-4">
              <div className="flex flex-col gap-1">
                <span
                  className={`text-muted${
                    stakeRewardMethod === StakeRewardMethod.Restake
                      ? "-foreground"
                      : ""
                  }`}
                >
                  Auto-Restake
                </span>
                <p className="text-xs text-muted">
                  Automatically restake &amp; compound rewards with this
                  validator.
                </p>
              </div>
              <div className="flex items-center gap-4">
                <Switch
                  id="enable-restake"
                  checked={stakeRewardMethod === StakeRewardMethod.Restake}
                  onCheckedChange={(checked) => {
                    setStakeRewardMethod(
                      checked
                        ? StakeRewardMethod.Restake
                        : StakeRewardMethod.PayToBalance,
                    );
                  }}
                />
                <p className="text-muted-foreground">
                  {stakeRewardMethod === StakeRewardMethod.Restake
                    ? "On"
                    : "Off"}
                </p>
              </div>
            </div>
          )}
          <div className="border-t border-border-light p-4">
            <div className="flex items-center gap-2">
              <Button
                variant="default"
                type="submit"
                disabled={!!amountInputError || isPendingTransaction}
                className="min-w-[120px]"
              >
                {isPendingTransaction ? <Spinner /> : "Update Stake"}
              </Button>
              <Button
                variant="ghost"
                type="button"
                onClick={() => {
                  if (isPendingTransaction) return;
                  setIsOpen(false);
                }}
              >
                Cancel
              </Button>
              {showSuccess && (
                <p className="text-sm text-green-500">
                  Stake updated successfully!
                </p>
              )}
            </div>
          </div>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default StakingModal;
