import { Skeleton } from "@/components/ui/skeleton";
import { Badge } from "@/components/ui/badge";
import { desoNanosToUSD, formatUSD } from "../../utils/currency";
import * as React from "react";
import { useContext } from "react";
import { MarketDataContext } from "../../contexts/market-data";

interface UserCoinPriceProps {
  coinPriceNanos: string;
  loading: boolean;
  badgeClassName?: string;
}

const UserCoinPrice = ({
  coinPriceNanos,
  loading,
  badgeClassName = "text-sm",
}: UserCoinPriceProps) => {
  const { exchangeRate, loading: loadingMarketData } =
    useContext(MarketDataContext);

  return loadingMarketData || !exchangeRate || loading ? (
    <Skeleton className="h-[16px] w-[70px] inline-block ml-2" />
  ) : (
    <>
      <span className="mx-2 inline-block">≈</span>
      <Badge variant="default" className={badgeClassName}>
        {formatUSD(desoNanosToUSD(coinPriceNanos || 0, exchangeRate))}
      </Badge>
    </>
  );
};

export default UserCoinPrice;
