import TableStakers from "@/components/shared/table-stakers";
import { Metric } from "@/components/ui/metric";
import ValidatorCard from "@/components/userDetail/validator-card";
import { useLazyQuery } from "@apollo/client";
import { FlagIcon, LucideBadgeDollarSign, User2Icon } from "lucide-react";
import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { NotFound } from "../components/NotFound";
import Spinner from "../components/shared/spinner";
import { ActiveAccountContext } from "../contexts/active-account";
import { client } from "../graphql/client";
import {
  GetValidatorByPublicKeyDocument,
  GetValidatorByUsernameDocument,
  StakeEntriesByValidatorPkidDocument,
  StakerFragment,
} from "../graphql/codegen/graphql";
import {
  basisPointsToPercent,
  desoNanosToDeso,
  formatDecimalValue,
} from "../utils/currency";
import { isDesoPublicKey } from "../utils/helpers";

const PAGINATION_LIMIT = 100;

const ValidatorDetailPage = () => {
  const { account } = useContext(ActiveAccountContext);
  const [
    getValidatorByUsername,
    {
      data: validatorByUsernameData,
      loading: loadingByUsername,
      error: errorByUsername,
      refetch: refetchValidatorByUsername,
    },
  ] = useLazyQuery(GetValidatorByUsernameDocument, { client });
  const [
    getValidatorByPublicKey,
    {
      data: validatorByPublicKeyData,
      loading: loadingByPublicKey,
      error: errorByPublicKey,
      refetch: refetchValidatorByPublicKey,
    },
  ] = useLazyQuery(GetValidatorByPublicKeyDocument, { client });
  const [getStakers, { data: stakersData, loading: stakersLoading }] =
    useLazyQuery(StakeEntriesByValidatorPkidDocument, { client });
  const [stakersListOffset, setStakersListOffset] = useState(0);

  const loading = loadingByUsername || loadingByPublicKey;
  const validator =
    validatorByUsernameData?.accountByUsername?.validatorEntry ||
    validatorByPublicKeyData?.accountByPublicKey?.validatorEntry;
  const { publicKeyOrUsername } = useParams();
  const viewerStakeRewardNanos =
    validator?.viewerStakeRewardsSum?.nodes[0]?.rewardNanos ?? 0;

  useEffect(() => {
    if (!publicKeyOrUsername) {
      // NOTE: this case will cause the generic NotFound component to be rendered.
      return;
    }

    if (isDesoPublicKey(publicKeyOrUsername)) {
      getValidatorByPublicKey({
        variables: {
          publicKey: publicKeyOrUsername,
          viewerPublicKey: account?.publicKey ?? "",
        },
      });
    } else {
      getValidatorByUsername({
        variables: {
          username: publicKeyOrUsername,
          viewerPublicKey: account?.publicKey ?? "",
        },
      });
    }
  }, [publicKeyOrUsername, account?.publicKey]);

  useEffect(() => {
    if (!validator) {
      // This case will cause the generic NotFound component to be rendered or an error screen if
      // there was an error.
      return;
    }

    getStakers({
      variables: {
        validatorPkid: validator.validatorPkid,
        first: PAGINATION_LIMIT,
        after: null,
        before: null,
        last: null,
      },
    });
  }, [validator]);

  // TODO: @mosster to make loading, not found, and error states look better.
  if (loading) {
    return <Spinner />;
  }

  if (errorByUsername || errorByPublicKey) {
    return <div>Unable to render!</div>;
  }

  if (!validator) {
    return <NotFound />;
  }

  return (
    <main className="mt-4 container m-auto">
      <div className="w-full justify-start flex items-center">
        <h1 className="text-2xl text-black dark:text-white font-semibold">
          Validator Detail
        </h1>
      </div>
      <div className="mt-8">
        <ValidatorCard
          validator={validator}
          rank={validator.validatorStats?.validatorRank ?? 0}
          reloadValidator={() => {
            if (!publicKeyOrUsername) {
              throw new Error(
                "Public key or username is missing from route params.",
              );
            }

            if (isDesoPublicKey(publicKeyOrUsername)) {
              return refetchValidatorByPublicKey({
                publicKey: publicKeyOrUsername,
                viewerPublicKey: account?.publicKey ?? "",
              });
            } else {
              return refetchValidatorByUsername({
                username: publicKeyOrUsername,
                viewerPublicKey: account?.publicKey ?? "",
              });
            }
          }}
        />
        {Number(validator?.totalStakeAmountNanos ?? 0) > 0 && (
          <>
            <div className="flex justify-between items-center">
              <h3 className="mb-4 flex items-center">
                <FlagIcon className="mr-2" />
                Validator Overview
              </h3>
            </div>
            <div className="grid align-center grid-cols-1 md:grid-cols-2 xl:grid-cols-4 w-full gap-4">
              <div className="flex flex-col gap-2">
                <Metric
                  value={formatDecimalValue(
                    desoNanosToDeso(validator?.totalStakeAmountNanos ?? 0),
                  )}
                  caption="DESO"
                  label="Total Staked"
                />
              </div>
              <div className="flex flex-col gap-2">
                <Metric
                  value={Number(
                    validator.validatorStats?.percentTotalStake ?? 0,
                  ).toLocaleString("en-US", {
                    style: "percent",
                    maximumFractionDigits: 2,
                  })}
                  label="Stake Percentage"
                />
              </div>
              <div className="flex flex-col gap-2">
                <Metric
                  value={`${basisPointsToPercent(
                    validator.delegatedStakeCommissionBasisPoints,
                  )}%`}
                  label="Commission Fee %"
                />
              </div>
              <div className="flex flex-col gap-2">
                <Metric
                  value={validator?.stakeEntries?.totalCount}
                  label="Stakers"
                />
              </div>
            </div>
          </>
        )}
      </div>

      {!!validator?.viewerStake?.nodes?.[0] && (
        <div className="mt-8 pb-8 border-b border-border-light">
          <div className="flex justify-between items-center">
            <h3 className="mb-4 flex items-center">
              <User2Icon className="mr-2" />
              My Stake Entries
            </h3>
          </div>
          <div className="grid align-center grid-cols-1 md:grid-cols-2 w-full gap-4">
            <div className="flex flex-col gap-2">
              <Metric
                value={formatDecimalValue(
                  desoNanosToDeso(
                    validator?.viewerStake?.nodes?.[0]?.stakeAmountNanos ?? 0,
                  ),
                )}
                caption="DESO"
                label="My Staked Balance"
              />
            </div>
            <div className="flex flex-col gap-2">
              <Metric
                value={formatDecimalValue(
                  desoNanosToDeso(viewerStakeRewardNanos),
                  9,
                )}
                label="My Staking Rewards"
                caption="DESO"
              />
            </div>
          </div>
        </div>
      )}

      {!!stakersData?.stakeEntries?.nodes?.length ? (
        <>
          <div className="flex justify-between items-center pt-6">
            <h3 className="mb-4 flex items-center">
              <LucideBadgeDollarSign className="mr-2" />
              Stakers
            </h3>
          </div>
          <div>
            <TableStakers
              stakers={stakersData?.stakeEntries?.nodes as StakerFragment[]}
              validator={validator}
              total={stakersData.stakeEntries.totalCount}
              offset={stakersListOffset}
              perPage={PAGINATION_LIMIT}
              loadingPage={stakersLoading}
              onPrevPage={async () => {
                await getStakers({
                  variables: {
                    validatorPkid: validator.validatorPkid,
                    first: null,
                    after: null,
                    before:
                      stakersData?.stakeEntries?.pageInfo?.startCursor ?? null,
                    last: PAGINATION_LIMIT,
                  },
                });
                setStakersListOffset((prev) => prev - PAGINATION_LIMIT);
              }}
              onNextPage={async () => {
                await getStakers({
                  variables: {
                    validatorPkid: validator.validatorPkid,
                    first: PAGINATION_LIMIT,
                    after:
                      stakersData?.stakeEntries?.pageInfo?.endCursor ?? null,
                    before: null,
                    last: null,
                  },
                });
                setStakersListOffset((prev) => prev + PAGINATION_LIMIT);
              }}
            />
          </div>
        </>
      ) : (
        <div className="text-center border rounded-xl mt-4 py-10">
          This validator doesn't have any stakers yet. Be the first to stake
          with them! Their commission rate is{" "}
          {basisPointsToPercent(
            validator.delegatedStakeCommissionBasisPoints,
          ).toLocaleString("en-US")}
          %
        </div>
      )}
    </main>
  );
};

export default ValidatorDetailPage;
