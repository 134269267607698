import * as React from "react";
import { InfoTooltip } from "@/components/shared/info-tooltip";
import { cva } from "class-variance-authority";
import { cn } from "@/lib";

const metricVariants = cva("rounded-xl relative cursor-auto", {
  variants: {
    state: {
      active:
        "bg-card-foreground cursor-auto border border-primary dark:border-transparent border-solid md:hover:border md:hover:border-border",
      inActive:
        "bg-card border border-transparent border-solid md:hover:bg-background md:hover:border md:hover:border-border cursor-pointer",
      none: "bg-card border border-transparent border-solid md:hover:bg-background md:hover:border md:hover:border-border cursor-auto",
    },
  },
  defaultVariants: {
    state: "none",
  },
});

interface MetricProps {
  state?: "active" | "inActive" | "none";
  className?: string;
  value: string | number;
  label: string;
  caption?: string;
  icon?: JSX.Element;
  tooltip?: string;
  onClick?: (v: string | number) => void;
  footer?: JSX.Element;
}

export const Metric = ({
  state,
  className,
  value,
  label,
  caption,
  icon,
  tooltip,
  onClick,
  footer,
  ...props
}: MetricProps) => {
  return (
    <div
      className={cn(metricVariants({ state, className }))}
      {...props}
      onClick={() => onClick?.(value)}
    >
      <div className="p-4 pb-3">
        {tooltip && (
          <div className="absolute top-[10px] right-[10px]">
            <InfoTooltip text={tooltip} />
          </div>
        )}
        {icon && <div className="mb-3 flex items-end w-full">{icon}</div>}
        <p className="text-xs mb-1">{label}</p>
        <div className="text-xl md:text-2xl text-muted-foreground mb-0">
          {value}
          {caption && (
            <span className="text-xs inline-block ml-2 dark:text-muted">
              {caption}
            </span>
          )}
        </div>

        <footer>{footer}</footer>
      </div>
    </div>
  );
};
