import { configure } from "deso-protocol";
import React from "react";
import ReactDOM from "react-dom/client";
import { RouterProvider } from "react-router-dom";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import { router } from "./router";
import { DESO_NETWORK, IDENTITY_URI, NODE_URI } from "./utils/constants";

configure({
  nodeURI: NODE_URI,
  network: DESO_NETWORK,
  identityURI: IDENTITY_URI,
  spendingLimitOptions: {
    GlobalDESOLimit: 1e7,
    TransactionCountLimitMap: {
      REGISTER_AS_VALIDATOR: "UNLIMITED",
      UNREGISTER_AS_VALIDATOR: "UNLIMITED",
      UNJAIL_VALIDATOR: "UNLIMITED",
    },
  },
});

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement,
);
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
