import { LeaderboardTable } from "@/components/home/leaderboard-table";
import {
  CandlestickChartIcon,
  PaintBucketIcon,
  Repeat2,
  SmileIcon,
  Wallet2,
} from "lucide-react";
import { useQuery } from "@apollo/client";
import {
  AccountDetailsFragment,
  ProfileNftTopOwner,
  ProfileNftTopOwnersDocument,
  ProfileNftTopOwnersOrderBy,
  ProfileTopCcHoldersDocument,
  ProfileTopDaoHoldersDocument,
  TokenBalanceFieldsFragment,
  TokenBalancesOrderBy,
} from "../../graphql/codegen/graphql";
import { client } from "../../graphql/client";
import { Skeleton } from "@/components/ui/skeleton";
import * as React from "react";
import {
  baseUnitsToTokens,
  desoNanosToDeso,
  desoNanosToUSD,
} from "src/utils/currency";
import { useContext } from "react";
import { MarketDataContext } from "../../contexts/market-data";
import { abbreviateNumber } from "../../utils/helpers";
import { LeaderboardUser } from "../../types";
import { InfoTooltip } from "@/components/shared/info-tooltip";

function parseLeaderboardUser<T>(
  rawValue: T,
  publicKey?: string | null,
  username?: string | null,
  value?: string | null,
): LeaderboardUser<T> {
  return {
    publicKey: publicKey || "",
    username: username || "",
    value: value || "",
    rawValue,
  };
}

interface HolderLeaderboardsProps {
  user: AccountDetailsFragment;
}

const HolderLeaderboards = ({ user }: HolderLeaderboardsProps) => {
  const { exchangeRate, loading: loadingMarketData } =
    useContext(MarketDataContext);

  // TODO: handle error
  const { loading: loadingCCHoldersLeaderboard, data: ccHoldersStats } =
    useQuery(ProfileTopCcHoldersDocument, {
      client,
      variables: {
        username: user.username || "",
        filter: {
          isDaoCoin: {
            equalTo: false,
          },
        },
        first: 10,
        orderBy: TokenBalancesOrderBy.BalanceNanosDesc,
      },
    });

  const topCCHoldersResponse = (
    ccHoldersStats?.accountByUsername?.tokenBalancesAsCreator.nodes || []
  ).filter(
    (e: TokenBalanceFieldsFragment | null) => e !== null,
  ) as Array<TokenBalanceFieldsFragment>;
  const coinPriceNanos =
    ccHoldersStats?.accountByUsername?.coinPriceDesoNanos || 0;

  const topCCHolders = topCCHoldersResponse.map((e) =>
    parseLeaderboardUser(
      e,
      e?.holder?.publicKey,
      e?.holder?.username,
      desoNanosToDeso(e?.balanceNanos as number).toString(),
    ),
  );

  // TODO: handle error
  const { loading: loadingNFTOwnersLeaderboard, data: nftOwnersStats } =
    useQuery(ProfileNftTopOwnersDocument, {
      client,
      variables: {
        orderBy: ProfileNftTopOwnersOrderBy.CountDesc,
        filter: {
          creatorPublicKey: {
            equalTo: user.publicKey,
          },
        },
        first: 20,
        publicKey: user.publicKey,
        postsFilter2: {
          isNft: {
            equalTo: true,
          },
        },
      },
    });

  const topOwnersResponse = (
    nftOwnersStats?.profileNftTopOwners?.nodes || []
  ).filter((e?: ProfileNftTopOwner | null) => !!e) as Array<ProfileNftTopOwner>;

  const topNFTOwners = topOwnersResponse.map((e: ProfileNftTopOwner) =>
    parseLeaderboardUser(e, e.publicKey, e.username, e.count),
  );

  // TODO: handle error
  const { loading: loadingDAOHoldersLeaderboard, data: daoHoldersStats } =
    useQuery(ProfileTopDaoHoldersDocument, {
      client,
      variables: {
        username: user.username || "",
        filter: {
          isDaoCoin: {
            equalTo: true,
          },
        },
        first: 20,
        orderBy: TokenBalancesOrderBy.BalanceNanosDesc,
      },
    });

  const topDAOOwnersResponse = (
    daoHoldersStats?.accountByUsername?.tokenBalancesAsCreator?.nodes || []
  ).filter((e?: TokenBalanceFieldsFragment | null) => e !== null);

  const topDAOOwners = topDAOOwnersResponse.map((e) =>
    parseLeaderboardUser(
      e,
      e?.holder?.publicKey,
      e?.holder?.username,
      baseUnitsToTokens(e?.balanceNanos as number).toString(),
    ),
  );

  return (
    <div className="my-12 m-auto">
      <div className="grid grid-cols-1 lg:grid-cols-3 gap-4">
        <div className="flex flex-col w-full">
          <SmileIcon className="m-auto mb-2" />

          <div className="text-center mb-3 text-lg text-muted-foreground font-medium">
            Creator Coin Holders
          </div>

          {loadingCCHoldersLeaderboard ? (
            <Skeleton className="w-full h-[400px] rounded-2xl" />
          ) : (
            <LeaderboardTable
              title="Top Creator Coin Holders"
              username="Username"
              metric="Held"
              tooltip=""
              noDataLabel="No holders found."
              topUsers={topCCHolders}
              additionalColumns={[
                {
                  title: "Value",
                  formatter: (
                    e: LeaderboardUser<TokenBalanceFieldsFragment>,
                  ) => {
                    if (loadingMarketData || !exchangeRate) {
                      return "Loading...";
                    }

                    const value = abbreviateNumber(
                      Number(e.value) *
                        desoNanosToUSD(coinPriceNanos, exchangeRate),
                    );

                    const icon = e.rawValue.hasPurchased ? (
                      <Wallet2 size={12} className="text-muted" />
                    ) : (
                      <Repeat2 size={12} className="text-muted" />
                    );

                    return (
                      <div className="flex gap-1 items-center justify-end">
                        ${value}
                        <InfoTooltip
                          text={
                            e.rawValue.hasPurchased ? "Purchased" : "Received"
                          }
                          triggerBtn={icon}
                        />
                      </div>
                    );
                  },
                },
              ]}
            />
          )}
        </div>

        <div className="flex flex-col w-full">
          <PaintBucketIcon className="m-auto mb-2" />

          <div className="text-center mb-3 text-lg text-muted-foreground font-medium">
            NFT Holders
          </div>

          {loadingNFTOwnersLeaderboard ? (
            <Skeleton className="w-full h-[400px] rounded-2xl" />
          ) : (
            <>
              <LeaderboardTable
                title="Top NFT Holders"
                username="Username"
                metric="NFTs Held"
                tooltip=""
                noDataLabel="This profile has not minted any NFTs."
                topUsers={topNFTOwners}
              />
            </>
          )}
        </div>

        <div className="flex flex-col w-full">
          <CandlestickChartIcon className="m-auto mb-2" />
          <div className="text-center mb-3 text-lg text-muted-foreground font-medium">
            Token Holders
          </div>

          {loadingDAOHoldersLeaderboard ? (
            <Skeleton className="w-full h-[400px] rounded-2xl" />
          ) : (
            <>
              <LeaderboardTable
                title="Top Token Holders"
                username="Username"
                metric="Coins Held"
                tooltip=""
                noDataLabel="This profile has not minted any tokens."
                topUsers={topDAOOwners}
              />
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default HolderLeaderboards;
