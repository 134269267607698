import { cn } from "@/lib";
import { useContext } from "react";
import { Skeleton } from "@/components/ui/skeleton";
import { formatUSD } from "../../utils/currency";
import TrendingIcon from "@/components/shared/trending-icon";
import { MarketDataContext } from "../../contexts/market-data";
import { Link } from "react-router-dom";
import { COINBASE_URL } from "../../utils/constants";

interface SubHeaderProps {
  className?: string;
  compact?: boolean;
}

export function SubHeader({ className, compact = false }: SubHeaderProps) {
  const { loading, marketData, exchangeRate } = useContext(MarketDataContext);

  const priceChangePercentage = marketData?.price_change_percentage_24h || 0;
  const marketCapChangePercentage =
    marketData?.market_cap_change_percentage_24h || 0;

  return (
    <div
      className={cn(
        "relative bg-background w-full",
        compact ? "border p-4 rounded-md" : "border-b border-border-light",
      )}
    >
      <div
        className={cn(
          "font-mono justify-center text-sm text-muted px-4 py-2 flex flex-col",
          !compact && " sm:flex-row",
          className,
        )}
      >
        {loading || !marketData || !exchangeRate ? (
          <div className="flex">
            <Skeleton className="mr-8 w-[240px] h-[20px]"></Skeleton>
            <Skeleton className="mr-8 w-[240px] h-[20px]"></Skeleton>
            <Skeleton className="mr-8 w-[240px] h-[20px]"></Skeleton>
          </div>
        ) : (
          <>
            <Link
              to={`${COINBASE_URL}/price/decentralized-social`}
              target="_blank"
              className={cn(
                "flex flex-wrap items-center",
                compact ? "mb-4" : "mr-8",
              )}
            >
              $DESO ≈&nbsp;
              <span className="font-medium text-muted-foreground">
                {formatUSD(exchangeRate / 100)}
              </span>
              {priceChangePercentage !== 0 && (
                <TrendingIcon percentage={priceChangePercentage}>
                  (24h)
                </TrendingIcon>
              )}
            </Link>

            {marketData.market_cap.usd !== 0 && (
              <Link
                to={`${COINBASE_URL}/price/decentralized-social`}
                target="_blank"
                className={cn(
                  "flex flex-wrap items-center",
                  compact ? "mb-4" : "mr-8",
                )}
              >
                {compact ? "Mkcap" : "Marketcap"} ≈&nbsp;
                <span className="font-medium text-muted-foreground">
                  {formatUSD(marketData.market_cap.usd, false)}
                </span>
                {marketCapChangePercentage !== 0 && (
                  <TrendingIcon percentage={marketCapChangePercentage}>
                    (24h)
                  </TrendingIcon>
                )}
              </Link>
            )}

            {marketData.total_volume.usd !== 0 && (
              <Link
                to={`${COINBASE_URL}/price/decentralized-social`}
                target="_blank"
              >
                {compact ? "Vol" : "Volume"} ≈&nbsp;
                <span className="font-medium text-muted-foreground">
                  {formatUSD(marketData.total_volume.usd, false)}
                </span>
                &nbsp;
                <span className="text-xs">(24h)</span>
              </Link>
            )}
          </>
        )}
      </div>
    </div>
  );
}

export default SubHeader;
