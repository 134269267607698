import { StakeRewardMethod } from "deso-protocol";
import { useContext } from "react";
import { Link } from "react-router-dom";
import { ActiveAccountContext } from "../../contexts/active-account";
import { StakeTableRowFragment } from "../../graphql/codegen/graphql";
import { useTableScroll } from "../../hooks/use-table-scroll";
import { desoNanosToDeso, formatDecimalValue } from "../../utils/currency";
import { formatDisplayName, shortenLongWord } from "../../utils/helpers";
import { Button } from "../ui/button";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../ui/table";
import StakingModal from "../validatorDetail/staking-modal";
import DesoAvatar from "./deso-avatar";
import JailedBadge from "@/components/shared/jailed-badge";

interface TableStakeEntriesProps {
  stakeEntries: Array<StakeTableRowFragment>;
  onTransactionFinalized: () => Promise<any>;
  total: number | null;
  offset: number;
  perPage: number;
  loadingPage: boolean;
  onPrevPage: () => void;
  onNextPage: () => void;
  hasPrevPage?: boolean;
  hasNextPage?: boolean;
}

const TableStakeEntries = ({
  stakeEntries,
  onTransactionFinalized,
  total,
  offset,
  perPage,
  loadingPage,
  onPrevPage,
  onNextPage,
  hasPrevPage,
  hasNextPage,
}: TableStakeEntriesProps) => {
  const { wrapperElement, onPageChange } = useTableScroll();
  const { account } = useContext(ActiveAccountContext);

  const headers = [
    "Validator",
    "My Stake",
    "Restaking",
    "Total Rewards",
    "Action",
  ];

  return (
    <div className="w-full" ref={wrapperElement}>
      <div className="relative rounded-2xl border overflow-hidden">
        <Table>
          <TableHeader>
            <TableRow>
              {headers.map((e) => (
                <TableHead
                  key={e}
                  className="last:text-right whitespace-nowrap md:whitespace-normal"
                >
                  {e}
                </TableHead>
              ))}
            </TableRow>
          </TableHeader>
          <TableBody>
            {stakeEntries.map((stake, index) => (
              <TableRow
                className="hover:bg-card dark:hover:bg-black/10 border-border-light"
                key={`stake_entry_${index}`}
              >
                <TableCell className="align-middle py-2 px-4 text-xs whitespace-nowrap">
                  <div className="flex items-start gap-3">
                    <DesoAvatar
                      size={40}
                      publicKey={
                        stake.validatorAccount?.publicKey ||
                        stake.validatorEntry?.account?.publicKey ||
                        ""
                      }
                      username={
                        stake.validatorAccount?.username ||
                        stake.validatorEntry?.account?.username ||
                        ""
                      }
                      clickable={true}
                      linkType="validator"
                      className="mb-1 cursor-pointer border w-10 h-10 hover:border-secondary"
                    />
                    <div className="flex flex-col items-start gap-1">
                      <div className=" flex items-center gap-2">
                        <div className="text-sm text-muted-foreground hover:underline underline-offset-4">
                          <Link
                            to={`../validator/${
                              stake.validatorAccount?.username ??
                              stake.validatorAccount?.publicKey
                            }`}
                          >
                            {formatDisplayName(
                              stake.validatorAccount?.username,
                              stake.validatorAccount?.publicKey,
                              true,
                            )}
                          </Link>
                        </div>
                        {stake.validatorEntry?.jailedAtEpochNumber !== "0" && (
                          <JailedBadge className="text-[10px] py-0 px-1.5" />
                        )}
                      </div>
                      <div className="text-xs text-muted font-mono">
                        {shortenLongWord(
                          stake.validatorAccount?.publicKey,
                          7,
                          7,
                        )}
                      </div>
                    </div>
                  </div>
                </TableCell>
                <TableCell>
                  <div className="flex flex-col items-start gap-1">
                    <div className="text-sm text-muted-foreground flex items-center gap-1">
                      {formatDecimalValue(
                        desoNanosToDeso(stake.stakeAmountNanos),
                      )}{" "}
                      <img
                        src="/assets/img/logo-deso-mark.svg"
                        alt="DESO"
                        className="h-[14px] ml-2 inline-block"
                      />
                    </div>
                    <div className="text-xs text-muted font-mono">
                      {(
                        stake.stakeAmountNanos /
                        stake.validatorEntry?.totalStakeAmountNanos
                      ).toLocaleString("en-US", {
                        style: "percent",
                        maximumFractionDigits: 2,
                      })}
                    </div>
                  </div>
                </TableCell>
                <TableCell>
                  <div className="flex flex-col items-start gap-1">
                    {stake.rewardMethod === StakeRewardMethod.Restake ? (
                      <div className="text-sm font-mono text-green-600 flex items-center gap-1">
                        <span className="w-2 h-2 rounded-full inline-block bg-green-500 mr-2 animate-pulse"></span>
                        Enabled
                      </div>
                    ) : (
                      <div className="text-sm font-mono text-red-600 flex items-center gap-1">
                        <span className="w-2 h-2 rounded-full inline-block bg-red-600 mr-2"></span>
                        Disabled
                      </div>
                    )}
                  </div>
                </TableCell>
                <TableCell>
                  <div className="flex flex-col items-start gap-1">
                    <div className="text-sm text-muted-foreground flex items-center gap-1">
                      {formatDecimalValue(
                        desoNanosToDeso(
                          stake.validatorEntry?.viewerStakeRewardsSum?.nodes[0]
                            ?.rewardNanos ?? 0,
                        ),
                        9,
                      )}{" "}
                      <img
                        src="/assets/img/logo-deso-mark.svg"
                        alt="DESO"
                        className="h-[14px] ml-2 inline-block"
                      />
                    </div>
                    {/* TODO: Add USD value */}
                    {/* <div className="text-xs text-muted font-mono">
                      ~$523.35 USD
                    </div> */}
                  </div>
                </TableCell>
                <TableCell>
                  <div className="flex justify-end gap-2">
                    {stake?.validatorAccount?.publicKey ? (
                      account?.publicKey && (
                        <>
                          <StakingModal
                            validatorPublicKey={
                              stake?.validatorAccount?.publicKey
                            }
                            transactorPublicKey={account?.publicKey}
                            operation="stake"
                            buttonStyle="default"
                            onTransactionFinalized={() =>
                              onTransactionFinalized()
                            }
                          />
                          <StakingModal
                            validatorPublicKey={
                              stake?.validatorAccount?.publicKey
                            }
                            transactorPublicKey={account?.publicKey}
                            operation="unstake"
                            buttonStyle="secondary"
                            onTransactionFinalized={() =>
                              onTransactionFinalized()
                            }
                          />
                        </>
                      )
                    ) : (
                      <Link
                        to={`/validator/${
                          stake?.validatorAccount?.username ??
                          stake?.validatorAccount?.publicKey
                        }`}
                      >
                        <Button
                          variant="outline"
                          size={"sm"}
                          className="text-sm flex items-center gap-2"
                        >
                          View
                        </Button>
                      </Link>
                    )}
                  </div>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>

      <div className="flex items-center justify-end space-x-2 py-4 flex-col md:flex-row gap-4 md:gap-0">
        <div className="flex-1 text-xs text-muted">
          Showing {offset + 1}-{offset + stakeEntries.length}
          {total !== null ? (
            <span> of {total.toLocaleString("en-US")} stake entries</span>
          ) : (
            " stake entries"
          )}
        </div>
        <div className="space-x-2">
          <Button
            variant="outline"
            size="sm"
            onClick={() => {
              onPrevPage();
              onPageChange();
            }}
            disabled={hasPrevPage !== undefined ? !hasPrevPage : offset === 0}
            className={loadingPage ? "pointer-events-none cursor-default" : ""}
          >
            Previous
          </Button>

          <Button
            variant="outline"
            size="sm"
            onClick={() => {
              onNextPage();
              onPageChange();
            }}
            disabled={
              hasNextPage !== undefined
                ? !hasNextPage
                : offset + perPage >= (total || 0)
            }
            className={loadingPage ? "pointer-events-none cursor-default" : ""}
          >
            Next
          </Button>
        </div>
      </div>
    </div>
  );
};

export default TableStakeEntries;
