import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
  DialogDescription,
} from "@/components/ui/dialog";
import { DialogClose } from "@radix-ui/react-dialog";
import { HEROSWAP_AFFILIATE_NAME, HEROSWAP_URL } from "../../utils/constants";
import { Link } from "react-router-dom";
import { useContext } from "react";
import { ActiveAccountContext } from "../../contexts/active-account";

// TODO: @ed
// Please check the links below
const exchanges = [
  {
    title: "Coinbase",
    href: "https://exchange.coinbase.com/trade/DESO-USD",
  },
  {
    title: "Huobi",
    href: "https://www.huobi.com/en-us/trade/deso_usdt",
  },
  {
    title: "Gate.io",
    href: "https://gate.io/trade/deso_usdt",
  },
  {
    title: "Ascendex",
    href: "https://www.ascendex.com/en/basic/cashtrade-spottrading/usdt/deso",
  },
  {
    title: "LBank",
    href: "https://www.lbank.com/en-US/trade/deso_usdt",
  },
  {
    title: "Blockchain.com",
    href: "https://exchange.blockchain.com/trade/DESO-USD",
  },
];

export function BuyDesoModal() {
  const { account } = useContext(ActiveAccountContext);

  // TODO: @ed
  // Please check the link
  let heroswapLink = `${HEROSWAP_URL}/${HEROSWAP_AFFILIATE_NAME}?destinationTickers=deso`;
  let heroswapIFrameLink = `${HEROSWAP_URL}/widget?destinationTickers=deso&affiliateName=${HEROSWAP_AFFILIATE_NAME}`;

  if (account?.publicKey) {
    heroswapLink += `&destinationAddress=${account.publicKey}`;
    heroswapIFrameLink += `&destinationAddress=${account.publicKey}`;
  }

  return (
    <Dialog>
      <DialogTrigger asChild>
        <Button variant="default" className="ml-2 rounded-full">
          Buy DESO
        </Button>
      </DialogTrigger>

      <DialogContent className="max-w-[500px]">
        <DialogHeader>
          <DialogTitle>Buy Anonymously with HeroSwap</DialogTitle>

          <DialogDescription>
            You can safely buy $DESO using{" "}
            <Link
              target="_blank"
              className="underline underline-offset-4"
              to={heroswapLink}
              rel="noreferrer"
            >
              HeroSwap
            </Link>
            , in under 10 seconds with ETH, SOL, BTC, USDC, USDT or another
            currency.
          </DialogDescription>
        </DialogHeader>

        <iframe
          title="heroswap"
          src={heroswapIFrameLink}
          className="border border-border-light border-solid w-full h-[400px] rounded-2xl"
        />

        <div className="p-4 bg-card rounded-2xl text-center">
          <p className="text-sm text-muted px-2 leading-6">
            <span className="font-semibold text-muted-foreground">
              Stake $DESO for 20% APY
              <br />
            </span>{" "}
            You can stake $DESO in a few clicks via the Validator Hub.
          </p>
          <Link to={"/validators"}>
            <DialogClose asChild>
              <Button variant="secondary" size={"sm"} className="mt-2">
                Stake DESO
              </Button>
            </DialogClose>
          </Link>
        </div>

        <p className="text-xs leading-5 text-center">
          DeSo is also listed on{" "}
          {exchanges.map((exchange, index) => (
            <Link
              key={index}
              to={exchange.href}
              className="mr-1 inline-block hover:underline"
            >
              {exchange.title},
            </Link>
          ))}
          and more.
        </p>
      </DialogContent>
    </Dialog>
  );
}
