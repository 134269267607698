import {
  CandlestickChartIcon,
  EditIcon,
  HeartIcon,
  MessageSquareIcon,
  MessagesSquareIcon,
  PaintBucketIcon,
  RepeatIcon,
  ScrollIcon,
  SmileIcon,
  StarIcon,
  UserPlus2Icon,
} from "lucide-react";
import { Metric } from "../ui/metric";
import { formatDecimalValue } from "../../utils/currency";
import { Skeleton } from "../ui/skeleton";
import { range } from "src/utils/helpers";
import { DashboardStat } from "../../graphql/codegen/graphql";

const SKELETON_NUM_OF_BIG_CARDS = 2;
const SKELETON_NUM_OF_SMALL_CARDS = 8;

interface SocialActivityMetricsProps {
  stats: DashboardStat;
  loadingStats: boolean;
}

const SocialActivityMetrics = ({
  stats,
  loadingStats,
}: SocialActivityMetricsProps) => {
  return (
    <div className="mt-12">
      <div className="mb-12">
        <div className="flex justify-between items-center mt-12">
          <h3 className="mb-4 flex items-center">
            <StarIcon className="mr-2" />
            Asset Activity
          </h3>
        </div>

        <div className="grid grid-cols-2 md:grid-cols-2 lg:grid-cols-4 gap-4">
          {loadingStats ? (
            range(SKELETON_NUM_OF_SMALL_CARDS).map((_, i) => (
              <Skeleton className="h-[80px]" key={i} />
            ))
          ) : (
            <>
              <Metric
                value={formatDecimalValue(stats.txnCountCreatorCoin)}
                label="Total Creator Coin Txns"
                icon={<SmileIcon />}
                tooltip="All on-chain Creator Coin buys, sales, and transfers."
              />
              <Metric
                value={formatDecimalValue(stats.txnCountNft)}
                label="Total NFT Txns"
                icon={<PaintBucketIcon />}
                tooltip="All on-chain NFT mints, sales, bids, buys, transfers, and burns"
              />
              <Metric
                value={formatDecimalValue(stats.txnCountDex)}
                icon={<CandlestickChartIcon />}
                label="Total DEX Txns"
                tooltip="All on-chain limit orders"
              />
              <Metric
                value={formatDecimalValue(stats.txnCountSocial)}
                icon={<HeartIcon />}
                label="Total Social Txns"
                tooltip="Combination of all on-chain posts, likes, follows, reactions, profile updates and comments."
              />
            </>
          )}
        </div>
      </div>

      <div className="flex justify-between items-center">
        <h3 className="mb-4 flex items-center">
          <HeartIcon className="mr-2" />
          Social Activity
        </h3>
      </div>

      <div className="grid grid-cols-2 md:grid-cols-2 lg:grid-cols-2 gap-4 mb-4">
        {loadingStats ? (
          range(SKELETON_NUM_OF_BIG_CARDS).map((e, i) => (
            <Skeleton className="h-[115px]" key={i} />
          ))
        ) : (
          <>
            <Metric
              icon={<EditIcon />}
              value={formatDecimalValue(stats.postCount)}
              label="Total Posts (Short Form)"
              tooltip="All on-chain posts"
            />
            <Metric
              icon={<MessageSquareIcon />}
              value={formatDecimalValue(stats.commentCount)}
              label="Total Comments"
              tooltip="All comments, replies and threads are stored on-chain."
            />
          </>
        )}
      </div>

      <div className="grid grid-cols-2 md:grid-cols-2 lg:grid-cols-4 gap-4">
        {loadingStats ? (
          range(SKELETON_NUM_OF_SMALL_CARDS).map((_, i) => (
            <Skeleton className="h-[80px]" key={i} />
          ))
        ) : (
          <>
            <Metric
              icon={<ScrollIcon />}
              value={formatDecimalValue(stats.postLongformCount)}
              label="Total Blogs (Long Form)"
              tooltip="All on-chain long form posts / articles."
            />
            <Metric
              icon={<RepeatIcon />}
              value={formatDecimalValue(stats.repostCount)}
              tooltip="All on-chain reposts and quote-posts."
              label="Total Reposts"
            />
            <Metric
              icon={<MessagesSquareIcon />}
              value={formatDecimalValue(stats.messageCount)}
              label="Total Messages"
              tooltip="All messages are end-to-end encrypted and stored on-chain, including personal DMs and Group Chats"
            />
            <Metric
              icon={<UserPlus2Icon />}
              value={formatDecimalValue(stats.followCount)}
              label="Total Follows"
              tooltip="All follow and unfollow transactions are stored directly on-chain."
            />
          </>
        )}
      </div>
    </div>
  );
};

export default SocialActivityMetrics;
