import { CheckIcon, CopyIcon } from "lucide-react";
import { useState } from "react";
import { copyTextToClipboard } from "../../utils/helpers";
import { useToast } from "@/components/ui/use-toast";

interface CopyToClipboardProps {
  children: Array<JSX.Element | string> | JSX.Element | string;
  className?: string;
  text: string;
  showIcon?: boolean;
  showToast?: boolean;
  iconSize?: number;
  toastText?: string;
}

const CHANGE_ICON_TIME_MS = 1500;

const CopyToClipboard = ({
  children,
  className = "",
  text,
  showIcon = true,
  showToast = false,
  iconSize = 16,
  toastText = "",
}: CopyToClipboardProps) => {
  const { toast } = useToast();

  const [isCopied, setIsCopied] = useState(false);

  const handleCopyClick = (copyText: string) => {
    copyTextToClipboard(copyText)
      .then(() => {
        setIsCopied(true);

        if (showToast) {
          toast({
            variant: "default",
            title: "Success",
            duration: 2500,
            description:
              toastText ||
              `The text has been successfully copied to your clipboard`,
          });
        }

        setTimeout(() => {
          setIsCopied(false);
        }, CHANGE_ICON_TIME_MS);
      })
      .catch((err) => {
        alert("The text cannot be copied");
        return Promise.reject(err);
      });
  };

  return (
    <div
      onClick={() => handleCopyClick(text)}
      className={`cursor-pointer flex items-center ${className}`}
    >
      {showIcon &&
        (isCopied ? (
          <CheckIcon size={iconSize} className="mr-2 text-green-500" />
        ) : (
          <CopyIcon size={iconSize} className="mr-2" />
        ))}

      {children}
    </div>
  );
};

export default CopyToClipboard;
