import { Link } from "react-router-dom";
import {
  StakerFragment,
  ValidatorFragment,
} from "../../graphql/codegen/graphql";
import { useTableScroll } from "../../hooks/use-table-scroll";
import { desoNanosToDeso, formatDecimalValue } from "../../utils/currency";
import { formatDisplayName, shortenLongWord } from "../../utils/helpers";
import { Button } from "../ui/button";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../ui/table";
import DesoAvatar from "./deso-avatar";

interface TableStakersProps {
  stakers: Array<StakerFragment>;
  validator: ValidatorFragment;
  total: number | null;
  offset: number;
  perPage: number;
  loadingPage: boolean;
  onPrevPage: () => void;
  onNextPage: () => void;
  hasPrevPage?: boolean;
  hasNextPage?: boolean;
}

const TableStakers = ({
  stakers,
  validator,
  total,
  offset,
  perPage,
  loadingPage,
  onPrevPage,
  onNextPage,
  hasPrevPage,
  hasNextPage,
}: TableStakersProps) => {
  const { wrapperElement, onPageChange } = useTableScroll();

  const headers = ["#", "Username", "Total Stake"];

  return (
    <div className="w-full" ref={wrapperElement}>
      <div className="relative rounded-2xl border overflow-hidden">
        <Table>
          <TableHeader>
            <TableRow>
              {headers.map((e) => (
                <TableHead
                  key={e}
                  className="last:text-right whitespace-nowrap px-4"
                >
                  {e}
                </TableHead>
              ))}
            </TableRow>
          </TableHeader>
          <TableBody>
            {stakers.map((staker, index) => (
              <TableRow
                className="hover:bg-card dark:hover:bg-black/10 border-border-light"
                key={`staker_${index}`}
              >
                <TableCell className="w-[20px]">
                  <div className="flex items-center gap-2">
                    <div className="text-sm text-muted">{index + 1}</div>
                  </div>
                </TableCell>
                <TableCell className="align-middle py-2 px-4 text-xs whitespace-nowrap">
                  <div className="flex items-start gap-3">
                    <DesoAvatar
                      size={40}
                      publicKey={staker.account?.publicKey || ""}
                      username={staker.account?.username || ""}
                      clickable={true}
                      className="mb-1 cursor-pointer border w-10 h-10 hover:border-secondary"
                    />
                    <div className="flex flex-col items-start gap-1">
                      <div className=" flex items-center gap-2">
                        <div className="text-sm text-muted-foreground hover:underline underline-offset-4">
                          <Link
                            to={`/u/${
                              staker.account?.username ||
                              staker.account?.publicKey
                            }`}
                          >
                            {formatDisplayName(
                              staker.account?.username,
                              staker.account?.publicKey,
                              true,
                            )}
                          </Link>
                        </div>
                      </div>
                      <div className="text-xs text-muted font-mono">
                        {shortenLongWord(staker.account?.publicKey, 8, 8)}
                      </div>
                    </div>
                  </div>
                </TableCell>
                <TableCell className="text-right">
                  <div className="flex flex-col items-end gap-1">
                    <div className="text-sm text-muted-foreground flex items-center gap-2">
                      {formatDecimalValue(
                        desoNanosToDeso(staker.stakeAmountNanos),
                      )}{" "}
                      <img
                        src="/assets/img/logo-deso-mark.svg"
                        alt="DESO"
                        className="h-[14px] inline-block"
                      />
                    </div>
                    <div className="text-xs text-muted font-mono">
                      {(
                        staker.stakeAmountNanos /
                        validator.totalStakeAmountNanos
                      ).toLocaleString("en-US", {
                        style: "percent",
                        minimumFractionDigits: 2,
                      })}
                    </div>
                  </div>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>

      <div className="flex items-center justify-end space-x-2 py-4 flex-col md:flex-row gap-4 md:gap-0 mb-8 md:mb-0">
        <div className="flex-1 text-xs text-muted">
          Showing {offset + 1}-{offset + stakers.length}
          {total !== null ? (
            <span> of {formatDecimalValue(total)} stakers</span>
          ) : (
            " stakers"
          )}
        </div>
        <div className="space-x-2">
          <Button
            variant="outline"
            size="sm"
            onClick={() => {
              onPrevPage();
              onPageChange();
            }}
            disabled={hasPrevPage !== undefined ? !hasPrevPage : offset === 0}
            className={loadingPage ? "pointer-events-none cursor-default" : ""}
          >
            Previous
          </Button>

          <Button
            variant="outline"
            size="sm"
            onClick={() => {
              onNextPage();
              onPageChange();
            }}
            disabled={
              hasNextPage !== undefined
                ? !hasNextPage
                : offset + perPage >= (total || 0)
            }
            className={loadingPage ? "pointer-events-none cursor-default" : ""}
          >
            Next
          </Button>
        </div>
      </div>
    </div>
  );
};

export default TableStakers;
