import DesoAvatar from "@/components/shared/deso-avatar";
import JailedBadge from "@/components/shared/jailed-badge";
import { buttonVariants } from "@/components/ui/button";
import { cn } from "@/lib";
import { LucideExternalLink, LucideGlobe, LucideInfo } from "lucide-react";
import { useContext } from "react";
import { DIAMOND_APP_URL } from "src/utils/constants";
import { ActiveAccountContext } from "../../contexts/active-account";
import { ValidatorFragment } from "../../graphql/codegen/graphql";
import { formatDisplayName, shortenLongWord } from "../../utils/helpers";
import CopyToClipboard from "../shared/copy-to-clipboard";
import { Badge } from "../ui/badge";
import StakingModal from "../validatorDetail/staking-modal";

interface ValidatorCardProps {
  validator: ValidatorFragment;
  reloadValidator: () => Promise<any>;
  rank: number;
}

const ValidatorCard = ({
  validator,
  rank,
  reloadValidator,
}: ValidatorCardProps) => {
  const { account } = useContext(ActiveAccountContext);

  if (!validator.account) {
    return <div>Error: Validator account is missing.</div>;
  }

  return (
    <div className="rounded-3xl mb-12 bg-card">
      <div
        className="h-[260px] border-t dark:border-t-white/20 border-b border-b-background bg-black bg-no-repeat rounded-tl-3xl rounded-tr-3xl bg-cover bg-center"
        style={{ backgroundImage: "url('/assets/img/image-cover-2.png')" }}
      />

      <div className="flex flex-col gap-8 lg:gap-0 lg:flex-row justify-between items-start p-6 relative">
        <div className="flex flex-col lg:flex-row items-start gap-2">
          <div className="relative -top-[60px]">
            <div className="flex flex-col-reverse items-center justify-center gap-3">
              <DesoAvatar
                publicKey={validator?.account?.publicKey ?? ""}
                username={validator?.account?.username ?? ""}
                size={74}
                className="border-4 border-background bg-background"
                clickable={true}
              />
            </div>
          </div>

          <div className="flex flex-col gap-2">
            <div className="relative -mt-12 lg:mt-0">
              <div className="flex flex-col items-start">
                <h1 className="text-lg text-black dark:text-white font-medium flex items-center gap-2">
                  {formatDisplayName(
                    validator?.account?.username,
                    validator?.account?.publicKey,
                    true,
                    true,
                  )}
                  <Badge variant={"outline"}>Rank #{rank}</Badge>
                  {validator?.jailedAtEpochNumber !== "0" && <JailedBadge />}
                </h1>
              </div>
            </div>

            <div className="">
              <div className="flex flex-col md:flex-row md:items-center gap-2 md:gap-4 text-muted">
                {validator?.account?.extraData?.WebsiteURL && (
                  <div className="flex items-center gap-2 text-sm">
                    <LucideGlobe className="w-5 h-5" />
                    <a
                      className="font-semibold underline underline-offset-4 cursor-pointer hover:text-muted-foreground"
                      href={validator?.account?.extraData?.WebsiteURL}
                      target="_blank"
                      rel="noreferrer noopener"
                    >
                      {validator?.account?.extraData?.WebsiteURL}
                    </a>
                  </div>
                )}
                {validator?.account?.publicKey && (
                  <div className="flex items-center gap-2 text-sm font-mono">
                    <CopyToClipboard text={validator?.account?.publicKey ?? ""}>
                      {shortenLongWord(
                        validator?.account?.publicKey ?? "",
                        8,
                        8,
                      )}
                    </CopyToClipboard>
                  </div>
                )}
              </div>
              <div className="max-w-full md:max-w-[80%] mt-4 text-sm text-foreground">
                {validator?.account?.description ?? ""}{" "}
              </div>
            </div>
            <div className="mt-4 flex flex-col md:flex-row items-start md:items-center gap-4 md:gap-3">
              <StakingModal
                validatorPublicKey={validator.account?.publicKey}
                transactorPublicKey={account?.publicKey}
                operation="stake"
                buttonStyle="default"
                onTransactionFinalized={() => {
                  return reloadValidator();
                }}
              />
              {Number(
                validator?.viewerStake?.nodes?.[0]?.stakeAmountNanos ?? 0,
              ) > 0 && (
                <StakingModal
                  validatorPublicKey={validator?.account?.publicKey}
                  transactorPublicKey={account?.publicKey}
                  operation="unstake"
                  buttonStyle="secondary"
                  onTransactionFinalized={() => {
                    return reloadValidator();
                  }}
                />
              )}
              <a
                className={cn(
                  "flex items-center gap-1",
                  buttonVariants({ variant: "outline" }),
                )}
                target="_blank"
                rel="noreferrer noopener"
                href={`${DIAMOND_APP_URL}/u/${
                  validator.account.username ?? validator.account.publicKey
                }`}
              >
                View Profile <LucideExternalLink className="w-4 h-4" />
              </a>
              <a
                href="https://chat.deso.com"
                target="_blank"
                rel="noreferrer noopener"
                className={cn(
                  "flex items-center gap-1",
                  buttonVariants({ variant: "outline" }),
                )}
              >
                Send Message
                <LucideExternalLink className="w-4 h-4" />
              </a>
            </div>
            {!account && (
              <div className="flex gap-2">
                <LucideInfo className=" w-4 h-4" />
                <p className="text-xs">
                  Log in or sign up to manage your stake with this validator.
                </p>
              </div>
            )}
          </div>
        </div>
        <div className="flex flex-col gap-1 xl:text-right border-t border-border-light md:border-none w-full md:w-auto pt-4 md:pt-0">
          {validator?.domains && validator?.domains.length > 0 && (
            <>
              <span className="text-sm">Registered Domains</span>
              <div className="mt-2">
                <ul className="font-mono border border-border-light rounded-xl text-xs p-2">
                  {validator?.domains?.map((domain) => (
                    <CopyToClipboard key={domain} text={domain ?? ""}>
                      {domain ?? ""}
                    </CopyToClipboard>
                  ))}
                </ul>
              </div>
            </>
          )}
          {validator?.lastActiveAtEpochNumber && (
            <div className="flex items-start xl:items-end gap-0 text-sm flex-col mt-4">
              Last Active Epoch{" "}
              <span className="font-semibold text-lg">
                {validator?.lastActiveAtEpochNumber}
              </span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ValidatorCard;
