import { useState } from "react";
import { DateFilters } from "../shared/date-filters";
import { Button } from "../ui/button";
import {
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger,
} from "../ui/collapsible";
import { Input } from "../ui/input";

import { BlockFilters } from "../../types";
import AdvancedFiltersToggle from "@/components/shared/advanced-filters-toggle";

interface BlockAdvancedFiltersProps {
  filters: BlockFilters;
  setFilters: (filter?: BlockFilters) => void;
  onApplyFilters: () => void;
  onClearFilters: () => void;
}

const BlockAdvancedFilters = ({
  filters,
  setFilters,
  onApplyFilters,
  onClearFilters,
}: BlockAdvancedFiltersProps) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <Collapsible open={isOpen} onOpenChange={setIsOpen} className="w-full">
      <div className="flex items-center justify-between">
        Advanced Filters
        <CollapsibleTrigger asChild>
          <AdvancedFiltersToggle open={isOpen} setOpen={setIsOpen} />
        </CollapsibleTrigger>
      </div>

      <CollapsibleContent className="w-full">
        <div className="md:border border-border-light mt-2 mb-2 border-solid rounded-md">
          <div className="grid grid-cols-1 md:grid-cols-3 gap-4 my-4 md:my-0 md:p-5 md:border-b border-b-border-light">
            <div>
              <label className="mb-2 inline-block text-sm">Block Height</label>
              <Input
                className="w-full placeholder:text-muted"
                placeholder="Enter Block Height"
                value={filters.blockHeight}
                onChange={(e) =>
                  setFilters({
                    ...filters,
                    blockHeight: e.target.value,
                  })
                }
              />
            </div>

            <div>
              <label className="mb-2 inline-block text-sm">Block Hash</label>
              <Input
                className="w-full placeholder:text-muted"
                placeholder="Enter Block Hash"
                value={filters.blockHash}
                onChange={(e) =>
                  setFilters({
                    ...filters,
                    blockHash: e.target.value,
                  })
                }
              />
            </div>

            <div>
              <label className="mb-2 inline-block text-sm">Dates</label>
              <DateFilters
                dateFilter={filters.date}
                setDateFilter={(e) => {
                  if (!e) {
                    return;
                  }

                  setFilters({
                    ...filters,
                    date: e,
                  });
                }}
              />
            </div>
          </div>
        </div>

        <div className="text-right mt-4 mb-2">
          <Button
            variant="outline"
            className="mr-2"
            onClick={() => onClearFilters()}
          >
            Clear all
          </Button>
          <Button onClick={() => onApplyFilters()}>Apply filters</Button>
        </div>
      </CollapsibleContent>
    </Collapsible>
  );
};

export default BlockAdvancedFilters;
