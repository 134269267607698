import {
  createContext,
  useCallback,
  useEffect,
  useState,
  ReactNode,
} from "react";
import { CoinGeckoMarketData, getDesoMarketData } from "../utils/coin-gecko";
import { getExchangeRates } from "deso-protocol";
import { getTotalSupply } from "../backend/api";
import { toast } from "@/components/ui/use-toast";

export interface MarketDataContextType {
  marketData: CoinGeckoMarketData | null;
  exchangeRate: number;
  loading: boolean;
}

export const MarketDataContext = createContext<MarketDataContextType>({
  marketData: null,
  exchangeRate: 0,
  loading: false,
});

export const MarketDataProvider = ({ children }: { children: ReactNode }) => {
  const [marketDataState, setMarketDataState] = useState<MarketDataContextType>(
    {
      marketData: null,
      exchangeRate: 0,
      loading: false,
    },
  );

  const fetchCoinGeckoMarketData = useCallback(async () => {
    try {
      return await getDesoMarketData();
    } catch (e) {
      toast({
        variant: "destructive",
        title: "Error",
        description: `There was a problem accessing CoinGecko API. This can result in potential data inconsistencies.`,
      });

      return Promise.resolve({
        market_data: {
          circulating_supply: 0,
          current_price: {
            usd: 0,
          },
          total_supply: 0,
          market_cap: {
            usd: 0,
          },
          total_volume: {
            usd: 0,
          },
          price_change_percentage_24h: 0,
          market_cap_change_percentage_24h: 0,
        },
      });
    }
  }, []);

  useEffect(() => {
    const fetchMarketData = async () => {
      setMarketDataState({
        marketData: null,
        exchangeRate: 0,
        loading: true,
      });

      try {
        const [{ market_data }, exchangeRateResponse, totalSupply] =
          await Promise.all([
            fetchCoinGeckoMarketData(),
            getExchangeRates(),
            getTotalSupply(),
          ]);

        if (market_data.current_price.usd === 0) {
          market_data.current_price.usd =
            exchangeRateResponse.USDCentsPerDeSoCoinbase / 100;
        }

        market_data.total_supply = totalSupply;
        market_data.circulating_supply = totalSupply;

        if (market_data.market_cap.usd === 0) {
          market_data.market_cap.usd =
            market_data.current_price.usd * market_data.total_supply;
        }

        setMarketDataState({
          marketData: market_data,
          exchangeRate: exchangeRateResponse.USDCentsPerDeSoCoinbase,
          loading: false,
        });
      } catch (e: any) {
        toast({
          variant: "destructive",
          title: "Error",
          description: `There was a problem getting exchange rates or market data. ${JSON.stringify(
            e,
          )}`,
        });

        setMarketDataState({
          marketData: null,
          exchangeRate: 0,
          loading: false,
        });
      }
    };

    fetchMarketData();
  }, [fetchCoinGeckoMarketData]);

  return (
    <MarketDataContext.Provider value={marketDataState}>
      {children}
    </MarketDataContext.Provider>
  );
};
