import SubHeader from "@/components/shared/sub-header";
import { Outlet } from "react-router";
import { Header } from "./components/shared/header";
import ScrollToTop from "./components/shared/scroll-to-top";
import { Toaster } from "./components/ui/toaster";
import { ThemeProvider } from "./contexts/theme";
import { ActiveAccountProvider } from "./contexts/active-account";
import { MarketDataProvider } from "./contexts/market-data";

function App() {
  return (
    <ThemeProvider>
      <ActiveAccountProvider>
        <MarketDataProvider>
          <ScrollToTop>
            <main className="min-h-screen">
              <Header />

              <SubHeader />

              <section className="p-4 md:p-6">
                <Outlet />
              </section>
            </main>
          </ScrollToTop>

          <Toaster />
        </MarketDataProvider>
      </ActiveAccountProvider>
    </ThemeProvider>
  );
}

export default App;
